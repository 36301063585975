// stylelint-disable

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

// $grays: () !default;
// $grays: map-merge((
//   "50":  #f3f3f8,
//   "100": #e1e1e6,
//   "200": #d2d3d7,
//   "300": #b9babe,
//   "400": #9a9c9f,
//   "500": #808285,
//   "600": #6b6d6f,
//   "700": #5a5b5c,
//   "800": #474748,
//   "900": #323232
// ), $grays);

// $blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge((
  "blue":       $theme-500,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $neutral-600,
  "gray-dark":  $neutral-800
), $colors);

$primary:       $theme-500 !default;
$secondary:     $neutral-600 !default;
$success:       $success-500 !default;
$info:          $info-500 !default;
$warning:       $warning-500 !default;
$danger:        $danger-500 !default;
$light:         $neutral-100 !default;
$dark:          $neutral-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black !default;
$yiq-text-light:            $white !default;

// gradient
$theme-gradient: "-45deg, #{$theme-400} 0%, #{$theme-600} 100%" !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              false !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
), $sizes);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $neutral-50 !default;
$body-color:                $black !default;

// Links
//
// Style anchor elements.


$link-color:                $theme-500 !default;
$link-decoration:           none !default;
$link-hover-color:          $theme-800 !default;
$link-hover-decoration:     underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.65 !default;
$line-height-sm:              1.25 !default;
$line-height-xs:              1.05 !default;

$border-width:                1px !default;
$border-color:                $neutral-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

$caret-width:                 .3em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
// $transition-collapse:         height .35s ease !default;
$transition-collapse:         height .25s !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case

$font-family-sans-serif:      "Roboto Condensed", Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case


$font-size-base:              1.25rem !default; // 18px
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                rem(ptr(60)) !default;
$h2-font-size:                rem(ptr(48)) !default;
$h3-font-size:                rem(ptr(34)) !default;
$h4-font-size:                rem(ptr(28)) !default;
$h5-font-size:                rem(ptr(24)) !default;
$h6-font-size:                $h5-font-size !default;
$h7-font-size:                rem(ptr(20)) !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.1 !default;
$headings-color:              $black !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              rem(ptr(24)) !default;
$lead-font-weight:            300 !default;
$lead-line-height:            1.5 !default;
$lead-color:                  $neutral-600 !default;

$small-font-size:             89% !default;

$text-muted:                  $neutral-600 !default;

$blockquote-small-color:      $neutral-600 !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             $neutral-400 !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

$hr-margin-y:                 $spacer !default;


// Glypy Fonts
//
// Glypy Font, Sizes, Color
$glyph-font-size-default:            rem(ptr(24)) !default;
$glyph-font-size-lg:                 rem(ptr(32)) !default;
$glyph-font-size-sm:                 rem(ptr(20)) !default;
$glyph-font-size-xs:                 rem(ptr(16)) !default;

$glyph-color:                        $neutral-600 !default;
$glyph-disabled-color:               $black-alpha-30 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            rem(ptr(8)) !default;

$table-cell-padding-sm-x:       5px !default;
$table-cell-padding-sm-top:     5px !default;
$table-cell-padding-sm-bottom:  4px !default;

// $table-cell-padding-sm:       .3rem !default;

$table-bg:                    transparent !default;
$table-accent-bg:             $neutral-100 !default;
$table-hover-bg:              $theme-50 !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          $border-width !default;
$table-border-color:          $neutral-400 !default;

$table-head-bg:               $neutral-200 !default;
$table-head-color:            $neutral-700 !default;
$table-head-height:           rem(ptr(44)) !default;
$table-head-height-sm:        rem(ptr(34)) !default;

$table-dark-bg:               $neutral-900 !default;
$table-dark-accent-bg:        rgba($white, .05) !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($neutral-900, 7.5%) !default;
$table-dark-color:            $body-bg !default;

$table-inverse-bg:            $neutral-600 !default;
// $table-inverse-accent-bg:        rgba($white, .05) !default;
// $table-inverse-hover-bg:         rgba($white, .075) !default;
$table-inverse-border-color:  $table-inverse-bg !default;
$table-inverse-color:         $white !default;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-min-width:               rem(ptr(115)) !default;

$btn-height:                  rem(ptr(48)) !default;
// $btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-y:               0 !default;
$btn-padding-x:               rem(ptr(16)) !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-height-xs:               rem(ptr(24)) !default;
// $btn-padding-y-xs:            rem(ptr(5)) !default;
$btn-padding-y-xs:            0 !default;
$btn-padding-x-xs:            rem(ptr(8)) !default;
$btn-line-height-xs:          $line-height-xs !default;

$btn-height-sm:               rem(ptr(32)) !default;
// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-y-sm:            0 !default;
$btn-padding-x-sm:            rem(ptr(11)) !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-height-lg:               rem(ptr(64)) !default;
// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-y-lg:            0 !default;
$btn-padding-x-lg:            rem(ptr(18)) !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;


$btn-font-size-xs:            rem(ptr(14)) !default;
$btn-font-size-sm:            rem(ptr(16)) !default;
$btn-font-size:               rem(ptr(20)) !default;
$btn-font-size-lg:            rem(ptr(26)) !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $black-alpha-30 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           rem(ptr(6)) !default;
$btn-border-radius-lg:        rem(ptr(8)) !default;
$btn-border-radius-sm:        rem(ptr(4)) !default;
$btn-border-radius-xs:        rem(ptr(3)) !default;

// $btn-transition:              color .2s linear, background-color .2s linear, border-color .2s linear, box-shadow .2s linear !default;
$btn-transition:              color .2s linear, background-color .2s linear, border-color .2s linear !default;


// Forms

$label-margin-bottom:                   .5rem !default;
$input-disabled-label:                  $black-alpha-30 !default;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-bg:                              $neutral-25 !default;
$input-disabled-bg:                     transparent !default;

$input-color:                           $black !default;
$input-border-color:                    $neutral-200 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-lg:                $border-radius-lg !default;
$input-border-radius-sm:                $border-radius-sm !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $theme-500 !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                inset 0 0 0 1px $theme-500 !default;

$input-placeholder-color:               $neutral-300 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:                 ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:                  rem(ptr(6)) !default;

$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .3rem !default;
$form-check-input-margin-x:             .25rem !default;

$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-group-margin-bottom:              1rem !default;

$input-group-addon-color:               $neutral-600 !default;
$input-group-addon-bg:                  transparent !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-control-gutter:                 1.5rem !default;
$custom-control-spacer-x:               1rem !default;

$custom-control-indicator-size:         1rem !default;
$custom-control-indicator-bg:           $neutral-300 !default;
$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-control-indicator-disabled-bg:          $neutral-200 !default;
$custom-control-label-disabled-color:           $neutral-600 !default;

$custom-control-indicator-checked-color:        $component-active-color !default;
$custom-control-indicator-checked-bg:           $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default;
$custom-control-indicator-checked-box-shadow:   none !default;

$custom-control-indicator-focus-box-shadow:     0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color:         $component-active-color !default;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow:    none !default;

$custom-checkbox-indicator-border-radius:       $border-radius !default;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg:          $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color:       $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:  none !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y:           .375rem !default;
$custom-select-padding-x:           .75rem !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-btn-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $neutral-600 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $neutral-200 !default;
$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $neutral-800 !default;
$custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width:        $input-btn-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-box-shadow:    inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm:        75% !default;
$custom-select-height-sm:           $input-height-sm !default;

$custom-select-font-size-lg:        125% !default;
$custom-select-height-lg:           $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $neutral-300 !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:            1rem !default;
$custom-range-thumb-height:           $custom-range-thumb-width !default;
$custom-range-thumb-bg:               $component-active-bg !default;
$custom-range-thumb-border:           0 !default;
$custom-range-thumb-border-radius:    1rem !default;
$custom-range-thumb-box-shadow:       0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;
$custom-range-thumb-active-bg:        lighten($component-active-bg, 35%) !default;

$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-btn-focus-box-shadow !default;

$custom-file-padding-y:             $input-btn-padding-y !default;
$custom-file-padding-x:             $input-btn-padding-x !default;
$custom-file-line-height:           $input-btn-line-height !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-btn-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;


// Form validation
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           rem(ptr(16)) !default;
$form-feedback-valid-color:         $success-500 !default;
$form-feedback-invalid-color:       $danger-500 !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-divider-bg:               $neutral-300 !default;
// $dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
$dropdown-box-shadow:               0 .125rem .625rem 0 rgba(0, 0, 0, .2) !default;

$dropdown-link-color:               $neutral-800 !default;
$dropdown-link-hover-color:         $neutral-800 !default;
$dropdown-link-hover-bg:            $theme-50 !default;

$dropdown-link-active-color:        $white !default;
$dropdown-link-active-bg:           $theme-500 !default;

$dropdown-link-disabled-color:      $black-alpha-30 !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           .625rem !default;

$dropdown-header-color:             $neutral-500 !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-xrx-mobile-header:                970 !default;
$zindex-xrx-mobile-nav-backdrop:          980 !default;
$zindex-xrx-mobile-nav:                   990 !default;
$zindex-xrx-desktop-header:               1000 !default;
$zindex-sticky:                           1020 !default;
$zindex-fixed:                            1030 !default;
$zindex-xrx-skip:                         1038 !default;
$zindex-modal-demoted:                    1039 !default;
$zindex-modal-backdrop:                   1040 !default;
$zindex-modal:                            1050 !default;
$zindex-alert-popup:                      1059 !default;
$zindex-datepicker:                       1070 !default;
$zindex-dropdown:                         1070 !default;
$zindex-dropdown-arrow:                   1071 !default;
$zindex-popover:                          1080 !default;
$zindex-tooltip:                          1090 !default;

// Navs

$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $neutral-600 !default;

$nav-tabs-border-color:             $neutral-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $neutral-200 $neutral-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $neutral-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $neutral-300 $neutral-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

$nav-divider-color:                 $neutral-200 !default;
$nav-divider-margin-y:              ($spacer / 2) !default;

// Navbar

$navbar-padding-y:                  ($spacer / 2) !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                rgba($black, .5) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $link-color !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           $neutral-300 !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               $neutral-200 !default;
$pagination-hover-border-color:     $neutral-300 !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $neutral-600 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $neutral-300 !default;


// Jumbotron

$jumbotron-padding:                 2rem !default;
$jumbotron-bg:                      $neutral-200 !default;


// Cards

$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.25rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                rem(ptr(8)) !default;
$card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       $neutral-200 !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 ($grid-gutter-width / 2) !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;


// Tooltips

$tooltip-font-size:                 rem(ptr(16)) !default;
$tooltip-min-width:                 48px !default;
$tooltip-max-width:                 250px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             rem(ptr(6)) !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 rem(ptr(12)) !default;
$tooltip-padding-x:                 rem(ptr(16)) !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

$tooltip-box-shadow:                0 3px 14px 0 $black-alpha-60 !default;


// Popovers

$popover-font-size:                 $font-size-base !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              transparent !default;
$popover-border-radius:             $border-radius-lg !default;
// $popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;
$popover-box-shadow:                $tooltip-box-shadow !default;

$popover-header-bg:                 $neutral-200 !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .625rem !default;
$popover-header-padding-x:          1.25rem !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;


// Badges

$badge-font-size:                   rem(ptr(16)) !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-padding-y:                   0 !default;
$badge-padding-x:                   rem(ptr(8)) !default;
$badge-border-radius:               $border-radius !default;

// $badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
// $badge-pill-border-radius:          10rem !default;


// Modals

// Padding applied to the modal body
$modal-body-padding:                rem(ptr(20)) !default;
$modal-footer-padding:              0 !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           1.25 !default;

$modal-header-bg:                   $white !default;
$modal-content-bg:                  $body-bg !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
// $modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
// $modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;
$modal-content-box-shadow:          0 3px 14px 0 $black-alpha-60 !default;

$modal-backdrop-bg:                 $black-alpha-60 !default;
$modal-backdrop-opacity:            1 !default;
$modal-header-border-color:         $neutral-400 !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         0 !default;
$modal-header-padding:              rem(ptr(20)) !default;

// $modal-lg:                          900px !default;
// $modal-md:                          600px !default;
// $modal-sm:                          300px !default;

$modal-transition:                  transform .15s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   rem(ptr(16)) !default;
$alert-padding-x:                   rem(ptr(16)) !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                $border-width !default;

$alert-bg-level:                    -10 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 6 !default;


// Progress bars

$progress-height:                   1rem !default;
$progress-font-size:                rem(ptr(16)) !default;
$progress-bg:                       $neutral-200 !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   theme-color("primary") !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;

// List group


$list-group-head-height:            rem(ptr(43)) !default;
$list-group-head-height-sm:         rem(ptr(33)) !default;

$list-group-color:                  $neutral-800 !default;
$list-group-contextual-color:       $black !default;

$list-group-bg:                     $white !default;
$list-group-border-color:           $neutral-400 !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:         1.25rem !default;

$list-group-hover-bg:               $theme-50 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $black-alpha-30 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $neutral-800 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $white !default;
$list-group-action-active-bg:       $component-active-bg !default;


// Image thumbnails

$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            $neutral-300 !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) !default;


// Figures

$figure-caption-font-weight:        $font-weight-light !default;
$figure-caption-font-size:          rem(ptr(15)) !default;
$figure-caption-color:              $neutral-600 !default;


// Breadcrumbs

$breadcrumb-padding-top:            rem(ptr(11)) !default;
$breadcrumb-padding-bottom:         rem(ptr(10)) !default;
$breadcrumb-padding-x:              1.125rem !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          0 !default;

$breadcrumb-bg:                     $body-bg !default;
$breadcrumb-divider-color:          $neutral-400 !default;
$breadcrumb-hover-color:            $theme-600 !default;
$breadcrumb-active-color:           $black !default;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          0 !default;


// Carousel

$carousel-control-color:            $white !default;
$carousel-control-width:            15% !default;
$carousel-control-opacity:          .5 !default;

$carousel-indicator-width:          30px !default;
$carousel-indicator-height:         3px !default;
$carousel-indicator-spacer:         3px !default;
$carousel-indicator-active-bg:      $white !default;

$carousel-caption-width:            70% !default;
$carousel-caption-color:            $white !default;

$carousel-control-icon-width:       20px !default;

$carousel-control-prev-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition:               transform .6s ease !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Close

$close-font-size:                   $font-size-base * 1.5 !default;
$close-font-weight:                 $font-weight-bold !default;
$close-color:                       $black !default;
$close-text-shadow:                 0 1px 0 $white !default;

// Code
$code-padding-y:                    rem(ptr(2)) !default;
$code-padding-x:                    rem(ptr(6)) !default;
$code-font-size:                    87.5% !default;
$code-color:                        $danger-700 !default;
$code-bg:                           $neutral-200 !default;
$code-border-radius:                $border-radius !default;

$kbd-padding-y:                     .2rem !default;
$kbd-padding-x:                     .4rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $white !default;
$kbd-bg:                            $neutral-900 !default;

$pre-color:                         $neutral-900 !default;
$pre-scrollable-max-height:         340px !default;

// Printing
$print-page-size:                   a3 !default;
$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;

// XRX Brand Header
$brand-header-rem-height: ptr(64) !default;

// Checkbox and Radio
//checkbox unckecked
$theme-uchkd-static:                $neutral-600 !default;
$theme-uchkd-hover:                 $theme-600 !default;
$theme-uchkd-active:                $theme-800 !default;
//checkbox ckecked
$theme-chkd-static:                 $theme-500 !default;
$theme-chkd-hover:                  $theme-600 !default;
$theme-chkd-active:                 $theme-800 !default;
//favorites unckecked
$favorites-uchkd-static:                 $neutral-600 !default;
$favorites-uchkd-hover:                  $warning-500 !default;
$favorites-uchkd-active:                 $warning-600 !default;
//favorites ckecked
$favorites-chkd-static:                  $warning-400 !default;
$favorites-chkd-hover:                   $warning-500 !default;
$favorites-chkd-active:                  $warning-600 !default;
//checkbox disabled
$checkbox-radio-disabled:           $black-alpha-30 !default;
$checkbox-radio-disabled-label:     $black-alpha-30 !default;

$radio-sm-size:            rem(ptr(20)) !default;
$radio-default-size:       rem(ptr(24)) !default;

$checkbox-sm-size:            rem(ptr(20)) !default;
$checkbox-default-size:       rem(ptr(24)) !default;


// switch
$switch-label-color: $neutral-800 !default;

$switch-thumb-label-bg: $neutral-50 !default;

$switch-on-alert-theme: $warning-500 !default;
$switch-on-alert-border-color: $switch-on-alert-theme !default;
$switch-on-alert-bg-color: $switch-on-alert-theme !default;
$switch-on-alert-label-bar-bg: $switch-on-alert-theme !default;
$switch-on-alert-label-bs: 1px 0 0 0 $switch-on-alert-theme !default;

$switch-on-theme: $theme-500 !default;
$switch-on-border-color: $switch-on-theme !default;
$switch-on-bg-color: $switch-on-theme !default;
$switch-on-label-bar-bg: $switch-on-theme !default;

$switch-off-theme: $theme-500 !default;
$switch-off-border-color: $switch-off-theme !default;
$switch-off-bg-color: fade-out($switch-off-theme, .85) !default;
$switch-off-label-bar-bg: $switch-off-theme !default;
$switch-off-label-bs: 1px 0 0 0 $switch-off-theme !default;

$switch-disabled-theme: $black-alpha-30 !default;
$switch-disabled-border-color: $switch-disabled-theme !default;
$switch-disabled-bg-color: fade-out($switch-disabled-theme, .85) !default;
$switch-disabled-label-bar-bg: $switch-disabled-theme !default;
$switch-on-disabled-label-bs: 0 0 0 1px $switch-disabled-theme !default;
$switch-off-disabled-label-bs: 1px 0 0 0 $switch-disabled-theme !default;

// dark-appearance - dark mode
$dark-appearance: $neutral-800 !default;

// dark-appearance - theme background on dark mode
$dark-theme-appearance: $theme-700 !default;

// theme-appearance - theme background on light mode
$light-theme-appearance: $theme-600 !default;
