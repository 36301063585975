// stylelint-disable
// global navbar styles
// body {
#content {
  padding-top: rem(ptr(64));
}

.xrx-navbar {
  a {
    text-decoration: none;
  }

  .dn-level1,
  .dn-level2,
  .dn-level3,
  .mn-level1,
  .mn-level2,
  .mn-level3 {
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      outline: none;
    }
  }
}

.dn-menu-item-label,
.mn-menu-item-label {
  position: relative;
  font-size: rem(ptr(20));
  letter-spacing: .15px;
  color: $black;
  text-transform: capitalize;
}

.dn-level1-item-glyph,
.dn-level2-item-glyph,
.dn-level3-item-glyph,
.mn-level1-item-glyph,
.mn-level2-item-glyph,
.mn-level3-item-glyph {
  display: none;
}

/* ==============================================================================================
   ** SECTION navbar styles (Desktop)
============================================================================================== */

// .dn-navbar-nav {
//   width: 100%;
//   height: rem(ptr(64));
//   background-color: $theme-600;
//   .dn-active-pathway {
//     background-color: $white-alpha-10;
//   }
// }

.dn-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-xrx-desktop-header;
  display: none;
  height: rem(ptr(64));
  background-color: $white;
  box-shadow: $shadow-light-03;
}

.dn-inner {
  position: relative;
  font-size: 0;
}

.dn-menu {
  position: relative;
  float: left;
}

.dn-app-brand {
  display: inline-flex;
  align-items: center;
  height: rem(ptr(64));
  padding: 0 rem(ptr(16));
  float: left;

  .dn-brand-img {
    width: rem(ptr(100));
  }

  .dn-app-brand-label {
    padding-left: rem(ptr(16));
    font-size: rem(ptr(20));
    font-weight: $font-weight-bold;
    letter-spacing: .25px;
    color: $black;
  }
}

.dn-brand-img-reverse {
  display: none;
}

.dn-dropdown-menu {
  padding: 0;
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 0;
  @include xrx-border-bottom-radius(rem(ptr(7)));
  opacity: 1 !important;
  transition: none;
}

// level1
.dn-menu-item a {
  display: flex;
  align-items: center;
  height: rem(ptr(64));
  padding: rem(ptr(1)) rem(ptr(16)) 0 rem(ptr(16));
  color: $black;
  transition: background-color .5s;

  &:focus {
    box-shadow: inset 0 0 0 3px $theme-a1, inset 0 0 0 6px rgba($theme-a1, .2);
  }
}

.dn-level1-item {
  float: left;
}

.dn-level1-item > a {
  &:hover {
    background-color: $neutral-100;
    transition: none;
  }

  &:active {
    background-color: $neutral-200;
    transition: none;
  }

  &.dn-indicator {
    // selected styles
    color: $theme-500;
    background-color: $theme-50 !important;
    transition: background-color .5s;

    .dn-menu-item-label {
      color: $theme-500;
    }
  }
}

// level1-item dropdown
.dn-dropdown-toggle::after {
  // hide bootstrap's dropdown caret
  display: none;
}

.dn-dropdown-btn-caret {
  margin-left: rem(ptr(8));
  margin-right: rem(ptr(-6));
}

// level2
.dn-level2 {
  background-color: transparent;
}

.dn-level2.dn-open-left .dn-level3 {
  right: auto;
  left: 0;
}

.dn-open-left {
  right: 0;
  left: auto;
}

.dn-level2-item {
  & > a {
    display: flex;
    align-items: center;
    height: rem(ptr(48));
    padding: 0 rem(ptr(10)) 0 rem(ptr(16));
    background-color: rgba($neutral-25, .95);
    transition: background-color .5s;
  }

  // & > a:focus,
  & > a:hover,
  & > .maintain-hover {
    background-color: $neutral-100;
    transition: none;
  }

  // & > a:focus,
  & > a:active:not([aria-haspopup]) {
    background-color: $neutral-200;
  }

  & > a > .xgl-sort_right {
    margin-left: auto; // flex item
  }

  & > a.selected {
    color: $white;
    background-color: $theme-500;

    .dn-menu-item-label {
      color: $white;
    }
  }
}

// level3
.dn-level3 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
}

.dn-level3-item {
  & > a {
    display: flex;
    align-items: center;
    height: rem(ptr(48));
    padding: 0 rem(ptr(16));
    background-color: rgba($neutral-50, .95);
    transition: background-color .5s;
  }

  // & > a:focus,
  & > a:hover {
    background-color: $neutral-100;
    transition: none;
  }

  & > a:active {
    background-color: $neutral-200;
  }

  & > a.selected {
    color: $white;
    background-color: $theme-500;

    .dn-menu-item-label {
      color: $white;
    }
  }
}

.nul-secondary-utility {
  float: right;
  display: flex;
  align-items: center;
  height: rem(ptr(64));

  .nul-secondary-utility-btn {
    margin-right: rem(ptr(8));
  }
}

/* ==============================================================================================
   ** SECTION navbar styles (Mobile)
============================================================================================== */

.mn-header {
  position: relative;
  height: rem(ptr(64));
  background-color: $white;
  box-shadow: $shadow-light-03;
}

.mn-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-xrx-mobile-header;
}

.mn-header-left-obj {
  display: flex;
  align-items: center;
  height: rem(ptr(64));
  float: left;
}

.mn-header-open-btn {
  padding: 0 rem(ptr(8));
}

.mn-header-close-btn {
  display: flex;
  align-items: center;
  height: rem(ptr(64));
  padding: 0 rem(ptr(8));
}

.mn-navbar-container {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: rem(ptr(-310));
  z-index: $zindex-xrx-mobile-nav;
  width: rem(ptr(300));
  min-height: 100%;
  background-color: $neutral-25;
  transition: left .25s;

  &.show {
    left: 0;
  }
}

.mn-inner,
.mn-navbar-nav {
  position: relative;
  min-height: 100%;
}

.mn-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: rem(ptr(300));
  padding-bottom: rem(ptr(64));
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-right: 1px solid $neutral-100;
}

.mn-container::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

.mn-footer {
  position: absolute;
  bottom: rem(ptr(-64));
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: rem(ptr(64));
  line-height: rem(ptr(64));
  background-color: transparent;

  .mn-brand-img {
    width: rem(ptr(100));
    font-size: 0;
  }
}

.mn-backdrop {
  position: fixed;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  z-index: $zindex-xrx-mobile-nav-backdrop;
  background-color: $black;
  opacity: 0;
  transition: opacity .25s;

  &.show {
    right: 0;
    opacity: .35;
  }

  &.fade {
    opacity: 0;
  }
}

.mn-app-brand {
  display: inline-flex;
  align-items: center;
  height: rem(ptr(64));

  .mn-app-brand-label {
    font-size: rem(ptr(20));
    font-weight: $font-weight-bold;
    letter-spacing: .25px;
    color: $black;
  }
}

.mn-brand-img-reverse {
  display: none;
}

.mn-menu-item a {
  display: flex;
  align-items: center;
  height: rem(ptr(56));
  padding: 0 rem(ptr(15)) 0 rem(ptr(16));
  color: $black;
  transition: background-color .5s;

  @media (hover) {
    &:hover {
      background-color: $neutral-100;
      transition: none !important;
    }
  }

  &:active {
    background-color: $neutral-200;
    transition: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 3px $theme-a1, inset 0 0 0 6px rgba($theme-a1, .2);
  }
}

.mn-collapse-target-caret {
  top: 1px;
  margin-left: 8px;
}

.mn-level1 {
  position: relative;
}

.mn-menu-item-label {
  top: rem(ptr(1));
}

// level1

.mn-level1-item {
  position: relative;
  .custom-icon {
    width: 36px;
    margin: 10px 16px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.08);
  }
}

.mn-navbar-container.collapsing,
.mn-navbar-container.collapsed {
  .mn-level2-item > a {
    // padding: 0;
    // margin: 0 22px;
    padding-left: 22px;
  }
}

// level2
.mn-level2-item {
  position: relative
}

/* ==============================================================================================
   ** SECTION Nav Options
============================================================================================== */

// OPTION .nav-opt-glyphs
.nav-opt-glyphs {

  .dn-level1-item-glyph,
  .dn-level2-item-glyph,
  .dn-level3-item-glyph,
  .mn-level1-item-glyph,
  .mn-level2-item-glyph,
  .mn-level3-item-glyph {
    display: table-cell;
  }

  .dn-level1-item-glyph {
    margin-right: rem(ptr(16));
  }

  .dn-level2-item-glyph,
  .dn-level3-item-glyph {
    margin-right: rem(ptr(16));
  }

  .mn-level1-item-glyph,
  .mn-level2-item-glyph,
  .mn-level3-item-glyph {
    margin-right: rem(ptr(16));
  }

}

// OPTION .nav-opt-indent
.nav-opt-indent {
  .mn-level2-item > a {
    padding-left: rem(ptr(32));
  }

  .mn-level3-item > a {
    padding-left: rem(ptr(48));
  }
}

.nav-drawer-minimize-toggle {
  display: none;
}

// OPTION .nav-opt-minimize
.nav-opt-minimize {

  .nav-drawer-minimize-toggle {
    display: block;
    position: absolute;
    bottom: -64px;
    width: 100%;
    height: 64px;
    text-align: right;
  }

  .nav-drawer-minimize-inner {
    position: relative;
    // display: inline-block;
    display: block;
    // cursor: pointer;
    // padding: 8px 14px 16px 7px;
    padding: 16px;
  }

  .mn-menu-item-label {
    white-space: nowrap;
  }
}


// OPTION .nav-opt-ext -- navs sit on their own row below the brand
.nav-opt-ext {
  #content {
    padding-top: rem(ptr(112));
  }

  .dn-header {
    height: rem(ptr(112));
  }

  .dn-menu-container {
    position: absolute;
    top: rem(ptr(64));
    left: 0;
    display: inline-block;
    width: 100%;
    margin-left: auto;
    background-color: $white;

    .dn-level1-item > a {
      height: rem(ptr(48));
    }
  }
}

// OPTION .nav-opt-left-navbar
.nav-opt-left-navbar {
  $left-nav-header-margin-left: ptr(300);
  margin-left: rem($left-nav-header-margin-left);

  .dn-menu {
    display: none;
  }

  .mn-inner {
    padding-top: 16px;
  }

  .mn-close-btn {
    visibility: hidden;
    outline: none;
  }

  .mn-navbar-container {
    display: block !important;
  }

  .mn-container {
    padding-bottom: 64px;
  }

  .mn-footer {
    display: none;
  }

  .xrx-footer {
    margin-left: rem($left-nav-header-margin-left);
  }
}

// OPTION .nav-opt-ds-go
.nav-opt-ds-go {
  $space: ptr(128) !default;
  .mn-level1-item > a {
    padding: 0;
    transition: padding .25s, margin .25s, background-color .5s;;
  }
  .mn-level2-item > a {
    padding-left: rem(ptr(40));
    transition: padding .25s, margin .25s, background-color .5s;;
  }
  .mn-level2-item-glyph {
    top: 0rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0;
    padding-right: rem(ptr(40));
  }
  .mn-container {
    padding-bottom: rem($space);
  }
  .nav-drawer-minimize-toggle {
    bottom: 0 !important;
    height: rem($space);
    background-color: #fafaff;
    border-top: 1px solid #dbdbe3;
    border-right: 1px solid #dbdbe3;
  }
  .nav-collapse-btn {
    // position: absolute;
    // right: 0;
    // margin-right: 16px;
    float: right;
  }
  .new-collection-btn {
    margin-bottom: 16px;
    min-width: 40px;
  }
  @media (max-width: 768px) {
    .new-collection-btn {
      margin-bottom: 0;
    }
    .nav-drawer-minimize-toggle {
      height: rem(ptr(73));
    }
    .mn-container {
      padding-bottom: rem(ptr(73));
    }
  }
  .new-collection {
    height: 0px;
    transition: height .25s linear;
  }
  .new-collection > a {
    opacity: 0;
    transition: opacity .25s linear;
  }
  .new-collection.animate-height {
    height: 56px;
  }
  .new-collection.fade-in > a {
    opacity: 1;
  }
}

/* ==============================================================================================
   ** SECTION media queries
============================================================================================== */
@include media-breakpoint-up(lg) {

  .xrx-brand-header .content,
  .dn-navbar-nav .dn-inner {
    max-width: 970px;
  }
}

// @include media-breakpoint-up(md) {
@media (min-width: 769px) {

  // show desktop nav 768px and above
  .dn-header {
    // left navbar
    display: block !important;
  }

  // hide mobile nav 768px and above
  .mn-header,
  .mn-navbar-container,
  .mn-backdrop {
    display: none;
  }

  .nav-opt-left-navbar {
    .mn-navbar-container {
      left: 0;
    }
  }
}

// @include media-breakpoint-down(sm) {
@media (max-width: 768px) {
  body {
    // padding-top: rem(ptr(64)) !important;
    margin-left: 0 !important;
  }

  #content {
    padding-top: rem(ptr(64)) !important;
  }

  .mn-inner {
    padding-top: 0 !important;
  }

  .mn-backdrop {
    display: block !important;
  }

  .mn-close-btn {
    visibility: visible !important;
  }

  .mn-navbar-container {
    padding-top: 0 !important;
    // background-color: $neutral-25;
    box-shadow: $shadow-light-04;
  }

  .mn-container {
    border-right: none;
  }

  .mn-footer {
    display: flex !important;
  }

  .nav-collapse-btn,
  .nav-drawer-minimize-toggle {
    display: none;
  }

  .new-collection-btn {
    margin-bottom: 0;
  }

  .xrx-footer {
    margin-left: 0 !important;
  }
}

/* ==============================================================================================
   ** SECTION sliding indicator
============================================================================================== */

.dn-sliding-indicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: $theme-500;

  &.animate {
    transition: left .25s, width .25s;
  }
}

.mn-level2-sliding-indicator,
.mn-level3-sliding-indicator,
.mn-global-sliding-indicator {
  position: absolute;
  right: 0;
  width: 4px;
  background-color: $theme-500;

  &.animate {
    transition: top .25s;
  }

  visibility: hidden;

  &.show {
    visibility: visible;
  }
}

.mn-indicator {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4px;
    background-color: $theme-500;
    visibility: hidden;
  }

  &.mn-indicator-show::after {
    content: '';
    visibility: visible;
  }
}

.mn-indicator {
  // selected styles
  color: $theme-500 !important;
  background-color: $theme-50 !important;
  transition: padding .25s, margin .25s, width .15s, background-color .5s !important;

  // transition: background-color .5s, color .5s;
  // border-bottom: 4px solid $theme-500;
  .mn-menu-item-label {
    color: $theme-500;
  }
}

.dn-dropdown.show {
  position: relative;
}

.transition-off {
  transition-property: none !important;
}

// keyboard tab focus override
.using-mouse {

  .dn-menu-item a,
  .mn-menu-item a {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

/* ==============================================================================================
   ** SECTION sliding indicator
============================================================================================== */

.xrx-dark-appearance {
  .dn-header {
    background-color: $dark-appearance;
    box-shadow: $shadow-dark-03;

    .dropdown-toggle {
      background-color: transparent;
    }
  }

  .dn-brand-img-default {
    display: none;
  }

  .dn-brand-img-reverse {
    display: inline;
  }

  .dn-app-brand-label {
    color: $white;
  }

  .dn-sliding-indicator {
    background-color: $theme-200;
  }

  .dn-level1-item > a {

    &,
    .dn-menu-item-label {
      color: $white;
    }

    &:hover {
      background-color: $theme-900;
    }

    &:active {
      background-color: $theme-1000;
    }

    &.dn-indicator {
      // selected styles
      background-color: $theme-900 !important;
      color: $theme-200;

      .dn-menu-item-label {
        color: $theme-200;
      }
    }
  }

  .dn-level2-item,
  .dn-level3-item {
    & > a {
      color: $white;
      .dn-menu-item-label {
        color: $white;
      }
    }

    // & > a:focus,
    & > a:hover {
      background-color: $neutral-900;
    }

    // & > a:focus,
    & > a:active:not([aria-haspopup]) {
      background-color: $neutral-1000;
    }

    & > a.selected {
      color: $black;
      background-color: $theme-200;

      .dn-menu-item-label {
        color: $black;
      }
    }
  }

  .dn-level2-item {
    & > a {
      background-color: rgba($neutral-700, .95);
    }
    & > .maintain-hover {
      background-color: $neutral-900;
    }
  }

  .dn-level3-item > a {
    background-color: rgba($neutral-800, .95);
  }

  .mn-navbar-container {
    background-color: $neutral-700;
  }
  .mn-container {
    border-right: 1px solid $neutral-900;
  }
  .mn-header {
    background-color: $dark-appearance;
    box-shadow: $shadow-dark-03;
  }
  .mn-app-brand .mn-app-brand-label {
    color: $white;
  }
  .mn-menu-item a {
    color: $white;
  }
  @media (hover) {
    .mn-menu-item a:hover {
      background-color: $neutral-900;
    }
  }
  .mn-menu-item a:active {
    background-color: $neutral-1000;
  }
  .mn-menu-item-label {
    color: $white;
  }
  .mn-indicator {
    // selected styles
    color: $theme-200 !important;
    background-color: $theme-900 !important;
    .mn-menu-item-label {
      color: $theme-200;
    }
    &::after {
      background-color: $theme-200;
    }
  }
  .mn-level2-sliding-indicator,
  .mn-level3-sliding-indicator,
  .mn-global-sliding-indicator {
    background-color: $theme-200;
  }
  .mn-brand-img-default {
    display: none;
  }
  .mn-brand-img-reverse {
    display: inline;
  }
}

.xrx-theme-appearance {
  &.dn-header {
    box-shadow: $shadow-light-03;
  }

  .dn-brand-img-default {
    display: none;
  }

  .dn-brand-img-reverse {
    display: inline;
  }

  .dn-app-brand-label {
    color: $white;
  }

  .dn-sliding-indicator {
    background-color: $white;
  }

  .dn-level1-item > a {

    &,
    .dn-menu-item-label {
      color: $white;
    }

    &:hover {
      background-color: $theme-800;
    }

    &:active {
      background-color: $theme-900;
    }

    &.dn-indicator {
      // selected styles
      background-color: $theme-700 !important;

      .dn-menu-item-label {
        color: $white;
      }
    }
  }

  .dn-menu-container {
    background-color: $light-theme-appearance;
  }

  .mn-app-brand .mn-app-brand-label {
    color: $white;
  }

  &.mn-header {
    box-shadow: $shadow-light-03;
  }
}

.xrx-dark-appearance .xrx-theme-appearance {
  .dn-header,
  .dn-menu-container {
    background-color: $dark-theme-appearance;
  }

  .dn-level1-item > a {

    &:hover {
      background-color: $theme-900;
    }

    &:active {
      background-color: $theme-1000;
    }

    &.dn-indicator {
      // selected styles
      background-color: $theme-800 !important;
      color: $white;

      .dn-menu-item-label {
        color: $white;
      }
    }
  }

}

// stylelint-enable
