// stylelint-disable

// The dropdown wrapper (`<div>`)
.xrx-dropdown {
  position: relative;
}

// .xrx-btn.dropdown-toggle.btn-caret-right {
//   justify-content: space-between;
// }

// The dropdown menu
.dropdown-menu {
  position: absolute;
  z-index: $zindex-dropdown;
  visibility: hidden;
  opacity: 0;
  margin-top: -8px !important;
  transition: visibility .165s linear, opacity .165s linear, margin-top .165s linear, margin-bottom .165s linear, margin-left .165s linear, margin-right .165s linear;
  min-width: $dropdown-min-width;
  color: $body-color;
  list-style: none;
  background-color: rgba($neutral-25, .95);
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: $shadow-light-04;

  a.list-group-item {
    text-decoration: none !important;
    .left-label {
      padding-right: 16px;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .menu-scroll-container {
    border-radius: 6px;
  }

}

.menu-divider {
  height: 1px;
  background-color: rgba($neutral-500, .2);
}

// dropdown menu arrow
.dropdown-menu-arrow,
.dropdown-menu-arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: -1;
  border-radius: 2px;
}

.dropdown-menu-arrow::before {
  content: '';
  transform: rotate(45deg);
  background: rgba($neutral-25, .94);
}

[data-popper-placement^='top'] > .dropdown-menu-arrow {
  bottom: -5px;
}

[data-popper-placement^='bottom'] > .dropdown-menu-arrow {
  top: -5px;
}

[data-popper-placement^='left'] > .dropdown-menu-arrow {
  right: -5px;
}

[data-popper-placement^='right'] > .dropdown-menu-arrow {
  left: -5px;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    margin-bottom: -8px !important;
  }
}

.dropright {
  .dropdown-menu {
    margin-top: 0px !important;
    margin-left: -8px !important;
  }
}

.dropleft {
  .dropdown-menu {
    margin-top: 0px !important;
    margin-right: -8px !important;
  }
}

// When enabled Popper.js, reset basic dropdown position
// .dropdown-menu {
//   &[x-placement^="top"],
//   &[x-placement^="right"],
//   &[x-placement^="bottom"],
//   &[x-placement^="left"] {
//     // right: auto;
//     // bottom: auto;
//   }
// }

.dropdown-menu.show {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  visibility: visible;
  opacity: 1;
}

.xrx-dark-appearance {
  .dropdown-menu {
    background-color: rgba($neutral-700, .94);
  }

  .dropdown-menu-arrow::before {
    content: '';
    background: rgba($neutral-700, .94);
  }

  .menu-divider {
    background-color: rgba($neutral-200, .2);
  }
}


//
// anchored menu
//

.anchored-menu {
  margin-top: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  clip-path: inset(0px -25px -25px -25px);
  transition: visibility .25s, opacity .25s, margin-top 0s;

  .dropdown-menu-arrow {
    display: none;
  }

  .menu-scroll-container,
  .xrx-list-group,
  .list-group-header {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

}

// dropdownin a listgroup
.xrx-list-group .xrx-dropdown {
  margin-right: 16px;
   .list-group-item:not(.divider) .content-container:before {
    border: none;
  }
}

// stylelint-enable
