@charset "UTF-8";
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.cb {
  position: relative;
  display: block;
  padding: 0.5rem;
}

:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto Condensed", Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  letter-spacing: .15px;
  background-color: #f2f2f9;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #155fbb;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: color .25s;
}

a:hover, a:focus {
  color: #002760;
  text-decoration: underline;
  outline: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

ul a,
ol a,
p a {
  text-decoration: underline;
}

.bg-primary a {
  color: #ffffff;
}

.bg-primary a:hover, .bg-primary a:focus {
  color: #002760;
}

.chevron-glyph::after {
  position: relative;
  top: 0.375rem;
  display: inline-block;
  padding-left: 0.5rem;
  margin-right: -0.1875rem;
  margin-left: -0.1875rem;
  font-family: xglyphregular;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-decoration: none !important;
  content: "\E30B";
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #525256;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: thin dotted #333;
  outline-offset: 1px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

legend.legend-sm {
  font-size: 1.125rem;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .h7 {
  margin-bottom: 1.0625rem;
  font-family: inherit;
  line-height: 1.1;
  color: #000000;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 2.125rem;
}

h4, .h4,
h5, .h5,
h6, .h6,
.h7 {
  margin-top: 1.0625rem;
}

h1, .h1 {
  font-size: 3.75rem;
  letter-spacing: −1 0.5px;
}

h2, .h2 {
  font-size: 3rem;
  letter-spacing: −0 0.5px;
}

h3, .h3 {
  font-size: 2.125rem;
  letter-spacing: 0px;
}

h4, .h4 {
  font-size: 1.75rem;
  letter-spacing: .25px;
}

h5, .h5 {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

h6, .h6 {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

.h7 {
  font-size: 1.25rem;
  letter-spacing: .25px;
}

h1, .h1,
h2, .h2 {
  font-weight: 300;
}

h3, .h3,
h4, .h4,
h6, .h6 {
  font-weight: 400;
}

h5, .h5,
.h7 {
  font-weight: 700;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  color: #525256;
}

small,
.small {
  font-size: 89%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.5625rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #525256;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.bg-primary {
  color: #ffffff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border .2s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.img-circle {
  border-radius: 50%;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f2f2f9;
  border: 1px solid #9e9fa4;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.photo-comment,
.figure-caption {
  font-size: 0.9375rem;
  font-weight: 300;
  color: #525256;
}

code {
  padding: 0.125rem 0.375rem;
  font-size: 87.5%;
  color: #9f0b14;
  word-break: break-word;
  background-color: #bbbcc3;
  border-radius: 0.25rem;
}

a > code {
  color: inherit;
}

.command-line {
  font-family: Courier, monospace;
  color: #fff;
  background-color: #000;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #1b1b1e;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #1b1b1e;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.close {
  float: right;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #155fbb !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #10488d !important;
}

.bg-secondary {
  background-color: #525256 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #39393c !important;
}

.bg-success {
  background-color: #24882b !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #19601e !important;
}

.bg-info {
  background-color: #177896 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #10556a !important;
}

.bg-warning {
  background-color: #fd9726 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ee7e02 !important;
}

.bg-danger {
  background-color: #d92231 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad1b27 !important;
}

.bg-light {
  background-color: #dbdbe3 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #bebecd !important;
}

.bg-dark {
  background-color: #29292d !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #111112 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #9e9fa4 !important;
}

.border-top {
  border-top: 1px solid #9e9fa4 !important;
}

.border-right {
  border-right: 1px solid #9e9fa4 !important;
}

.border-bottom {
  border-bottom: 1px solid #9e9fa4 !important;
}

.border-left {
  border-left: 1px solid #9e9fa4 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #155fbb !important;
}

.border-secondary {
  border-color: #525256 !important;
}

.border-success {
  border-color: #24882b !important;
}

.border-info {
  border-color: #177896 !important;
}

.border-warning {
  border-color: #fd9726 !important;
}

.border-danger {
  border-color: #d92231 !important;
}

.border-light {
  border-color: #dbdbe3 !important;
}

.border-dark {
  border-color: #29292d !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #155fbb !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #10488d !important;
}

.text-secondary {
  color: #525256 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #39393c !important;
}

.text-success {
  color: #24882b !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19601e !important;
}

.text-info {
  color: #177896 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #10556a !important;
}

.text-warning {
  color: #fd9726 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ee7e02 !important;
}

.text-danger {
  color: #d92231 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad1b27 !important;
}

.text-light {
  color: #dbdbe3 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bebecd !important;
}

.text-dark {
  color: #29292d !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #111112 !important;
}

.text-body {
  color: #000000 !important;
}

.text-muted {
  color: #525256 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #67676c;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #9e9fa4 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #818187;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #818187;
  }
}

[class*="xrx-icon-"] {
  box-sizing: content-box;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
  cursor: default;
}

.xrx-icon-disabled {
  opacity: 0.35;
  box-shadow: none;
}

.xrx-icon-sm {
  border-radius: 6px;
}

.xrx-icon-lg {
  border-radius: 12px;
}

.xrx-icon-xl {
  border-radius: 16px;
}

@font-face {
  font-family: xglyphregular;
  font-style: normal;
  font-weight: 400;
  src: url("../../../fonts/xglyph-regular.eot");
  src: url("../../../fonts/xglyph-regular.woff2") format("woff2"), url("../../../fonts/xglyph-regular.woff") format("woff"), url("../../../fonts/xglyph-regular.ttf") format("truetype");
}

[class*="xgl-"] {
  position: relative;
  top: 0.0625rem;
  display: table-cell;
  padding: 0;
  margin: 0;
  font-family: xglyphregular;
  font-size: 0;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 0;
  speak: none;
  text-transform: none;
  background: none;
  border: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xglsize-12 {
  top: 0rem;
}

.xglsize-12::before {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.xglsize-14 {
  top: 0rem;
}

.xglsize-14::before {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.xglsize-16 {
  top: 0rem;
}

.xglsize-16::before {
  font-size: 1rem;
  line-height: 1rem;
}

.xglsize-18 {
  top: 0rem;
}

.xglsize-18::before {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.xglsize-20 {
  top: 0rem;
}

.xglsize-20::before {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.xglsize-24 {
  top: 0rem;
}

.xglsize-24::before {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.xglsize-28::before {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.xglsize-32::before {
  font-size: 2rem;
  line-height: 2rem;
}

.xglsize-36::before {
  margin-right: -0.0625rem;
  margin-left: -0.0625rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.xglsize-48::before {
  margin-right: -0.0625rem;
  margin-left: -0.0625rem;
  font-size: 3rem;
  line-height: 3rem;
}

.xglsize-64::before {
  margin-right: -0.125rem;
  margin-left: -0.125rem;
  font-size: 4rem;
  line-height: 4rem;
}

[class*="_two_tone"]::before, [class*="_two_tone"]::after {
  position: absolute;
  left: 0;
}

[class*="_two_tone"].xglsize-12 {
  top: 0rem;
  width: 0.75rem;
  height: 0.75rem;
}

[class*="_two_tone"].xglsize-12::before, [class*="_two_tone"].xglsize-12::after {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

[class*="_two_tone"].xglsize-14 {
  top: 0rem;
  width: 0.875rem;
  height: 0.875rem;
}

[class*="_two_tone"].xglsize-14::before, [class*="_two_tone"].xglsize-14::after {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

[class*="_two_tone"].xglsize-16 {
  top: 0rem;
  width: 1rem;
  height: 1rem;
}

[class*="_two_tone"].xglsize-16::before, [class*="_two_tone"].xglsize-16::after {
  font-size: 1rem;
  line-height: 1rem;
}

[class*="_two_tone"].xglsize-18 {
  top: 0rem;
  width: 1.125rem;
  height: 1.125rem;
}

[class*="_two_tone"].xglsize-18::before, [class*="_two_tone"].xglsize-18::after {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

[class*="_two_tone"].xglsize-20 {
  top: 0rem;
  width: 1.25rem;
  height: 1.25rem;
}

[class*="_two_tone"].xglsize-20::before, [class*="_two_tone"].xglsize-20::after {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

[class*="_two_tone"].xglsize-24 {
  top: 0rem;
  width: 1.5rem;
  height: 1.5rem;
}

[class*="_two_tone"].xglsize-24::before, [class*="_two_tone"].xglsize-24::after {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

[class*="_two_tone"].xglsize-28 {
  width: 1.75rem;
  height: 1.75rem;
}

[class*="_two_tone"].xglsize-28::before, [class*="_two_tone"].xglsize-28::after {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

[class*="_two_tone"].xglsize-32 {
  width: 2rem;
  height: 2rem;
}

[class*="_two_tone"].xglsize-32::before, [class*="_two_tone"].xglsize-32::after {
  font-size: 2rem;
  line-height: 2rem;
}

[class*="_two_tone"].xglsize-48 {
  width: 2.875rem;
  height: 3rem;
}

[class*="_two_tone"].xglsize-48::before, [class*="_two_tone"].xglsize-48::after {
  margin-right: -0.0625rem;
  margin-left: -0.0625rem;
  font-size: 3rem;
  line-height: 3rem;
}

[class*="_two_tone"].xglsize-64 {
  width: 3.75rem;
  height: 4rem;
}

[class*="_two_tone"].xglsize-64::before, [class*="_two_tone"].xglsize-64::after {
  margin-right: -0.125rem;
  margin-left: -0.125rem;
  font-size: 4rem;
  line-height: 4rem;
}

.xgl-accent_menu::before {
  content: '\E91F';
}

.xgl-accent_menu_alt::before {
  content: '\E920';
}

.xgl-accent_menu_two_tone::before {
  content: '\E91F';
}

.xgl-accent_menu_two_tone::after {
  content: '\E920';
  opacity: .2;
}

.xgl-accessibility::before {
  content: '\E000';
}

.xgl-accessibility_alt::before {
  content: '\E001';
}

.xgl-accessibility_two_tone::before {
  content: '\E000';
}

.xgl-accessibility_two_tone::after {
  content: '\E001';
  opacity: .2;
}

.xgl-accounting::before {
  content: '\E002';
}

.xgl-accounting_alt::before {
  content: '\E003';
}

.xgl-accounting_authentication::before {
  content: '\E887';
}

.xgl-accounting_authentication_alt::before {
  content: '\E888';
}

.xgl-accounting_authentication_two_tone::before {
  content: '\E887';
}

.xgl-accounting_authentication_two_tone::after {
  content: '\E888';
  opacity: .2;
}

.xgl-accounting_auxiliary_device::before {
  content: '\E004';
}

.xgl-accounting_auxiliary_device_alt::before {
  content: '\E005';
}

.xgl-accounting_auxiliary_device_two_tone::before {
  content: '\E004';
}

.xgl-accounting_auxiliary_device_two_tone::after {
  content: '\E005';
  opacity: .2;
}

.xgl-accounting_disabled::before {
  content: '\E006';
}

.xgl-accounting_disabled_alt::before {
  content: '\E007';
}

.xgl-accounting_disabled_two_tone::before {
  content: '\E006';
}

.xgl-accounting_disabled_two_tone::after {
  content: '\E007';
  opacity: .2;
}

.xgl-accounting_local::before {
  content: '\E885';
}

.xgl-accounting_local_alt::before {
  content: '\E886';
}

.xgl-accounting_local_two_tone::before {
  content: '\E885';
}

.xgl-accounting_local_two_tone::after {
  content: '\E886';
  opacity: .2;
}

.xgl-accounting_network::before {
  content: '\E008';
}

.xgl-accounting_network_alt::before {
  content: '\E009';
}

.xgl-accounting_network_two_tone::before {
  content: '\E008';
}

.xgl-accounting_network_two_tone::after {
  content: '\E009';
  opacity: .2;
}

.xgl-accounting_two_tone::before {
  content: '\E002';
}

.xgl-accounting_two_tone::after {
  content: '\E003';
  opacity: .2;
}

.xgl-acknowledgement_report::before {
  content: '\EA2B';
}

.xgl-acknowledgement_report_alt::before {
  content: '\EA2C';
}

.xgl-acknowledgement_report_two_tone::before {
  content: '\EA2B';
}

.xgl-acknowledgement_report_two_tone::after {
  content: '\EA2C';
  opacity: .2;
}

.xgl-activation_code::before {
  content: '\EA2D';
}

.xgl-activation_code_alt::before {
  content: '\EA2E';
}

.xgl-activation_code_two_tone::before {
  content: '\EA2D';
}

.xgl-activation_code_two_tone::after {
  content: '\EA2E';
  opacity: .2;
}

.xgl-activity::before {
  content: '\E00A';
}

.xgl-activity_alt::before {
  content: '\E00B';
}

.xgl-activity_two_tone::before {
  content: '\E00A';
}

.xgl-activity_two_tone::after {
  content: '\E00B';
  opacity: .2;
}

.xgl-add_circle::before {
  content: '\E00C';
}

.xgl-add_circle_alt::before {
  content: '\E00D';
}

.xgl-add_circle_two_tone::before {
  content: '\E00C';
}

.xgl-add_circle_two_tone::after {
  content: '\E00D';
  opacity: .2;
}

.xgl-add_selection::before {
  content: '\EA80';
}

.xgl-add_selection_alt::before {
  content: '\EA81';
}

.xgl-add_selection_two_tone::before {
  content: '\EA80';
}

.xgl-add_selection_two_tone::after {
  content: '\EA81';
  opacity: .2;
}

.xgl-add_square::before {
  content: '\E00E';
}

.xgl-add_square_alt::before {
  content: '\E00F';
}

.xgl-add_square_two_tone::before {
  content: '\E00E';
}

.xgl-add_square_two_tone::after {
  content: '\E00F';
  opacity: .2;
}

.xgl-address_book_import::before {
  content: '\EA2F';
}

.xgl-address_book_import_alt::before {
  content: '\EA30';
}

.xgl-address_book_import_two_tone::before {
  content: '\EA2F';
}

.xgl-address_book_import_two_tone::after {
  content: '\EA30';
  opacity: .2;
}

.xgl-address_book_network::before {
  content: '\EA31';
}

.xgl-address_book_network_alt::before {
  content: '\EA32';
}

.xgl-address_book_network_two_tone::before {
  content: '\EA31';
}

.xgl-address_book_network_two_tone::after {
  content: '\EA32';
  opacity: .2;
}

.xgl-adhesive_lef::before {
  content: '\E010';
}

.xgl-adhesive_lef_alt::before {
  content: '\E6C2';
}

.xgl-adhesive_lef_two_tone::before {
  content: '\E010';
}

.xgl-adhesive_lef_two_tone::after {
  content: '\E6C2';
  opacity: .2;
}

.xgl-adhesive_sef::before {
  content: '\E011';
}

.xgl-adhesive_sef_alt::before {
  content: '\E6C3';
}

.xgl-adhesive_sef_two_tone::before {
  content: '\E011';
}

.xgl-adhesive_sef_two_tone::after {
  content: '\E6C3';
  opacity: .2;
}

.xgl-air_flow_high::before {
  content: '\EC99';
}

.xgl-air_flow_low::before {
  content: '\EC97';
}

.xgl-air_flow_medium::before {
  content: '\EC98';
}

.xgl-airplane::before {
  content: '\E012';
}

.xgl-airplane_alt::before {
  content: '\E013';
}

.xgl-airplane_rtl::before {
  content: '\E589';
}

.xgl-airplane_rtl_alt::before {
  content: '\E58A';
}

.xgl-airplane_rtl_two_tone::before {
  content: '\E589';
}

.xgl-airplane_rtl_two_tone::after {
  content: '\E58A';
  opacity: .2;
}

.xgl-airplane_two_tone::before {
  content: '\E012';
}

.xgl-airplane_two_tone::after {
  content: '\E013';
  opacity: .2;
}

.xgl-airprint::before {
  content: '\E014';
}

.xgl-airprint_alt::before {
  content: '\E015';
}

.xgl-airprint_two_tone::before {
  content: '\E014';
}

.xgl-airprint_two_tone::after {
  content: '\E015';
  opacity: .2;
}

.xgl-alarmclock::before {
  content: '\E016';
}

.xgl-alarmclock_alt::before {
  content: '\E017';
}

.xgl-alarmclock_two_tone::before {
  content: '\E016';
}

.xgl-alarmclock_two_tone::after {
  content: '\E017';
  opacity: .2;
}

.xgl-alert::before {
  content: '\E018';
}

.xgl-alert_all::before {
  content: '\EC23';
}

.xgl-alert_all_alt::before {
  content: '\EC25';
}

.xgl-alert_all_two_tone::before {
  content: '\EC23';
}

.xgl-alert_all_two_tone::after {
  content: '\EC25';
  opacity: .2;
}

.xgl-alert_alt::before {
  content: '\E019';
}

.xgl-alert_square::before {
  content: '\E01A';
}

.xgl-alert_square_alt::before {
  content: '\E01B';
}

.xgl-alert_square_two_tone::before {
  content: '\E01A';
}

.xgl-alert_square_two_tone::after {
  content: '\E01B';
  opacity: .2;
}

.xgl-alert_two_tone::before {
  content: '\E018';
}

.xgl-alert_two_tone::after {
  content: '\E019';
  opacity: .2;
}

.xgl-aliasing::before {
  content: '\E833';
}

.xgl-align_bottom::before {
  content: '\E01C';
}

.xgl-align_center::before {
  content: '\E01D';
}

.xgl-align_center_off::before {
  content: '\EDF8';
}

.xgl-align_dropdown::before {
  content: '\E01E';
}

.xgl-align_left::before {
  content: '\E01F';
}

.xgl-align_middle::before {
  content: '\E020';
}

.xgl-align_middle_off::before {
  content: '\EDF9';
}

.xgl-align_right::before {
  content: '\E021';
}

.xgl-align_top::before {
  content: '\E022';
}

.xgl-amazon_drive::before {
  content: '\E023';
}

.xgl-amex::before {
  content: '\EC9F';
}

.xgl-amex_alt::before {
  content: '\ECA0';
}

.xgl-amex_two_tone::before {
  content: '\EC9F';
}

.xgl-amex_two_tone::after {
  content: '\ECA0';
  opacity: .2;
}

.xgl-anchor::before {
  content: '\E9D0';
}

.xgl-android::before {
  content: '\E024';
}

.xgl-android_alt::before {
  content: '\EC8D';
}

.xgl-android_two_tone::before {
  content: '\E024';
}

.xgl-android_two_tone::after {
  content: '\EC8D';
  opacity: .2;
}

.xgl-annotate::before {
  content: '\EA82';
}

.xgl-annotate_alt::before {
  content: '\EA83';
}

.xgl-annotate_applied::before {
  content: '\E966';
}

.xgl-annotate_applied_alt::before {
  content: '\E967';
}

.xgl-annotate_applied_two_tone::before {
  content: '\E966';
}

.xgl-annotate_applied_two_tone::after {
  content: '\E967';
  opacity: .2;
}

.xgl-annotate_digits::before {
  content: '\E968';
}

.xgl-annotate_digits_alt::before {
  content: '\E969';
}

.xgl-annotate_digits_two_tone::before {
  content: '\E968';
}

.xgl-annotate_digits_two_tone::after {
  content: '\E969';
  opacity: .2;
}

.xgl-annotate_format::before {
  content: '\E96A';
}

.xgl-annotate_format_alt::before {
  content: '\E96B';
}

.xgl-annotate_format_color::before {
  content: '\E96C';
}

.xgl-annotate_format_color_alt::before {
  content: '\E96D';
}

.xgl-annotate_format_color_two_tone::before {
  content: '\E96C';
}

.xgl-annotate_format_color_two_tone::after {
  content: '\E96D';
  opacity: .2;
}

.xgl-annotate_format_style::before {
  content: '\EA35';
}

.xgl-annotate_format_style_alt::before {
  content: '\EA36';
}

.xgl-annotate_format_style_two_tone::before {
  content: '\EA35';
}

.xgl-annotate_format_style_two_tone::after {
  content: '\EA36';
  opacity: .2;
}

.xgl-annotate_format_two_tone::before {
  content: '\E96A';
}

.xgl-annotate_format_two_tone::after {
  content: '\E96B';
  opacity: .2;
}

.xgl-annotate_prefix::before {
  content: '\E970';
}

.xgl-annotate_prefix_alt::before {
  content: '\E971';
}

.xgl-annotate_prefix_two_tone::before {
  content: '\E970';
}

.xgl-annotate_prefix_two_tone::after {
  content: '\E971';
  opacity: .2;
}

.xgl-annotate_starting_number::before {
  content: '\E972';
}

.xgl-annotate_starting_number_alt::before {
  content: '\E973';
}

.xgl-annotate_starting_number_two_tone::before {
  content: '\E972';
}

.xgl-annotate_starting_number_two_tone::after {
  content: '\E973';
  opacity: .2;
}

.xgl-annotate_two_tone::before {
  content: '\EA82';
}

.xgl-annotate_two_tone::after {
  content: '\EA83';
  opacity: .2;
}

.xgl-announcement::before {
  content: '\E025';
}

.xgl-announcement_alt::before {
  content: '\E026';
}

.xgl-announcement_two_tone::before {
  content: '\E025';
}

.xgl-announcement_two_tone::after {
  content: '\E026';
  opacity: .2;
}

.xgl-app_gallery::before {
  content: '\E027';
}

.xgl-app_store::before {
  content: '\E974';
}

.xgl-apple::before {
  content: '\E028';
}

.xgl-apple_alt::before {
  content: '\EC8E';
}

.xgl-apple_two_tone::before {
  content: '\E028';
}

.xgl-apple_two_tone::after {
  content: '\EC8E';
  opacity: .2;
}

.xgl-applications::before {
  content: '\E029';
}

.xgl-applications_alt::before {
  content: '\E88B';
}

.xgl-applications_two_tone::before {
  content: '\E029';
}

.xgl-applications_two_tone::after {
  content: '\E88B';
  opacity: .2;
}

.xgl-approval::before {
  content: '\E02A';
}

.xgl-approval_alt::before {
  content: '\E02B';
}

.xgl-approval_two_tone::before {
  content: '\E02A';
}

.xgl-approval_two_tone::after {
  content: '\E02B';
  opacity: .2;
}

.xgl-approximate_estimation::before {
  content: '\EE33';
}

.xgl-approximate_estimation_alt::before {
  content: '\EE34';
}

.xgl-approximate_estimation_two_tone::before {
  content: '\EE33';
}

.xgl-approximate_estimation_two_tone::after {
  content: '\EE34';
  opacity: .2;
}

.xgl-archival_lef::before {
  content: '\E02C';
}

.xgl-archival_lef_alt::before {
  content: '\E6C4';
}

.xgl-archival_lef_two_tone::before {
  content: '\E02C';
}

.xgl-archival_lef_two_tone::after {
  content: '\E6C4';
  opacity: .2;
}

.xgl-archival_sef::before {
  content: '\E02D';
}

.xgl-archival_sef_alt::before {
  content: '\E6C5';
}

.xgl-archival_sef_two_tone::before {
  content: '\E02D';
}

.xgl-archival_sef_two_tone::after {
  content: '\E6C5';
  opacity: .2;
}

.xgl-arrow_down::before {
  content: '\E02E';
}

.xgl-arrow_down_circle::before {
  content: '\E02F';
}

.xgl-arrow_down_circle_alt::before {
  content: '\E030';
}

.xgl-arrow_down_circle_two_tone::before {
  content: '\E02F';
}

.xgl-arrow_down_circle_two_tone::after {
  content: '\E030';
  opacity: .2;
}

.xgl-arrow_down_left::before {
  content: '\E031';
}

.xgl-arrow_down_left_circle::before {
  content: '\E032';
}

.xgl-arrow_down_left_circle_alt::before {
  content: '\E033';
}

.xgl-arrow_down_left_circle_two_tone::before {
  content: '\E032';
}

.xgl-arrow_down_left_circle_two_tone::after {
  content: '\E033';
  opacity: .2;
}

.xgl-arrow_down_left_square::before {
  content: '\E034';
}

.xgl-arrow_down_left_square_alt::before {
  content: '\E035';
}

.xgl-arrow_down_left_square_two_tone::before {
  content: '\E034';
}

.xgl-arrow_down_left_square_two_tone::after {
  content: '\E035';
  opacity: .2;
}

.xgl-arrow_down_right::before {
  content: '\E036';
}

.xgl-arrow_down_right_circle::before {
  content: '\E037';
}

.xgl-arrow_down_right_circle_alt::before {
  content: '\E038';
}

.xgl-arrow_down_right_circle_two_tone::before {
  content: '\E037';
}

.xgl-arrow_down_right_circle_two_tone::after {
  content: '\E038';
  opacity: .2;
}

.xgl-arrow_down_right_square::before {
  content: '\E039';
}

.xgl-arrow_down_right_square_alt::before {
  content: '\E03A';
}

.xgl-arrow_down_right_square_two_tone::before {
  content: '\E039';
}

.xgl-arrow_down_right_square_two_tone::after {
  content: '\E03A';
  opacity: .2;
}

.xgl-arrow_down_square::before {
  content: '\E03B';
}

.xgl-arrow_down_square_alt::before {
  content: '\E03C';
}

.xgl-arrow_down_square_two_tone::before {
  content: '\E03B';
}

.xgl-arrow_down_square_two_tone::after {
  content: '\E03C';
  opacity: .2;
}

.xgl-arrow_left::before {
  content: '\E03D';
}

.xgl-arrow_left_circle::before {
  content: '\E03E';
}

.xgl-arrow_left_circle_alt::before {
  content: '\E03F';
}

.xgl-arrow_left_circle_two_tone::before {
  content: '\E03E';
}

.xgl-arrow_left_circle_two_tone::after {
  content: '\E03F';
  opacity: .2;
}

.xgl-arrow_left_right::before {
  content: '\E040';
}

.xgl-arrow_left_square::before {
  content: '\E041';
}

.xgl-arrow_left_square_alt::before {
  content: '\E042';
}

.xgl-arrow_left_square_two_tone::before {
  content: '\E041';
}

.xgl-arrow_left_square_two_tone::after {
  content: '\E042';
  opacity: .2;
}

.xgl-arrow_right::before {
  content: '\E043';
}

.xgl-arrow_right_circle::before {
  content: '\E044';
}

.xgl-arrow_right_circle_alt::before {
  content: '\E045';
}

.xgl-arrow_right_circle_two_tone::before {
  content: '\E044';
}

.xgl-arrow_right_circle_two_tone::after {
  content: '\E045';
  opacity: .2;
}

.xgl-arrow_right_square::before {
  content: '\E046';
}

.xgl-arrow_right_square_alt::before {
  content: '\E047';
}

.xgl-arrow_right_square_two_tone::before {
  content: '\E046';
}

.xgl-arrow_right_square_two_tone::after {
  content: '\E047';
  opacity: .2;
}

.xgl-arrow_up::before {
  content: '\E048';
}

.xgl-arrow_up_circle::before {
  content: '\E049';
}

.xgl-arrow_up_circle_alt::before {
  content: '\E04A';
}

.xgl-arrow_up_circle_two_tone::before {
  content: '\E049';
}

.xgl-arrow_up_circle_two_tone::after {
  content: '\E04A';
  opacity: .2;
}

.xgl-arrow_up_down::before {
  content: '\E04B';
}

.xgl-arrow_up_left::before {
  content: '\E04C';
}

.xgl-arrow_up_left_circle::before {
  content: '\E04D';
}

.xgl-arrow_up_left_circle_alt::before {
  content: '\E04E';
}

.xgl-arrow_up_left_circle_two_tone::before {
  content: '\E04D';
}

.xgl-arrow_up_left_circle_two_tone::after {
  content: '\E04E';
  opacity: .2;
}

.xgl-arrow_up_left_square::before {
  content: '\E04F';
}

.xgl-arrow_up_left_square_alt::before {
  content: '\E050';
}

.xgl-arrow_up_left_square_two_tone::before {
  content: '\E04F';
}

.xgl-arrow_up_left_square_two_tone::after {
  content: '\E050';
  opacity: .2;
}

.xgl-arrow_up_right::before {
  content: '\E051';
}

.xgl-arrow_up_right_circle::before {
  content: '\E052';
}

.xgl-arrow_up_right_circle_alt::before {
  content: '\E053';
}

.xgl-arrow_up_right_circle_two_tone::before {
  content: '\E052';
}

.xgl-arrow_up_right_circle_two_tone::after {
  content: '\E053';
  opacity: .2;
}

.xgl-arrow_up_right_square::before {
  content: '\E054';
}

.xgl-arrow_up_right_square_alt::before {
  content: '\E055';
}

.xgl-arrow_up_right_square_two_tone::before {
  content: '\E054';
}

.xgl-arrow_up_right_square_two_tone::after {
  content: '\E055';
  opacity: .2;
}

.xgl-arrow_up_square::before {
  content: '\E056';
}

.xgl-arrow_up_square_alt::before {
  content: '\E057';
}

.xgl-arrow_up_square_two_tone::before {
  content: '\E056';
}

.xgl-arrow_up_square_two_tone::after {
  content: '\E057';
  opacity: .2;
}

.xgl-asterisk::before {
  content: '\E058';
}

.xgl-asterisk_alt::before {
  content: '\E059';
}

.xgl-asterisk_overlay::before {
  content: '\E834';
}

.xgl-asterisk_square::before {
  content: '\E05A';
}

.xgl-asterisk_square_alt::before {
  content: '\E05B';
}

.xgl-asterisk_square_two_tone::before {
  content: '\E05A';
}

.xgl-asterisk_square_two_tone::after {
  content: '\E05B';
  opacity: .2;
}

.xgl-asterisk_two_tone::before {
  content: '\E058';
}

.xgl-asterisk_two_tone::after {
  content: '\E059';
  opacity: .2;
}

.xgl-audio_wave::before {
  content: '\E79A';
}

.xgl-auto::before {
  content: '\E05C';
}

.xgl-auto_alt::before {
  content: '\E05D';
}

.xgl-auto_disabled::before {
  content: '\E05E';
}

.xgl-auto_disabled_alt::before {
  content: '\E05F';
}

.xgl-auto_disabled_two_tone::before {
  content: '\E05E';
}

.xgl-auto_disabled_two_tone::after {
  content: '\E05F';
  opacity: .2;
}

.xgl-auto_orientation::before {
  content: '\EA8B';
}

.xgl-auto_orientation_alt::before {
  content: '\EA8C';
}

.xgl-auto_orientation_two_tone::before {
  content: '\EA8B';
}

.xgl-auto_orientation_two_tone::after {
  content: '\EA8C';
  opacity: .2;
}

.xgl-auto_select_type_lef::before {
  content: '\E060';
}

.xgl-auto_select_type_lef_alt::before {
  content: '\E6C6';
}

.xgl-auto_select_type_lef_two_tone::before {
  content: '\E060';
}

.xgl-auto_select_type_lef_two_tone::after {
  content: '\E6C6';
  opacity: .2;
}

.xgl-auto_select_type_sef::before {
  content: '\E061';
}

.xgl-auto_select_type_sef_alt::before {
  content: '\E6C7';
}

.xgl-auto_select_type_sef_two_tone::before {
  content: '\E061';
}

.xgl-auto_select_type_sef_two_tone::after {
  content: '\E6C7';
  opacity: .2;
}

.xgl-auto_start::before {
  content: '\ED8A';
}

.xgl-auto_start_alt::before {
  content: '\ED8B';
}

.xgl-auto_start_two_tone::before {
  content: '\ED8A';
}

.xgl-auto_start_two_tone::after {
  content: '\ED8B';
  opacity: .2;
}

.xgl-auto_two_tone::before {
  content: '\E05C';
}

.xgl-auto_two_tone::after {
  content: '\E05D';
  opacity: .2;
}

.xgl-auto_wake_sleep::before {
  content: '\E832';
}

.xgl-axis_x::before {
  content: '\ECD0';
}

.xgl-axis_x_overlay::before {
  content: '\ECD1';
}

.xgl-axis_y::before {
  content: '\ECD2';
}

.xgl-axis_y_overlay::before {
  content: '\ECD3';
}

.xgl-axis_z::before {
  content: '\ECD4';
}

.xgl-axis_z_overlay::before {
  content: '\ECD5';
}

.xgl-background_color::before {
  content: '\E062';
}

.xgl-background_color_two_tone::before {
  content: '\E062';
}

.xgl-background_color_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-background_suppression::before {
  content: '\EA8D';
}

.xgl-background_suppression_alt::before {
  content: '\EA8E';
}

.xgl-background_suppression_two_tone::before {
  content: '\EA8D';
}

.xgl-background_suppression_two_tone::after {
  content: '\EA8E';
  opacity: .2;
}

.xgl-backlog::before {
  content: '\E063';
}

.xgl-backlog_alt::before {
  content: '\E064';
}

.xgl-backlog_two_tone::before {
  content: '\E063';
}

.xgl-backlog_two_tone::after {
  content: '\E064';
  opacity: .2;
}

.xgl-backspace::before {
  content: '\E065';
}

.xgl-backspace_alt::before {
  content: '\E066';
}

.xgl-backspace_rtl::before {
  content: '\E59E';
}

.xgl-backspace_rtl_alt::before {
  content: '\E59F';
}

.xgl-backspace_rtl_two_tone::before {
  content: '\E59E';
}

.xgl-backspace_rtl_two_tone::after {
  content: '\E59F';
  opacity: .2;
}

.xgl-backspace_two_tone::before {
  content: '\E065';
}

.xgl-backspace_two_tone::after {
  content: '\E066';
  opacity: .2;
}

.xgl-bar_chart::before {
  content: '\E067';
}

.xgl-bar_chart_alt::before {
  content: '\E068';
}

.xgl-bar_chart_square::before {
  content: '\E069';
}

.xgl-bar_chart_square_alt::before {
  content: '\E06A';
}

.xgl-bar_chart_square_two_tone::before {
  content: '\E069';
}

.xgl-bar_chart_square_two_tone::after {
  content: '\E06A';
  opacity: .2;
}

.xgl-bar_chart_two_tone::before {
  content: '\E067';
}

.xgl-bar_chart_two_tone::after {
  content: '\E068';
  opacity: .2;
}

.xgl-barcode::before {
  content: '\E06B';
}

.xgl-barcode_alt::before {
  content: '\E06C';
}

.xgl-barcode_pattern::before {
  content: '\EE19';
}

.xgl-barcode_two_tone::before {
  content: '\E06B';
}

.xgl-barcode_two_tone::after {
  content: '\E06C';
  opacity: .2;
}

.xgl-bates_stamp::before {
  content: '\E06D';
}

.xgl-bates_stamp_alt::before {
  content: '\E06E';
}

.xgl-bates_stamp_two_tone::before {
  content: '\E06D';
}

.xgl-bates_stamp_two_tone::after {
  content: '\E06E';
  opacity: .2;
}

.xgl-battery_alert::before {
  content: '\E6C0';
}

.xgl-battery_alert_two_tone::before {
  content: '\E6C0';
}

.xgl-battery_alert_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_alt::before {
  content: '\E6BE';
}

.xgl-battery_disabled::before {
  content: '\E6BF';
}

.xgl-battery_disabled_two_tone::before {
  content: '\E6BF';
}

.xgl-battery_disabled_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_eighty::before {
  content: '\E6B9';
}

.xgl-battery_eighty_two_tone::before {
  content: '\E6B9';
}

.xgl-battery_eighty_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_empty::before {
  content: '\E6BD';
}

.xgl-battery_empty_two_tone::before {
  content: '\E6BD';
}

.xgl-battery_empty_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_fourty::before {
  content: '\E6BB';
}

.xgl-battery_fourty_two_tone::before {
  content: '\E6BB';
}

.xgl-battery_fourty_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_full::before {
  content: '\E6B8';
}

.xgl-battery_full_two_tone::before {
  content: '\E6B8';
}

.xgl-battery_full_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_remove::before {
  content: '\E6C1';
}

.xgl-battery_remove_two_tone::before {
  content: '\E6C1';
}

.xgl-battery_remove_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_sixty::before {
  content: '\E6BA';
}

.xgl-battery_sixty_two_tone::before {
  content: '\E6BA';
}

.xgl-battery_sixty_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-battery_twenty::before {
  content: '\E6BC';
}

.xgl-battery_twenty_two_tone::before {
  content: '\E6BC';
}

.xgl-battery_twenty_two_tone::after {
  content: '\E6BE';
  opacity: .2;
}

.xgl-bell::before {
  content: '\E06F';
}

.xgl-bell_alt::before {
  content: '\E070';
}

.xgl-bell_disabled::before {
  content: '\E071';
}

.xgl-bell_disabled_alt::before {
  content: '\E072';
}

.xgl-bell_disabled_two_tone::before {
  content: '\E071';
}

.xgl-bell_disabled_two_tone::after {
  content: '\E072';
  opacity: .2;
}

.xgl-bell_two_tone::before {
  content: '\E06F';
}

.xgl-bell_two_tone::after {
  content: '\E070';
  opacity: .2;
}

.xgl-belt::before {
  content: '\E80D';
}

.xgl-belt_alt::before {
  content: '\E80E';
}

.xgl-belt_two_tone::before {
  content: '\E80D';
}

.xgl-belt_two_tone::after {
  content: '\E80E';
  opacity: .2;
}

.xgl-bicycle::before {
  content: '\E843';
}

.xgl-billboard::before {
  content: '\E073';
}

.xgl-billboard_alt::before {
  content: '\E074';
}

.xgl-billboard_two_tone::before {
  content: '\E073';
}

.xgl-billboard_two_tone::after {
  content: '\E074';
  opacity: .2;
}

.xgl-billing_usage_sheets::before {
  content: '\EA37';
}

.xgl-billing_usage_sheets_alt::before {
  content: '\EA38';
}

.xgl-billing_usage_sheets_two_tone::before {
  content: '\EA37';
}

.xgl-billing_usage_sheets_two_tone::after {
  content: '\EA38';
  opacity: .2;
}

.xgl-binary::before {
  content: '\E5CC';
}

.xgl-binary_alt::before {
  content: '\E5CB';
}

.xgl-binary_two_tone::before {
  content: '\E5CC';
}

.xgl-binary_two_tone::after {
  content: '\E5CB';
  opacity: .2;
}

.xgl-binding::before {
  content: '\E835';
}

.xgl-binding_alt::before {
  content: '\E836';
}

.xgl-binding_bottom_l::before {
  content: '\ED8C';
}

.xgl-binding_bottom_l_alt::before {
  content: '\ED8D';
}

.xgl-binding_bottom_l_two_tone::before {
  content: '\ED8C';
}

.xgl-binding_bottom_l_two_tone::after {
  content: '\ED8D';
  opacity: .2;
}

.xgl-binding_bottom_p::before {
  content: '\ED8E';
}

.xgl-binding_bottom_p_alt::before {
  content: '\ED8F';
}

.xgl-binding_bottom_p_two_tone::before {
  content: '\ED8E';
}

.xgl-binding_bottom_p_two_tone::after {
  content: '\ED8F';
  opacity: .2;
}

.xgl-binding_left_l::before {
  content: '\ED90';
}

.xgl-binding_left_l_alt::before {
  content: '\ED91';
}

.xgl-binding_left_l_two_tone::before {
  content: '\ED90';
}

.xgl-binding_left_l_two_tone::after {
  content: '\ED91';
  opacity: .2;
}

.xgl-binding_right_l::before {
  content: '\ED92';
}

.xgl-binding_right_l_alt::before {
  content: '\ED93';
}

.xgl-binding_right_l_two_tone::before {
  content: '\ED92';
}

.xgl-binding_right_l_two_tone::after {
  content: '\ED93';
  opacity: .2;
}

.xgl-binding_right_p::before {
  content: '\ED94';
}

.xgl-binding_right_p_alt::before {
  content: '\ED95';
}

.xgl-binding_right_p_two_tone::before {
  content: '\ED94';
}

.xgl-binding_right_p_two_tone::after {
  content: '\ED95';
  opacity: .2;
}

.xgl-binding_top_l::before {
  content: '\ED96';
}

.xgl-binding_top_l_alt::before {
  content: '\ED97';
}

.xgl-binding_top_l_two_tone::before {
  content: '\ED96';
}

.xgl-binding_top_l_two_tone::after {
  content: '\ED97';
  opacity: .2;
}

.xgl-binding_top_p::before {
  content: '\ED98';
}

.xgl-binding_top_p_alt::before {
  content: '\ED99';
}

.xgl-binding_top_p_two_tone::before {
  content: '\ED98';
}

.xgl-binding_top_p_two_tone::after {
  content: '\ED99';
  opacity: .2;
}

.xgl-binding_two_tone::before {
  content: '\E835';
}

.xgl-binding_two_tone::after {
  content: '\E836';
  opacity: .2;
}

.xgl-bird::before {
  content: '\E975';
}

.xgl-bird_alt::before {
  content: '\E976';
}

.xgl-bird_two_tone::before {
  content: '\E975';
}

.xgl-bird_two_tone::after {
  content: '\E976';
  opacity: .2;
}

.xgl-black_impressions_recieved::before {
  content: '\EA39';
}

.xgl-black_impressions_recieved_alt::before {
  content: '\EA3A';
}

.xgl-black_impressions_recieved_two_tone::before {
  content: '\EA39';
}

.xgl-black_impressions_recieved_two_tone::after {
  content: '\EA3A';
  opacity: .2;
}

.xgl-black_white::before {
  content: '\E837';
}

.xgl-blank::before {
  content: '\EE50';
}

.xgl-blocks::before {
  content: '\E800';
}

.xgl-blocks_alt::before {
  content: '\E801';
}

.xgl-blocks_two_tone::before {
  content: '\E800';
}

.xgl-blocks_two_tone::after {
  content: '\E801';
  opacity: .2;
}

.xgl-bluetooth::before {
  content: '\E075';
}

.xgl-bluetooth_audio::before {
  content: '\E076';
}

.xgl-bluetooth_off::before {
  content: '\E077';
}

.xgl-bluetooth_square::before {
  content: '\E078';
}

.xgl-bluetooth_square_alt::before {
  content: '\E079';
}

.xgl-bluetooth_square_two_tone::before {
  content: '\E078';
}

.xgl-bluetooth_square_two_tone::after {
  content: '\E079';
  opacity: .2;
}

.xgl-bold::before {
  content: '\E07A';
}

.xgl-bond_lef::before {
  content: '\E07B';
}

.xgl-bond_lef_alt::before {
  content: '\E6C8';
}

.xgl-bond_lef_two_tone::before {
  content: '\E07B';
}

.xgl-bond_lef_two_tone::after {
  content: '\E6C8';
  opacity: .2;
}

.xgl-bond_reloaded_lef::before {
  content: '\E945';
}

.xgl-bond_reloaded_lef_alt::before {
  content: '\E946';
}

.xgl-bond_reloaded_lef_two_tone::before {
  content: '\E945';
}

.xgl-bond_reloaded_lef_two_tone::after {
  content: '\E946';
  opacity: .2;
}

.xgl-bond_reloaded_sef::before {
  content: '\E947';
}

.xgl-bond_reloaded_sef_alt::before {
  content: '\E948';
}

.xgl-bond_reloaded_sef_two_tone::before {
  content: '\E947';
}

.xgl-bond_reloaded_sef_two_tone::after {
  content: '\E948';
  opacity: .2;
}

.xgl-bond_sef::before {
  content: '\E07C';
}

.xgl-bond_sef_alt::before {
  content: '\E6C9';
}

.xgl-bond_sef_two_tone::before {
  content: '\E07C';
}

.xgl-bond_sef_two_tone::after {
  content: '\E6C9';
  opacity: .2;
}

.xgl-book::before {
  content: '\E07D';
}

.xgl-book_alt::before {
  content: '\E07E';
}

.xgl-book_copy_both::before {
  content: '\E9E3';
}

.xgl-book_copy_both_alt::before {
  content: '\E9E4';
}

.xgl-book_copy_both_top::before {
  content: '\E9E5';
}

.xgl-book_copy_both_top_alt::before {
  content: '\E9E6';
}

.xgl-book_copy_both_top_two_tone::before {
  content: '\E9E5';
}

.xgl-book_copy_both_top_two_tone::after {
  content: '\E9E6';
  opacity: .2;
}

.xgl-book_copy_both_two_tone::before {
  content: '\E9E3';
}

.xgl-book_copy_both_two_tone::after {
  content: '\E9E4';
  opacity: .2;
}

.xgl-book_copy_bottom::before {
  content: '\E9E7';
}

.xgl-book_copy_bottom_alt::before {
  content: '\E9E8';
}

.xgl-book_copy_bottom_two_tone::before {
  content: '\E9E7';
}

.xgl-book_copy_bottom_two_tone::after {
  content: '\E9E8';
  opacity: .2;
}

.xgl-book_copy_left::before {
  content: '\E9E9';
}

.xgl-book_copy_left_alt::before {
  content: '\E9EA';
}

.xgl-book_copy_left_two_tone::before {
  content: '\E9E9';
}

.xgl-book_copy_left_two_tone::after {
  content: '\E9EA';
  opacity: .2;
}

.xgl-book_copy_right::before {
  content: '\E9EB';
}

.xgl-book_copy_right_alt::before {
  content: '\E9EC';
}

.xgl-book_copy_right_two_tone::before {
  content: '\E9EB';
}

.xgl-book_copy_right_two_tone::after {
  content: '\E9EC';
  opacity: .2;
}

.xgl-book_copy_top::before {
  content: '\E9ED';
}

.xgl-book_copy_top_alt::before {
  content: '\E9EE';
}

.xgl-book_copy_top_two_tone::before {
  content: '\E9ED';
}

.xgl-book_copy_top_two_tone::after {
  content: '\E9EE';
  opacity: .2;
}

.xgl-book_copy_ttob::before {
  content: '\E9EF';
}

.xgl-book_copy_ttob_alt::before {
  content: '\E9F0';
}

.xgl-book_copy_ttob_two_tone::before {
  content: '\E9EF';
}

.xgl-book_copy_ttob_two_tone::after {
  content: '\E9F0';
  opacity: .2;
}

.xgl-book_create::before {
  content: '\E9F1';
}

.xgl-book_create_alt::before {
  content: '\E9F2';
}

.xgl-book_create_cover::before {
  content: '\E9F3';
}

.xgl-book_create_cover_alt::before {
  content: '\E9F4';
}

.xgl-book_create_cover_blank::before {
  content: '\E9F5';
}

.xgl-book_create_cover_blank_alt::before {
  content: '\E9F6';
}

.xgl-book_create_cover_blank_two_tone::before {
  content: '\E9F5';
}

.xgl-book_create_cover_blank_two_tone::after {
  content: '\E9F6';
  opacity: .2;
}

.xgl-book_create_cover_both::before {
  content: '\E9F7';
}

.xgl-book_create_cover_both_alt::before {
  content: '\E9F8';
}

.xgl-book_create_cover_both_two_tone::before {
  content: '\E9F7';
}

.xgl-book_create_cover_both_two_tone::after {
  content: '\E9F8';
  opacity: .2;
}

.xgl-book_create_cover_outside::before {
  content: '\E9F9';
}

.xgl-book_create_cover_outside_alt::before {
  content: '\E9FA';
}

.xgl-book_create_cover_outside_two_tone::before {
  content: '\E9F9';
}

.xgl-book_create_cover_outside_two_tone::after {
  content: '\E9FA';
  opacity: .2;
}

.xgl-book_create_cover_two_tone::before {
  content: '\E9F3';
}

.xgl-book_create_cover_two_tone::after {
  content: '\E9F4';
  opacity: .2;
}

.xgl-book_create_crease::before {
  content: '\E9FB';
}

.xgl-book_create_crease_alt::before {
  content: '\E9FC';
}

.xgl-book_create_crease_staple::before {
  content: '\E9FD';
}

.xgl-book_create_crease_staple_alt::before {
  content: '\E9FE';
}

.xgl-book_create_crease_staple_two_tone::before {
  content: '\E9FD';
}

.xgl-book_create_crease_staple_two_tone::after {
  content: '\E9FE';
  opacity: .2;
}

.xgl-book_create_crease_two_tone::before {
  content: '\E9FB';
}

.xgl-book_create_crease_two_tone::after {
  content: '\E9FC';
  opacity: .2;
}

.xgl-book_create_finishing_bkfold_bkstaple::before {
  content: '\E9FF';
}

.xgl-book_create_finishing_bkfold_bkstaple_alt::before {
  content: '\EA00';
}

.xgl-book_create_finishing_bkfold_bkstaple_two_tone::before {
  content: '\E9FF';
}

.xgl-book_create_finishing_bkfold_bkstaple_two_tone::after {
  content: '\EA00';
  opacity: .2;
}

.xgl-book_create_gutter::before {
  content: '\EA01';
}

.xgl-book_create_gutter_alt::before {
  content: '\EA02';
}

.xgl-book_create_gutter_two_tone::before {
  content: '\EA01';
}

.xgl-book_create_gutter_two_tone::after {
  content: '\EA02';
  opacity: .2;
}

.xgl-book_create_odd_back::before {
  content: '\EA03';
}

.xgl-book_create_odd_back_alt::before {
  content: '\EA04';
}

.xgl-book_create_odd_back_two_tone::before {
  content: '\EA03';
}

.xgl-book_create_odd_back_two_tone::after {
  content: '\EA04';
  opacity: .2;
}

.xgl-book_create_odd_front::before {
  content: '\EA05';
}

.xgl-book_create_odd_front_alt::before {
  content: '\EA06';
}

.xgl-book_create_odd_front_two_tone::before {
  content: '\EA05';
}

.xgl-book_create_odd_front_two_tone::after {
  content: '\EA06';
  opacity: .2;
}

.xgl-book_create_sheets::before {
  content: '\EA07';
}

.xgl-book_create_sheets_alt::before {
  content: '\EA08';
}

.xgl-book_create_sheets_two_tone::before {
  content: '\EA07';
}

.xgl-book_create_sheets_two_tone::after {
  content: '\EA08';
  opacity: .2;
}

.xgl-book_create_two_tone::before {
  content: '\E9F1';
}

.xgl-book_create_two_tone::after {
  content: '\E9F2';
  opacity: .2;
}

.xgl-book_face_down::before {
  content: '\E76F';
}

.xgl-book_face_down_alt::before {
  content: '\EC86';
}

.xgl-book_face_down_two_tone::before {
  content: '\E76F';
}

.xgl-book_face_down_two_tone::after {
  content: '\EC86';
  opacity: .2;
}

.xgl-book_face_up::before {
  content: '\E770';
}

.xgl-book_face_up_alt::before {
  content: '\EC87';
}

.xgl-book_face_up_two_tone::before {
  content: '\E770';
}

.xgl-book_face_up_two_tone::after {
  content: '\EC87';
  opacity: .2;
}

.xgl-book_fold::before {
  content: '\E881';
}

.xgl-book_fold_alt::before {
  content: '\E882';
}

.xgl-book_fold_two_tone::before {
  content: '\E881';
}

.xgl-book_fold_two_tone::after {
  content: '\E882';
  opacity: .2;
}

.xgl-book_half::before {
  content: '\EA09';
}

.xgl-book_half_two_tone::before {
  content: '\EA09';
}

.xgl-book_half_two_tone::after {
  content: '\E07E';
  opacity: .2;
}

.xgl-book_off::before {
  content: '\EE05';
}

.xgl-book_off_alt::before {
  content: '\EE06';
}

.xgl-book_off_two_tone::before {
  content: '\EE05';
}

.xgl-book_off_two_tone::after {
  content: '\EE06';
  opacity: .2;
}

.xgl-book_shelf::before {
  content: '\E6A7';
}

.xgl-book_shelf_alt::before {
  content: '\E6A8';
}

.xgl-book_shelf_two_tone::before {
  content: '\E6A7';
}

.xgl-book_shelf_two_tone::after {
  content: '\E6A8';
  opacity: .2;
}

.xgl-book_two_tone::before {
  content: '\E07D';
}

.xgl-book_two_tone::after {
  content: '\E07E';
  opacity: .2;
}

.xgl-bookmark::before {
  content: '\E07F';
}

.xgl-bookmark_alt::before {
  content: '\E080';
}

.xgl-bookmark_two_tone::before {
  content: '\E07F';
}

.xgl-bookmark_two_tone::after {
  content: '\E080';
  opacity: .2;
}

.xgl-bootstrap::before {
  content: '\E081';
}

.xgl-bootstrap_alt::before {
  content: '\EC76';
}

.xgl-bootstrap_two_tone::before {
  content: '\E081';
}

.xgl-bootstrap_two_tone::after {
  content: '\EC76';
  opacity: .2;
}

.xgl-bottom::before {
  content: '\E6B5';
}

.xgl-bounce::before {
  content: '\E977';
}

.xgl-bounce_alt::before {
  content: '\E978';
}

.xgl-bounce_two_tone::before {
  content: '\E977';
}

.xgl-bounce_two_tone::after {
  content: '\E978';
  opacity: .2;
}

.xgl-box::before {
  content: '\E082';
}

.xgl-box_alt::before {
  content: '\E083';
}

.xgl-box_logo::before {
  content: '\EE53';
}

.xgl-box_two_tone::before {
  content: '\E082';
}

.xgl-box_two_tone::after {
  content: '\E083';
  opacity: .2;
}

.xgl-braille::before {
  content: '\E7B3';
}

.xgl-braille_alt::before {
  content: '\E7B4';
}

.xgl-braille_two_tone::before {
  content: '\E7B3';
}

.xgl-braille_two_tone::after {
  content: '\E7B4';
  opacity: .2;
}

.xgl-break::before {
  content: '\E65B';
}

.xgl-break_alt::before {
  content: '\E65C ';
}

.xgl-break_two_tone::before {
  content: '\E65B';
}

.xgl-break_two_tone::after {
  content: '\E65C ';
  opacity: .2;
}

.xgl-bridge::before {
  content: '\E952 ';
}

.xgl-bridge_alt::before {
  content: '\E953 ';
}

.xgl-bridge_two_tone::before {
  content: '\E952 ';
}

.xgl-bridge_two_tone::after {
  content: '\E953 ';
  opacity: .2;
}

.xgl-briefcase::before {
  content: '\E084';
}

.xgl-briefcase_alt::before {
  content: '\E085';
}

.xgl-briefcase_two_tone::before {
  content: '\E084';
}

.xgl-briefcase_two_tone::after {
  content: '\E085';
  opacity: .2;
}

.xgl-brightness::before {
  content: '\E086';
}

.xgl-brightness_alt::before {
  content: '\E087';
}

.xgl-brightness_two_tone::before {
  content: '\E086';
}

.xgl-brightness_two_tone::after {
  content: '\E087';
  opacity: .2;
}

.xgl-brush::before {
  content: '\E088';
}

.xgl-brush_alt::before {
  content: '\E089';
}

.xgl-brush_two_tone::before {
  content: '\E088';
}

.xgl-brush_two_tone::after {
  content: '\E089';
  opacity: .2;
}

.xgl-bug::before {
  content: '\E63A';
}

.xgl-bug_alt::before {
  content: '\E63B';
}

.xgl-bug_two_tone::before {
  content: '\E63A';
}

.xgl-bug_two_tone::after {
  content: '\E63B';
  opacity: .2;
}

.xgl-build::before {
  content: '\E08A';
}

.xgl-build_alt::before {
  content: '\E08B';
}

.xgl-build_job_off::before {
  content: '\EA3B';
}

.xgl-build_job_square::before {
  content: '\ED9A';
}

.xgl-build_job_square_alt::before {
  content: '\ED9B';
}

.xgl-build_job_square_off::before {
  content: '\ED9C';
}

.xgl-build_job_square_off_alt::before {
  content: '\ED9D';
}

.xgl-build_job_square_off_two_tone::before {
  content: '\ED9C';
}

.xgl-build_job_square_off_two_tone::after {
  content: '\ED9D';
  opacity: .2;
}

.xgl-build_job_square_two_tone::before {
  content: '\ED9A';
}

.xgl-build_job_square_two_tone::after {
  content: '\ED9B';
  opacity: .2;
}

.xgl-build_two_tone::before {
  content: '\E08A';
}

.xgl-build_two_tone::after {
  content: '\E08B';
  opacity: .2;
}

.xgl-build_volume::before {
  content: '\ECD6';
}

.xgl-build_volume_alt::before {
  content: '\ECD7';
}

.xgl-build_volume_off::before {
  content: '\ECD8';
}

.xgl-build_volume_off_alt::before {
  content: '\ECDA';
}

.xgl-build_volume_off_two_tone::before {
  content: '\ECD8';
}

.xgl-build_volume_off_two_tone::after {
  content: '\ECDA';
  opacity: .2;
}

.xgl-build_volume_two_tone::before {
  content: '\ECD6';
}

.xgl-build_volume_two_tone::after {
  content: '\ECD7';
  opacity: .2;
}

.xgl-business::before {
  content: '\E08C';
}

.xgl-business_alt::before {
  content: '\E08D';
}

.xgl-business_favorite::before {
  content: '\E8B8';
}

.xgl-business_favorite_alt::before {
  content: '\E8C0';
}

.xgl-business_favorite_two_tone::before {
  content: '\E8B8';
}

.xgl-business_favorite_two_tone::after {
  content: '\E8C0';
  opacity: .2;
}

.xgl-business_two_tone::before {
  content: '\E08C';
}

.xgl-business_two_tone::after {
  content: '\E08D';
  opacity: .2;
}

.xgl-businesses::before {
  content: '\E08E';
}

.xgl-businesses_alt::before {
  content: '\E08F';
}

.xgl-businesses_two_tone::before {
  content: '\E08E';
}

.xgl-businesses_two_tone::after {
  content: '\E08F';
  opacity: .2;
}

.xgl-busy_stack_bkgd::before {
  content: '\E979';
}

.xgl-busy_stack_frgd::before {
  content: '\E97A';
}

.xgl-busy_stack_mdgd::before {
  content: '\E97B';
}

.xgl-buy::before {
  content: '\E939';
}

.xgl-buy_alt::before {
  content: '\E93A';
}

.xgl-buy_two_tone::before {
  content: '\E939';
}

.xgl-buy_two_tone::after {
  content: '\E93A';
  opacity: .2;
}

.xgl-bypass_feed::before {
  content: '\E838';
}

.xgl-bypass_feed_alt::before {
  content: '\E839';
}

.xgl-bypass_feed_two_tone::before {
  content: '\E838';
}

.xgl-bypass_feed_two_tone::after {
  content: '\E839';
  opacity: .2;
}

.xgl-bypass_paper_empty::before {
  content: '\E090';
}

.xgl-bypass_paper_present::before {
  content: '\E091';
}

.xgl-calculate::before {
  content: '\E092';
}

.xgl-calculate_alt::before {
  content: '\E093';
}

.xgl-calculate_two_tone::before {
  content: '\E092';
}

.xgl-calculate_two_tone::after {
  content: '\E093';
  opacity: .2;
}

.xgl-calculator::before {
  content: '\E094';
}

.xgl-calculator_alt::before {
  content: '\E095';
}

.xgl-calculator_two_tone::before {
  content: '\E094';
}

.xgl-calculator_two_tone::after {
  content: '\E095';
  opacity: .2;
}

.xgl-calendar::before {
  content: '\E096';
}

.xgl-calendar_add::before {
  content: '\E097';
}

.xgl-calendar_add_alt::before {
  content: '\E098';
}

.xgl-calendar_add_two_tone::before {
  content: '\E097';
}

.xgl-calendar_add_two_tone::after {
  content: '\E098';
  opacity: .2;
}

.xgl-calendar_alt::before {
  content: '\E099';
}

.xgl-calendar_blank::before {
  content: '\E09A';
}

.xgl-calendar_blank_alt::before {
  content: '\E09B';
}

.xgl-calendar_blank_two_tone::before {
  content: '\E09A';
}

.xgl-calendar_blank_two_tone::after {
  content: '\E09B';
  opacity: .2;
}

.xgl-calendar_cancel::before {
  content: '\E09C';
}

.xgl-calendar_cancel_alt::before {
  content: '\E09D';
}

.xgl-calendar_cancel_two_tone::before {
  content: '\E09C';
}

.xgl-calendar_cancel_two_tone::after {
  content: '\E09D';
  opacity: .2;
}

.xgl-calendar_check::before {
  content: '\E09E';
}

.xgl-calendar_check_alt::before {
  content: '\E09F';
}

.xgl-calendar_check_two_tone::before {
  content: '\E09E';
}

.xgl-calendar_check_two_tone::after {
  content: '\E09F';
  opacity: .2;
}

.xgl-calendar_daily::before {
  content: '\EA0A';
}

.xgl-calendar_daily_alt::before {
  content: '\EA0B';
}

.xgl-calendar_daily_two_tone::before {
  content: '\EA0A';
}

.xgl-calendar_daily_two_tone::after {
  content: '\EA0B';
  opacity: .2;
}

.xgl-calendar_day::before {
  content: '\E0A0';
}

.xgl-calendar_day_alt::before {
  content: '\E0A1';
}

.xgl-calendar_day_two_tone::before {
  content: '\E0A0';
}

.xgl-calendar_day_two_tone::after {
  content: '\E0A1';
  opacity: .2;
}

.xgl-calendar_download::before {
  content: '\E0A2';
}

.xgl-calendar_download_alt::before {
  content: '\E0A3';
}

.xgl-calendar_download_two_tone::before {
  content: '\E0A2';
}

.xgl-calendar_download_two_tone::after {
  content: '\E0A3';
  opacity: .2;
}

.xgl-calendar_friday::before {
  content: '\EA0C';
}

.xgl-calendar_friday_alt::before {
  content: '\EA0D';
}

.xgl-calendar_friday_two_tone::before {
  content: '\EA0C';
}

.xgl-calendar_friday_two_tone::after {
  content: '\EA0D';
  opacity: .2;
}

.xgl-calendar_monday::before {
  content: '\EA0E';
}

.xgl-calendar_monday_alt::before {
  content: '\EA0F';
}

.xgl-calendar_monday_two_tone::before {
  content: '\EA0E';
}

.xgl-calendar_monday_two_tone::after {
  content: '\EA0F';
  opacity: .2;
}

.xgl-calendar_month::before {
  content: '\E0A4';
}

.xgl-calendar_month_alt::before {
  content: '\E0A5';
}

.xgl-calendar_month_two_tone::before {
  content: '\E0A4';
}

.xgl-calendar_month_two_tone::after {
  content: '\E0A5';
  opacity: .2;
}

.xgl-calendar_monthly::before {
  content: '\EA10';
}

.xgl-calendar_monthly_alt::before {
  content: '\EA11';
}

.xgl-calendar_monthly_two_tone::before {
  content: '\EA10';
}

.xgl-calendar_monthly_two_tone::after {
  content: '\EA11';
  opacity: .2;
}

.xgl-calendar_never::before {
  content: '\EA12';
}

.xgl-calendar_never_alt::before {
  content: '\EA13';
}

.xgl-calendar_never_two_tone::before {
  content: '\EA12';
}

.xgl-calendar_never_two_tone::after {
  content: '\EA13';
  opacity: .2;
}

.xgl-calendar_saturday::before {
  content: '\EA14';
}

.xgl-calendar_saturday_alt::before {
  content: '\EA15';
}

.xgl-calendar_saturday_two_tone::before {
  content: '\EA14';
}

.xgl-calendar_saturday_two_tone::after {
  content: '\EA15';
  opacity: .2;
}

.xgl-calendar_subtract::before {
  content: '\E0A6';
}

.xgl-calendar_subtract_alt::before {
  content: '\E0A7';
}

.xgl-calendar_subtract_two_tone::before {
  content: '\E0A6';
}

.xgl-calendar_subtract_two_tone::after {
  content: '\E0A7';
  opacity: .2;
}

.xgl-calendar_sunday::before {
  content: '\EA16';
}

.xgl-calendar_sunday_alt::before {
  content: '\EA17';
}

.xgl-calendar_sunday_two_tone::before {
  content: '\EA16';
}

.xgl-calendar_sunday_two_tone::after {
  content: '\EA17';
  opacity: .2;
}

.xgl-calendar_thursday::before {
  content: '\EA18';
}

.xgl-calendar_thursday_alt::before {
  content: '\EA19';
}

.xgl-calendar_thursday_two_tone::before {
  content: '\EA18';
}

.xgl-calendar_thursday_two_tone::after {
  content: '\EA19';
  opacity: .2;
}

.xgl-calendar_today::before {
  content: '\ED3E';
}

.xgl-calendar_today_alt::before {
  content: '\ED3F';
}

.xgl-calendar_today_two_tone::before {
  content: '\ED3E';
}

.xgl-calendar_today_two_tone::after {
  content: '\ED3F';
  opacity: .2;
}

.xgl-calendar_tuesday::before {
  content: '\EA1A';
}

.xgl-calendar_tuesday_alt::before {
  content: '\EA1B';
}

.xgl-calendar_tuesday_two_tone::before {
  content: '\EA1A';
}

.xgl-calendar_tuesday_two_tone::after {
  content: '\EA1B';
  opacity: .2;
}

.xgl-calendar_two_tone::before {
  content: '\E096';
}

.xgl-calendar_two_tone::after {
  content: '\E099';
  opacity: .2;
}

.xgl-calendar_upload::before {
  content: '\E0A8';
}

.xgl-calendar_upload_alt::before {
  content: '\E0A9';
}

.xgl-calendar_upload_two_tone::before {
  content: '\E0A8';
}

.xgl-calendar_upload_two_tone::after {
  content: '\E0A9';
  opacity: .2;
}

.xgl-calendar_wednesday::before {
  content: '\EA1C';
}

.xgl-calendar_wednesday_alt::before {
  content: '\EA1D';
}

.xgl-calendar_wednesday_two_tone::before {
  content: '\EA1C';
}

.xgl-calendar_wednesday_two_tone::after {
  content: '\EA1D';
  opacity: .2;
}

.xgl-calendar_week::before {
  content: '\E0AA';
}

.xgl-calendar_week_alt::before {
  content: '\E0AB';
}

.xgl-calendar_week_two_tone::before {
  content: '\E0AA';
}

.xgl-calendar_week_two_tone::after {
  content: '\E0AB';
  opacity: .2;
}

.xgl-calendar_weekly::before {
  content: '\EA1E';
}

.xgl-calendar_weekly_alt::before {
  content: '\EA1F';
}

.xgl-calendar_weekly_two_tone::before {
  content: '\EA1E';
}

.xgl-calendar_weekly_two_tone::after {
  content: '\EA1F';
  opacity: .2;
}

.xgl-callout::before {
  content: '\E0AC';
}

.xgl-callout_alt::before {
  content: '\E0AD';
}

.xgl-callout_two_tone::before {
  content: '\E0AC';
}

.xgl-callout_two_tone::after {
  content: '\E0AD';
  opacity: .2;
}

.xgl-camera::before {
  content: '\E0AE';
}

.xgl-camera_alt::before {
  content: '\E0AF';
}

.xgl-camera_rotate::before {
  content: '\E97C';
}

.xgl-camera_rotate_alt::before {
  content: '\E97D';
}

.xgl-camera_rotate_two_tone::before {
  content: '\E97C';
}

.xgl-camera_rotate_two_tone::after {
  content: '\E97D';
  opacity: .2;
}

.xgl-camera_two_tone::before {
  content: '\E0AE';
}

.xgl-camera_two_tone::after {
  content: '\E0AF';
  opacity: .2;
}

.xgl-caps::before {
  content: '\E0B0';
}

.xgl-car_side::before {
  content: '\E625';
}

.xgl-car_side_alt::before {
  content: '\E626';
}

.xgl-car_side_two_tone::before {
  content: '\E625';
}

.xgl-car_side_two_tone::after {
  content: '\E626';
  opacity: .2;
}

.xgl-card::before {
  content: '\E0B1';
}

.xgl-card_alt::before {
  content: '\EC80';
}

.xgl-card_chip::before {
  content: '\E0B2';
}

.xgl-card_chip_alt::before {
  content: '\E0B3';
}

.xgl-card_chip_two_tone::before {
  content: '\E0B2';
}

.xgl-card_chip_two_tone::after {
  content: '\E0B3';
  opacity: .2;
}

.xgl-card_two_tone::before {
  content: '\E0B1';
}

.xgl-card_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-cardstock_lef::before {
  content: '\EBD7';
}

.xgl-cardstock_lef_alt::before {
  content: '\EBD8';
}

.xgl-cardstock_lef_two_tone::before {
  content: '\EBD7';
}

.xgl-cardstock_lef_two_tone::after {
  content: '\EBD8';
  opacity: .2;
}

.xgl-cardstock_sef::before {
  content: '\EBD5';
}

.xgl-cardstock_sef_alt::before {
  content: '\EBD6';
}

.xgl-cardstock_sef_two_tone::before {
  content: '\EBD5';
}

.xgl-cardstock_sef_two_tone::after {
  content: '\EBD6';
  opacity: .2;
}

.xgl-carousel::before {
  content: '\E0B4';
}

.xgl-carousel_add::before {
  content: '\EAE5';
}

.xgl-carousel_add_alt::before {
  content: '\EAE6';
}

.xgl-carousel_add_two_tone::before {
  content: '\EAE5';
}

.xgl-carousel_add_two_tone::after {
  content: '\EAE6';
  opacity: .2;
}

.xgl-carousel_alt::before {
  content: '\E0B5';
}

.xgl-carousel_left::before {
  content: '\E889';
}

.xgl-carousel_right::before {
  content: '\E88A';
}

.xgl-carousel_two_tone::before {
  content: '\E0B4';
}

.xgl-carousel_two_tone::after {
  content: '\E0B5';
  opacity: .2;
}

.xgl-cart::before {
  content: '\E0B6';
}

.xgl-cart_alt::before {
  content: '\E0B7';
}

.xgl-cart_disabled::before {
  content: '\E0B8';
}

.xgl-cart_disabled_alt::before {
  content: '\E0B9';
}

.xgl-cart_disabled_two_tone::before {
  content: '\E0B8';
}

.xgl-cart_disabled_two_tone::after {
  content: '\E0B9';
  opacity: .2;
}

.xgl-cart_overlay::before {
  content: '\E0BA';
}

.xgl-cart_two_tone::before {
  content: '\E0B6';
}

.xgl-cart_two_tone::after {
  content: '\E0B7';
  opacity: .2;
}

.xgl-cast_coated_lef::before {
  content: '\E0BB';
}

.xgl-cast_coated_lef_alt::before {
  content: '\E6CA';
}

.xgl-cast_coated_lef_two_tone::before {
  content: '\E0BB';
}

.xgl-cast_coated_lef_two_tone::after {
  content: '\E6CA';
  opacity: .2;
}

.xgl-cast_coated_sef::before {
  content: '\E0BC';
}

.xgl-cast_coated_sef_alt::before {
  content: '\E6CB';
}

.xgl-cast_coated_sef_two_tone::before {
  content: '\E0BC';
}

.xgl-cast_coated_sef_two_tone::after {
  content: '\E6CB';
  opacity: .2;
}

.xgl-center_model::before {
  content: '\EE35';
}

.xgl-center_model_alt::before {
  content: '\EE36';
}

.xgl-center_model_two_tone::before {
  content: '\EE35';
}

.xgl-center_model_two_tone::after {
  content: '\EE36';
  opacity: .2;
}

.xgl-center_point::before {
  content: '\ED9E';
}

.xgl-center_point_alt::before {
  content: '\EDF3';
}

.xgl-center_point_two_tone::before {
  content: '\ED9E';
}

.xgl-center_point_two_tone::after {
  content: '\EDF3';
  opacity: .2;
}

.xgl-center_square::before {
  content: '\EE11';
}

.xgl-center_square_alt::before {
  content: '\EE12';
}

.xgl-center_square_two_tone::before {
  content: '\EE11';
}

.xgl-center_square_two_tone::after {
  content: '\EE12';
  opacity: .2;
}

.xgl-center_text::before {
  content: '\E0BD';
}

.xgl-certificate::before {
  content: '\E0BE';
}

.xgl-certificate_alt::before {
  content: '\E0BF';
}

.xgl-certificate_blank::before {
  content: '\E0C0';
}

.xgl-certificate_blank_alt::before {
  content: '\E0C1';
}

.xgl-certificate_blank_two_tone::before {
  content: '\E0C0';
}

.xgl-certificate_blank_two_tone::after {
  content: '\E0C1';
  opacity: .2;
}

.xgl-certificate_download::before {
  content: '\E7FA';
}

.xgl-certificate_download_alt::before {
  content: '\E7F9';
}

.xgl-certificate_download_two_tone::before {
  content: '\E7FA';
}

.xgl-certificate_download_two_tone::after {
  content: '\E7F9';
  opacity: .2;
}

.xgl-certificate_error::before {
  content: '\E0C2';
}

.xgl-certificate_error_alt::before {
  content: '\E0C3';
}

.xgl-certificate_error_two_tone::before {
  content: '\E0C2';
}

.xgl-certificate_error_two_tone::after {
  content: '\E0C3';
  opacity: .2;
}

.xgl-certificate_favorite::before {
  content: '\EA2A';
}

.xgl-certificate_favorite_alt::before {
  content: '\EA3C';
}

.xgl-certificate_favorite_two_tone::before {
  content: '\EA2A';
}

.xgl-certificate_favorite_two_tone::after {
  content: '\EA3C';
  opacity: .2;
}

.xgl-certificate_group::before {
  content: '\EA3D';
}

.xgl-certificate_group_alt::before {
  content: '\EA3E';
}

.xgl-certificate_group_favorite::before {
  content: '\EA3F';
}

.xgl-certificate_group_favorite_alt::before {
  content: '\EA42';
}

.xgl-certificate_group_favorite_two_tone::before {
  content: '\EA3F';
}

.xgl-certificate_group_favorite_two_tone::after {
  content: '\EA42';
  opacity: .2;
}

.xgl-certificate_group_two_tone::before {
  content: '\EA3D';
}

.xgl-certificate_group_two_tone::after {
  content: '\EA3E';
  opacity: .2;
}

.xgl-certificate_processing::before {
  content: '\E844';
}

.xgl-certificate_processing_alt::before {
  content: '\E845';
}

.xgl-certificate_processing_two_tone::before {
  content: '\E844';
}

.xgl-certificate_processing_two_tone::after {
  content: '\E845';
  opacity: .2;
}

.xgl-certificate_two_tone::before {
  content: '\E0BE';
}

.xgl-certificate_two_tone::after {
  content: '\E0BF';
  opacity: .2;
}

.xgl-certificate_unverified::before {
  content: '\E846';
}

.xgl-certificate_unverified_alt::before {
  content: '\E847';
}

.xgl-certificate_unverified_two_tone::before {
  content: '\E846';
}

.xgl-certificate_unverified_two_tone::after {
  content: '\E847';
  opacity: .2;
}

.xgl-certificate_upload::before {
  content: '\E0C4';
}

.xgl-certificate_upload_alt::before {
  content: '\E0C5';
}

.xgl-certificate_upload_two_tone::before {
  content: '\E0C4';
}

.xgl-certificate_upload_two_tone::after {
  content: '\E0C5';
  opacity: .2;
}

.xgl-certificate_user::before {
  content: '\EA40';
}

.xgl-certificate_user_alt::before {
  content: '\EA41';
}

.xgl-certificate_user_two_tone::before {
  content: '\EA40';
}

.xgl-certificate_user_two_tone::after {
  content: '\EA41';
  opacity: .2;
}

.xgl-certified::before {
  content: '\E0C6';
}

.xgl-certified_alt::before {
  content: '\E0C7';
}

.xgl-certified_rtl::before {
  content: '\E5AF';
}

.xgl-certified_rtl_alt::before {
  content: '\E5AE';
}

.xgl-certified_rtl_two_tone::before {
  content: '\E5AF';
}

.xgl-certified_rtl_two_tone::after {
  content: '\E5AE';
  opacity: .2;
}

.xgl-certified_two_tone::before {
  content: '\E0C6';
}

.xgl-certified_two_tone::after {
  content: '\E0C7';
  opacity: .2;
}

.xgl-change::before {
  content: '\E0C8';
}

.xgl-chapter_start::before {
  content: '\EA43';
}

.xgl-chapter_start_alt::before {
  content: '\EA44';
}

.xgl-chapter_start_left::before {
  content: '\EA45';
}

.xgl-chapter_start_left_alt::before {
  content: '\EA46';
}

.xgl-chapter_start_left_two_tone::before {
  content: '\EA45';
}

.xgl-chapter_start_left_two_tone::after {
  content: '\EA46';
  opacity: .2;
}

.xgl-chapter_start_two_tone::before {
  content: '\EA43';
}

.xgl-chapter_start_two_tone::after {
  content: '\EA44';
  opacity: .2;
}

.xgl-chart::before {
  content: '\E0C9';
}

.xgl-chart_alt::before {
  content: '\E0CA';
}

.xgl-chart_two_tone::before {
  content: '\E0C9';
}

.xgl-chart_two_tone::after {
  content: '\E0CA';
  opacity: .2;
}

.xgl-chat::before {
  content: '\E0CB';
}

.xgl-chat_alt::before {
  content: '\E0CC';
}

.xgl-chat_ellipsis::before {
  content: '\EA8F';
}

.xgl-chat_ellipsis_alt::before {
  content: '\EA90';
}

.xgl-chat_ellipsis_two_tone::before {
  content: '\EA8F';
}

.xgl-chat_ellipsis_two_tone::after {
  content: '\EA90';
  opacity: .2;
}

.xgl-chat_multiple::before {
  content: '\E0CD';
}

.xgl-chat_multiple_alt::before {
  content: '\E0CE';
}

.xgl-chat_multiple_ellipsis::before {
  content: '\E0CF';
}

.xgl-chat_multiple_ellipsis_alt::before {
  content: '\E0D0';
}

.xgl-chat_multiple_ellipsis_two_tone::before {
  content: '\E0CF';
}

.xgl-chat_multiple_ellipsis_two_tone::after {
  content: '\E0D0';
  opacity: .2;
}

.xgl-chat_multiple_two_tone::before {
  content: '\E0CD';
}

.xgl-chat_multiple_two_tone::after {
  content: '\E0CE';
  opacity: .2;
}

.xgl-chat_text::before {
  content: '\E0D1';
}

.xgl-chat_text_alt::before {
  content: '\E0D2';
}

.xgl-chat_text_rtl::before {
  content: '\E5AD';
}

.xgl-chat_text_rtl_alt::before {
  content: '\E5AC';
}

.xgl-chat_text_rtl_two_tone::before {
  content: '\E5AD';
}

.xgl-chat_text_rtl_two_tone::after {
  content: '\E5AC';
  opacity: .2;
}

.xgl-chat_text_two_tone::before {
  content: '\E0D1';
}

.xgl-chat_text_two_tone::after {
  content: '\E0D2';
  opacity: .2;
}

.xgl-chat_two_tone::before {
  content: '\E0CB';
}

.xgl-chat_two_tone::after {
  content: '\E0CC';
  opacity: .2;
}

.xgl-check_background::before {
  content: '\E0D3';
}

.xgl-check_off::before {
  content: '\E0D4';
}

.xgl-check_on::before {
  content: '\E0D5';
}

.xgl-check_on_press::before {
  content: '\E0D6';
}

.xgl-check_switch::before {
  content: '\EA91';
}

.xgl-checklist::before {
  content: '\E0D7';
}

.xgl-checklist_alt::before {
  content: '\E0D8';
}

.xgl-checklist_rtl::before {
  content: '\E5A0';
}

.xgl-checklist_rtl_alt::before {
  content: '\E5A1';
}

.xgl-checklist_rtl_two_tone::before {
  content: '\E5A0';
}

.xgl-checklist_rtl_two_tone::after {
  content: '\E5A1';
  opacity: .2;
}

.xgl-checklist_two_tone::before {
  content: '\E0D7';
}

.xgl-checklist_two_tone::after {
  content: '\E0D8';
  opacity: .2;
}

.xgl-checkmark::before {
  content: '\E0D9';
}

.xgl-checkmark_circle::before {
  content: '\E0DA';
}

.xgl-checkmark_circle_alt::before {
  content: '\E0DB';
}

.xgl-checkmark_circle_break::before {
  content: '\E0DC';
}

.xgl-checkmark_circle_break_alt::before {
  content: '\E7F8';
}

.xgl-checkmark_circle_break_two_tone::before {
  content: '\E0DC';
}

.xgl-checkmark_circle_break_two_tone::after {
  content: '\E7F8';
  opacity: .2;
}

.xgl-checkmark_circle_two_tone::before {
  content: '\E0DA';
}

.xgl-checkmark_circle_two_tone::after {
  content: '\E0DB';
  opacity: .2;
}

.xgl-chip_card::before {
  content: '\E97E';
}

.xgl-chip_card_alt::before {
  content: '\E97F';
}

.xgl-chip_card_two_tone::before {
  content: '\E97E';
}

.xgl-chip_card_two_tone::after {
  content: '\E97F';
  opacity: .2;
}

.xgl-chrome::before {
  content: '\E0DD';
}

.xgl-chrome_alt::before {
  content: '\EC77';
}

.xgl-chrome_two_tone::before {
  content: '\E0DD';
}

.xgl-chrome_two_tone::after {
  content: '\EC77';
  opacity: .2;
}

.xgl-circle::before {
  content: '\E0DE';
}

.xgl-circle_alt::before {
  content: '\E0DF';
}

.xgl-circle_bkgd::before {
  content: '\E0E0';
}

.xgl-circle_two_tone::before {
  content: '\E0DE';
}

.xgl-circle_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-circuit::before {
  content: '\E0E1';
}

.xgl-circuit_alt::before {
  content: '\E0E2';
}

.xgl-circuit_two_tone::before {
  content: '\E0E1';
}

.xgl-circuit_two_tone::after {
  content: '\E0E2';
  opacity: .2;
}

.xgl-circular_eighty::before {
  content: '\E876';
}

.xgl-circular_eighty_five::before {
  content: '\E877';
}

.xgl-circular_fifteen::before {
  content: '\E869';
}

.xgl-circular_fifty::before {
  content: '\E870';
}

.xgl-circular_fifty_five::before {
  content: '\E871';
}

.xgl-circular_five::before {
  content: '\E867';
}

.xgl-circular_fourty::before {
  content: '\E86E';
}

.xgl-circular_fourty_five::before {
  content: '\E86F';
}

.xgl-circular_hundred::before {
  content: '\E87A';
}

.xgl-circular_ninety::before {
  content: '\E878';
}

.xgl-circular_ninety_five::before {
  content: '\E879';
}

.xgl-circular_seventy::before {
  content: '\E874';
}

.xgl-circular_seventy_five::before {
  content: '\E875';
}

.xgl-circular_sixty::before {
  content: '\E872';
}

.xgl-circular_sixty_five::before {
  content: '\E873';
}

.xgl-circular_ten::before {
  content: '\E868';
}

.xgl-circular_thirty::before {
  content: '\E86C';
}

.xgl-circular_thirty_five::before {
  content: '\E86D';
}

.xgl-circular_twenty::before {
  content: '\E86A';
}

.xgl-circular_twenty_five::before {
  content: '\E86B';
}

.xgl-circular_zero::before {
  content: '\E866';
}

.xgl-cleaner::before {
  content: '\E66F';
}

.xgl-cleaner_alt::before {
  content: '\E670';
}

.xgl-cleaner_two_tone::before {
  content: '\E66F';
}

.xgl-cleaner_two_tone::after {
  content: '\E670';
  opacity: .2;
}

.xgl-cleaning::before {
  content: '\E771';
}

.xgl-cleaning_alt::before {
  content: '\E772';
}

.xgl-cleaning_two_tone::before {
  content: '\E771';
}

.xgl-cleaning_two_tone::after {
  content: '\E772';
  opacity: .2;
}

.xgl-clear_all::before {
  content: '\E0E3';
}

.xgl-clear_all_alt::before {
  content: '\E0E4';
}

.xgl-clear_all_panel::before {
  content: '\E773';
}

.xgl-clear_all_two_tone::before {
  content: '\E0E3';
}

.xgl-clear_all_two_tone::after {
  content: '\E0E4';
  opacity: .2;
}

.xgl-clear_fax::before {
  content: '\E9E1';
}

.xgl-clear_fax_alt::before {
  content: '\E9E2';
}

.xgl-clear_fax_two_tone::before {
  content: '\E9E1';
}

.xgl-clear_fax_two_tone::after {
  content: '\E9E2';
  opacity: .2;
}

.xgl-clipboard::before {
  content: '\E0E5';
}

.xgl-clipboard_accounting::before {
  content: '\EC34';
}

.xgl-clipboard_accounting_alt::before {
  content: '\EC35';
}

.xgl-clipboard_accounting_two_tone::before {
  content: '\EC34';
}

.xgl-clipboard_accounting_two_tone::after {
  content: '\EC35';
  opacity: .2;
}

.xgl-clipboard_add::before {
  content: '\E627';
}

.xgl-clipboard_add_alt::before {
  content: '\E628';
}

.xgl-clipboard_add_two_tone::before {
  content: '\E627';
}

.xgl-clipboard_add_two_tone::after {
  content: '\E628';
  opacity: .2;
}

.xgl-clipboard_alt::before {
  content: '\E0E6';
}

.xgl-clipboard_check::before {
  content: '\E0E7';
}

.xgl-clipboard_check_alt::before {
  content: '\E0E8';
}

.xgl-clipboard_check_disabled::before {
  content: '\EA20';
}

.xgl-clipboard_check_disabled_alt::before {
  content: '\EA21';
}

.xgl-clipboard_check_disabled_two_tone::before {
  content: '\EA20';
}

.xgl-clipboard_check_disabled_two_tone::after {
  content: '\EA21';
  opacity: .2;
}

.xgl-clipboard_check_rtl::before {
  content: '\E58B';
}

.xgl-clipboard_check_rtl_alt::before {
  content: '\E58C';
}

.xgl-clipboard_check_rtl_two_tone::before {
  content: '\E58B';
}

.xgl-clipboard_check_rtl_two_tone::after {
  content: '\E58C';
  opacity: .2;
}

.xgl-clipboard_check_two_tone::before {
  content: '\E0E7';
}

.xgl-clipboard_check_two_tone::after {
  content: '\E0E8';
  opacity: .2;
}

.xgl-clipboard_data::before {
  content: '\E0E9';
}

.xgl-clipboard_data_alt::before {
  content: '\E0EA';
}

.xgl-clipboard_data_two_tone::before {
  content: '\E0E9';
}

.xgl-clipboard_data_two_tone::after {
  content: '\E0EA';
  opacity: .2;
}

.xgl-clipboard_disabled::before {
  content: '\EA22';
}

.xgl-clipboard_disabled_alt::before {
  content: '\EA23';
}

.xgl-clipboard_disabled_two_tone::before {
  content: '\EA22';
}

.xgl-clipboard_disabled_two_tone::after {
  content: '\EA23';
  opacity: .2;
}

.xgl-clipboard_error::before {
  content: '\EA24';
}

.xgl-clipboard_error_alt::before {
  content: '\EA25';
}

.xgl-clipboard_error_two_tone::before {
  content: '\EA24';
}

.xgl-clipboard_error_two_tone::after {
  content: '\EA25';
  opacity: .2;
}

.xgl-clipboard_list::before {
  content: '\E0EB';
}

.xgl-clipboard_list_alt::before {
  content: '\E0EC';
}

.xgl-clipboard_list_rtl::before {
  content: '\E58D';
}

.xgl-clipboard_list_rtl_alt::before {
  content: '\E58E';
}

.xgl-clipboard_list_rtl_two_tone::before {
  content: '\E58D';
}

.xgl-clipboard_list_rtl_two_tone::after {
  content: '\E58E';
  opacity: .2;
}

.xgl-clipboard_list_two_tone::before {
  content: '\E0EB';
}

.xgl-clipboard_list_two_tone::after {
  content: '\E0EC';
  opacity: .2;
}

.xgl-clipboard_print::before {
  content: '\EB9C';
}

.xgl-clipboard_print_alt::before {
  content: '\EB9D';
}

.xgl-clipboard_print_two_tone::before {
  content: '\EB9C';
}

.xgl-clipboard_print_two_tone::after {
  content: '\EB9D';
  opacity: .2;
}

.xgl-clipboard_two_tone::before {
  content: '\E0E5';
}

.xgl-clipboard_two_tone::after {
  content: '\E0E6';
  opacity: .2;
}

.xgl-clipping_plane::before {
  content: '\ECDB';
}

.xgl-clipping_plane_alt::before {
  content: '\ECDC';
}

.xgl-clipping_plane_two_tone::before {
  content: '\ECDB';
}

.xgl-clipping_plane_two_tone::after {
  content: '\ECDC';
  opacity: .2;
}

.xgl-clock::before {
  content: '\E0ED';
}

.xgl-clock_alt::before {
  content: '\E0EE';
}

.xgl-clock_square::before {
  content: '\E0EF';
}

.xgl-clock_square_alt::before {
  content: '\E0F0';
}

.xgl-clock_square_two_tone::before {
  content: '\E0EF';
}

.xgl-clock_square_two_tone::after {
  content: '\E0F0';
  opacity: .2;
}

.xgl-clock_two_tone::before {
  content: '\E0ED';
}

.xgl-clock_two_tone::after {
  content: '\E0EE';
  opacity: .2;
}

.xgl-clone::before {
  content: '\E62F';
}

.xgl-clone_alt::before {
  content: '\E630';
}

.xgl-clone_destination::before {
  content: '\E631';
}

.xgl-clone_source::before {
  content: '\E632';
}

.xgl-clone_two_tone::before {
  content: '\E62F';
}

.xgl-clone_two_tone::after {
  content: '\E630';
  opacity: .2;
}

.xgl-close::before {
  content: '\E0F1';
}

.xgl-close_square::before {
  content: '\E0F2';
}

.xgl-close_square_alt::before {
  content: '\E0F3';
}

.xgl-close_square_two_tone::before {
  content: '\E0F2';
}

.xgl-close_square_two_tone::after {
  content: '\E0F3';
  opacity: .2;
}

.xgl-cloud::before {
  content: '\E0F4';
}

.xgl-cloud_add::before {
  content: '\E0F5';
}

.xgl-cloud_add_alt::before {
  content: '\E0F6';
}

.xgl-cloud_add_square::before {
  content: '\E0F7';
}

.xgl-cloud_add_square_alt::before {
  content: '\E0F8';
}

.xgl-cloud_add_square_two_tone::before {
  content: '\E0F7';
}

.xgl-cloud_add_square_two_tone::after {
  content: '\E0F8';
  opacity: .2;
}

.xgl-cloud_add_two_tone::before {
  content: '\E0F5';
}

.xgl-cloud_add_two_tone::after {
  content: '\E0F6';
  opacity: .2;
}

.xgl-cloud_alt::before {
  content: '\E0F9';
}

.xgl-cloud_check::before {
  content: '\E0FA';
}

.xgl-cloud_check_alt::before {
  content: '\E0FB';
}

.xgl-cloud_check_square::before {
  content: '\E0FC';
}

.xgl-cloud_check_square_alt::before {
  content: '\E0FD';
}

.xgl-cloud_check_square_two_tone::before {
  content: '\E0FC';
}

.xgl-cloud_check_square_two_tone::after {
  content: '\E0FD';
  opacity: .2;
}

.xgl-cloud_check_two_tone::before {
  content: '\E0FA';
}

.xgl-cloud_check_two_tone::after {
  content: '\E0FB';
  opacity: .2;
}

.xgl-cloud_circle::before {
  content: '\E0FE';
}

.xgl-cloud_circle_alt::before {
  content: '\E0FF';
}

.xgl-cloud_circle_two_tone::before {
  content: '\E0FE';
}

.xgl-cloud_circle_two_tone::after {
  content: '\E0FF';
  opacity: .2;
}

.xgl-cloud_close::before {
  content: '\E100';
}

.xgl-cloud_close_alt::before {
  content: '\E101';
}

.xgl-cloud_close_square::before {
  content: '\E102';
}

.xgl-cloud_close_square_alt::before {
  content: '\E103';
}

.xgl-cloud_close_square_two_tone::before {
  content: '\E102';
}

.xgl-cloud_close_square_two_tone::after {
  content: '\E103';
  opacity: .2;
}

.xgl-cloud_close_two_tone::before {
  content: '\E100';
}

.xgl-cloud_close_two_tone::after {
  content: '\E101';
  opacity: .2;
}

.xgl-cloud_connect::before {
  content: '\E80F';
}

.xgl-cloud_connect_alt::before {
  content: '\EA26';
}

.xgl-cloud_connect_two_tone::before {
  content: '\E80F';
}

.xgl-cloud_connect_two_tone::after {
  content: '\EA26';
  opacity: .2;
}

.xgl-cloud_disabled::before {
  content: '\E104';
}

.xgl-cloud_disabled_alt::before {
  content: '\E105';
}

.xgl-cloud_disabled_square::before {
  content: '\E106';
}

.xgl-cloud_disabled_square_alt::before {
  content: '\E107';
}

.xgl-cloud_disabled_square_two_tone::before {
  content: '\E106';
}

.xgl-cloud_disabled_square_two_tone::after {
  content: '\E107';
  opacity: .2;
}

.xgl-cloud_disabled_two_tone::before {
  content: '\E104';
}

.xgl-cloud_disabled_two_tone::after {
  content: '\E105';
  opacity: .2;
}

.xgl-cloud_download::before {
  content: '\E108';
}

.xgl-cloud_download_alt::before {
  content: '\E109';
}

.xgl-cloud_download_square::before {
  content: '\E10A';
}

.xgl-cloud_download_square_alt::before {
  content: '\E10B';
}

.xgl-cloud_download_square_two_tone::before {
  content: '\E10A';
}

.xgl-cloud_download_square_two_tone::after {
  content: '\E10B';
  opacity: .2;
}

.xgl-cloud_download_two_tone::before {
  content: '\E108';
}

.xgl-cloud_download_two_tone::after {
  content: '\E109';
  opacity: .2;
}

.xgl-cloud_print::before {
  content: '\E10C';
}

.xgl-cloud_print_alt::before {
  content: '\E10D';
}

.xgl-cloud_print_square::before {
  content: '\E10E';
}

.xgl-cloud_print_square_alt::before {
  content: '\E10F';
}

.xgl-cloud_print_square_two_tone::before {
  content: '\E10E';
}

.xgl-cloud_print_square_two_tone::after {
  content: '\E10F';
  opacity: .2;
}

.xgl-cloud_print_two_tone::before {
  content: '\E10C';
}

.xgl-cloud_print_two_tone::after {
  content: '\E10D';
  opacity: .2;
}

.xgl-cloud_square::before {
  content: '\E110';
}

.xgl-cloud_square_alt::before {
  content: '\E111';
}

.xgl-cloud_square_two_tone::before {
  content: '\E110';
}

.xgl-cloud_square_two_tone::after {
  content: '\E111';
  opacity: .2;
}

.xgl-cloud_subtract::before {
  content: '\E112';
}

.xgl-cloud_subtract_alt::before {
  content: '\E113';
}

.xgl-cloud_subtract_square::before {
  content: '\E114';
}

.xgl-cloud_subtract_square_alt::before {
  content: '\E115';
}

.xgl-cloud_subtract_square_two_tone::before {
  content: '\E114';
}

.xgl-cloud_subtract_square_two_tone::after {
  content: '\E115';
  opacity: .2;
}

.xgl-cloud_subtract_two_tone::before {
  content: '\E112';
}

.xgl-cloud_subtract_two_tone::after {
  content: '\E113';
  opacity: .2;
}

.xgl-cloud_token::before {
  content: '\EC33';
}

.xgl-cloud_token_alt::before {
  content: '\EC67';
}

.xgl-cloud_token_two_tone::before {
  content: '\EC33';
}

.xgl-cloud_token_two_tone::after {
  content: '\EC67';
  opacity: .2;
}

.xgl-cloud_transfer::before {
  content: '\E116';
}

.xgl-cloud_transfer_alt::before {
  content: '\EAB9';
}

.xgl-cloud_transfer_two_tone::before {
  content: '\E116';
}

.xgl-cloud_transfer_two_tone::after {
  content: '\EAB9';
  opacity: .2;
}

.xgl-cloud_two_tone::before {
  content: '\E0F4';
}

.xgl-cloud_two_tone::after {
  content: '\E0F9';
  opacity: .2;
}

.xgl-cloud_upload::before {
  content: '\E117';
}

.xgl-cloud_upload_alt::before {
  content: '\E118';
}

.xgl-cloud_upload_square::before {
  content: '\E119';
}

.xgl-cloud_upload_square_alt::before {
  content: '\E11A';
}

.xgl-cloud_upload_square_two_tone::before {
  content: '\E119';
}

.xgl-cloud_upload_square_two_tone::after {
  content: '\E11A';
  opacity: .2;
}

.xgl-cloud_upload_two_tone::before {
  content: '\E117';
}

.xgl-cloud_upload_two_tone::after {
  content: '\E118';
  opacity: .2;
}

.xgl-code::before {
  content: '\E11B';
}

.xgl-code_square::before {
  content: '\E11C';
}

.xgl-code_square_alt::before {
  content: '\E11D';
}

.xgl-code_square_two_tone::before {
  content: '\E11C';
}

.xgl-code_square_two_tone::after {
  content: '\E11D';
  opacity: .2;
}

.xgl-coil_bottom_l::before {
  content: '\ED9F';
}

.xgl-coil_bottom_l_alt::before {
  content: '\EDA0';
}

.xgl-coil_bottom_l_two_tone::before {
  content: '\ED9F';
}

.xgl-coil_bottom_l_two_tone::after {
  content: '\EDA0';
  opacity: .2;
}

.xgl-coil_bottom_p::before {
  content: '\EDA1';
}

.xgl-coil_bottom_p_alt::before {
  content: '\EDA2';
}

.xgl-coil_bottom_p_two_tone::before {
  content: '\EDA1';
}

.xgl-coil_bottom_p_two_tone::after {
  content: '\EDA2';
  opacity: .2;
}

.xgl-coil_left_l::before {
  content: '\EDA3';
}

.xgl-coil_left_l_alt::before {
  content: '\EDA4';
}

.xgl-coil_left_l_two_tone::before {
  content: '\EDA3';
}

.xgl-coil_left_l_two_tone::after {
  content: '\EDA4';
  opacity: .2;
}

.xgl-coil_left_p::before {
  content: '\EDA5';
}

.xgl-coil_left_p_alt::before {
  content: '\EDA6';
}

.xgl-coil_left_p_two_tone::before {
  content: '\EDA5';
}

.xgl-coil_left_p_two_tone::after {
  content: '\EDA6';
  opacity: .2;
}

.xgl-coil_right_l::before {
  content: '\EDA7';
}

.xgl-coil_right_l_alt::before {
  content: '\EDA8';
}

.xgl-coil_right_l_two_tone::before {
  content: '\EDA7';
}

.xgl-coil_right_l_two_tone::after {
  content: '\EDA8';
  opacity: .2;
}

.xgl-coil_right_p::before {
  content: '\EDA9';
}

.xgl-coil_right_p_alt::before {
  content: '\EDAA';
}

.xgl-coil_right_p_two_tone::before {
  content: '\EDA9';
}

.xgl-coil_right_p_two_tone::after {
  content: '\EDAA';
  opacity: .2;
}

.xgl-coil_top_l::before {
  content: '\EDAB';
}

.xgl-coil_top_l_alt::before {
  content: '\EDAC';
}

.xgl-coil_top_l_two_tone::before {
  content: '\EDAB';
}

.xgl-coil_top_l_two_tone::after {
  content: '\EDAC';
  opacity: .2;
}

.xgl-coil_top_p::before {
  content: '\EDAD';
}

.xgl-coil_top_p_alt::before {
  content: '\EDAE';
}

.xgl-coil_top_p_two_tone::before {
  content: '\EDAD';
}

.xgl-coil_top_p_two_tone::after {
  content: '\EDAE';
  opacity: .2;
}

.xgl-coin_star::before {
  content: '\E11E';
}

.xgl-coin_star_alt::before {
  content: '\E11F';
}

.xgl-coin_star_two_tone::before {
  content: '\E11E';
}

.xgl-coin_star_two_tone::after {
  content: '\E11F';
  opacity: .2;
}

.xgl-coins::before {
  content: '\E120';
}

.xgl-coins_alt::before {
  content: '\E121';
}

.xgl-coins_two_tone::before {
  content: '\E120';
}

.xgl-coins_two_tone::after {
  content: '\E121';
  opacity: .2;
}

.xgl-collate::before {
  content: '\EA29';
}

.xgl-collate_alt::before {
  content: '\EA47';
}

.xgl-collate_off::before {
  content: '\EA48';
}

.xgl-collate_off_alt::before {
  content: '\EA49';
}

.xgl-collate_off_two_tone::before {
  content: '\EA48';
}

.xgl-collate_off_two_tone::after {
  content: '\EA49';
  opacity: .2;
}

.xgl-collate_two_tone::before {
  content: '\EA29';
}

.xgl-collate_two_tone::after {
  content: '\EA47';
  opacity: .2;
}

.xgl-color_balance_square::before {
  content: '\EA4A';
}

.xgl-color_balance_square_alt::before {
  content: '\EA4B';
}

.xgl-color_balance_square_two_tone::before {
  content: '\EA4A';
}

.xgl-color_balance_square_two_tone::after {
  content: '\EA4B';
  opacity: .2;
}

.xgl-color_effects::before {
  content: '\EA4C';
}

.xgl-color_effects_alt::before {
  content: '\EA4D';
}

.xgl-color_effects_two_tone::before {
  content: '\EA4C';
}

.xgl-color_effects_two_tone::after {
  content: '\EA4D';
  opacity: .2;
}

.xgl-color_key::before {
  content: '\E7EF';
}

.xgl-color_overlay::before {
  content: '\E122';
}

.xgl-color_palette::before {
  content: '\E8A5';
}

.xgl-color_palette_alt::before {
  content: '\E8A6';
}

.xgl-color_palette_two_tone::before {
  content: '\E8A5';
}

.xgl-color_palette_two_tone::after {
  content: '\E8A6';
  opacity: .2;
}

.xgl-color_wheel::before {
  content: '\E623';
}

.xgl-color_wheel_alt::before {
  content: '\E624';
}

.xgl-color_wheel_two_tone::before {
  content: '\E623';
}

.xgl-color_wheel_two_tone::after {
  content: '\E624';
  opacity: .2;
}

.xgl-columns::before {
  content: '\E123';
}

.xgl-columns_alt::before {
  content: '\E124';
}

.xgl-columns_off::before {
  content: '\EDFA';
}

.xgl-columns_off_alt::before {
  content: '\EDFB';
}

.xgl-columns_off_two_tone::before {
  content: '\EDFA';
}

.xgl-columns_off_two_tone::after {
  content: '\EDFB';
  opacity: .2;
}

.xgl-columns_two_tone::before {
  content: '\E123';
}

.xgl-columns_two_tone::after {
  content: '\E124';
  opacity: .2;
}

.xgl-compass_tool::before {
  content: '\E125';
}

.xgl-completed_error_bkgd::before {
  content: '\E980';
}

.xgl-completed_error_frgd::before {
  content: '\E981';
}

.xgl-completed_error_mdgd::before {
  content: '\E982';
}

.xgl-compose::before {
  content: '\E126';
}

.xgl-compose_alt::before {
  content: '\EC0B';
}

.xgl-compose_two_tone::before {
  content: '\E126';
}

.xgl-compose_two_tone::after {
  content: '\EC0B';
  opacity: .2;
}

.xgl-compress::before {
  content: '\E127';
}

.xgl-compress_alt::before {
  content: '\E128';
}

.xgl-compress_two_tone::before {
  content: '\E127';
}

.xgl-compress_two_tone::after {
  content: '\E128';
  opacity: .2;
}

.xgl-computer::before {
  content: '\E129';
}

.xgl-computer_alt::before {
  content: '\E12A';
}

.xgl-computer_two_tone::before {
  content: '\E129';
}

.xgl-computer_two_tone::after {
  content: '\E12A';
  opacity: .2;
}

.xgl-confidential_danger::before {
  content: '\E12B';
}

.xgl-confidential_danger_alt::before {
  content: '\E12C';
}

.xgl-confidential_danger_two_tone::before {
  content: '\E12B';
}

.xgl-confidential_danger_two_tone::after {
  content: '\E12C';
  opacity: .2;
}

.xgl-confidential_ok::before {
  content: '\E12D';
}

.xgl-confidential_ok_alt::before {
  content: '\E12E';
}

.xgl-confidential_ok_two_tone::before {
  content: '\E12D';
}

.xgl-confidential_ok_two_tone::after {
  content: '\E12E';
  opacity: .2;
}

.xgl-confidential_warning::before {
  content: '\E12F';
}

.xgl-confidential_warning_alt::before {
  content: '\E130';
}

.xgl-confidential_warning_two_tone::before {
  content: '\E12F';
}

.xgl-confidential_warning_two_tone::after {
  content: '\E130';
  opacity: .2;
}

.xgl-configuration_report::before {
  content: '\E131';
}

.xgl-configuration_report_alt::before {
  content: '\E132';
}

.xgl-configuration_report_two_tone::before {
  content: '\E131';
}

.xgl-configuration_report_two_tone::after {
  content: '\E132';
  opacity: .2;
}

.xgl-confirmation_report::before {
  content: '\EA4E';
}

.xgl-confirmation_report_alt::before {
  content: '\EA4F';
}

.xgl-confirmation_report_two_tone::before {
  content: '\EA4E';
}

.xgl-confirmation_report_two_tone::after {
  content: '\EA4F';
  opacity: .2;
}

.xgl-connect::before {
  content: '\E7BB';
}

.xgl-connect_alt::before {
  content: '\E7BC';
}

.xgl-connect_two_tone::before {
  content: '\E7BB';
}

.xgl-connect_two_tone::after {
  content: '\E7BC';
  opacity: .2;
}

.xgl-connected::before {
  content: '\E983';
}

.xgl-connected_alt::before {
  content: '\E984';
}

.xgl-connected_stack_bkgd::before {
  content: '\E985';
}

.xgl-connected_stack_frgd::before {
  content: '\E986';
}

.xgl-connected_stack_mdgd::before {
  content: '\E987';
}

.xgl-connected_two_tone::before {
  content: '\E983';
}

.xgl-connected_two_tone::after {
  content: '\E984';
  opacity: .2;
}

.xgl-console::before {
  content: '\E133';
}

.xgl-console_alt::before {
  content: '\E134';
}

.xgl-console_two_tone::before {
  content: '\E133';
}

.xgl-console_two_tone::after {
  content: '\E134';
  opacity: .2;
}

.xgl-contact_card::before {
  content: '\E135';
}

.xgl-contact_card_alt::before {
  content: '\E136';
}

.xgl-contact_card_group::before {
  content: '\EA50';
}

.xgl-contact_card_group_alt::before {
  content: '\EA51';
}

.xgl-contact_card_group_two_tone::before {
  content: '\EA50';
}

.xgl-contact_card_group_two_tone::after {
  content: '\EA51';
  opacity: .2;
}

.xgl-contact_card_rtl::before {
  content: '\E5AB';
}

.xgl-contact_card_rtl_alt::before {
  content: '\E5AA';
}

.xgl-contact_card_rtl_two_tone::before {
  content: '\E5AB';
}

.xgl-contact_card_rtl_two_tone::after {
  content: '\E5AA';
  opacity: .2;
}

.xgl-contact_card_two_tone::before {
  content: '\E135';
}

.xgl-contact_card_two_tone::after {
  content: '\E136';
  opacity: .2;
}

.xgl-contact_edit::before {
  content: '\EA52';
}

.xgl-contact_edit_alt::before {
  content: '\EA53';
}

.xgl-contact_edit_two_tone::before {
  content: '\EA52';
}

.xgl-contact_edit_two_tone::after {
  content: '\EA53';
  opacity: .2;
}

.xgl-contacts::before {
  content: '\E137';
}

.xgl-contacts_add::before {
  content: '\E138';
}

.xgl-contacts_add_alt::before {
  content: '\E139';
}

.xgl-contacts_add_two_tone::before {
  content: '\E138';
}

.xgl-contacts_add_two_tone::after {
  content: '\E139';
  opacity: .2;
}

.xgl-contacts_alt::before {
  content: '\E13A';
}

.xgl-contacts_subtract::before {
  content: '\E8B2';
}

.xgl-contacts_subtract_alt::before {
  content: '\E8B3';
}

.xgl-contacts_subtract_two_tone::before {
  content: '\E8B2';
}

.xgl-contacts_subtract_two_tone::after {
  content: '\E8B3';
  opacity: .2;
}

.xgl-contacts_two_tone::before {
  content: '\E137';
}

.xgl-contacts_two_tone::after {
  content: '\E13A';
  opacity: .2;
}

.xgl-contract::before {
  content: '\E13B';
}

.xgl-contract_height::before {
  content: '\E13C';
}

.xgl-contrast::before {
  content: '\E13D';
}

.xgl-contrast_auto::before {
  content: '\EA92';
}

.xgl-contrast_auto_two_tone::before {
  content: '\EA92';
}

.xgl-contrast_auto_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-contrast_manual::before {
  content: '\EA93';
}

.xgl-contrast_manual_two_tone::before {
  content: '\EA93';
}

.xgl-contrast_manual_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-contrast_two_tone::before {
  content: '\E13D';
}

.xgl-contrast_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-control_panel_horizontal::before {
  content: '\E13E';
}

.xgl-control_panel_horizontal_alt::before {
  content: '\E13F';
}

.xgl-control_panel_horizontal_two_tone::before {
  content: '\E13E';
}

.xgl-control_panel_horizontal_two_tone::after {
  content: '\E13F';
  opacity: .2;
}

.xgl-control_panel_vertical::before {
  content: '\E140';
}

.xgl-control_panel_vertical_alt::before {
  content: '\E141';
}

.xgl-control_panel_vertical_two_tone::before {
  content: '\E140';
}

.xgl-control_panel_vertical_two_tone::after {
  content: '\E141';
  opacity: .2;
}

.xgl-convert_handwriting::before {
  content: '\E988';
}

.xgl-convert_handwriting_alt::before {
  content: '\E989';
}

.xgl-convert_handwriting_two_tone::before {
  content: '\E988';
}

.xgl-convert_handwriting_two_tone::after {
  content: '\E989';
  opacity: .2;
}

.xgl-cooling_time::before {
  content: '\EE1A';
}

.xgl-cooling_time_alt::before {
  content: '\EE1B';
}

.xgl-cooling_time_two_tone::before {
  content: '\EE1A';
}

.xgl-cooling_time_two_tone::after {
  content: '\EE1B';
  opacity: .2;
}

.xgl-copy::before {
  content: '\E142';
}

.xgl-copy_alt::before {
  content: '\E143';
}

.xgl-copy_length_selector::before {
  content: '\E774';
}

.xgl-copy_output::before {
  content: '\EA54';
}

.xgl-copy_output_alt::before {
  content: '\EA55';
}

.xgl-copy_output_two_tone::before {
  content: '\EA54';
}

.xgl-copy_output_two_tone::after {
  content: '\EA55';
  opacity: .2;
}

.xgl-copy_two_tone::before {
  content: '\E142';
}

.xgl-copy_two_tone::after {
  content: '\E143';
  opacity: .2;
}

.xgl-copyright::before {
  content: '\E144';
}

.xgl-cost::before {
  content: '\EE1C';
}

.xgl-cost_alt::before {
  content: '\EE1D';
}

.xgl-cost_stack_bkgd::before {
  content: '\EE1E';
}

.xgl-cost_stack_frgd::before {
  content: '\EE1F';
}

.xgl-cost_stack_mdgd::before {
  content: '\EE20';
}

.xgl-cost_two_tone::before {
  content: '\EE1C';
}

.xgl-cost_two_tone::after {
  content: '\EE1D';
  opacity: .2;
}

.xgl-cover_sheet::before {
  content: '\EA56';
}

.xgl-cover_sheet_alt::before {
  content: '\EA57';
}

.xgl-cover_sheet_two_tone::before {
  content: '\EA56';
}

.xgl-cover_sheet_two_tone::after {
  content: '\EA57';
  opacity: .2;
}

.xgl-covers::before {
  content: '\E83A';
}

.xgl-covers_alt::before {
  content: '\E83B';
}

.xgl-covers_back::before {
  content: '\EA58';
}

.xgl-covers_back_alt::before {
  content: '\EA59';
}

.xgl-covers_back_only::before {
  content: '\EA5A';
}

.xgl-covers_back_only_alt::before {
  content: '\EA5B';
}

.xgl-covers_back_only_two_tone::before {
  content: '\EA5A';
}

.xgl-covers_back_only_two_tone::after {
  content: '\EA5B';
  opacity: .2;
}

.xgl-covers_back_two_tone::before {
  content: '\EA58';
}

.xgl-covers_back_two_tone::after {
  content: '\EA59';
  opacity: .2;
}

.xgl-covers_blank::before {
  content: '\EA94';
}

.xgl-covers_blank_alt::before {
  content: '\EA95';
}

.xgl-covers_blank_two_tone::before {
  content: '\EA94';
}

.xgl-covers_blank_two_tone::after {
  content: '\EA95';
  opacity: .2;
}

.xgl-covers_different::before {
  content: '\EA5C';
}

.xgl-covers_different_alt::before {
  content: '\EA5D';
}

.xgl-covers_different_two_tone::before {
  content: '\EA5C';
}

.xgl-covers_different_two_tone::after {
  content: '\EA5D';
  opacity: .2;
}

.xgl-covers_front_only::before {
  content: '\EA5E';
}

.xgl-covers_front_only_alt::before {
  content: '\EA5F';
}

.xgl-covers_front_only_two_tone::before {
  content: '\EA5E';
}

.xgl-covers_front_only_two_tone::after {
  content: '\EA5F';
  opacity: .2;
}

.xgl-covers_none::before {
  content: '\EA60';
}

.xgl-covers_none_alt::before {
  content: '\EA61';
}

.xgl-covers_none_two_tone::before {
  content: '\EA60';
}

.xgl-covers_none_two_tone::after {
  content: '\EA61';
  opacity: .2;
}

.xgl-covers_printed_both::before {
  content: '\EA96';
}

.xgl-covers_printed_both_alt::before {
  content: '\EA97';
}

.xgl-covers_printed_both_rotate2::before {
  content: '\EA98';
}

.xgl-covers_printed_both_rotate2_alt::before {
  content: '\EA99';
}

.xgl-covers_printed_both_rotate2_two_tone::before {
  content: '\EA98';
}

.xgl-covers_printed_both_rotate2_two_tone::after {
  content: '\EA99';
  opacity: .2;
}

.xgl-covers_printed_both_two_tone::before {
  content: '\EA96';
}

.xgl-covers_printed_both_two_tone::after {
  content: '\EA97';
  opacity: .2;
}

.xgl-covers_same::before {
  content: '\EA62';
}

.xgl-covers_same_alt::before {
  content: '\EA63';
}

.xgl-covers_same_two_tone::before {
  content: '\EA62';
}

.xgl-covers_same_two_tone::after {
  content: '\EA63';
  opacity: .2;
}

.xgl-covers_two_tone::before {
  content: '\E83A';
}

.xgl-covers_two_tone::after {
  content: '\E83B';
  opacity: .2;
}

.xgl-create::before {
  content: '\E145';
}

.xgl-create_alt::before {
  content: '\E146';
}

.xgl-create_two_tone::before {
  content: '\E145';
}

.xgl-create_two_tone::after {
  content: '\E146';
  opacity: .2;
}

.xgl-credit_card::before {
  content: '\E147';
}

.xgl-credit_card_alt::before {
  content: '\E148';
}

.xgl-credit_card_disabled::before {
  content: '\E149';
}

.xgl-credit_card_disabled_alt::before {
  content: '\E14A';
}

.xgl-credit_card_disabled_two_tone::before {
  content: '\E149';
}

.xgl-credit_card_disabled_two_tone::after {
  content: '\E14A';
  opacity: .2;
}

.xgl-credit_card_two_tone::before {
  content: '\E147';
}

.xgl-credit_card_two_tone::after {
  content: '\E148';
  opacity: .2;
}

.xgl-credit_cards::before {
  content: '\E14B';
}

.xgl-credit_cards_alt::before {
  content: '\E14C';
}

.xgl-credit_cards_two_tone::before {
  content: '\E14B';
}

.xgl-credit_cards_two_tone::after {
  content: '\E14C';
  opacity: .2;
}

.xgl-crimp_horizontal::before {
  content: '\E6B1';
}

.xgl-crimp_none::before {
  content: '\E6AE';
}

.xgl-crimp_two_horizontal::before {
  content: '\E6B2';
}

.xgl-crimp_two_vertical::before {
  content: '\E6B3';
}

.xgl-crimp_upper_left::before {
  content: '\E6AD';
}

.xgl-crimp_upper_right::before {
  content: '\E6B0';
}

.xgl-crimp_vertical::before {
  content: '\E6AF';
}

.xgl-crop::before {
  content: '\E14D';
}

.xgl-crop_rotate::before {
  content: '\E59B';
}

.xgl-crosshair::before {
  content: '\E14E';
}

.xgl-crosshair_alt::before {
  content: '\EDAF';
}

.xgl-crosshair_two_tone::before {
  content: '\E14E';
}

.xgl-crosshair_two_tone::after {
  content: '\EDAF';
  opacity: .2;
}

.xgl-cube::before {
  content: '\EC9D';
}

.xgl-cube_add::before {
  content: '\ECDD';
}

.xgl-cube_add_alt::before {
  content: '\ECDE';
}

.xgl-cube_add_two_tone::before {
  content: '\ECDD';
}

.xgl-cube_add_two_tone::after {
  content: '\ECDE';
  opacity: .2;
}

.xgl-cube_alt::before {
  content: '\EC9E';
}

.xgl-cube_center::before {
  content: '\ECDF';
}

.xgl-cube_center_alt::before {
  content: '\ECE0';
}

.xgl-cube_center_two_tone::before {
  content: '\ECDF';
}

.xgl-cube_center_two_tone::after {
  content: '\ECE0';
  opacity: .2;
}

.xgl-cube_move::before {
  content: '\ECE1';
}

.xgl-cube_move_alt::before {
  content: '\ECE2';
}

.xgl-cube_move_two_tone::before {
  content: '\ECE1';
}

.xgl-cube_move_two_tone::after {
  content: '\ECE2';
  opacity: .2;
}

.xgl-cube_rotate::before {
  content: '\ECE3';
}

.xgl-cube_rotate_alt::before {
  content: '\ECE4';
}

.xgl-cube_rotate_two_tone::before {
  content: '\ECE3';
}

.xgl-cube_rotate_two_tone::after {
  content: '\ECE4';
  opacity: .2;
}

.xgl-cube_scale::before {
  content: '\ECE5';
}

.xgl-cube_scale_alt::before {
  content: '\ECE6';
}

.xgl-cube_scale_two_tone::before {
  content: '\ECE5';
}

.xgl-cube_scale_two_tone::after {
  content: '\ECE6';
  opacity: .2;
}

.xgl-cube_slice::before {
  content: '\ECE7';
}

.xgl-cube_slice_alt::before {
  content: '\ECE8';
}

.xgl-cube_slice_export::before {
  content: '\ECE9';
}

.xgl-cube_slice_export_alt::before {
  content: '\ECEA';
}

.xgl-cube_slice_export_two_tone::before {
  content: '\ECE9';
}

.xgl-cube_slice_export_two_tone::after {
  content: '\ECEA';
  opacity: .2;
}

.xgl-cube_slice_generate::before {
  content: '\ECEB';
}

.xgl-cube_slice_generate_alt::before {
  content: '\ECEC';
}

.xgl-cube_slice_generate_two_tone::before {
  content: '\ECEB';
}

.xgl-cube_slice_generate_two_tone::after {
  content: '\ECEC';
  opacity: .2;
}

.xgl-cube_slice_import::before {
  content: '\ECED';
}

.xgl-cube_slice_import_alt::before {
  content: '\ECEE';
}

.xgl-cube_slice_import_two_tone::before {
  content: '\ECED';
}

.xgl-cube_slice_import_two_tone::after {
  content: '\ECEE';
  opacity: .2;
}

.xgl-cube_slice_two_tone::before {
  content: '\ECE7';
}

.xgl-cube_slice_two_tone::after {
  content: '\ECE8';
  opacity: .2;
}

.xgl-cube_two_tone::before {
  content: '\EC9D';
}

.xgl-cube_two_tone::after {
  content: '\EC9E';
  opacity: .2;
}

.xgl-currency_prohibit::before {
  content: '\EABA';
}

.xgl-curve_chart::before {
  content: '\E83C';
}

.xgl-custom_type_eight_lef::before {
  content: '\EBD9';
}

.xgl-custom_type_eight_lef_alt::before {
  content: '\EBDA';
}

.xgl-custom_type_eight_lef_two_tone::before {
  content: '\EBD9';
}

.xgl-custom_type_eight_lef_two_tone::after {
  content: '\EBDA';
  opacity: .2;
}

.xgl-custom_type_five_lef::before {
  content: '\E14F';
}

.xgl-custom_type_five_lef_alt::before {
  content: '\E6CC';
}

.xgl-custom_type_five_lef_two_tone::before {
  content: '\E14F';
}

.xgl-custom_type_five_lef_two_tone::after {
  content: '\E6CC';
  opacity: .2;
}

.xgl-custom_type_five_sef::before {
  content: '\E150';
}

.xgl-custom_type_five_sef_alt::before {
  content: '\E6CD';
}

.xgl-custom_type_five_sef_two_tone::before {
  content: '\E150';
}

.xgl-custom_type_five_sef_two_tone::after {
  content: '\E6CD';
  opacity: .2;
}

.xgl-custom_type_four_lef::before {
  content: '\E151';
}

.xgl-custom_type_four_lef_alt::before {
  content: '\E6CE';
}

.xgl-custom_type_four_lef_two_tone::before {
  content: '\E151';
}

.xgl-custom_type_four_lef_two_tone::after {
  content: '\E6CE';
  opacity: .2;
}

.xgl-custom_type_four_sef::before {
  content: '\E152';
}

.xgl-custom_type_four_sef_alt::before {
  content: '\E6CF';
}

.xgl-custom_type_four_sef_two_tone::before {
  content: '\E152';
}

.xgl-custom_type_four_sef_two_tone::after {
  content: '\E6CF';
  opacity: .2;
}

.xgl-custom_type_lef::before {
  content: '\E153';
}

.xgl-custom_type_lef_alt::before {
  content: '\E6D0';
}

.xgl-custom_type_lef_two_tone::before {
  content: '\E153';
}

.xgl-custom_type_lef_two_tone::after {
  content: '\E6D0';
  opacity: .2;
}

.xgl-custom_type_nine_lef::before {
  content: '\EBDB';
}

.xgl-custom_type_nine_lef_alt::before {
  content: '\EBDC';
}

.xgl-custom_type_nine_lef_two_tone::before {
  content: '\EBDB';
}

.xgl-custom_type_nine_lef_two_tone::after {
  content: '\EBDC';
  opacity: .2;
}

.xgl-custom_type_one_lef::before {
  content: '\E154';
}

.xgl-custom_type_one_lef_alt::before {
  content: '\E6D1';
}

.xgl-custom_type_one_lef_two_tone::before {
  content: '\E154';
}

.xgl-custom_type_one_lef_two_tone::after {
  content: '\E6D1';
  opacity: .2;
}

.xgl-custom_type_one_sef::before {
  content: '\E155';
}

.xgl-custom_type_one_sef_alt::before {
  content: '\E6D2';
}

.xgl-custom_type_one_sef_two_tone::before {
  content: '\E155';
}

.xgl-custom_type_one_sef_two_tone::after {
  content: '\E6D2';
  opacity: .2;
}

.xgl-custom_type_sef::before {
  content: '\E156';
}

.xgl-custom_type_sef_alt::before {
  content: '\E6D3';
}

.xgl-custom_type_sef_two_tone::before {
  content: '\E156';
}

.xgl-custom_type_sef_two_tone::after {
  content: '\E6D3';
  opacity: .2;
}

.xgl-custom_type_seven_lef::before {
  content: '\E157';
}

.xgl-custom_type_seven_lef_alt::before {
  content: '\E6D4';
}

.xgl-custom_type_seven_lef_two_tone::before {
  content: '\E157';
}

.xgl-custom_type_seven_lef_two_tone::after {
  content: '\E6D4';
  opacity: .2;
}

.xgl-custom_type_seven_sef::before {
  content: '\E158';
}

.xgl-custom_type_seven_sef_alt::before {
  content: '\E6D5';
}

.xgl-custom_type_seven_sef_two_tone::before {
  content: '\E158';
}

.xgl-custom_type_seven_sef_two_tone::after {
  content: '\E6D5';
  opacity: .2;
}

.xgl-custom_type_six_lef::before {
  content: '\E159';
}

.xgl-custom_type_six_lef_alt::before {
  content: '\E6D6';
}

.xgl-custom_type_six_lef_two_tone::before {
  content: '\E159';
}

.xgl-custom_type_six_lef_two_tone::after {
  content: '\E6D6';
  opacity: .2;
}

.xgl-custom_type_six_sef::before {
  content: '\E15A';
}

.xgl-custom_type_six_sef_alt::before {
  content: '\E6D7';
}

.xgl-custom_type_six_sef_two_tone::before {
  content: '\E15A';
}

.xgl-custom_type_six_sef_two_tone::after {
  content: '\E6D7';
  opacity: .2;
}

.xgl-custom_type_three_lef::before {
  content: '\E15B';
}

.xgl-custom_type_three_lef_alt::before {
  content: '\E6D8';
}

.xgl-custom_type_three_lef_two_tone::before {
  content: '\E15B';
}

.xgl-custom_type_three_lef_two_tone::after {
  content: '\E6D8';
  opacity: .2;
}

.xgl-custom_type_three_sef::before {
  content: '\E15C';
}

.xgl-custom_type_three_sef_alt::before {
  content: '\E6D9';
}

.xgl-custom_type_three_sef_two_tone::before {
  content: '\E15C';
}

.xgl-custom_type_three_sef_two_tone::after {
  content: '\E6D9';
  opacity: .2;
}

.xgl-custom_type_two_lef::before {
  content: '\E15D';
}

.xgl-custom_type_two_lef_alt::before {
  content: '\E6DA';
}

.xgl-custom_type_two_lef_two_tone::before {
  content: '\E15D';
}

.xgl-custom_type_two_lef_two_tone::after {
  content: '\E6DA';
  opacity: .2;
}

.xgl-custom_type_two_sef::before {
  content: '\E15E';
}

.xgl-custom_type_two_sef_alt::before {
  content: '\E6DB';
}

.xgl-custom_type_two_sef_two_tone::before {
  content: '\E15E';
}

.xgl-custom_type_two_sef_two_tone::after {
  content: '\E6DB';
  opacity: .2;
}

.xgl-customize_destination_picker::before {
  content: '\EB86';
}

.xgl-customize_destination_picker_alt::before {
  content: '\EB95';
}

.xgl-customize_destination_picker_two_tone::before {
  content: '\EB86';
}

.xgl-customize_destination_picker_two_tone::after {
  content: '\EB95';
  opacity: .2;
}

.xgl-customize_source_picker::before {
  content: '\EB96';
}

.xgl-customize_source_picker_alt::before {
  content: '\EB97';
}

.xgl-customize_source_picker_two_tone::before {
  content: '\EB96';
}

.xgl-customize_source_picker_two_tone::after {
  content: '\EB97';
  opacity: .2;
}

.xgl-cut::before {
  content: '\E98A';
}

.xgl-cutter::before {
  content: '\E669';
}

.xgl-cutter_alt::before {
  content: '\E66A';
}

.xgl-cutter_two_tone::before {
  content: '\E669';
}

.xgl-cutter_two_tone::after {
  content: '\E66A';
  opacity: .2;
}

.xgl-danger_stack_bkgd::before {
  content: '\E98B';
}

.xgl-danger_stack_frgd::before {
  content: '\E98C';
}

.xgl-danger_stack_mdgd::before {
  content: '\E98D';
}

.xgl-dashboard::before {
  content: '\E15F';
}

.xgl-dashboard_alt::before {
  content: '\E160';
}

.xgl-dashboard_two_tone::before {
  content: '\E15F';
}

.xgl-dashboard_two_tone::after {
  content: '\E160';
  opacity: .2;
}

.xgl-data::before {
  content: '\E775';
}

.xgl-date_time::before {
  content: '\E161';
}

.xgl-date_time_alt::before {
  content: '\E162';
}

.xgl-date_time_two_tone::before {
  content: '\E161';
}

.xgl-date_time_two_tone::after {
  content: '\E162';
  opacity: .2;
}

.xgl-decision::before {
  content: '\E163';
}

.xgl-decrease_indent::before {
  content: '\E164';
}

.xgl-delay_print::before {
  content: '\EA9A';
}

.xgl-delay_print_alt::before {
  content: '\EA9B';
}

.xgl-delay_print_two_tone::before {
  content: '\EA9A';
}

.xgl-delay_print_two_tone::after {
  content: '\EA9B';
  opacity: .2;
}

.xgl-delete_all::before {
  content: '\E165';
}

.xgl-delete_all_alt::before {
  content: '\E166';
}

.xgl-delete_all_two_tone::before {
  content: '\E165';
}

.xgl-delete_all_two_tone::after {
  content: '\E166';
  opacity: .2;
}

.xgl-deleted_stack_bkgd::before {
  content: '\E98E';
}

.xgl-deleted_stack_frgd::before {
  content: '\E98F';
}

.xgl-deleted_stack_mdgd::before {
  content: '\E990';
}

.xgl-demote::before {
  content: '\E167';
}

.xgl-demote_square::before {
  content: '\E168';
}

.xgl-demote_square_alt::before {
  content: '\E169';
}

.xgl-demote_square_two_tone::before {
  content: '\E168';
}

.xgl-demote_square_two_tone::after {
  content: '\E169';
  opacity: .2;
}

.xgl-deselect::before {
  content: '\E803';
}

.xgl-deselect_all::before {
  content: '\E16A';
}

.xgl-deselect_all_alt::before {
  content: '\E16B';
}

.xgl-deselect_all_two_tone::before {
  content: '\E16A';
}

.xgl-deselect_all_two_tone::after {
  content: '\E16B';
  opacity: .2;
}

.xgl-desktop::before {
  content: '\E16C';
}

.xgl-desktop_alt::before {
  content: '\E16D';
}

.xgl-desktop_two_tone::before {
  content: '\E16C';
}

.xgl-desktop_two_tone::after {
  content: '\E16D';
  opacity: .2;
}

.xgl-details::before {
  content: '\E16E';
}

.xgl-details_alt::before {
  content: '\E16F';
}

.xgl-details_two_tone::before {
  content: '\E16E';
}

.xgl-details_two_tone::after {
  content: '\E16F';
  opacity: .2;
}

.xgl-device_share::before {
  content: '\ECBA';
}

.xgl-device_share_alt::before {
  content: '\ECBB';
}

.xgl-device_share_two_tone::before {
  content: '\ECBA';
}

.xgl-device_share_two_tone::after {
  content: '\ECBB';
  opacity: .2;
}

.xgl-device_smart_card::before {
  content: '\EC2E';
}

.xgl-device_smart_card_alt::before {
  content: '\EC2F';
}

.xgl-device_smart_card_two_tone::before {
  content: '\EC2E';
}

.xgl-device_smart_card_two_tone::after {
  content: '\EC2F';
  opacity: .2;
}

.xgl-dial::before {
  content: '\E170';
}

.xgl-dial_alt::before {
  content: '\E171';
}

.xgl-dial_two_tone::before {
  content: '\E170';
}

.xgl-dial_two_tone::after {
  content: '\E171';
  opacity: .2;
}

.xgl-dialpad::before {
  content: '\E172';
}

.xgl-dialpad_alt::before {
  content: '\E173';
}

.xgl-dialpad_two_tone::before {
  content: '\E172';
}

.xgl-dialpad_two_tone::after {
  content: '\E173';
  opacity: .2;
}

.xgl-direction::before {
  content: '\E174';
}

.xgl-direction_alt::before {
  content: '\E175';
}

.xgl-direction_two_tone::before {
  content: '\E174';
}

.xgl-direction_two_tone::after {
  content: '\E175';
  opacity: .2;
}

.xgl-disconnected::before {
  content: '\E991';
}

.xgl-disconnected_alt::before {
  content: '\E992';
}

.xgl-disconnected_two_tone::before {
  content: '\E991';
}

.xgl-disconnected_two_tone::after {
  content: '\E992';
  opacity: .2;
}

.xgl-discover::before {
  content: '\ECA1';
}

.xgl-discover_alt::before {
  content: '\ECA2';
}

.xgl-discover_two_tone::before {
  content: '\ECA1';
}

.xgl-discover_two_tone::after {
  content: '\ECA2';
  opacity: .2;
}

.xgl-dismiss::before {
  content: '\E6A5';
}

.xgl-dispatch::before {
  content: '\EAD7';
}

.xgl-dispatch_square::before {
  content: '\EDF0';
}

.xgl-dispatch_square_alt::before {
  content: '\EDF1';
}

.xgl-dispatch_square_two_tone::before {
  content: '\EDF0';
}

.xgl-dispatch_square_two_tone::after {
  content: '\EDF1';
  opacity: .2;
}

.xgl-displays::before {
  content: '\E176';
}

.xgl-displays_alt::before {
  content: '\E177';
}

.xgl-displays_two_tone::before {
  content: '\E176';
}

.xgl-displays_two_tone::after {
  content: '\E177';
  opacity: .2;
}

.xgl-dispose::before {
  content: '\E993';
}

.xgl-dna::before {
  content: '\E61E';
}

.xgl-document_glass::before {
  content: '\E178';
}

.xgl-document_glass_alt::before {
  content: '\E179';
}

.xgl-document_glass_two_tone::before {
  content: '\E178';
}

.xgl-document_glass_two_tone::after {
  content: '\E179';
  opacity: .2;
}

.xgl-docushare_logo::before {
  content: '\E6A3';
}

.xgl-docushare_logo_alt::before {
  content: '\E6A4';
}

.xgl-docushare_logo_two_tone::before {
  content: '\E6A3';
}

.xgl-docushare_logo_two_tone::after {
  content: '\E6A4';
  opacity: .2;
}

.xgl-down::before {
  content: '\E17A';
}

.xgl-down_alt::before {
  content: '\E17B';
}

.xgl-down_square::before {
  content: '\E17C';
}

.xgl-down_square_alt::before {
  content: '\E17D';
}

.xgl-down_square_two_tone::before {
  content: '\E17C';
}

.xgl-down_square_two_tone::after {
  content: '\E17D';
  opacity: .2;
}

.xgl-down_two_tone::before {
  content: '\E17A';
}

.xgl-down_two_tone::after {
  content: '\E17B';
  opacity: .2;
}

.xgl-downface_surfaces::before {
  content: '\EE37';
}

.xgl-downface_surfaces_alt::before {
  content: '\EE38';
}

.xgl-downface_surfaces_two_tone::before {
  content: '\EE37';
}

.xgl-downface_surfaces_two_tone::after {
  content: '\EE38';
  opacity: .2;
}

.xgl-download::before {
  content: '\E17E';
}

.xgl-download_error_report::before {
  content: '\EA9C';
}

.xgl-download_error_report_alt::before {
  content: '\EA9D';
}

.xgl-download_error_report_two_tone::before {
  content: '\EA9C';
}

.xgl-download_error_report_two_tone::after {
  content: '\EA9D';
  opacity: .2;
}

.xgl-download_import::before {
  content: '\EA9E';
}

.xgl-download_import_alt::before {
  content: '\EA9F';
}

.xgl-download_import_two_tone::before {
  content: '\EA9E';
}

.xgl-download_import_two_tone::after {
  content: '\EA9F';
  opacity: .2;
}

.xgl-download_report::before {
  content: '\EAA0';
}

.xgl-download_report_alt::before {
  content: '\EAA1';
}

.xgl-download_report_two_tone::before {
  content: '\EAA0';
}

.xgl-download_report_two_tone::after {
  content: '\EAA1';
  opacity: .2;
}

.xgl-download_sample::before {
  content: '\EAA2';
}

.xgl-download_sample_alt::before {
  content: '\EAA3';
}

.xgl-download_sample_two_tone::before {
  content: '\EAA2';
}

.xgl-download_sample_two_tone::after {
  content: '\EAA3';
  opacity: .2;
}

.xgl-download_square::before {
  content: '\E17F';
}

.xgl-download_square_alt::before {
  content: '\E180';
}

.xgl-download_square_two_tone::before {
  content: '\E17F';
}

.xgl-download_square_two_tone::after {
  content: '\E180';
  opacity: .2;
}

.xgl-dropbox::before {
  content: '\E181';
}

.xgl-dropbox_alt::before {
  content: '\E994';
}

.xgl-dropbox_two_tone::before {
  content: '\E181';
}

.xgl-dropbox_two_tone::after {
  content: '\E994';
  opacity: .2;
}

.xgl-droplet::before {
  content: '\E183';
}

.xgl-droplet_alt::before {
  content: '\E184';
}

.xgl-droplet_two_tone::before {
  content: '\E183';
}

.xgl-droplet_two_tone::after {
  content: '\E184';
  opacity: .2;
}

.xgl-duplex::before {
  content: '\E185';
}

.xgl-duplex_alt::before {
  content: '\E186';
}

.xgl-duplex_one_one::before {
  content: '\E85F';
}

.xgl-duplex_one_one_alt::before {
  content: '\EAA4';
}

.xgl-duplex_one_one_two_tone::before {
  content: '\E85F';
}

.xgl-duplex_one_one_two_tone::after {
  content: '\EAA4';
  opacity: .2;
}

.xgl-duplex_one_two::before {
  content: '\E860';
}

.xgl-duplex_one_two_alt::before {
  content: '\E995';
}

.xgl-duplex_one_two_rotate::before {
  content: '\E863';
}

.xgl-duplex_one_two_rotate_alt::before {
  content: '\EAA5';
}

.xgl-duplex_one_two_rotate_two_tone::before {
  content: '\E863';
}

.xgl-duplex_one_two_rotate_two_tone::after {
  content: '\EAA5';
  opacity: .2;
}

.xgl-duplex_one_two_two_tone::before {
  content: '\E860';
}

.xgl-duplex_one_two_two_tone::after {
  content: '\E995';
  opacity: .2;
}

.xgl-duplex_sided_rotate::before {
  content: '\EAA6';
}

.xgl-duplex_sided_rotate_alt::before {
  content: '\EAA7';
}

.xgl-duplex_sided_rotate_two_tone::before {
  content: '\EAA6';
}

.xgl-duplex_sided_rotate_two_tone::after {
  content: '\EAA7';
  opacity: .2;
}

.xgl-duplex_two_one::before {
  content: '\E862';
}

.xgl-duplex_two_one_alt::before {
  content: '\EAA8';
}

.xgl-duplex_two_one_rotate::before {
  content: '\E865';
}

.xgl-duplex_two_one_rotate_alt::before {
  content: '\EAA9';
}

.xgl-duplex_two_one_rotate_two_tone::before {
  content: '\E865';
}

.xgl-duplex_two_one_rotate_two_tone::after {
  content: '\EAA9';
  opacity: .2;
}

.xgl-duplex_two_one_two_tone::before {
  content: '\E862';
}

.xgl-duplex_two_one_two_tone::after {
  content: '\EAA8';
  opacity: .2;
}

.xgl-duplex_two_tone::before {
  content: '\E185';
}

.xgl-duplex_two_tone::after {
  content: '\E186';
  opacity: .2;
}

.xgl-duplex_two_two::before {
  content: '\E861';
}

.xgl-duplex_two_two_alt::before {
  content: '\EAAA';
}

.xgl-duplex_two_two_rotate::before {
  content: '\E864';
}

.xgl-duplex_two_two_rotate_alt::before {
  content: '\EAAB';
}

.xgl-duplex_two_two_rotate_two_tone::before {
  content: '\E864';
}

.xgl-duplex_two_two_rotate_two_tone::after {
  content: '\EAAB';
  opacity: .2;
}

.xgl-duplex_two_two_two_tone::before {
  content: '\E861';
}

.xgl-duplex_two_two_two_tone::after {
  content: '\EAAA';
  opacity: .2;
}

.xgl-duplicate::before {
  content: '\E187';
}

.xgl-duplicate_alt::before {
  content: '\E188';
}

.xgl-duplicate_two_tone::before {
  content: '\E187';
}

.xgl-duplicate_two_tone::after {
  content: '\E188';
  opacity: .2;
}

.xgl-eco::before {
  content: '\E7B9';
}

.xgl-eco_alt::before {
  content: '\E7BA';
}

.xgl-eco_two_tone::before {
  content: '\E7B9';
}

.xgl-eco_two_tone::after {
  content: '\E7BA';
  opacity: .2;
}

.xgl-edge_stitch_bottom_l::before {
  content: '\EDB0';
}

.xgl-edge_stitch_bottom_l_alt::before {
  content: '\EDB1';
}

.xgl-edge_stitch_bottom_l_two_tone::before {
  content: '\EDB0';
}

.xgl-edge_stitch_bottom_l_two_tone::after {
  content: '\EDB1';
  opacity: .2;
}

.xgl-edge_stitch_bottom_p::before {
  content: '\EDB2';
}

.xgl-edge_stitch_bottom_p_alt::before {
  content: '\EDB3';
}

.xgl-edge_stitch_bottom_p_two_tone::before {
  content: '\EDB2';
}

.xgl-edge_stitch_bottom_p_two_tone::after {
  content: '\EDB3';
  opacity: .2;
}

.xgl-edge_stitch_left_l::before {
  content: '\EDB4';
}

.xgl-edge_stitch_left_l_alt::before {
  content: '\EDB5';
}

.xgl-edge_stitch_left_l_two_tone::before {
  content: '\EDB4';
}

.xgl-edge_stitch_left_l_two_tone::after {
  content: '\EDB5';
  opacity: .2;
}

.xgl-edge_stitch_left_p::before {
  content: '\EDB6';
}

.xgl-edge_stitch_left_p_alt::before {
  content: '\EDB7';
}

.xgl-edge_stitch_left_p_two_tone::before {
  content: '\EDB6';
}

.xgl-edge_stitch_left_p_two_tone::after {
  content: '\EDB7';
  opacity: .2;
}

.xgl-edge_stitch_right_l::before {
  content: '\EDB8';
}

.xgl-edge_stitch_right_l_alt::before {
  content: '\EDB9';
}

.xgl-edge_stitch_right_l_two_tone::before {
  content: '\EDB8';
}

.xgl-edge_stitch_right_l_two_tone::after {
  content: '\EDB9';
  opacity: .2;
}

.xgl-edge_stitch_right_p::before {
  content: '\EDBA';
}

.xgl-edge_stitch_right_p_alt::before {
  content: '\EDBB';
}

.xgl-edge_stitch_right_p_two_tone::before {
  content: '\EDBA';
}

.xgl-edge_stitch_right_p_two_tone::after {
  content: '\EDBB';
  opacity: .2;
}

.xgl-edge_stitch_top_l::before {
  content: '\EDBC';
}

.xgl-edge_stitch_top_l_alt::before {
  content: '\EDBD';
}

.xgl-edge_stitch_top_l_two_tone::before {
  content: '\EDBC';
}

.xgl-edge_stitch_top_l_two_tone::after {
  content: '\EDBD';
  opacity: .2;
}

.xgl-edge_stitch_top_p::before {
  content: '\EDBE';
}

.xgl-edge_stitch_top_p_alt::before {
  content: '\EDBF';
}

.xgl-edge_stitch_top_p_two_tone::before {
  content: '\EDBE';
}

.xgl-edge_stitch_top_p_two_tone::after {
  content: '\EDBF';
  opacity: .2;
}

.xgl-edit::before {
  content: '\E189';
}

.xgl-edit_all::before {
  content: '\E18A';
}

.xgl-edit_all_alt::before {
  content: '\E18B';
}

.xgl-edit_all_two_tone::before {
  content: '\E18A';
}

.xgl-edit_all_two_tone::after {
  content: '\E18B';
  opacity: .2;
}

.xgl-edit_alt::before {
  content: '\E18C';
}

.xgl-edit_circle::before {
  content: '\EE66';
}

.xgl-edit_circle_alt::before {
  content: '\EE67';
}

.xgl-edit_circle_two_tone::before {
  content: '\EE66';
}

.xgl-edit_circle_two_tone::after {
  content: '\EE67';
  opacity: .2;
}

.xgl-edit_color::before {
  content: '\E18D';
}

.xgl-edit_disabled::before {
  content: '\E18E';
}

.xgl-edit_disabled_alt::before {
  content: '\E18F';
}

.xgl-edit_disabled_two_tone::before {
  content: '\E18E';
}

.xgl-edit_disabled_two_tone::after {
  content: '\E18F';
  opacity: .2;
}

.xgl-edit_file::before {
  content: '\E190';
}

.xgl-edit_file_alt::before {
  content: '\E191';
}

.xgl-edit_file_two_tone::before {
  content: '\E190';
}

.xgl-edit_file_two_tone::after {
  content: '\E191';
  opacity: .2;
}

.xgl-edit_square::before {
  content: '\E192';
}

.xgl-edit_square_alt::before {
  content: '\E193';
}

.xgl-edit_square_two_tone::before {
  content: '\E192';
}

.xgl-edit_square_two_tone::after {
  content: '\E193';
  opacity: .2;
}

.xgl-edit_two_tone::before {
  content: '\E189';
}

.xgl-edit_two_tone::after {
  content: '\E18C';
  opacity: .2;
}

.xgl-edit_wireframe::before {
  content: '\ECEF';
}

.xgl-edit_wireframe_alt::before {
  content: '\ECF0';
}

.xgl-edit_wireframe_two_tone::before {
  content: '\ECEF';
}

.xgl-edit_wireframe_two_tone::after {
  content: '\ECF0';
  opacity: .2;
}

.xgl-eight_circle::before {
  content: '\EE62';
}

.xgl-eight_circle_alt::before {
  content: '\EE63';
}

.xgl-eight_circle_two_tone::before {
  content: '\EE62';
}

.xgl-eight_circle_two_tone::after {
  content: '\EE63';
  opacity: .2;
}

.xgl-eject::before {
  content: '\E194';
}

.xgl-eject_alt::before {
  content: '\E195';
}

.xgl-eject_two_tone::before {
  content: '\E194';
}

.xgl-eject_two_tone::after {
  content: '\E195';
  opacity: .2;
}

.xgl-ellipsis::before {
  content: '\E196';
}

.xgl-ellipsis_circle::before {
  content: '\E883';
}

.xgl-ellipsis_circle_alt::before {
  content: '\E884';
}

.xgl-ellipsis_circle_two_tone::before {
  content: '\E883';
}

.xgl-ellipsis_circle_two_tone::after {
  content: '\E884';
  opacity: .2;
}

.xgl-ellipsis_vertical::before {
  content: '\E197';
}

.xgl-email_alert::before {
  content: '\EC26';
}

.xgl-email_alert_alt::before {
  content: '\EC27';
}

.xgl-email_alert_two_tone::before {
  content: '\EC26';
}

.xgl-email_alert_two_tone::after {
  content: '\EC27';
  opacity: .2;
}

.xgl-email_certificate_alert::before {
  content: '\EAAC';
}

.xgl-email_certificate_alert_alt::before {
  content: '\EAAD';
}

.xgl-email_certificate_alert_two_tone::before {
  content: '\EAAC';
}

.xgl-email_certificate_alert_two_tone::after {
  content: '\EAAD';
  opacity: .2;
}

.xgl-email_from::before {
  content: '\EC0F';
}

.xgl-email_from_alt::before {
  content: '\EC10';
}

.xgl-email_from_two_tone::before {
  content: '\EC0F';
}

.xgl-email_from_two_tone::after {
  content: '\EC10';
  opacity: .2;
}

.xgl-email_message::before {
  content: '\E198';
}

.xgl-email_message_alt::before {
  content: '\E199';
}

.xgl-email_message_two_tone::before {
  content: '\E198';
}

.xgl-email_message_two_tone::after {
  content: '\E199';
  opacity: .2;
}

.xgl-email_open::before {
  content: '\E19A';
}

.xgl-email_open_alt::before {
  content: '\E19B';
}

.xgl-email_open_two_tone::before {
  content: '\E19A';
}

.xgl-email_open_two_tone::after {
  content: '\E19B';
  opacity: .2;
}

.xgl-email_reload::before {
  content: '\EC3C';
}

.xgl-email_reload_alt::before {
  content: '\EC3D';
}

.xgl-email_reload_two_tone::before {
  content: '\EC3C';
}

.xgl-email_reload_two_tone::after {
  content: '\EC3D';
  opacity: .2;
}

.xgl-email_sign::before {
  content: '\E79B';
}

.xgl-email_sign_alt::before {
  content: '\E79C';
}

.xgl-email_sign_two_tone::before {
  content: '\E79B';
}

.xgl-email_sign_two_tone::after {
  content: '\E79C';
  opacity: .2;
}

.xgl-emboss_full_cut_tabs_lef::before {
  content: '\E19C';
}

.xgl-emboss_full_cut_tabs_lef_alt::before {
  content: '\E6DC';
}

.xgl-emboss_full_cut_tabs_lef_two_tone::before {
  content: '\E19C';
}

.xgl-emboss_full_cut_tabs_lef_two_tone::after {
  content: '\E6DC';
  opacity: .2;
}

.xgl-emboss_full_cut_tabs_sef::before {
  content: '\E19D';
}

.xgl-emboss_full_cut_tabs_sef_alt::before {
  content: '\E6DD';
}

.xgl-emboss_full_cut_tabs_sef_two_tone::before {
  content: '\E19D';
}

.xgl-emboss_full_cut_tabs_sef_two_tone::after {
  content: '\E6DD';
  opacity: .2;
}

.xgl-emboss_precut_tabs_lef::before {
  content: '\E19E';
}

.xgl-emboss_precut_tabs_lef_alt::before {
  content: '\E6DE';
}

.xgl-emboss_precut_tabs_lef_two_tone::before {
  content: '\E19E';
}

.xgl-emboss_precut_tabs_lef_two_tone::after {
  content: '\E6DE';
  opacity: .2;
}

.xgl-emboss_precut_tabs_sef::before {
  content: '\E19F';
}

.xgl-emboss_precut_tabs_sef_alt::before {
  content: '\E6DF';
}

.xgl-emboss_precut_tabs_sef_two_tone::before {
  content: '\E19F';
}

.xgl-emboss_precut_tabs_sef_two_tone::after {
  content: '\E6DF';
  opacity: .2;
}

.xgl-embossed_lef::before {
  content: '\E1A0';
}

.xgl-embossed_lef_alt::before {
  content: '\E6E0';
}

.xgl-embossed_lef_two_tone::before {
  content: '\E1A0';
}

.xgl-embossed_lef_two_tone::after {
  content: '\E6E0';
  opacity: .2;
}

.xgl-embossed_sef::before {
  content: '\E1A1';
}

.xgl-embossed_sef_alt::before {
  content: '\E6E1';
}

.xgl-embossed_sef_two_tone::before {
  content: '\E1A1';
}

.xgl-embossed_sef_two_tone::after {
  content: '\E6E1';
  opacity: .2;
}

.xgl-emoji::before {
  content: '\E1A2';
}

.xgl-emoji_alt::before {
  content: '\E1A3';
}

.xgl-emoji_neutral::before {
  content: '\E5BF';
}

.xgl-emoji_neutral_alt::before {
  content: '\E5C0';
}

.xgl-emoji_neutral_two_tone::before {
  content: '\E5BF';
}

.xgl-emoji_neutral_two_tone::after {
  content: '\E5C0';
  opacity: .2;
}

.xgl-emoji_satisfied::before {
  content: '\E5BD';
}

.xgl-emoji_satisfied_alt::before {
  content: '\E5BE';
}

.xgl-emoji_satisfied_two_tone::before {
  content: '\E5BD';
}

.xgl-emoji_satisfied_two_tone::after {
  content: '\E5BE';
  opacity: .2;
}

.xgl-emoji_two_tone::before {
  content: '\E1A2';
}

.xgl-emoji_two_tone::after {
  content: '\E1A3';
  opacity: .2;
}

.xgl-emoji_unsatisfied::before {
  content: '\E5C1';
}

.xgl-emoji_unsatisfied_alt::before {
  content: '\E5C2';
}

.xgl-emoji_unsatisfied_two_tone::before {
  content: '\E5C1';
}

.xgl-emoji_unsatisfied_two_tone::after {
  content: '\E5C2';
  opacity: .2;
}

.xgl-emoji_very_unsatisfied::before {
  content: '\E5C3';
}

.xgl-emoji_very_unsatisfied_alt::before {
  content: '\E5C4';
}

.xgl-emoji_very_unsatisfied_two_tone::before {
  content: '\E5C3';
}

.xgl-emoji_very_unsatisfied_two_tone::after {
  content: '\E5C4';
  opacity: .2;
}

.xgl-encryption::before {
  content: '\EAAE';
}

.xgl-encryption_alt::before {
  content: '\EAAF';
}

.xgl-encryption_two_tone::before {
  content: '\EAAE';
}

.xgl-encryption_two_tone::after {
  content: '\EAAF';
  opacity: .2;
}

.xgl-energy_saver::before {
  content: '\E1A4';
}

.xgl-energy_saver_alt::before {
  content: '\EAB0';
}

.xgl-energy_saver_timer::before {
  content: '\EAB1';
}

.xgl-energy_saver_timer_alt::before {
  content: '\EAB2';
}

.xgl-energy_saver_timer_two_tone::before {
  content: '\EAB1';
}

.xgl-energy_saver_timer_two_tone::after {
  content: '\EAB2';
  opacity: .2;
}

.xgl-energy_saver_two_tone::before {
  content: '\E1A4';
}

.xgl-energy_saver_two_tone::after {
  content: '\EAB0';
  opacity: .2;
}

.xgl-enter::before {
  content: '\E1A5';
}

.xgl-enter_rtl::before {
  content: '\E5A6';
}

.xgl-envelope::before {
  content: '\E1A6';
}

.xgl-envelope_alt::before {
  content: '\E1A7';
}

.xgl-envelope_lef::before {
  content: '\E1A8';
}

.xgl-envelope_lef_alt::before {
  content: '\E6E2';
}

.xgl-envelope_lef_two_tone::before {
  content: '\E1A8';
}

.xgl-envelope_lef_two_tone::after {
  content: '\E6E2';
  opacity: .2;
}

.xgl-envelope_reloaded_lef::before {
  content: '\E1A9';
}

.xgl-envelope_reloaded_lef_alt::before {
  content: '\E6E3';
}

.xgl-envelope_reloaded_lef_two_tone::before {
  content: '\E1A9';
}

.xgl-envelope_reloaded_lef_two_tone::after {
  content: '\E6E3';
  opacity: .2;
}

.xgl-envelope_reloaded_sef::before {
  content: '\E1AA';
}

.xgl-envelope_reloaded_sef_alt::before {
  content: '\E6E4';
}

.xgl-envelope_reloaded_sef_two_tone::before {
  content: '\E1AA';
}

.xgl-envelope_reloaded_sef_two_tone::after {
  content: '\E6E4';
  opacity: .2;
}

.xgl-envelope_sef::before {
  content: '\E1AB';
}

.xgl-envelope_sef_alt::before {
  content: '\E6E5';
}

.xgl-envelope_sef_two_tone::before {
  content: '\E1AB';
}

.xgl-envelope_sef_two_tone::after {
  content: '\E6E5';
  opacity: .2;
}

.xgl-envelope_tall_back::before {
  content: '\EE23';
}

.xgl-envelope_tall_back_alt::before {
  content: '\EE24';
}

.xgl-envelope_tall_back_two_tone::before {
  content: '\EE23';
}

.xgl-envelope_tall_back_two_tone::after {
  content: '\EE24';
  opacity: .2;
}

.xgl-envelope_tall_lef::before {
  content: '\E6E6';
}

.xgl-envelope_tall_lef_alt::before {
  content: '\E6E7';
}

.xgl-envelope_tall_lef_two_tone::before {
  content: '\E6E6';
}

.xgl-envelope_tall_lef_two_tone::after {
  content: '\E6E7';
  opacity: .2;
}

.xgl-envelope_tall_sef::before {
  content: '\E6E8';
}

.xgl-envelope_tall_sef_alt::before {
  content: '\E6E9';
}

.xgl-envelope_tall_sef_two_tone::before {
  content: '\E6E8';
}

.xgl-envelope_tall_sef_two_tone::after {
  content: '\E6E9';
  opacity: .2;
}

.xgl-envelope_two_tone::before {
  content: '\E1A6';
}

.xgl-envelope_two_tone::after {
  content: '\E1A7';
  opacity: .2;
}

.xgl-erase_all_edges::before {
  content: '\EAB3';
}

.xgl-erase_all_edges_alt::before {
  content: '\EAB4';
}

.xgl-erase_all_edges_two_tone::before {
  content: '\EAB3';
}

.xgl-erase_all_edges_two_tone::after {
  content: '\EAB4';
  opacity: .2;
}

.xgl-erase_edges::before {
  content: '\EDC0';
}

.xgl-erase_edges_alt::before {
  content: '\EDC1';
}

.xgl-erase_edges_two_tone::before {
  content: '\EDC0';
}

.xgl-erase_edges_two_tone::after {
  content: '\EDC1';
  opacity: .2;
}

.xgl-erase_individual_edges::before {
  content: '\EAB5';
}

.xgl-erase_individual_edges_alt::before {
  content: '\EAB6';
}

.xgl-erase_individual_edges_two_tone::before {
  content: '\EAB5';
}

.xgl-erase_individual_edges_two_tone::after {
  content: '\EAB6';
  opacity: .2;
}

.xgl-erase_print_to_edge::before {
  content: '\EAB7';
}

.xgl-erase_print_to_edge_alt::before {
  content: '\EAB8';
}

.xgl-erase_print_to_edge_two_tone::before {
  content: '\EAB7';
}

.xgl-erase_print_to_edge_two_tone::after {
  content: '\EAB8';
  opacity: .2;
}

.xgl-eraser::before {
  content: '\E1AC';
}

.xgl-eraser_alt::before {
  content: '\E1AD';
}

.xgl-eraser_two_tone::before {
  content: '\E1AC';
}

.xgl-eraser_two_tone::after {
  content: '\E1AD';
  opacity: .2;
}

.xgl-error_history_report::before {
  content: '\EAE9';
}

.xgl-error_history_report_alt::before {
  content: '\EAEA';
}

.xgl-error_history_report_two_tone::before {
  content: '\EAE9';
}

.xgl-error_history_report_two_tone::after {
  content: '\EAEA';
  opacity: .2;
}

.xgl-ethernet::before {
  content: '\E5BC';
}

.xgl-ethernet_alt::before {
  content: '\E5BB';
}

.xgl-ethernet_two_tone::before {
  content: '\E5BC';
}

.xgl-ethernet_two_tone::after {
  content: '\E5BB';
  opacity: .2;
}

.xgl-exchange::before {
  content: '\E87B';
}

.xgl-exit::before {
  content: '\E1AE';
}

.xgl-exit_alt::before {
  content: '\E996';
}

.xgl-exit_two_tone::before {
  content: '\E1AE';
}

.xgl-exit_two_tone::after {
  content: '\E996';
  opacity: .2;
}

.xgl-expand::before {
  content: '\E1AF';
}

.xgl-expand_height::before {
  content: '\E1B0';
}

.xgl-expand_row::before {
  content: '\E1B1';
}

.xgl-expand_row_alt::before {
  content: '\E1B2';
}

.xgl-expand_row_two_tone::before {
  content: '\E1B1';
}

.xgl-expand_row_two_tone::after {
  content: '\E1B2';
  opacity: .2;
}

.xgl-export::before {
  content: '\E1B3';
}

.xgl-export_alt::before {
  content: '\E1B4';
}

.xgl-export_two_tone::before {
  content: '\E1B3';
}

.xgl-export_two_tone::after {
  content: '\E1B4';
  opacity: .2;
}

.xgl-exposure::before {
  content: '\E577';
}

.xgl-exposure_alt::before {
  content: '\EABB';
}

.xgl-exposure_two_tone::before {
  content: '\E577';
}

.xgl-exposure_two_tone::after {
  content: '\EABB';
  opacity: .2;
}

.xgl-external_element::before {
  content: '\EE25';
}

.xgl-external_element_alt::before {
  content: '\EE26';
}

.xgl-external_element_two_tone::before {
  content: '\EE25';
}

.xgl-external_element_two_tone::after {
  content: '\EE26';
  opacity: .2;
}

.xgl-external_process::before {
  content: '\EE27';
}

.xgl-external_process_alt::before {
  content: '\EE28';
}

.xgl-external_process_two_tone::before {
  content: '\EE27';
}

.xgl-external_process_two_tone::after {
  content: '\EE28';
  opacity: .2;
}

.xgl-extra_hw_gloss_lef::before {
  content: '\E1B5';
}

.xgl-extra_hw_gloss_lef_alt::before {
  content: '\E6EA';
}

.xgl-extra_hw_gloss_lef_two_tone::before {
  content: '\E1B5';
}

.xgl-extra_hw_gloss_lef_two_tone::after {
  content: '\E6EA';
  opacity: .2;
}

.xgl-extra_hw_gloss_plus_lef::before {
  content: '\E1B6';
}

.xgl-extra_hw_gloss_plus_lef_alt::before {
  content: '\E6EB';
}

.xgl-extra_hw_gloss_plus_lef_two_tone::before {
  content: '\E1B6';
}

.xgl-extra_hw_gloss_plus_lef_two_tone::after {
  content: '\E6EB';
  opacity: .2;
}

.xgl-extra_hw_gloss_plus_sef::before {
  content: '\E1B7';
}

.xgl-extra_hw_gloss_plus_sef_alt::before {
  content: '\E6EC';
}

.xgl-extra_hw_gloss_plus_sef_two_tone::before {
  content: '\E1B7';
}

.xgl-extra_hw_gloss_plus_sef_two_tone::after {
  content: '\E6EC';
  opacity: .2;
}

.xgl-extra_hw_gloss_reloaded_lef::before {
  content: '\E1C3';
}

.xgl-extra_hw_gloss_reloaded_lef_alt::before {
  content: '\E6ED';
}

.xgl-extra_hw_gloss_reloaded_lef_two_tone::before {
  content: '\E1C3';
}

.xgl-extra_hw_gloss_reloaded_lef_two_tone::after {
  content: '\E6ED';
  opacity: .2;
}

.xgl-extra_hw_gloss_reloaded_sef::before {
  content: '\E1C4';
}

.xgl-extra_hw_gloss_reloaded_sef_alt::before {
  content: '\E6EE';
}

.xgl-extra_hw_gloss_reloaded_sef_two_tone::before {
  content: '\E1C4';
}

.xgl-extra_hw_gloss_reloaded_sef_two_tone::after {
  content: '\E6EE';
  opacity: .2;
}

.xgl-extra_hw_gloss_sef::before {
  content: '\E1B8';
}

.xgl-extra_hw_gloss_sef_alt::before {
  content: '\E6EF';
}

.xgl-extra_hw_gloss_sef_two_tone::before {
  content: '\E1B8';
}

.xgl-extra_hw_gloss_sef_two_tone::after {
  content: '\E6EF';
  opacity: .2;
}

.xgl-extra_hw_labels_lef::before {
  content: '\E1B9';
}

.xgl-extra_hw_labels_lef_alt::before {
  content: '\E6F0';
}

.xgl-extra_hw_labels_lef_two_tone::before {
  content: '\E1B9';
}

.xgl-extra_hw_labels_lef_two_tone::after {
  content: '\E6F0';
  opacity: .2;
}

.xgl-extra_hw_labels_sef::before {
  content: '\E1BA';
}

.xgl-extra_hw_labels_sef_alt::before {
  content: '\E6F1';
}

.xgl-extra_hw_labels_sef_two_tone::before {
  content: '\E1BA';
}

.xgl-extra_hw_labels_sef_two_tone::after {
  content: '\E6F1';
  opacity: .2;
}

.xgl-extra_hw_lef::before {
  content: '\E1BB';
}

.xgl-extra_hw_lef_alt::before {
  content: '\E6F2';
}

.xgl-extra_hw_lef_two_tone::before {
  content: '\E1BB';
}

.xgl-extra_hw_lef_two_tone::after {
  content: '\E6F2';
  opacity: .2;
}

.xgl-extra_hw_plus_lef::before {
  content: '\E1BC';
}

.xgl-extra_hw_plus_lef_alt::before {
  content: '\E6F3';
}

.xgl-extra_hw_plus_lef_two_tone::before {
  content: '\E1BC';
}

.xgl-extra_hw_plus_lef_two_tone::after {
  content: '\E6F3';
  opacity: .2;
}

.xgl-extra_hw_plus_reloaded_lef::before {
  content: '\E1C5';
}

.xgl-extra_hw_plus_reloaded_lef_alt::before {
  content: '\E6F4';
}

.xgl-extra_hw_plus_reloaded_lef_two_tone::before {
  content: '\E1C5';
}

.xgl-extra_hw_plus_reloaded_lef_two_tone::after {
  content: '\E6F4';
  opacity: .2;
}

.xgl-extra_hw_plus_reloaded_sef::before {
  content: '\E1C6';
}

.xgl-extra_hw_plus_reloaded_sef_alt::before {
  content: '\E6F5';
}

.xgl-extra_hw_plus_reloaded_sef_two_tone::before {
  content: '\E1C6';
}

.xgl-extra_hw_plus_reloaded_sef_two_tone::after {
  content: '\E6F5';
  opacity: .2;
}

.xgl-extra_hw_plus_sef::before {
  content: '\E1BD';
}

.xgl-extra_hw_plus_sef_alt::before {
  content: '\E6F6';
}

.xgl-extra_hw_plus_sef_two_tone::before {
  content: '\E1BD';
}

.xgl-extra_hw_plus_sef_two_tone::after {
  content: '\E6F6';
  opacity: .2;
}

.xgl-extra_hw_punched_lef::before {
  content: '\E1BE';
}

.xgl-extra_hw_punched_lef_alt::before {
  content: '\E6F7';
}

.xgl-extra_hw_punched_lef_two_tone::before {
  content: '\E1BE';
}

.xgl-extra_hw_punched_lef_two_tone::after {
  content: '\E6F7';
  opacity: .2;
}

.xgl-extra_hw_punched_sef::before {
  content: '\E1BF';
}

.xgl-extra_hw_punched_sef_alt::before {
  content: '\E6F8';
}

.xgl-extra_hw_punched_sef_two_tone::before {
  content: '\E1BF';
}

.xgl-extra_hw_punched_sef_two_tone::after {
  content: '\E6F8';
  opacity: .2;
}

.xgl-extra_hw_reloaded_lef::before {
  content: '\E1C0';
}

.xgl-extra_hw_reloaded_lef_alt::before {
  content: '\E6F9';
}

.xgl-extra_hw_reloaded_lef_two_tone::before {
  content: '\E1C0';
}

.xgl-extra_hw_reloaded_lef_two_tone::after {
  content: '\E6F9';
  opacity: .2;
}

.xgl-extra_hw_reloaded_sef::before {
  content: '\E1C1';
}

.xgl-extra_hw_reloaded_sef_alt::before {
  content: '\E6FA';
}

.xgl-extra_hw_reloaded_sef_two_tone::before {
  content: '\E1C1';
}

.xgl-extra_hw_reloaded_sef_two_tone::after {
  content: '\E6FA';
  opacity: .2;
}

.xgl-extra_hw_sef::before {
  content: '\E1C2';
}

.xgl-extra_hw_sef_alt::before {
  content: '\E6FB';
}

.xgl-extra_hw_sef_two_tone::before {
  content: '\E1C2';
}

.xgl-extra_hw_sef_two_tone::after {
  content: '\E6FB';
  opacity: .2;
}

.xgl-face_mask::before {
  content: '\EC78';
}

.xgl-face_mask_alt::before {
  content: '\EC79';
}

.xgl-face_mask_two_tone::before {
  content: '\EC78';
}

.xgl-face_mask_two_tone::after {
  content: '\EC79';
  opacity: .2;
}

.xgl-facebook::before {
  content: '\E1C7';
}

.xgl-fan::before {
  content: '\E7B5';
}

.xgl-fan_alt::before {
  content: '\E7B6';
}

.xgl-fan_on::before {
  content: '\E7B7';
}

.xgl-fan_on_alt::before {
  content: '\E7B8';
}

.xgl-fan_on_two_tone::before {
  content: '\E7B7';
}

.xgl-fan_on_two_tone::after {
  content: '\E7B8';
  opacity: .2;
}

.xgl-fan_two_tone::before {
  content: '\E7B5';
}

.xgl-fan_two_tone::after {
  content: '\E7B6';
  opacity: .2;
}

.xgl-fast_stop::before {
  content: '\E6FC';
}

.xgl-fastforward::before {
  content: '\E1C8';
}

.xgl-fastforward_alt::before {
  content: '\E1C9';
}

.xgl-fastforward_two_tone::before {
  content: '\E1C8';
}

.xgl-fastforward_two_tone::after {
  content: '\E1C9';
  opacity: .2;
}

.xgl-fault::before {
  content: '\E1CA';
}

.xgl-fault_alt::before {
  content: '\E1CB';
}

.xgl-fault_two_tone::before {
  content: '\E1CA';
}

.xgl-fault_two_tone::after {
  content: '\E1CB';
  opacity: .2;
}

.xgl-favorites::before {
  content: '\E1CC';
}

.xgl-favorites_alt::before {
  content: '\E1CD';
}

.xgl-favorites_disabled::before {
  content: '\E1CE';
}

.xgl-favorites_disabled_alt::before {
  content: '\E1CF';
}

.xgl-favorites_disabled_two_tone::before {
  content: '\E1CE';
}

.xgl-favorites_disabled_two_tone::after {
  content: '\E1CF';
  opacity: .2;
}

.xgl-favorites_two_tone::before {
  content: '\E1CC';
}

.xgl-favorites_two_tone::after {
  content: '\E1CD';
  opacity: .2;
}

.xgl-fax::before {
  content: '\E1D0';
}

.xgl-fax_alt::before {
  content: '\E1D1';
}

.xgl-fax_auto_answer::before {
  content: '\EA64';
}

.xgl-fax_auto_answer_alt::before {
  content: '\EA65';
}

.xgl-fax_auto_answer_two_tone::before {
  content: '\EA64';
}

.xgl-fax_auto_answer_two_tone::after {
  content: '\EA65';
  opacity: .2;
}

.xgl-fax_confirm::before {
  content: '\EA66';
}

.xgl-fax_confirm_alt::before {
  content: '\EA67';
}

.xgl-fax_confirm_two_tone::before {
  content: '\EA66';
}

.xgl-fax_confirm_two_tone::after {
  content: '\EA67';
  opacity: .2;
}

.xgl-fax_confirmation_report::before {
  content: '\EABE';
}

.xgl-fax_confirmation_report_alt::before {
  content: '\EABF';
}

.xgl-fax_confirmation_report_two_tone::before {
  content: '\EABE';
}

.xgl-fax_confirmation_report_two_tone::after {
  content: '\EABF';
  opacity: .2;
}

.xgl-fax_cover_off::before {
  content: '\EAED';
}

.xgl-fax_cover_off_alt::before {
  content: '\EAEE';
}

.xgl-fax_cover_off_two_tone::before {
  content: '\EAED';
}

.xgl-fax_cover_off_two_tone::after {
  content: '\EAEE';
  opacity: .2;
}

.xgl-fax_cover_on::before {
  content: '\EAEB';
}

.xgl-fax_cover_on_alt::before {
  content: '\EAEC';
}

.xgl-fax_cover_on_two_tone::before {
  content: '\EAEB';
}

.xgl-fax_cover_on_two_tone::after {
  content: '\EAEC';
  opacity: .2;
}

.xgl-fax_dial_numbers::before {
  content: '\E1D2';
}

.xgl-fax_dialog_characters::before {
  content: '\E1D3';
}

.xgl-fax_from::before {
  content: '\EA68';
}

.xgl-fax_from_alt::before {
  content: '\EA69';
}

.xgl-fax_from_two_tone::before {
  content: '\EA68';
}

.xgl-fax_from_two_tone::after {
  content: '\EA69';
  opacity: .2;
}

.xgl-fax_machine::before {
  content: '\E1D4';
}

.xgl-fax_machine_alt::before {
  content: '\E1D5';
}

.xgl-fax_machine_two_tone::before {
  content: '\E1D4';
}

.xgl-fax_machine_two_tone::after {
  content: '\E1D5';
  opacity: .2;
}

.xgl-fax_manual_answer::before {
  content: '\EA6A';
}

.xgl-fax_manual_answer_alt::before {
  content: '\EA6B';
}

.xgl-fax_manual_answer_two_tone::before {
  content: '\EA6A';
}

.xgl-fax_manual_answer_two_tone::after {
  content: '\EA6B';
  opacity: .2;
}

.xgl-fax_passcode::before {
  content: '\EA6C';
}

.xgl-fax_passcode_alt::before {
  content: '\EA6D';
}

.xgl-fax_passcode_two_tone::before {
  content: '\EA6C';
}

.xgl-fax_passcode_two_tone::after {
  content: '\EA6D';
  opacity: .2;
}

.xgl-fax_secure::before {
  content: '\E1D6';
}

.xgl-fax_secure_alt::before {
  content: '\E1D7';
}

.xgl-fax_secure_two_tone::before {
  content: '\E1D6';
}

.xgl-fax_secure_two_tone::after {
  content: '\E1D7';
  opacity: .2;
}

.xgl-fax_to::before {
  content: '\EA6E';
}

.xgl-fax_to_alt::before {
  content: '\EA6F';
}

.xgl-fax_to_two_tone::before {
  content: '\EA6E';
}

.xgl-fax_to_two_tone::after {
  content: '\EA6F';
  opacity: .2;
}

.xgl-fax_two_tone::before {
  content: '\E1D0';
}

.xgl-fax_two_tone::after {
  content: '\E1D1';
  opacity: .2;
}

.xgl-feather::before {
  content: '\E659';
}

.xgl-feather_alt::before {
  content: '\E65A';
}

.xgl-feather_two_tone::before {
  content: '\E659';
}

.xgl-feather_two_tone::after {
  content: '\E65A';
  opacity: .2;
}

.xgl-feature_installation::before {
  content: '\EABC';
}

.xgl-feature_installation_alt::before {
  content: '\EABD';
}

.xgl-feature_installation_two_tone::before {
  content: '\EABC';
}

.xgl-feature_installation_two_tone::after {
  content: '\EABD';
  opacity: .2;
}

.xgl-file::before {
  content: '\E1D8';
}

.xgl-file_abic::before {
  content: '\E616';
}

.xgl-file_abic_two_tone::before {
  content: '\E616';
}

.xgl-file_abic_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_add::before {
  content: '\E1D9';
}

.xgl-file_add_alt::before {
  content: '\E1DA';
}

.xgl-file_add_two_tone::before {
  content: '\E1D9';
}

.xgl-file_add_two_tone::after {
  content: '\E1DA';
  opacity: .2;
}

.xgl-file_afp::before {
  content: '\E615';
}

.xgl-file_afp_two_tone::before {
  content: '\E615';
}

.xgl-file_afp_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_aiff::before {
  content: '\E5FE';
}

.xgl-file_aiff_two_tone::before {
  content: '\E5FE';
}

.xgl-file_aiff_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_alt::before {
  content: '\E1DB';
}

.xgl-file_ascii::before {
  content: '\E614';
}

.xgl-file_ascii_two_tone::before {
  content: '\E614';
}

.xgl-file_ascii_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_asf::before {
  content: '\E5FB';
}

.xgl-file_asf_two_tone::before {
  content: '\E5FB';
}

.xgl-file_asf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_attr::before {
  content: '\E683';
}

.xgl-file_attr_two_tone::before {
  content: '\E683';
}

.xgl-file_attr_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_auto::before {
  content: '\E5F6';
}

.xgl-file_auto_two_tone::before {
  content: '\E5F6';
}

.xgl-file_auto_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_avi::before {
  content: '\E605';
}

.xgl-file_avi_two_tone::before {
  content: '\E605';
}

.xgl-file_avi_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_bin::before {
  content: '\E5E1';
}

.xgl-file_bin_two_tone::before {
  content: '\E5E1';
}

.xgl-file_bin_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_bkgd::before {
  content: '\E619';
}

.xgl-file_blank::before {
  content: '\E1DC';
}

.xgl-file_blank_alt::before {
  content: '\E1DD';
}

.xgl-file_blank_two_tone::before {
  content: '\E1DC';
}

.xgl-file_blank_two_tone::after {
  content: '\E1DD';
  opacity: .2;
}

.xgl-file_bmp::before {
  content: '\E5EF';
}

.xgl-file_bmp_two_tone::before {
  content: '\E5EF';
}

.xgl-file_bmp_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_cabinet::before {
  content: '\E1DE';
}

.xgl-file_cabinet_alt::before {
  content: '\E1DF';
}

.xgl-file_cabinet_two_tone::before {
  content: '\E1DE';
}

.xgl-file_cabinet_two_tone::after {
  content: '\E1DF';
  opacity: .2;
}

.xgl-file_cad::before {
  content: '\E5EA';
}

.xgl-file_cad_two_tone::before {
  content: '\E5EA';
}

.xgl-file_cad_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_cals::before {
  content: '\E613';
}

.xgl-file_cals_two_tone::before {
  content: '\E613';
}

.xgl-file_cals_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_cancel::before {
  content: '\E1E0';
}

.xgl-file_cancel_alt::before {
  content: '\E1E1';
}

.xgl-file_cancel_two_tone::before {
  content: '\E1E0';
}

.xgl-file_cancel_two_tone::after {
  content: '\E1E1';
  opacity: .2;
}

.xgl-file_cff::before {
  content: '\E612';
}

.xgl-file_cff_two_tone::before {
  content: '\E612';
}

.xgl-file_cff_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_chart::before {
  content: '\E1E2';
}

.xgl-file_chart_alt::before {
  content: '\E1E3';
}

.xgl-file_chart_two_tone::before {
  content: '\E1E2';
}

.xgl-file_chart_two_tone::after {
  content: '\E1E3';
  opacity: .2;
}

.xgl-file_check::before {
  content: '\E1E4';
}

.xgl-file_check_alt::before {
  content: '\E1E5';
}

.xgl-file_check_two_tone::before {
  content: '\E1E4';
}

.xgl-file_check_two_tone::after {
  content: '\E1E5';
  opacity: .2;
}

.xgl-file_ciff::before {
  content: '\E611';
}

.xgl-file_ciff_two_tone::before {
  content: '\E611';
}

.xgl-file_ciff_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_compress::before {
  content: '\E1E6';
}

.xgl-file_compress_alt::before {
  content: '\E1E7';
}

.xgl-file_compress_two_tone::before {
  content: '\E1E6';
}

.xgl-file_compress_two_tone::after {
  content: '\E1E7';
  opacity: .2;
}

.xgl-file_csv::before {
  content: '\E5F4';
}

.xgl-file_csv_two_tone::before {
  content: '\E5F4';
}

.xgl-file_csv_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_delay::before {
  content: '\EAEF';
}

.xgl-file_delay_alt::before {
  content: '\EAF0';
}

.xgl-file_delay_two_tone::before {
  content: '\EAEF';
}

.xgl-file_delay_two_tone::after {
  content: '\EAF0';
  opacity: .2;
}

.xgl-file_dib::before {
  content: '\E610';
}

.xgl-file_dib_two_tone::before {
  content: '\E610';
}

.xgl-file_dib_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_dlm::before {
  content: '\E5E2';
}

.xgl-file_dlm_two_tone::before {
  content: '\E5E2';
}

.xgl-file_dlm_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_doc::before {
  content: '\E5D2';
}

.xgl-file_doc_two_tone::before {
  content: '\E5D2';
}

.xgl-file_doc_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_docm::before {
  content: '\E684';
}

.xgl-file_docm_two_tone::before {
  content: '\E684';
}

.xgl-file_docm_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_docx::before {
  content: '\E5F9';
}

.xgl-file_docx_two_tone::before {
  content: '\E5F9';
}

.xgl-file_docx_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_download::before {
  content: '\E1E8';
}

.xgl-file_download_alt::before {
  content: '\E1E9';
}

.xgl-file_download_two_tone::before {
  content: '\E1E8';
}

.xgl-file_download_two_tone::after {
  content: '\E1E9';
  opacity: .2;
}

.xgl-file_dwg::before {
  content: '\E5EB';
}

.xgl-file_dwg_two_tone::before {
  content: '\E5EB';
}

.xgl-file_dwg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_dxf::before {
  content: '\E60F';
}

.xgl-file_dxf_two_tone::before {
  content: '\E60F';
}

.xgl-file_dxf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_eml::before {
  content: '\E685';
}

.xgl-file_eml_two_tone::before {
  content: '\E685';
}

.xgl-file_eml_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_eps::before {
  content: '\E5DD';
}

.xgl-file_eps_two_tone::before {
  content: '\E5DD';
}

.xgl-file_eps_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_exists_no_saved::before {
  content: '\EAF1';
}

.xgl-file_exists_no_saved_alt::before {
  content: '\EAF2';
}

.xgl-file_exists_no_saved_two_tone::before {
  content: '\EAF1';
}

.xgl-file_exists_no_saved_two_tone::after {
  content: '\EAF2';
  opacity: .2;
}

.xgl-file_favorite::before {
  content: '\E1EA';
}

.xgl-file_favorite_alt::before {
  content: '\E1EB';
}

.xgl-file_favorite_two_tone::before {
  content: '\E1EA';
}

.xgl-file_favorite_two_tone::after {
  content: '\E1EB';
  opacity: .2;
}

.xgl-file_frf::before {
  content: '\E5E6';
}

.xgl-file_frf_two_tone::before {
  content: '\E5E6';
}

.xgl-file_frf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_gif::before {
  content: '\E5E5';
}

.xgl-file_gif_two_tone::before {
  content: '\E5E5';
}

.xgl-file_gif_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_globe::before {
  content: '\E1EC';
}

.xgl-file_globe_alt::before {
  content: '\E1ED';
}

.xgl-file_globe_two_tone::before {
  content: '\E1EC';
}

.xgl-file_globe_two_tone::after {
  content: '\E1ED';
  opacity: .2;
}

.xgl-file_gz::before {
  content: '\E686';
}

.xgl-file_gz_two_tone::before {
  content: '\E686';
}

.xgl-file_gz_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_help::before {
  content: '\E848';
}

.xgl-file_help_alt::before {
  content: '\E849';
}

.xgl-file_help_two_tone::before {
  content: '\E848';
}

.xgl-file_help_two_tone::after {
  content: '\E849';
  opacity: .2;
}

.xgl-file_hpgl::before {
  content: '\E643';
}

.xgl-file_hpgl_two_tone::before {
  content: '\E643';
}

.xgl-file_hpgl_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_html::before {
  content: '\E5DC';
}

.xgl-file_html_two_tone::before {
  content: '\E5DC';
}

.xgl-file_html_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_ics::before {
  content: '\E617';
}

.xgl-file_ics_two_tone::before {
  content: '\E617';
}

.xgl-file_ics_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_img::before {
  content: '\E60E';
}

.xgl-file_img_two_tone::before {
  content: '\E60E';
}

.xgl-file_img_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_iso::before {
  content: '\E5E8';
}

.xgl-file_iso_two_tone::before {
  content: '\E5E8';
}

.xgl-file_iso_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jif::before {
  content: '\E5E3';
}

.xgl-file_jif_two_tone::before {
  content: '\E5E3';
}

.xgl-file_jif_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jpe::before {
  content: '\E5E4';
}

.xgl-file_jpe_two_tone::before {
  content: '\E5E4';
}

.xgl-file_jpe_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jpeg::before {
  content: '\E606';
}

.xgl-file_jpeg_two_tone::before {
  content: '\E606';
}

.xgl-file_jpeg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jpg::before {
  content: '\E5D6';
}

.xgl-file_jpg_lock::before {
  content: '\EC45';
}

.xgl-file_jpg_lock_two_tone::before {
  content: '\EC45';
}

.xgl-file_jpg_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_jpg_two_tone::before {
  content: '\E5D6';
}

.xgl-file_jpg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jt::before {
  content: '\E644';
}

.xgl-file_jt_two_tone::before {
  content: '\E644';
}

.xgl-file_jt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_jtd::before {
  content: '\E687';
}

.xgl-file_jtd_two_tone::before {
  content: '\E687';
}

.xgl-file_jtd_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_l::before {
  content: '\E1EE';
}

.xgl-file_l_alt::before {
  content: '\E1EF';
}

.xgl-file_l_two_tone::before {
  content: '\E1EE';
}

.xgl-file_l_two_tone::after {
  content: '\E1EF';
  opacity: .2;
}

.xgl-file_locked::before {
  content: '\E1F0';
}

.xgl-file_locked_alt::before {
  content: '\E1F1';
}

.xgl-file_locked_two_tone::before {
  content: '\E1F0';
}

.xgl-file_locked_two_tone::after {
  content: '\E1F1';
  opacity: .2;
}

.xgl-file_mdb::before {
  content: '\E645';
}

.xgl-file_mdb_two_tone::before {
  content: '\E645';
}

.xgl-file_mdb_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mid::before {
  content: '\E600';
}

.xgl-file_mid_two_tone::before {
  content: '\E600';
}

.xgl-file_mid_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mov::before {
  content: '\E5DF';
}

.xgl-file_mov_two_tone::before {
  content: '\E5DF';
}

.xgl-file_mov_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_move::before {
  content: '\E1F2';
}

.xgl-file_move_alt::before {
  content: '\E1F3';
}

.xgl-file_move_copy::before {
  content: '\E1F4';
}

.xgl-file_move_copy_alt::before {
  content: '\E1F5';
}

.xgl-file_move_copy_two_tone::before {
  content: '\E1F4';
}

.xgl-file_move_copy_two_tone::after {
  content: '\E1F5';
  opacity: .2;
}

.xgl-file_move_two_tone::before {
  content: '\E1F2';
}

.xgl-file_move_two_tone::after {
  content: '\E1F3';
  opacity: .2;
}

.xgl-file_mp3::before {
  content: '\E601';
}

.xgl-file_mp3_two_tone::before {
  content: '\E601';
}

.xgl-file_mp3_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mp4::before {
  content: '\E603';
}

.xgl-file_mp4_two_tone::before {
  content: '\E603';
}

.xgl-file_mp4_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mpg::before {
  content: '\E602';
}

.xgl-file_mpg_two_tone::before {
  content: '\E602';
}

.xgl-file_mpg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mpp::before {
  content: '\E688';
}

.xgl-file_mpp_two_tone::before {
  content: '\E688';
}

.xgl-file_mpp_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mso::before {
  content: '\EE07';
}

.xgl-file_mso_two_tone::before {
  content: '\EE07';
}

.xgl-file_mso_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_mtif::before {
  content: '\E5D8';
}

.xgl-file_mtif_lock::before {
  content: '\EC47';
}

.xgl-file_mtif_lock_two_tone::before {
  content: '\EC47';
}

.xgl-file_mtif_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_mtif_two_tone::before {
  content: '\E5D8';
}

.xgl-file_mtif_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_multipage::before {
  content: '\EB99';
}

.xgl-file_multipage_alt::before {
  content: '\EB9A';
}

.xgl-file_multipage_two_tone::before {
  content: '\EB99';
}

.xgl-file_multipage_two_tone::after {
  content: '\EB9A';
  opacity: .2;
}

.xgl-file_ocr::before {
  content: '\E5F5';
}

.xgl-file_ocr_two_tone::before {
  content: '\E5F5';
}

.xgl-file_ocr_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_odf::before {
  content: '\E60D';
}

.xgl-file_odf_two_tone::before {
  content: '\E60D';
}

.xgl-file_odf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_odg::before {
  content: '\E8BF';
}

.xgl-file_odg_two_tone::before {
  content: '\E8BF';
}

.xgl-file_odg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_odp::before {
  content: '\E60C';
}

.xgl-file_odp_two_tone::before {
  content: '\E60C';
}

.xgl-file_odp_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_ods::before {
  content: '\E60B';
}

.xgl-file_ods_two_tone::before {
  content: '\E60B';
}

.xgl-file_ods_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_odt::before {
  content: '\E60A';
}

.xgl-file_odt_two_tone::before {
  content: '\E60A';
}

.xgl-file_odt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_option::before {
  content: '\EAF4';
}

.xgl-file_option_alt::before {
  content: '\EAF5';
}

.xgl-file_option_two_tone::before {
  content: '\EAF4';
}

.xgl-file_option_two_tone::after {
  content: '\EAF5';
  opacity: .2;
}

.xgl-file_otf::before {
  content: '\E5EC';
}

.xgl-file_otf_two_tone::before {
  content: '\E5EC';
}

.xgl-file_otf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_oxps::before {
  content: '\E689';
}

.xgl-file_oxps_two_tone::before {
  content: '\E689';
}

.xgl-file_oxps_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pcl::before {
  content: '\E5D9';
}

.xgl-file_pcl_two_tone::before {
  content: '\E5D9';
}

.xgl-file_pcl_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pct::before {
  content: '\E5EE';
}

.xgl-file_pct_two_tone::before {
  content: '\E5EE';
}

.xgl-file_pct_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pdf::before {
  content: '\E5CE';
}

.xgl-file_pdf_lock::before {
  content: '\EC49';
}

.xgl-file_pdf_lock_two_tone::before {
  content: '\EC49';
}

.xgl-file_pdf_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_pdf_two_tone::before {
  content: '\E5CE';
}

.xgl-file_pdf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pdfa::before {
  content: '\E5D0';
}

.xgl-file_pdfa_lock::before {
  content: '\EC4B';
}

.xgl-file_pdfa_lock_two_tone::before {
  content: '\EC4B';
}

.xgl-file_pdfa_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_pdfa_two_tone::before {
  content: '\E5D0';
}

.xgl-file_pdfa_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pdl::before {
  content: '\E5CD';
}

.xgl-file_pdl_two_tone::before {
  content: '\E5CD';
}

.xgl-file_pdl_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pict::before {
  content: '\E609';
}

.xgl-file_pict_two_tone::before {
  content: '\E609';
}

.xgl-file_pict_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_png::before {
  content: '\E5D1';
}

.xgl-file_png_lock::before {
  content: '\EC4C';
}

.xgl-file_png_lock_two_tone::before {
  content: '\EC4C';
}

.xgl-file_png_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_png_two_tone::before {
  content: '\E5D1';
}

.xgl-file_png_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_ppt::before {
  content: '\E5CF';
}

.xgl-file_ppt_two_tone::before {
  content: '\E5CF';
}

.xgl-file_ppt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pptm::before {
  content: '\E68A';
}

.xgl-file_pptm_two_tone::before {
  content: '\E68A';
}

.xgl-file_pptm_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pptx::before {
  content: '\E5F8';
}

.xgl-file_pptx_two_tone::before {
  content: '\E5F8';
}

.xgl-file_pptx_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_preview::before {
  content: '\E94A';
}

.xgl-file_preview_alt::before {
  content: '\E949';
}

.xgl-file_preview_two_tone::before {
  content: '\E94A';
}

.xgl-file_preview_two_tone::after {
  content: '\E949';
  opacity: .2;
}

.xgl-file_prn::before {
  content: '\E5E0';
}

.xgl-file_prn_two_tone::before {
  content: '\E5E0';
}

.xgl-file_prn_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_ps::before {
  content: '\E5DA';
}

.xgl-file_ps_two_tone::before {
  content: '\E5DA';
}

.xgl-file_ps_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_psd::before {
  content: '\E5FA';
}

.xgl-file_psd_two_tone::before {
  content: '\E5FA';
}

.xgl-file_psd_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_pub::before {
  content: '\E68B';
}

.xgl-file_pub_two_tone::before {
  content: '\E68B';
}

.xgl-file_pub_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_qt::before {
  content: '\E604';
}

.xgl-file_qt_two_tone::before {
  content: '\E604';
}

.xgl-file_qt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_quality::before {
  content: '\EC43';
}

.xgl-file_quality_alt::before {
  content: '\EC44';
}

.xgl-file_quality_two_tone::before {
  content: '\EC43';
}

.xgl-file_quality_two_tone::after {
  content: '\EC44';
  opacity: .2;
}

.xgl-file_raw::before {
  content: '\E5F3';
}

.xgl-file_raw_two_tone::before {
  content: '\E5F3';
}

.xgl-file_raw_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_rtf::before {
  content: '\E5F0';
}

.xgl-file_rtf_two_tone::before {
  content: '\E5F0';
}

.xgl-file_rtf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_sheet::before {
  content: '\E1F6';
}

.xgl-file_sheet_alt::before {
  content: '\E1F7';
}

.xgl-file_sheet_two_tone::before {
  content: '\E1F6';
}

.xgl-file_sheet_two_tone::after {
  content: '\E1F7';
  opacity: .2;
}

.xgl-file_slide::before {
  content: '\E1F8';
}

.xgl-file_slide_alt::before {
  content: '\E1F9';
}

.xgl-file_slide_two_tone::before {
  content: '\E1F8';
}

.xgl-file_slide_two_tone::after {
  content: '\E1F9';
  opacity: .2;
}

.xgl-file_sql::before {
  content: '\ECA3';
}

.xgl-file_sql_two_tone::before {
  content: '\ECA3';
}

.xgl-file_sql_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_step::before {
  content: '\E68C';
}

.xgl-file_step_two_tone::before {
  content: '\E68C';
}

.xgl-file_step_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_subtract::before {
  content: '\E1FA';
}

.xgl-file_subtract_alt::before {
  content: '\E1FB';
}

.xgl-file_subtract_two_tone::before {
  content: '\E1FA';
}

.xgl-file_subtract_two_tone::after {
  content: '\E1FB';
  opacity: .2;
}

.xgl-file_svg::before {
  content: '\E608';
}

.xgl-file_svg_two_tone::before {
  content: '\E608';
}

.xgl-file_svg_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_tar::before {
  content: '\E68D';
}

.xgl-file_tar_two_tone::before {
  content: '\E68D';
}

.xgl-file_tar_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_tfx::before {
  content: '\E68E';
}

.xgl-file_tfx_two_tone::before {
  content: '\E68E';
}

.xgl-file_tfx_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_tif::before {
  content: '\E5D5';
}

.xgl-file_tif_lock::before {
  content: '\EC4E';
}

.xgl-file_tif_lock_two_tone::before {
  content: '\EC4E';
}

.xgl-file_tif_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_tif_two_tone::before {
  content: '\E5D5';
}

.xgl-file_tif_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_tiff::before {
  content: '\E61A';
}

.xgl-file_tiff_lock::before {
  content: '\EC50';
}

.xgl-file_tiff_lock_two_tone::before {
  content: '\EC50';
}

.xgl-file_tiff_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_tiff_two_tone::before {
  content: '\E61A';
}

.xgl-file_tiff_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_transfer::before {
  content: '\EDCF';
}

.xgl-file_transfer_alt::before {
  content: '\EDF2';
}

.xgl-file_transfer_two_tone::before {
  content: '\EDCF';
}

.xgl-file_transfer_two_tone::after {
  content: '\EDF2';
  opacity: .2;
}

.xgl-file_ttf::before {
  content: '\E5ED';
}

.xgl-file_ttf_two_tone::before {
  content: '\E5ED';
}

.xgl-file_ttf_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_two_tone::before {
  content: '\E1D8';
}

.xgl-file_two_tone::after {
  content: '\E1DB';
  opacity: .2;
}

.xgl-file_txt::before {
  content: '\E5D3';
}

.xgl-file_txt_two_tone::before {
  content: '\E5D3';
}

.xgl-file_txt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_type::before {
  content: '\EAF3';
}

.xgl-file_type_alt::before {
  content: '\E88C';
}

.xgl-file_type_bkgd::before {
  content: '\E997';
}

.xgl-file_type_lock_bkgd::before {
  content: '\EB9E';
}

.xgl-file_type_signature::before {
  content: '\EBA0';
}

.xgl-file_type_signature_alt::before {
  content: '\EBA1';
}

.xgl-file_type_signature_two_tone::before {
  content: '\EBA0';
}

.xgl-file_type_signature_two_tone::after {
  content: '\EBA1';
  opacity: .2;
}

.xgl-file_type_two_tone::before {
  content: '\EAF3';
}

.xgl-file_type_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_unix::before {
  content: '\E5E9';
}

.xgl-file_unix_two_tone::before {
  content: '\E5E9';
}

.xgl-file_unix_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_unknown::before {
  content: '\E5F2';
}

.xgl-file_unknown_two_tone::before {
  content: '\E5F2';
}

.xgl-file_unknown_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_unlock::before {
  content: '\E1FC';
}

.xgl-file_unlock_alt::before {
  content: '\E1FD';
}

.xgl-file_unlock_two_tone::before {
  content: '\E1FC';
}

.xgl-file_unlock_two_tone::after {
  content: '\E1FD';
  opacity: .2;
}

.xgl-file_upload::before {
  content: '\E1FE';
}

.xgl-file_upload_alt::before {
  content: '\E1FF';
}

.xgl-file_upload_default::before {
  content: '\EA86';
}

.xgl-file_upload_default_alt::before {
  content: '\EA88';
}

.xgl-file_upload_default_two_tone::before {
  content: '\EA86';
}

.xgl-file_upload_default_two_tone::after {
  content: '\EA88';
  opacity: .2;
}

.xgl-file_upload_favorite::before {
  content: '\EA87';
}

.xgl-file_upload_favorite_alt::before {
  content: '\EA8A';
}

.xgl-file_upload_favorite_two_tone::before {
  content: '\EA87';
}

.xgl-file_upload_favorite_two_tone::after {
  content: '\EA8A';
  opacity: .2;
}

.xgl-file_upload_two_tone::before {
  content: '\E1FE';
}

.xgl-file_upload_two_tone::after {
  content: '\E1FF';
  opacity: .2;
}

.xgl-file_user::before {
  content: '\E200';
}

.xgl-file_user_alt::before {
  content: '\E201';
}

.xgl-file_user_two_tone::before {
  content: '\E200';
}

.xgl-file_user_two_tone::after {
  content: '\E201';
  opacity: .2;
}

.xgl-file_vcs::before {
  content: '\E618';
}

.xgl-file_vcs_two_tone::before {
  content: '\E618';
}

.xgl-file_vcs_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_vsd::before {
  content: '\E68F';
}

.xgl-file_vsd_two_tone::before {
  content: '\E68F';
}

.xgl-file_vsd_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_wav::before {
  content: '\E5FF';
}

.xgl-file_wav_two_tone::before {
  content: '\E5FF';
}

.xgl-file_wav_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_wma::before {
  content: '\E5FC';
}

.xgl-file_wma_two_tone::before {
  content: '\E5FC';
}

.xgl-file_wma_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_wmv::before {
  content: '\E5FD';
}

.xgl-file_wmv_two_tone::before {
  content: '\E5FD';
}

.xgl-file_wmv_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_x_b::before {
  content: '\E691';
}

.xgl-file_x_b_two_tone::before {
  content: '\E691';
}

.xgl-file_x_b_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_x_t::before {
  content: '\E692';
}

.xgl-file_x_t_two_tone::before {
  content: '\E692';
}

.xgl-file_x_t_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xbd::before {
  content: '\E690';
}

.xgl-file_xbd_two_tone::before {
  content: '\E690';
}

.xgl-file_xbd_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xct::before {
  content: '\E694';
}

.xgl-file_xct_two_tone::before {
  content: '\E694';
}

.xgl-file_xct_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xdw::before {
  content: '\E5F1';
}

.xgl-file_xdw_two_tone::before {
  content: '\E5F1';
}

.xgl-file_xdw_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xla::before {
  content: '\E646';
}

.xgl-file_xla_two_tone::before {
  content: '\E646';
}

.xgl-file_xla_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xlc::before {
  content: '\E647';
}

.xgl-file_xlc_two_tone::before {
  content: '\E647';
}

.xgl-file_xlc_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xlm::before {
  content: '\E648';
}

.xgl-file_xlm_two_tone::before {
  content: '\E648';
}

.xgl-file_xlm_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xls::before {
  content: '\E5D4';
}

.xgl-file_xls_lock::before {
  content: '\EC51';
}

.xgl-file_xls_lock_two_tone::before {
  content: '\EC51';
}

.xgl-file_xls_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_xls_two_tone::before {
  content: '\E5D4';
}

.xgl-file_xls_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xlsx::before {
  content: '\E5F7';
}

.xgl-file_xlsx_lock::before {
  content: '\EC53';
}

.xgl-file_xlsx_lock_two_tone::before {
  content: '\EC53';
}

.xgl-file_xlsx_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_xlsx_two_tone::before {
  content: '\E5F7';
}

.xgl-file_xlsx_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xlt::before {
  content: '\E649';
}

.xgl-file_xlt_two_tone::before {
  content: '\E649';
}

.xgl-file_xlt_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xlw::before {
  content: '\E64A';
}

.xgl-file_xlw_two_tone::before {
  content: '\E64A';
}

.xgl-file_xlw_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xml::before {
  content: '\E5DB';
}

.xgl-file_xml_two_tone::before {
  content: '\E5DB';
}

.xgl-file_xml_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xps::before {
  content: '\E5D7';
}

.xgl-file_xps_lock::before {
  content: '\EC55';
}

.xgl-file_xps_lock_two_tone::before {
  content: '\EC55';
}

.xgl-file_xps_lock_two_tone::after {
  content: '\EB9E';
  opacity: .2;
}

.xgl-file_xps_two_tone::before {
  content: '\E5D7';
}

.xgl-file_xps_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xql::before {
  content: '\E5DE';
}

.xgl-file_xql_two_tone::before {
  content: '\E5DE';
}

.xgl-file_xql_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_xwd::before {
  content: '\E607';
}

.xgl-file_xwd_two_tone::before {
  content: '\E607';
}

.xgl-file_xwd_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-file_zip::before {
  content: '\E5E7';
}

.xgl-file_zip_two_tone::before {
  content: '\E5E7';
}

.xgl-file_zip_two_tone::after {
  content: '\E997';
  opacity: .2;
}

.xgl-files::before {
  content: '\E202';
}

.xgl-files_alt::before {
  content: '\E203';
}

.xgl-files_subtract::before {
  content: '\EA70';
}

.xgl-files_subtract_alt::before {
  content: '\EA71';
}

.xgl-files_subtract_two_tone::before {
  content: '\EA70';
}

.xgl-files_subtract_two_tone::after {
  content: '\EA71';
  opacity: .2;
}

.xgl-files_two_tone::before {
  content: '\E202';
}

.xgl-files_two_tone::after {
  content: '\E203';
  opacity: .2;
}

.xgl-film::before {
  content: '\E204';
}

.xgl-film_alt::before {
  content: '\E205';
}

.xgl-film_lef::before {
  content: '\E206';
}

.xgl-film_lef_alt::before {
  content: '\E6FD';
}

.xgl-film_lef_two_tone::before {
  content: '\E206';
}

.xgl-film_lef_two_tone::after {
  content: '\E6FD';
  opacity: .2;
}

.xgl-film_sef::before {
  content: '\E207';
}

.xgl-film_sef_alt::before {
  content: '\E6FE';
}

.xgl-film_sef_two_tone::before {
  content: '\E207';
}

.xgl-film_sef_two_tone::after {
  content: '\E6FE';
  opacity: .2;
}

.xgl-film_two_tone::before {
  content: '\E204';
}

.xgl-film_two_tone::after {
  content: '\E205';
  opacity: .2;
}

.xgl-filter::before {
  content: '\E650';
}

.xgl-filter_alt::before {
  content: '\E64E';
}

.xgl-filter_alt_two_tone::before {
  content: '\E64E';
}

.xgl-filter_alt_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-filter_two_tone::before {
  content: '\E650';
}

.xgl-filter_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-fingerprint::before {
  content: '\E208';
}

.xgl-finishing::before {
  content: '\E209';
}

.xgl-finishing_advanced::before {
  content: '\EAF8';
}

.xgl-finishing_advanced_alt::before {
  content: '\EAF9';
}

.xgl-finishing_advanced_two_tone::before {
  content: '\EAF8';
}

.xgl-finishing_advanced_two_tone::after {
  content: '\EAF9';
  opacity: .2;
}

.xgl-finishing_alt::before {
  content: '\E20A';
}

.xgl-finishing_off::before {
  content: '\EAF6';
}

.xgl-finishing_off_alt::before {
  content: '\EAF7';
}

.xgl-finishing_off_two_tone::before {
  content: '\EAF6';
}

.xgl-finishing_off_two_tone::after {
  content: '\EAF7';
  opacity: .2;
}

.xgl-finishing_two_tone::before {
  content: '\E209';
}

.xgl-finishing_two_tone::after {
  content: '\E20A';
  opacity: .2;
}

.xgl-fire::before {
  content: '\E20B';
}

.xgl-fire_alt::before {
  content: '\E20C';
}

.xgl-fire_two_tone::before {
  content: '\E20B';
}

.xgl-fire_two_tone::after {
  content: '\E20C';
  opacity: .2;
}

.xgl-firefox::before {
  content: '\E20D';
}

.xgl-firefox_two_tone::before {
  content: '\E20D';
}

.xgl-firefox_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-first::before {
  content: '\E20E';
}

.xgl-fish::before {
  content: '\E998';
}

.xgl-fish_alt::before {
  content: '\E999';
}

.xgl-fish_two_tone::before {
  content: '\E998';
}

.xgl-fish_two_tone::after {
  content: '\E999';
  opacity: .2;
}

.xgl-five_circle::before {
  content: '\EE5C';
}

.xgl-five_circle_alt::before {
  content: '\EE5D';
}

.xgl-five_circle_two_tone::before {
  content: '\EE5C';
}

.xgl-five_circle_two_tone::after {
  content: '\EE5D';
  opacity: .2;
}

.xgl-flag::before {
  content: '\E20F';
}

.xgl-flag_alt::before {
  content: '\E210';
}

.xgl-flag_checkered::before {
  content: '\E951';
}

.xgl-flag_two_tone::before {
  content: '\E20F';
}

.xgl-flag_two_tone::after {
  content: '\E210';
  opacity: .2;
}

.xgl-flask::before {
  content: '\E211';
}

.xgl-flask_alt::before {
  content: '\E212';
}

.xgl-flask_two_tone::before {
  content: '\E211';
}

.xgl-flask_two_tone::after {
  content: '\E212';
  opacity: .2;
}

.xgl-flex::before {
  content: '\E937';
}

.xgl-flex_alt::before {
  content: '\E938';
}

.xgl-flex_two_tone::before {
  content: '\E937';
}

.xgl-flex_two_tone::after {
  content: '\E938';
  opacity: .2;
}

.xgl-flick::before {
  content: '\E91B';
}

.xgl-flick_alt::before {
  content: '\E91C';
}

.xgl-flick_two_tone::before {
  content: '\E91B';
}

.xgl-flick_two_tone::after {
  content: '\E91C';
  opacity: .2;
}

.xgl-floorplan::before {
  content: '\E8C1';
}

.xgl-floorplan_alt::before {
  content: '\E8C2';
}

.xgl-floorplan_two_tone::before {
  content: '\E8C1';
}

.xgl-floorplan_two_tone::after {
  content: '\E8C2';
  opacity: .2;
}

.xgl-fold::before {
  content: '\E213';
}

.xgl-fold_advanced::before {
  content: '\EAFA';
}

.xgl-fold_advanced_alt::before {
  content: '\EAFB';
}

.xgl-fold_advanced_two_tone::before {
  content: '\EAFA';
}

.xgl-fold_advanced_two_tone::after {
  content: '\EAFB';
  opacity: .2;
}

.xgl-fold_alt::before {
  content: '\E214';
}

.xgl-fold_bifold_individual::before {
  content: '\EAFC';
}

.xgl-fold_bifold_individual_alt::before {
  content: '\EAFD';
}

.xgl-fold_bifold_individual_two_tone::before {
  content: '\EAFC';
}

.xgl-fold_bifold_individual_two_tone::after {
  content: '\EAFD';
  opacity: .2;
}

.xgl-fold_bifold_mult_inside::before {
  content: '\EB00';
}

.xgl-fold_bifold_mult_inside_alt::before {
  content: '\EB01';
}

.xgl-fold_bifold_mult_inside_two_tone::before {
  content: '\EB00';
}

.xgl-fold_bifold_mult_inside_two_tone::after {
  content: '\EB01';
  opacity: .2;
}

.xgl-fold_bifold_mult_outside::before {
  content: '\EAFE';
}

.xgl-fold_bifold_mult_outside_alt::before {
  content: '\EAFF';
}

.xgl-fold_bifold_mult_outside_two_tone::before {
  content: '\EAFE';
}

.xgl-fold_bifold_mult_outside_two_tone::after {
  content: '\EAFF';
  opacity: .2;
}

.xgl-fold_bifold_set::before {
  content: '\EB02';
}

.xgl-fold_bifold_set_alt::before {
  content: '\EB03';
}

.xgl-fold_bifold_set_two_tone::before {
  content: '\EB02';
}

.xgl-fold_bifold_set_two_tone::after {
  content: '\EB03';
  opacity: .2;
}

.xgl-fold_cfold::before {
  content: '\EB04';
}

.xgl-fold_cfold_alt::before {
  content: '\EB05';
}

.xgl-fold_cfold_inside::before {
  content: '\EB06';
}

.xgl-fold_cfold_inside_alt::before {
  content: '\EB07';
}

.xgl-fold_cfold_inside_two_tone::before {
  content: '\EB06';
}

.xgl-fold_cfold_inside_two_tone::after {
  content: '\EB07';
  opacity: .2;
}

.xgl-fold_cfold_outside::before {
  content: '\EB08';
}

.xgl-fold_cfold_outside_alt::before {
  content: '\EB09';
}

.xgl-fold_cfold_outside_two_tone::before {
  content: '\EB08';
}

.xgl-fold_cfold_outside_two_tone::after {
  content: '\EB09';
  opacity: .2;
}

.xgl-fold_cfold_two_tone::before {
  content: '\EB04';
}

.xgl-fold_cfold_two_tone::after {
  content: '\EB05';
  opacity: .2;
}

.xgl-fold_image_inside::before {
  content: '\EB0A';
}

.xgl-fold_image_inside_alt::before {
  content: '\EB0B';
}

.xgl-fold_image_inside_two_tone::before {
  content: '\EB0A';
}

.xgl-fold_image_inside_two_tone::after {
  content: '\EB0B';
  opacity: .2;
}

.xgl-fold_image_outside::before {
  content: '\EB0C';
}

.xgl-fold_image_outside_alt::before {
  content: '\EB0D';
}

.xgl-fold_image_outside_two_tone::before {
  content: '\EB0C';
}

.xgl-fold_image_outside_two_tone::after {
  content: '\EB0D';
  opacity: .2;
}

.xgl-fold_off::before {
  content: '\EB0E';
}

.xgl-fold_off_alt::before {
  content: '\EB0F';
}

.xgl-fold_off_two_tone::before {
  content: '\EB0E';
}

.xgl-fold_off_two_tone::after {
  content: '\EB0F';
  opacity: .2;
}

.xgl-fold_order_booklet::before {
  content: '\EB10';
}

.xgl-fold_order_booklet_alt::before {
  content: '\EB11';
}

.xgl-fold_order_booklet_two_tone::before {
  content: '\EB10';
}

.xgl-fold_order_booklet_two_tone::after {
  content: '\EB11';
  opacity: .2;
}

.xgl-fold_two_tone::before {
  content: '\E213';
}

.xgl-fold_two_tone::after {
  content: '\E214';
  opacity: .2;
}

.xgl-fold_zfold::before {
  content: '\EB12';
}

.xgl-fold_zfold_alt::before {
  content: '\EB13';
}

.xgl-fold_zfold_half::before {
  content: '\EB18';
}

.xgl-fold_zfold_half_alt::before {
  content: '\EB19';
}

.xgl-fold_zfold_half_bottom::before {
  content: '\EDC2';
}

.xgl-fold_zfold_half_bottom_alt::before {
  content: '\EDC3';
}

.xgl-fold_zfold_half_bottom_two_tone::before {
  content: '\EDC2';
}

.xgl-fold_zfold_half_bottom_two_tone::after {
  content: '\EDC3';
  opacity: .2;
}

.xgl-fold_zfold_half_left::before {
  content: '\EDC4';
}

.xgl-fold_zfold_half_left_alt::before {
  content: '\EDC5';
}

.xgl-fold_zfold_half_left_two_tone::before {
  content: '\EDC4';
}

.xgl-fold_zfold_half_left_two_tone::after {
  content: '\EDC5';
  opacity: .2;
}

.xgl-fold_zfold_half_top::before {
  content: '\EDC6';
}

.xgl-fold_zfold_half_top_alt::before {
  content: '\EDC7';
}

.xgl-fold_zfold_half_top_two_tone::before {
  content: '\EDC6';
}

.xgl-fold_zfold_half_top_two_tone::after {
  content: '\EDC7';
  opacity: .2;
}

.xgl-fold_zfold_half_two_tone::before {
  content: '\EB18';
}

.xgl-fold_zfold_half_two_tone::after {
  content: '\EB19';
  opacity: .2;
}

.xgl-fold_zfold_inside::before {
  content: '\EB14';
}

.xgl-fold_zfold_inside_alt::before {
  content: '\EB15';
}

.xgl-fold_zfold_inside_two_tone::before {
  content: '\EB14';
}

.xgl-fold_zfold_inside_two_tone::after {
  content: '\EB15';
  opacity: .2;
}

.xgl-fold_zfold_outside::before {
  content: '\EB16';
}

.xgl-fold_zfold_outside_alt::before {
  content: '\EB17';
}

.xgl-fold_zfold_outside_two_tone::before {
  content: '\EB16';
}

.xgl-fold_zfold_outside_two_tone::after {
  content: '\EB17';
  opacity: .2;
}

.xgl-fold_zfold_two_tone::before {
  content: '\EB12';
}

.xgl-fold_zfold_two_tone::after {
  content: '\EB13';
  opacity: .2;
}

.xgl-folder::before {
  content: '\E215';
}

.xgl-folder_add::before {
  content: '\E216';
}

.xgl-folder_add_alt::before {
  content: '\E217';
}

.xgl-folder_add_two_tone::before {
  content: '\E216';
}

.xgl-folder_add_two_tone::after {
  content: '\E217';
  opacity: .2;
}

.xgl-folder_alt::before {
  content: '\E218';
}

.xgl-folder_camera::before {
  content: '\E219';
}

.xgl-folder_camera_alt::before {
  content: '\E21A';
}

.xgl-folder_camera_two_tone::before {
  content: '\E219';
}

.xgl-folder_camera_two_tone::after {
  content: '\E21A';
  opacity: .2;
}

.xgl-folder_compress::before {
  content: '\E21B';
}

.xgl-folder_compress_alt::before {
  content: '\E21C';
}

.xgl-folder_compress_two_tone::before {
  content: '\E21B';
}

.xgl-folder_compress_two_tone::after {
  content: '\E21C';
  opacity: .2;
}

.xgl-folder_cube::before {
  content: '\ECF3';
}

.xgl-folder_cube_alt::before {
  content: '\ECF4';
}

.xgl-folder_cube_two_tone::before {
  content: '\ECF3';
}

.xgl-folder_cube_two_tone::after {
  content: '\ECF4';
  opacity: .2;
}

.xgl-folder_default::before {
  content: '\EB1C';
}

.xgl-folder_default_alt::before {
  content: '\EB1D';
}

.xgl-folder_default_two_tone::before {
  content: '\EB1C';
}

.xgl-folder_default_two_tone::after {
  content: '\EB1D';
  opacity: .2;
}

.xgl-folder_download::before {
  content: '\E21D';
}

.xgl-folder_download_alt::before {
  content: '\E21E';
}

.xgl-folder_download_two_tone::before {
  content: '\E21D';
}

.xgl-folder_download_two_tone::after {
  content: '\E21E';
  opacity: .2;
}

.xgl-folder_dropbox::before {
  content: '\E21F';
}

.xgl-folder_dropbox_alt::before {
  content: '\E220';
}

.xgl-folder_dropbox_two_tone::before {
  content: '\E21F';
}

.xgl-folder_dropbox_two_tone::after {
  content: '\E220';
  opacity: .2;
}

.xgl-folder_favorite::before {
  content: '\E221';
}

.xgl-folder_favorite_alt::before {
  content: '\E222';
}

.xgl-folder_favorite_two_tone::before {
  content: '\E221';
}

.xgl-folder_favorite_two_tone::after {
  content: '\E222';
  opacity: .2;
}

.xgl-folder_file::before {
  content: '\E7FF';
}

.xgl-folder_file_alt::before {
  content: '\ED40';
}

.xgl-folder_file_two_tone::before {
  content: '\E7FF';
}

.xgl-folder_file_two_tone::after {
  content: '\ED40';
  opacity: .2;
}

.xgl-folder_flex::before {
  content: '\E935';
}

.xgl-folder_flex_alt::before {
  content: '\E936';
}

.xgl-folder_flex_two_tone::before {
  content: '\E935';
}

.xgl-folder_flex_two_tone::after {
  content: '\E936';
  opacity: .2;
}

.xgl-folder_generate::before {
  content: '\ECF5';
}

.xgl-folder_generate_alt::before {
  content: '\ECF6';
}

.xgl-folder_generate_two_tone::before {
  content: '\ECF5';
}

.xgl-folder_generate_two_tone::after {
  content: '\ECF6';
  opacity: .2;
}

.xgl-folder_google_drive::before {
  content: '\E223';
}

.xgl-folder_google_drive_alt::before {
  content: '\E224';
}

.xgl-folder_google_drive_two_tone::before {
  content: '\E223';
}

.xgl-folder_google_drive_two_tone::after {
  content: '\E224';
  opacity: .2;
}

.xgl-folder_history::before {
  content: '\EC38';
}

.xgl-folder_history_alt::before {
  content: '\EC39';
}

.xgl-folder_history_two_tone::before {
  content: '\EC38';
}

.xgl-folder_history_two_tone::after {
  content: '\EC39';
  opacity: .2;
}

.xgl-folder_hot::before {
  content: '\E225';
}

.xgl-folder_hot_alt::before {
  content: '\E226';
}

.xgl-folder_hot_two_tone::before {
  content: '\E225';
}

.xgl-folder_hot_two_tone::after {
  content: '\E226';
  opacity: .2;
}

.xgl-folder_jobflow::before {
  content: '\E84A';
}

.xgl-folder_jobflow_alt::before {
  content: '\E84B';
}

.xgl-folder_jobflow_autostart::before {
  content: '\E84C';
}

.xgl-folder_jobflow_autostart_alt::before {
  content: '\E84D';
}

.xgl-folder_jobflow_autostart_two_tone::before {
  content: '\E84C';
}

.xgl-folder_jobflow_autostart_two_tone::after {
  content: '\E84D';
  opacity: .2;
}

.xgl-folder_jobflow_two_tone::before {
  content: '\E84A';
}

.xgl-folder_jobflow_two_tone::after {
  content: '\E84B';
  opacity: .2;
}

.xgl-folder_jpg::before {
  content: '\EB98';
}

.xgl-folder_jpg_lock::before {
  content: '\EC46';
}

.xgl-folder_jpg_lock_two_tone::before {
  content: '\EC46';
}

.xgl-folder_jpg_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_jpg_two_tone::before {
  content: '\EB98';
}

.xgl-folder_jpg_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_lock_bkgd::before {
  content: '\EBA8';
}

.xgl-folder_locked::before {
  content: '\E227';
}

.xgl-folder_locked_alt::before {
  content: '\E228';
}

.xgl-folder_locked_two_tone::before {
  content: '\E227';
}

.xgl-folder_locked_two_tone::after {
  content: '\E228';
  opacity: .2;
}

.xgl-folder_move::before {
  content: '\ECF7';
}

.xgl-folder_move_alt::before {
  content: '\ECF8';
}

.xgl-folder_move_two_tone::before {
  content: '\ECF7';
}

.xgl-folder_move_two_tone::after {
  content: '\ECF8';
  opacity: .2;
}

.xgl-folder_ms_office::before {
  content: '\E229';
}

.xgl-folder_ms_office_alt::before {
  content: '\E22A';
}

.xgl-folder_ms_office_two_tone::before {
  content: '\E229';
}

.xgl-folder_ms_office_two_tone::after {
  content: '\E22A';
  opacity: .2;
}

.xgl-folder_network::before {
  content: '\ED46';
}

.xgl-folder_network_alt::before {
  content: '\ED47';
}

.xgl-folder_network_two_tone::before {
  content: '\ED46';
}

.xgl-folder_network_two_tone::after {
  content: '\ED47';
  opacity: .2;
}

.xgl-folder_open::before {
  content: '\E7FD';
}

.xgl-folder_open_alt::before {
  content: '\E7FE';
}

.xgl-folder_open_two_tone::before {
  content: '\E7FD';
}

.xgl-folder_open_two_tone::after {
  content: '\E7FE';
  opacity: .2;
}

.xgl-folder_pdf::before {
  content: '\EB9B';
}

.xgl-folder_pdf_lock::before {
  content: '\EC48';
}

.xgl-folder_pdf_lock_two_tone::before {
  content: '\EC48';
}

.xgl-folder_pdf_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_pdf_two_tone::before {
  content: '\EB9B';
}

.xgl-folder_pdf_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_pdfa::before {
  content: '\EB9F';
}

.xgl-folder_pdfa_lock::before {
  content: '\EC4A';
}

.xgl-folder_pdfa_lock_two_tone::before {
  content: '\EC4A';
}

.xgl-folder_pdfa_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_pdfa_two_tone::before {
  content: '\EB9F';
}

.xgl-folder_pdfa_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_personal::before {
  content: '\E22B';
}

.xgl-folder_personal_alt::before {
  content: '\E22C';
}

.xgl-folder_personal_two_tone::before {
  content: '\E22B';
}

.xgl-folder_personal_two_tone::after {
  content: '\E22C';
  opacity: .2;
}

.xgl-folder_polling::before {
  content: '\EB1E';
}

.xgl-folder_polling_alt::before {
  content: '\EB1F';
}

.xgl-folder_polling_two_tone::before {
  content: '\EB1E';
}

.xgl-folder_polling_two_tone::after {
  content: '\EB1F';
  opacity: .2;
}

.xgl-folder_position::before {
  content: '\EDC8';
}

.xgl-folder_position_alt::before {
  content: '\EDC9';
}

.xgl-folder_position_two_tone::before {
  content: '\EDC8';
}

.xgl-folder_position_two_tone::after {
  content: '\EDC9';
  opacity: .2;
}

.xgl-folder_public::before {
  content: '\E22D';
}

.xgl-folder_public_alt::before {
  content: '\E22E';
}

.xgl-folder_public_two_tone::before {
  content: '\E22D';
}

.xgl-folder_public_two_tone::after {
  content: '\E22E';
  opacity: .2;
}

.xgl-folder_read_only::before {
  content: '\E22F';
}

.xgl-folder_read_only_alt::before {
  content: '\E230';
}

.xgl-folder_read_only_two_tone::before {
  content: '\E22F';
}

.xgl-folder_read_only_two_tone::after {
  content: '\E230';
  opacity: .2;
}

.xgl-folder_save_as::before {
  content: '\ECF9';
}

.xgl-folder_save_as_alt::before {
  content: '\ECFA';
}

.xgl-folder_save_as_two_tone::before {
  content: '\ECF9';
}

.xgl-folder_save_as_two_tone::after {
  content: '\ECFA';
  opacity: .2;
}

.xgl-folder_search::before {
  content: '\EB1A';
}

.xgl-folder_search_alt::before {
  content: '\EB1B';
}

.xgl-folder_search_two_tone::before {
  content: '\EB1A';
}

.xgl-folder_search_two_tone::after {
  content: '\EB1B';
  opacity: .2;
}

.xgl-folder_settings::before {
  content: '\EC3A';
}

.xgl-folder_settings_alt::before {
  content: '\EC3B';
}

.xgl-folder_settings_two_tone::before {
  content: '\EC3A';
}

.xgl-folder_settings_two_tone::after {
  content: '\EC3B';
  opacity: .2;
}

.xgl-folder_subtract::before {
  content: '\E231';
}

.xgl-folder_subtract_alt::before {
  content: '\E232';
}

.xgl-folder_subtract_two_tone::before {
  content: '\E231';
}

.xgl-folder_subtract_two_tone::after {
  content: '\E232';
  opacity: .2;
}

.xgl-folder_tif::before {
  content: '\EBA4';
}

.xgl-folder_tif_lock::before {
  content: '\EC4D';
}

.xgl-folder_tif_lock_two_tone::before {
  content: '\EC4D';
}

.xgl-folder_tif_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_tif_two_tone::before {
  content: '\EBA4';
}

.xgl-folder_tif_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_tiff::before {
  content: '\EBA5';
}

.xgl-folder_tiff_lock::before {
  content: '\EC4F';
}

.xgl-folder_tiff_lock_two_tone::before {
  content: '\EC4F';
}

.xgl-folder_tiff_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_tiff_two_tone::before {
  content: '\EBA5';
}

.xgl-folder_tiff_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_two_tone::before {
  content: '\E215';
}

.xgl-folder_two_tone::after {
  content: '\E218';
  opacity: .2;
}

.xgl-folder_type_bkgd::before {
  content: '\EC6A';
}

.xgl-folder_unknown::before {
  content: '\E84E';
}

.xgl-folder_unknown_alt::before {
  content: '\E84F';
}

.xgl-folder_unknown_two_tone::before {
  content: '\E84E';
}

.xgl-folder_unknown_two_tone::after {
  content: '\E84F';
  opacity: .2;
}

.xgl-folder_unlock::before {
  content: '\E233';
}

.xgl-folder_unlock_alt::before {
  content: '\E234';
}

.xgl-folder_unlock_two_tone::before {
  content: '\E233';
}

.xgl-folder_unlock_two_tone::after {
  content: '\E234';
  opacity: .2;
}

.xgl-folder_upload::before {
  content: '\E235';
}

.xgl-folder_upload_alt::before {
  content: '\E236';
}

.xgl-folder_upload_two_tone::before {
  content: '\E235';
}

.xgl-folder_upload_two_tone::after {
  content: '\E236';
  opacity: .2;
}

.xgl-folder_usb::before {
  content: '\E237';
}

.xgl-folder_usb_alt::before {
  content: '\E238';
}

.xgl-folder_usb_two_tone::before {
  content: '\E237';
}

.xgl-folder_usb_two_tone::after {
  content: '\E238';
  opacity: .2;
}

.xgl-folder_xlsx::before {
  content: '\EBA6';
}

.xgl-folder_xlsx_lock::before {
  content: '\EC52';
}

.xgl-folder_xlsx_lock_two_tone::before {
  content: '\EC52';
}

.xgl-folder_xlsx_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_xlsx_two_tone::before {
  content: '\EBA6';
}

.xgl-folder_xlsx_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folder_xps::before {
  content: '\EBA7';
}

.xgl-folder_xps_lock::before {
  content: '\EC54';
}

.xgl-folder_xps_lock_two_tone::before {
  content: '\EC54';
}

.xgl-folder_xps_lock_two_tone::after {
  content: '\EBA8';
  opacity: .2;
}

.xgl-folder_xps_two_tone::before {
  content: '\EBA7';
}

.xgl-folder_xps_two_tone::after {
  content: '\EC6A';
  opacity: .2;
}

.xgl-folding::before {
  content: '\E239';
}

.xgl-folding_alt::before {
  content: '\E23A';
}

.xgl-folding_two_tone::before {
  content: '\E239';
}

.xgl-folding_two_tone::after {
  content: '\E23A';
  opacity: .2;
}

.xgl-font_color::before {
  content: '\E23B';
}

.xgl-font_size::before {
  content: '\E23C';
}

.xgl-font_size_square::before {
  content: '\E99A';
}

.xgl-font_size_square_alt::before {
  content: '\E99B';
}

.xgl-font_size_square_two_tone::before {
  content: '\E99A';
}

.xgl-font_size_square_two_tone::after {
  content: '\E99B';
  opacity: .2;
}

.xgl-fonts::before {
  content: '\E23D';
}

.xgl-fonts_alt::before {
  content: '\E23E';
}

.xgl-fonts_two_tone::before {
  content: '\E23D';
}

.xgl-fonts_two_tone::after {
  content: '\E23E';
  opacity: .2;
}

.xgl-food::before {
  content: '\E23F';
}

.xgl-forced_speed::before {
  content: '\EB20';
}

.xgl-forced_speed_alt::before {
  content: '\EB21';
}

.xgl-forced_speed_two_tone::before {
  content: '\EB20';
}

.xgl-forced_speed_two_tone::after {
  content: '\EB21';
  opacity: .2;
}

.xgl-forgot_password::before {
  content: '\ECA4';
}

.xgl-forgot_password_alt::before {
  content: '\ECA5';
}

.xgl-forgot_password_two_tone::before {
  content: '\ECA4';
}

.xgl-forgot_password_two_tone::after {
  content: '\ECA5';
  opacity: .2;
}

.xgl-four_circle::before {
  content: '\EE5A';
}

.xgl-four_circle_alt::before {
  content: '\EE5B';
}

.xgl-four_circle_two_tone::before {
  content: '\EE5A';
}

.xgl-four_circle_two_tone::after {
  content: '\EE5B';
  opacity: .2;
}

.xgl-four_free_staple::before {
  content: '\EB3A';
}

.xgl-four_free_staple_alt::before {
  content: '\EB3B';
}

.xgl-four_free_staple_two_tone::before {
  content: '\EB3A';
}

.xgl-four_free_staple_two_tone::after {
  content: '\EB3B';
  opacity: .2;
}

.xgl-four_hole::before {
  content: '\ED89';
}

.xgl-four_hole_alt::before {
  content: '\ED72';
}

.xgl-four_hole_bottom_l::before {
  content: '\ED73';
}

.xgl-four_hole_bottom_l_alt::before {
  content: '\ED74';
}

.xgl-four_hole_bottom_l_two_tone::before {
  content: '\ED73';
}

.xgl-four_hole_bottom_l_two_tone::after {
  content: '\ED74';
  opacity: .2;
}

.xgl-four_hole_left_l::before {
  content: '\E9D9';
}

.xgl-four_hole_left_l_alt::before {
  content: '\E9DA';
}

.xgl-four_hole_left_l_two_tone::before {
  content: '\E9D9';
}

.xgl-four_hole_left_l_two_tone::after {
  content: '\E9DA';
  opacity: .2;
}

.xgl-four_hole_left_p::before {
  content: '\E9DB';
}

.xgl-four_hole_left_p_alt::before {
  content: '\E9DC';
}

.xgl-four_hole_left_p_two_tone::before {
  content: '\E9DB';
}

.xgl-four_hole_left_p_two_tone::after {
  content: '\E9DC';
  opacity: .2;
}

.xgl-four_hole_right_p::before {
  content: '\ED75';
}

.xgl-four_hole_right_p_alt::before {
  content: '\ED76';
}

.xgl-four_hole_right_p_two_tone::before {
  content: '\ED75';
}

.xgl-four_hole_right_p_two_tone::after {
  content: '\ED76';
  opacity: .2;
}

.xgl-four_hole_two_tone::before {
  content: '\ED89';
}

.xgl-four_hole_two_tone::after {
  content: '\ED72';
  opacity: .2;
}

.xgl-four_holes::before {
  content: '\ED77';
}

.xgl-four_holes_alt::before {
  content: '\ED78';
}

.xgl-four_holes_bottom_l::before {
  content: '\E8F7';
}

.xgl-four_holes_bottom_l_alt::before {
  content: '\E8F8';
}

.xgl-four_holes_bottom_l_two_tone::before {
  content: '\E8F7';
}

.xgl-four_holes_bottom_l_two_tone::after {
  content: '\E8F8';
  opacity: .2;
}

.xgl-four_holes_bottom_p::before {
  content: '\E8F9';
}

.xgl-four_holes_bottom_p_alt::before {
  content: '\E8FA';
}

.xgl-four_holes_bottom_p_two_tone::before {
  content: '\E8F9';
}

.xgl-four_holes_bottom_p_two_tone::after {
  content: '\E8FA';
  opacity: .2;
}

.xgl-four_holes_left_l::before {
  content: '\E240';
}

.xgl-four_holes_left_l_alt::before {
  content: '\E6FF';
}

.xgl-four_holes_left_l_two_tone::before {
  content: '\E240';
}

.xgl-four_holes_left_l_two_tone::after {
  content: '\E6FF';
  opacity: .2;
}

.xgl-four_holes_left_p::before {
  content: '\E241';
}

.xgl-four_holes_left_p_alt::before {
  content: '\E700';
}

.xgl-four_holes_left_p_two_tone::before {
  content: '\E241';
}

.xgl-four_holes_left_p_two_tone::after {
  content: '\E700';
  opacity: .2;
}

.xgl-four_holes_right_l::before {
  content: '\E242';
}

.xgl-four_holes_right_l_alt::before {
  content: '\E701';
}

.xgl-four_holes_right_l_two_tone::before {
  content: '\E242';
}

.xgl-four_holes_right_l_two_tone::after {
  content: '\E701';
  opacity: .2;
}

.xgl-four_holes_right_p::before {
  content: '\E243';
}

.xgl-four_holes_right_p_alt::before {
  content: '\E702';
}

.xgl-four_holes_right_p_two_tone::before {
  content: '\E243';
}

.xgl-four_holes_right_p_two_tone::after {
  content: '\E702';
  opacity: .2;
}

.xgl-four_holes_top_l::before {
  content: '\E244';
}

.xgl-four_holes_top_l_alt::before {
  content: '\E703';
}

.xgl-four_holes_top_l_two_tone::before {
  content: '\E244';
}

.xgl-four_holes_top_l_two_tone::after {
  content: '\E703';
  opacity: .2;
}

.xgl-four_holes_top_p::before {
  content: '\E245';
}

.xgl-four_holes_top_p_alt::before {
  content: '\E704';
}

.xgl-four_holes_top_p_two_tone::before {
  content: '\E245';
}

.xgl-four_holes_top_p_two_tone::after {
  content: '\E704';
  opacity: .2;
}

.xgl-four_holes_two_tone::before {
  content: '\ED77';
}

.xgl-four_holes_two_tone::after {
  content: '\ED78';
  opacity: .2;
}

.xgl-four_s_hole_left_l::before {
  content: '\E9DD';
}

.xgl-four_s_hole_left_l_alt::before {
  content: '\E9DE';
}

.xgl-four_s_hole_left_l_two_tone::before {
  content: '\E9DD';
}

.xgl-four_s_hole_left_l_two_tone::after {
  content: '\E9DE';
  opacity: .2;
}

.xgl-four_s_hole_left_p::before {
  content: '\E9DF';
}

.xgl-four_s_hole_left_p_alt::before {
  content: '\E9E0';
}

.xgl-four_s_hole_left_p_two_tone::before {
  content: '\E9DF';
}

.xgl-four_s_hole_left_p_two_tone::after {
  content: '\E9E0';
  opacity: .2;
}

.xgl-four_staple::before {
  content: '\EB38';
}

.xgl-four_staple_alt::before {
  content: '\EB39';
}

.xgl-four_staple_two_tone::before {
  content: '\EB38';
}

.xgl-four_staple_two_tone::after {
  content: '\EB39';
  opacity: .2;
}

.xgl-four_staples_bottom_l::before {
  content: '\ED79';
}

.xgl-four_staples_bottom_l_alt::before {
  content: '\ED7A';
}

.xgl-four_staples_bottom_l_two_tone::before {
  content: '\ED79';
}

.xgl-four_staples_bottom_l_two_tone::after {
  content: '\ED7A';
  opacity: .2;
}

.xgl-four_staples_bottom_p::before {
  content: '\ED7B';
}

.xgl-four_staples_bottom_p_alt::before {
  content: '\ED7C';
}

.xgl-four_staples_bottom_p_two_tone::before {
  content: '\ED7B';
}

.xgl-four_staples_bottom_p_two_tone::after {
  content: '\ED7C';
  opacity: .2;
}

.xgl-four_staples_left_l::before {
  content: '\ED7D';
}

.xgl-four_staples_left_l_alt::before {
  content: '\ED7E';
}

.xgl-four_staples_left_l_two_tone::before {
  content: '\ED7D';
}

.xgl-four_staples_left_l_two_tone::after {
  content: '\ED7E';
  opacity: .2;
}

.xgl-four_staples_left_p::before {
  content: '\ED7F';
}

.xgl-four_staples_left_p_alt::before {
  content: '\ED80';
}

.xgl-four_staples_left_p_two_tone::before {
  content: '\ED7F';
}

.xgl-four_staples_left_p_two_tone::after {
  content: '\ED80';
  opacity: .2;
}

.xgl-four_staples_right_l::before {
  content: '\ED81';
}

.xgl-four_staples_right_l_alt::before {
  content: '\ED82';
}

.xgl-four_staples_right_l_two_tone::before {
  content: '\ED81';
}

.xgl-four_staples_right_l_two_tone::after {
  content: '\ED82';
  opacity: .2;
}

.xgl-four_staples_right_p::before {
  content: '\ED83';
}

.xgl-four_staples_right_p_alt::before {
  content: '\ED84';
}

.xgl-four_staples_right_p_two_tone::before {
  content: '\ED83';
}

.xgl-four_staples_right_p_two_tone::after {
  content: '\ED84';
  opacity: .2;
}

.xgl-four_staples_top_l::before {
  content: '\ED85';
}

.xgl-four_staples_top_l_alt::before {
  content: '\ED86';
}

.xgl-four_staples_top_l_two_tone::before {
  content: '\ED85';
}

.xgl-four_staples_top_l_two_tone::after {
  content: '\ED86';
  opacity: .2;
}

.xgl-four_staples_top_p::before {
  content: '\ED87';
}

.xgl-four_staples_top_p_alt::before {
  content: '\ED88';
}

.xgl-four_staples_top_p_two_tone::before {
  content: '\ED87';
}

.xgl-four_staples_top_p_two_tone::after {
  content: '\ED88';
  opacity: .2;
}

.xgl-full_cut_tabs_lef::before {
  content: '\E246';
}

.xgl-full_cut_tabs_lef_alt::before {
  content: '\E705';
}

.xgl-full_cut_tabs_lef_two_tone::before {
  content: '\E246';
}

.xgl-full_cut_tabs_lef_two_tone::after {
  content: '\E705';
  opacity: .2;
}

.xgl-full_cut_tabs_sef::before {
  content: '\E247';
}

.xgl-full_cut_tabs_sef_alt::before {
  content: '\E706';
}

.xgl-full_cut_tabs_sef_two_tone::before {
  content: '\E247';
}

.xgl-full_cut_tabs_sef_two_tone::after {
  content: '\E706';
  opacity: .2;
}

.xgl-fullscreen::before {
  content: '\E677';
}

.xgl-fullscreen_exit::before {
  content: '\E678';
}

.xgl-function::before {
  content: '\E5C6';
}

.xgl-funnel::before {
  content: '\E248';
}

.xgl-funnel_alt::before {
  content: '\E249';
}

.xgl-funnel_two_tone::before {
  content: '\E248';
}

.xgl-funnel_two_tone::after {
  content: '\E249';
  opacity: .2;
}

.xgl-fuser_web::before {
  content: '\E66D';
}

.xgl-fuser_web_gloss::before {
  content: '\E66E';
}

.xgl-fx_logo::before {
  content: '\E24A';
}

.xgl-fx_logo_small::before {
  content: '\E24B';
}

.xgl-g3::before {
  content: '\EC11';
}

.xgl-g3_alt::before {
  content: '\EC12';
}

.xgl-g3_auto::before {
  content: '\EBDD';
}

.xgl-g3_auto_alt::before {
  content: '\EBDE';
}

.xgl-g3_auto_two_tone::before {
  content: '\EBDD';
}

.xgl-g3_auto_two_tone::after {
  content: '\EBDE';
  opacity: .2;
}

.xgl-g3_two_tone::before {
  content: '\EC11';
}

.xgl-g3_two_tone::after {
  content: '\EC12';
  opacity: .2;
}

.xgl-gas_cylinder::before {
  content: '\EE13';
}

.xgl-gas_cylinder_alt::before {
  content: '\EE14';
}

.xgl-gas_cylinder_two_tone::before {
  content: '\EE13';
}

.xgl-gas_cylinder_two_tone::after {
  content: '\EE14';
  opacity: .2;
}

.xgl-gavel::before {
  content: '\E629';
}

.xgl-gavel_alt::before {
  content: '\E62A';
}

.xgl-gavel_two_tone::before {
  content: '\E629';
}

.xgl-gavel_two_tone::after {
  content: '\E62A';
  opacity: .2;
}

.xgl-gear::before {
  content: '\E24C';
}

.xgl-gear_alt::before {
  content: '\E24D';
}

.xgl-gear_disabled::before {
  content: '\EC71';
}

.xgl-gear_disabled_alt::before {
  content: '\EC72';
}

.xgl-gear_disabled_two_tone::before {
  content: '\EC71';
}

.xgl-gear_disabled_two_tone::after {
  content: '\EC72';
  opacity: .2;
}

.xgl-gear_square::before {
  content: '\E24E';
}

.xgl-gear_square_alt::before {
  content: '\E24F';
}

.xgl-gear_square_two_tone::before {
  content: '\E24E';
}

.xgl-gear_square_two_tone::after {
  content: '\E24F';
  opacity: .2;
}

.xgl-gear_two_tone::before {
  content: '\E24C';
}

.xgl-gear_two_tone::after {
  content: '\E24D';
  opacity: .2;
}

.xgl-gears::before {
  content: '\E250';
}

.xgl-geometry::before {
  content: '\EE29';
}

.xgl-geometry_alt::before {
  content: '\EE2A';
}

.xgl-geometry_two_tone::before {
  content: '\EE29';
}

.xgl-geometry_two_tone::after {
  content: '\EE2A';
  opacity: .2;
}

.xgl-ghost_print::before {
  content: '\EBA9';
}

.xgl-ghost_print_alt::before {
  content: '\EBAA';
}

.xgl-ghost_print_two_tone::before {
  content: '\EBA9';
}

.xgl-ghost_print_two_tone::after {
  content: '\EBAA';
  opacity: .2;
}

.xgl-gift::before {
  content: '\E636';
}

.xgl-gift_alt::before {
  content: '\E637';
}

.xgl-gift_two_tone::before {
  content: '\E636';
}

.xgl-gift_two_tone::after {
  content: '\E637';
  opacity: .2;
}

.xgl-globe::before {
  content: '\E251';
}

.xgl-globe_alt::before {
  content: '\E252';
}

.xgl-globe_earth::before {
  content: '\E6AB';
}

.xgl-globe_earth_alt::before {
  content: '\E6AC';
}

.xgl-globe_earth_two_tone::before {
  content: '\E6AB';
}

.xgl-globe_earth_two_tone::after {
  content: '\E6AC';
  opacity: .2;
}

.xgl-globe_two_tone::before {
  content: '\E251';
}

.xgl-globe_two_tone::after {
  content: '\E252';
  opacity: .2;
}

.xgl-globe_view::before {
  content: '\EDCA';
}

.xgl-globe_view_alt::before {
  content: '\EDCB';
}

.xgl-globe_view_two_tone::before {
  content: '\EDCA';
}

.xgl-globe_view_two_tone::after {
  content: '\EDCB';
  opacity: .2;
}

.xgl-gloss::before {
  content: '\E83D';
}

.xgl-gloss_alt::before {
  content: '\E83E';
}

.xgl-gloss_lef::before {
  content: '\E253';
}

.xgl-gloss_lef_alt::before {
  content: '\E707';
}

.xgl-gloss_lef_two_tone::before {
  content: '\E253';
}

.xgl-gloss_lef_two_tone::after {
  content: '\E707';
  opacity: .2;
}

.xgl-gloss_reloaded_lef::before {
  content: '\E254';
}

.xgl-gloss_reloaded_lef_alt::before {
  content: '\E708';
}

.xgl-gloss_reloaded_lef_two_tone::before {
  content: '\E254';
}

.xgl-gloss_reloaded_lef_two_tone::after {
  content: '\E708';
  opacity: .2;
}

.xgl-gloss_reloaded_sef::before {
  content: '\E255';
}

.xgl-gloss_reloaded_sef_alt::before {
  content: '\E709';
}

.xgl-gloss_reloaded_sef_two_tone::before {
  content: '\E255';
}

.xgl-gloss_reloaded_sef_two_tone::after {
  content: '\E709';
  opacity: .2;
}

.xgl-gloss_sef::before {
  content: '\E256';
}

.xgl-gloss_sef_alt::before {
  content: '\E70A';
}

.xgl-gloss_sef_two_tone::before {
  content: '\E256';
}

.xgl-gloss_sef_two_tone::after {
  content: '\E70A';
  opacity: .2;
}

.xgl-gloss_two_tone::before {
  content: '\E83D';
}

.xgl-gloss_two_tone::after {
  content: '\E83E';
  opacity: .2;
}

.xgl-gmail::before {
  content: '\EB7C';
}

.xgl-gmail_alt::before {
  content: '\EB7D';
}

.xgl-gmail_two_tone::before {
  content: '\EB7C';
}

.xgl-gmail_two_tone::after {
  content: '\EB7D';
  opacity: .2;
}

.xgl-goal::before {
  content: '\E257';
}

.xgl-google::before {
  content: '\E258';
}

.xgl-google_drive::before {
  content: '\E259';
}

.xgl-google_drive_alt::before {
  content: '\E95A';
}

.xgl-google_drive_two_tone::before {
  content: '\E259';
}

.xgl-google_drive_two_tone::after {
  content: '\E95A';
  opacity: .2;
}

.xgl-google_play::before {
  content: '\E25A';
}

.xgl-google_play_alt::before {
  content: '\E25B';
}

.xgl-google_play_two_tone::before {
  content: '\E25A';
}

.xgl-google_play_two_tone::after {
  content: '\E25B';
  opacity: .2;
}

.xgl-grid::before {
  content: '\E25C';
}

.xgl-grid_alt::before {
  content: '\E25D';
}

.xgl-grid_two_tone::before {
  content: '\E25C';
}

.xgl-grid_two_tone::after {
  content: '\E25D';
  opacity: .2;
}

.xgl-group::before {
  content: '\E25E';
}

.xgl-group_add::before {
  content: '\E25F';
}

.xgl-group_add_alt::before {
  content: '\E260';
}

.xgl-group_add_two_tone::before {
  content: '\E25F';
}

.xgl-group_add_two_tone::after {
  content: '\E260';
  opacity: .2;
}

.xgl-group_alt::before {
  content: '\E261';
}

.xgl-group_favorite::before {
  content: '\E262';
}

.xgl-group_favorite_alt::before {
  content: '\E263';
}

.xgl-group_favorite_two_tone::before {
  content: '\E262';
}

.xgl-group_favorite_two_tone::after {
  content: '\E263';
  opacity: .2;
}

.xgl-group_network::before {
  content: '\E264';
}

.xgl-group_network_alt::before {
  content: '\E265';
}

.xgl-group_network_two_tone::before {
  content: '\E264';
}

.xgl-group_network_two_tone::after {
  content: '\E265';
  opacity: .2;
}

.xgl-group_two_tone::before {
  content: '\E25E';
}

.xgl-group_two_tone::after {
  content: '\E261';
  opacity: .2;
}

.xgl-halftone::before {
  content: '\E83F';
}

.xgl-halftone_alt::before {
  content: '\EC7B';
}

.xgl-halftone_two_tone::before {
  content: '\E83F';
}

.xgl-halftone_two_tone::after {
  content: '\EC7B';
  opacity: .2;
}

.xgl-hand::before {
  content: '\E266';
}

.xgl-hand_alt::before {
  content: '\E267';
}

.xgl-hand_pinch::before {
  content: '\E95B';
}

.xgl-hand_pinch_alt::before {
  content: '\E95C';
}

.xgl-hand_pinch_two_tone::before {
  content: '\E95B';
}

.xgl-hand_pinch_two_tone::after {
  content: '\E95C';
  opacity: .2;
}

.xgl-hand_square::before {
  content: '\EBAB';
}

.xgl-hand_square_all::before {
  content: '\EBAC';
}

.xgl-hand_square_all_alt::before {
  content: '\EBAD';
}

.xgl-hand_square_all_two_tone::before {
  content: '\EBAC';
}

.xgl-hand_square_all_two_tone::after {
  content: '\EBAD';
  opacity: .2;
}

.xgl-hand_square_alt::before {
  content: '\EBAE';
}

.xgl-hand_square_two_tone::before {
  content: '\EBAB';
}

.xgl-hand_square_two_tone::after {
  content: '\EBAE';
  opacity: .2;
}

.xgl-hand_two_tone::before {
  content: '\E266';
}

.xgl-hand_two_tone::after {
  content: '\E267';
  opacity: .2;
}

.xgl-hard_drive::before {
  content: '\E268';
}

.xgl-hard_drive_alt::before {
  content: '\E269';
}

.xgl-hard_drive_two_tone::before {
  content: '\E268';
}

.xgl-hard_drive_two_tone::after {
  content: '\E269';
  opacity: .2;
}

.xgl-header_disabled::before {
  content: '\EBF1';
}

.xgl-header_disabled_alt::before {
  content: '\EBF2';
}

.xgl-header_disabled_two_tone::before {
  content: '\EBF1';
}

.xgl-header_disabled_two_tone::after {
  content: '\EBF2';
  opacity: .2;
}

.xgl-header_one::before {
  content: '\E5B7';
}

.xgl-header_two::before {
  content: '\E5B8';
}

.xgl-headphones::before {
  content: '\E26A';
}

.xgl-headset::before {
  content: '\E26B';
}

.xgl-hearing::before {
  content: '\E61B';
}

.xgl-hearing_alt::before {
  content: '\EC7A';
}

.xgl-hearing_two_tone::before {
  content: '\E61B';
}

.xgl-hearing_two_tone::after {
  content: '\EC7A';
  opacity: .2;
}

.xgl-heart::before {
  content: '\E26C';
}

.xgl-heart_alt::before {
  content: '\E26D';
}

.xgl-heart_disabled::before {
  content: '\E26E';
}

.xgl-heart_disabled_alt::before {
  content: '\E26F';
}

.xgl-heart_disabled_two_tone::before {
  content: '\E26E';
}

.xgl-heart_disabled_two_tone::after {
  content: '\E26F';
  opacity: .2;
}

.xgl-heart_rate::before {
  content: '\E7F6';
}

.xgl-heart_rate_alt::before {
  content: '\E7F7';
}

.xgl-heart_rate_two_tone::before {
  content: '\E7F6';
}

.xgl-heart_rate_two_tone::after {
  content: '\E7F7';
  opacity: .2;
}

.xgl-heart_two_tone::before {
  content: '\E26C';
}

.xgl-heart_two_tone::after {
  content: '\E26D';
  opacity: .2;
}

.xgl-heated_surface::before {
  content: '\EA33';
}

.xgl-heated_surface_alt::before {
  content: '\EA34';
}

.xgl-heated_surface_two_tone::before {
  content: '\EA33';
}

.xgl-heated_surface_two_tone::after {
  content: '\EA34';
  opacity: .2;
}

.xgl-heater::before {
  content: '\E65D';
}

.xgl-heater_alt::before {
  content: '\E65E';
}

.xgl-heater_two_tone::before {
  content: '\E65D';
}

.xgl-heater_two_tone::after {
  content: '\E65E';
  opacity: .2;
}

.xgl-help::before {
  content: '\E280';
}

.xgl-help_alt::before {
  content: '\E281';
}

.xgl-help_rtl::before {
  content: '\E5A2';
}

.xgl-help_rtl_alt::before {
  content: '\E5A3';
}

.xgl-help_rtl_two_tone::before {
  content: '\E5A2';
}

.xgl-help_rtl_two_tone::after {
  content: '\E5A3';
  opacity: .2;
}

.xgl-help_stack_bkgd::before {
  content: '\E99C';
}

.xgl-help_stack_frgd::before {
  content: '\E99D';
}

.xgl-help_stack_frgd_rtl::before {
  content: '\EC7C';
}

.xgl-help_stack_mdgd::before {
  content: '\E99E';
}

.xgl-help_two_tone::before {
  content: '\E280';
}

.xgl-help_two_tone::after {
  content: '\E281';
  opacity: .2;
}

.xgl-helping_hands::before {
  content: '\E796';
}

.xgl-helping_hands_alt::before {
  content: '\E797';
}

.xgl-helping_hands_two_tone::before {
  content: '\E796';
}

.xgl-helping_hands_two_tone::after {
  content: '\E797';
  opacity: .2;
}

.xgl-highlighter::before {
  content: '\E282';
}

.xgl-highlighter_alt::before {
  content: '\E283';
}

.xgl-highlighter_color::before {
  content: '\E284';
}

.xgl-highlighter_two_tone::before {
  content: '\E282';
}

.xgl-highlighter_two_tone::after {
  content: '\E283';
  opacity: .2;
}

.xgl-history::before {
  content: '\E285';
}

.xgl-history_alt::before {
  content: '\EC7D';
}

.xgl-history_two_tone::before {
  content: '\E285';
}

.xgl-history_two_tone::after {
  content: '\EC7D';
  opacity: .2;
}

.xgl-hole_punch::before {
  content: '\E286';
}

.xgl-hole_punch_disabled::before {
  content: '\EC3F';
}

.xgl-hole_punch_disabled_alt::before {
  content: '\EC40';
}

.xgl-hole_punch_disabled_two_tone::before {
  content: '\EC3F';
}

.xgl-hole_punch_disabled_two_tone::after {
  content: '\EC40';
  opacity: .2;
}

.xgl-hole_punched_lef::before {
  content: '\E287';
}

.xgl-hole_punched_lef_alt::before {
  content: '\E70B';
}

.xgl-hole_punched_lef_two_tone::before {
  content: '\E287';
}

.xgl-hole_punched_lef_two_tone::after {
  content: '\E70B';
  opacity: .2;
}

.xgl-hole_punched_sef::before {
  content: '\E288';
}

.xgl-hole_punched_sef_alt::before {
  content: '\E70C';
}

.xgl-hole_punched_sef_two_tone::before {
  content: '\E288';
}

.xgl-hole_punched_sef_two_tone::after {
  content: '\E70C';
  opacity: .2;
}

.xgl-home::before {
  content: '\E289';
}

.xgl-home_alt::before {
  content: '\E28A';
}

.xgl-home_favorite::before {
  content: '\E28B';
}

.xgl-home_favorite_alt::before {
  content: '\E28C';
}

.xgl-home_favorite_two_tone::before {
  content: '\E28B';
}

.xgl-home_favorite_two_tone::after {
  content: '\E28C';
  opacity: .2;
}

.xgl-home_two_tone::before {
  content: '\E289';
}

.xgl-home_two_tone::after {
  content: '\E28A';
  opacity: .2;
}

.xgl-hospital::before {
  content: '\E28D';
}

.xgl-hospital_alt::before {
  content: '\E28E';
}

.xgl-hospital_two_tone::before {
  content: '\E28D';
}

.xgl-hospital_two_tone::after {
  content: '\E28E';
  opacity: .2;
}

.xgl-hotmail::before {
  content: '\EB7E';
}

.xgl-hotmail_alt::before {
  content: '\EB7F';
}

.xgl-hotmail_two_tone::before {
  content: '\EB7E';
}

.xgl-hotmail_two_tone::after {
  content: '\EB7F';
  opacity: .2;
}

.xgl-hotspot::before {
  content: '\E28F';
}

.xgl-hotspot_alt::before {
  content: '\EC93';
}

.xgl-hotspot_two_tone::before {
  content: '\E28F';
}

.xgl-hotspot_two_tone::after {
  content: '\EC93';
  opacity: .2;
}

.xgl-hourglass::before {
  content: '\E7A4';
}

.xgl-hourglass_alt::before {
  content: '\E7A5';
}

.xgl-hourglass_two_tone::before {
  content: '\E7A4';
}

.xgl-hourglass_two_tone::after {
  content: '\E7A5';
  opacity: .2;
}

.xgl-hw_gloss_lef::before {
  content: '\E270';
}

.xgl-hw_gloss_lef_alt::before {
  content: '\E70D';
}

.xgl-hw_gloss_lef_two_tone::before {
  content: '\E270';
}

.xgl-hw_gloss_lef_two_tone::after {
  content: '\E70D';
  opacity: .2;
}

.xgl-hw_gloss_reloaded_lef::before {
  content: '\E271';
}

.xgl-hw_gloss_reloaded_lef_alt::before {
  content: '\E70E';
}

.xgl-hw_gloss_reloaded_lef_two_tone::before {
  content: '\E271';
}

.xgl-hw_gloss_reloaded_lef_two_tone::after {
  content: '\E70E';
  opacity: .2;
}

.xgl-hw_gloss_reloaded_sef::before {
  content: '\E272';
}

.xgl-hw_gloss_reloaded_sef_alt::before {
  content: '\E70F';
}

.xgl-hw_gloss_reloaded_sef_two_tone::before {
  content: '\E272';
}

.xgl-hw_gloss_reloaded_sef_two_tone::after {
  content: '\E70F';
  opacity: .2;
}

.xgl-hw_gloss_sef::before {
  content: '\E273';
}

.xgl-hw_gloss_sef_alt::before {
  content: '\E710';
}

.xgl-hw_gloss_sef_two_tone::before {
  content: '\E273';
}

.xgl-hw_gloss_sef_two_tone::after {
  content: '\E710';
  opacity: .2;
}

.xgl-hw_hole_punched_lef::before {
  content: '\E274';
}

.xgl-hw_hole_punched_lef_alt::before {
  content: '\E711';
}

.xgl-hw_hole_punched_lef_two_tone::before {
  content: '\E274';
}

.xgl-hw_hole_punched_lef_two_tone::after {
  content: '\E711';
  opacity: .2;
}

.xgl-hw_hole_punched_sef::before {
  content: '\E275';
}

.xgl-hw_hole_punched_sef_alt::before {
  content: '\E712';
}

.xgl-hw_hole_punched_sef_two_tone::before {
  content: '\E275';
}

.xgl-hw_hole_punched_sef_two_tone::after {
  content: '\E712';
  opacity: .2;
}

.xgl-hw_labels_lef::before {
  content: '\E276';
}

.xgl-hw_labels_lef_alt::before {
  content: '\E713';
}

.xgl-hw_labels_lef_two_tone::before {
  content: '\E276';
}

.xgl-hw_labels_lef_two_tone::after {
  content: '\E713';
  opacity: .2;
}

.xgl-hw_labels_sef::before {
  content: '\E277';
}

.xgl-hw_labels_sef_alt::before {
  content: '\E714';
}

.xgl-hw_labels_sef_two_tone::before {
  content: '\E277';
}

.xgl-hw_labels_sef_two_tone::after {
  content: '\E714';
  opacity: .2;
}

.xgl-hw_lef::before {
  content: '\E278';
}

.xgl-hw_lef_alt::before {
  content: '\E715';
}

.xgl-hw_lef_two_tone::before {
  content: '\E278';
}

.xgl-hw_lef_two_tone::after {
  content: '\E715';
  opacity: .2;
}

.xgl-hw_precut_tabs_lef::before {
  content: '\E279';
}

.xgl-hw_precut_tabs_lef_alt::before {
  content: '\E716';
}

.xgl-hw_precut_tabs_lef_two_tone::before {
  content: '\E279';
}

.xgl-hw_precut_tabs_lef_two_tone::after {
  content: '\E716';
  opacity: .2;
}

.xgl-hw_precut_tabs_sef::before {
  content: '\E27A';
}

.xgl-hw_precut_tabs_sef_alt::before {
  content: '\E717';
}

.xgl-hw_precut_tabs_sef_two_tone::before {
  content: '\E27A';
}

.xgl-hw_precut_tabs_sef_two_tone::after {
  content: '\E717';
  opacity: .2;
}

.xgl-hw_preprinted_lef::before {
  content: '\E27B';
}

.xgl-hw_preprinted_lef_alt::before {
  content: '\E718';
}

.xgl-hw_preprinted_lef_two_tone::before {
  content: '\E27B';
}

.xgl-hw_preprinted_lef_two_tone::after {
  content: '\E718';
  opacity: .2;
}

.xgl-hw_preprinted_sef::before {
  content: '\E27C';
}

.xgl-hw_preprinted_sef_alt::before {
  content: '\E719';
}

.xgl-hw_preprinted_sef_two_tone::before {
  content: '\E27C';
}

.xgl-hw_preprinted_sef_two_tone::after {
  content: '\E719';
  opacity: .2;
}

.xgl-hw_reloaded_lef::before {
  content: '\E27D';
}

.xgl-hw_reloaded_lef_alt::before {
  content: '\E71A';
}

.xgl-hw_reloaded_lef_two_tone::before {
  content: '\E27D';
}

.xgl-hw_reloaded_lef_two_tone::after {
  content: '\E71A';
  opacity: .2;
}

.xgl-hw_reloaded_sef::before {
  content: '\E27E';
}

.xgl-hw_reloaded_sef_alt::before {
  content: '\E71B';
}

.xgl-hw_reloaded_sef_two_tone::before {
  content: '\E27E';
}

.xgl-hw_reloaded_sef_two_tone::after {
  content: '\E71B';
  opacity: .2;
}

.xgl-hw_sef::before {
  content: '\E27F';
}

.xgl-hw_sef_alt::before {
  content: '\E71C';
}

.xgl-hw_sef_two_tone::before {
  content: '\E27F';
}

.xgl-hw_sef_two_tone::after {
  content: '\E71C';
  opacity: .2;
}

.xgl-icloud::before {
  content: '\EB80';
}

.xgl-icloud_alt::before {
  content: '\EB81';
}

.xgl-icloud_two_tone::before {
  content: '\EB80';
}

.xgl-icloud_two_tone::after {
  content: '\EB81';
  opacity: .2;
}

.xgl-id::before {
  content: '\E655';
}

.xgl-id_alt::before {
  content: '\E656';
}

.xgl-id_card::before {
  content: '\E290';
}

.xgl-id_card_two_tone::before {
  content: '\E290';
}

.xgl-id_card_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-id_two_tone::before {
  content: '\E655';
}

.xgl-id_two_tone::after {
  content: '\E656';
  opacity: .2;
}

.xgl-ifax_cc::before {
  content: '\EA72';
}

.xgl-ifax_cc_alt::before {
  content: '\EA73';
}

.xgl-ifax_cc_two_tone::before {
  content: '\EA72';
}

.xgl-ifax_cc_two_tone::after {
  content: '\EA73';
  opacity: .2;
}

.xgl-ignored::before {
  content: '\E291';
}

.xgl-ignored_alt::before {
  content: '\E292';
}

.xgl-ignored_two_tone::before {
  content: '\E291';
}

.xgl-ignored_two_tone::after {
  content: '\E292';
  opacity: .2;
}

.xgl-illustrator::before {
  content: '\E8D6';
}

.xgl-illustrator_alt::before {
  content: '\E8D5';
}

.xgl-illustrator_two_tone::before {
  content: '\E8D6';
}

.xgl-illustrator_two_tone::after {
  content: '\E8D5';
  opacity: .2;
}

.xgl-image_add::before {
  content: '\E7AF';
}

.xgl-image_add_alt::before {
  content: '\E7B0';
}

.xgl-image_add_two_tone::before {
  content: '\E7AF';
}

.xgl-image_add_two_tone::after {
  content: '\E7B0';
  opacity: .2;
}

.xgl-image_scale_fill::before {
  content: '\EBAF';
}

.xgl-image_scale_fill_alt::before {
  content: '\EBB0';
}

.xgl-image_scale_fill_two_tone::before {
  content: '\EBAF';
}

.xgl-image_scale_fill_two_tone::after {
  content: '\EBB0';
  opacity: .2;
}

.xgl-image_scale_fit::before {
  content: '\EBB1';
}

.xgl-image_scale_fit_alt::before {
  content: '\EBB2';
}

.xgl-image_scale_fit_two_tone::before {
  content: '\EBB1';
}

.xgl-image_scale_fit_two_tone::after {
  content: '\EBB2';
  opacity: .2;
}

.xgl-image_scale_placement::before {
  content: '\EBB3';
}

.xgl-image_scale_placement_alt::before {
  content: '\EBB4';
}

.xgl-image_scale_placement_two_tone::before {
  content: '\EBB3';
}

.xgl-image_scale_placement_two_tone::after {
  content: '\EBB4';
  opacity: .2;
}

.xgl-image_send::before {
  content: '\EBB7';
}

.xgl-image_send_alt::before {
  content: '\EBB8';
}

.xgl-image_send_two_tone::before {
  content: '\EBB7';
}

.xgl-image_send_two_tone::after {
  content: '\EBB8';
  opacity: .2;
}

.xgl-image_shift::before {
  content: '\EBBD';
}

.xgl-image_shift_alt::before {
  content: '\EBBE';
}

.xgl-image_shift_disabled::before {
  content: '\EBBF';
}

.xgl-image_shift_disabled_alt::before {
  content: '\EBC0';
}

.xgl-image_shift_disabled_two_tone::before {
  content: '\EBBF';
}

.xgl-image_shift_disabled_two_tone::after {
  content: '\EBC0';
  opacity: .2;
}

.xgl-image_shift_two_tone::before {
  content: '\EBBD';
}

.xgl-image_shift_two_tone::after {
  content: '\EBBE';
  opacity: .2;
}

.xgl-import::before {
  content: '\E293';
}

.xgl-import_alt::before {
  content: '\E294';
}

.xgl-import_export::before {
  content: '\E295';
}

.xgl-import_model::before {
  content: '\EE39';
}

.xgl-import_model_alt::before {
  content: '\EE3A';
}

.xgl-import_model_two_tone::before {
  content: '\EE39';
}

.xgl-import_model_two_tone::after {
  content: '\EE3A';
  opacity: .2;
}

.xgl-import_two_tone::before {
  content: '\E293';
}

.xgl-import_two_tone::after {
  content: '\E294';
  opacity: .2;
}

.xgl-inactive::before {
  content: '\EC9A';
}

.xgl-inactive_circle::before {
  content: '\ECA6';
}

.xgl-inactive_circle_alt::before {
  content: '\ECA7';
}

.xgl-inactive_circle_two_tone::before {
  content: '\ECA6';
}

.xgl-inactive_circle_two_tone::after {
  content: '\ECA7';
  opacity: .2;
}

.xgl-inactive_stack_bkgd::before {
  content: '\ECA8';
}

.xgl-inactive_stack_frgd::before {
  content: '\ECA9';
}

.xgl-inactive_stack_mdgd::before {
  content: '\ECAA';
}

.xgl-inactive_two_tone::before {
  content: '\EC9A';
}

.xgl-inactive_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-inbox::before {
  content: '\E296';
}

.xgl-inbox_alt::before {
  content: '\E297';
}

.xgl-inbox_two_tone::before {
  content: '\E296';
}

.xgl-inbox_two_tone::after {
  content: '\E297';
  opacity: .2;
}

.xgl-incomplete_stack_bkgd::before {
  content: '\E99F';
}

.xgl-incomplete_stack_frgd::before {
  content: '\E9A0';
}

.xgl-incomplete_stack_mdgd::before {
  content: '\E9A1';
}

.xgl-increase_indent::before {
  content: '\E298';
}

.xgl-info_pages::before {
  content: '\EDCC';
}

.xgl-info_pages_alt::before {
  content: '\EDCD';
}

.xgl-info_pages_two_tone::before {
  content: '\EDCC';
}

.xgl-info_pages_two_tone::after {
  content: '\EDCD';
  opacity: .2;
}

.xgl-info_stack_bkgd::before {
  content: '\E9A2';
}

.xgl-info_stack_frgd::before {
  content: '\E9A3';
}

.xgl-info_stack_mdgd::before {
  content: '\E9A4';
}

.xgl-infrared::before {
  content: '\ECC1';
}

.xgl-infrared_alt::before {
  content: '\ECC2';
}

.xgl-infrared_two_tone::before {
  content: '\ECC1';
}

.xgl-infrared_two_tone::after {
  content: '\ECC2';
  opacity: .2;
}

.xgl-ink_purge::before {
  content: '\E695';
}

.xgl-ink_purge_alt::before {
  content: '\E696';
}

.xgl-ink_purge_two_tone::before {
  content: '\E695';
}

.xgl-ink_purge_two_tone::after {
  content: '\E696';
  opacity: .2;
}

.xgl-inkjet::before {
  content: '\EBC5';
}

.xgl-inkjet_alt::before {
  content: '\EBC6';
}

.xgl-inkjet_two_tone::before {
  content: '\EBC5';
}

.xgl-inkjet_two_tone::after {
  content: '\EBC6';
  opacity: .2;
}

.xgl-input::before {
  content: '\E798';
}

.xgl-insert::before {
  content: '\E299';
}

.xgl-insert_alt::before {
  content: '\E29A';
}

.xgl-insert_pages::before {
  content: '\EC07';
}

.xgl-insert_pages_alt::before {
  content: '\EC08';
}

.xgl-insert_pages_two_tone::before {
  content: '\EC07';
}

.xgl-insert_pages_two_tone::after {
  content: '\EC08';
  opacity: .2;
}

.xgl-insert_two_tone::before {
  content: '\E299';
}

.xgl-insert_two_tone::after {
  content: '\E29A';
  opacity: .2;
}

.xgl-inserter_paper_empty::before {
  content: '\E29B';
}

.xgl-inserter_paper_present::before {
  content: '\E29C';
}

.xgl-instagram::before {
  content: '\E29D';
}

.xgl-instagram_alt::before {
  content: '\EC7E';
}

.xgl-instagram_two_tone::before {
  content: '\E29D';
}

.xgl-instagram_two_tone::after {
  content: '\EC7E';
  opacity: .2;
}

.xgl-install::before {
  content: '\E29E';
}

.xgl-install_options::before {
  content: '\EBC1';
}

.xgl-install_options_alt::before {
  content: '\EBC2';
}

.xgl-install_options_two_tone::before {
  content: '\EBC1';
}

.xgl-install_options_two_tone::after {
  content: '\EBC2';
  opacity: .2;
}

.xgl-install_square::before {
  content: '\E29F';
}

.xgl-install_square_alt::before {
  content: '\E2A0';
}

.xgl-install_square_two_tone::before {
  content: '\E29F';
}

.xgl-install_square_two_tone::after {
  content: '\E2A0';
  opacity: .2;
}

.xgl-internet_explorer::before {
  content: '\E2A1';
}

.xgl-internet_fax::before {
  content: '\E8EB';
}

.xgl-internet_fax_alt::before {
  content: '\E8EC';
}

.xgl-internet_fax_two_tone::before {
  content: '\E8EB';
}

.xgl-internet_fax_two_tone::after {
  content: '\E8EC';
  opacity: .2;
}

.xgl-interrupt::before {
  content: '\E2A2';
}

.xgl-invert::before {
  content: '\E804';
}

.xgl-invert_alt::before {
  content: '\EC7F';
}

.xgl-invert_two_tone::before {
  content: '\E804';
}

.xgl-invert_two_tone::after {
  content: '\EC7F';
  opacity: .2;
}

.xgl-invitation::before {
  content: '\E7F2';
}

.xgl-invitation_alt::before {
  content: '\E7F3';
}

.xgl-invitation_two_tone::before {
  content: '\E7F2';
}

.xgl-invitation_two_tone::after {
  content: '\E7F3';
  opacity: .2;
}

.xgl-ion_transfer::before {
  content: '\EAD6';
}

.xgl-ip_address::before {
  content: '\ED42';
}

.xgl-ip_address_alt::before {
  content: '\ED43';
}

.xgl-ip_address_two_tone::before {
  content: '\ED42';
}

.xgl-ip_address_two_tone::after {
  content: '\ED43';
  opacity: .2;
}

.xgl-isotropic_remesh::before {
  content: '\ECFB';
}

.xgl-isotropic_remesh_alt::before {
  content: '\ECFC';
}

.xgl-isotropic_remesh_two_tone::before {
  content: '\ECFB';
}

.xgl-isotropic_remesh_two_tone::after {
  content: '\ECFC';
  opacity: .2;
}

.xgl-issue::before {
  content: '\E7AD';
}

.xgl-issue_alt::before {
  content: '\E7AE';
}

.xgl-issue_two_tone::before {
  content: '\E7AD';
}

.xgl-issue_two_tone::after {
  content: '\E7AE';
  opacity: .2;
}

.xgl-italic::before {
  content: '\E2A3';
}

.xgl-justify_text::before {
  content: '\E2A4';
}

.xgl-key::before {
  content: '\E2A5';
}

.xgl-key_alt::before {
  content: '\E2A6';
}

.xgl-key_counter::before {
  content: '\E2A7';
}

.xgl-key_counter_two_tone::before {
  content: '\E2A7';
}

.xgl-key_counter_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-key_two_tone::before {
  content: '\E2A5';
}

.xgl-key_two_tone::after {
  content: '\E2A6';
  opacity: .2;
}

.xgl-keyboard::before {
  content: '\E2A8';
}

.xgl-keyboard_accent::before {
  content: '\E2A9';
}

.xgl-keyboard_alpha::before {
  content: '\E2AA';
}

.xgl-keyboard_alt::before {
  content: '\E2AB';
}

.xgl-keyboard_arabic::before {
  content: '\E596';
}

.xgl-keyboard_chinese_simplified::before {
  content: '\E592';
}

.xgl-keyboard_chinese_traditional::before {
  content: '\E593';
}

.xgl-keyboard_down::before {
  content: '\E2AC';
}

.xgl-keyboard_down_alt::before {
  content: '\E2AD';
}

.xgl-keyboard_down_two_tone::before {
  content: '\E2AC';
}

.xgl-keyboard_down_two_tone::after {
  content: '\E2AD';
  opacity: .2;
}

.xgl-keyboard_greek_alpha::before {
  content: '\E2AE';
}

.xgl-keyboard_hankaku::before {
  content: '\E850';
}

.xgl-keyboard_japanese_hiragana::before {
  content: '\E590';
}

.xgl-keyboard_japanese_kana::before {
  content: '\E591';
}

.xgl-keyboard_japanese_katakana::before {
  content: '\E58F';
}

.xgl-keyboard_korean::before {
  content: '\E594';
}

.xgl-keyboard_numbers::before {
  content: '\E2AF';
}

.xgl-keyboard_period::before {
  content: '\E9D3';
}

.xgl-keyboard_russian_alpha::before {
  content: '\E2B0';
}

.xgl-keyboard_symbols::before {
  content: '\E2B1';
}

.xgl-keyboard_thai::before {
  content: '\E595';
}

.xgl-keyboard_two_tone::before {
  content: '\E2A8';
}

.xgl-keyboard_two_tone::after {
  content: '\E2AB';
  opacity: .2;
}

.xgl-keyboard_vietnam::before {
  content: '\E851';
}

.xgl-keyboard_view::before {
  content: '\EC15';
}

.xgl-keyboard_view_alt::before {
  content: '\EC16';
}

.xgl-keyboard_view_two_tone::before {
  content: '\EC15';
}

.xgl-keyboard_view_two_tone::after {
  content: '\EC16';
  opacity: .2;
}

.xgl-keyboard_zenkaku::before {
  content: '\E852';
}

.xgl-keyhole::before {
  content: '\E675';
}

.xgl-keyhole_alt::before {
  content: '\E676';
}

.xgl-keyhole_two_tone::before {
  content: '\E675';
}

.xgl-keyhole_two_tone::after {
  content: '\E676';
  opacity: .2;
}

.xgl-keypad::before {
  content: '\E2B2';
}

.xgl-keypad_asterisk::before {
  content: '\E64C';
}

.xgl-keypad_colon::before {
  content: '\E699';
}

.xgl-keypad_comma::before {
  content: '\E69A';
}

.xgl-keypad_down::before {
  content: '\EB3C';
}

.xgl-keypad_down_alt::before {
  content: '\EB3D';
}

.xgl-keypad_down_two_tone::before {
  content: '\EB3C';
}

.xgl-keypad_down_two_tone::after {
  content: '\EB3D';
  opacity: .2;
}

.xgl-keypad_equals_sign::before {
  content: '\E69B';
}

.xgl-keypad_line_one::before {
  content: '\EC6C';
}

.xgl-keypad_line_two::before {
  content: '\EC6D';
}

.xgl-keypad_parenthesis_left::before {
  content: '\E69C';
}

.xgl-keypad_parenthesis_right::before {
  content: '\E69D';
}

.xgl-keypad_password::before {
  content: '\E69E';
}

.xgl-keypad_pound::before {
  content: '\E64B';
}

.xgl-keypad_reverse_solidus::before {
  content: '\E69F';
}

.xgl-keypad_solidus::before {
  content: '\E6A0';
}

.xgl-keypad_vertical_line::before {
  content: '\E6A1';
}

.xgl-keypad_wait::before {
  content: '\EC6E';
}

.xgl-labels_lef::before {
  content: '\E2B3';
}

.xgl-labels_lef_alt::before {
  content: '\E71D';
}

.xgl-labels_lef_two_tone::before {
  content: '\E2B3';
}

.xgl-labels_lef_two_tone::after {
  content: '\E71D';
  opacity: .2;
}

.xgl-labels_sef::before {
  content: '\E2B4';
}

.xgl-labels_sef_alt::before {
  content: '\E71E';
}

.xgl-labels_sef_two_tone::before {
  content: '\E2B4';
}

.xgl-labels_sef_two_tone::after {
  content: '\E71E';
  opacity: .2;
}

.xgl-lamp_high::before {
  content: '\E776';
}

.xgl-lamp_high_alt::before {
  content: '\EB3E';
}

.xgl-lamp_high_two_tone::before {
  content: '\E776';
}

.xgl-lamp_high_two_tone::after {
  content: '\EB3E';
  opacity: .2;
}

.xgl-lamp_low::before {
  content: '\E777';
}

.xgl-lamp_low_alt::before {
  content: '\EB3F';
}

.xgl-lamp_low_two_tone::before {
  content: '\E777';
}

.xgl-lamp_low_two_tone::after {
  content: '\EB3F';
  opacity: .2;
}

.xgl-landscape::before {
  content: '\E2B5';
}

.xgl-landscape_alt::before {
  content: '\E2B6';
}

.xgl-landscape_two_tone::before {
  content: '\E2B5';
}

.xgl-landscape_two_tone::after {
  content: '\E2B6';
  opacity: .2;
}

.xgl-language_brzlprtgse::before {
  content: '\EB40';
}

.xgl-language_brzlprtgse_alt::before {
  content: '\EB41';
}

.xgl-language_brzlprtgse_two_tone::before {
  content: '\EB40';
}

.xgl-language_brzlprtgse_two_tone::after {
  content: '\EB41';
  opacity: .2;
}

.xgl-language_catalan::before {
  content: '\EB42';
}

.xgl-language_catalan_alt::before {
  content: '\EB43';
}

.xgl-language_catalan_two_tone::before {
  content: '\EB42';
}

.xgl-language_catalan_two_tone::after {
  content: '\EB43';
  opacity: .2;
}

.xgl-language_croatian::before {
  content: '\EB44';
}

.xgl-language_croatian_alt::before {
  content: '\EB45';
}

.xgl-language_croatian_two_tone::before {
  content: '\EB44';
}

.xgl-language_croatian_two_tone::after {
  content: '\EB45';
  opacity: .2;
}

.xgl-language_czech::before {
  content: '\EB46';
}

.xgl-language_czech_alt::before {
  content: '\EB47';
}

.xgl-language_czech_two_tone::before {
  content: '\EB46';
}

.xgl-language_czech_two_tone::after {
  content: '\EB47';
  opacity: .2;
}

.xgl-language_danish::before {
  content: '\EB48';
}

.xgl-language_danish_alt::before {
  content: '\EB49';
}

.xgl-language_danish_two_tone::before {
  content: '\EB48';
}

.xgl-language_danish_two_tone::after {
  content: '\EB49';
  opacity: .2;
}

.xgl-language_dutch::before {
  content: '\EB4A';
}

.xgl-language_dutch_alt::before {
  content: '\EB4B';
}

.xgl-language_dutch_two_tone::before {
  content: '\EB4A';
}

.xgl-language_dutch_two_tone::after {
  content: '\EB4B';
  opacity: .2;
}

.xgl-language_english::before {
  content: '\EB4C';
}

.xgl-language_english_alt::before {
  content: '\EB4D';
}

.xgl-language_english_two_tone::before {
  content: '\EB4C';
}

.xgl-language_english_two_tone::after {
  content: '\EB4D';
  opacity: .2;
}

.xgl-language_finnish::before {
  content: '\EB4E';
}

.xgl-language_finnish_alt::before {
  content: '\EB4F';
}

.xgl-language_finnish_two_tone::before {
  content: '\EB4E';
}

.xgl-language_finnish_two_tone::after {
  content: '\EB4F';
  opacity: .2;
}

.xgl-language_french::before {
  content: '\EB50';
}

.xgl-language_french_alt::before {
  content: '\EB51';
}

.xgl-language_french_two_tone::before {
  content: '\EB50';
}

.xgl-language_french_two_tone::after {
  content: '\EB51';
  opacity: .2;
}

.xgl-language_german::before {
  content: '\EB52';
}

.xgl-language_german_alt::before {
  content: '\EB53';
}

.xgl-language_german_two_tone::before {
  content: '\EB52';
}

.xgl-language_german_two_tone::after {
  content: '\EB53';
  opacity: .2;
}

.xgl-language_greek::before {
  content: '\EB54';
}

.xgl-language_greek_alt::before {
  content: '\EB55';
}

.xgl-language_greek_two_tone::before {
  content: '\EB54';
}

.xgl-language_greek_two_tone::after {
  content: '\EB55';
  opacity: .2;
}

.xgl-language_hungarian::before {
  content: '\EB56';
}

.xgl-language_hungarian_alt::before {
  content: '\EB57';
}

.xgl-language_hungarian_two_tone::before {
  content: '\EB56';
}

.xgl-language_hungarian_two_tone::after {
  content: '\EB57';
  opacity: .2;
}

.xgl-language_italian::before {
  content: '\EB58';
}

.xgl-language_italian_alt::before {
  content: '\EB59';
}

.xgl-language_italian_two_tone::before {
  content: '\EB58';
}

.xgl-language_italian_two_tone::after {
  content: '\EB59';
  opacity: .2;
}

.xgl-language_norwegian::before {
  content: '\EB5A';
}

.xgl-language_norwegian_alt::before {
  content: '\EB5B';
}

.xgl-language_norwegian_two_tone::before {
  content: '\EB5A';
}

.xgl-language_norwegian_two_tone::after {
  content: '\EB5B';
  opacity: .2;
}

.xgl-language_polish::before {
  content: '\EB5C';
}

.xgl-language_polish_alt::before {
  content: '\EB5D';
}

.xgl-language_polish_two_tone::before {
  content: '\EB5C';
}

.xgl-language_polish_two_tone::after {
  content: '\EB5D';
  opacity: .2;
}

.xgl-language_portuguese::before {
  content: '\EB5E';
}

.xgl-language_portuguese_alt::before {
  content: '\EB5F';
}

.xgl-language_portuguese_two_tone::before {
  content: '\EB5E';
}

.xgl-language_portuguese_two_tone::after {
  content: '\EB5F';
  opacity: .2;
}

.xgl-language_roman::before {
  content: '\EB60';
}

.xgl-language_roman_alt::before {
  content: '\EB61';
}

.xgl-language_roman_two_tone::before {
  content: '\EB60';
}

.xgl-language_roman_two_tone::after {
  content: '\EB61';
  opacity: .2;
}

.xgl-language_russian::before {
  content: '\EB62';
}

.xgl-language_russian_alt::before {
  content: '\EB63';
}

.xgl-language_russian_two_tone::before {
  content: '\EB62';
}

.xgl-language_russian_two_tone::after {
  content: '\EB63';
  opacity: .2;
}

.xgl-language_spanish::before {
  content: '\EBA2';
}

.xgl-language_spanish_alt::before {
  content: '\EBA3';
}

.xgl-language_spanish_two_tone::before {
  content: '\EBA2';
}

.xgl-language_spanish_two_tone::after {
  content: '\EBA3';
  opacity: .2;
}

.xgl-language_swedish::before {
  content: '\EB64';
}

.xgl-language_swedish_alt::before {
  content: '\EB65';
}

.xgl-language_swedish_two_tone::before {
  content: '\EB64';
}

.xgl-language_swedish_two_tone::after {
  content: '\EB65';
  opacity: .2;
}

.xgl-language_turkish::before {
  content: '\EB66';
}

.xgl-language_turkish_alt::before {
  content: '\EB67';
}

.xgl-language_turkish_two_tone::before {
  content: '\EB66';
}

.xgl-language_turkish_two_tone::after {
  content: '\EB67';
  opacity: .2;
}

.xgl-language_ukrainian::before {
  content: '\EB68';
}

.xgl-language_ukrainian_alt::before {
  content: '\EB69';
}

.xgl-language_ukrainian_two_tone::before {
  content: '\EB68';
}

.xgl-language_ukrainian_two_tone::after {
  content: '\EB69';
  opacity: .2;
}

.xgl-laptop::before {
  content: '\E2B7';
}

.xgl-laptop_add::before {
  content: '\E6A9';
}

.xgl-laptop_add_alt::before {
  content: '\E6AA';
}

.xgl-laptop_add_two_tone::before {
  content: '\E6A9';
}

.xgl-laptop_add_two_tone::after {
  content: '\E6AA';
  opacity: .2;
}

.xgl-laptop_alt::before {
  content: '\E2B8';
}

.xgl-laptop_two_tone::before {
  content: '\E2B7';
}

.xgl-laptop_two_tone::after {
  content: '\E2B8';
  opacity: .2;
}

.xgl-laser::before {
  content: '\EC6F';
}

.xgl-laser_alt::before {
  content: '\EC70';
}

.xgl-laser_two_tone::before {
  content: '\EC6F';
}

.xgl-laser_two_tone::after {
  content: '\EC70';
  opacity: .2;
}

.xgl-last::before {
  content: '\E2B9';
}

.xgl-layers::before {
  content: '\E2BA';
}

.xgl-layers_alt::before {
  content: '\E2BB';
}

.xgl-layers_two_tone::before {
  content: '\E2BA';
}

.xgl-layers_two_tone::after {
  content: '\E2BB';
  opacity: .2;
}

.xgl-layout::before {
  content: '\E8B9';
}

.xgl-layout_alt::before {
  content: '\E8BA';
}

.xgl-layout_disabled::before {
  content: '\EBC3';
}

.xgl-layout_disabled_alt::before {
  content: '\EBC4';
}

.xgl-layout_disabled_two_tone::before {
  content: '\EBC3';
}

.xgl-layout_disabled_two_tone::after {
  content: '\EBC4';
  opacity: .2;
}

.xgl-layout_image_text_l::before {
  content: '\E5CA';
}

.xgl-layout_image_text_p::before {
  content: '\E5C8';
}

.xgl-layout_order::before {
  content: '\EC3E';
}

.xgl-layout_order_alt::before {
  content: '\EC6B';
}

.xgl-layout_order_two_tone::before {
  content: '\EC3E';
}

.xgl-layout_order_two_tone::after {
  content: '\EC6B';
  opacity: .2;
}

.xgl-layout_text_image_l::before {
  content: '\E5C9';
}

.xgl-layout_text_image_p::before {
  content: '\E5C7';
}

.xgl-layout_two_tone::before {
  content: '\E8B9';
}

.xgl-layout_two_tone::after {
  content: '\E8BA';
  opacity: .2;
}

.xgl-learning::before {
  content: '\ECFD';
}

.xgl-learning_alt::before {
  content: '\ECFE';
}

.xgl-learning_off::before {
  content: '\ECFF';
}

.xgl-learning_off_alt::before {
  content: '\ED00';
}

.xgl-learning_off_two_tone::before {
  content: '\ECFF';
}

.xgl-learning_off_two_tone::after {
  content: '\ED00';
  opacity: .2;
}

.xgl-learning_two_tone::before {
  content: '\ECFD';
}

.xgl-learning_two_tone::after {
  content: '\ECFE';
  opacity: .2;
}

.xgl-lef_sef::before {
  content: '\E2BC';
}

.xgl-lef_sef_alt::before {
  content: '\E2BD';
}

.xgl-lef_sef_two_tone::before {
  content: '\E2BC';
}

.xgl-lef_sef_two_tone::after {
  content: '\E2BD';
  opacity: .2;
}

.xgl-letterhead_lef::before {
  content: '\E2BE';
}

.xgl-letterhead_lef_alt::before {
  content: '\E71F';
}

.xgl-letterhead_lef_two_tone::before {
  content: '\E2BE';
}

.xgl-letterhead_lef_two_tone::after {
  content: '\E71F';
  opacity: .2;
}

.xgl-letterhead_sef::before {
  content: '\E2BF';
}

.xgl-letterhead_sef_alt::before {
  content: '\E720';
}

.xgl-letterhead_sef_two_tone::before {
  content: '\E2BF';
}

.xgl-letterhead_sef_two_tone::after {
  content: '\E720';
  opacity: .2;
}

.xgl-library::before {
  content: '\E657';
}

.xgl-library_alt::before {
  content: '\E658';
}

.xgl-library_two_tone::before {
  content: '\E657';
}

.xgl-library_two_tone::after {
  content: '\E658';
  opacity: .2;
}

.xgl-lightbulb::before {
  content: '\E2C0';
}

.xgl-lightbulb_alt::before {
  content: '\E2C1';
}

.xgl-lightbulb_two_tone::before {
  content: '\E2C0';
}

.xgl-lightbulb_two_tone::after {
  content: '\E2C1';
  opacity: .2;
}

.xgl-lighten_darken::before {
  content: '\E64F';
}

.xgl-lighten_darken_two_tone::before {
  content: '\E64F';
}

.xgl-lighten_darken_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-lightweight_gloss_lef::before {
  content: '\E2C2';
}

.xgl-lightweight_gloss_lef_alt::before {
  content: '\E721';
}

.xgl-lightweight_gloss_lef_two_tone::before {
  content: '\E2C2';
}

.xgl-lightweight_gloss_lef_two_tone::after {
  content: '\E721';
  opacity: .2;
}

.xgl-lightweight_gloss_sef::before {
  content: '\E2C3';
}

.xgl-lightweight_gloss_sef_alt::before {
  content: '\E722';
}

.xgl-lightweight_gloss_sef_two_tone::before {
  content: '\E2C3';
}

.xgl-lightweight_gloss_sef_two_tone::after {
  content: '\E722';
  opacity: .2;
}

.xgl-lightweight_lef::before {
  content: '\E2C4';
}

.xgl-lightweight_lef_alt::before {
  content: '\E723';
}

.xgl-lightweight_lef_two_tone::before {
  content: '\E2C4';
}

.xgl-lightweight_lef_two_tone::after {
  content: '\E723';
  opacity: .2;
}

.xgl-lightweight_preprinted_lef::before {
  content: '\E2C5';
}

.xgl-lightweight_preprinted_lef_alt::before {
  content: '\E724';
}

.xgl-lightweight_preprinted_lef_two_tone::before {
  content: '\E2C5';
}

.xgl-lightweight_preprinted_lef_two_tone::after {
  content: '\E724';
  opacity: .2;
}

.xgl-lightweight_preprinted_sef::before {
  content: '\E2C6';
}

.xgl-lightweight_preprinted_sef_alt::before {
  content: '\E725';
}

.xgl-lightweight_preprinted_sef_two_tone::before {
  content: '\E2C6';
}

.xgl-lightweight_preprinted_sef_two_tone::after {
  content: '\E725';
  opacity: .2;
}

.xgl-lightweight_reloaded_lef::before {
  content: '\E2C7';
}

.xgl-lightweight_reloaded_lef_alt::before {
  content: '\E726';
}

.xgl-lightweight_reloaded_lef_two_tone::before {
  content: '\E2C7';
}

.xgl-lightweight_reloaded_lef_two_tone::after {
  content: '\E726';
  opacity: .2;
}

.xgl-lightweight_reloaded_sef::before {
  content: '\E2C8';
}

.xgl-lightweight_reloaded_sef_alt::before {
  content: '\E727';
}

.xgl-lightweight_reloaded_sef_two_tone::before {
  content: '\E2C8';
}

.xgl-lightweight_reloaded_sef_two_tone::after {
  content: '\E727';
  opacity: .2;
}

.xgl-lightweight_sef::before {
  content: '\E2C9';
}

.xgl-lightweight_sef_alt::before {
  content: '\E728';
}

.xgl-lightweight_sef_two_tone::before {
  content: '\E2C9';
}

.xgl-lightweight_sef_two_tone::after {
  content: '\E728';
  opacity: .2;
}

.xgl-line_chart::before {
  content: '\E2CA';
}

.xgl-line_weight::before {
  content: '\E840';
}

.xgl-link::before {
  content: '\E2CB';
}

.xgl-link_disabled::before {
  content: '\E2CC';
}

.xgl-linked_in::before {
  content: '\E2CD';
}

.xgl-list::before {
  content: '\E2CE';
}

.xgl-list_filter::before {
  content: '\E2CF';
}

.xgl-list_off::before {
  content: '\EDCE';
}

.xgl-list_rtl::before {
  content: '\E59D';
}

.xgl-lock_outline::before {
  content: '\EE15';
}

.xgl-lock_outline_alt::before {
  content: '\EE16';
}

.xgl-lock_outline_two_tone::before {
  content: '\EE15';
}

.xgl-lock_outline_two_tone::after {
  content: '\EE16';
  opacity: .2;
}

.xgl-locked::before {
  content: '\E2D0';
}

.xgl-locked_alt::before {
  content: '\E2D1';
}

.xgl-locked_plus::before {
  content: '\EAE7';
}

.xgl-locked_plus_alt::before {
  content: '\EAE8';
}

.xgl-locked_plus_two_tone::before {
  content: '\EAE7';
}

.xgl-locked_plus_two_tone::after {
  content: '\EAE8';
  opacity: .2;
}

.xgl-locked_square::before {
  content: '\E2D2';
}

.xgl-locked_square_alt::before {
  content: '\E2D3';
}

.xgl-locked_square_disabled::before {
  content: '\E9A5';
}

.xgl-locked_square_disabled_alt::before {
  content: '\E9A6';
}

.xgl-locked_square_disabled_two_tone::before {
  content: '\E9A5';
}

.xgl-locked_square_disabled_two_tone::after {
  content: '\E9A6';
  opacity: .2;
}

.xgl-locked_square_two_tone::before {
  content: '\E2D2';
}

.xgl-locked_square_two_tone::after {
  content: '\E2D3';
  opacity: .2;
}

.xgl-locked_stack_bkgd::before {
  content: '\E9A7';
}

.xgl-locked_stack_frgd::before {
  content: '\E9A8';
}

.xgl-locked_stack_mdgd::before {
  content: '\E9AA';
}

.xgl-locked_two_tone::before {
  content: '\E2D0';
}

.xgl-locked_two_tone::after {
  content: '\E2D1';
  opacity: .2;
}

.xgl-log_in::before {
  content: '\E2D4';
}

.xgl-log_in_alt::before {
  content: '\EE2B';
}

.xgl-log_in_two_tone::before {
  content: '\E2D4';
}

.xgl-log_in_two_tone::after {
  content: '\EE2B';
  opacity: .2;
}

.xgl-logic::before {
  content: '\E2D5';
}

.xgl-logo::before {
  content: '\E2D6';
}

.xgl-logo_small::before {
  content: '\E2D7';
}

.xgl-long_edge_feed::before {
  content: '\E2D8';
}

.xgl-long_edge_feed_alt::before {
  content: '\E2D9';
}

.xgl-long_edge_feed_two_tone::before {
  content: '\E2D8';
}

.xgl-long_edge_feed_two_tone::after {
  content: '\E2D9';
  opacity: .2;
}

.xgl-long_touch::before {
  content: '\E919';
}

.xgl-long_touch_alt::before {
  content: '\E91A';
}

.xgl-long_touch_two_tone::before {
  content: '\E919';
}

.xgl-long_touch_two_tone::after {
  content: '\E91A';
  opacity: .2;
}

.xgl-magnet::before {
  content: '\EE21';
}

.xgl-magnet_alt::before {
  content: '\EE22';
}

.xgl-magnet_two_tone::before {
  content: '\EE21';
}

.xgl-magnet_two_tone::after {
  content: '\EE22';
  opacity: .2;
}

.xgl-mailbox::before {
  content: '\E2DA';
}

.xgl-mailbox_add::before {
  content: '\E2DB';
}

.xgl-mailbox_add_alt::before {
  content: '\E2DC';
}

.xgl-mailbox_add_two_tone::before {
  content: '\E2DB';
}

.xgl-mailbox_add_two_tone::after {
  content: '\E2DC';
  opacity: .2;
}

.xgl-mailbox_alt::before {
  content: '\E2DD';
}

.xgl-mailbox_local::before {
  content: '\EC17';
}

.xgl-mailbox_local_alt::before {
  content: '\EC18';
}

.xgl-mailbox_local_two_tone::before {
  content: '\EC17';
}

.xgl-mailbox_local_two_tone::after {
  content: '\EC18';
  opacity: .2;
}

.xgl-mailbox_password::before {
  content: '\EC19';
}

.xgl-mailbox_password_alt::before {
  content: '\EC1A';
}

.xgl-mailbox_password_two_tone::before {
  content: '\EC19';
}

.xgl-mailbox_password_two_tone::after {
  content: '\EC1A';
  opacity: .2;
}

.xgl-mailbox_private::before {
  content: '\E2DE';
}

.xgl-mailbox_private_alt::before {
  content: '\E2DF';
}

.xgl-mailbox_private_two_tone::before {
  content: '\E2DE';
}

.xgl-mailbox_private_two_tone::after {
  content: '\E2DF';
  opacity: .2;
}

.xgl-mailbox_remote::before {
  content: '\EC1B';
}

.xgl-mailbox_remote_alt::before {
  content: '\EC1C';
}

.xgl-mailbox_remote_two_tone::before {
  content: '\EC1B';
}

.xgl-mailbox_remote_two_tone::after {
  content: '\EC1C';
  opacity: .2;
}

.xgl-mailbox_two_tone::before {
  content: '\E2DA';
}

.xgl-mailbox_two_tone::after {
  content: '\E2DD';
  opacity: .2;
}

.xgl-mailboxes::before {
  content: '\E2E0';
}

.xgl-mailboxes_alt::before {
  content: '\E2E1';
}

.xgl-mailboxes_two_tone::before {
  content: '\E2E0';
}

.xgl-mailboxes_two_tone::after {
  content: '\E2E1';
  opacity: .2;
}

.xgl-map::before {
  content: '\E2E2';
}

.xgl-map_alt::before {
  content: '\E2E3';
}

.xgl-map_disabled::before {
  content: '\E87C';
}

.xgl-map_disabled_alt::before {
  content: '\E87D';
}

.xgl-map_disabled_two_tone::before {
  content: '\E87C';
}

.xgl-map_disabled_two_tone::after {
  content: '\E87D';
  opacity: .2;
}

.xgl-map_two_tone::before {
  content: '\E2E2';
}

.xgl-map_two_tone::after {
  content: '\E2E3';
  opacity: .2;
}

.xgl-marker::before {
  content: '\E8A2';
}

.xgl-marker_alt::before {
  content: '\E8A3';
}

.xgl-marker_two_tone::before {
  content: '\E8A2';
}

.xgl-marker_two_tone::after {
  content: '\E8A3';
  opacity: .2;
}

.xgl-markup::before {
  content: '\E9AB';
}

.xgl-markup_alt::before {
  content: '\E9AC';
}

.xgl-markup_two_tone::before {
  content: '\E9AB';
}

.xgl-markup_two_tone::after {
  content: '\E9AC';
  opacity: .2;
}

.xgl-mask::before {
  content: '\E805';
}

.xgl-mastercard::before {
  content: '\ECAB';
}

.xgl-mastercard_alt::before {
  content: '\ECAC';
}

.xgl-mastercard_two_tone::before {
  content: '\ECAB';
}

.xgl-mastercard_two_tone::after {
  content: '\ECAC';
  opacity: .2;
}

.xgl-material_ribbon::before {
  content: '\ED01';
}

.xgl-material_ribbon_alt::before {
  content: '\ED02';
}

.xgl-material_ribbon_two_tone::before {
  content: '\ED01';
}

.xgl-material_ribbon_two_tone::after {
  content: '\ED02';
  opacity: .2;
}

.xgl-matte_lef::before {
  content: '\E2E4';
}

.xgl-matte_lef_alt::before {
  content: '\E729';
}

.xgl-matte_lef_two_tone::before {
  content: '\E2E4';
}

.xgl-matte_lef_two_tone::after {
  content: '\E729';
  opacity: .2;
}

.xgl-matte_sef::before {
  content: '\E2E5';
}

.xgl-matte_sef_alt::before {
  content: '\E72A';
}

.xgl-matte_sef_two_tone::before {
  content: '\E2E5';
}

.xgl-matte_sef_two_tone::after {
  content: '\E72A';
  opacity: .2;
}

.xgl-mcafee::before {
  content: '\E87E';
}

.xgl-medal::before {
  content: '\E2E6';
}

.xgl-medal_alt::before {
  content: '\E2E7';
}

.xgl-medal_two_tone::before {
  content: '\E2E6';
}

.xgl-medal_two_tone::after {
  content: '\E2E7';
  opacity: .2;
}

.xgl-media_camera::before {
  content: '\E853';
}

.xgl-media_camera_alt::before {
  content: '\E854';
}

.xgl-media_camera_two_tone::before {
  content: '\E853';
}

.xgl-media_camera_two_tone::after {
  content: '\E854';
  opacity: .2;
}

.xgl-medical::before {
  content: '\E2E8';
}

.xgl-medical_alt::before {
  content: '\E2E9';
}

.xgl-medical_two_tone::before {
  content: '\E2E8';
}

.xgl-medical_two_tone::after {
  content: '\E2E9';
  opacity: .2;
}

.xgl-member::before {
  content: '\E7F0';
}

.xgl-member_alt::before {
  content: '\E7F1';
}

.xgl-member_two_tone::before {
  content: '\E7F0';
}

.xgl-member_two_tone::after {
  content: '\E7F1';
  opacity: .2;
}

.xgl-menu::before {
  content: '\E2EA';
}

.xgl-menu_side::before {
  content: '\E2EB';
}

.xgl-merge::before {
  content: '\E2EC';
}

.xgl-merge_verticies::before {
  content: '\ED03';
}

.xgl-merge_verticies_alt::before {
  content: '\ED04';
}

.xgl-merge_verticies_two_tone::before {
  content: '\ED03';
}

.xgl-merge_verticies_two_tone::after {
  content: '\ED04';
  opacity: .2;
}

.xgl-mesh::before {
  content: '\ED05';
}

.xgl-mesh_alt::before {
  content: '\ED06';
}

.xgl-mesh_check::before {
  content: '\ED07';
}

.xgl-mesh_check_alt::before {
  content: '\ED08';
}

.xgl-mesh_check_two_tone::before {
  content: '\ED07';
}

.xgl-mesh_check_two_tone::after {
  content: '\ED08';
  opacity: .2;
}

.xgl-mesh_repair::before {
  content: '\EE3B';
}

.xgl-mesh_repair_alt::before {
  content: '\EE3C';
}

.xgl-mesh_repair_two_tone::before {
  content: '\EE3B';
}

.xgl-mesh_repair_two_tone::after {
  content: '\EE3C';
  opacity: .2;
}

.xgl-mesh_two_tone::before {
  content: '\ED05';
}

.xgl-mesh_two_tone::after {
  content: '\ED06';
  opacity: .2;
}

.xgl-metallic_lef::before {
  content: '\E63D';
}

.xgl-metallic_lef_alt::before {
  content: '\E72B';
}

.xgl-metallic_lef_two_tone::before {
  content: '\E63D';
}

.xgl-metallic_lef_two_tone::after {
  content: '\E72B';
  opacity: .2;
}

.xgl-metallic_sef::before {
  content: '\E63C';
}

.xgl-metallic_sef_alt::before {
  content: '\E72C';
}

.xgl-metallic_sef_two_tone::before {
  content: '\E63C';
}

.xgl-metallic_sef_two_tone::after {
  content: '\E72C';
  opacity: .2;
}

.xgl-mfd::before {
  content: '\E2ED';
}

.xgl-mfd_add::before {
  content: '\ECAD';
}

.xgl-mfd_add_alt::before {
  content: '\ECAE';
}

.xgl-mfd_add_two_tone::before {
  content: '\ECAD';
}

.xgl-mfd_add_two_tone::after {
  content: '\ECAE';
  opacity: .2;
}

.xgl-mfd_alt::before {
  content: '\E2EE';
}

.xgl-mfd_default::before {
  content: '\E8B7';
}

.xgl-mfd_default_alt::before {
  content: '\E8BD';
}

.xgl-mfd_default_two_tone::before {
  content: '\E8B7';
}

.xgl-mfd_default_two_tone::after {
  content: '\E8BD';
  opacity: .2;
}

.xgl-mfd_direct::before {
  content: '\E8B5';
}

.xgl-mfd_direct_alt::before {
  content: '\E8BC';
}

.xgl-mfd_direct_two_tone::before {
  content: '\E8B5';
}

.xgl-mfd_direct_two_tone::after {
  content: '\E8BC';
  opacity: .2;
}

.xgl-mfd_favorite::before {
  content: '\E8B4';
}

.xgl-mfd_favorite_alt::before {
  content: '\E8BB';
}

.xgl-mfd_favorite_direct::before {
  content: '\E8B6';
}

.xgl-mfd_favorite_direct_alt::before {
  content: '\E8BE';
}

.xgl-mfd_favorite_direct_two_tone::before {
  content: '\E8B6';
}

.xgl-mfd_favorite_direct_two_tone::after {
  content: '\E8BE';
  opacity: .2;
}

.xgl-mfd_favorite_two_tone::before {
  content: '\E8B4';
}

.xgl-mfd_favorite_two_tone::after {
  content: '\E8BB';
  opacity: .2;
}

.xgl-mfd_fill::before {
  content: '\E635';
}

.xgl-mfd_minus::before {
  content: '\ECAF';
}

.xgl-mfd_minus_alt::before {
  content: '\ECB0';
}

.xgl-mfd_minus_two_tone::before {
  content: '\ECAF';
}

.xgl-mfd_minus_two_tone::after {
  content: '\ECB0';
  opacity: .2;
}

.xgl-mfd_off::before {
  content: '\ECB1';
}

.xgl-mfd_off_alt::before {
  content: '\ECB2';
}

.xgl-mfd_off_two_tone::before {
  content: '\ECB1';
}

.xgl-mfd_off_two_tone::after {
  content: '\ECB2';
  opacity: .2;
}

.xgl-mfd_positioning::before {
  content: '\E8C3';
}

.xgl-mfd_positioning_alt::before {
  content: '\E8C4';
}

.xgl-mfd_positioning_two_tone::before {
  content: '\E8C3';
}

.xgl-mfd_positioning_two_tone::after {
  content: '\E8C4';
  opacity: .2;
}

.xgl-mfd_secure::before {
  content: '\E94B';
}

.xgl-mfd_secure_alt::before {
  content: '\E94C';
}

.xgl-mfd_secure_direct::before {
  content: '\E94D';
}

.xgl-mfd_secure_direct_alt::before {
  content: '\E94E';
}

.xgl-mfd_secure_direct_two_tone::before {
  content: '\E94D';
}

.xgl-mfd_secure_direct_two_tone::after {
  content: '\E94E';
  opacity: .2;
}

.xgl-mfd_secure_two_tone::before {
  content: '\E94B';
}

.xgl-mfd_secure_two_tone::after {
  content: '\E94C';
  opacity: .2;
}

.xgl-mfd_two_tone::before {
  content: '\E2ED';
}

.xgl-mfd_two_tone::after {
  content: '\E2EE';
  opacity: .2;
}

.xgl-microphone::before {
  content: '\E2EF';
}

.xgl-microphone_alt::before {
  content: '\E2F0';
}

.xgl-microphone_disabled::before {
  content: '\E2F1';
}

.xgl-microphone_disabled_alt::before {
  content: '\E2F2';
}

.xgl-microphone_disabled_two_tone::before {
  content: '\E2F1';
}

.xgl-microphone_disabled_two_tone::after {
  content: '\E2F2';
  opacity: .2;
}

.xgl-microphone_two_tone::before {
  content: '\E2EF';
}

.xgl-microphone_two_tone::after {
  content: '\E2F0';
  opacity: .2;
}

.xgl-minimize::before {
  content: '\E2F3';
}

.xgl-minimize_alt::before {
  content: '\E2F4';
}

.xgl-minimize_two_tone::before {
  content: '\E2F3';
}

.xgl-minimize_two_tone::after {
  content: '\E2F4';
  opacity: .2;
}

.xgl-minus::before {
  content: '\E2F5';
}

.xgl-mirror_image::before {
  content: '\E841';
}

.xgl-mirror_image_alt::before {
  content: '\EDD0';
}

.xgl-mirror_image_two_tone::before {
  content: '\E841';
}

.xgl-mirror_image_two_tone::after {
  content: '\EDD0';
  opacity: .2;
}

.xgl-mirror_image_vertical::before {
  content: '\EDD1';
}

.xgl-mirror_image_vertical_alt::before {
  content: '\EDD2';
}

.xgl-mirror_image_vertical_two_tone::before {
  content: '\EDD1';
}

.xgl-mirror_image_vertical_two_tone::after {
  content: '\EDD2';
  opacity: .2;
}

.xgl-mixed_size::before {
  content: '\EC1F';
}

.xgl-mixed_size_alt::before {
  content: '\EC20';
}

.xgl-mixed_size_two_tone::before {
  content: '\EC1F';
}

.xgl-mixed_size_two_tone::after {
  content: '\EC20';
  opacity: .2;
}

.xgl-mobile::before {
  content: '\E2F6';
}

.xgl-mobile_alt::before {
  content: '\E2F7';
}

.xgl-mobile_displays::before {
  content: '\E2F8';
}

.xgl-mobile_displays_alt::before {
  content: '\E2F9';
}

.xgl-mobile_displays_two_tone::before {
  content: '\E2F8';
}

.xgl-mobile_displays_two_tone::after {
  content: '\E2F9';
  opacity: .2;
}

.xgl-mobile_down::before {
  content: '\E2FA';
}

.xgl-mobile_down_alt::before {
  content: '\E2FB';
}

.xgl-mobile_down_two_tone::before {
  content: '\E2FA';
}

.xgl-mobile_down_two_tone::after {
  content: '\E2FB';
  opacity: .2;
}

.xgl-mobile_in::before {
  content: '\E2FC';
}

.xgl-mobile_in_alt::before {
  content: '\E2FD';
}

.xgl-mobile_in_two_tone::before {
  content: '\E2FC';
}

.xgl-mobile_in_two_tone::after {
  content: '\E2FD';
  opacity: .2;
}

.xgl-mobile_two_tone::before {
  content: '\E2F6';
}

.xgl-mobile_two_tone::after {
  content: '\E2F7';
  opacity: .2;
}

.xgl-model_shadow::before {
  content: '\ED09';
}

.xgl-model_shadow_alt::before {
  content: '\ED0A';
}

.xgl-model_shadow_off::before {
  content: '\ED39';
}

.xgl-model_shadow_off_alt::before {
  content: '\ED3A';
}

.xgl-model_shadow_off_two_tone::before {
  content: '\ED39';
}

.xgl-model_shadow_off_two_tone::after {
  content: '\ED3A';
  opacity: .2;
}

.xgl-model_shadow_two_tone::before {
  content: '\ED09';
}

.xgl-model_shadow_two_tone::after {
  content: '\ED0A';
  opacity: .2;
}

.xgl-money::before {
  content: '\E2FE';
}

.xgl-money_alt::before {
  content: '\E2FF';
}

.xgl-money_two_tone::before {
  content: '\E2FE';
}

.xgl-money_two_tone::after {
  content: '\E2FF';
  opacity: .2;
}

.xgl-mountain::before {
  content: '\E300';
}

.xgl-mountain_alt::before {
  content: '\E301';
}

.xgl-mountain_two_tone::before {
  content: '\E300';
}

.xgl-mountain_two_tone::after {
  content: '\E301';
  opacity: .2;
}

.xgl-mouse::before {
  content: '\E794';
}

.xgl-mouse_alt::before {
  content: '\E795';
}

.xgl-mouse_none::before {
  content: '\E7A6';
}

.xgl-mouse_none_alt::before {
  content: '\E7A7';
}

.xgl-mouse_none_two_tone::before {
  content: '\E7A6';
}

.xgl-mouse_none_two_tone::after {
  content: '\E7A7';
  opacity: .2;
}

.xgl-mouse_two_tone::before {
  content: '\E794';
}

.xgl-mouse_two_tone::after {
  content: '\E795';
  opacity: .2;
}

.xgl-move::before {
  content: '\E302';
}

.xgl-move_reorder::before {
  content: '\ED3B';
}

.xgl-move_square::before {
  content: '\ED3C';
}

.xgl-move_square_alt::before {
  content: '\ED3D';
}

.xgl-move_square_two_tone::before {
  content: '\ED3C';
}

.xgl-move_square_two_tone::after {
  content: '\ED3D';
  opacity: .2;
}

.xgl-ms_edge::before {
  content: '\E303';
}

.xgl-ms_excel::before {
  content: '\EB89';
}

.xgl-ms_excel_alt::before {
  content: '\EB8A';
}

.xgl-ms_excel_two_tone::before {
  content: '\EB89';
}

.xgl-ms_excel_two_tone::after {
  content: '\EB8A';
  opacity: .2;
}

.xgl-ms_logo::before {
  content: '\EB82';
}

.xgl-ms_logo_alt::before {
  content: '\EB83';
}

.xgl-ms_logo_two_tone::before {
  content: '\EB82';
}

.xgl-ms_logo_two_tone::after {
  content: '\EB83';
  opacity: .2;
}

.xgl-ms_office::before {
  content: '\E304';
}

.xgl-ms_office_alt::before {
  content: '\EB88';
}

.xgl-ms_office_two_tone::before {
  content: '\E304';
}

.xgl-ms_office_two_tone::after {
  content: '\EB88';
  opacity: .2;
}

.xgl-ms_onenote::before {
  content: '\EB8D';
}

.xgl-ms_onenote_alt::before {
  content: '\EB8E';
}

.xgl-ms_onenote_two_tone::before {
  content: '\EB8D';
}

.xgl-ms_onenote_two_tone::after {
  content: '\EB8E';
  opacity: .2;
}

.xgl-ms_outlook::before {
  content: '\EB8F';
}

.xgl-ms_outlook_alt::before {
  content: '\EB90';
}

.xgl-ms_outlook_two_tone::before {
  content: '\EB8F';
}

.xgl-ms_outlook_two_tone::after {
  content: '\EB90';
  opacity: .2;
}

.xgl-ms_powerpoint::before {
  content: '\EB91';
}

.xgl-ms_powerpoint_alt::before {
  content: '\EB92';
}

.xgl-ms_powerpoint_two_tone::before {
  content: '\EB91';
}

.xgl-ms_powerpoint_two_tone::after {
  content: '\EB92';
  opacity: .2;
}

.xgl-ms_teams::before {
  content: '\EB93';
}

.xgl-ms_teams_alt::before {
  content: '\EB94';
}

.xgl-ms_teams_two_tone::before {
  content: '\EB93';
}

.xgl-ms_teams_two_tone::after {
  content: '\EB94';
  opacity: .2;
}

.xgl-ms_word::before {
  content: '\EB8B';
}

.xgl-ms_word_alt::before {
  content: '\EB8C';
}

.xgl-ms_word_two_tone::before {
  content: '\EB8B';
}

.xgl-ms_word_two_tone::after {
  content: '\EB8C';
  opacity: .2;
}

.xgl-multi_holes_bottom_l::before {
  content: '\E8FC';
}

.xgl-multi_holes_bottom_l_alt::before {
  content: '\E8FD';
}

.xgl-multi_holes_bottom_l_two_tone::before {
  content: '\E8FC';
}

.xgl-multi_holes_bottom_l_two_tone::after {
  content: '\E8FD';
  opacity: .2;
}

.xgl-multi_holes_bottom_p::before {
  content: '\E911';
}

.xgl-multi_holes_bottom_p_alt::before {
  content: '\E901';
}

.xgl-multi_holes_bottom_p_two_tone::before {
  content: '\E911';
}

.xgl-multi_holes_bottom_p_two_tone::after {
  content: '\E901';
  opacity: .2;
}

.xgl-multi_holes_left_l::before {
  content: '\E903';
}

.xgl-multi_holes_left_l_alt::before {
  content: '\E906';
}

.xgl-multi_holes_left_l_two_tone::before {
  content: '\E903';
}

.xgl-multi_holes_left_l_two_tone::after {
  content: '\E906';
  opacity: .2;
}

.xgl-multi_holes_left_p::before {
  content: '\E908';
}

.xgl-multi_holes_left_p_alt::before {
  content: '\E909';
}

.xgl-multi_holes_left_p_two_tone::before {
  content: '\E908';
}

.xgl-multi_holes_left_p_two_tone::after {
  content: '\E909';
  opacity: .2;
}

.xgl-multi_holes_right_l::before {
  content: '\E904';
}

.xgl-multi_holes_right_l_alt::before {
  content: '\E905';
}

.xgl-multi_holes_right_l_two_tone::before {
  content: '\E904';
}

.xgl-multi_holes_right_l_two_tone::after {
  content: '\E905';
  opacity: .2;
}

.xgl-multi_holes_right_p::before {
  content: '\E907';
}

.xgl-multi_holes_right_p_alt::before {
  content: '\E90A';
}

.xgl-multi_holes_right_p_two_tone::before {
  content: '\E907';
}

.xgl-multi_holes_right_p_two_tone::after {
  content: '\E90A';
  opacity: .2;
}

.xgl-multi_holes_top_l::before {
  content: '\E8FB';
}

.xgl-multi_holes_top_l_alt::before {
  content: '\E8FE';
}

.xgl-multi_holes_top_l_two_tone::before {
  content: '\E8FB';
}

.xgl-multi_holes_top_l_two_tone::after {
  content: '\E8FE';
  opacity: .2;
}

.xgl-multi_holes_top_p::before {
  content: '\E8FF';
}

.xgl-multi_holes_top_p_alt::before {
  content: '\E902';
}

.xgl-multi_holes_top_p_two_tone::before {
  content: '\E8FF';
}

.xgl-multi_holes_top_p_two_tone::after {
  content: '\E902';
  opacity: .2;
}

.xgl-multiple::before {
  content: '\EDD3';
}

.xgl-multiple_alt::before {
  content: '\EDD4';
}

.xgl-multiple_two_tone::before {
  content: '\EDD3';
}

.xgl-multiple_two_tone::after {
  content: '\EDD4';
  opacity: .2;
}

.xgl-music::before {
  content: '\E305';
}

.xgl-music_alt::before {
  content: '\E306';
}

.xgl-music_two_tone::before {
  content: '\E305';
}

.xgl-music_two_tone::after {
  content: '\E306';
  opacity: .2;
}

.xgl-navigate_bottom::before {
  content: '\E6B6';
}

.xgl-navigate_down::before {
  content: '\E307';
}

.xgl-navigate_first::before {
  content: '\E308';
}

.xgl-navigate_last::before {
  content: '\E309';
}

.xgl-navigate_left::before {
  content: '\E30A';
}

.xgl-navigate_right::before {
  content: '\E30B';
}

.xgl-navigate_top::before {
  content: '\E6B7';
}

.xgl-navigate_up::before {
  content: '\E30C';
}

.xgl-navigation::before {
  content: '\E30D';
}

.xgl-navigation_alt::before {
  content: '\E30E';
}

.xgl-navigation_two_tone::before {
  content: '\E30D';
}

.xgl-navigation_two_tone::after {
  content: '\E30E';
  opacity: .2;
}

.xgl-network::before {
  content: '\E30F';
}

.xgl-network_alt::before {
  content: '\E310';
}

.xgl-network_close::before {
  content: '\E931';
}

.xgl-network_close_alt::before {
  content: '\E932';
}

.xgl-network_close_two_tone::before {
  content: '\E931';
}

.xgl-network_close_two_tone::after {
  content: '\E932';
  opacity: .2;
}

.xgl-network_disabled::before {
  content: '\E930';
}

.xgl-network_disabled_alt::before {
  content: '\E92F';
}

.xgl-network_disabled_two_tone::before {
  content: '\E930';
}

.xgl-network_disabled_two_tone::after {
  content: '\E92F';
  opacity: .2;
}

.xgl-network_grid::before {
  content: '\E79D';
}

.xgl-network_grid_alt::before {
  content: '\E79E';
}

.xgl-network_grid_two_tone::before {
  content: '\E79D';
}

.xgl-network_grid_two_tone::after {
  content: '\E79E';
  opacity: .2;
}

.xgl-network_square::before {
  content: '\E311';
}

.xgl-network_square_alt::before {
  content: '\E312';
}

.xgl-network_square_two_tone::before {
  content: '\E311';
}

.xgl-network_square_two_tone::after {
  content: '\E312';
  opacity: .2;
}

.xgl-network_two_tone::before {
  content: '\E30F';
}

.xgl-network_two_tone::after {
  content: '\E310';
  opacity: .2;
}

.xgl-network_vpn::before {
  content: '\EB6C';
}

.xgl-network_vpn_alt::before {
  content: '\EB6D';
}

.xgl-network_vpn_two_tone::before {
  content: '\EB6C';
}

.xgl-network_vpn_two_tone::after {
  content: '\EB6D';
  opacity: .2;
}

.xgl-newspaper::before {
  content: '\E313';
}

.xgl-newspaper_alt::before {
  content: '\E314';
}

.xgl-newspaper_two_tone::before {
  content: '\E313';
}

.xgl-newspaper_two_tone::after {
  content: '\E314';
  opacity: .2;
}

.xgl-next::before {
  content: '\E315';
}

.xgl-next_alt::before {
  content: '\E316';
}

.xgl-next_square::before {
  content: '\E317';
}

.xgl-next_square_alt::before {
  content: '\E318';
}

.xgl-next_square_two_tone::before {
  content: '\E317';
}

.xgl-next_square_two_tone::after {
  content: '\E318';
  opacity: .2;
}

.xgl-next_two_tone::before {
  content: '\E315';
}

.xgl-next_two_tone::after {
  content: '\E316';
  opacity: .2;
}

.xgl-nfc::before {
  content: '\E319';
}

.xgl-nfc_tag::before {
  content: '\E31A';
}

.xgl-nfc_two_tone::before {
  content: '\E319';
}

.xgl-nfc_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-nine_circle::before {
  content: '\EE64';
}

.xgl-nine_circle_alt::before {
  content: '\EE65';
}

.xgl-nine_circle_two_tone::before {
  content: '\EE64';
}

.xgl-nine_circle_two_tone::after {
  content: '\EE65';
  opacity: .2;
}

.xgl-nine_x::before {
  content: '\E7A3';
}

.xgl-no_entry::before {
  content: '\E31B';
}

.xgl-no_entry_alt::before {
  content: '\E31C';
}

.xgl-no_entry_two_tone::before {
  content: '\E31B';
}

.xgl-no_entry_two_tone::after {
  content: '\E31C';
  opacity: .2;
}

.xgl-non_secure::before {
  content: '\E31D';
}

.xgl-non_secure_alt::before {
  content: '\E31E';
}

.xgl-non_secure_two_tone::before {
  content: '\E31D';
}

.xgl-non_secure_two_tone::after {
  content: '\E31E';
  opacity: .2;
}

.xgl-north::before {
  content: '\E31F';
}

.xgl-note_converter::before {
  content: '\E9CF';
}

.xgl-notebook::before {
  content: '\E320';
}

.xgl-notebook_alt::before {
  content: '\E321';
}

.xgl-notebook_two_tone::before {
  content: '\E320';
}

.xgl-notebook_two_tone::after {
  content: '\E321';
  opacity: .2;
}

.xgl-notification::before {
  content: '\E322';
}

.xgl-notification_alt::before {
  content: '\E323';
}

.xgl-notification_overlay::before {
  content: '\E8A4';
}

.xgl-notification_two_tone::before {
  content: '\E322';
}

.xgl-notification_two_tone::after {
  content: '\E323';
  opacity: .2;
}

.xgl-numbering::before {
  content: '\E324';
}

.xgl-numbering_rtl::before {
  content: '\E59C';
}

.xgl-nuvera_print::before {
  content: '\EDD5';
}

.xgl-nuvera_print_alt::before {
  content: '\EDD6';
}

.xgl-nuvera_print_two_tone::before {
  content: '\EDD5';
}

.xgl-nuvera_print_two_tone::after {
  content: '\EDD6';
  opacity: .2;
}

.xgl-octogon_bkgd::before {
  content: '\E325';
}

.xgl-off::before {
  content: '\ECC3';
}

.xgl-off_alt::before {
  content: '\ECC4';
}

.xgl-off_stack_bkgd::before {
  content: '\ECC7';
}

.xgl-off_stack_frgd::before {
  content: '\ECC5';
}

.xgl-off_stack_mdgd::before {
  content: '\ECC6';
}

.xgl-off_two_tone::before {
  content: '\ECC3';
}

.xgl-off_two_tone::after {
  content: '\ECC4';
  opacity: .2;
}

.xgl-office_phone::before {
  content: '\E891';
}

.xgl-office_phone_alt::before {
  content: '\E892';
}

.xgl-office_phone_two_tone::before {
  content: '\E891';
}

.xgl-office_phone_two_tone::after {
  content: '\E892';
  opacity: .2;
}

.xgl-offline::before {
  content: '\E92E';
}

.xgl-offline_alt::before {
  content: '\E92D';
}

.xgl-offline_two_tone::before {
  content: '\E92E';
}

.xgl-offline_two_tone::after {
  content: '\E92D';
  opacity: .2;
}

.xgl-offset::before {
  content: '\E842';
}

.xgl-offset_printed::before {
  content: '\E326';
}

.xgl-offset_printed_alt::before {
  content: '\E327';
}

.xgl-offset_printed_two_tone::before {
  content: '\E326';
}

.xgl-offset_printed_two_tone::after {
  content: '\E327';
  opacity: .2;
}

.xgl-ok::before {
  content: '\E778';
}

.xgl-one_circle::before {
  content: '\EE54';
}

.xgl-one_circle_alt::before {
  content: '\EE55';
}

.xgl-one_circle_two_tone::before {
  content: '\EE54';
}

.xgl-one_circle_two_tone::after {
  content: '\EE54';
  opacity: .2;
}

.xgl-one_file_per_page::before {
  content: '\EB22';
}

.xgl-one_file_per_page_alt::before {
  content: '\EB23';
}

.xgl-one_file_per_page_two_tone::before {
  content: '\EB22';
}

.xgl-one_file_per_page_two_tone::after {
  content: '\EB23';
  opacity: .2;
}

.xgl-one_free_staple::before {
  content: '\EB26';
}

.xgl-one_free_staple_alt::before {
  content: '\EB27';
}

.xgl-one_free_staple_l::before {
  content: '\EB28';
}

.xgl-one_free_staple_l_alt::before {
  content: '\EB29';
}

.xgl-one_free_staple_l_two_tone::before {
  content: '\EB28';
}

.xgl-one_free_staple_l_two_tone::after {
  content: '\EB29';
  opacity: .2;
}

.xgl-one_free_staple_p::before {
  content: '\EB2A';
}

.xgl-one_free_staple_p_alt::before {
  content: '\EB2B';
}

.xgl-one_free_staple_p_two_tone::before {
  content: '\EB2A';
}

.xgl-one_free_staple_p_two_tone::after {
  content: '\EB2B';
  opacity: .2;
}

.xgl-one_free_staple_two_tone::before {
  content: '\EB26';
}

.xgl-one_free_staple_two_tone::after {
  content: '\EB27';
  opacity: .2;
}

.xgl-one_round_tile::before {
  content: '\E9D2';
}

.xgl-one_round_tile_alt::before {
  content: '\E9D1';
}

.xgl-one_round_tile_two_tone::before {
  content: '\E9D2';
}

.xgl-one_round_tile_two_tone::after {
  content: '\E9D1';
  opacity: .2;
}

.xgl-one_staple::before {
  content: '\EB24';
}

.xgl-one_staple_alt::before {
  content: '\EB25';
}

.xgl-one_staple_bottom_l::before {
  content: '\ED48';
}

.xgl-one_staple_bottom_l_alt::before {
  content: '\ED49';
}

.xgl-one_staple_bottom_l_two_tone::before {
  content: '\ED48';
}

.xgl-one_staple_bottom_l_two_tone::after {
  content: '\ED49';
  opacity: .2;
}

.xgl-one_staple_bottom_left_l::before {
  content: '\ED4A';
}

.xgl-one_staple_bottom_left_l_alt::before {
  content: '\ED4B';
}

.xgl-one_staple_bottom_left_l_two_tone::before {
  content: '\ED4A';
}

.xgl-one_staple_bottom_left_l_two_tone::after {
  content: '\ED4B';
  opacity: .2;
}

.xgl-one_staple_bottom_left_p::before {
  content: '\ED4C';
}

.xgl-one_staple_bottom_left_p_alt::before {
  content: '\ED4D';
}

.xgl-one_staple_bottom_left_p_two_tone::before {
  content: '\ED4C';
}

.xgl-one_staple_bottom_left_p_two_tone::after {
  content: '\ED4D';
  opacity: .2;
}

.xgl-one_staple_bottom_p::before {
  content: '\ED4E';
}

.xgl-one_staple_bottom_p_alt::before {
  content: '\ED4F';
}

.xgl-one_staple_bottom_p_two_tone::before {
  content: '\ED4E';
}

.xgl-one_staple_bottom_p_two_tone::after {
  content: '\ED4F';
  opacity: .2;
}

.xgl-one_staple_bottom_right_l::before {
  content: '\ED50';
}

.xgl-one_staple_bottom_right_l_alt::before {
  content: '\ED51';
}

.xgl-one_staple_bottom_right_l_two_tone::before {
  content: '\ED50';
}

.xgl-one_staple_bottom_right_l_two_tone::after {
  content: '\ED51';
  opacity: .2;
}

.xgl-one_staple_bottom_right_p::before {
  content: '\ED52';
}

.xgl-one_staple_bottom_right_p_alt::before {
  content: '\ED53';
}

.xgl-one_staple_bottom_right_p_two_tone::before {
  content: '\ED52';
}

.xgl-one_staple_bottom_right_p_two_tone::after {
  content: '\ED53';
  opacity: .2;
}

.xgl-one_staple_center_left_l::before {
  content: '\ED54';
}

.xgl-one_staple_center_left_l_alt::before {
  content: '\ED55';
}

.xgl-one_staple_center_left_l_two_tone::before {
  content: '\ED54';
}

.xgl-one_staple_center_left_l_two_tone::after {
  content: '\ED55';
  opacity: .2;
}

.xgl-one_staple_center_left_p::before {
  content: '\ED56';
}

.xgl-one_staple_center_left_p_alt::before {
  content: '\ED57';
}

.xgl-one_staple_center_left_p_two_tone::before {
  content: '\ED56';
}

.xgl-one_staple_center_left_p_two_tone::after {
  content: '\ED57';
  opacity: .2;
}

.xgl-one_staple_center_right_l::before {
  content: '\ED58';
}

.xgl-one_staple_center_right_l_alt::before {
  content: '\ED59';
}

.xgl-one_staple_center_right_l_two_tone::before {
  content: '\ED58';
}

.xgl-one_staple_center_right_l_two_tone::after {
  content: '\ED59';
  opacity: .2;
}

.xgl-one_staple_center_right_p::before {
  content: '\ED5A';
}

.xgl-one_staple_center_right_p_alt::before {
  content: '\ED5B';
}

.xgl-one_staple_center_right_p_two_tone::before {
  content: '\ED5A';
}

.xgl-one_staple_center_right_p_two_tone::after {
  content: '\ED5B';
  opacity: .2;
}

.xgl-one_staple_l::before {
  content: '\E328';
}

.xgl-one_staple_l_alt::before {
  content: '\E72D';
}

.xgl-one_staple_l_two_tone::before {
  content: '\E328';
}

.xgl-one_staple_l_two_tone::after {
  content: '\E72D';
  opacity: .2;
}

.xgl-one_staple_left_l::before {
  content: '\E329';
}

.xgl-one_staple_left_l_alt::before {
  content: '\E72E';
}

.xgl-one_staple_left_l_two_tone::before {
  content: '\E329';
}

.xgl-one_staple_left_l_two_tone::after {
  content: '\E72E';
  opacity: .2;
}

.xgl-one_staple_left_p::before {
  content: '\E32A';
}

.xgl-one_staple_left_p_alt::before {
  content: '\E72F';
}

.xgl-one_staple_left_p_two_tone::before {
  content: '\E32A';
}

.xgl-one_staple_left_p_two_tone::after {
  content: '\E72F';
  opacity: .2;
}

.xgl-one_staple_p::before {
  content: '\E32B';
}

.xgl-one_staple_p_alt::before {
  content: '\E730';
}

.xgl-one_staple_p_two_tone::before {
  content: '\E32B';
}

.xgl-one_staple_p_two_tone::after {
  content: '\E730';
  opacity: .2;
}

.xgl-one_staple_right_l::before {
  content: '\E32C';
}

.xgl-one_staple_right_l_alt::before {
  content: '\E731';
}

.xgl-one_staple_right_l_two_tone::before {
  content: '\E32C';
}

.xgl-one_staple_right_l_two_tone::after {
  content: '\E731';
  opacity: .2;
}

.xgl-one_staple_right_p::before {
  content: '\E32D';
}

.xgl-one_staple_right_p_alt::before {
  content: '\E732';
}

.xgl-one_staple_right_p_two_tone::before {
  content: '\E32D';
}

.xgl-one_staple_right_p_two_tone::after {
  content: '\E732';
  opacity: .2;
}

.xgl-one_staple_two_tone::before {
  content: '\EB24';
}

.xgl-one_staple_two_tone::after {
  content: '\EB25';
  opacity: .2;
}

.xgl-one_touch::before {
  content: '\E32E';
}

.xgl-one_touch_alt::before {
  content: '\E32F';
}

.xgl-one_touch_circle::before {
  content: '\ECCE';
}

.xgl-one_touch_circle_alt::before {
  content: '\ECCF';
}

.xgl-one_touch_circle_two_tone::before {
  content: '\ECCE';
}

.xgl-one_touch_circle_two_tone::after {
  content: '\ECCF';
  opacity: .2;
}

.xgl-one_touch_two_tone::before {
  content: '\E32E';
}

.xgl-one_touch_two_tone::after {
  content: '\E32F';
  opacity: .2;
}

.xgl-onedrive::before {
  content: '\E330';
}

.xgl-onedrive_alt::before {
  content: '\E9D4';
}

.xgl-onedrive_two_tone::before {
  content: '\E330';
}

.xgl-onedrive_two_tone::after {
  content: '\E9D4';
  opacity: .2;
}

.xgl-online::before {
  content: '\E331';
}

.xgl-online_alt::before {
  content: '\E923';
}

.xgl-online_two_tone::before {
  content: '\E331';
}

.xgl-online_two_tone::after {
  content: '\E923';
  opacity: .2;
}

.xgl-opacity::before {
  content: '\E5B4';
}

.xgl-opacity_two_tone::before {
  content: '\E5B4';
}

.xgl-opacity_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-opera::before {
  content: '\E332';
}

.xgl-operator::before {
  content: '\E8CF';
}

.xgl-operator_alt::before {
  content: '\E8D0';
}

.xgl-operator_two_tone::before {
  content: '\E8CF';
}

.xgl-operator_two_tone::after {
  content: '\E8D0';
  opacity: .2;
}

.xgl-order_bottom_top::before {
  content: '\E90B';
}

.xgl-order_bottom_top_square::before {
  content: '\E90F';
}

.xgl-order_bottom_top_square_alt::before {
  content: '\E910';
}

.xgl-order_bottom_top_square_two_tone::before {
  content: '\E90F';
}

.xgl-order_bottom_top_square_two_tone::after {
  content: '\E910';
  opacity: .2;
}

.xgl-order_down_left::before {
  content: '\E333';
}

.xgl-order_down_left_square::before {
  content: '\E8DE';
}

.xgl-order_down_left_square_alt::before {
  content: '\E8E1';
}

.xgl-order_down_left_square_two_tone::before {
  content: '\E8DE';
}

.xgl-order_down_left_square_two_tone::after {
  content: '\E8E1';
  opacity: .2;
}

.xgl-order_down_right::before {
  content: '\E334';
}

.xgl-order_down_right_square::before {
  content: '\E8E6';
}

.xgl-order_down_right_square_alt::before {
  content: '\E8E5';
}

.xgl-order_down_right_square_two_tone::before {
  content: '\E8E6';
}

.xgl-order_down_right_square_two_tone::after {
  content: '\E8E5';
  opacity: .2;
}

.xgl-order_left_down::before {
  content: '\E335';
}

.xgl-order_left_down_square::before {
  content: '\E8DF';
}

.xgl-order_left_down_square_alt::before {
  content: '\E8E2';
}

.xgl-order_left_down_square_two_tone::before {
  content: '\E8DF';
}

.xgl-order_left_down_square_two_tone::after {
  content: '\E8E2';
  opacity: .2;
}

.xgl-order_left_right::before {
  content: '\E336';
}

.xgl-order_left_right_square::before {
  content: '\E8E3';
}

.xgl-order_left_right_square_alt::before {
  content: '\E8E4';
}

.xgl-order_left_right_square_two_tone::before {
  content: '\E8E3';
}

.xgl-order_left_right_square_two_tone::after {
  content: '\E8E4';
  opacity: .2;
}

.xgl-order_left_up::before {
  content: '\EA74';
}

.xgl-order_right_down::before {
  content: '\E337';
}

.xgl-order_right_down_square::before {
  content: '\E8DB';
}

.xgl-order_right_down_square_alt::before {
  content: '\E8E7';
}

.xgl-order_right_down_square_two_tone::before {
  content: '\E8DB';
}

.xgl-order_right_down_square_two_tone::after {
  content: '\E8E7';
  opacity: .2;
}

.xgl-order_right_left::before {
  content: '\E338';
}

.xgl-order_right_left_square::before {
  content: '\E8E0';
}

.xgl-order_right_left_square_alt::before {
  content: '\E8E8';
}

.xgl-order_right_left_square_two_tone::before {
  content: '\E8E0';
}

.xgl-order_right_left_square_two_tone::after {
  content: '\E8E8';
  opacity: .2;
}

.xgl-order_right_up::before {
  content: '\E8DA';
}

.xgl-order_right_up_square::before {
  content: '\E8DD';
}

.xgl-order_right_up_square_alt::before {
  content: '\E8E9';
}

.xgl-order_right_up_square_two_tone::before {
  content: '\E8DD';
}

.xgl-order_right_up_square_two_tone::after {
  content: '\E8E9';
  opacity: .2;
}

.xgl-order_top_bottom::before {
  content: '\E90C';
}

.xgl-order_top_bottom_square::before {
  content: '\E90D';
}

.xgl-order_top_bottom_square_alt::before {
  content: '\E90E';
}

.xgl-order_top_bottom_square_two_tone::before {
  content: '\E90D';
}

.xgl-order_top_bottom_square_two_tone::after {
  content: '\E90E';
  opacity: .2;
}

.xgl-order_up_left::before {
  content: '\EA75';
}

.xgl-order_up_right::before {
  content: '\E8D9';
}

.xgl-order_up_right_square::before {
  content: '\E8DC';
}

.xgl-order_up_right_square_alt::before {
  content: '\E8EA';
}

.xgl-order_up_right_square_two_tone::before {
  content: '\E8DC';
}

.xgl-order_up_right_square_two_tone::after {
  content: '\E8EA';
  opacity: .2;
}

.xgl-orient_to_print_bed::before {
  content: '\EE3D';
}

.xgl-orient_to_print_bed_alt::before {
  content: '\EE3E';
}

.xgl-orient_to_print_bed_two_tone::before {
  content: '\EE3D';
}

.xgl-orient_to_print_bed_two_tone::after {
  content: '\EE3E';
  opacity: .2;
}

.xgl-orientation_rotate_l::before {
  content: '\EC1D';
}

.xgl-orientation_rotate_l_alt::before {
  content: '\EC1E';
}

.xgl-orientation_rotate_l_two_tone::before {
  content: '\EC1D';
}

.xgl-orientation_rotate_l_two_tone::after {
  content: '\EC1E';
  opacity: .2;
}

.xgl-origin_triad::before {
  content: '\EE2C';
}

.xgl-orthographic::before {
  content: '\ED0B';
}

.xgl-other_type_lef::before {
  content: '\E339';
}

.xgl-other_type_lef_alt::before {
  content: '\E733';
}

.xgl-other_type_lef_rtl::before {
  content: '\E5A4';
}

.xgl-other_type_lef_rtl_alt::before {
  content: '\E734';
}

.xgl-other_type_lef_rtl_two_tone::before {
  content: '\E5A4';
}

.xgl-other_type_lef_rtl_two_tone::after {
  content: '\E734';
  opacity: .2;
}

.xgl-other_type_lef_two_tone::before {
  content: '\E339';
}

.xgl-other_type_lef_two_tone::after {
  content: '\E733';
  opacity: .2;
}

.xgl-other_type_sef::before {
  content: '\E33A';
}

.xgl-other_type_sef_alt::before {
  content: '\E735';
}

.xgl-other_type_sef_rtl::before {
  content: '\E5A5';
}

.xgl-other_type_sef_rtl_alt::before {
  content: '\E736';
}

.xgl-other_type_sef_rtl_two_tone::before {
  content: '\E5A5';
}

.xgl-other_type_sef_rtl_two_tone::after {
  content: '\E736';
  opacity: .2;
}

.xgl-other_type_sef_two_tone::before {
  content: '\E33A';
}

.xgl-other_type_sef_two_tone::after {
  content: '\E735';
  opacity: .2;
}

.xgl-ouput_paper_present::before {
  content: '\60936';
}

.xgl-out_of_date::before {
  content: '\EE3F';
}

.xgl-out_of_date_alt::before {
  content: '\EE40';
}

.xgl-out_of_date_two_tone::before {
  content: '\EE3F';
}

.xgl-out_of_date_two_tone::after {
  content: '\EE40';
  opacity: .2;
}

.xgl-outbox::before {
  content: '\E33B';
}

.xgl-outbox_alt::before {
  content: '\E33C';
}

.xgl-outbox_two_tone::before {
  content: '\E33B';
}

.xgl-outbox_two_tone::after {
  content: '\E33C';
  opacity: .2;
}

.xgl-output::before {
  content: '\E799';
}

.xgl-overlap::before {
  content: '\E921';
}

.xgl-overlap_alt::before {
  content: '\E922';
}

.xgl-overlap_two_tone::before {
  content: '\E921';
}

.xgl-overlap_two_tone::after {
  content: '\E922';
  opacity: .2;
}

.xgl-overwrite_square::before {
  content: '\EA77';
}

.xgl-overwrite_square_alt::before {
  content: '\EA76';
}

.xgl-overwrite_square_two_tone::before {
  content: '\EA77';
}

.xgl-overwrite_square_two_tone::after {
  content: '\EA76';
  opacity: .2;
}

.xgl-overwrite_start::before {
  content: '\EC41';
}

.xgl-overwrite_start_alt::before {
  content: '\EC42';
}

.xgl-overwrite_start_two_tone::before {
  content: '\EC41';
}

.xgl-overwrite_start_two_tone::after {
  content: '\EC42';
  opacity: .2;
}

.xgl-package::before {
  content: '\E33D';
}

.xgl-package_alt::before {
  content: '\E33E';
}

.xgl-package_two_tone::before {
  content: '\E33D';
}

.xgl-package_two_tone::after {
  content: '\E33E';
  opacity: .2;
}

.xgl-page_layout_eight_p::before {
  content: '\EBCB';
}

.xgl-page_layout_eight_p_alt::before {
  content: '\EBCC';
}

.xgl-page_layout_eight_p_two_tone::before {
  content: '\EBCB';
}

.xgl-page_layout_eight_p_two_tone::after {
  content: '\EBCC';
  opacity: .2;
}

.xgl-page_layout_four_p::before {
  content: '\EBC9';
}

.xgl-page_layout_four_p_alt::before {
  content: '\EBCA';
}

.xgl-page_layout_four_p_repeat::before {
  content: '\EC58';
}

.xgl-page_layout_four_p_repeat_alt::before {
  content: '\EC59';
}

.xgl-page_layout_four_p_repeat_two_tone::before {
  content: '\EC58';
}

.xgl-page_layout_four_p_repeat_two_tone::after {
  content: '\EC59';
  opacity: .2;
}

.xgl-page_layout_four_p_two_tone::before {
  content: '\EBC9';
}

.xgl-page_layout_four_p_two_tone::after {
  content: '\EBCA';
  opacity: .2;
}

.xgl-page_layout_nine_p::before {
  content: '\EBD1';
}

.xgl-page_layout_nine_p_alt::before {
  content: '\EBD2';
}

.xgl-page_layout_nine_p_repeat::before {
  content: '\EC5C';
}

.xgl-page_layout_nine_p_repeat_alt::before {
  content: '\EC5D';
}

.xgl-page_layout_nine_p_repeat_two_tone::before {
  content: '\EC5C';
}

.xgl-page_layout_nine_p_repeat_two_tone::after {
  content: '\EC5D';
  opacity: .2;
}

.xgl-page_layout_nine_p_two_tone::before {
  content: '\EBD1';
}

.xgl-page_layout_nine_p_two_tone::after {
  content: '\EBD2';
  opacity: .2;
}

.xgl-page_layout_six_p::before {
  content: '\EBCF';
}

.xgl-page_layout_six_p_alt::before {
  content: '\EBD0';
}

.xgl-page_layout_six_p_repeat::before {
  content: '\EC5A';
}

.xgl-page_layout_six_p_repeat_alt::before {
  content: '\EC5B';
}

.xgl-page_layout_six_p_repeat_two_tone::before {
  content: '\EC5A';
}

.xgl-page_layout_six_p_repeat_two_tone::after {
  content: '\EC5B';
  opacity: .2;
}

.xgl-page_layout_six_p_two_tone::before {
  content: '\EBCF';
}

.xgl-page_layout_six_p_two_tone::after {
  content: '\EBD0';
  opacity: .2;
}

.xgl-page_layout_specify_p::before {
  content: '\EBD3';
}

.xgl-page_layout_specify_p_alt::before {
  content: '\EBD4';
}

.xgl-page_layout_specify_p_repeat::before {
  content: '\EC5E';
}

.xgl-page_layout_specify_p_repeat_alt::before {
  content: '\EC5F';
}

.xgl-page_layout_specify_p_repeat_two_tone::before {
  content: '\EC5E';
}

.xgl-page_layout_specify_p_repeat_two_tone::after {
  content: '\EC5F';
  opacity: .2;
}

.xgl-page_layout_specify_p_two_tone::before {
  content: '\EBD3';
}

.xgl-page_layout_specify_p_two_tone::after {
  content: '\EBD4';
  opacity: .2;
}

.xgl-page_layout_two_l::before {
  content: '\EBCD';
}

.xgl-page_layout_two_l_alt::before {
  content: '\EBCE';
}

.xgl-page_layout_two_l_two_tone::before {
  content: '\EBCD';
}

.xgl-page_layout_two_l_two_tone::after {
  content: '\EBCE';
  opacity: .2;
}

.xgl-page_layout_two_p::before {
  content: '\EBC7';
}

.xgl-page_layout_two_p_alt::before {
  content: '\EBC8';
}

.xgl-page_layout_two_p_repeat::before {
  content: '\EC56';
}

.xgl-page_layout_two_p_repeat_alt::before {
  content: '\EC57';
}

.xgl-page_layout_two_p_repeat_two_tone::before {
  content: '\EC56';
}

.xgl-page_layout_two_p_repeat_two_tone::after {
  content: '\EC57';
  opacity: .2;
}

.xgl-page_layout_two_p_two_tone::before {
  content: '\EBC7';
}

.xgl-page_layout_two_p_two_tone::after {
  content: '\EBC8';
  opacity: .2;
}

.xgl-page_number::before {
  content: '\E33F';
}

.xgl-page_number_alt::before {
  content: '\E340';
}

.xgl-page_number_two_tone::before {
  content: '\E33F';
}

.xgl-page_number_two_tone::after {
  content: '\E340';
  opacity: .2;
}

.xgl-paint::before {
  content: '\E341';
}

.xgl-paint_alt::before {
  content: '\E342';
}

.xgl-paint_brush::before {
  content: '\E343';
}

.xgl-paint_brush_alt::before {
  content: '\E344';
}

.xgl-paint_brush_two_tone::before {
  content: '\E343';
}

.xgl-paint_brush_two_tone::after {
  content: '\E344';
  opacity: .2;
}

.xgl-paint_roller::before {
  content: '\E93D';
}

.xgl-paint_roller_alt::before {
  content: '\E93E';
}

.xgl-paint_roller_two_tone::before {
  content: '\E93D';
}

.xgl-paint_roller_two_tone::after {
  content: '\E93E';
  opacity: .2;
}

.xgl-paint_two_tone::before {
  content: '\E341';
}

.xgl-paint_two_tone::after {
  content: '\E342';
  opacity: .2;
}

.xgl-palette::before {
  content: '\E622';
}

.xgl-palette_alt::before {
  content: '\E7AB';
}

.xgl-palette_two_tone::before {
  content: '\E622';
}

.xgl-palette_two_tone::after {
  content: '\E7AB';
  opacity: .2;
}

.xgl-panda::before {
  content: '\EE51';
}

.xgl-panda_alt::before {
  content: '\EE52';
}

.xgl-panda_two_tone::before {
  content: '\EE51';
}

.xgl-panda_two_tone::after {
  content: '\EE52';
  opacity: .2;
}

.xgl-panel_down::before {
  content: '\E88D';
}

.xgl-panel_left::before {
  content: '\E88E';
}

.xgl-panel_right::before {
  content: '\E88F';
}

.xgl-panel_up::before {
  content: '\E890';
}

.xgl-paper_add::before {
  content: '\E779';
}

.xgl-paper_defect::before {
  content: '\ECBC';
}

.xgl-paper_defect_alt::before {
  content: '\ECBD';
}

.xgl-paper_defect_two_tone::before {
  content: '\ECBC';
}

.xgl-paper_defect_two_tone::after {
  content: '\ECBD';
  opacity: .2;
}

.xgl-paper_face_down_copy::before {
  content: '\E77A';
}

.xgl-paper_face_down_copy_alt::before {
  content: '\EC84';
}

.xgl-paper_face_down_copy_two_tone::before {
  content: '\E77A';
}

.xgl-paper_face_down_copy_two_tone::after {
  content: '\EC84';
  opacity: .2;
}

.xgl-paper_face_down_original::before {
  content: '\E77B';
}

.xgl-paper_face_down_original_alt::before {
  content: '\EC85';
}

.xgl-paper_face_down_original_two_tone::before {
  content: '\E77B';
}

.xgl-paper_face_down_original_two_tone::after {
  content: '\EC85';
  opacity: .2;
}

.xgl-paper_jam::before {
  content: '\E77C';
}

.xgl-paper_roll::before {
  content: '\E77D';
}

.xgl-paper_roll_alt::before {
  content: '\EC81';
}

.xgl-paper_roll_two_tone::before {
  content: '\E77D';
}

.xgl-paper_roll_two_tone::after {
  content: '\EC81';
  opacity: .2;
}

.xgl-paper_save::before {
  content: '\E77E';
}

.xgl-paper_sided_copy::before {
  content: '\EC8F';
}

.xgl-paper_sided_copy_alt::before {
  content: '\EC90';
}

.xgl-paper_sided_copy_two_tone::before {
  content: '\EC8F';
}

.xgl-paper_sided_copy_two_tone::after {
  content: '\EC90';
  opacity: .2;
}

.xgl-paper_sided_original::before {
  content: '\EC91';
}

.xgl-paper_sided_original_alt::before {
  content: '\EC92';
}

.xgl-paper_sided_original_two_tone::before {
  content: '\EC91';
}

.xgl-paper_sided_original_two_tone::after {
  content: '\EC92';
  opacity: .2;
}

.xgl-paper_size::before {
  content: '\E77F';
}

.xgl-paper_size_alt::before {
  content: '\EB6E';
}

.xgl-paper_size_two_tone::before {
  content: '\E77F';
}

.xgl-paper_size_two_tone::after {
  content: '\EB6E';
  opacity: .2;
}

.xgl-paper_two_sided_copy::before {
  content: '\E780';
}

.xgl-paper_two_sided_copy_alt::before {
  content: '\EC82';
}

.xgl-paper_two_sided_copy_two_tone::before {
  content: '\E780';
}

.xgl-paper_two_sided_copy_two_tone::after {
  content: '\EC82';
  opacity: .2;
}

.xgl-paper_two_sided_original::before {
  content: '\E781';
}

.xgl-paper_two_sided_original_alt::before {
  content: '\EC83';
}

.xgl-paper_two_sided_original_two_tone::before {
  content: '\E781';
}

.xgl-paper_two_sided_original_two_tone::after {
  content: '\EC83';
  opacity: .2;
}

.xgl-paperclip::before {
  content: '\E345';
}

.xgl-parachute::before {
  content: '\E93F';
}

.xgl-parachute_alt::before {
  content: '\E940';
}

.xgl-parachute_two_tone::before {
  content: '\E93F';
}

.xgl-parachute_two_tone::after {
  content: '\E940';
  opacity: .2;
}

.xgl-park::before {
  content: '\E66B';
}

.xgl-park_alt::before {
  content: '\E66C';
}

.xgl-park_two_tone::before {
  content: '\E66B';
}

.xgl-park_two_tone::after {
  content: '\E66C';
  opacity: .2;
}

.xgl-parked_stack_bkgd::before {
  content: '\E9AD';
}

.xgl-parked_stack_frgd::before {
  content: '\E9AE';
}

.xgl-parked_stack_mdgd::before {
  content: '\E9AF';
}

.xgl-password::before {
  content: '\E346';
}

.xgl-password_alt::before {
  content: '\E347';
}

.xgl-password_two_tone::before {
  content: '\E346';
}

.xgl-password_two_tone::after {
  content: '\E347';
  opacity: .2;
}

.xgl-pause::before {
  content: '\E348';
}

.xgl-pause_alt::before {
  content: '\E349';
}

.xgl-pause_circle::before {
  content: '\E34A';
}

.xgl-pause_circle_alt::before {
  content: '\E34B';
}

.xgl-pause_circle_two_tone::before {
  content: '\E34A';
}

.xgl-pause_circle_two_tone::after {
  content: '\E34B';
  opacity: .2;
}

.xgl-pause_secure::before {
  content: '\EC13';
}

.xgl-pause_secure_alt::before {
  content: '\EC14';
}

.xgl-pause_secure_two_tone::before {
  content: '\EC13';
}

.xgl-pause_secure_two_tone::after {
  content: '\EC14';
  opacity: .2;
}

.xgl-pause_square::before {
  content: '\E807';
}

.xgl-pause_square_alt::before {
  content: '\E808';
}

.xgl-pause_square_two_tone::before {
  content: '\E807';
}

.xgl-pause_square_two_tone::after {
  content: '\E808';
  opacity: .2;
}

.xgl-pause_stack_bkgd::before {
  content: '\E9B0';
}

.xgl-pause_stack_frgd::before {
  content: '\E9B1';
}

.xgl-pause_stack_mdgd::before {
  content: '\E9B2';
}

.xgl-pause_two_tone::before {
  content: '\E348';
}

.xgl-pause_two_tone::after {
  content: '\E349';
  opacity: .2;
}

.xgl-paypal::before {
  content: '\E34C';
}

.xgl-paypal_alt::before {
  content: '\EC8C';
}

.xgl-paypal_two_tone::before {
  content: '\E34C';
}

.xgl-paypal_two_tone::after {
  content: '\EC8C';
  opacity: .2;
}

.xgl-pen_cup::before {
  content: '\E87F';
}

.xgl-pen_cup_alt::before {
  content: '\E880';
}

.xgl-pen_cup_two_tone::before {
  content: '\E87F';
}

.xgl-pen_cup_two_tone::after {
  content: '\E880';
  opacity: .2;
}

.xgl-pending_stack_bkgd::before {
  content: '\E9B3';
}

.xgl-pending_stack_frgd::before {
  content: '\E9B4';
}

.xgl-pending_stack_mdgd::before {
  content: '\E9B5';
}

.xgl-people::before {
  content: '\E34D';
}

.xgl-people_alt::before {
  content: '\E34E';
}

.xgl-people_two_tone::before {
  content: '\E34D';
}

.xgl-people_two_tone::after {
  content: '\E34E';
  opacity: .2;
}

.xgl-perforator::before {
  content: '\E671';
}

.xgl-perforator_alt::before {
  content: '\E672';
}

.xgl-perforator_two_tone::before {
  content: '\E671';
}

.xgl-perforator_two_tone::after {
  content: '\E672';
  opacity: .2;
}

.xgl-personal_jobs::before {
  content: '\E34F';
}

.xgl-personal_jobs_alt::before {
  content: '\E350';
}

.xgl-personal_jobs_two_tone::before {
  content: '\E34F';
}

.xgl-personal_jobs_two_tone::after {
  content: '\E350';
  opacity: .2;
}

.xgl-perspective::before {
  content: '\ED0C';
}

.xgl-phone::before {
  content: '\E351';
}

.xgl-phone_alt::before {
  content: '\E352';
}

.xgl-phone_audio::before {
  content: '\E353';
}

.xgl-phone_audio_alt::before {
  content: '\E354';
}

.xgl-phone_audio_two_tone::before {
  content: '\E353';
}

.xgl-phone_audio_two_tone::after {
  content: '\E354';
  opacity: .2;
}

.xgl-phone_dial_pause::before {
  content: '\E782';
}

.xgl-phone_dial_pause_alt::before {
  content: '\E783';
}

.xgl-phone_dial_pause_two_tone::before {
  content: '\E782';
}

.xgl-phone_dial_pause_two_tone::after {
  content: '\E783';
  opacity: .2;
}

.xgl-phone_disabled::before {
  content: '\E355';
}

.xgl-phone_disabled_alt::before {
  content: '\E356';
}

.xgl-phone_disabled_two_tone::before {
  content: '\E355';
}

.xgl-phone_disabled_two_tone::after {
  content: '\E356';
  opacity: .2;
}

.xgl-phone_incoming::before {
  content: '\E357';
}

.xgl-phone_incoming_alt::before {
  content: '\E358';
}

.xgl-phone_incoming_two_tone::before {
  content: '\E357';
}

.xgl-phone_incoming_two_tone::after {
  content: '\E358';
  opacity: .2;
}

.xgl-phone_info::before {
  content: '\EBBB';
}

.xgl-phone_info_alt::before {
  content: '\EBBC';
}

.xgl-phone_info_two_tone::before {
  content: '\EBBB';
}

.xgl-phone_info_two_tone::after {
  content: '\EBBC';
  opacity: .2;
}

.xgl-phone_jack::before {
  content: '\E784';
}

.xgl-phone_jack_alt::before {
  content: '\E785';
}

.xgl-phone_jack_two_tone::before {
  content: '\E784';
}

.xgl-phone_jack_two_tone::after {
  content: '\E785';
  opacity: .2;
}

.xgl-phone_log::before {
  content: '\E359';
}

.xgl-phone_log_alt::before {
  content: '\E35A';
}

.xgl-phone_log_two_tone::before {
  content: '\E359';
}

.xgl-phone_log_two_tone::after {
  content: '\E35A';
  opacity: .2;
}

.xgl-phone_message::before {
  content: '\EBB9';
}

.xgl-phone_message_alt::before {
  content: '\EBBA';
}

.xgl-phone_message_two_tone::before {
  content: '\EBB9';
}

.xgl-phone_message_two_tone::after {
  content: '\EBBA';
  opacity: .2;
}

.xgl-phone_outgoing::before {
  content: '\E35B';
}

.xgl-phone_outgoing_alt::before {
  content: '\E35C';
}

.xgl-phone_outgoing_two_tone::before {
  content: '\E35B';
}

.xgl-phone_outgoing_two_tone::after {
  content: '\E35C';
  opacity: .2;
}

.xgl-phone_recent::before {
  content: '\E35D';
}

.xgl-phone_recent_alt::before {
  content: '\E35E';
}

.xgl-phone_recent_two_tone::before {
  content: '\E35D';
}

.xgl-phone_recent_two_tone::after {
  content: '\E35E';
  opacity: .2;
}

.xgl-phone_redial::before {
  content: '\E786';
}

.xgl-phone_redial_alt::before {
  content: '\E786';
}

.xgl-phone_redial_two_tone::before {
  content: '\E786';
}

.xgl-phone_redial_two_tone::after {
  content: '\E786';
  opacity: .2;
}

.xgl-phone_speed_dial::before {
  content: '\E788';
}

.xgl-phone_speed_dial_alt::before {
  content: '\E789';
}

.xgl-phone_speed_dial_two_tone::before {
  content: '\E788';
}

.xgl-phone_speed_dial_two_tone::after {
  content: '\E789';
  opacity: .2;
}

.xgl-phone_two_tone::before {
  content: '\E351';
}

.xgl-phone_two_tone::after {
  content: '\E352';
  opacity: .2;
}

.xgl-photo_lef::before {
  content: '\E35F';
}

.xgl-photo_lef_alt::before {
  content: '\E737';
}

.xgl-photo_lef_two_tone::before {
  content: '\E35F';
}

.xgl-photo_lef_two_tone::after {
  content: '\E737';
  opacity: .2;
}

.xgl-photo_sef::before {
  content: '\E360';
}

.xgl-photo_sef_alt::before {
  content: '\E738';
}

.xgl-photo_sef_two_tone::before {
  content: '\E360';
}

.xgl-photo_sef_two_tone::after {
  content: '\E738';
  opacity: .2;
}

.xgl-photoshop::before {
  content: '\E8D7';
}

.xgl-photoshop_alt::before {
  content: '\E8D8';
}

.xgl-photoshop_two_tone::before {
  content: '\E8D7';
}

.xgl-photoshop_two_tone::after {
  content: '\E8D8';
  opacity: .2;
}

.xgl-picture::before {
  content: '\E361';
}

.xgl-picture_alt::before {
  content: '\E362';
}

.xgl-picture_two_tone::before {
  content: '\E361';
}

.xgl-picture_two_tone::after {
  content: '\E362';
  opacity: .2;
}

.xgl-pictures::before {
  content: '\E363';
}

.xgl-pictures_alt::before {
  content: '\E364';
}

.xgl-pictures_two_tone::before {
  content: '\E363';
}

.xgl-pictures_two_tone::after {
  content: '\E364';
  opacity: .2;
}

.xgl-pie_chart::before {
  content: '\E365';
}

.xgl-pie_chart_alt::before {
  content: '\E366';
}

.xgl-pie_chart_square::before {
  content: '\E367';
}

.xgl-pie_chart_square_alt::before {
  content: '\E368';
}

.xgl-pie_chart_square_two_tone::before {
  content: '\E367';
}

.xgl-pie_chart_square_two_tone::after {
  content: '\E368';
  opacity: .2;
}

.xgl-pie_chart_two_tone::before {
  content: '\E365';
}

.xgl-pie_chart_two_tone::after {
  content: '\E366';
  opacity: .2;
}

.xgl-pilcrow::before {
  content: '\E5C5';
}

.xgl-pin::before {
  content: '\E597';
}

.xgl-pin_alt::before {
  content: '\E598';
}

.xgl-pin_two_tone::before {
  content: '\E597';
}

.xgl-pin_two_tone::after {
  content: '\E598';
  opacity: .2;
}

.xgl-pinch::before {
  content: '\E913';
}

.xgl-pinch_alt::before {
  content: '\E914';
}

.xgl-pinch_three::before {
  content: '\E917';
}

.xgl-pinch_three_alt::before {
  content: '\E918';
}

.xgl-pinch_three_two_tone::before {
  content: '\E917';
}

.xgl-pinch_three_two_tone::after {
  content: '\E918';
  opacity: .2;
}

.xgl-pinch_two_tone::before {
  content: '\E913';
}

.xgl-pinch_two_tone::after {
  content: '\E914';
  opacity: .2;
}

.xgl-plain_lef::before {
  content: '\E369';
}

.xgl-plain_lef_alt::before {
  content: '\E739';
}

.xgl-plain_lef_two_tone::before {
  content: '\E369';
}

.xgl-plain_lef_two_tone::after {
  content: '\E739';
  opacity: .2;
}

.xgl-plain_reloaded_lef::before {
  content: '\E36A';
}

.xgl-plain_reloaded_lef_alt::before {
  content: '\E73A';
}

.xgl-plain_reloaded_lef_two_tone::before {
  content: '\E36A';
}

.xgl-plain_reloaded_lef_two_tone::after {
  content: '\E73A';
  opacity: .2;
}

.xgl-plain_reloaded_sef::before {
  content: '\E36B';
}

.xgl-plain_reloaded_sef_alt::before {
  content: '\E73B';
}

.xgl-plain_reloaded_sef_two_tone::before {
  content: '\E36B';
}

.xgl-plain_reloaded_sef_two_tone::after {
  content: '\E73B';
  opacity: .2;
}

.xgl-plain_sef::before {
  content: '\E36C';
}

.xgl-plain_sef_alt::before {
  content: '\E73C';
}

.xgl-plain_sef_two_tone::before {
  content: '\E36C';
}

.xgl-plain_sef_two_tone::after {
  content: '\E73C';
  opacity: .2;
}

.xgl-play::before {
  content: '\E36D';
}

.xgl-play_alt::before {
  content: '\E36E';
}

.xgl-play_two_tone::before {
  content: '\E36D';
}

.xgl-play_two_tone::after {
  content: '\E36E';
  opacity: .2;
}

.xgl-plug::before {
  content: '\E36F';
}

.xgl-plug_alt::before {
  content: '\E370';
}

.xgl-plug_disabled::before {
  content: '\E933';
}

.xgl-plug_disabled_alt::before {
  content: '\E934';
}

.xgl-plug_disabled_two_tone::before {
  content: '\E933';
}

.xgl-plug_disabled_two_tone::after {
  content: '\E934';
  opacity: .2;
}

.xgl-plug_two_tone::before {
  content: '\E36F';
}

.xgl-plug_two_tone::after {
  content: '\E370';
  opacity: .2;
}

.xgl-plugin::before {
  content: '\E8AA';
}

.xgl-plugin_alt::before {
  content: '\E8AB';
}

.xgl-plugin_two_tone::before {
  content: '\E8AA';
}

.xgl-plugin_two_tone::after {
  content: '\E8AB';
  opacity: .2;
}

.xgl-plus::before {
  content: '\E371';
}

.xgl-plus_minus::before {
  content: '\E372';
}

.xgl-point_to_point::before {
  content: '\EE41';
}

.xgl-pointer::before {
  content: '\E373';
}

.xgl-pointer_alt::before {
  content: '\E374';
}

.xgl-pointer_two_tone::before {
  content: '\E373';
}

.xgl-pointer_two_tone::after {
  content: '\E374';
  opacity: .2;
}

.xgl-polling::before {
  content: '\E375';
}

.xgl-polling_circle::before {
  content: '\EDD7';
}

.xgl-polling_circle_alt::before {
  content: '\EDD8';
}

.xgl-polling_circle_two_tone::before {
  content: '\EDD7';
}

.xgl-polling_circle_two_tone::after {
  content: '\EDD8';
  opacity: .2;
}

.xgl-polling_disabled::before {
  content: '\E924';
}

.xgl-portrait::before {
  content: '\E376';
}

.xgl-portrait_alt::before {
  content: '\E377';
}

.xgl-portrait_ninety::before {
  content: '\EE09';
}

.xgl-portrait_ninety_alt::before {
  content: '\EE0A';
}

.xgl-portrait_ninety_two_tone::before {
  content: '\EE09';
}

.xgl-portrait_ninety_two_tone::after {
  content: '\EE0A';
  opacity: .2;
}

.xgl-portrait_one_eighty::before {
  content: '\EE0B';
}

.xgl-portrait_one_eighty_alt::before {
  content: '\EE0C';
}

.xgl-portrait_one_eighty_two_tone::before {
  content: '\EE0B';
}

.xgl-portrait_one_eighty_two_tone::after {
  content: '\EE0C';
  opacity: .2;
}

.xgl-portrait_two_seventy::before {
  content: '\EE0D';
}

.xgl-portrait_two_seventy_alt::before {
  content: '\EE0E';
}

.xgl-portrait_two_seventy_two_tone::before {
  content: '\EE0D';
}

.xgl-portrait_two_seventy_two_tone::after {
  content: '\EE0E';
  opacity: .2;
}

.xgl-portrait_two_tone::before {
  content: '\E376';
}

.xgl-portrait_two_tone::after {
  content: '\E377';
  opacity: .2;
}

.xgl-positioning::before {
  content: '\E378';
}

.xgl-positioning_alt::before {
  content: '\E379';
}

.xgl-positioning_mfd::before {
  content: '\E9B6';
}

.xgl-positioning_mfd_alt::before {
  content: '\E9B7';
}

.xgl-positioning_mfd_two_tone::before {
  content: '\E9B6';
}

.xgl-positioning_mfd_two_tone::after {
  content: '\E9B7';
  opacity: .2;
}

.xgl-positioning_two_tone::before {
  content: '\E378';
}

.xgl-positioning_two_tone::after {
  content: '\E379';
  opacity: .2;
}

.xgl-postcard_lef::before {
  content: '\E37A';
}

.xgl-postcard_lef_alt::before {
  content: '\E73D';
}

.xgl-postcard_lef_two_tone::before {
  content: '\E37A';
}

.xgl-postcard_lef_two_tone::after {
  content: '\E73D';
  opacity: .2;
}

.xgl-postcard_reloaded_lef::before {
  content: '\E37B';
}

.xgl-postcard_reloaded_lef_alt::before {
  content: '\E73E';
}

.xgl-postcard_reloaded_lef_two_tone::before {
  content: '\E37B';
}

.xgl-postcard_reloaded_lef_two_tone::after {
  content: '\E73E';
  opacity: .2;
}

.xgl-postcard_reloaded_sef::before {
  content: '\E37C';
}

.xgl-postcard_reloaded_sef_alt::before {
  content: '\E73F';
}

.xgl-postcard_reloaded_sef_two_tone::before {
  content: '\E37C';
}

.xgl-postcard_reloaded_sef_two_tone::after {
  content: '\E73F';
  opacity: .2;
}

.xgl-postcard_sef::before {
  content: '\E37D';
}

.xgl-postcard_sef_alt::before {
  content: '\E740';
}

.xgl-postcard_sef_two_tone::before {
  content: '\E37D';
}

.xgl-postcard_sef_two_tone::after {
  content: '\E740';
  opacity: .2;
}

.xgl-pound::before {
  content: '\E37E';
}

.xgl-power::before {
  content: '\E37F';
}

.xgl-power_alt::before {
  content: '\EB75';
}

.xgl-power_cycle::before {
  content: '\E78A';
}

.xgl-power_cycle_alt::before {
  content: '\EB74';
}

.xgl-power_cycle_two_tone::before {
  content: '\E78A';
}

.xgl-power_cycle_two_tone::after {
  content: '\EB74';
  opacity: .2;
}

.xgl-power_off::before {
  content: '\E78B';
}

.xgl-power_on::before {
  content: '\E78C';
}

.xgl-power_two_tone::before {
  content: '\E37F';
}

.xgl-power_two_tone::after {
  content: '\EB75';
  opacity: .2;
}

.xgl-pre_gate_roll::before {
  content: '\EAD2';
}

.xgl-pre_gate_roll_alt::before {
  content: '\EAD3';
}

.xgl-pre_gate_roll_two_tone::before {
  content: '\EAD2';
}

.xgl-pre_gate_roll_two_tone::after {
  content: '\EAD3';
  opacity: .2;
}

.xgl-precut_tabs_lef::before {
  content: '\E380';
}

.xgl-precut_tabs_lef_alt::before {
  content: '\E741';
}

.xgl-precut_tabs_lef_two_tone::before {
  content: '\E380';
}

.xgl-precut_tabs_lef_two_tone::after {
  content: '\E741';
  opacity: .2;
}

.xgl-precut_tabs_sef::before {
  content: '\E381';
}

.xgl-precut_tabs_sef_alt::before {
  content: '\E742';
}

.xgl-precut_tabs_sef_two_tone::before {
  content: '\E381';
}

.xgl-precut_tabs_sef_two_tone::after {
  content: '\E742';
  opacity: .2;
}

.xgl-preprinted_lef::before {
  content: '\E382';
}

.xgl-preprinted_lef_alt::before {
  content: '\E743';
}

.xgl-preprinted_lef_two_tone::before {
  content: '\E382';
}

.xgl-preprinted_lef_two_tone::after {
  content: '\E743';
  opacity: .2;
}

.xgl-preprinted_sef::before {
  content: '\E383';
}

.xgl-preprinted_sef_alt::before {
  content: '\E744';
}

.xgl-preprinted_sef_two_tone::before {
  content: '\E383';
}

.xgl-preprinted_sef_two_tone::after {
  content: '\E744';
  opacity: .2;
}

.xgl-presentation::before {
  content: '\E384';
}

.xgl-presentation_alt::before {
  content: '\E385';
}

.xgl-presentation_two_tone::before {
  content: '\E384';
}

.xgl-presentation_two_tone::after {
  content: '\E385';
  opacity: .2;
}

.xgl-preset_pixels::before {
  content: '\EC0D';
}

.xgl-preset_pixels_alt::before {
  content: '\EC0E';
}

.xgl-preset_pixels_two_tone::before {
  content: '\EC0D';
}

.xgl-preset_pixels_two_tone::after {
  content: '\EC0E';
  opacity: .2;
}

.xgl-press_mark::before {
  content: '\EE2D';
}

.xgl-press_mark_alt::before {
  content: '\EE2E';
}

.xgl-press_mark_two_tone::before {
  content: '\EE2D';
}

.xgl-press_mark_two_tone::after {
  content: '\EE2E';
  opacity: .2;
}

.xgl-previous::before {
  content: '\E386';
}

.xgl-previous_alt::before {
  content: '\E387';
}

.xgl-previous_square::before {
  content: '\E388';
}

.xgl-previous_square_alt::before {
  content: '\E389';
}

.xgl-previous_square_two_tone::before {
  content: '\E388';
}

.xgl-previous_square_two_tone::after {
  content: '\E389';
  opacity: .2;
}

.xgl-previous_two_tone::before {
  content: '\E386';
}

.xgl-previous_two_tone::after {
  content: '\E387';
  opacity: .2;
}

.xgl-print::before {
  content: '\E38A';
}

.xgl-print_all::before {
  content: '\E697';
}

.xgl-print_all_alt::before {
  content: '\E698';
}

.xgl-print_all_two_tone::before {
  content: '\E697';
}

.xgl-print_all_two_tone::after {
  content: '\E698';
  opacity: .2;
}

.xgl-print_alt::before {
  content: '\E38B';
}

.xgl-print_cartridge::before {
  content: '\E809';
}

.xgl-print_cartridge_alt::before {
  content: '\E80A';
}

.xgl-print_cartridge_two_tone::before {
  content: '\E809';
}

.xgl-print_cartridge_two_tone::after {
  content: '\E80A';
  opacity: .2;
}

.xgl-print_drum::before {
  content: '\E7BD';
}

.xgl-print_drum_two_tone::before {
  content: '\E7BD';
}

.xgl-print_drum_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-print_estimation::before {
  content: '\EE42';
}

.xgl-print_estimation_alt::before {
  content: '\EE43';
}

.xgl-print_estimation_two_tone::before {
  content: '\EE42';
}

.xgl-print_estimation_two_tone::after {
  content: '\EE43';
  opacity: .2;
}

.xgl-print_settings::before {
  content: '\EE44';
}

.xgl-print_settings_alt::before {
  content: '\EE45';
}

.xgl-print_settings_two_tone::before {
  content: '\EE44';
}

.xgl-print_settings_two_tone::after {
  content: '\EE45';
  opacity: .2;
}

.xgl-print_square::before {
  content: '\E38C';
}

.xgl-print_square_alt::before {
  content: '\E38D';
}

.xgl-print_square_two_tone::before {
  content: '\E38C';
}

.xgl-print_square_two_tone::after {
  content: '\E38D';
  opacity: .2;
}

.xgl-print_two_tone::before {
  content: '\E38A';
}

.xgl-print_two_tone::after {
  content: '\E38B';
  opacity: .2;
}

.xgl-printability_analysis::before {
  content: '\EE46';
}

.xgl-printability_analysis_alt::before {
  content: '\EE47';
}

.xgl-printability_analysis_two_tone::before {
  content: '\EE46';
}

.xgl-printability_analysis_two_tone::after {
  content: '\EE47';
  opacity: .2;
}

.xgl-printer_default_type_lef::before {
  content: '\E38E';
}

.xgl-printer_default_type_lef_alt::before {
  content: '\E745';
}

.xgl-printer_default_type_lef_two_tone::before {
  content: '\E38E';
}

.xgl-printer_default_type_lef_two_tone::after {
  content: '\E745';
  opacity: .2;
}

.xgl-printer_default_type_sef::before {
  content: '\E38F';
}

.xgl-printer_default_type_sef_alt::before {
  content: '\E76E';
}

.xgl-printer_default_type_sef_two_tone::before {
  content: '\E38F';
}

.xgl-printer_default_type_sef_two_tone::after {
  content: '\E76E';
  opacity: .2;
}

.xgl-printer_three_d::before {
  content: '\ECF1';
}

.xgl-printer_three_d_alt::before {
  content: '\ECF2';
}

.xgl-printer_three_d_two_tone::before {
  content: '\ECF1';
}

.xgl-printer_three_d_two_tone::after {
  content: '\ECF2';
  opacity: .2;
}

.xgl-printhead::before {
  content: '\E640';
}

.xgl-printhead_alt::before {
  content: '\E641';
}

.xgl-printhead_two_tone::before {
  content: '\E640';
}

.xgl-printhead_two_tone::after {
  content: '\E641';
  opacity: .2;
}

.xgl-processing_stack_bkgd::before {
  content: '\E9B8';
}

.xgl-processing_stack_frgd::before {
  content: '\E9B9';
}

.xgl-processing_stack_mdgd::before {
  content: '\E9BA';
}

.xgl-production_print::before {
  content: '\EDD9';
}

.xgl-production_print_alt::before {
  content: '\EDDA';
}

.xgl-production_print_two_tone::before {
  content: '\EDD9';
}

.xgl-production_print_two_tone::after {
  content: '\EDDA';
  opacity: .2;
}

.xgl-profile::before {
  content: '\E390';
}

.xgl-profile_add::before {
  content: '\E954';
}

.xgl-profile_add_alt::before {
  content: '\E955';
}

.xgl-profile_add_two_tone::before {
  content: '\E954';
}

.xgl-profile_add_two_tone::after {
  content: '\E955';
  opacity: .2;
}

.xgl-profile_alt::before {
  content: '\E391';
}

.xgl-profile_group::before {
  content: '\EA78';
}

.xgl-profile_group_alt::before {
  content: '\EA79';
}

.xgl-profile_group_two_tone::before {
  content: '\EA78';
}

.xgl-profile_group_two_tone::after {
  content: '\EA79';
  opacity: .2;
}

.xgl-profile_two_tone::before {
  content: '\E390';
}

.xgl-profile_two_tone::after {
  content: '\E391';
  opacity: .2;
}

.xgl-prohibit::before {
  content: '\E392';
}

.xgl-prohibit_square::before {
  content: '\E393';
}

.xgl-prohibit_square_alt::before {
  content: '\E394';
}

.xgl-prohibit_square_two_tone::before {
  content: '\E393';
}

.xgl-prohibit_square_two_tone::after {
  content: '\E394';
  opacity: .2;
}

.xgl-prohibit_two_tone::before {
  content: '\E392';
}

.xgl-prohibit_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-promo::before {
  content: '\E638';
}

.xgl-promo_alt::before {
  content: '\E639';
}

.xgl-promo_two_tone::before {
  content: '\E638';
}

.xgl-promo_two_tone::after {
  content: '\E639';
  opacity: .2;
}

.xgl-promote::before {
  content: '\E395';
}

.xgl-promote_square::before {
  content: '\E396';
}

.xgl-promote_square_alt::before {
  content: '\E397';
}

.xgl-promote_square_two_tone::before {
  content: '\E396';
}

.xgl-promote_square_two_tone::after {
  content: '\E397';
  opacity: .2;
}

.xgl-property::before {
  content: '\E855';
}

.xgl-property_alt::before {
  content: '\E856';
}

.xgl-property_two_tone::before {
  content: '\E855';
}

.xgl-property_two_tone::after {
  content: '\E856';
  opacity: .2;
}

.xgl-prototype::before {
  content: '\E8AC';
}

.xgl-prototype_alt::before {
  content: '\E8AD';
}

.xgl-prototype_two_tone::before {
  content: '\E8AC';
}

.xgl-prototype_two_tone::after {
  content: '\E8AD';
  opacity: .2;
}

.xgl-puzzle::before {
  content: '\E599';
}

.xgl-puzzle_alt::before {
  content: '\E59A';
}

.xgl-puzzle_two_tone::before {
  content: '\E599';
}

.xgl-puzzle_two_tone::after {
  content: '\E59A';
  opacity: .2;
}

.xgl-qr_code::before {
  content: '\E398';
}

.xgl-qr_code_alt::before {
  content: '\EC88';
}

.xgl-qr_code_two_tone::before {
  content: '\E398';
}

.xgl-qr_code_two_tone::after {
  content: '\EC88';
  opacity: .2;
}

.xgl-quantity::before {
  content: '\E399';
}

.xgl-quantity_alt::before {
  content: '\E39A';
}

.xgl-quantity_two_tone::before {
  content: '\E399';
}

.xgl-quantity_two_tone::after {
  content: '\E39A';
  opacity: .2;
}

.xgl-queue::before {
  content: '\E39B';
}

.xgl-queue_active::before {
  content: '\E39C';
}

.xgl-queue_active_alt::before {
  content: '\E39D';
}

.xgl-queue_active_two_tone::before {
  content: '\E39C';
}

.xgl-queue_active_two_tone::after {
  content: '\E39D';
  opacity: .2;
}

.xgl-queue_add::before {
  content: '\E62B';
}

.xgl-queue_add_alt::before {
  content: '\E62C';
}

.xgl-queue_add_two_tone::before {
  content: '\E62B';
}

.xgl-queue_add_two_tone::after {
  content: '\E62C';
  opacity: .2;
}

.xgl-queue_alt::before {
  content: '\E39E';
}

.xgl-queue_completed::before {
  content: '\E39F';
}

.xgl-queue_completed_alt::before {
  content: '\E3A0';
}

.xgl-queue_completed_two_tone::before {
  content: '\E39F';
}

.xgl-queue_completed_two_tone::after {
  content: '\E3A0';
  opacity: .2;
}

.xgl-queue_held::before {
  content: '\E3A1';
}

.xgl-queue_held_alt::before {
  content: '\E3A2';
}

.xgl-queue_held_two_tone::before {
  content: '\E3A1';
}

.xgl-queue_held_two_tone::after {
  content: '\E3A2';
  opacity: .2;
}

.xgl-queue_options::before {
  content: '\EE0F';
}

.xgl-queue_options_alt::before {
  content: '\EE10';
}

.xgl-queue_options_two_tone::before {
  content: '\EE0F';
}

.xgl-queue_options_two_tone::after {
  content: '\EE10';
  opacity: .2;
}

.xgl-queue_secure::before {
  content: '\EB72';
}

.xgl-queue_secure_alt::before {
  content: '\EB73';
}

.xgl-queue_secure_two_tone::before {
  content: '\EB72';
}

.xgl-queue_secure_two_tone::after {
  content: '\EB73';
  opacity: .2;
}

.xgl-queue_two_tone::before {
  content: '\E39B';
}

.xgl-queue_two_tone::after {
  content: '\E39E';
  opacity: .2;
}

.xgl-quote::before {
  content: '\E3A3';
}

.xgl-quote_alt::before {
  content: '\E3A4';
}

.xgl-quote_open::before {
  content: '\E5B5';
}

.xgl-quote_open_alt::before {
  content: '\E5B6';
}

.xgl-quote_open_two_tone::before {
  content: '\E5B5';
}

.xgl-quote_open_two_tone::after {
  content: '\E5B6';
  opacity: .2;
}

.xgl-quote_two_tone::before {
  content: '\E3A3';
}

.xgl-quote_two_tone::after {
  content: '\E3A4';
  opacity: .2;
}

.xgl-radio_off::before {
  content: '\E3A5';
}

.xgl-radio_off_hover::before {
  content: '\EB6A';
}

.xgl-radio_on::before {
  content: '\E3A6';
}

.xgl-radio_on_hover::before {
  content: '\EB6B';
}

.xgl-rate::before {
  content: '\E3A7';
}

.xgl-rate_alt::before {
  content: '\E3A8';
}

.xgl-rate_two_tone::before {
  content: '\E3A7';
}

.xgl-rate_two_tone::after {
  content: '\E3A8';
  opacity: .2;
}

.xgl-read_receipts::before {
  content: '\E3A9';
}

.xgl-read_receipts_alt::before {
  content: '\E3AA';
}

.xgl-read_receipts_two_tone::before {
  content: '\E3A9';
}

.xgl-read_receipts_two_tone::after {
  content: '\E3AA';
  opacity: .2;
}

.xgl-reading_list::before {
  content: '\E3AB';
}

.xgl-reading_list_alt::before {
  content: '\EB71';
}

.xgl-reading_list_two_tone::before {
  content: '\E3AB';
}

.xgl-reading_list_two_tone::after {
  content: '\EB71';
  opacity: .2;
}

.xgl-reading_order_l_r::before {
  content: '\E3AC';
}

.xgl-reading_order_l_r_alt::before {
  content: '\E3AD';
}

.xgl-reading_order_l_r_two_tone::before {
  content: '\E3AC';
}

.xgl-reading_order_l_r_two_tone::after {
  content: '\E3AD';
  opacity: .2;
}

.xgl-reading_order_r_l::before {
  content: '\E3AE';
}

.xgl-reading_order_r_l_alt::before {
  content: '\E3AF';
}

.xgl-reading_order_r_l_two_tone::before {
  content: '\E3AE';
}

.xgl-reading_order_r_l_two_tone::after {
  content: '\E3AF';
  opacity: .2;
}

.xgl-receipt::before {
  content: '\E3B0';
}

.xgl-receipt_alt::before {
  content: '\E3B1';
}

.xgl-receipt_two_tone::before {
  content: '\E3B0';
}

.xgl-receipt_two_tone::after {
  content: '\E3B1';
  opacity: .2;
}

.xgl-recipient_bcc::before {
  content: '\E857';
}

.xgl-recipient_bcc_alt::before {
  content: '\E858';
}

.xgl-recipient_bcc_two_tone::before {
  content: '\E857';
}

.xgl-recipient_bcc_two_tone::after {
  content: '\E858';
  opacity: .2;
}

.xgl-recipient_cc::before {
  content: '\E859';
}

.xgl-recipient_cc_alt::before {
  content: '\E85A';
}

.xgl-recipient_cc_two_tone::before {
  content: '\E859';
}

.xgl-recipient_cc_two_tone::after {
  content: '\E85A';
  opacity: .2;
}

.xgl-recipient_to::before {
  content: '\E85B';
}

.xgl-recipient_to_alt::before {
  content: '\E85C';
}

.xgl-recipient_to_two_tone::before {
  content: '\E85B';
}

.xgl-recipient_to_two_tone::after {
  content: '\E85C';
  opacity: .2;
}

.xgl-record::before {
  content: '\E3B2';
}

.xgl-record_alt::before {
  content: '\E3B3';
}

.xgl-record_two_tone::before {
  content: '\E3B2';
}

.xgl-record_two_tone::after {
  content: '\E3B3';
  opacity: .2;
}

.xgl-rectangle_horiz_bkgd::before {
  content: '\E3B4';
}

.xgl-rectangle_vert_bkgd::before {
  content: '\E3B5';
}

.xgl-recycle::before {
  content: '\E3B6';
}

.xgl-recycle_two_tone::before {
  content: '\E3B6';
}

.xgl-recycle_two_tone::after {
  content: '\EC89';
  opacity: .2;
}

.xgl-recycled_full_cut_tabs_lef::before {
  content: '\E3B7';
}

.xgl-recycled_full_cut_tabs_lef_alt::before {
  content: '\E746';
}

.xgl-recycled_full_cut_tabs_lef_two_tone::before {
  content: '\E3B7';
}

.xgl-recycled_full_cut_tabs_lef_two_tone::after {
  content: '\E746';
  opacity: .2;
}

.xgl-recycled_full_cut_tabs_sef::before {
  content: '\E3B8';
}

.xgl-recycled_full_cut_tabs_sef_alt::before {
  content: '\E747';
}

.xgl-recycled_full_cut_tabs_sef_two_tone::before {
  content: '\E3B8';
}

.xgl-recycled_full_cut_tabs_sef_two_tone::after {
  content: '\E747';
  opacity: .2;
}

.xgl-recycled_lef::before {
  content: '\E3B9';
}

.xgl-recycled_lef_alt::before {
  content: '\E748';
}

.xgl-recycled_lef_two_tone::before {
  content: '\E3B9';
}

.xgl-recycled_lef_two_tone::after {
  content: '\E748';
  opacity: .2;
}

.xgl-recycled_precut_tabs_lef::before {
  content: '\E3BA';
}

.xgl-recycled_precut_tabs_lef_alt::before {
  content: '\E749';
}

.xgl-recycled_precut_tabs_lef_two_tone::before {
  content: '\E3BA';
}

.xgl-recycled_precut_tabs_lef_two_tone::after {
  content: '\E749';
  opacity: .2;
}

.xgl-recycled_precut_tabs_sef::before {
  content: '\E3BB';
}

.xgl-recycled_precut_tabs_sef_alt::before {
  content: '\E74A';
}

.xgl-recycled_precut_tabs_sef_two_tone::before {
  content: '\E3BB';
}

.xgl-recycled_precut_tabs_sef_two_tone::after {
  content: '\E74A';
  opacity: .2;
}

.xgl-recycled_reloaded_lef::before {
  content: '\E943';
}

.xgl-recycled_reloaded_lef_alt::before {
  content: '\E944';
}

.xgl-recycled_reloaded_lef_two_tone::before {
  content: '\E943';
}

.xgl-recycled_reloaded_lef_two_tone::after {
  content: '\E944';
  opacity: .2;
}

.xgl-recycled_reloaded_sef::before {
  content: '\E941';
}

.xgl-recycled_reloaded_sef_alt::before {
  content: '\E942';
}

.xgl-recycled_reloaded_sef_two_tone::before {
  content: '\E941';
}

.xgl-recycled_reloaded_sef_two_tone::after {
  content: '\E942';
  opacity: .2;
}

.xgl-recycled_sef::before {
  content: '\E3BC';
}

.xgl-recycled_sef_alt::before {
  content: '\E74B';
}

.xgl-recycled_sef_two_tone::before {
  content: '\E3BC';
}

.xgl-recycled_sef_two_tone::after {
  content: '\E74B';
  opacity: .2;
}

.xgl-redirect::before {
  content: '\E3BD';
}

.xgl-redirect_all::before {
  content: '\EAC2';
}

.xgl-redirect_all_alt::before {
  content: '\EAC3';
}

.xgl-redirect_all_rtl::before {
  content: '\EAC4';
}

.xgl-redirect_all_rtl_alt::before {
  content: '\EAC5';
}

.xgl-redirect_all_rtl_two_tone::before {
  content: '\EAC4';
}

.xgl-redirect_all_rtl_two_tone::after {
  content: '\EAC5';
  opacity: .2;
}

.xgl-redirect_all_two_tone::before {
  content: '\EAC2';
}

.xgl-redirect_all_two_tone::after {
  content: '\EAC3';
  opacity: .2;
}

.xgl-redirect_alt::before {
  content: '\E95F';
}

.xgl-redirect_rtl::before {
  content: '\E5A7';
}

.xgl-redirect_rtl_alt::before {
  content: '\E960';
}

.xgl-redirect_rtl_two_tone::before {
  content: '\E5A7';
}

.xgl-redirect_rtl_two_tone::after {
  content: '\E960';
  opacity: .2;
}

.xgl-redirect_two_tone::before {
  content: '\E3BD';
}

.xgl-redirect_two_tone::after {
  content: '\E95F';
  opacity: .2;
}

.xgl-redo::before {
  content: '\E3BE';
}

.xgl-redo_square::before {
  content: '\E3BF';
}

.xgl-redo_square_alt::before {
  content: '\E3C0';
}

.xgl-redo_square_two_tone::before {
  content: '\E3BF';
}

.xgl-redo_square_two_tone::after {
  content: '\E3C0';
  opacity: .2;
}

.xgl-reduce_down::before {
  content: '\EDDB';
}

.xgl-reduce_down_alt::before {
  content: '\EDDC';
}

.xgl-reduce_down_two_tone::before {
  content: '\EDDB';
}

.xgl-reduce_down_two_tone::after {
  content: '\EDDC';
  opacity: .2;
}

.xgl-reduce_enlarge::before {
  content: '\E3C1';
}

.xgl-reduce_enlarge_100::before {
  content: '\EC60';
}

.xgl-reduce_enlarge_100_alt::before {
  content: '\EC61';
}

.xgl-reduce_enlarge_100_two_tone::before {
  content: '\EC60';
}

.xgl-reduce_enlarge_100_two_tone::after {
  content: '\EC61';
  opacity: .2;
}

.xgl-reduce_enlarge_alt::before {
  content: '\E3C2';
}

.xgl-reduce_enlarge_locked::before {
  content: '\EC36';
}

.xgl-reduce_enlarge_locked_alt::before {
  content: '\EC37';
}

.xgl-reduce_enlarge_locked_two_tone::before {
  content: '\EC36';
}

.xgl-reduce_enlarge_locked_two_tone::after {
  content: '\EC37';
  opacity: .2;
}

.xgl-reduce_enlarge_two_tone::before {
  content: '\E3C1';
}

.xgl-reduce_enlarge_two_tone::after {
  content: '\E3C2';
  opacity: .2;
}

.xgl-reduce_up::before {
  content: '\EDDD';
}

.xgl-reduce_up_alt::before {
  content: '\EDDE';
}

.xgl-reduce_up_two_tone::before {
  content: '\EDDD';
}

.xgl-reduce_up_two_tone::after {
  content: '\EDDE';
  opacity: .2;
}

.xgl-refresh::before {
  content: '\E3C3';
}

.xgl-refresh_all::before {
  content: '\E3C4';
}

.xgl-refresh_all_alt::before {
  content: '\E3C5';
}

.xgl-refresh_all_two_tone::before {
  content: '\E3C4';
}

.xgl-refresh_all_two_tone::after {
  content: '\E3C5';
  opacity: .2;
}

.xgl-refresh_square::before {
  content: '\E3C6';
}

.xgl-refresh_square_alt::before {
  content: '\E3C7';
}

.xgl-refresh_square_two_tone::before {
  content: '\E3C6';
}

.xgl-refresh_square_two_tone::after {
  content: '\E3C7';
  opacity: .2;
}

.xgl-regi_loop::before {
  content: '\EAD0';
}

.xgl-regi_loop_alt::before {
  content: '\EAD1';
}

.xgl-regi_loop_two_tone::before {
  content: '\EAD0';
}

.xgl-regi_loop_two_tone::after {
  content: '\EAD1';
  opacity: .2;
}

.xgl-reload::before {
  content: '\E3C8';
}

.xgl-reload_partial::before {
  content: '\EA7A';
}

.xgl-remote::before {
  content: '\E3C9';
}

.xgl-remote_alt::before {
  content: '\E3CA';
}

.xgl-remote_desktop::before {
  content: '\E3CB';
}

.xgl-remote_desktop_alt::before {
  content: '\E3CC';
}

.xgl-remote_desktop_two_tone::before {
  content: '\E3CB';
}

.xgl-remote_desktop_two_tone::after {
  content: '\E3CC';
  opacity: .2;
}

.xgl-remote_two_tone::before {
  content: '\E3C9';
}

.xgl-remote_two_tone::after {
  content: '\E3CA';
  opacity: .2;
}

.xgl-remote_vert::before {
  content: '\E3CD';
}

.xgl-remote_vert_alt::before {
  content: '\E3CE';
}

.xgl-remote_vert_two_tone::before {
  content: '\E3CD';
}

.xgl-remote_vert_two_tone::after {
  content: '\E3CE';
  opacity: .2;
}

.xgl-remove_all::before {
  content: '\E3CF';
}

.xgl-remove_all_alt::before {
  content: '\E3D0';
}

.xgl-remove_all_square::before {
  content: '\E3D1';
}

.xgl-remove_all_square_alt::before {
  content: '\E3D2';
}

.xgl-remove_all_square_two_tone::before {
  content: '\E3D1';
}

.xgl-remove_all_square_two_tone::after {
  content: '\E3D2';
  opacity: .2;
}

.xgl-remove_all_two_tone::before {
  content: '\E3CF';
}

.xgl-remove_all_two_tone::after {
  content: '\E3D0';
  opacity: .2;
}

.xgl-remove_blank_pages::before {
  content: '\EC28';
}

.xgl-remove_blank_pages_alt::before {
  content: '\EC29';
}

.xgl-remove_blank_pages_two_tone::before {
  content: '\EC28';
}

.xgl-remove_blank_pages_two_tone::after {
  content: '\EC29';
  opacity: .2;
}

.xgl-remove_pages::before {
  content: '\EC09';
}

.xgl-remove_pages_alt::before {
  content: '\EC0A';
}

.xgl-remove_pages_two_tone::before {
  content: '\EC09';
}

.xgl-remove_pages_two_tone::after {
  content: '\EC0A';
  opacity: .2;
}

.xgl-rename::before {
  content: '\E3D3';
}

.xgl-rename_alt::before {
  content: '\EC75';
}

.xgl-rename_two_tone::before {
  content: '\E3D3';
}

.xgl-rename_two_tone::after {
  content: '\EC75';
  opacity: .2;
}

.xgl-reorder::before {
  content: '\E3D4';
}

.xgl-reorder_horizontal::before {
  content: '\E3D5';
}

.xgl-repeat::before {
  content: '\E3D6';
}

.xgl-replay::before {
  content: '\E3D7';
}

.xgl-replay_all::before {
  content: '\E3D8';
}

.xgl-replay_all_alt::before {
  content: '\E3D9';
}

.xgl-replay_all_two_tone::before {
  content: '\E3D8';
}

.xgl-replay_all_two_tone::after {
  content: '\E3D9';
  opacity: .2;
}

.xgl-replay_square::before {
  content: '\E3DA';
}

.xgl-replay_square_alt::before {
  content: '\E3DB';
}

.xgl-replay_square_two_tone::before {
  content: '\E3DA';
}

.xgl-replay_square_two_tone::after {
  content: '\E3DB';
  opacity: .2;
}

.xgl-reply_all_circle::before {
  content: '\EDDF';
}

.xgl-reply_all_circle_alt::before {
  content: '\EDE0';
}

.xgl-reply_all_circle_two_tone::before {
  content: '\EDDF';
}

.xgl-reply_all_circle_two_tone::after {
  content: '\EDE0';
  opacity: .2;
}

.xgl-reply_circle::before {
  content: '\EDE1';
}

.xgl-reply_circle_alt::before {
  content: '\EDE2';
}

.xgl-reply_circle_two_tone::before {
  content: '\EDE1';
}

.xgl-reply_circle_two_tone::after {
  content: '\EDE2';
  opacity: .2;
}

.xgl-reply_to::before {
  content: '\E3DC';
}

.xgl-reply_to_all::before {
  content: '\E3DD';
}

.xgl-repository::before {
  content: '\E3DE';
}

.xgl-repository_alt::before {
  content: '\E3DF';
}

.xgl-repository_two_tone::before {
  content: '\E3DE';
}

.xgl-repository_two_tone::after {
  content: '\E3DF';
  opacity: .2;
}

.xgl-res_bkgd::before {
  content: '\EC0C';
}

.xgl-res_four_hundred::before {
  content: '\E826';
}

.xgl-res_four_hundred_two_tone::before {
  content: '\E826';
}

.xgl-res_four_hundred_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_high::before {
  content: '\E827';
}

.xgl-res_high_two_tone::before {
  content: '\E827';
}

.xgl-res_high_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-res_low::before {
  content: '\E828';
}

.xgl-res_low_two_tone::before {
  content: '\E828';
}

.xgl-res_low_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-res_medium::before {
  content: '\E829';
}

.xgl-res_medium_two_tone::before {
  content: '\E829';
}

.xgl-res_medium_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-res_one_fifty::before {
  content: '\E82A';
}

.xgl-res_one_fifty_two_tone::before {
  content: '\E82A';
}

.xgl-res_one_fifty_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_one_hundred::before {
  content: '\E82B';
}

.xgl-res_one_hundred_two_tone::before {
  content: '\E82B';
}

.xgl-res_one_hundred_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_seventy_two::before {
  content: '\E82C';
}

.xgl-res_seventy_two_two_tone::before {
  content: '\E82C';
}

.xgl-res_seventy_two_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_six_hundred::before {
  content: '\E82D';
}

.xgl-res_six_hundred_two_tone::before {
  content: '\E82D';
}

.xgl-res_six_hundred_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_three_hundred::before {
  content: '\E82E';
}

.xgl-res_three_hundred_two_tone::before {
  content: '\E82E';
}

.xgl-res_three_hundred_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_two_hundred::before {
  content: '\E82F';
}

.xgl-res_two_hundred_two_tone::before {
  content: '\E82F';
}

.xgl-res_two_hundred_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_two_x_four::before {
  content: '\E830';
}

.xgl-res_two_x_four_two_tone::before {
  content: '\E830';
}

.xgl-res_two_x_four_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-res_two_x_one::before {
  content: '\E831';
}

.xgl-res_two_x_one_two_tone::before {
  content: '\E831';
}

.xgl-res_two_x_one_two_tone::after {
  content: '\EC0C';
  opacity: .2;
}

.xgl-reset::before {
  content: '\E3E0';
}

.xgl-reset_all::before {
  content: '\E3E1';
}

.xgl-reset_all_alt::before {
  content: '\E3E2';
}

.xgl-reset_all_two_tone::before {
  content: '\E3E1';
}

.xgl-reset_all_two_tone::after {
  content: '\E3E2';
  opacity: .2;
}

.xgl-reset_square::before {
  content: '\E3E3';
}

.xgl-reset_square_alt::before {
  content: '\E3E4';
}

.xgl-reset_square_two_tone::before {
  content: '\E3E3';
}

.xgl-reset_square_two_tone::after {
  content: '\E3E4';
  opacity: .2;
}

.xgl-responsive::before {
  content: '\E91D';
}

.xgl-responsive_alt::before {
  content: '\E91E';
}

.xgl-responsive_two_tone::before {
  content: '\E91D';
}

.xgl-responsive_two_tone::after {
  content: '\E91E';
  opacity: .2;
}

.xgl-restart::before {
  content: '\E3E5';
}

.xgl-restart_two_tone::before {
  content: '\E3E5';
}

.xgl-restart_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-retrieve_settings::before {
  content: '\EC30';
}

.xgl-retrieve_settings_alt::before {
  content: '\EC31';
}

.xgl-retrieve_settings_two_tone::before {
  content: '\EC30';
}

.xgl-retrieve_settings_two_tone::after {
  content: '\EC31';
  opacity: .2;
}

.xgl-return::before {
  content: '\E3E6';
}

.xgl-rewind::before {
  content: '\E3E8';
}

.xgl-rewind_alt::before {
  content: '\E3E9';
}

.xgl-rewind_two_tone::before {
  content: '\E3E8';
}

.xgl-rewind_two_tone::after {
  content: '\E3E9';
  opacity: .2;
}

.xgl-robotic_arm::before {
  content: '\E7ED';
}

.xgl-robotic_arm_alt::before {
  content: '\E7EE';
}

.xgl-robotic_arm_two_tone::before {
  content: '\E7ED';
}

.xgl-robotic_arm_two_tone::after {
  content: '\E7EE';
  opacity: .2;
}

.xgl-rocket::before {
  content: '\E3EA';
}

.xgl-rocket_alt::before {
  content: '\E3EB';
}

.xgl-rocket_two_tone::before {
  content: '\E3EA';
}

.xgl-rocket_two_tone::after {
  content: '\E3EB';
  opacity: .2;
}

.xgl-roller::before {
  content: '\E80B';
}

.xgl-roller_alt::before {
  content: '\E80C';
}

.xgl-roller_two_tone::before {
  content: '\E80B';
}

.xgl-roller_two_tone::after {
  content: '\E80C';
  opacity: .2;
}

.xgl-rotate::before {
  content: '\E915';
}

.xgl-rotate_alt::before {
  content: '\E916';
}

.xgl-rotate_around::before {
  content: '\ED0D';
}

.xgl-rotate_around_alt::before {
  content: '\ED0E';
}

.xgl-rotate_around_two_tone::before {
  content: '\ED0D';
}

.xgl-rotate_around_two_tone::after {
  content: '\ED0E';
  opacity: .2;
}

.xgl-rotate_axis::before {
  content: '\EDE3';
}

.xgl-rotate_axis_alt::before {
  content: '\ED0F';
}

.xgl-rotate_axis_center::before {
  content: '\ED10';
}

.xgl-rotate_axis_center_two_tone::before {
  content: '\ED10';
}

.xgl-rotate_axis_center_two_tone::after {
  content: '\ED0F';
  opacity: .2;
}

.xgl-rotate_axis_two_tone::before {
  content: '\EDE3';
}

.xgl-rotate_axis_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-rotate_axis_x::before {
  content: '\ED11';
}

.xgl-rotate_axis_x_two_tone::before {
  content: '\ED11';
}

.xgl-rotate_axis_x_two_tone::after {
  content: '\ED0F';
  opacity: .2;
}

.xgl-rotate_axis_y::before {
  content: '\ED12';
}

.xgl-rotate_axis_y_two_tone::before {
  content: '\ED12';
}

.xgl-rotate_axis_y_two_tone::after {
  content: '\ED0F';
  opacity: .2;
}

.xgl-rotate_axis_z::before {
  content: '\ED13';
}

.xgl-rotate_axis_z_two_tone::before {
  content: '\ED13';
}

.xgl-rotate_axis_z_two_tone::after {
  content: '\ED0F';
  opacity: .2;
}

.xgl-rotate_left::before {
  content: '\E3EC';
}

.xgl-rotate_right::before {
  content: '\E3ED';
}

.xgl-rotate_two_tone::before {
  content: '\E915';
}

.xgl-rotate_two_tone::after {
  content: '\E916';
  opacity: .2;
}

.xgl-rough_surface_lef::before {
  content: '\E3EE';
}

.xgl-rough_surface_lef_alt::before {
  content: '\E74C';
}

.xgl-rough_surface_lef_two_tone::before {
  content: '\E3EE';
}

.xgl-rough_surface_lef_two_tone::after {
  content: '\E74C';
  opacity: .2;
}

.xgl-rough_surface_sef::before {
  content: '\E3EF';
}

.xgl-rough_surface_sef_alt::before {
  content: '\E74D';
}

.xgl-rough_surface_sef_two_tone::before {
  content: '\E3EF';
}

.xgl-rough_surface_sef_two_tone::after {
  content: '\E74D';
  opacity: .2;
}

.xgl-rows::before {
  content: '\E3F0';
}

.xgl-rows_alt::before {
  content: '\E3F1';
}

.xgl-rows_off::before {
  content: '\EDFC';
}

.xgl-rows_off_alt::before {
  content: '\EDFD';
}

.xgl-rows_off_two_tone::before {
  content: '\EDFC';
}

.xgl-rows_off_two_tone::after {
  content: '\EDFD';
  opacity: .2;
}

.xgl-rows_two_tone::before {
  content: '\E3F0';
}

.xgl-rows_two_tone::after {
  content: '\E3F1';
  opacity: .2;
}

.xgl-ruler::before {
  content: '\E3F2';
}

.xgl-ruler_alt::before {
  content: '\E3F3';
}

.xgl-ruler_off::before {
  content: '\EE01';
}

.xgl-ruler_off_alt::before {
  content: '\EE02';
}

.xgl-ruler_off_two_tone::before {
  content: '\EE01';
}

.xgl-ruler_off_two_tone::after {
  content: '\EE02';
  opacity: .2;
}

.xgl-ruler_two_tone::before {
  content: '\E3F2';
}

.xgl-ruler_two_tone::after {
  content: '\E3F3';
  opacity: .2;
}

.xgl-safari::before {
  content: '\E3F4';
}

.xgl-safari_alt::before {
  content: '\E3F5';
}

.xgl-safari_two_tone::before {
  content: '\E3F4';
}

.xgl-safari_two_tone::after {
  content: '\E3F5';
  opacity: .2;
}

.xgl-safe::before {
  content: '\E3F6';
}

.xgl-safe_alt::before {
  content: '\E3F7';
}

.xgl-safe_two_tone::before {
  content: '\E3F6';
}

.xgl-safe_two_tone::after {
  content: '\E3F7';
  opacity: .2;
}

.xgl-sample::before {
  content: '\E3F8';
}

.xgl-sample_alt::before {
  content: '\E3F9';
}

.xgl-sample_copy::before {
  content: '\EDF4';
}

.xgl-sample_copy_alt::before {
  content: '\EDF5';
}

.xgl-sample_copy_two_tone::before {
  content: '\EDF4';
}

.xgl-sample_copy_two_tone::after {
  content: '\EDF5';
  opacity: .2;
}

.xgl-sample_two_tone::before {
  content: '\E3F8';
}

.xgl-sample_two_tone::after {
  content: '\E3F9';
  opacity: .2;
}

.xgl-saturation::before {
  content: '\EBDF';
}

.xgl-saturation_alt::before {
  content: '\EBE0';
}

.xgl-saturation_two_tone::before {
  content: '\EBDF';
}

.xgl-saturation_two_tone::after {
  content: '\EBE0';
  opacity: .2;
}

.xgl-save::before {
  content: '\E3FA';
}

.xgl-save_alt::before {
  content: '\E3FB';
}

.xgl-save_disabled::before {
  content: '\E3FC';
}

.xgl-save_disabled_alt::before {
  content: '\E3FD';
}

.xgl-save_disabled_two_tone::before {
  content: '\E3FC';
}

.xgl-save_disabled_two_tone::after {
  content: '\E3FD';
  opacity: .2;
}

.xgl-save_two_tone::before {
  content: '\E3FA';
}

.xgl-save_two_tone::after {
  content: '\E3FB';
  opacity: .2;
}

.xgl-saved_jobs::before {
  content: '\E3FE';
}

.xgl-saved_jobs_alt::before {
  content: '\E3FF';
}

.xgl-saved_jobs_two_tone::before {
  content: '\E3FE';
}

.xgl-saved_jobs_two_tone::after {
  content: '\E3FF';
  opacity: .2;
}

.xgl-scale_balance::before {
  content: '\E7EC';
}

.xgl-scale_balance_alt::before {
  content: '\E893';
}

.xgl-scale_balance_two_tone::before {
  content: '\E7EC';
}

.xgl-scale_balance_two_tone::after {
  content: '\E893';
  opacity: .2;
}

.xgl-scale_center::before {
  content: '\EC32';
}

.xgl-scale_down::before {
  content: '\E400';
}

.xgl-scale_image::before {
  content: '\EBB5';
}

.xgl-scale_image_alt::before {
  content: '\EBB6';
}

.xgl-scale_image_two_tone::before {
  content: '\EBB5';
}

.xgl-scale_image_two_tone::after {
  content: '\EBB6';
  opacity: .2;
}

.xgl-scale_up::before {
  content: '\E401';
}

.xgl-scale_up_off::before {
  content: '\EDFE';
}

.xgl-scan::before {
  content: '\E402';
}

.xgl-scan_alt::before {
  content: '\E403';
}

.xgl-scan_sent::before {
  content: '\EC2C';
}

.xgl-scan_sent_alt::before {
  content: '\EC2D';
}

.xgl-scan_sent_two_tone::before {
  content: '\EC2C';
}

.xgl-scan_sent_two_tone::after {
  content: '\EC2D';
  opacity: .2;
}

.xgl-scan_two_tone::before {
  content: '\E402';
}

.xgl-scan_two_tone::after {
  content: '\E403';
  opacity: .2;
}

.xgl-scanner::before {
  content: '\E673';
}

.xgl-scanner_alt::before {
  content: '\E674';
}

.xgl-scanner_bed::before {
  content: '\E78D';
}

.xgl-scanner_bed_alt::before {
  content: '\E78E';
}

.xgl-scanner_bed_full::before {
  content: '\E78F';
}

.xgl-scanner_bed_full_two_tone::before {
  content: '\E78F';
}

.xgl-scanner_bed_full_two_tone::after {
  content: '\E78E';
  opacity: .2;
}

.xgl-scanner_bed_two_tone::before {
  content: '\E78D';
}

.xgl-scanner_bed_two_tone::after {
  content: '\E78E';
  opacity: .2;
}

.xgl-scanner_two_tone::before {
  content: '\E673';
}

.xgl-scanner_two_tone::after {
  content: '\E674';
  opacity: .2;
}

.xgl-scene::before {
  content: '\E93B';
}

.xgl-scene_alt::before {
  content: '\E93C';
}

.xgl-scene_two_tone::before {
  content: '\E93B';
}

.xgl-scene_two_tone::after {
  content: '\E93C';
  opacity: .2;
}

.xgl-school::before {
  content: '\E404';
}

.xgl-school_alt::before {
  content: '\E405';
}

.xgl-school_two_tone::before {
  content: '\E404';
}

.xgl-school_two_tone::after {
  content: '\E405';
  opacity: .2;
}

.xgl-screen_reader::before {
  content: '\E7A8';
}

.xgl-screen_reader_alt::before {
  content: '\E7A9';
}

.xgl-screen_reader_two_tone::before {
  content: '\E7A8';
}

.xgl-screen_reader_two_tone::after {
  content: '\E7A9';
  opacity: .2;
}

.xgl-screen_share::before {
  content: '\E406';
}

.xgl-screen_share_alt::before {
  content: '\E407';
}

.xgl-screen_share_two_tone::before {
  content: '\E406';
}

.xgl-screen_share_two_tone::after {
  content: '\E407';
  opacity: .2;
}

.xgl-screenshot::before {
  content: '\E408';
}

.xgl-screenshot_two_tone::before {
  content: '\E408';
}

.xgl-screenshot_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-scroll_top::before {
  content: '\E409';
}

.xgl-scroll_top_alt::before {
  content: '\E40A';
}

.xgl-scroll_top_two_tone::before {
  content: '\E409';
}

.xgl-scroll_top_two_tone::after {
  content: '\E40A';
  opacity: .2;
}

.xgl-sd_card::before {
  content: '\E40B';
}

.xgl-sd_card_alt::before {
  content: '\E40C';
}

.xgl-sd_card_two_tone::before {
  content: '\E40B';
}

.xgl-sd_card_two_tone::after {
  content: '\E40C';
  opacity: .2;
}

.xgl-search::before {
  content: '\E40D';
}

.xgl-search_alt::before {
  content: '\E40E';
}

.xgl-search_circle::before {
  content: '\E40F';
}

.xgl-search_circle_alt::before {
  content: '\E410';
}

.xgl-search_circle_two_tone::before {
  content: '\E40F';
}

.xgl-search_circle_two_tone::after {
  content: '\E410';
  opacity: .2;
}

.xgl-search_two_tone::before {
  content: '\E40D';
}

.xgl-search_two_tone::after {
  content: '\E40E';
  opacity: .2;
}

.xgl-secure::before {
  content: '\E411';
}

.xgl-secure_alt::before {
  content: '\E412';
}

.xgl-secure_print::before {
  content: '\E413';
}

.xgl-secure_print_alt::before {
  content: '\E414';
}

.xgl-secure_print_two_tone::before {
  content: '\E413';
}

.xgl-secure_print_two_tone::after {
  content: '\E414';
  opacity: .2;
}

.xgl-secure_two_tone::before {
  content: '\E411';
}

.xgl-secure_two_tone::after {
  content: '\E412';
  opacity: .2;
}

.xgl-select::before {
  content: '\E802';
}

.xgl-select_all::before {
  content: '\E415';
}

.xgl-select_all_alt::before {
  content: '\E416';
}

.xgl-select_all_two_tone::before {
  content: '\E415';
}

.xgl-select_all_two_tone::after {
  content: '\E416';
  opacity: .2;
}

.xgl-select_two_tone::before {
  content: '\E802';
}

.xgl-select_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-selection_box::before {
  content: '\ED14';
}

.xgl-selection_box_alt::before {
  content: '\ED15';
}

.xgl-selection_box_two_tone::before {
  content: '\ED14';
}

.xgl-selection_box_two_tone::after {
  content: '\ED15';
  opacity: .2;
}

.xgl-selection_tool::before {
  content: '\ED16';
}

.xgl-selection_tool_alt::before {
  content: '\ED17';
}

.xgl-selection_tool_two_tone::before {
  content: '\ED16';
}

.xgl-selection_tool_two_tone::after {
  content: '\ED17';
  opacity: .2;
}

.xgl-send::before {
  content: '\E417';
}

.xgl-send_alt::before {
  content: '\E418';
}

.xgl-send_rtl::before {
  content: '\E5B0';
}

.xgl-send_rtl_alt::before {
  content: '\E5B1';
}

.xgl-send_rtl_two_tone::before {
  content: '\E5B0';
}

.xgl-send_rtl_two_tone::after {
  content: '\E5B1';
  opacity: .2;
}

.xgl-send_two_tone::before {
  content: '\E417';
}

.xgl-send_two_tone::after {
  content: '\E418';
  opacity: .2;
}

.xgl-sequence_emboss_lef::before {
  content: '\E419';
}

.xgl-sequence_emboss_lef_alt::before {
  content: '\E74E';
}

.xgl-sequence_emboss_lef_two_tone::before {
  content: '\E419';
}

.xgl-sequence_emboss_lef_two_tone::after {
  content: '\E74E';
  opacity: .2;
}

.xgl-sequence_emboss_sef::before {
  content: '\E41A';
}

.xgl-sequence_emboss_sef_alt::before {
  content: '\E74F';
}

.xgl-sequence_emboss_sef_two_tone::before {
  content: '\E41A';
}

.xgl-sequence_emboss_sef_two_tone::after {
  content: '\E74F';
  opacity: .2;
}

.xgl-sequence_lef::before {
  content: '\E41B';
}

.xgl-sequence_lef_alt::before {
  content: '\E750';
}

.xgl-sequence_lef_two_tone::before {
  content: '\E41B';
}

.xgl-sequence_lef_two_tone::after {
  content: '\E750';
  opacity: .2;
}

.xgl-sequence_recycled_lef::before {
  content: '\E41C';
}

.xgl-sequence_recycled_lef_alt::before {
  content: '\E751';
}

.xgl-sequence_recycled_lef_two_tone::before {
  content: '\E41C';
}

.xgl-sequence_recycled_lef_two_tone::after {
  content: '\E751';
  opacity: .2;
}

.xgl-sequence_recycled_sef::before {
  content: '\E41D';
}

.xgl-sequence_recycled_sef_alt::before {
  content: '\E752';
}

.xgl-sequence_recycled_sef_two_tone::before {
  content: '\E41D';
}

.xgl-sequence_recycled_sef_two_tone::after {
  content: '\E752';
  opacity: .2;
}

.xgl-sequence_sef::before {
  content: '\E41E';
}

.xgl-sequence_sef_alt::before {
  content: '\E753';
}

.xgl-sequence_sef_two_tone::before {
  content: '\E41E';
}

.xgl-sequence_sef_two_tone::after {
  content: '\E753';
  opacity: .2;
}

.xgl-server::before {
  content: '\E41F';
}

.xgl-server_alt::before {
  content: '\E420';
}

.xgl-server_lock::before {
  content: '\EB6F';
}

.xgl-server_lock_alt::before {
  content: '\EB70';
}

.xgl-server_lock_two_tone::before {
  content: '\EB6F';
}

.xgl-server_lock_two_tone::after {
  content: '\EB70';
  opacity: .2;
}

.xgl-server_two_tone::before {
  content: '\E41F';
}

.xgl-server_two_tone::after {
  content: '\E420';
  opacity: .2;
}

.xgl-seven_circle::before {
  content: '\EE60';
}

.xgl-seven_circle_alt::before {
  content: '\EE61';
}

.xgl-seven_circle_two_tone::before {
  content: '\EE60';
}

.xgl-seven_circle_two_tone::after {
  content: '\EE61';
  opacity: .2;
}

.xgl-shading_flat::before {
  content: '\ED18';
}

.xgl-shading_flat_alt::before {
  content: '\ED19';
}

.xgl-shading_flat_edge::before {
  content: '\ED1A';
}

.xgl-shading_flat_edge_alt::before {
  content: '\ED1B';
}

.xgl-shading_flat_edge_two_tone::before {
  content: '\ED1A';
}

.xgl-shading_flat_edge_two_tone::after {
  content: '\ED1B';
  opacity: .2;
}

.xgl-shading_flat_two_tone::before {
  content: '\ED18';
}

.xgl-shading_flat_two_tone::after {
  content: '\ED19';
  opacity: .2;
}

.xgl-shading_smooth::before {
  content: '\ED1C';
}

.xgl-shading_smooth_alt::before {
  content: '\ED1D';
}

.xgl-shading_smooth_two_tone::before {
  content: '\ED1C';
}

.xgl-shading_smooth_two_tone::after {
  content: '\ED1D';
  opacity: .2;
}

.xgl-shadow::before {
  content: '\E9BB';
}

.xgl-shadow_alt::before {
  content: '\E9BC';
}

.xgl-shadow_two_tone::before {
  content: '\E9BB';
}

.xgl-shadow_two_tone::after {
  content: '\E9BC';
  opacity: .2;
}

.xgl-shape::before {
  content: '\E8AE';
}

.xgl-shape_add::before {
  content: '\E8B0';
}

.xgl-shape_add_alt::before {
  content: '\E8B1';
}

.xgl-shape_add_two_tone::before {
  content: '\E8B0';
}

.xgl-shape_add_two_tone::after {
  content: '\E8B1';
  opacity: .2;
}

.xgl-shape_alt::before {
  content: '\E8AF';
}

.xgl-shape_two_tone::before {
  content: '\E8AE';
}

.xgl-shape_two_tone::after {
  content: '\E8AF';
  opacity: .2;
}

.xgl-shapes::before {
  content: '\E421';
}

.xgl-shapes_alt::before {
  content: '\E422';
}

.xgl-shapes_two_tone::before {
  content: '\E421';
}

.xgl-shapes_two_tone::after {
  content: '\E422';
  opacity: .2;
}

.xgl-share::before {
  content: '\E423';
}

.xgl-share_alt::before {
  content: '\E7AC';
}

.xgl-share_android::before {
  content: '\E424';
}

.xgl-share_android_alt::before {
  content: '\E425';
}

.xgl-share_android_two_tone::before {
  content: '\E424';
}

.xgl-share_android_two_tone::after {
  content: '\E425';
  opacity: .2;
}

.xgl-share_two_tone::before {
  content: '\E423';
}

.xgl-share_two_tone::after {
  content: '\E7AC';
  opacity: .2;
}

.xgl-sharpness::before {
  content: '\E426';
}

.xgl-sharpness_two_tone::before {
  content: '\E426';
}

.xgl-sharpness_two_tone::after {
  content: '\E0D3';
  opacity: .2;
}

.xgl-sheet_paper_feed::before {
  content: '\E790';
}

.xgl-shield::before {
  content: '\E427';
}

.xgl-shield_accounting::before {
  content: '\E7BE';
}

.xgl-shield_accounting_alt::before {
  content: '\E7BF';
}

.xgl-shield_accounting_two_tone::before {
  content: '\E7BE';
}

.xgl-shield_accounting_two_tone::after {
  content: '\E7BF';
  opacity: .2;
}

.xgl-shield_add::before {
  content: '\E7C0';
}

.xgl-shield_add_alt::before {
  content: '\E7C1';
}

.xgl-shield_add_two_tone::before {
  content: '\E7C0';
}

.xgl-shield_add_two_tone::after {
  content: '\E7C1';
  opacity: .2;
}

.xgl-shield_alt::before {
  content: '\E428';
}

.xgl-shield_auto::before {
  content: '\E7C2';
}

.xgl-shield_auto_alt::before {
  content: '\E7C3';
}

.xgl-shield_auto_two_tone::before {
  content: '\E7C2';
}

.xgl-shield_auto_two_tone::after {
  content: '\E7C3';
  opacity: .2;
}

.xgl-shield_defense::before {
  content: '\E7C4';
}

.xgl-shield_defense_alt::before {
  content: '\E7C5';
}

.xgl-shield_defense_two_tone::before {
  content: '\E7C4';
}

.xgl-shield_defense_two_tone::after {
  content: '\E7C5';
  opacity: .2;
}

.xgl-shield_disabled::before {
  content: '\E7C6';
}

.xgl-shield_disabled_alt::before {
  content: '\E7C7';
}

.xgl-shield_disabled_two_tone::before {
  content: '\E7C6';
}

.xgl-shield_disabled_two_tone::after {
  content: '\E7C7';
  opacity: .2;
}

.xgl-shield_download::before {
  content: '\E7C8';
}

.xgl-shield_download_alt::before {
  content: '\E7C9';
}

.xgl-shield_download_two_tone::before {
  content: '\E7C8';
}

.xgl-shield_download_two_tone::after {
  content: '\E7C9';
  opacity: .2;
}

.xgl-shield_education::before {
  content: '\E7E0';
}

.xgl-shield_education_alt::before {
  content: '\E7E1';
}

.xgl-shield_education_two_tone::before {
  content: '\E7E0';
}

.xgl-shield_education_two_tone::after {
  content: '\E7E1';
  opacity: .2;
}

.xgl-shield_favorite::before {
  content: '\E7CA';
}

.xgl-shield_favorite_alt::before {
  content: '\E7CB';
}

.xgl-shield_favorite_two_tone::before {
  content: '\E7CA';
}

.xgl-shield_favorite_two_tone::after {
  content: '\E7CB';
  opacity: .2;
}

.xgl-shield_file::before {
  content: '\EE17';
}

.xgl-shield_file_alt::before {
  content: '\EE18';
}

.xgl-shield_file_two_tone::before {
  content: '\EE17';
}

.xgl-shield_file_two_tone::after {
  content: '\EE18';
  opacity: .2;
}

.xgl-shield_gears::before {
  content: '\EAC6';
}

.xgl-shield_gears_alt::before {
  content: '\EAC7';
}

.xgl-shield_gears_two_tone::before {
  content: '\EAC6';
}

.xgl-shield_gears_two_tone::after {
  content: '\EAC7';
  opacity: .2;
}

.xgl-shield_government::before {
  content: '\E7E2';
}

.xgl-shield_government_alt::before {
  content: '\E7E3';
}

.xgl-shield_government_two_tone::before {
  content: '\E7E2';
}

.xgl-shield_government_two_tone::after {
  content: '\E7E3';
  opacity: .2;
}

.xgl-shield_half::before {
  content: '\E429';
}

.xgl-shield_half_two_tone::before {
  content: '\E429';
}

.xgl-shield_half_two_tone::after {
  content: '\E428';
  opacity: .2;
}

.xgl-shield_healthcare::before {
  content: '\E7E4';
}

.xgl-shield_healthcare_alt::before {
  content: '\E7E5';
}

.xgl-shield_healthcare_two_tone::before {
  content: '\E7E4';
}

.xgl-shield_healthcare_two_tone::after {
  content: '\E7E5';
  opacity: .2;
}

.xgl-shield_heart::before {
  content: '\E7CC';
}

.xgl-shield_heart_alt::before {
  content: '\E7CD';
}

.xgl-shield_heart_two_tone::before {
  content: '\E7CC';
}

.xgl-shield_heart_two_tone::after {
  content: '\E7CD';
  opacity: .2;
}

.xgl-shield_hot::before {
  content: '\E7CE';
}

.xgl-shield_hot_alt::before {
  content: '\E7CF';
}

.xgl-shield_hot_two_tone::before {
  content: '\E7CE';
}

.xgl-shield_hot_two_tone::after {
  content: '\E7CF';
  opacity: .2;
}

.xgl-shield_list::before {
  content: '\EAC8';
}

.xgl-shield_list_alt::before {
  content: '\EAC9';
}

.xgl-shield_list_two_tone::before {
  content: '\EAC8';
}

.xgl-shield_list_two_tone::after {
  content: '\EAC9';
  opacity: .2;
}

.xgl-shield_lock::before {
  content: '\E7D0';
}

.xgl-shield_lock_alt::before {
  content: '\E7D1';
}

.xgl-shield_lock_two_tone::before {
  content: '\E7D0';
}

.xgl-shield_lock_two_tone::after {
  content: '\E7D1';
  opacity: .2;
}

.xgl-shield_locked::before {
  content: '\E7D2';
}

.xgl-shield_locked_alt::before {
  content: '\E7D3';
}

.xgl-shield_locked_two_tone::before {
  content: '\E7D2';
}

.xgl-shield_locked_two_tone::after {
  content: '\E7D3';
  opacity: .2;
}

.xgl-shield_manufacturing::before {
  content: '\E7E6';
}

.xgl-shield_manufacturing_alt::before {
  content: '\E7E7';
}

.xgl-shield_manufacturing_two_tone::before {
  content: '\E7E6';
}

.xgl-shield_manufacturing_two_tone::after {
  content: '\E7E7';
  opacity: .2;
}

.xgl-shield_marketing::before {
  content: '\E7E8';
}

.xgl-shield_marketing_alt::before {
  content: '\E7E9';
}

.xgl-shield_marketing_two_tone::before {
  content: '\E7E8';
}

.xgl-shield_marketing_two_tone::after {
  content: '\E7E9';
  opacity: .2;
}

.xgl-shield_medical::before {
  content: '\E7D4';
}

.xgl-shield_medical_alt::before {
  content: '\E7D5';
}

.xgl-shield_medical_two_tone::before {
  content: '\E7D4';
}

.xgl-shield_medical_two_tone::after {
  content: '\E7D5';
  opacity: .2;
}

.xgl-shield_permissions::before {
  content: '\EB76';
}

.xgl-shield_permissions_alt::before {
  content: '\EB77';
}

.xgl-shield_permissions_two_tone::before {
  content: '\EB76';
}

.xgl-shield_permissions_two_tone::after {
  content: '\EB77';
  opacity: .2;
}

.xgl-shield_personal::before {
  content: '\E7D6';
}

.xgl-shield_personal_alt::before {
  content: '\E7D7';
}

.xgl-shield_personal_two_tone::before {
  content: '\E7D6';
}

.xgl-shield_personal_two_tone::after {
  content: '\E7D7';
  opacity: .2;
}

.xgl-shield_print::before {
  content: '\EACA';
}

.xgl-shield_print_alt::before {
  content: '\EACB';
}

.xgl-shield_print_two_tone::before {
  content: '\EACA';
}

.xgl-shield_print_two_tone::after {
  content: '\EACB';
  opacity: .2;
}

.xgl-shield_prohibit::before {
  content: '\E7D8';
}

.xgl-shield_prohibit_alt::before {
  content: '\E7D9';
}

.xgl-shield_prohibit_two_tone::before {
  content: '\E7D8';
}

.xgl-shield_prohibit_two_tone::after {
  content: '\E7D9';
  opacity: .2;
}

.xgl-shield_public::before {
  content: '\E7DA';
}

.xgl-shield_public_alt::before {
  content: '\E7DB';
}

.xgl-shield_public_two_tone::before {
  content: '\E7DA';
}

.xgl-shield_public_two_tone::after {
  content: '\E7DB';
  opacity: .2;
}

.xgl-shield_receipt::before {
  content: '\EACC';
}

.xgl-shield_receipt_alt::before {
  content: '\EACD';
}

.xgl-shield_receipt_two_tone::before {
  content: '\EACC';
}

.xgl-shield_receipt_two_tone::after {
  content: '\EACD';
  opacity: .2;
}

.xgl-shield_retail::before {
  content: '\E7EA';
}

.xgl-shield_retail_alt::before {
  content: '\E7EB';
}

.xgl-shield_retail_two_tone::before {
  content: '\E7EA';
}

.xgl-shield_retail_two_tone::after {
  content: '\E7EB';
  opacity: .2;
}

.xgl-shield_scan::before {
  content: '\EACE';
}

.xgl-shield_scan_alt::before {
  content: '\EACF';
}

.xgl-shield_scan_two_tone::before {
  content: '\EACE';
}

.xgl-shield_scan_two_tone::after {
  content: '\EACF';
  opacity: .2;
}

.xgl-shield_subtract::before {
  content: '\EA27';
}

.xgl-shield_subtract_alt::before {
  content: '\EA28';
}

.xgl-shield_subtract_two_tone::before {
  content: '\EA27';
}

.xgl-shield_subtract_two_tone::after {
  content: '\EA28';
  opacity: .2;
}

.xgl-shield_two_tone::before {
  content: '\E427';
}

.xgl-shield_two_tone::after {
  content: '\E428';
  opacity: .2;
}

.xgl-shield_upload::before {
  content: '\E7DC';
}

.xgl-shield_upload_alt::before {
  content: '\E7DD';
}

.xgl-shield_upload_two_tone::before {
  content: '\E7DC';
}

.xgl-shield_upload_two_tone::after {
  content: '\E7DD';
  opacity: .2;
}

.xgl-shield_warning::before {
  content: '\E7DE';
}

.xgl-shield_warning_alt::before {
  content: '\E7DF';
}

.xgl-shield_warning_two_tone::before {
  content: '\E7DE';
}

.xgl-shield_warning_two_tone::after {
  content: '\E7DF';
  opacity: .2;
}

.xgl-shift::before {
  content: '\E42A';
}

.xgl-shifted::before {
  content: '\E42B';
}

.xgl-shopping_bag::before {
  content: '\E42C';
}

.xgl-shopping_bag_alt::before {
  content: '\E42D';
}

.xgl-shopping_bag_disabled::before {
  content: '\E42E';
}

.xgl-shopping_bag_disabled_alt::before {
  content: '\E42F';
}

.xgl-shopping_bag_disabled_two_tone::before {
  content: '\E42E';
}

.xgl-shopping_bag_disabled_two_tone::after {
  content: '\E42F';
  opacity: .2;
}

.xgl-shopping_bag_overlay::before {
  content: '\E430';
}

.xgl-shopping_bag_two_tone::before {
  content: '\E42C';
}

.xgl-shopping_bag_two_tone::after {
  content: '\E42D';
  opacity: .2;
}

.xgl-short_edge_feed::before {
  content: '\E431';
}

.xgl-short_edge_feed_alt::before {
  content: '\E432';
}

.xgl-short_edge_feed_two_tone::before {
  content: '\E431';
}

.xgl-short_edge_feed_two_tone::after {
  content: '\E432';
  opacity: .2;
}

.xgl-sign_post::before {
  content: '\E433';
}

.xgl-sign_post_alt::before {
  content: '\E434';
}

.xgl-sign_post_two_tone::before {
  content: '\E433';
}

.xgl-sign_post_two_tone::after {
  content: '\E434';
  opacity: .2;
}

.xgl-signature::before {
  content: '\E435';
}

.xgl-signature_tag_left::before {
  content: '\E8CD';
}

.xgl-signature_tag_left_alt::before {
  content: '\E8CE';
}

.xgl-signature_tag_left_two_tone::before {
  content: '\E8CD';
}

.xgl-signature_tag_left_two_tone::after {
  content: '\E8CE';
  opacity: .2;
}

.xgl-signature_tag_right::before {
  content: '\E8CB';
}

.xgl-signature_tag_right_alt::before {
  content: '\E8CC';
}

.xgl-signature_tag_right_two_tone::before {
  content: '\E8CB';
}

.xgl-signature_tag_right_two_tone::after {
  content: '\E8CC';
  opacity: .2;
}

.xgl-sim_card::before {
  content: '\E436';
}

.xgl-sim_card_alt::before {
  content: '\E437';
}

.xgl-sim_card_two_tone::before {
  content: '\E436';
}

.xgl-sim_card_two_tone::after {
  content: '\E437';
  opacity: .2;
}

.xgl-simplex::before {
  content: '\E438';
}

.xgl-simplex_alt::before {
  content: '\E439';
}

.xgl-simplex_two_tone::before {
  content: '\E438';
}

.xgl-simplex_two_tone::after {
  content: '\E439';
  opacity: .2;
}

.xgl-six_circle::before {
  content: '\EE5E';
}

.xgl-six_circle_alt::before {
  content: '\EE5F';
}

.xgl-six_circle_two_tone::before {
  content: '\EE5E';
}

.xgl-six_circle_two_tone::after {
  content: '\EE5F';
  opacity: .2;
}

.xgl-sketch::before {
  content: '\E8A8';
}

.xgl-sketch_alt::before {
  content: '\E8A9';
}

.xgl-sketch_two_tone::before {
  content: '\E8A8';
}

.xgl-sketch_two_tone::after {
  content: '\E8A9';
  opacity: .2;
}

.xgl-skew::before {
  content: '\ED1E';
}

.xgl-skew_alt::before {
  content: '\ED1F';
}

.xgl-skew_two_tone::before {
  content: '\ED1E';
}

.xgl-skew_two_tone::after {
  content: '\ED1F';
  opacity: .2;
}

.xgl-skip_backward::before {
  content: '\E43A';
}

.xgl-skip_backward_alt::before {
  content: '\E43B';
}

.xgl-skip_backward_fifteen::before {
  content: '\E67B';
}

.xgl-skip_backward_five::before {
  content: '\E679';
}

.xgl-skip_backward_ten::before {
  content: '\E67A';
}

.xgl-skip_backward_two_tone::before {
  content: '\E43A';
}

.xgl-skip_backward_two_tone::after {
  content: '\E43B';
  opacity: .2;
}

.xgl-skip_forward::before {
  content: '\E43C';
}

.xgl-skip_forward_alt::before {
  content: '\E43D';
}

.xgl-skip_forward_fifteen::before {
  content: '\E67E';
}

.xgl-skip_forward_five::before {
  content: '\E67C';
}

.xgl-skip_forward_ten::before {
  content: '\E67D';
}

.xgl-skip_forward_two_tone::before {
  content: '\E43C';
}

.xgl-skip_forward_two_tone::after {
  content: '\E43D';
  opacity: .2;
}

.xgl-sleep::before {
  content: '\E43E';
}

.xgl-sleep_alt::before {
  content: '\E43F';
}

.xgl-sleep_auto::before {
  content: '\EA7B';
}

.xgl-sleep_auto_alt::before {
  content: '\EA7C';
}

.xgl-sleep_auto_two_tone::before {
  content: '\EA7B';
}

.xgl-sleep_auto_two_tone::after {
  content: '\EA7C';
  opacity: .2;
}

.xgl-sleep_two_tone::before {
  content: '\E43E';
}

.xgl-sleep_two_tone::after {
  content: '\E43F';
  opacity: .2;
}

.xgl-smart_card::before {
  content: '\E440';
}

.xgl-smart_card_two_tone::before {
  content: '\E440';
}

.xgl-smart_card_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-snowflake::before {
  content: '\E63F';
}

.xgl-sort::before {
  content: '\E441';
}

.xgl-sort_a_z::before {
  content: '\E442';
}

.xgl-sort_ascending::before {
  content: '\E443';
}

.xgl-sort_big_small::before {
  content: '\E444';
}

.xgl-sort_descending::before {
  content: '\E445';
}

.xgl-sort_down::before {
  content: '\E446';
}

.xgl-sort_left::before {
  content: '\E447';
}

.xgl-sort_right::before {
  content: '\E448';
}

.xgl-sort_small_big::before {
  content: '\E449';
}

.xgl-sort_up::before {
  content: '\E44A';
}

.xgl-sort_z_a::before {
  content: '\E44B';
}

.xgl-space_bar::before {
  content: '\E44C';
}

.xgl-special_pages::before {
  content: '\EC2A';
}

.xgl-special_pages_alt::before {
  content: '\EC2B';
}

.xgl-special_pages_two_tone::before {
  content: '\EC2A';
}

.xgl-special_pages_two_tone::after {
  content: '\EC2B';
  opacity: .2;
}

.xgl-speech::before {
  content: '\E44D';
}

.xgl-speech_rectangle::before {
  content: '\E44E';
}

.xgl-speech_rectangle_alt::before {
  content: '\E44F';
}

.xgl-speech_rectangle_two_tone::before {
  content: '\E44E';
}

.xgl-speech_rectangle_two_tone::after {
  content: '\E44F';
  opacity: .2;
}

.xgl-speed_ipv4::before {
  content: '\EBFF';
}

.xgl-speed_ipv4_alt::before {
  content: '\EC00';
}

.xgl-speed_ipv4_two_tone::before {
  content: '\EBFF';
}

.xgl-speed_ipv4_two_tone::after {
  content: '\EC00';
  opacity: .2;
}

.xgl-speed_ipv6::before {
  content: '\EC01';
}

.xgl-speed_ipv6_alt::before {
  content: '\EC02';
}

.xgl-speed_ipv6_two_tone::before {
  content: '\EC01';
}

.xgl-speed_ipv6_two_tone::after {
  content: '\EC02';
  opacity: .2;
}

.xgl-spell_check::before {
  content: '\E894';
}

.xgl-spell_check_alt::before {
  content: '\E895';
}

.xgl-spell_check_two_tone::before {
  content: '\E894';
}

.xgl-spell_check_two_tone::after {
  content: '\E895';
  opacity: .2;
}

.xgl-spinner::before {
  content: '\E450';
}

.xgl-split::before {
  content: '\E451';
}

.xgl-split_all::before {
  content: '\EC65';
}

.xgl-split_all_alt::before {
  content: '\EC66';
}

.xgl-split_all_two_tone::before {
  content: '\EC65';
}

.xgl-split_all_two_tone::after {
  content: '\EC66';
  opacity: .2;
}

.xgl-split_fit::before {
  content: '\EC63';
}

.xgl-split_fit_alt::before {
  content: '\EC64';
}

.xgl-split_fit_two_tone::before {
  content: '\EC63';
}

.xgl-split_fit_two_tone::after {
  content: '\EC64';
  opacity: .2;
}

.xgl-split_square::before {
  content: '\EDE4';
}

.xgl-split_square_alt::before {
  content: '\EDE5';
}

.xgl-split_square_two_tone::before {
  content: '\EDE4';
}

.xgl-split_square_two_tone::after {
  content: '\EDE5';
  opacity: .2;
}

.xgl-spread::before {
  content: '\E900';
}

.xgl-spread_alt::before {
  content: '\E912';
}

.xgl-spread_two_tone::before {
  content: '\E900';
}

.xgl-spread_two_tone::after {
  content: '\E912';
  opacity: .2;
}

.xgl-springboard::before {
  content: '\E452';
}

.xgl-springboard_alt::before {
  content: '\E453';
}

.xgl-springboard_destination::before {
  content: '\EDE6';
}

.xgl-springboard_destination_alt::before {
  content: '\EDE7';
}

.xgl-springboard_destination_two_tone::before {
  content: '\EDE6';
}

.xgl-springboard_destination_two_tone::after {
  content: '\EDE7';
  opacity: .2;
}

.xgl-springboard_source::before {
  content: '\EDE8';
}

.xgl-springboard_source_alt::before {
  content: '\EDE9';
}

.xgl-springboard_source_two_tone::before {
  content: '\EDE8';
}

.xgl-springboard_source_two_tone::after {
  content: '\EDE9';
  opacity: .2;
}

.xgl-springboard_two_tone::before {
  content: '\E452';
}

.xgl-springboard_two_tone::after {
  content: '\E453';
  opacity: .2;
}

.xgl-square_bkgd::before {
  content: '\E454';
}

.xgl-stamp::before {
  content: '\EC04';
}

.xgl-stamp_alt::before {
  content: '\EC05';
}

.xgl-stamp_prohibit::before {
  content: '\EC03';
}

.xgl-stamp_prohibit_alt::before {
  content: '\EC06';
}

.xgl-stamp_prohibit_two_tone::before {
  content: '\EC03';
}

.xgl-stamp_prohibit_two_tone::after {
  content: '\EC06';
  opacity: .2;
}

.xgl-stamp_two_tone::before {
  content: '\EC04';
}

.xgl-stamp_two_tone::after {
  content: '\EC05';
  opacity: .2;
}

.xgl-staple_horizontal_top::before {
  content: '\E455';
}

.xgl-staple_jam::before {
  content: '\E791';
}

.xgl-staple_none::before {
  content: '\E456';
}

.xgl-staple_two_horizontal_top::before {
  content: '\E457';
}

.xgl-staple_two_vertical_left::before {
  content: '\E458';
}

.xgl-staple_two_vertical_right::before {
  content: '\E459';
}

.xgl-staple_upper_left::before {
  content: '\E45A';
}

.xgl-staple_upper_right::before {
  content: '\E45B';
}

.xgl-staple_vertical_left::before {
  content: '\E45C';
}

.xgl-staple_vertical_right::before {
  content: '\E45D';
}

.xgl-stapling::before {
  content: '\E45E';
}

.xgl-stapling_alt::before {
  content: '\E45F';
}

.xgl-stapling_disabled::before {
  content: '\E9BD';
}

.xgl-stapling_disabled_alt::before {
  content: '\E9BE';
}

.xgl-stapling_disabled_two_tone::before {
  content: '\E9BD';
}

.xgl-stapling_disabled_two_tone::after {
  content: '\E9BE';
  opacity: .2;
}

.xgl-stapling_two_tone::before {
  content: '\E45E';
}

.xgl-stapling_two_tone::after {
  content: '\E45F';
  opacity: .2;
}

.xgl-star::before {
  content: '\E460';
}

.xgl-star_add::before {
  content: '\E8C5';
}

.xgl-star_add_alt::before {
  content: '\E8C6';
}

.xgl-star_add_two_tone::before {
  content: '\E8C5';
}

.xgl-star_add_two_tone::after {
  content: '\E8C6';
  opacity: .2;
}

.xgl-star_alt::before {
  content: '\E461';
}

.xgl-star_disabled::before {
  content: '\E462';
}

.xgl-star_disabled_alt::before {
  content: '\E463';
}

.xgl-star_disabled_two_tone::before {
  content: '\E462';
}

.xgl-star_disabled_two_tone::after {
  content: '\E463';
  opacity: .2;
}

.xgl-star_half::before {
  content: '\E464';
}

.xgl-star_half_rtl::before {
  content: '\E3E7';
}

.xgl-star_half_rtl_two_tone::before {
  content: '\E3E7';
}

.xgl-star_half_rtl_two_tone::after {
  content: '\E461';
  opacity: .2;
}

.xgl-star_half_two_tone::before {
  content: '\E464';
}

.xgl-star_half_two_tone::after {
  content: '\E461';
  opacity: .2;
}

.xgl-star_subtract::before {
  content: '\E8C7';
}

.xgl-star_subtract_alt::before {
  content: '\E8C8';
}

.xgl-star_subtract_two_tone::before {
  content: '\E8C7';
}

.xgl-star_subtract_two_tone::after {
  content: '\E8C8';
  opacity: .2;
}

.xgl-star_two_tone::before {
  content: '\E460';
}

.xgl-star_two_tone::after {
  content: '\E461';
  opacity: .2;
}

.xgl-starburst::before {
  content: '\E465';
}

.xgl-starburst_alt::before {
  content: '\E466';
}

.xgl-starburst_two_tone::before {
  content: '\E465';
}

.xgl-starburst_two_tone::after {
  content: '\E466';
  opacity: .2;
}

.xgl-start::before {
  content: '\E467';
}

.xgl-start_alt::before {
  content: '\E468';
}

.xgl-start_two_tone::before {
  content: '\E467';
}

.xgl-start_two_tone::after {
  content: '\E468';
  opacity: .2;
}

.xgl-startup_page::before {
  content: '\EBFB';
}

.xgl-startup_page_alt::before {
  content: '\EBFC';
}

.xgl-startup_page_two_tone::before {
  content: '\EBFB';
}

.xgl-startup_page_two_tone::after {
  content: '\EBFC';
  opacity: .2;
}

.xgl-stethoscope::before {
  content: '\E61F';
}

.xgl-sticky_note::before {
  content: '\E62D';
}

.xgl-sticky_note_alt::before {
  content: '\E62E';
}

.xgl-sticky_note_two_tone::before {
  content: '\E62D';
}

.xgl-sticky_note_two_tone::after {
  content: '\E62E';
  opacity: .2;
}

.xgl-stop::before {
  content: '\E469';
}

.xgl-stop_action::before {
  content: '\E63E';
}

.xgl-stop_action_two_tone::before {
  content: '\E63E';
}

.xgl-stop_action_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-stop_alt::before {
  content: '\E46A';
}

.xgl-stop_two_tone::before {
  content: '\E469';
}

.xgl-stop_two_tone::after {
  content: '\E46A';
  opacity: .2;
}

.xgl-stopwatch::before {
  content: '\E46B';
}

.xgl-stopwatch_alt::before {
  content: '\E46C';
}

.xgl-stopwatch_empty::before {
  content: '\EC9B';
}

.xgl-stopwatch_empty_alt::before {
  content: '\EC9C';
}

.xgl-stopwatch_empty_two_tone::before {
  content: '\EC9B';
}

.xgl-stopwatch_empty_two_tone::after {
  content: '\EC9C';
  opacity: .2;
}

.xgl-stopwatch_two_tone::before {
  content: '\E46B';
}

.xgl-stopwatch_two_tone::after {
  content: '\E46C';
  opacity: .2;
}

.xgl-store::before {
  content: '\E46D';
}

.xgl-store_alt::before {
  content: '\E46E';
}

.xgl-store_two_tone::before {
  content: '\E46D';
}

.xgl-store_two_tone::after {
  content: '\E46E';
  opacity: .2;
}

.xgl-straighten::before {
  content: '\E46F';
}

.xgl-straighten_alt::before {
  content: '\EAE4';
}

.xgl-straighten_two_tone::before {
  content: '\E46F';
}

.xgl-straighten_two_tone::after {
  content: '\EAE4';
  opacity: .2;
}

.xgl-strikethrough::before {
  content: '\E470';
}

.xgl-submit_file::before {
  content: '\E471';
}

.xgl-submit_file_alt::before {
  content: '\E472';
}

.xgl-submit_file_rtl::before {
  content: '\E5B2';
}

.xgl-submit_file_rtl_alt::before {
  content: '\E5B3';
}

.xgl-submit_file_rtl_two_tone::before {
  content: '\E5B2';
}

.xgl-submit_file_rtl_two_tone::after {
  content: '\E5B3';
  opacity: .2;
}

.xgl-submit_file_two_tone::before {
  content: '\E471';
}

.xgl-submit_file_two_tone::after {
  content: '\E472';
  opacity: .2;
}

.xgl-subscript::before {
  content: '\E5BA';
}

.xgl-subtask::before {
  content: '\E9BF';
}

.xgl-subtask_alt::before {
  content: '\E9C0';
}

.xgl-subtask_two_tone::before {
  content: '\E9BF';
}

.xgl-subtask_two_tone::after {
  content: '\E9C0';
  opacity: .2;
}

.xgl-subtract_circle::before {
  content: '\E473';
}

.xgl-subtract_circle_alt::before {
  content: '\E474';
}

.xgl-subtract_circle_two_tone::before {
  content: '\E473';
}

.xgl-subtract_circle_two_tone::after {
  content: '\E474';
  opacity: .2;
}

.xgl-subtract_square::before {
  content: '\E475';
}

.xgl-subtract_square_alt::before {
  content: '\E476';
}

.xgl-subtract_square_two_tone::before {
  content: '\E475';
}

.xgl-subtract_square_two_tone::after {
  content: '\E476';
  opacity: .2;
}

.xgl-success::before {
  content: '\E9C1';
}

.xgl-success_alt::before {
  content: '\E9C2';
}

.xgl-success_stack_bkgd::before {
  content: '\E9C3';
}

.xgl-success_stack_frgd::before {
  content: '\E9C4';
}

.xgl-success_stack_mdgd::before {
  content: '\E9C5';
}

.xgl-success_two_tone::before {
  content: '\E9C1';
}

.xgl-success_two_tone::after {
  content: '\E9C2';
  opacity: .2;
}

.xgl-suggestion::before {
  content: '\EAD8';
}

.xgl-suggestion_alt::before {
  content: '\EAD9';
}

.xgl-suggestion_email::before {
  content: '\EAE0';
}

.xgl-suggestion_email_alt::before {
  content: '\EAE1';
}

.xgl-suggestion_email_two_tone::before {
  content: '\EAE0';
}

.xgl-suggestion_email_two_tone::after {
  content: '\EAE1';
  opacity: .2;
}

.xgl-suggestion_file::before {
  content: '\EADA';
}

.xgl-suggestion_file_alt::before {
  content: '\EADB';
}

.xgl-suggestion_file_two_tone::before {
  content: '\EADA';
}

.xgl-suggestion_file_two_tone::after {
  content: '\EADB';
  opacity: .2;
}

.xgl-suggestion_manage::before {
  content: '\EC73';
}

.xgl-suggestion_manage_alt::before {
  content: '\EC74';
}

.xgl-suggestion_manage_two_tone::before {
  content: '\EC73';
}

.xgl-suggestion_manage_two_tone::after {
  content: '\EC74';
  opacity: .2;
}

.xgl-suggestion_phone::before {
  content: '\EADE';
}

.xgl-suggestion_phone_alt::before {
  content: '\EADF';
}

.xgl-suggestion_phone_two_tone::before {
  content: '\EADE';
}

.xgl-suggestion_phone_two_tone::after {
  content: '\EADF';
  opacity: .2;
}

.xgl-suggestion_scan::before {
  content: '\EADC';
}

.xgl-suggestion_scan_alt::before {
  content: '\EADD';
}

.xgl-suggestion_scan_two_tone::before {
  content: '\EADC';
}

.xgl-suggestion_scan_two_tone::after {
  content: '\EADD';
  opacity: .2;
}

.xgl-suggestion_two_tone::before {
  content: '\EAD8';
}

.xgl-suggestion_two_tone::after {
  content: '\EAD9';
  opacity: .2;
}

.xgl-sunburst::before {
  content: '\E927';
}

.xgl-sunburst_alt::before {
  content: '\E928';
}

.xgl-sunburst_compare::before {
  content: '\E92B';
}

.xgl-sunburst_compare_alt::before {
  content: '\E92C';
}

.xgl-sunburst_compare_two_tone::before {
  content: '\E92B';
}

.xgl-sunburst_compare_two_tone::after {
  content: '\E92C';
  opacity: .2;
}

.xgl-sunburst_progress::before {
  content: '\E929';
}

.xgl-sunburst_progress_alt::before {
  content: '\E92A';
}

.xgl-sunburst_progress_two_tone::before {
  content: '\E929';
}

.xgl-sunburst_progress_two_tone::after {
  content: '\E92A';
  opacity: .2;
}

.xgl-sunburst_two_tone::before {
  content: '\E927';
}

.xgl-sunburst_two_tone::after {
  content: '\E928';
  opacity: .2;
}

.xgl-sunshine::before {
  content: '\E477';
}

.xgl-sunshine_alt::before {
  content: '\E478';
}

.xgl-sunshine_two_tone::before {
  content: '\E477';
}

.xgl-sunshine_two_tone::after {
  content: '\E478';
  opacity: .2;
}

.xgl-superscript::before {
  content: '\E5B9';
}

.xgl-support::before {
  content: '\E479';
}

.xgl-support_alt::before {
  content: '\E47A';
}

.xgl-support_two_tone::before {
  content: '\E479';
}

.xgl-support_two_tone::after {
  content: '\E47A';
  opacity: .2;
}

.xgl-supports::before {
  content: '\EE48';
}

.xgl-supports_alt::before {
  content: '\EE49';
}

.xgl-supports_two_tone::before {
  content: '\EE48';
}

.xgl-supports_two_tone::after {
  content: '\EE49';
  opacity: .2;
}

.xgl-swipe_card::before {
  content: '\E47B';
}

.xgl-swipe_card_two_tone::before {
  content: '\E47B';
}

.xgl-swipe_card_two_tone::after {
  content: '\EC80';
  opacity: .2;
}

.xgl-sync::before {
  content: '\E47C';
}

.xgl-sync_alert::before {
  content: '\E47D';
}

.xgl-sync_check::before {
  content: '\E47E';
}

.xgl-sync_fault::before {
  content: '\E47F';
}

.xgl-tab_backward::before {
  content: '\E480';
}

.xgl-tab_forward::before {
  content: '\E481';
}

.xgl-tables::before {
  content: '\E482';
}

.xgl-tables_alt::before {
  content: '\E483';
}

.xgl-tables_off::before {
  content: '\EDFF';
}

.xgl-tables_off_alt::before {
  content: '\EE00';
}

.xgl-tables_off_two_tone::before {
  content: '\EDFF';
}

.xgl-tables_off_two_tone::after {
  content: '\EE00';
  opacity: .2;
}

.xgl-tables_two_tone::before {
  content: '\E482';
}

.xgl-tables_two_tone::after {
  content: '\E483';
  opacity: .2;
}

.xgl-tablet::before {
  content: '\E484';
}

.xgl-tablet_alt::before {
  content: '\E485';
}

.xgl-tablet_two_tone::before {
  content: '\E484';
}

.xgl-tablet_two_tone::after {
  content: '\E485';
  opacity: .2;
}

.xgl-tag::before {
  content: '\E486';
}

.xgl-tag_alt::before {
  content: '\E487';
}

.xgl-tag_two_tone::before {
  content: '\E486';
}

.xgl-tag_two_tone::after {
  content: '\E487';
  opacity: .2;
}

.xgl-tags::before {
  content: '\E488';
}

.xgl-tags_alt::before {
  content: '\E489';
}

.xgl-tags_two_tone::before {
  content: '\E488';
}

.xgl-tags_two_tone::after {
  content: '\E489';
  opacity: .2;
}

.xgl-tap::before {
  content: '\E48A';
}

.xgl-tap_alt::before {
  content: '\E48B';
}

.xgl-tap_down::before {
  content: '\E48C';
}

.xgl-tap_down_alt::before {
  content: '\E48D';
}

.xgl-tap_down_two_tone::before {
  content: '\E48C';
}

.xgl-tap_down_two_tone::after {
  content: '\E48D';
  opacity: .2;
}

.xgl-tap_left::before {
  content: '\E48E';
}

.xgl-tap_left_alt::before {
  content: '\E48F';
}

.xgl-tap_left_two_tone::before {
  content: '\E48E';
}

.xgl-tap_left_two_tone::after {
  content: '\E48F';
  opacity: .2;
}

.xgl-tap_right::before {
  content: '\E490';
}

.xgl-tap_right_alt::before {
  content: '\E491';
}

.xgl-tap_right_two_tone::before {
  content: '\E490';
}

.xgl-tap_right_two_tone::after {
  content: '\E491';
  opacity: .2;
}

.xgl-tap_two::before {
  content: '\E651';
}

.xgl-tap_two_alt::before {
  content: '\E652';
}

.xgl-tap_two_tone::before {
  content: '\E48A';
}

.xgl-tap_two_tone::after {
  content: '\E48B';
  opacity: .2;
}

.xgl-tap_two_two_tone::before {
  content: '\E651';
}

.xgl-tap_two_two_tone::after {
  content: '\E652';
  opacity: .2;
}

.xgl-tap_up::before {
  content: '\E492';
}

.xgl-tap_up_alt::before {
  content: '\E493';
}

.xgl-tap_up_two_tone::before {
  content: '\E492';
}

.xgl-tap_up_two_tone::after {
  content: '\E493';
  opacity: .2;
}

.xgl-target::before {
  content: '\E494';
}

.xgl-target_two_tone::before {
  content: '\E494';
}

.xgl-target_two_tone::after {
  content: '\E0DF';
  opacity: .2;
}

.xgl-team::before {
  content: '\E495';
}

.xgl-team_alt::before {
  content: '\E496';
}

.xgl-team_two_tone::before {
  content: '\E495';
}

.xgl-team_two_tone::after {
  content: '\E496';
  opacity: .2;
}

.xgl-teamwork::before {
  content: '\E8D1';
}

.xgl-teamwork_alt::before {
  content: '\E8D2';
}

.xgl-teamwork_two_tone::before {
  content: '\E8D1';
}

.xgl-teamwork_two_tone::after {
  content: '\E8D2';
  opacity: .2;
}

.xgl-template_update::before {
  content: '\EBF3';
}

.xgl-template_update_alt::before {
  content: '\EBF4';
}

.xgl-template_update_two_tone::before {
  content: '\EBF3';
}

.xgl-template_update_two_tone::after {
  content: '\EBF4';
  opacity: .2;
}

.xgl-ten_circle::before {
  content: '\EE68';
}

.xgl-ten_circle_alt::before {
  content: '\EE69';
}

.xgl-ten_circle_two_tone::before {
  content: '\EE68';
}

.xgl-ten_circle_two_tone::after {
  content: '\EE69';
  opacity: .2;
}

.xgl-terms::before {
  content: '\E497';
}

.xgl-terms_alt::before {
  content: '\E498';
}

.xgl-terms_two_tone::before {
  content: '\E497';
}

.xgl-terms_two_tone::after {
  content: '\E498';
  opacity: .2;
}

.xgl-text::before {
  content: '\E499';
}

.xgl-text_align_left::before {
  content: '\E49A';
}

.xgl-text_align_right::before {
  content: '\E49B';
}

.xgl-text_box::before {
  content: '\E49C';
}

.xgl-text_box_alt::before {
  content: '\E7AA';
}

.xgl-text_box_two_tone::before {
  content: '\E49C';
}

.xgl-text_box_two_tone::after {
  content: '\E7AA';
  opacity: .2;
}

.xgl-text_lines_square::before {
  content: '\E49D';
}

.xgl-text_lines_square_alt::before {
  content: '\E49E';
}

.xgl-text_lines_square_rtl::before {
  content: '\E5A9';
}

.xgl-text_lines_square_rtl_alt::before {
  content: '\E5A8';
}

.xgl-text_lines_square_rtl_two_tone::before {
  content: '\E5A9';
}

.xgl-text_lines_square_rtl_two_tone::after {
  content: '\E5A8';
  opacity: .2;
}

.xgl-text_lines_square_two_tone::before {
  content: '\E49D';
}

.xgl-text_lines_square_two_tone::after {
  content: '\E49E';
  opacity: .2;
}

.xgl-text_square::before {
  content: '\E49F';
}

.xgl-text_square_alt::before {
  content: '\E4A0';
}

.xgl-text_square_two_tone::before {
  content: '\E49F';
}

.xgl-text_square_two_tone::after {
  content: '\E4A0';
  opacity: .2;
}

.xgl-thermometer::before {
  content: '\EAC0';
}

.xgl-thermometer_alt::before {
  content: '\EAC1';
}

.xgl-thermometer_two_tone::before {
  content: '\EAC0';
}

.xgl-thermometer_two_tone::after {
  content: '\EAC1';
  opacity: .2;
}

.xgl-three_circle::before {
  content: '\EE58';
}

.xgl-three_circle_alt::before {
  content: '\EE59';
}

.xgl-three_circle_two_tone::before {
  content: '\EE58';
}

.xgl-three_circle_two_tone::after {
  content: '\EE59';
  opacity: .2;
}

.xgl-three_free_staple::before {
  content: '\EB36';
}

.xgl-three_free_staple_alt::before {
  content: '\EB37';
}

.xgl-three_free_staple_two_tone::before {
  content: '\EB36';
}

.xgl-three_free_staple_two_tone::after {
  content: '\EB37';
  opacity: .2;
}

.xgl-three_hole::before {
  content: '\ED71';
}

.xgl-three_hole_alt::before {
  content: '\ED60';
}

.xgl-three_hole_two_tone::before {
  content: '\ED71';
}

.xgl-three_hole_two_tone::after {
  content: '\ED60';
  opacity: .2;
}

.xgl-three_holes_bottom_l::before {
  content: '\E8F3';
}

.xgl-three_holes_bottom_l_alt::before {
  content: '\E8F4';
}

.xgl-three_holes_bottom_l_two_tone::before {
  content: '\E8F3';
}

.xgl-three_holes_bottom_l_two_tone::after {
  content: '\E8F4';
  opacity: .2;
}

.xgl-three_holes_bottom_p::before {
  content: '\E8F5';
}

.xgl-three_holes_bottom_p_alt::before {
  content: '\E8F6';
}

.xgl-three_holes_bottom_p_two_tone::before {
  content: '\E8F5';
}

.xgl-three_holes_bottom_p_two_tone::after {
  content: '\E8F6';
  opacity: .2;
}

.xgl-three_holes_left_l::before {
  content: '\E4A1';
}

.xgl-three_holes_left_l_alt::before {
  content: '\E754';
}

.xgl-three_holes_left_l_two_tone::before {
  content: '\E4A1';
}

.xgl-three_holes_left_l_two_tone::after {
  content: '\E754';
  opacity: .2;
}

.xgl-three_holes_left_p::before {
  content: '\E4A2';
}

.xgl-three_holes_left_p_alt::before {
  content: '\E755';
}

.xgl-three_holes_left_p_two_tone::before {
  content: '\E4A2';
}

.xgl-three_holes_left_p_two_tone::after {
  content: '\E755';
  opacity: .2;
}

.xgl-three_holes_right_l::before {
  content: '\E4A3';
}

.xgl-three_holes_right_l_alt::before {
  content: '\E756';
}

.xgl-three_holes_right_l_two_tone::before {
  content: '\E4A3';
}

.xgl-three_holes_right_l_two_tone::after {
  content: '\E756';
  opacity: .2;
}

.xgl-three_holes_right_p::before {
  content: '\E4A4';
}

.xgl-three_holes_right_p_alt::before {
  content: '\E757';
}

.xgl-three_holes_right_p_two_tone::before {
  content: '\E4A4';
}

.xgl-three_holes_right_p_two_tone::after {
  content: '\E757';
  opacity: .2;
}

.xgl-three_holes_top_l::before {
  content: '\E4A5';
}

.xgl-three_holes_top_l_alt::before {
  content: '\E758';
}

.xgl-three_holes_top_l_two_tone::before {
  content: '\E4A5';
}

.xgl-three_holes_top_l_two_tone::after {
  content: '\E758';
  opacity: .2;
}

.xgl-three_holes_top_p::before {
  content: '\E4A6';
}

.xgl-three_holes_top_p_alt::before {
  content: '\E759';
}

.xgl-three_holes_top_p_two_tone::before {
  content: '\E4A6';
}

.xgl-three_holes_top_p_two_tone::after {
  content: '\E759';
  opacity: .2;
}

.xgl-three_round_tile::before {
  content: '\E9D7';
}

.xgl-three_round_tile_alt::before {
  content: '\E9D8';
}

.xgl-three_round_tile_two_tone::before {
  content: '\E9D7';
}

.xgl-three_round_tile_two_tone::after {
  content: '\E9D8';
  opacity: .2;
}

.xgl-three_staple::before {
  content: '\EB34';
}

.xgl-three_staple_alt::before {
  content: '\EB35';
}

.xgl-three_staple_two_tone::before {
  content: '\EB34';
}

.xgl-three_staple_two_tone::after {
  content: '\EB35';
  opacity: .2;
}

.xgl-three_staples_bottom_l::before {
  content: '\ED61';
}

.xgl-three_staples_bottom_l_alt::before {
  content: '\ED62';
}

.xgl-three_staples_bottom_l_two_tone::before {
  content: '\ED61';
}

.xgl-three_staples_bottom_l_two_tone::after {
  content: '\ED62';
  opacity: .2;
}

.xgl-three_staples_bottom_p::before {
  content: '\ED63';
}

.xgl-three_staples_bottom_p_alt::before {
  content: '\ED64';
}

.xgl-three_staples_bottom_p_two_tone::before {
  content: '\ED63';
}

.xgl-three_staples_bottom_p_two_tone::after {
  content: '\ED64';
  opacity: .2;
}

.xgl-three_staples_left_l::before {
  content: '\ED65';
}

.xgl-three_staples_left_l_alt::before {
  content: '\ED66';
}

.xgl-three_staples_left_l_two_tone::before {
  content: '\ED65';
}

.xgl-three_staples_left_l_two_tone::after {
  content: '\ED66';
  opacity: .2;
}

.xgl-three_staples_left_p::before {
  content: '\ED67';
}

.xgl-three_staples_left_p_alt::before {
  content: '\ED68';
}

.xgl-three_staples_left_p_two_tone::before {
  content: '\ED67';
}

.xgl-three_staples_left_p_two_tone::after {
  content: '\ED68';
  opacity: .2;
}

.xgl-three_staples_right_l::before {
  content: '\ED69';
}

.xgl-three_staples_right_l_alt::before {
  content: '\ED6A';
}

.xgl-three_staples_right_l_two_tone::before {
  content: '\ED69';
}

.xgl-three_staples_right_l_two_tone::after {
  content: '\ED6A';
  opacity: .2;
}

.xgl-three_staples_right_p::before {
  content: '\ED6B';
}

.xgl-three_staples_right_p_alt::before {
  content: '\ED6C';
}

.xgl-three_staples_right_p_two_tone::before {
  content: '\ED6B';
}

.xgl-three_staples_right_p_two_tone::after {
  content: '\ED6C';
  opacity: .2;
}

.xgl-three_staples_top_l::before {
  content: '\ED6D';
}

.xgl-three_staples_top_l_alt::before {
  content: '\ED6E';
}

.xgl-three_staples_top_l_two_tone::before {
  content: '\ED6D';
}

.xgl-three_staples_top_l_two_tone::after {
  content: '\ED6E';
  opacity: .2;
}

.xgl-three_staples_top_p::before {
  content: '\ED6F';
}

.xgl-three_staples_top_p_alt::before {
  content: '\ED70';
}

.xgl-three_staples_top_p_two_tone::before {
  content: '\ED6F';
}

.xgl-three_staples_top_p_two_tone::after {
  content: '\ED70';
  opacity: .2;
}

.xgl-thumb_drive::before {
  content: '\E4A7';
}

.xgl-thumb_drive_alt::before {
  content: '\E4A8';
}

.xgl-thumb_drive_two_tone::before {
  content: '\E4A7';
}

.xgl-thumb_drive_two_tone::after {
  content: '\E4A8';
  opacity: .2;
}

.xgl-thumb_drive_usb::before {
  content: '\EDEA';
}

.xgl-thumb_drive_usb_alt::before {
  content: '\EDEB';
}

.xgl-thumb_drive_usb_two_tone::before {
  content: '\EDEA';
}

.xgl-thumb_drive_usb_two_tone::after {
  content: '\EDEB';
  opacity: .2;
}

.xgl-thumbnail_reorder::before {
  content: '\E4A9';
}

.xgl-thumbnail_strip_four::before {
  content: '\E67F';
}

.xgl-thumbnail_strip_four_alt::before {
  content: '\E680';
}

.xgl-thumbnail_strip_four_two_tone::before {
  content: '\E67F';
}

.xgl-thumbnail_strip_four_two_tone::after {
  content: '\E680';
  opacity: .2;
}

.xgl-thumbnail_strip_two::before {
  content: '\E681';
}

.xgl-thumbnail_strip_two_alt::before {
  content: '\E682';
}

.xgl-thumbnail_strip_two_two_tone::before {
  content: '\E681';
}

.xgl-thumbnail_strip_two_two_tone::after {
  content: '\E682';
  opacity: .2;
}

.xgl-thumbnail_text::before {
  content: '\E4AA';
}

.xgl-thumbnail_text_alt::before {
  content: '\E4AB';
}

.xgl-thumbnail_text_two_tone::before {
  content: '\E4AA';
}

.xgl-thumbnail_text_two_tone::after {
  content: '\E4AB';
  opacity: .2;
}

.xgl-thumbnails::before {
  content: '\E4AC';
}

.xgl-thumbnails_alt::before {
  content: '\E4AD';
}

.xgl-thumbnails_six::before {
  content: '\E4AE';
}

.xgl-thumbnails_six_alt::before {
  content: '\E4AF';
}

.xgl-thumbnails_six_two_tone::before {
  content: '\E4AE';
}

.xgl-thumbnails_six_two_tone::after {
  content: '\E4AF';
  opacity: .2;
}

.xgl-thumbnails_two_tone::before {
  content: '\E4AC';
}

.xgl-thumbnails_two_tone::after {
  content: '\E4AD';
  opacity: .2;
}

.xgl-thumbs_down::before {
  content: '\E4B0';
}

.xgl-thumbs_down_alt::before {
  content: '\E4B1';
}

.xgl-thumbs_down_two_tone::before {
  content: '\E4B0';
}

.xgl-thumbs_down_two_tone::after {
  content: '\E4B1';
  opacity: .2;
}

.xgl-thumbs_up::before {
  content: '\E4B2';
}

.xgl-thumbs_up_alt::before {
  content: '\E4B3';
}

.xgl-thumbs_up_two_tone::before {
  content: '\E4B2';
}

.xgl-thumbs_up_two_tone::after {
  content: '\E4B3';
  opacity: .2;
}

.xgl-ticket::before {
  content: '\E4B4';
}

.xgl-ticket_alt::before {
  content: '\E4B5';
}

.xgl-ticket_two_tone::before {
  content: '\E4B4';
}

.xgl-ticket_two_tone::after {
  content: '\E4B5';
  opacity: .2;
}

.xgl-time_off::before {
  content: '\EA84';
}

.xgl-time_off_alt::before {
  content: '\EA85';
}

.xgl-time_off_two_tone::before {
  content: '\EA84';
}

.xgl-time_off_two_tone::after {
  content: '\EA85';
  opacity: .2;
}

.xgl-timer_start::before {
  content: '\EBFD';
}

.xgl-timer_start_alt::before {
  content: '\EBFE';
}

.xgl-timer_start_two_tone::before {
  content: '\EBFD';
}

.xgl-timer_start_two_tone::after {
  content: '\EBFE';
  opacity: .2;
}

.xgl-timezone::before {
  content: '\EBEF';
}

.xgl-timezone_alt::before {
  content: '\EBF0';
}

.xgl-timezone_two_tone::before {
  content: '\EBEF';
}

.xgl-timezone_two_tone::after {
  content: '\EBF0';
  opacity: .2;
}

.xgl-today::before {
  content: '\ED41';
}

.xgl-toggles::before {
  content: '\E4B6';
}

.xgl-toggles_alt::before {
  content: '\E4B7';
}

.xgl-toggles_disabled::before {
  content: '\EA7D';
}

.xgl-toggles_disabled_alt::before {
  content: '\EA7E';
}

.xgl-toggles_disabled_two_tone::before {
  content: '\EA7D';
}

.xgl-toggles_disabled_two_tone::after {
  content: '\EA7E';
  opacity: .2;
}

.xgl-toggles_two_tone::before {
  content: '\E4B6';
}

.xgl-toggles_two_tone::after {
  content: '\E4B7';
  opacity: .2;
}

.xgl-toner::before {
  content: '\E4B8';
}

.xgl-toner_add::before {
  content: '\E792';
}

.xgl-toner_add_two_tone::before {
  content: '\E792';
}

.xgl-toner_add_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_black::before {
  content: '\E813';
}

.xgl-toner_black_two_tone::before {
  content: '\E813';
}

.xgl-toner_black_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_blue::before {
  content: '\E81D';
}

.xgl-toner_blue_two_tone::before {
  content: '\E81D';
}

.xgl-toner_blue_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_c::before {
  content: '\E65F';
}

.xgl-toner_c_alt::before {
  content: '\E660';
}

.xgl-toner_c_two_tone::before {
  content: '\E65F';
}

.xgl-toner_c_two_tone::after {
  content: '\E660';
  opacity: .2;
}

.xgl-toner_clear::before {
  content: '\E81E';
}

.xgl-toner_clear_two_tone::before {
  content: '\E81E';
}

.xgl-toner_clear_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_cyan::before {
  content: '\E810';
}

.xgl-toner_cyan_two_tone::before {
  content: '\E810';
}

.xgl-toner_cyan_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_ember_gold::before {
  content: '\E821';
}

.xgl-toner_ember_gold_two_tone::before {
  content: '\E821';
}

.xgl-toner_ember_gold_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_fifty::before {
  content: '\E816';
}

.xgl-toner_full::before {
  content: '\E814';
}

.xgl-toner_gold::before {
  content: '\E81F';
}

.xgl-toner_gold_two_tone::before {
  content: '\E81F';
}

.xgl-toner_gold_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_green::before {
  content: '\E818';
}

.xgl-toner_green_two_tone::before {
  content: '\E818';
}

.xgl-toner_green_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_k::before {
  content: '\E665';
}

.xgl-toner_k_alt::before {
  content: '\E666';
}

.xgl-toner_k_two_tone::before {
  content: '\E665';
}

.xgl-toner_k_two_tone::after {
  content: '\E666';
  opacity: .2;
}

.xgl-toner_m::before {
  content: '\E661';
}

.xgl-toner_m_alt::before {
  content: '\E662';
}

.xgl-toner_m_two_tone::before {
  content: '\E661';
}

.xgl-toner_m_two_tone::after {
  content: '\E662';
  opacity: .2;
}

.xgl-toner_magenta::before {
  content: '\E811';
}

.xgl-toner_magenta_two_tone::before {
  content: '\E811';
}

.xgl-toner_magenta_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_orange::before {
  content: '\E81B';
}

.xgl-toner_orange_two_tone::before {
  content: '\E81B';
}

.xgl-toner_orange_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_purple::before {
  content: '\E81C';
}

.xgl-toner_purple_two_tone::before {
  content: '\E81C';
}

.xgl-toner_purple_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_red::before {
  content: '\E81A';
}

.xgl-toner_red_two_tone::before {
  content: '\E81A';
}

.xgl-toner_red_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_save::before {
  content: '\E793';
}

.xgl-toner_save_two_tone::before {
  content: '\E793';
}

.xgl-toner_save_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_seventy_five::before {
  content: '\E815';
}

.xgl-toner_silver::before {
  content: '\E820';
}

.xgl-toner_silver_two_tone::before {
  content: '\E820';
}

.xgl-toner_silver_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_square::before {
  content: '\E4B9';
}

.xgl-toner_square_alt::before {
  content: '\E4BA';
}

.xgl-toner_square_two_tone::before {
  content: '\E4B9';
}

.xgl-toner_square_two_tone::after {
  content: '\E4BA';
  opacity: .2;
}

.xgl-toner_twenty_five::before {
  content: '\E817';
}

.xgl-toner_white::before {
  content: '\E819';
}

.xgl-toner_white_two_tone::before {
  content: '\E819';
}

.xgl-toner_white_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toner_y::before {
  content: '\E663';
}

.xgl-toner_y_alt::before {
  content: '\E664';
}

.xgl-toner_y_two_tone::before {
  content: '\E663';
}

.xgl-toner_y_two_tone::after {
  content: '\E664';
  opacity: .2;
}

.xgl-toner_yellow::before {
  content: '\E812';
}

.xgl-toner_yellow_two_tone::before {
  content: '\E812';
}

.xgl-toner_yellow_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-toolbox::before {
  content: '\E4BB';
}

.xgl-toolbox_alt::before {
  content: '\E4BC';
}

.xgl-toolbox_two_tone::before {
  content: '\E4BB';
}

.xgl-toolbox_two_tone::after {
  content: '\E4BC';
  opacity: .2;
}

.xgl-tools::before {
  content: '\E4BD';
}

.xgl-tools_alt::before {
  content: '\E4BE';
}

.xgl-tools_two_tone::before {
  content: '\E4BD';
}

.xgl-tools_two_tone::after {
  content: '\E4BE';
  opacity: .2;
}

.xgl-top::before {
  content: '\E6B4';
}

.xgl-transfer::before {
  content: '\E4BF';
}

.xgl-transfer_lef::before {
  content: '\E4C0';
}

.xgl-transfer_lef_alt::before {
  content: '\E75A';
}

.xgl-transfer_lef_two_tone::before {
  content: '\E4C0';
}

.xgl-transfer_lef_two_tone::after {
  content: '\E75A';
  opacity: .2;
}

.xgl-transfer_secure::before {
  content: '\E6A2';
}

.xgl-transfer_secure_square::before {
  content: '\EDEC';
}

.xgl-transfer_secure_square_alt::before {
  content: '\EDED';
}

.xgl-transfer_secure_square_two_tone::before {
  content: '\EDEC';
}

.xgl-transfer_secure_square_two_tone::after {
  content: '\EDED';
  opacity: .2;
}

.xgl-transfer_sef::before {
  content: '\E4C1';
}

.xgl-transfer_sef_alt::before {
  content: '\E75B';
}

.xgl-transfer_sef_two_tone::before {
  content: '\E4C1';
}

.xgl-transfer_sef_two_tone::after {
  content: '\E75B';
  opacity: .2;
}

.xgl-transfer_square::before {
  content: '\EDEE';
}

.xgl-transfer_square_alt::before {
  content: '\EDEF';
}

.xgl-transfer_square_two_tone::before {
  content: '\EDEE';
}

.xgl-transfer_square_two_tone::after {
  content: '\EDEF';
  opacity: .2;
}

.xgl-transform::before {
  content: '\EE4A';
}

.xgl-transform_alt::before {
  content: '\EE4B';
}

.xgl-transform_two_tone::before {
  content: '\EE4A';
}

.xgl-transform_two_tone::after {
  content: '\EE4B';
  opacity: .2;
}

.xgl-transition::before {
  content: '\EC68';
}

.xgl-transition_alt::before {
  content: '\EC69';
}

.xgl-transition_two_tone::before {
  content: '\EC68';
}

.xgl-transition_two_tone::after {
  content: '\EC69';
  opacity: .2;
}

.xgl-translate::before {
  content: '\E8A0';
}

.xgl-translate_alt::before {
  content: '\E8A1';
}

.xgl-translate_two_tone::before {
  content: '\E8A0';
}

.xgl-translate_two_tone::after {
  content: '\E8A1';
  opacity: .2;
}

.xgl-transparency_lef::before {
  content: '\E4C2';
}

.xgl-transparency_lef_alt::before {
  content: '\E75C';
}

.xgl-transparency_lef_two_tone::before {
  content: '\E4C2';
}

.xgl-transparency_lef_two_tone::after {
  content: '\E75C';
  opacity: .2;
}

.xgl-transparency_sef::before {
  content: '\E4C3';
}

.xgl-transparency_sef_alt::before {
  content: '\E75D';
}

.xgl-transparency_sef_two_tone::before {
  content: '\E4C3';
}

.xgl-transparency_sef_two_tone::after {
  content: '\E75D';
  opacity: .2;
}

.xgl-transparency_separator::before {
  content: '\EBF5';
}

.xgl-transparency_separator_alt::before {
  content: '\EBF6';
}

.xgl-transparency_separator_disabled::before {
  content: '\EBF7';
}

.xgl-transparency_separator_disabled_alt::before {
  content: '\EBF8';
}

.xgl-transparency_separator_disabled_two_tone::before {
  content: '\EBF7';
}

.xgl-transparency_separator_disabled_two_tone::after {
  content: '\EBF8';
  opacity: .2;
}

.xgl-transparency_separator_printed::before {
  content: '\EBF9';
}

.xgl-transparency_separator_printed_alt::before {
  content: '\EBFA';
}

.xgl-transparency_separator_printed_two_tone::before {
  content: '\EBF9';
}

.xgl-transparency_separator_printed_two_tone::after {
  content: '\EBFA';
  opacity: .2;
}

.xgl-transparency_separator_two_tone::before {
  content: '\EBF5';
}

.xgl-transparency_separator_two_tone::after {
  content: '\EBF6';
  opacity: .2;
}

.xgl-trash::before {
  content: '\E4C4';
}

.xgl-trash_alt::before {
  content: '\E4C5';
}

.xgl-trash_square::before {
  content: '\E4C6';
}

.xgl-trash_square_alt::before {
  content: '\E4C7';
}

.xgl-trash_square_two_tone::before {
  content: '\E4C6';
}

.xgl-trash_square_two_tone::after {
  content: '\E4C7';
  opacity: .2;
}

.xgl-trash_two_tone::before {
  content: '\E4C4';
}

.xgl-trash_two_tone::after {
  content: '\E4C5';
  opacity: .2;
}

.xgl-travel::before {
  content: '\E4C8';
}

.xgl-travel_alt::before {
  content: '\E4C9';
}

.xgl-travel_two_tone::before {
  content: '\E4C8';
}

.xgl-travel_two_tone::after {
  content: '\E4C9';
  opacity: .2;
}

.xgl-tray_alt::before {
  content: '\E7FC';
}

.xgl-tray_auto::before {
  content: '\E4CA';
}

.xgl-tray_auto_two_tone::before {
  content: '\E4CA';
}

.xgl-tray_auto_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_bkgd::before {
  content: '\EA7F';
}

.xgl-tray_eight_empty::before {
  content: '\E4CB';
}

.xgl-tray_eight_empty_two_tone::before {
  content: '\E4CB';
}

.xgl-tray_eight_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_eight_fifty::before {
  content: '\E4CC';
}

.xgl-tray_eight_fifty_two_tone::before {
  content: '\E4CC';
}

.xgl-tray_eight_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_eight_full::before {
  content: '\E4CD';
}

.xgl-tray_eight_full_two_tone::before {
  content: '\E4CD';
}

.xgl-tray_eight_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_eight_inserter_empty::before {
  content: '\E4CE';
}

.xgl-tray_eight_inserter_present::before {
  content: '\E4CF';
}

.xgl-tray_eight_seventy_five::before {
  content: '\E4D0';
}

.xgl-tray_eight_seventy_five_two_tone::before {
  content: '\E4D0';
}

.xgl-tray_eight_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_eight_twenty_five::before {
  content: '\E4D1';
}

.xgl-tray_eight_twenty_five_two_tone::before {
  content: '\E4D1';
}

.xgl-tray_eight_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_empty::before {
  content: '\E4D2';
}

.xgl-tray_empty_two_tone::before {
  content: '\E4D2';
}

.xgl-tray_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_favorite::before {
  content: '\E4D3';
}

.xgl-tray_favorite_two_tone::before {
  content: '\E4D3';
}

.xgl-tray_favorite_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_fifty::before {
  content: '\E4D4';
}

.xgl-tray_fifty_two_tone::before {
  content: '\E4D4';
}

.xgl-tray_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_five_bypass_empty::before {
  content: '\E4D5';
}

.xgl-tray_five_bypass_present::before {
  content: '\E4D6';
}

.xgl-tray_five_empty::before {
  content: '\E4D7';
}

.xgl-tray_five_empty_two_tone::before {
  content: '\E4D7';
}

.xgl-tray_five_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_five_fifty::before {
  content: '\E4D8';
}

.xgl-tray_five_fifty_two_tone::before {
  content: '\E4D8';
}

.xgl-tray_five_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_five_full::before {
  content: '\E4D9';
}

.xgl-tray_five_full_two_tone::before {
  content: '\E4D9';
}

.xgl-tray_five_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_five_seventy_five::before {
  content: '\E4DA';
}

.xgl-tray_five_seventy_five_two_tone::before {
  content: '\E4DA';
}

.xgl-tray_five_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_five_twenty_five::before {
  content: '\E4DB';
}

.xgl-tray_five_twenty_five_two_tone::before {
  content: '\E4DB';
}

.xgl-tray_five_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_four_empty::before {
  content: '\E4DC';
}

.xgl-tray_four_empty_two_tone::before {
  content: '\E4DC';
}

.xgl-tray_four_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_four_fifty::before {
  content: '\E4DD';
}

.xgl-tray_four_fifty_two_tone::before {
  content: '\E4DD';
}

.xgl-tray_four_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_four_full::before {
  content: '\E4DE';
}

.xgl-tray_four_full_two_tone::before {
  content: '\E4DE';
}

.xgl-tray_four_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_four_seventy_five::before {
  content: '\E4DF';
}

.xgl-tray_four_seventy_five_two_tone::before {
  content: '\E4DF';
}

.xgl-tray_four_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_four_twenty_five::before {
  content: '\E4E0';
}

.xgl-tray_four_twenty_five_two_tone::before {
  content: '\E4E0';
}

.xgl-tray_four_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_full::before {
  content: '\E4E1';
}

.xgl-tray_full_alt::before {
  content: '\ED20';
}

.xgl-tray_full_two_tone::before {
  content: '\E4E1';
}

.xgl-tray_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_heater::before {
  content: '\EAD4';
}

.xgl-tray_heater_alt::before {
  content: '\EAD5';
}

.xgl-tray_heater_two_tone::before {
  content: '\EAD4';
}

.xgl-tray_heater_two_tone::after {
  content: '\EAD5';
  opacity: .2;
}

.xgl-tray_level_fifty::before {
  content: '\E824';
}

.xgl-tray_level_full::before {
  content: '\E822';
}

.xgl-tray_level_seventy_five::before {
  content: '\E823';
}

.xgl-tray_level_twenty_five::before {
  content: '\E825';
}

.xgl-tray_nine_empty::before {
  content: '\E896';
}

.xgl-tray_nine_empty_two_tone::before {
  content: '\E896';
}

.xgl-tray_nine_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_nine_fifty::before {
  content: '\E897';
}

.xgl-tray_nine_fifty_two_tone::before {
  content: '\E897';
}

.xgl-tray_nine_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_nine_full::before {
  content: '\E898';
}

.xgl-tray_nine_full_two_tone::before {
  content: '\E898';
}

.xgl-tray_nine_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_nine_seventy_five::before {
  content: '\E899';
}

.xgl-tray_nine_seventy_five_two_tone::before {
  content: '\E899';
}

.xgl-tray_nine_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_nine_twenty_five::before {
  content: '\E89A';
}

.xgl-tray_nine_twenty_five_two_tone::before {
  content: '\E89A';
}

.xgl-tray_nine_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_one_bypass_empty::before {
  content: '\E4E2';
}

.xgl-tray_one_bypass_present::before {
  content: '\E4E3';
}

.xgl-tray_one_empty::before {
  content: '\E4E4';
}

.xgl-tray_one_empty_two_tone::before {
  content: '\E4E4';
}

.xgl-tray_one_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_one_fifty::before {
  content: '\E4E5';
}

.xgl-tray_one_fifty_two_tone::before {
  content: '\E4E5';
}

.xgl-tray_one_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_one_full::before {
  content: '\E4E6';
}

.xgl-tray_one_full_two_tone::before {
  content: '\E4E6';
}

.xgl-tray_one_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_one_seventy_five::before {
  content: '\E4E7';
}

.xgl-tray_one_seventy_five_two_tone::before {
  content: '\E4E7';
}

.xgl-tray_one_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_one_twenty_five::before {
  content: '\E4E8';
}

.xgl-tray_one_twenty_five_two_tone::before {
  content: '\E4E8';
}

.xgl-tray_one_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seven_empty::before {
  content: '\E4E9';
}

.xgl-tray_seven_empty_two_tone::before {
  content: '\E4E9';
}

.xgl-tray_seven_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seven_fifty::before {
  content: '\E4EA';
}

.xgl-tray_seven_fifty_two_tone::before {
  content: '\E4EA';
}

.xgl-tray_seven_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seven_full::before {
  content: '\E4EB';
}

.xgl-tray_seven_full_two_tone::before {
  content: '\E4EB';
}

.xgl-tray_seven_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seven_inserter_empty::before {
  content: '\E4EC';
}

.xgl-tray_seven_inserter_present::before {
  content: '\E4ED';
}

.xgl-tray_seven_seventy_five::before {
  content: '\E4EE';
}

.xgl-tray_seven_seventy_five_two_tone::before {
  content: '\E4EE';
}

.xgl-tray_seven_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seven_twenty_five::before {
  content: '\E4EF';
}

.xgl-tray_seven_twenty_five_two_tone::before {
  content: '\E4EF';
}

.xgl-tray_seven_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_seventy_five::before {
  content: '\E4F0';
}

.xgl-tray_seventy_five_two_tone::before {
  content: '\E4F0';
}

.xgl-tray_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_six_empty::before {
  content: '\E4F1';
}

.xgl-tray_six_empty_two_tone::before {
  content: '\E4F1';
}

.xgl-tray_six_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_six_fifty::before {
  content: '\E4F2';
}

.xgl-tray_six_fifty_two_tone::before {
  content: '\E4F2';
}

.xgl-tray_six_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_six_full::before {
  content: '\E4F3';
}

.xgl-tray_six_full_two_tone::before {
  content: '\E4F3';
}

.xgl-tray_six_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_six_inserter_empty::before {
  content: '\E4F4';
}

.xgl-tray_six_inserter_present::before {
  content: '\E4F5';
}

.xgl-tray_six_seventy_five::before {
  content: '\E4F6';
}

.xgl-tray_six_seventy_five_two_tone::before {
  content: '\E4F6';
}

.xgl-tray_six_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_six_twenty_five::before {
  content: '\E4F7';
}

.xgl-tray_six_twenty_five_two_tone::before {
  content: '\E4F7';
}

.xgl-tray_six_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_square::before {
  content: '\E4F8';
}

.xgl-tray_square_alt::before {
  content: '\E4F9';
}

.xgl-tray_square_two_tone::before {
  content: '\E4F8';
}

.xgl-tray_square_two_tone::after {
  content: '\E4F9';
  opacity: .2;
}

.xgl-tray_t_one_inserter_empty::before {
  content: '\E89B';
}

.xgl-tray_t_one_inserter_present::before {
  content: '\E89C';
}

.xgl-tray_t_two_inserter_empty::before {
  content: '\E89D';
}

.xgl-tray_t_two_inserter_present::before {
  content: '\E89E';
}

.xgl-tray_three_empty::before {
  content: '\E4FA';
}

.xgl-tray_three_empty_two_tone::before {
  content: '\E4FA';
}

.xgl-tray_three_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_three_fifty::before {
  content: '\E4FB';
}

.xgl-tray_three_fifty_two_tone::before {
  content: '\E4FB';
}

.xgl-tray_three_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_three_full::before {
  content: '\E4FC';
}

.xgl-tray_three_full_two_tone::before {
  content: '\E4FC';
}

.xgl-tray_three_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_three_seventy_five::before {
  content: '\E4FD';
}

.xgl-tray_three_seventy_five_two_tone::before {
  content: '\E4FD';
}

.xgl-tray_three_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_three_twenty_five::before {
  content: '\E4FE';
}

.xgl-tray_three_twenty_five_two_tone::before {
  content: '\E4FE';
}

.xgl-tray_three_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_twenty_five::before {
  content: '\E4FF';
}

.xgl-tray_twenty_five_two_tone::before {
  content: '\E4FF';
}

.xgl-tray_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_two_empty::before {
  content: '\E500';
}

.xgl-tray_two_empty_two_tone::before {
  content: '\E500';
}

.xgl-tray_two_empty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_two_fifty::before {
  content: '\E501';
}

.xgl-tray_two_fifty_two_tone::before {
  content: '\E501';
}

.xgl-tray_two_fifty_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_two_full::before {
  content: '\E502';
}

.xgl-tray_two_full_two_tone::before {
  content: '\E502';
}

.xgl-tray_two_full_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_two_seventy_five::before {
  content: '\E503';
}

.xgl-tray_two_seventy_five_two_tone::before {
  content: '\E503';
}

.xgl-tray_two_seventy_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tray_two_twenty_five::before {
  content: '\E504';
}

.xgl-tray_two_twenty_five_two_tone::before {
  content: '\E504';
}

.xgl-tray_two_twenty_five_two_tone::after {
  content: '\EA7F';
  opacity: .2;
}

.xgl-tree::before {
  content: '\EC21';
}

.xgl-tree_alt::before {
  content: '\EC22';
}

.xgl-tree_two_tone::before {
  content: '\EC21';
}

.xgl-tree_two_tone::after {
  content: '\EC22';
  opacity: .2;
}

.xgl-tree_view::before {
  content: '\E633';
}

.xgl-tree_view_alt::before {
  content: '\E634';
}

.xgl-tree_view_two_tone::before {
  content: '\E633';
}

.xgl-tree_view_two_tone::after {
  content: '\E634';
  opacity: .2;
}

.xgl-trend_down::before {
  content: '\ED21';
}

.xgl-trend_down_alt::before {
  content: '\ED22';
}

.xgl-trend_down_stack_bkgd::before {
  content: '\ECC8';
}

.xgl-trend_down_stack_frgd::before {
  content: '\ECCA';
}

.xgl-trend_down_stack_mdgd::before {
  content: '\ECC9';
}

.xgl-trend_down_two_tone::before {
  content: '\ED21';
}

.xgl-trend_down_two_tone::after {
  content: '\ED22';
  opacity: .2;
}

.xgl-trend_up::before {
  content: '\ED23';
}

.xgl-trend_up_alt::before {
  content: '\ED24';
}

.xgl-trend_up_stack_bkgd::before {
  content: '\ECCB';
}

.xgl-trend_up_stack_frgd::before {
  content: '\ECCD';
}

.xgl-trend_up_stack_mdgd::before {
  content: '\ECCC';
}

.xgl-trend_up_two_tone::before {
  content: '\ED23';
}

.xgl-trend_up_two_tone::after {
  content: '\ED24';
  opacity: .2;
}

.xgl-triangle::before {
  content: '\EC89';
}

.xgl-triangle_bkgd::before {
  content: '\E505';
}

.xgl-trophy::before {
  content: '\E506';
}

.xgl-trophy_alt::before {
  content: '\E507';
}

.xgl-trophy_two_tone::before {
  content: '\E506';
}

.xgl-trophy_two_tone::after {
  content: '\E507';
  opacity: .2;
}

.xgl-troubleshoot::before {
  content: '\EAE2';
}

.xgl-troubleshoot_alt::before {
  content: '\EAE3';
}

.xgl-troubleshoot_two_tone::before {
  content: '\EAE2';
}

.xgl-troubleshoot_two_tone::after {
  content: '\EAE3';
  opacity: .2;
}

.xgl-truck::before {
  content: '\E508';
}

.xgl-truck_alt::before {
  content: '\E509';
}

.xgl-truck_two_tone::before {
  content: '\E508';
}

.xgl-truck_two_tone::after {
  content: '\E509';
  opacity: .2;
}

.xgl-tshirt::before {
  content: '\E50A';
}

.xgl-tshirt_alt::before {
  content: '\E50B';
}

.xgl-tshirt_two_tone::before {
  content: '\E50A';
}

.xgl-tshirt_two_tone::after {
  content: '\E50B';
  opacity: .2;
}

.xgl-tulip::before {
  content: '\E50C';
}

.xgl-tulip_alt::before {
  content: '\E50D';
}

.xgl-tulip_two_tone::before {
  content: '\E50C';
}

.xgl-tulip_two_tone::after {
  content: '\E50D';
  opacity: .2;
}

.xgl-tv::before {
  content: '\E50E';
}

.xgl-tv_alt::before {
  content: '\E50F';
}

.xgl-tv_two_tone::before {
  content: '\E50E';
}

.xgl-tv_two_tone::after {
  content: '\E50F';
  opacity: .2;
}

.xgl-twitter::before {
  content: '\E510';
}

.xgl-twitter_alt::before {
  content: '\EC8B';
}

.xgl-twitter_two_tone::before {
  content: '\E510';
}

.xgl-twitter_two_tone::after {
  content: '\EC8B';
  opacity: .2;
}

.xgl-two_circle::before {
  content: '\EE56';
}

.xgl-two_circle_alt::before {
  content: '\EE57';
}

.xgl-two_circle_two_tone::before {
  content: '\EE56';
}

.xgl-two_circle_two_tone::after {
  content: '\EE57';
  opacity: .2;
}

.xgl-two_free_staple::before {
  content: '\EB2E';
}

.xgl-two_free_staple_alt::before {
  content: '\EB2F';
}

.xgl-two_free_staple_l::before {
  content: '\EB30';
}

.xgl-two_free_staple_l_alt::before {
  content: '\EB31';
}

.xgl-two_free_staple_l_two_tone::before {
  content: '\EB30';
}

.xgl-two_free_staple_l_two_tone::after {
  content: '\EB31';
  opacity: .2;
}

.xgl-two_free_staple_p::before {
  content: '\EB32';
}

.xgl-two_free_staple_p_alt::before {
  content: '\EB33';
}

.xgl-two_free_staple_p_two_tone::before {
  content: '\EB32';
}

.xgl-two_free_staple_p_two_tone::after {
  content: '\EB33';
  opacity: .2;
}

.xgl-two_free_staple_two_tone::before {
  content: '\EB2E';
}

.xgl-two_free_staple_two_tone::after {
  content: '\EB2F';
  opacity: .2;
}

.xgl-two_holes_bottom_l::before {
  content: '\E8ED';
}

.xgl-two_holes_bottom_l_alt::before {
  content: '\E8EE';
}

.xgl-two_holes_bottom_l_two_tone::before {
  content: '\E8ED';
}

.xgl-two_holes_bottom_l_two_tone::after {
  content: '\E8EE';
  opacity: .2;
}

.xgl-two_holes_bottom_p::before {
  content: '\E8EF';
}

.xgl-two_holes_bottom_p_alt::before {
  content: '\E8F0';
}

.xgl-two_holes_bottom_p_two_tone::before {
  content: '\E8EF';
}

.xgl-two_holes_bottom_p_two_tone::after {
  content: '\E8F0';
  opacity: .2;
}

.xgl-two_holes_left_l::before {
  content: '\E511';
}

.xgl-two_holes_left_l_alt::before {
  content: '\E75E';
}

.xgl-two_holes_left_l_two_tone::before {
  content: '\E511';
}

.xgl-two_holes_left_l_two_tone::after {
  content: '\E75E';
  opacity: .2;
}

.xgl-two_holes_left_p::before {
  content: '\E512';
}

.xgl-two_holes_left_p_alt::before {
  content: '\E75F';
}

.xgl-two_holes_left_p_two_tone::before {
  content: '\E512';
}

.xgl-two_holes_left_p_two_tone::after {
  content: '\E75F';
  opacity: .2;
}

.xgl-two_holes_right_l::before {
  content: '\E513';
}

.xgl-two_holes_right_l_alt::before {
  content: '\E760';
}

.xgl-two_holes_right_l_two_tone::before {
  content: '\E513';
}

.xgl-two_holes_right_l_two_tone::after {
  content: '\E760';
  opacity: .2;
}

.xgl-two_holes_right_p::before {
  content: '\E514';
}

.xgl-two_holes_right_p_alt::before {
  content: '\E761';
}

.xgl-two_holes_right_p_two_tone::before {
  content: '\E514';
}

.xgl-two_holes_right_p_two_tone::after {
  content: '\E761';
  opacity: .2;
}

.xgl-two_holes_top_l::before {
  content: '\E515';
}

.xgl-two_holes_top_l_alt::before {
  content: '\E762';
}

.xgl-two_holes_top_l_two_tone::before {
  content: '\E515';
}

.xgl-two_holes_top_l_two_tone::after {
  content: '\E762';
  opacity: .2;
}

.xgl-two_holes_top_p::before {
  content: '\E516';
}

.xgl-two_holes_top_p_alt::before {
  content: '\E763';
}

.xgl-two_holes_top_p_two_tone::before {
  content: '\E516';
}

.xgl-two_holes_top_p_two_tone::after {
  content: '\E763';
  opacity: .2;
}

.xgl-two_lists::before {
  content: '\EE03';
}

.xgl-two_lists_off::before {
  content: '\EE04';
}

.xgl-two_round_tile::before {
  content: '\E9D5';
}

.xgl-two_round_tile_alt::before {
  content: '\E9D6';
}

.xgl-two_round_tile_two_tone::before {
  content: '\E9D5';
}

.xgl-two_round_tile_two_tone::after {
  content: '\E9D6';
  opacity: .2;
}

.xgl-two_staple::before {
  content: '\EB2C';
}

.xgl-two_staple_alt::before {
  content: '\EB2D';
}

.xgl-two_staple_two_tone::before {
  content: '\EB2C';
}

.xgl-two_staple_two_tone::after {
  content: '\EB2D';
  opacity: .2;
}

.xgl-two_staples_bottom_l::before {
  content: '\ED5C';
}

.xgl-two_staples_bottom_l_alt::before {
  content: '\ED5D';
}

.xgl-two_staples_bottom_l_two_tone::before {
  content: '\ED5C';
}

.xgl-two_staples_bottom_l_two_tone::after {
  content: '\ED5D';
  opacity: .2;
}

.xgl-two_staples_bottom_p::before {
  content: '\ED5E';
}

.xgl-two_staples_bottom_p_alt::before {
  content: '\ED5F';
}

.xgl-two_staples_bottom_p_two_tone::before {
  content: '\ED5E';
}

.xgl-two_staples_bottom_p_two_tone::after {
  content: '\ED5F';
  opacity: .2;
}

.xgl-two_staples_left_l::before {
  content: '\E517';
}

.xgl-two_staples_left_l_alt::before {
  content: '\E764';
}

.xgl-two_staples_left_l_two_tone::before {
  content: '\E517';
}

.xgl-two_staples_left_l_two_tone::after {
  content: '\E764';
  opacity: .2;
}

.xgl-two_staples_left_p::before {
  content: '\E518';
}

.xgl-two_staples_left_p_alt::before {
  content: '\E765';
}

.xgl-two_staples_left_p_two_tone::before {
  content: '\E518';
}

.xgl-two_staples_left_p_two_tone::after {
  content: '\E765';
  opacity: .2;
}

.xgl-two_staples_right_l::before {
  content: '\E519';
}

.xgl-two_staples_right_l_alt::before {
  content: '\E766';
}

.xgl-two_staples_right_l_two_tone::before {
  content: '\E519';
}

.xgl-two_staples_right_l_two_tone::after {
  content: '\E766';
  opacity: .2;
}

.xgl-two_staples_right_p::before {
  content: '\E51A';
}

.xgl-two_staples_right_p_alt::before {
  content: '\E767';
}

.xgl-two_staples_right_p_two_tone::before {
  content: '\E51A';
}

.xgl-two_staples_right_p_two_tone::after {
  content: '\E767';
  opacity: .2;
}

.xgl-two_staples_top_l::before {
  content: '\E51B';
}

.xgl-two_staples_top_l_alt::before {
  content: '\E768';
}

.xgl-two_staples_top_l_two_tone::before {
  content: '\E51B';
}

.xgl-two_staples_top_l_two_tone::after {
  content: '\E768';
  opacity: .2;
}

.xgl-two_staples_top_p::before {
  content: '\E51C';
}

.xgl-two_staples_top_p_alt::before {
  content: '\E769';
}

.xgl-two_staples_top_p_two_tone::before {
  content: '\E51C';
}

.xgl-two_staples_top_p_two_tone::after {
  content: '\E769';
  opacity: .2;
}

.xgl-type_text::before {
  content: '\E7F5';
}

.xgl-type_text_photo::before {
  content: '\E7F4';
}

.xgl-type_text_photo_alt::before {
  content: '\ECBE';
}

.xgl-type_text_photo_two_tone::before {
  content: '\E7F4';
}

.xgl-type_text_photo_two_tone::after {
  content: '\ECBE';
  opacity: .2;
}

.xgl-umbrella::before {
  content: '\E620';
}

.xgl-umbrella_alt::before {
  content: '\E621';
}

.xgl-umbrella_two_tone::before {
  content: '\E620';
}

.xgl-umbrella_two_tone::after {
  content: '\E621';
  opacity: .2;
}

.xgl-uncoated_lef::before {
  content: '\E51D';
}

.xgl-uncoated_lef_alt::before {
  content: '\E76A';
}

.xgl-uncoated_lef_two_tone::before {
  content: '\E51D';
}

.xgl-uncoated_lef_two_tone::after {
  content: '\E76A';
  opacity: .2;
}

.xgl-uncoated_reloaded_lef::before {
  content: '\E51E';
}

.xgl-uncoated_reloaded_lef_alt::before {
  content: '\E76B';
}

.xgl-uncoated_reloaded_lef_two_tone::before {
  content: '\E51E';
}

.xgl-uncoated_reloaded_lef_two_tone::after {
  content: '\E76B';
  opacity: .2;
}

.xgl-uncoated_reloaded_sef::before {
  content: '\E51F';
}

.xgl-uncoated_reloaded_sef_alt::before {
  content: '\E76C';
}

.xgl-uncoated_reloaded_sef_two_tone::before {
  content: '\E51F';
}

.xgl-uncoated_reloaded_sef_two_tone::after {
  content: '\E76C';
  opacity: .2;
}

.xgl-uncoated_sef::before {
  content: '\E520';
}

.xgl-uncoated_sef_alt::before {
  content: '\E76D';
}

.xgl-uncoated_sef_two_tone::before {
  content: '\E520';
}

.xgl-uncoated_sef_two_tone::after {
  content: '\E76D';
  opacity: .2;
}

.xgl-underline::before {
  content: '\E521';
}

.xgl-undo::before {
  content: '\E522';
}

.xgl-undo_square::before {
  content: '\E523';
}

.xgl-undo_square_alt::before {
  content: '\E524';
}

.xgl-undo_square_two_tone::before {
  content: '\E523';
}

.xgl-undo_square_two_tone::after {
  content: '\E524';
  opacity: .2;
}

.xgl-unlock::before {
  content: '\E525';
}

.xgl-unlock_alt::before {
  content: '\E526';
}

.xgl-unlock_square::before {
  content: '\E527';
}

.xgl-unlock_square_alt::before {
  content: '\E528';
}

.xgl-unlock_square_two_tone::before {
  content: '\E527';
}

.xgl-unlock_square_two_tone::after {
  content: '\E528';
  opacity: .2;
}

.xgl-unlock_two_tone::before {
  content: '\E525';
}

.xgl-unlock_two_tone::after {
  content: '\E526';
  opacity: .2;
}

.xgl-unlocked_stack_bkgd::before {
  content: '\EC94';
}

.xgl-unlocked_stack_frgd::before {
  content: '\EC95';
}

.xgl-unlocked_stack_mdgd::before {
  content: '\EC96';
}

.xgl-unsubscribe::before {
  content: '\E9C6';
}

.xgl-unsubscribe_alt::before {
  content: '\E9C7';
}

.xgl-unsubscribe_two_tone::before {
  content: '\E9C6';
}

.xgl-unsubscribe_two_tone::after {
  content: '\E9C7';
  opacity: .2;
}

.xgl-up::before {
  content: '\E529';
}

.xgl-up_alt::before {
  content: '\E52A';
}

.xgl-up_square::before {
  content: '\E52B';
}

.xgl-up_square_alt::before {
  content: '\E52C';
}

.xgl-up_square_two_tone::before {
  content: '\E52B';
}

.xgl-up_square_two_tone::after {
  content: '\E52C';
  opacity: .2;
}

.xgl-up_two_tone::before {
  content: '\E529';
}

.xgl-up_two_tone::after {
  content: '\E52A';
  opacity: .2;
}

.xgl-upload::before {
  content: '\E52D';
}

.xgl-upload_square::before {
  content: '\E52E';
}

.xgl-upload_square_alt::before {
  content: '\E52F';
}

.xgl-upload_square_two_tone::before {
  content: '\E52E';
}

.xgl-upload_square_two_tone::after {
  content: '\E52F';
  opacity: .2;
}

.xgl-usage_counter::before {
  content: '\E530';
}

.xgl-usage_counter_alt::before {
  content: '\E531';
}

.xgl-usage_counter_disabled::before {
  content: '\E9C8';
}

.xgl-usage_counter_disabled_alt::before {
  content: '\E9C9';
}

.xgl-usage_counter_disabled_two_tone::before {
  content: '\E9C8';
}

.xgl-usage_counter_disabled_two_tone::after {
  content: '\E9C9';
  opacity: .2;
}

.xgl-usage_counter_two_tone::before {
  content: '\E530';
}

.xgl-usage_counter_two_tone::after {
  content: '\E531';
  opacity: .2;
}

.xgl-usb::before {
  content: '\E532';
}

.xgl-usb_c::before {
  content: '\E79F';
}

.xgl-usb_c_alt::before {
  content: '\E7A0';
}

.xgl-usb_c_two_tone::before {
  content: '\E79F';
}

.xgl-usb_c_two_tone::after {
  content: '\E7A0';
  opacity: .2;
}

.xgl-usb_micro::before {
  content: '\E7A1';
}

.xgl-usb_micro_alt::before {
  content: '\E7A2';
}

.xgl-usb_micro_two_tone::before {
  content: '\E7A1';
}

.xgl-usb_micro_two_tone::after {
  content: '\E7A2';
  opacity: .2;
}

.xgl-user::before {
  content: '\E533';
}

.xgl-user_add::before {
  content: '\E534';
}

.xgl-user_add_alt::before {
  content: '\E535';
}

.xgl-user_add_two_tone::before {
  content: '\E534';
}

.xgl-user_add_two_tone::after {
  content: '\E535';
  opacity: .2;
}

.xgl-user_admin::before {
  content: '\E536';
}

.xgl-user_admin_alt::before {
  content: '\E537';
}

.xgl-user_admin_two_tone::before {
  content: '\E536';
}

.xgl-user_admin_two_tone::after {
  content: '\E537';
  opacity: .2;
}

.xgl-user_alt::before {
  content: '\E538';
}

.xgl-user_check_on::before {
  content: '\E539';
}

.xgl-user_check_on_alt::before {
  content: '\E53A';
}

.xgl-user_check_on_two_tone::before {
  content: '\E539';
}

.xgl-user_check_on_two_tone::after {
  content: '\E53A';
  opacity: .2;
}

.xgl-user_diagnose::before {
  content: '\E53B';
}

.xgl-user_diagnose_alt::before {
  content: '\E53C';
}

.xgl-user_diagnose_two_tone::before {
  content: '\E53B';
}

.xgl-user_diagnose_two_tone::after {
  content: '\E53C';
  opacity: .2;
}

.xgl-user_disabled::before {
  content: '\E85D';
}

.xgl-user_disabled_alt::before {
  content: '\E85E';
}

.xgl-user_disabled_two_tone::before {
  content: '\E85D';
}

.xgl-user_disabled_two_tone::after {
  content: '\E85E';
  opacity: .2;
}

.xgl-user_favorite::before {
  content: '\E53D';
}

.xgl-user_favorite_alt::before {
  content: '\E53E';
}

.xgl-user_favorite_two_tone::before {
  content: '\E53D';
}

.xgl-user_favorite_two_tone::after {
  content: '\E53E';
  opacity: .2;
}

.xgl-user_job::before {
  content: '\E53F';
}

.xgl-user_job_alt::before {
  content: '\E540';
}

.xgl-user_job_two_tone::before {
  content: '\E53F';
}

.xgl-user_job_two_tone::after {
  content: '\E540';
  opacity: .2;
}

.xgl-user_network::before {
  content: '\E541';
}

.xgl-user_network_alt::before {
  content: '\E542';
}

.xgl-user_network_two_tone::before {
  content: '\E541';
}

.xgl-user_network_two_tone::after {
  content: '\E542';
  opacity: .2;
}

.xgl-user_password::before {
  content: '\E543';
}

.xgl-user_password_alt::before {
  content: '\E544';
}

.xgl-user_password_two_tone::before {
  content: '\E543';
}

.xgl-user_password_two_tone::after {
  content: '\E544';
  opacity: .2;
}

.xgl-user_permissions::before {
  content: '\EB78';
}

.xgl-user_permissions_alt::before {
  content: '\EB79';
}

.xgl-user_permissions_two_tone::before {
  content: '\EB78';
}

.xgl-user_permissions_two_tone::after {
  content: '\EB79';
  opacity: .2;
}

.xgl-user_personalize::before {
  content: '\E545';
}

.xgl-user_personalize_alt::before {
  content: '\E546';
}

.xgl-user_personalize_two_tone::before {
  content: '\E545';
}

.xgl-user_personalize_two_tone::after {
  content: '\E546';
  opacity: .2;
}

.xgl-user_save::before {
  content: '\E547';
}

.xgl-user_save_alt::before {
  content: '\E548';
}

.xgl-user_save_two_tone::before {
  content: '\E547';
}

.xgl-user_save_two_tone::after {
  content: '\E548';
  opacity: .2;
}

.xgl-user_secure::before {
  content: '\E549';
}

.xgl-user_secure_alt::before {
  content: '\E54A';
}

.xgl-user_secure_two_tone::before {
  content: '\E549';
}

.xgl-user_secure_two_tone::after {
  content: '\E54A';
  opacity: .2;
}

.xgl-user_send::before {
  content: '\EDF6';
}

.xgl-user_send_alt::before {
  content: '\EDF7';
}

.xgl-user_send_two_tone::before {
  content: '\EDF6';
}

.xgl-user_send_two_tone::after {
  content: '\EDF7';
  opacity: .2;
}

.xgl-user_square::before {
  content: '\E54B';
}

.xgl-user_square_alt::before {
  content: '\E54C';
}

.xgl-user_square_two_tone::before {
  content: '\E54B';
}

.xgl-user_square_two_tone::after {
  content: '\E54C';
  opacity: .2;
}

.xgl-user_subtract::before {
  content: '\E54D';
}

.xgl-user_subtract_alt::before {
  content: '\E54E';
}

.xgl-user_subtract_two_tone::before {
  content: '\E54D';
}

.xgl-user_subtract_two_tone::after {
  content: '\E54E';
  opacity: .2;
}

.xgl-user_two_tone::before {
  content: '\E533';
}

.xgl-user_two_tone::after {
  content: '\E538';
  opacity: .2;
}

.xgl-user_unknown::before {
  content: '\E8D3';
}

.xgl-user_unknown_alt::before {
  content: '\E8D4';
}

.xgl-user_unknown_two_tone::before {
  content: '\E8D3';
}

.xgl-user_unknown_two_tone::after {
  content: '\E8D4';
  opacity: .2;
}

.xgl-user_view::before {
  content: '\ECB3';
}

.xgl-user_view_alt::before {
  content: '\ECB4';
}

.xgl-user_view_two_tone::before {
  content: '\ECB3';
}

.xgl-user_view_two_tone::after {
  content: '\ECB4';
  opacity: .2;
}

.xgl-user_watermark::before {
  content: '\EBE4';
}

.xgl-user_watermark_alt::before {
  content: '\EBE3';
}

.xgl-user_watermark_two_tone::before {
  content: '\EBE4';
}

.xgl-user_watermark_two_tone::after {
  content: '\EBE3';
  opacity: .2;
}

.xgl-users::before {
  content: '\E653';
}

.xgl-users_alt::before {
  content: '\E654';
}

.xgl-users_two_tone::before {
  content: '\E653';
}

.xgl-users_two_tone::after {
  content: '\E654';
  opacity: .2;
}

.xgl-versant::before {
  content: '\EE2F';
}

.xgl-versant_alt::before {
  content: '\EE30';
}

.xgl-versant_two_tone::before {
  content: '\EE2F';
}

.xgl-versant_two_tone::after {
  content: '\EE30';
  opacity: .2;
}

.xgl-video::before {
  content: '\E54F';
}

.xgl-video_alt::before {
  content: '\E550';
}

.xgl-video_disabled::before {
  content: '\E551';
}

.xgl-video_disabled_alt::before {
  content: '\E552';
}

.xgl-video_disabled_two_tone::before {
  content: '\E551';
}

.xgl-video_disabled_two_tone::after {
  content: '\E552';
  opacity: .2;
}

.xgl-video_player::before {
  content: '\E553';
}

.xgl-video_player_alt::before {
  content: '\E554';
}

.xgl-video_player_two_tone::before {
  content: '\E553';
}

.xgl-video_player_two_tone::after {
  content: '\E554';
  opacity: .2;
}

.xgl-video_two_tone::before {
  content: '\E54F';
}

.xgl-video_two_tone::after {
  content: '\E550';
  opacity: .2;
}

.xgl-view::before {
  content: '\E555';
}

.xgl-view_alt::before {
  content: '\E556';
}

.xgl-view_disabled::before {
  content: '\E557';
}

.xgl-view_disabled_alt::before {
  content: '\E558';
}

.xgl-view_disabled_two_tone::before {
  content: '\E557';
}

.xgl-view_disabled_two_tone::after {
  content: '\E558';
  opacity: .2;
}

.xgl-view_lock::before {
  content: '\E8C9';
}

.xgl-view_lock_alt::before {
  content: '\E8CA';
}

.xgl-view_lock_two_tone::before {
  content: '\E8C9';
}

.xgl-view_lock_two_tone::after {
  content: '\E8CA';
  opacity: .2;
}

.xgl-view_orientation::before {
  content: '\ED25';
}

.xgl-view_orientation_alt::before {
  content: '\ED26';
}

.xgl-view_orientation_two_tone::before {
  content: '\ED25';
}

.xgl-view_orientation_two_tone::after {
  content: '\ED26';
  opacity: .2;
}

.xgl-view_side::before {
  content: '\ED27';
}

.xgl-view_side_alt::before {
  content: '\ED28';
}

.xgl-view_side_back::before {
  content: '\ED29';
}

.xgl-view_side_back_two_tone::before {
  content: '\ED29';
}

.xgl-view_side_back_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_bottom::before {
  content: '\ED2A';
}

.xgl-view_side_bottom_two_tone::before {
  content: '\ED2A';
}

.xgl-view_side_bottom_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_front::before {
  content: '\ED2B';
}

.xgl-view_side_front_two_tone::before {
  content: '\ED2B';
}

.xgl-view_side_front_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_left::before {
  content: '\ED2C';
}

.xgl-view_side_left_two_tone::before {
  content: '\ED2C';
}

.xgl-view_side_left_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_right::before {
  content: '\ED2D';
}

.xgl-view_side_right_two_tone::before {
  content: '\ED2D';
}

.xgl-view_side_right_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_top::before {
  content: '\ED2E';
}

.xgl-view_side_top_two_tone::before {
  content: '\ED2E';
}

.xgl-view_side_top_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_side_two_tone::before {
  content: '\ED27';
}

.xgl-view_side_two_tone::after {
  content: '\ED28';
  opacity: .2;
}

.xgl-view_three_d::before {
  content: '\ED2F';
}

.xgl-view_three_d_alt::before {
  content: '\ED30';
}

.xgl-view_three_d_two_tone::before {
  content: '\ED2F';
}

.xgl-view_three_d_two_tone::after {
  content: '\ED30';
  opacity: .2;
}

.xgl-view_trimetric::before {
  content: '\EE4C';
}

.xgl-view_trimetric_alt::before {
  content: '\EE4D';
}

.xgl-view_trimetric_two_tone::before {
  content: '\EE4C';
}

.xgl-view_trimetric_two_tone::after {
  content: '\EE4D';
  opacity: .2;
}

.xgl-view_two_d::before {
  content: '\ED31';
}

.xgl-view_two_d_alt::before {
  content: '\ED32';
}

.xgl-view_two_d_two_tone::before {
  content: '\ED31';
}

.xgl-view_two_d_two_tone::after {
  content: '\ED32';
  opacity: .2;
}

.xgl-view_two_tone::before {
  content: '\E555';
}

.xgl-view_two_tone::after {
  content: '\E556';
  opacity: .2;
}

.xgl-visa::before {
  content: '\ECB5';
}

.xgl-visa_alt::before {
  content: '\ECB6';
}

.xgl-visa_two_tone::before {
  content: '\ECB5';
}

.xgl-visa_two_tone::after {
  content: '\ECB6';
  opacity: .2;
}

.xgl-vision_low::before {
  content: '\E7B1';
}

.xgl-vision_low_alt::before {
  content: '\E7B2';
}

.xgl-vision_low_two_tone::before {
  content: '\E7B1';
}

.xgl-vision_low_two_tone::after {
  content: '\E7B2';
  opacity: .2;
}

.xgl-voice::before {
  content: '\E9CA';
}

.xgl-voice_alt::before {
  content: '\E9CB';
}

.xgl-voice_two_tone::before {
  content: '\E9CA';
}

.xgl-voice_two_tone::after {
  content: '\E9CB';
  opacity: .2;
}

.xgl-volume::before {
  content: '\E559';
}

.xgl-volume_alt::before {
  content: '\E55A';
}

.xgl-volume_high::before {
  content: '\E55B';
}

.xgl-volume_high_alt::before {
  content: '\E55C';
}

.xgl-volume_high_two_tone::before {
  content: '\E55B';
}

.xgl-volume_high_two_tone::after {
  content: '\E55C';
  opacity: .2;
}

.xgl-volume_low::before {
  content: '\E55D';
}

.xgl-volume_low_alt::before {
  content: '\E55E';
}

.xgl-volume_low_two_tone::before {
  content: '\E55D';
}

.xgl-volume_low_two_tone::after {
  content: '\E55E';
  opacity: .2;
}

.xgl-volume_measurement::before {
  content: '\EE31';
}

.xgl-volume_measurement_alt::before {
  content: '\EE32';
}

.xgl-volume_measurement_two_tone::before {
  content: '\EE31';
}

.xgl-volume_measurement_two_tone::after {
  content: '\EE32';
  opacity: .2;
}

.xgl-volume_med::before {
  content: '\E55F';
}

.xgl-volume_med_alt::before {
  content: '\E560';
}

.xgl-volume_med_two_tone::before {
  content: '\E55F';
}

.xgl-volume_med_two_tone::after {
  content: '\E560';
  opacity: .2;
}

.xgl-volume_mute::before {
  content: '\E561';
}

.xgl-volume_mute_alt::before {
  content: '\E562';
}

.xgl-volume_mute_two_tone::before {
  content: '\E561';
}

.xgl-volume_mute_two_tone::after {
  content: '\E562';
  opacity: .2;
}

.xgl-volume_two_tone::before {
  content: '\E559';
}

.xgl-volume_two_tone::after {
  content: '\E55A';
  opacity: .2;
}

.xgl-wait_print::before {
  content: '\EC62';
}

.xgl-wallet::before {
  content: '\E563';
}

.xgl-wallet_alt::before {
  content: '\E564';
}

.xgl-wallet_two_tone::before {
  content: '\E563';
}

.xgl-wallet_two_tone::after {
  content: '\E564';
  opacity: .2;
}

.xgl-wand::before {
  content: '\E565';
}

.xgl-wand_alt::before {
  content: '\E566';
}

.xgl-wand_two_tone::before {
  content: '\E565';
}

.xgl-wand_two_tone::after {
  content: '\E566';
  opacity: .2;
}

.xgl-warming_stack_bkgd::before {
  content: '\ECB7';
}

.xgl-warming_stack_frgd::before {
  content: '\ECB8';
}

.xgl-warming_stack_mdgd::before {
  content: '\ECB9';
}

.xgl-warning_stack_bkgd::before {
  content: '\E956';
}

.xgl-warning_stack_frgd::before {
  content: '\E958';
}

.xgl-warning_stack_mdgd::before {
  content: '\E957';
}

.xgl-waste::before {
  content: '\E64D';
}

.xgl-waste_paper::before {
  content: '\ECBF';
}

.xgl-waste_paper_alt::before {
  content: '\ECC0';
}

.xgl-waste_paper_two_tone::before {
  content: '\ECBF';
}

.xgl-waste_paper_two_tone::after {
  content: '\ECC0';
  opacity: .2;
}

.xgl-waste_square::before {
  content: '\E667';
}

.xgl-waste_square_alt::before {
  content: '\E668';
}

.xgl-waste_square_two_tone::before {
  content: '\E667';
}

.xgl-waste_square_two_tone::after {
  content: '\E668';
  opacity: .2;
}

.xgl-watch::before {
  content: '\E61C';
}

.xgl-watch_alt::before {
  content: '\E61D';
}

.xgl-watch_two_tone::before {
  content: '\E61C';
}

.xgl-watch_two_tone::after {
  content: '\E61D';
  opacity: .2;
}

.xgl-watermark::before {
  content: '\EBED';
}

.xgl-watermark_alt::before {
  content: '\EBEE';
}

.xgl-watermark_date::before {
  content: '\EBEB';
}

.xgl-watermark_date_alt::before {
  content: '\EBEC';
}

.xgl-watermark_date_two_tone::before {
  content: '\EBEB';
}

.xgl-watermark_date_two_tone::after {
  content: '\EBEC';
  opacity: .2;
}

.xgl-watermark_disabled::before {
  content: '\EBE5';
}

.xgl-watermark_disabled_alt::before {
  content: '\EBE6';
}

.xgl-watermark_disabled_two_tone::before {
  content: '\EBE5';
}

.xgl-watermark_disabled_two_tone::after {
  content: '\EBE6';
  opacity: .2;
}

.xgl-watermark_emboss::before {
  content: '\EBE9';
}

.xgl-watermark_emboss_alt::before {
  content: '\EBEA';
}

.xgl-watermark_emboss_two_tone::before {
  content: '\EBE9';
}

.xgl-watermark_emboss_two_tone::after {
  content: '\EBEA';
  opacity: .2;
}

.xgl-watermark_save::before {
  content: '\EBE7';
}

.xgl-watermark_save_alt::before {
  content: '\EBE8';
}

.xgl-watermark_save_two_tone::before {
  content: '\EBE7';
}

.xgl-watermark_save_two_tone::after {
  content: '\EBE8';
  opacity: .2;
}

.xgl-watermark_two_tone::before {
  content: '\EBED';
}

.xgl-watermark_two_tone::after {
  content: '\EBEE';
  opacity: .2;
}

.xgl-weight::before {
  content: '\E567';
}

.xgl-weight_alt::before {
  content: '\E568';
}

.xgl-weight_two_tone::before {
  content: '\E567';
}

.xgl-weight_two_tone::after {
  content: '\E568';
  opacity: .2;
}

.xgl-wifi::before {
  content: '\E569';
}

.xgl-wifi_bkgd::before {
  content: '\E959';
}

.xgl-wifi_direct::before {
  content: '\E56A';
}

.xgl-wifi_direct_two_tone::before {
  content: '\E56A';
}

.xgl-wifi_direct_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-wifi_disabled::before {
  content: '\E56B';
}

.xgl-wifi_disabled_two_tone::before {
  content: '\E56B';
}

.xgl-wifi_disabled_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-wifi_medium::before {
  content: '\E56C';
}

.xgl-wifi_medium_two_tone::before {
  content: '\E56C';
}

.xgl-wifi_medium_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-wifi_none::before {
  content: '\E56D';
}

.xgl-wifi_none_two_tone::before {
  content: '\E56D';
}

.xgl-wifi_none_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-wifi_secure_bkgd::before {
  content: '\E961';
}

.xgl-wifi_secure_medium::before {
  content: '\E56E';
}

.xgl-wifi_secure_medium_two_tone::before {
  content: '\E56E';
}

.xgl-wifi_secure_medium_two_tone::after {
  content: '\E961';
  opacity: .2;
}

.xgl-wifi_secure_none::before {
  content: '\E56F';
}

.xgl-wifi_secure_none_two_tone::before {
  content: '\E56F';
}

.xgl-wifi_secure_none_two_tone::after {
  content: '\E961';
  opacity: .2;
}

.xgl-wifi_secure_strong::before {
  content: '\E570';
}

.xgl-wifi_secure_strong_two_tone::before {
  content: '\E570';
}

.xgl-wifi_secure_strong_two_tone::after {
  content: '\E961';
  opacity: .2;
}

.xgl-wifi_secure_weak::before {
  content: '\E571';
}

.xgl-wifi_secure_weak_two_tone::before {
  content: '\E571';
}

.xgl-wifi_secure_weak_two_tone::after {
  content: '\E961';
  opacity: .2;
}

.xgl-wifi_square::before {
  content: '\E572';
}

.xgl-wifi_square_alt::before {
  content: '\E573';
}

.xgl-wifi_square_two_tone::before {
  content: '\E572';
}

.xgl-wifi_square_two_tone::after {
  content: '\E573';
  opacity: .2;
}

.xgl-wifi_two_tone::before {
  content: '\E569';
}

.xgl-wifi_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-wifi_weak::before {
  content: '\E574';
}

.xgl-wifi_weak_two_tone::before {
  content: '\E574';
}

.xgl-wifi_weak_two_tone::after {
  content: '\E959';
  opacity: .2;
}

.xgl-window::before {
  content: '\E575';
}

.xgl-window_alt::before {
  content: '\E576';
}

.xgl-window_two_tone::before {
  content: '\E575';
}

.xgl-window_two_tone::after {
  content: '\E576';
  opacity: .2;
}

.xgl-windows_live::before {
  content: '\EB84';
}

.xgl-windows_live_alt::before {
  content: '\EB85';
}

.xgl-windows_live_two_tone::before {
  content: '\EB84';
}

.xgl-windows_live_two_tone::after {
  content: '\EB85';
  opacity: .2;
}

.xgl-windows_phone::before {
  content: '\EC24';
}

.xgl-windows_phone_alt::before {
  content: '\EB87';
}

.xgl-windows_phone_two_tone::before {
  content: '\EC24';
}

.xgl-windows_phone_two_tone::after {
  content: '\EB87';
  opacity: .2;
}

.xgl-wired::before {
  content: '\EBE2';
}

.xgl-wired_alt::before {
  content: '\EBE1';
}

.xgl-wired_two_tone::before {
  content: '\EBE2';
}

.xgl-wired_two_tone::after {
  content: '\EBE1';
  opacity: .2;
}

.xgl-wireframe::before {
  content: '\ED33';
}

.xgl-wireframe_alt::before {
  content: '\ED34';
}

.xgl-wireframe_two_tone::before {
  content: '\ED33';
}

.xgl-wireframe_two_tone::after {
  content: '\ED34';
  opacity: .2;
}

.xgl-workflow::before {
  content: '\E578';
}

.xgl-workflow_add::before {
  content: '\E579';
}

.xgl-workflow_add_alt::before {
  content: '\E57A';
}

.xgl-workflow_add_two_tone::before {
  content: '\E579';
}

.xgl-workflow_add_two_tone::after {
  content: '\E57A';
  opacity: .2;
}

.xgl-workflow_alt::before {
  content: '\E57B';
}

.xgl-workflow_central::before {
  content: '\ED44';
}

.xgl-workflow_central_alt::before {
  content: '\ED45';
}

.xgl-workflow_central_two_tone::before {
  content: '\ED44';
}

.xgl-workflow_central_two_tone::after {
  content: '\ED45';
  opacity: .2;
}

.xgl-workflow_two_tone::before {
  content: '\E578';
}

.xgl-workflow_two_tone::after {
  content: '\E57B';
  opacity: .2;
}

.xgl-workspace::before {
  content: '\E925';
}

.xgl-workspace_alt::before {
  content: '\E926';
}

.xgl-workspace_two_tone::before {
  content: '\E925';
}

.xgl-workspace_two_tone::after {
  content: '\E926';
  opacity: .2;
}

.xgl-world::before {
  content: '\E57C';
}

.xgl-world_alt::before {
  content: '\E57D';
}

.xgl-world_two_tone::before {
  content: '\E57C';
}

.xgl-world_two_tone::after {
  content: '\E57D';
  opacity: .2;
}

.xgl-wps_logo::before {
  content: '\E94F';
}

.xgl-wps_logo_alt::before {
  content: '\E950';
}

.xgl-wps_logo_two_tone::before {
  content: '\E94F';
}

.xgl-wps_logo_two_tone::after {
  content: '\E950';
  opacity: .2;
}

.xgl-wrench::before {
  content: '\E57E';
}

.xgl-wrench_alt::before {
  content: '\E57F';
}

.xgl-wrench_phone::before {
  content: '\E580';
}

.xgl-wrench_phone_alt::before {
  content: '\E581';
}

.xgl-wrench_phone_two_tone::before {
  content: '\E580';
}

.xgl-wrench_phone_two_tone::after {
  content: '\E581';
  opacity: .2;
}

.xgl-wrench_square::before {
  content: '\E582';
}

.xgl-wrench_square_alt::before {
  content: '\E583';
}

.xgl-wrench_square_two_tone::before {
  content: '\E582';
}

.xgl-wrench_square_two_tone::after {
  content: '\E583';
  opacity: .2;
}

.xgl-wrench_two_tone::before {
  content: '\E57E';
}

.xgl-wrench_two_tone::after {
  content: '\E57F';
  opacity: .2;
}

.xgl-xds::before {
  content: '\E7FB';
}

.xgl-xds_alt::before {
  content: '\E806';
}

.xgl-xds_two_tone::before {
  content: '\E7FB';
}

.xgl-xds_two_tone::after {
  content: '\E806';
  opacity: .2;
}

.xgl-xerox_logo::before {
  content: '\E89F';
}

.xgl-xerox_logo_sans_tm::before {
  content: '\E8F2';
}

.xgl-xerox_logo_tm::before {
  content: '\E8F1';
}

.xgl-xids::before {
  content: '\E8A7';
}

.xgl-yahoo::before {
  content: '\EB7A';
}

.xgl-yahoo_alt::before {
  content: '\EB7B';
}

.xgl-yahoo_two_tone::before {
  content: '\EB7A';
}

.xgl-yahoo_two_tone::after {
  content: '\EB7B';
  opacity: .2;
}

.xgl-youtube::before {
  content: '\E584';
}

.xgl-youtube_alt::before {
  content: '\EC8A';
}

.xgl-youtube_two_tone::before {
  content: '\E584';
}

.xgl-youtube_two_tone::after {
  content: '\EC8A';
  opacity: .2;
}

.xgl-zoom_in::before {
  content: '\E585';
}

.xgl-zoom_in_alt::before {
  content: '\E586';
}

.xgl-zoom_in_two_tone::before {
  content: '\E585';
}

.xgl-zoom_in_two_tone::after {
  content: '\E586';
  opacity: .2;
}

.xgl-zoom_out::before {
  content: '\E587';
}

.xgl-zoom_out_alt::before {
  content: '\E588';
}

.xgl-zoom_out_two_tone::before {
  content: '\E587';
}

.xgl-zoom_out_two_tone::after {
  content: '\E588';
  opacity: .2;
}

.xgl-zoom_to_fit::before {
  content: '\EE4E';
}

.xgl-zoom_to_fit_alt::before {
  content: '\EE4F';
}

.xgl-zoom_to_fit_two_tone::before {
  content: '\EE4E';
}

.xgl-zoom_to_fit_two_tone::after {
  content: '\EE4F';
  opacity: .2;
}

.xgl-zoom_to_window::before {
  content: '\ED35';
}

.xgl-zoom_to_window_alt::before {
  content: '\ED36';
}

.xgl-zoom_to_window_two_tone::before {
  content: '\ED35';
}

.xgl-zoom_to_window_two_tone::after {
  content: '\ED36';
  opacity: .2;
}

.xgl-zoom_window::before {
  content: '\ED37';
}

.xgl-zoom_window_alt::before {
  content: '\ED38';
}

.xgl-zoom_window_two_tone::before {
  content: '\ED37';
}

.xgl-zoom_window_two_tone::after {
  content: '\ED38';
  opacity: .2;
}

.stacked-icon-size-16 {
  width: 16px;
  height: 16px;
}

.stacked-icon-size-20 {
  width: 20px;
  height: 20px;
}

.stacked-icon-size-24 {
  width: 24px;
  height: 24px;
}

.stacked-icon-size-32 {
  width: 32px;
  height: 32px;
}

.stacked-icon-size-36 {
  width: 36px;
  height: 36px;
}

.stacked-icon-size-48 {
  width: 48px;
  height: 48px;
}

.stacked-icon-size-64 {
  width: 64px;
  height: 64px;
}

.xrx-stacked-icon {
  position: relative;
}

.xrx-stacked-icon [class*="xgl-"] {
  position: absolute;
}

.xgl-success_stack_mdgd {
  color: #24882b;
}

.xgl-success_stack_frgd {
  color: #ffffff;
}

.xgl-danger_stack_mdgd {
  color: #d92231;
}

.xgl-danger_stack_frgd {
  color: #ffffff;
}

.xgl-warning_stack_mdgd {
  color: #fd9726;
}

.xgl-warning_stack_frgd {
  color: #000000;
}

.xrx-theme-appearance .xgl-success_stack_mdgd,
.xrx-dark-appearance .xgl-success_stack_mdgd {
  color: #7bc76f;
}

.xrx-theme-appearance .xgl-success_stack_frgd,
.xrx-dark-appearance .xgl-success_stack_frgd {
  color: #000000;
}

.xrx-theme-appearance .xgl-danger_stack_mdgd,
.xrx-dark-appearance .xgl-danger_stack_mdgd {
  color: #ff808b;
}

.xrx-theme-appearance .xgl-danger_stack_frgd,
.xrx-dark-appearance .xgl-danger_stack_frgd {
  color: #000000;
}

.xrx-theme-appearance .xgl-warning_stack_mdgd,
.xrx-dark-appearance .xgl-warning_stack_mdgd {
  color: #ffda7b;
}

.xrx-theme-appearance .xgl-warning_stack_frgd,
.xrx-dark-appearance .xgl-warning_stack_frgd {
  color: #000000;
}

.svg-defs {
  position: fixed;
  left: -2000px;
  width: 0;
  height: 0;
}

label {
  margin-bottom: 0.1875rem;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .25px;
}

.form-text {
  display: flex;
  align-items: center;
  margin-top: 0.1875rem;
  margin-bottom: 1rem;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: .30px;
  color: #525256;
}

.form-text.multiline {
  display: block;
}

.form-text .message-container {
  display: flex;
  align-items: center;
}

.xrx-input {
  height: 48px;
  padding: 3px;
  margin-bottom: 1rem;
  background-color: #fafaff;
  border: 1px solid #dbdbe3;
  border-radius: 6px;
  transition: background-color .165s, box-shadow .165s;
}

.xrx-input.input-hover {
  background-color: #dbdbe3;
}

.xrx-input.input-focus-first-click:not(.input-active) {
  background-color: #fafaff;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.xrx-input.input-active {
  background-color: #bbbcc3;
}

.xrx-input.input-focus {
  background-color: #fafaff;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.xrx-input.input-readonly {
  background-color: #f2f2f9;
}

.xrx-input.input-disabled {
  opacity: .3;
  cursor: not-allowed;
}

.xrx-input.input-disabled input,
.xrx-input.input-disabled .input-group {
  pointer-events: none;
}

.label-disabled,
.form-text-disabled {
  opacity: .3;
  cursor: not-allowed;
}

.xrx-input .form-control {
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 11px 0.375rem 11px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15px;
  line-height: 1.2;
  color: #000000;
  background-color: transparent !important;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #000000;
  background-color: #fafaff;
  border-color: #155fbb;
  outline: 0;
  box-shadow: inset 0 0 0 1px #155fbb;
}

.form-control::placeholder {
  color: #9e9fa4;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.5625rem;
  line-height: 1.65;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.25;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form-text .xrx-stacked-icon {
  margin-right: 6px;
}

.xrx-input.input-validation-success {
  border-color: #24882b;
}

.xrx-input.input-validation-success + .form-text .message {
  color: #186a1e;
}

.form-text .success-message {
  color: #186a1e !important;
}

.xrx-input.input-focus-first-click.input-validation-success:not(.input-active) {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.xrx-input.input-focus.input-validation-success {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.xrx-input.input-validation-danger {
  border-color: #d92231;
}

.xrx-input.input-validation-danger + .form-text .message {
  color: #9f0b14;
}

.form-text .danger-message {
  color: #9f0b14 !important;
}

.xrx-input.input-focus-first-click.input-validation-danger:not(.input-active) {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.xrx-input.input-focus.input-validation-danger {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.xrx-input.input-validation-warning {
  border-color: #fd9726;
}

.xrx-input.input-validation-warning + .form-text .message {
  color: #955503;
}

.form-text .warning-message {
  color: #955503 !important;
}

.xrx-input.input-focus-first-click.input-validation-warning:not(.input-active) {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.xrx-input.input-focus.input-validation-warning {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.form-helper-text {
  margin-bottom: 0 !important;
}

.xrx-theme-appearance label,
.xrx-theme-appearance .form-control {
  color: #ffffff;
}

.xrx-theme-appearance .form-text {
  color: #91b6ec;
}

.xrx-theme-appearance .form-control::placeholder {
  color: #5f94dc;
}

.xrx-theme-appearance .xrx-input {
  background-color: #155fbb;
  border-color: #01377f;
}

.xrx-theme-appearance .xrx-input.input-hover {
  background-color: #01377f;
}

.xrx-theme-appearance .xrx-input.input-focus-first-click:not(.input-active) {
  background-color: #155fbb;
}

.xrx-theme-appearance .xrx-input.input-active {
  background-color: #002760;
}

.xrx-theme-appearance .xrx-input.input-focus {
  background-color: #155fbb;
}

.xrx-theme-appearance .xrx-input.input-readonly {
  background-color: #0c4fa3;
}

.xrx-theme-appearance .xrx-input.input-validation-success {
  border-color: #7bc76f;
}

.xrx-theme-appearance .xrx-input.input-validation-success + .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .xrx-input .form-text .success-message {
  color: #91b6ec !important;
}

.xrx-theme-appearance .xrx-input.input-validation-danger {
  border-color: #ff808b;
}

.xrx-theme-appearance .xrx-input.input-validation-danger + .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .xrx-input .form-text .danger-message {
  color: #91b6ec !important;
}

.xrx-theme-appearance .xrx-input.input-validation-warning {
  border-color: #ffda7b;
}

.xrx-theme-appearance .xrx-input.input-validation-warning + .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .xrx-input .form-text .warning-message {
  color: #91b6ec !important;
}

.xrx-dark-appearance label,
.xrx-dark-appearance .form-control {
  color: #ffffff;
}

.xrx-dark-appearance .form-text {
  color: #bbbcc3;
}

.xrx-dark-appearance .form-control::placeholder {
  color: #818187;
}

.xrx-dark-appearance .xrx-input {
  background-color: #3f3f43;
  border-color: #1b1b1e;
}

.xrx-dark-appearance .xrx-input.input-hover {
  background-color: #1b1b1e;
}

.xrx-dark-appearance .xrx-input.input-focus-first-click:not(.input-active) {
  background-color: #3f3f43;
}

.xrx-dark-appearance .xrx-input.input-active {
  background-color: #0e0e10;
}

.xrx-dark-appearance .xrx-input.input-focus {
  background-color: #3f3f43;
}

.xrx-dark-appearance .xrx-input.input-readonly {
  background-color: #29292d;
}

.xrx-dark-appearance .xrx-input.input-validation-success {
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-input.input-validation-success + .form-text .message {
  color: #7bc76f;
}

.xrx-dark-appearance .xrx-input .form-text .success-message {
  color: #7bc76f !important;
}

.xrx-dark-appearance .xrx-input.input-validation-danger {
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-input.input-validation-danger + .form-text .message {
  color: #ff808b;
}

.xrx-dark-appearance .xrx-input .form-text .danger-message {
  color: #ff808b !important;
}

.xrx-dark-appearance .xrx-input.input-validation-warning {
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-input.input-validation-warning + .form-text .message {
  color: #ffda7b;
}

.xrx-dark-appearance .xrx-input .form-text .warning-message {
  color: #ffda7b !important;
}

.xrx-dark-appearance .xrx-theme-appearance label,
.xrx-dark-appearance .xrx-theme-appearance .form-control {
  color: #ffffff;
}

.xrx-dark-appearance .xrx-theme-appearance .form-text {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .form-control::placeholder {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input {
  background-color: #0c4fa3;
  border-color: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-hover {
  background-color: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-focus-first-click:not(.input-active) {
  background-color: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-active {
  background-color: #00173c;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-focus {
  background-color: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-readonly {
  background-color: #01377f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-success {
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-success + .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input .form-text .success-message {
  color: #91b6ec !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-danger {
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-danger + .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input .form-text .danger-message {
  color: #91b6ec !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-warning {
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input.input-validation-warning + .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-input .form-text .warning-message {
  color: #91b6ec !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .input-group-xgl-icon {
  margin: 0 11px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
  align-items: center;
  color: #9e9fa4;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend .xrx-btn:last-child {
  margin-right: 10px;
}

.input-group-prepend ~ input {
  padding-left: 0;
}

.input-group-append .input-group-text {
  padding-left: 0;
}

.input-group-append .input-group-xgl-icon {
  margin-left: 0;
}

.input-group-append .xrx-stacked-icon {
  margin-left: 11px;
  margin-right: 9px;
}

.input-group-divider {
  width: 1px;
  height: 24px;
  background-color: #dbdbe3;
}

.input-group-divider ~ .input-group-text {
  margin-left: 13px;
}

.input-group-divider ~ .xrx-btn {
  margin-left: 16px;
}

.input-group-divider ~ .input-group-xgl-icon {
  margin-left: 15px;
}

.input-group-text {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15px;
  line-height: 1.5;
  color: #9e9fa4;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: none;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-text.input-group-at-sign {
  top: -1px;
}

.xrx-theme-appearance .input-group-prepend,
.xrx-theme-appearance .input-group-append {
  color: #5f94dc;
}

.xrx-dark-appearance .input-group-prepend,
.xrx-dark-appearance .input-group-append {
  color: #9e9fa4;
}

.xrx-dark-appearance .xrx-theme-appearance .input-group-prepend,
.xrx-dark-appearance .xrx-theme-appearance .input-group-append {
  color: #5f94dc;
}

.xrx-header {
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 16px;
  background-color: #dbdbe3;
  color: #000000;
  font-weight: 700;
}

.xrx-header.header-secondary {
  background-color: #ffffff;
}

.xrx-header.header-primary {
  background-color: #bad1f5;
}

.xrx-header.header-success {
  background-color: #caf7ba;
}

.xrx-header.header-warning {
  background-color: #fff3bb;
}

.xrx-header.header-danger {
  background-color: #ffcbcf;
}

.xrx-header.header-info {
  background-color: #bcefff;
}

.xrx-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  color: #000000;
  background-color: #fafaff;
  box-shadow: inset 0 1px 0 0 #ffffff, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
  height: 3rem;
  padding: 0 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}

@media (hover) {
  .xrx-btn:hover:not(:focus) {
    color: #000000;
    background-color: #fafaff;
    box-shadow: inset 0 1px 0 0 #ffffff, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn:hover {
    background-color: #fafaff;
    box-shadow: inset 0 1px 0 0 #ffffff, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffffff, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffffff, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn:not(:disabled):not(.disabled):active, .xrx-btn:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #bbbcc3;
  box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 #ffffff, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.xrx-btn.disabled, .xrx-btn:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(187, 188, 195, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-inline {
  height: 40px;
  padding: 0 12px;
}

.xrx-btn.btn-inline.btn-glyph {
  padding: 0 10px;
}

.xrx-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.xrx-btn:disabled, .xrx-btn.disabled {
  cursor: not-allowed;
}

a.xrx-btn {
  line-height: 3rem;
}

a.xrx-btn.disabled,
fieldset:disabled a.xrx-btn {
  pointer-events: none;
}

.xrx-btn.btn-primary {
  color: #ffffff;
  background-color: #155fbb;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-primary:hover:not(:focus) {
    color: #ffffff;
    background-color: #155fbb;
    box-shadow: inset 0 1px 0 0 #2f74ca, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-primary:hover {
    background-color: #155fbb;
    box-shadow: inset 0 1px 0 0 #2f74ca, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-primary:not(:disabled):not(.disabled):active, .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #01377f;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-primary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-btn.btn-primary.disabled, .xrx-btn.btn-primary:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-secondary {
  color: #000000;
  background-color: #bbbcc3;
  box-shadow: inset 0 1px 0 0 #dbdbe3, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-secondary:hover:not(:focus) {
    color: #000000;
    background-color: #bbbcc3;
    box-shadow: inset 0 1px 0 0 #dbdbe3, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-secondary:hover {
    background-color: #bbbcc3;
    box-shadow: inset 0 1px 0 0 #dbdbe3, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-secondary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #dbdbe3, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-btn.btn-secondary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #dbdbe3, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-secondary:not(:disabled):not(.disabled):active, .xrx-btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #818187;
  box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 #dbdbe3, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-secondary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-secondary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.xrx-btn.btn-secondary.disabled, .xrx-btn.btn-secondary:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(187, 188, 195, 0.3) !important;
  box-shadow: none !important;
}

.xrx-theme-appearance .xrx-btn {
  color: #ffffff;
  background-color: #155fbb;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-theme-appearance .xrx-btn:hover:not(:focus) {
    color: #ffffff;
    background-color: #155fbb;
    box-shadow: inset 0 1px 0 0 #2f74ca, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-theme-appearance .xrx-btn:hover {
    background-color: #155fbb;
    box-shadow: inset 0 1px 0 0 #2f74ca, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-theme-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-theme-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

.xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled):active, .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #01377f;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled):active,
.using-mouse .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-theme-appearance .xrx-btn.disabled, .xrx-theme-appearance .xrx-btn:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-theme-appearance .xrx-btn.btn-primary {
  color: #000000;
  background-color: #91b6ec;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-theme-appearance .xrx-btn.btn-primary:hover:not(:focus) {
    color: #000000;
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-theme-appearance .xrx-btn.btn-primary:hover {
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-theme-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

.xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active, .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #5f94dc;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #bad1f5, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-theme-appearance .xrx-btn.btn-primary.disabled, .xrx-theme-appearance .xrx-btn.btn-primary:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis {
  color: #000000;
  background-color: #91b6ec;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:hover:not(:focus) {
    color: #000000;
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 16px 24px 5px rgba(0, 0, 0, 0.32), 0 0 16px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:hover {
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 16px 24px 5px rgba(0, 0, 0, 0.32), 0 0 16px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled):active, .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #5f94dc;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #bad1f5, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled):active,
.using-mouse .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis.disabled, .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-success {
  color: #ffffff;
  background-color: #24882b;
  box-shadow: inset 0 1px 0 0 #3ea33e, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-success:hover:not(:focus) {
    color: #ffffff;
    background-color: #24882b;
    box-shadow: inset 0 1px 0 0 #3ea33e, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-success:hover {
    background-color: #24882b;
    box-shadow: inset 0 1px 0 0 #3ea33e, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-success:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #3ea33e, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-btn.btn-success:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #3ea33e, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-success:not(:disabled):not(.disabled):active, .xrx-btn.btn-success:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #0f5615;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #3ea33e, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-success:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-success:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-btn.btn-success.disabled, .xrx-btn.btn-success:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(36, 136, 43, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-warning {
  color: #000000;
  background-color: #fd9726;
  box-shadow: inset 0 1px 0 0 #ffaf3d, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-warning:hover:not(:focus) {
    color: #000000;
    background-color: #fd9726;
    box-shadow: inset 0 1px 0 0 #ffaf3d, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-warning:hover {
    background-color: #fd9726;
    box-shadow: inset 0 1px 0 0 #ffaf3d, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-warning:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffaf3d, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-btn.btn-warning:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffaf3d, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-warning:not(:disabled):not(.disabled):active, .xrx-btn.btn-warning:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #ba6a0c;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #ffaf3d, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-warning:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-warning:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-btn.btn-warning.disabled, .xrx-btn.btn-warning:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(253, 151, 38, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-info {
  color: #ffffff;
  background-color: #177896;
  box-shadow: inset 0 1px 0 0 #3192b0, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-info:hover:not(:focus) {
    color: #ffffff;
    background-color: #177896;
    box-shadow: inset 0 1px 0 0 #3192b0, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-info:hover {
    background-color: #177896;
    box-shadow: inset 0 1px 0 0 #3192b0, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-info:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #3192b0, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
}

.using-mouse .xrx-btn.btn-info:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #3192b0, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-info:not(:disabled):not(.disabled):active, .xrx-btn.btn-info:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #08546e;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #3192b0, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-info:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-info:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-btn.btn-info.disabled, .xrx-btn.btn-info:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(23, 120, 150, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-danger {
  color: #ffffff;
  background-color: #d92231;
  box-shadow: inset 0 1px 0 0 #ed3745, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-danger:hover:not(:focus) {
    color: #ffffff;
    background-color: #d92231;
    box-shadow: inset 0 1px 0 0 #ed3745, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-danger:hover {
    background-color: #d92231;
    box-shadow: inset 0 1px 0 0 #ed3745, 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.xrx-btn.btn-danger:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ed3745, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-btn.btn-danger:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ed3745, 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-btn.btn-danger:not(:disabled):not(.disabled):active, .xrx-btn.btn-danger:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #9f0b14;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #ed3745, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-danger:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-danger:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-btn.btn-danger.disabled, .xrx-btn.btn-danger:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(217, 34, 49, 0.3) !important;
  box-shadow: none !important;
}

.xrx-btn.btn-borderless {
  color: #000000;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-borderless:hover:not(:focus) {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-borderless:hover {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-borderless.disabled, .xrx-btn.btn-borderless:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-btn.btn-primary.btn-borderless {
  color: #155fbb;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-primary.btn-borderless:hover:not(:focus) {
    color: #155fbb;
    background-color: rgba(21, 95, 187, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-primary.btn-borderless:hover {
    background-color: rgba(21, 95, 187, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-primary.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-btn.btn-primary.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled).active {
  color: #155fbb;
  background-color: rgba(21, 95, 187, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-primary.btn-borderless.disabled, .xrx-btn.btn-primary.btn-borderless:disabled {
  color: rgba(21, 95, 187, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-theme-appearance .xrx-btn.btn-borderless {
  color: #ffffff;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-theme-appearance .xrx-btn.btn-borderless:hover:not(:focus) {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-theme-appearance .xrx-btn.btn-borderless:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

.xrx-theme-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active, .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-theme-appearance .xrx-btn.btn-borderless.disabled, .xrx-theme-appearance .xrx-btn.btn-borderless:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-btn.btn-success.btn-borderless {
  color: #186a1e;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-success.btn-borderless:hover:not(:focus) {
    color: #186a1e;
    background-color: rgba(36, 136, 43, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-success.btn-borderless:hover {
    background-color: rgba(36, 136, 43, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-success.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-btn.btn-success.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled).active {
  color: #186a1e;
  background-color: rgba(36, 136, 43, 0.2);
  box-shadow: none, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-success.btn-borderless.disabled, .xrx-btn.btn-success.btn-borderless:disabled {
  color: rgba(24, 106, 30, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-btn.btn-warning.btn-borderless {
  color: #955503;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-warning.btn-borderless:hover:not(:focus) {
    color: #955503;
    background-color: rgba(253, 151, 38, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-warning.btn-borderless:hover {
    background-color: rgba(253, 151, 38, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-warning.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-btn.btn-warning.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled).active {
  color: #955503;
  background-color: rgba(253, 151, 38, 0.2);
  box-shadow: none, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-warning.btn-borderless.disabled, .xrx-btn.btn-warning.btn-borderless:disabled {
  color: rgba(149, 85, 3, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-btn.btn-info.btn-borderless {
  color: #0e6682;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-info.btn-borderless:hover:not(:focus) {
    color: #0e6682;
    background-color: rgba(23, 120, 150, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-info.btn-borderless:hover {
    background-color: rgba(23, 120, 150, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-info.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
}

.using-mouse .xrx-btn.btn-info.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled).active {
  color: #0e6682;
  background-color: rgba(23, 120, 150, 0.2);
  box-shadow: none, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-info.btn-borderless.disabled, .xrx-btn.btn-info.btn-borderless:disabled {
  color: rgba(14, 102, 130, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-btn.btn-danger.btn-borderless {
  color: #9f0b14;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-btn.btn-danger.btn-borderless:hover:not(:focus) {
    color: #9f0b14;
    background-color: rgba(217, 34, 49, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-btn.btn-danger.btn-borderless:hover {
    background-color: rgba(217, 34, 49, 0.1);
    box-shadow: none;
  }
}

.xrx-btn.btn-danger.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-btn.btn-danger.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled):active, .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled).active {
  color: #9f0b14;
  background-color: rgba(217, 34, 49, 0.2);
  box-shadow: none, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-btn.btn-danger.btn-borderless.disabled, .xrx-btn.btn-danger.btn-borderless:disabled {
  color: rgba(159, 11, 20, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn {
  color: #ffffff;
  background-color: #3f3f43;
  box-shadow: inset 0 1px 0 0 #525256, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn:hover:not(:focus) {
    color: #ffffff;
    background-color: #3f3f43;
    box-shadow: inset 0 1px 0 0 #525256, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn:hover {
    background-color: #3f3f43;
    box-shadow: inset 0 1px 0 0 #525256, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #525256, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #525256, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #1b1b1e;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #525256, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.disabled, .xrx-dark-appearance .xrx-btn:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(82, 82, 86, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-primary {
  color: #000000;
  background-color: #91b6ec;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-primary:hover:not(:focus) {
    color: #000000;
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-primary:hover {
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #5f94dc;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #2f74ca, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-primary.disabled, .xrx-dark-appearance .xrx-btn.btn-primary:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-secondary {
  color: #ffffff;
  background-color: #67676c;
  box-shadow: inset 0 1px 0 0 #818187, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-secondary:hover:not(:focus) {
    color: #ffffff;
    background-color: #67676c;
    box-shadow: inset 0 1px 0 0 #818187, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-secondary:hover {
    background-color: #67676c;
    box-shadow: inset 0 1px 0 0 #818187, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-secondary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #818187, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-secondary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #818187, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-secondary:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-secondary:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #3f3f43;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #818187, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-secondary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-secondary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-secondary.disabled, .xrx-dark-appearance .xrx-btn.btn-secondary:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(82, 82, 86, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-success {
  color: #000000;
  background-color: #7bc76f;
  box-shadow: inset 0 1px 0 0 #a4e195, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-success:hover:not(:focus) {
    color: #000000;
    background-color: #7bc76f;
    box-shadow: inset 0 1px 0 0 #a4e195, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-success:hover {
    background-color: #7bc76f;
    box-shadow: inset 0 1px 0 0 #a4e195, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-success:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #a4e195, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-success:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #a4e195, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-success:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-success:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #3ea33e;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #a4e195, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-success:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-success:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-success.disabled, .xrx-dark-appearance .xrx-btn.btn-success:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(123, 199, 111, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-warning {
  color: #000000;
  background-color: #ffda7b;
  box-shadow: inset 0 1px 0 0 #ffea9e, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-warning:hover:not(:focus) {
    color: #000000;
    background-color: #ffda7b;
    box-shadow: inset 0 1px 0 0 #ffea9e, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-warning:hover {
    background-color: #ffda7b;
    box-shadow: inset 0 1px 0 0 #ffea9e, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-warning:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffea9e, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffea9e, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-warning:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-warning:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #ffaf3d;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #ffea9e, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-warning.disabled, .xrx-dark-appearance .xrx-btn.btn-warning:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(255, 218, 123, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-info {
  color: #000000;
  background-color: #64bbd2;
  box-shadow: inset 0 1px 0 0 #87d8ec, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-info:hover:not(:focus) {
    color: #000000;
    background-color: #64bbd2;
    box-shadow: inset 0 1px 0 0 #87d8ec, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-info:hover {
    background-color: #64bbd2;
    box-shadow: inset 0 1px 0 0 #87d8ec, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-info:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #87d8ec, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-info:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #87d8ec, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-info:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-info:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #3192b0;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #87d8ec, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-info:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-info:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-info.disabled, .xrx-dark-appearance .xrx-btn.btn-info:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(100, 187, 210, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-danger {
  color: #000000;
  background-color: #ff808b;
  box-shadow: inset 0 1px 0 0 #ffa1a9, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-danger:hover:not(:focus) {
    color: #000000;
    background-color: #ff808b;
    box-shadow: inset 0 1px 0 0 #ffa1a9, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-danger:hover {
    background-color: #ff808b;
    box-shadow: inset 0 1px 0 0 #ffa1a9, 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.xrx-dark-appearance .xrx-btn.btn-danger:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffa1a9, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #ffa1a9, 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-btn.btn-danger:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-danger:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #ed3745;
  box-shadow: inset 0 1px 2px 0 black, inset 0 1px 0 0 #ffa1a9, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-btn.btn-danger.disabled, .xrx-dark-appearance .xrx-btn.btn-danger:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(255, 128, 139, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn {
  color: #ffffff;
  background-color: #0c4fa3;
  box-shadow: inset 0 1px 0 0 #155fbb, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:hover:not(:focus) {
    color: #ffffff;
    background-color: #0c4fa3;
    box-shadow: inset 0 1px 0 0 #155fbb, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:hover {
    background-color: #0c4fa3;
    box-shadow: inset 0 1px 0 0 #155fbb, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #155fbb, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #002760;
  box-shadow: inset 0 1px 2px 0 black, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.disabled, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: rgba(21, 95, 187, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary {
  color: #000000;
  background-color: #91b6ec;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:hover:not(:focus) {
    color: #000000;
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:hover {
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #5f94dc;
  box-shadow: inset 0 1px 2px 0 black, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.disabled, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis {
  color: #000000;
  background-color: #91b6ec;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:hover:not(:focus) {
    color: #000000;
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 16px 24px 5px rgba(0, 0, 0, 0.32), 0 0 16px 0 rgba(0, 0, 0, 0.16);
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:hover {
    background-color: #91b6ec;
    box-shadow: inset 0 1px 0 0 #bad1f5, 0 16px 24px 5px rgba(0, 0, 0, 0.32), 0 0 16px 0 rgba(0, 0, 0, 0.16);
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:focus {
  outline: none;
  box-shadow: inset 0 1px 0 0 #bad1f5, 0 4px 8px 3px rgba(0, 0, 0, 0.32), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled).active {
  color: #000000;
  background-color: #5f94dc;
  box-shadow: inset 0 1px 2px 0 black, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis.disabled, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis:disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(47, 116, 202, 0.3) !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-borderless {
  color: #ffffff;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-borderless:hover:not(:focus) {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-borderless:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-borderless:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless {
  color: #91b6ec;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:hover:not(:focus) {
    color: #91b6ec;
    background-color: rgba(145, 182, 236, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:hover {
    background-color: rgba(145, 182, 236, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled).active {
  color: #91b6ec;
  background-color: rgba(145, 182, 236, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless:disabled {
  color: rgba(145, 182, 236, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-success.btn-borderless {
  color: #7bc76f;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:hover:not(:focus) {
    color: #7bc76f;
    background-color: rgba(123, 199, 111, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:hover {
    background-color: rgba(123, 199, 111, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled).active {
  color: #7bc76f;
  background-color: rgba(123, 199, 111, 0.2);
  box-shadow: none, 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-success.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-success.btn-borderless:disabled {
  color: rgba(123, 199, 111, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless {
  color: #ffda7b;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:hover:not(:focus) {
    color: #ffda7b;
    background-color: rgba(255, 218, 123, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:hover {
    background-color: rgba(255, 218, 123, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled).active {
  color: #ffda7b;
  background-color: rgba(255, 218, 123, 0.2);
  box-shadow: none, 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless:disabled {
  color: rgba(255, 218, 123, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-info.btn-borderless {
  color: #64bbd2;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:hover:not(:focus) {
    color: #64bbd2;
    background-color: rgba(100, 187, 210, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:hover {
    background-color: rgba(100, 187, 210, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled).active {
  color: #64bbd2;
  background-color: rgba(100, 187, 210, 0.2);
  box-shadow: none, 0 0 0 3px #12b3ff, 0 0 0 6px rgba(18, 179, 255, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-info.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-info.btn-borderless:disabled {
  color: rgba(100, 187, 210, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless {
  color: #ff808b;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:hover:not(:focus) {
    color: #ff808b;
    background-color: rgba(255, 128, 139, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:hover {
    background-color: rgba(255, 128, 139, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled).active {
  color: #ff808b;
  background-color: rgba(255, 128, 139, 0.2);
  box-shadow: none, 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless.disabled, .xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless:disabled {
  color: rgba(255, 128, 139, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless {
  color: #ffffff;
  background-color: transparent;
  box-shadow: none;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:hover:not(:focus) {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:focus {
  outline: none;
  box-shadow: none;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none, 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
  transition-duration: .067s;
  transform: scale(0.978);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled):active,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless.disabled, .xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless:disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn-sm {
  height: 2rem;
  padding: 0 0.6875rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
}

.btn-sm.btn-inline {
  height: 26px;
  padding: 0 8px;
}

.btn-sm.btn-inline.btn-glyph {
  padding: 0 7px;
}

a.btn-sm {
  line-height: 2rem;
}

.btn-block {
  width: 100%;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.xrx-btn [class*="xgl-"] {
  position: relative;
}

.xrx-btn [class*="xgl-"] ~ .btn-label {
  padding-left: 0.375rem;
}

.xrx-btn.btn-sm [class*="xgl-"] {
  top: 0;
}

.xrx-btn.btn-sm [class*="xgl-"] ~ .btn-label {
  padding-left: 0.25rem;
}

.xrx-btn .btn-label ~ [class*="xgl-"] {
  margin-right: -0.125rem;
  margin-left: 0.375rem;
}

.xrx-btn.btn-sm .btn-label ~ [class*="xgl-"] {
  margin-left: 0.25rem;
}

.xrx-btn .btn-label {
  position: relative;
  letter-spacing: 0.01rem;
}

.xrx-btn.btn-sm .btn-label {
  top: 0.0625rem;
  letter-spacing: 0.0125rem;
}

.btn-glyph {
  min-width: 0;
}

.btn-glyph [class*="xgl-"] {
  margin-right: -0.125rem;
  margin-left: -0.125rem;
}

.btn-glyph.btn-sm [class*="xgl-"] {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.xrx-radio,
.xrx-checkbox {
  position: relative;
  display: table;
  font-size: 0;
}

.xrx-radio label,
.xrx-checkbox label {
  position: relative;
  top: 10px;
  margin-bottom: 0;
  margin-left: -2px;
  font-size: 20px;
  letter-spacing: .16px;
  float: right;
  user-select: none;
}

.xrx-radio.radio-sm label, .xrx-radio.checkbox-sm label,
.xrx-checkbox.radio-sm label,
.xrx-checkbox.checkbox-sm label {
  top: 5px;
  font-size: 16px;
  letter-spacing: .20px;
}

.xrx-radio input,
.xrx-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.xrx-radio .svg-checkbox-indeterminate,
.xrx-radio .svg-checkbox-checkmark,
.xrx-radio .svg-checkbox-focus,
.xrx-radio .svg-checkbox-background,
.xrx-checkbox .svg-checkbox-indeterminate,
.xrx-checkbox .svg-checkbox-checkmark,
.xrx-checkbox .svg-checkbox-focus,
.xrx-checkbox .svg-checkbox-background {
  opacity: 0;
}

.xrx-radio .svg-checkbox-outer-focus-ring,
.xrx-checkbox .svg-checkbox-outer-focus-ring {
  opacity: .2;
}

.xrx-radio .svg-checkbox-focus,
.xrx-checkbox .svg-checkbox-focus {
  fill: #3a90d1;
}

.xrx-radio .svg-checkbox,
.xrx-checkbox .svg-checkbox {
  fill: #525256;
}

@media (hover) {
  .xrx-radio:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-checkbox:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    opacity: 1;
    fill: rgba(82, 82, 86, 0.2);
  }
}

.xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox-background {
  opacity: 1;
  fill: #3f3f43;
}

.xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox,
.xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox {
  opacity: 0;
}

.xrx-radio input:focus ~ svg .svg-checkbox-focus,
.xrx-checkbox input:focus ~ svg .svg-checkbox-focus {
  opacity: 1;
}

.xrx-radio input:disabled ~ label,
.xrx-checkbox input:disabled ~ label {
  color: rgba(0, 0, 0, 0.3);
}

.xrx-radio input:disabled ~ svg,
.xrx-checkbox input:disabled ~ svg {
  cursor: not-allowed;
}

.xrx-radio input:disabled ~ svg .svg-checkbox,
.xrx-checkbox input:disabled ~ svg .svg-checkbox {
  fill: rgba(82, 82, 86, 0.3);
}

.xrx-radio.checked .svg-checkbox-checkmark,
.xrx-checkbox.checked .svg-checkbox-checkmark {
  opacity: 1;
  fill: #ffffff;
}

.xrx-radio.checked .svg-checkbox-background,
.xrx-checkbox.checked .svg-checkbox-background {
  opacity: 1;
  fill: #155fbb;
}

.xrx-radio.checked .svg-checkbox,
.xrx-checkbox.checked .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-radio.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-checkbox.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #01377f;
  }
}

.xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark,
.xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark {
  opacity: 0;
}

.xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #01377f;
}

.xrx-radio.checked input:disabled ~ label,
.xrx-checkbox.checked input:disabled ~ label {
  color: rgba(0, 0, 0, 0.3);
}

.xrx-radio.checked input:disabled ~ svg .svg-checkbox-background,
.xrx-checkbox.checked input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(21, 95, 187, 0.3);
}

.xrx-radio.indeterminate .svg-checkbox-indeterminate,
.xrx-checkbox.indeterminate .svg-checkbox-indeterminate {
  opacity: 1;
  fill: #ffffff;
}

.xrx-radio.indeterminate .svg-checkbox-background,
.xrx-checkbox.indeterminate .svg-checkbox-background {
  opacity: 1;
  fill: #155fbb;
}

.xrx-radio.indeterminate .svg-checkbox,
.xrx-checkbox.indeterminate .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-radio.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-checkbox.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #01377f;
  }
}

.xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate,
.xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate {
  opacity: 0;
}

.xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #01377f;
}

.xrx-radio.indeterminate input:disabled ~ label,
.xrx-checkbox.indeterminate input:disabled ~ label {
  color: rgba(0, 0, 0, 0.3);
}

.xrx-radio.indeterminate input:disabled ~ svg .svg-checkbox-background,
.xrx-checkbox.indeterminate input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(21, 95, 187, 0.3);
}

.xrx-dark-appearance .xrx-radio label,
.xrx-dark-appearance .xrx-checkbox label {
  color: #ffffff;
}

.xrx-dark-appearance .xrx-radio .svg-checkbox,
.xrx-dark-appearance .xrx-checkbox .svg-checkbox {
  fill: #bbbcc3;
}

@media (hover) {
  .xrx-dark-appearance .xrx-radio:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-checkbox:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    opacity: 1;
    fill: rgba(187, 188, 195, 0.2);
  }
}

.xrx-dark-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox-background {
  opacity: 1;
  fill: #9e9fa4;
}

.xrx-dark-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox,
.xrx-dark-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox {
  opacity: 0;
}

.xrx-dark-appearance .xrx-radio input:focus ~ svg .svg-checkbox-focus,
.xrx-dark-appearance .xrx-checkbox input:focus ~ svg .svg-checkbox-focus {
  opacity: 1;
}

.xrx-dark-appearance .xrx-radio input:disabled ~ label,
.xrx-dark-appearance .xrx-checkbox input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-radio input:disabled ~ svg .svg-checkbox,
.xrx-dark-appearance .xrx-checkbox input:disabled ~ svg .svg-checkbox {
  fill: rgba(187, 188, 195, 0.3);
}

.xrx-dark-appearance .xrx-radio.checked .svg-checkbox-checkmark,
.xrx-dark-appearance .xrx-checkbox.checked .svg-checkbox-checkmark {
  opacity: 1;
  fill: #29292d;
}

.xrx-dark-appearance .xrx-radio.checked .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.checked .svg-checkbox-background {
  opacity: 1;
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-radio.checked .svg-checkbox,
.xrx-dark-appearance .xrx-checkbox.checked .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-dark-appearance .xrx-radio.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-checkbox.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #5f94dc;
  }
}

.xrx-dark-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark,
.xrx-dark-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark {
  opacity: 0;
}

.xrx-dark-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #5f94dc;
}

.xrx-dark-appearance .xrx-radio.checked input:disabled ~ label,
.xrx-dark-appearance .xrx-checkbox.checked input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-radio.checked input:disabled ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.checked input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(145, 182, 236, 0.3);
}

.xrx-dark-appearance .xrx-radio.indeterminate .svg-checkbox-indeterminate,
.xrx-dark-appearance .xrx-checkbox.indeterminate .svg-checkbox-indeterminate {
  opacity: 1;
  fill: #29292d;
}

.xrx-dark-appearance .xrx-radio.indeterminate .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.indeterminate .svg-checkbox-background {
  opacity: 1;
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-radio.indeterminate .svg-checkbox,
.xrx-dark-appearance .xrx-checkbox.indeterminate .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-dark-appearance .xrx-radio.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-checkbox.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #5f94dc;
  }
}

.xrx-dark-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate,
.xrx-dark-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate {
  opacity: 0;
}

.xrx-dark-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #5f94dc;
}

.xrx-dark-appearance .xrx-radio.indeterminate input:disabled ~ label,
.xrx-dark-appearance .xrx-checkbox.indeterminate input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-radio.indeterminate input:disabled ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-checkbox.indeterminate input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(145, 182, 236, 0.3);
}

.xrx-theme-appearance .xrx-radio label,
.xrx-theme-appearance .xrx-checkbox label {
  color: #ffffff;
}

.xrx-theme-appearance .xrx-radio .svg-checkbox,
.xrx-theme-appearance .xrx-checkbox .svg-checkbox {
  fill: #e3edfc;
}

@media (hover) {
  .xrx-theme-appearance .xrx-radio:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-theme-appearance .xrx-checkbox:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    opacity: 1;
    fill: rgba(227, 237, 252, 0.2);
  }
}

.xrx-theme-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox-background {
  opacity: 1;
  fill: #91b6ec;
}

.xrx-theme-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox,
.xrx-theme-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox {
  opacity: 0;
}

.xrx-theme-appearance .xrx-radio input:focus ~ svg .svg-checkbox-focus,
.xrx-theme-appearance .xrx-checkbox input:focus ~ svg .svg-checkbox-focus {
  opacity: 1;
}

.xrx-theme-appearance .xrx-radio input:disabled ~ label,
.xrx-theme-appearance .xrx-checkbox input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-theme-appearance .xrx-radio input:disabled ~ svg .svg-checkbox,
.xrx-theme-appearance .xrx-checkbox input:disabled ~ svg .svg-checkbox {
  fill: rgba(227, 237, 252, 0.3);
}

.xrx-theme-appearance .xrx-radio.checked .svg-checkbox-checkmark,
.xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox-checkmark {
  opacity: 1;
  fill: #0c4fa3;
}

.xrx-theme-appearance .xrx-radio.checked .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox-background {
  opacity: 1;
  fill: #bad1f5;
}

.xrx-theme-appearance .xrx-radio.checked .svg-checkbox,
.xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-theme-appearance .xrx-radio.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-theme-appearance .xrx-checkbox.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #91b6ec;
  }
}

.xrx-theme-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark,
.xrx-theme-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark {
  opacity: 0;
}

.xrx-theme-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #91b6ec;
}

.xrx-theme-appearance .xrx-radio.checked input:disabled ~ label,
.xrx-theme-appearance .xrx-checkbox.checked input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-theme-appearance .xrx-radio.checked input:disabled ~ svg .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.checked input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(186, 209, 245, 0.3);
}

.xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox-indeterminate,
.xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox-indeterminate {
  opacity: 1;
  fill: #0c4fa3;
}

.xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox-background {
  opacity: 1;
  fill: #bad1f5;
}

.xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox,
.xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-theme-appearance .xrx-radio.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-theme-appearance .xrx-checkbox.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #91b6ec;
  }
}

.xrx-theme-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate,
.xrx-theme-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate {
  opacity: 0;
}

.xrx-theme-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #91b6ec;
}

.xrx-theme-appearance .xrx-radio.indeterminate input:disabled ~ label,
.xrx-theme-appearance .xrx-checkbox.indeterminate input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-theme-appearance .xrx-radio.indeterminate input:disabled ~ svg .svg-checkbox-background,
.xrx-theme-appearance .xrx-checkbox.indeterminate input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(186, 209, 245, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio label,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox label {
  color: #ffffff;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio .svg-checkbox,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox .svg-checkbox {
  fill: #bad1f5;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-radio:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    opacity: 1;
    fill: rgba(186, 209, 245, 0.2);
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox-background {
  opacity: 1;
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio:active input:not(:disabled) ~ svg .svg-checkbox,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox:active input:not(:disabled) ~ svg .svg-checkbox {
  opacity: 0;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio input:focus ~ svg .svg-checkbox-focus,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox input:focus ~ svg .svg-checkbox-focus {
  opacity: 1;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio input:disabled ~ label,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio input:disabled ~ svg .svg-checkbox,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox input:disabled ~ svg .svg-checkbox {
  fill: rgba(186, 209, 245, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked .svg-checkbox-checkmark,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox-checkmark {
  opacity: 1;
  fill: #01377f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox-background {
  opacity: 1;
  fill: #bad1f5;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked .svg-checkbox,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #91b6ec;
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-checkmark {
  opacity: 0;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked input:disabled ~ label,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.checked input:disabled ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.checked input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(186, 209, 245, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox-indeterminate,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox-indeterminate {
  opacity: 1;
  fill: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox-background {
  opacity: 1;
  fill: #bad1f5;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate .svg-checkbox,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate .svg-checkbox {
  opacity: 0;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background,
  .xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate:hover input:not(:disabled) ~ svg .svg-checkbox-background {
    fill: #91b6ec;
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-indeterminate {
  opacity: 0;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate:active input:not(:disabled) ~ svg .svg-checkbox-background {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate input:disabled ~ label,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate input:disabled ~ label {
  color: rgba(255, 255, 255, 0.3);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-radio.indeterminate input:disabled ~ svg .svg-checkbox-background,
.xrx-dark-appearance .xrx-theme-appearance .xrx-checkbox.indeterminate input:disabled ~ svg .svg-checkbox-background {
  fill: rgba(186, 209, 245, 0.3);
}

.using-mouse .svg-checkbox-focus {
  opacity: 0 !important;
}

.xrx-switch {
  position: relative;
  display: table;
  font-size: 0;
}

.xrx-switch input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.xrx-switch .svg-switch-focus,
.xrx-switch .svg-switch-thumb-shadow-dark {
  opacity: 0;
}

.xrx-switch .svg-switch-checkmark {
  fill: white;
}

.xrx-switch .svg-switch-focus {
  fill: #3a90d1;
}

.xrx-switch .svg-switch-outer-focus {
  opacity: .2;
}

.xrx-switch .svg-switch-channel {
  fill: #dbdbe3;
  transition: fill .15s linear;
}

.xrx-switch .svg-switch-thumb {
  transform-origin: 0%;
  transform: translate(10px, 10px);
  transition: transform .15s linear, transform-origin .15s linear;
}

.xrx-switch.switch-sm .svg-switch-thumb {
  transform: translate(7px, 7px);
}

.xrx-switch .svg-switch-thumb-background {
  fill: #fafaff;
  transition: fill .15s linear;
}

.xrx-switch .svg-switch-thumb-shadow-inset {
  fill: white;
  transition: fill .15s linear;
}

.xrx-switch .svg-switch-thumb-shadow-light {
  opacity: 1;
  transition: opacity .15s linear;
}

.xrx-switch .svg-switch-thumb-shadow-theme {
  opacity: 0;
  transition: opacity .15s linear;
}

@media (hover) {
  .xrx-switch:hover input:not(:disabled) ~ svg .svg-switch-thumb {
    transform: translate(10px, 10px) scaleX(1.135);
  }
}

@media (hover) {
  .xrx-switch.switch-sm:hover input:not(:disabled) ~ svg .svg-switch-thumb {
    transform: translate(7px, 7px) scaleX(1.135);
  }
}

.xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-background {
  fill: #bbbcc3;
  transition: fill .067s linear;
}

.xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-shadow-inset {
  fill: #dbdbe3;
  transition: fill .067s linear;
}

.xrx-switch input:focus ~ svg .svg-switch-focus {
  opacity: 1;
}

.xrx-switch input:disabled ~ svg {
  opacity: .3;
  cursor: not-allowed;
}

.xrx-switch input:disabled ~ svg .svg-switch-channel {
  fill: #bbbcc3;
}

.xrx-switch input:disabled ~ svg .svg-switch-thumb-shadow-inset {
  display: none !important;
}

.xrx-switch input:disabled ~ svg .svg-switch-thumb-shadow-light {
  display: none !important;
}

.xrx-switch input:disabled ~ svg .svg-switch-thumb-shadow-dark {
  display: none !important;
}

.xrx-switch input:disabled ~ svg .svg-switch-thumb-shadow-theme {
  display: none !important;
}

.xrx-switch.checked .svg-switch-channel {
  fill: #155fbb;
}

.xrx-switch.checked .svg-switch-thumb {
  transform-origin: 37%;
  transform: translate(40px, 10px);
}

.xrx-switch.checked.switch-sm .svg-switch-thumb {
  transform-origin: 36%;
  transform: translate(26px, 7px);
}

.xrx-switch.checked .svg-switch-thumb-shadow-light {
  opacity: 0;
}

.xrx-switch.checked .svg-switch-thumb-shadow-theme {
  opacity: 1;
}

@media (hover) {
  .xrx-switch.checked:hover input:not(:disabled) ~ svg .svg-switch-thumb {
    transform: translate(40px, 10px) scaleX(1.135);
  }
}

@media (hover) {
  .xrx-switch.checked.switch-sm:hover input:not(:disabled) ~ svg .svg-switch-thumb {
    transform: translate(26px, 7px) scaleX(1.135);
  }
}

.xrx-switch.checked input:disabled ~ svg .svg-switch-channel {
  fill: #155fbb;
}

.xrx-theme-appearance .xrx-switch .svg-switch-checkmark {
  fill: black;
}

.xrx-theme-appearance .xrx-switch .svg-switch-channel {
  fill: #002760;
}

.xrx-theme-appearance .xrx-switch .svg-switch-thumb-background {
  fill: #155fbb;
}

.xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-inset {
  fill: #2f74ca;
}

.xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-light {
  opacity: 0;
}

.xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-theme {
  opacity: 1;
}

.xrx-theme-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-background {
  fill: #01377f;
}

.xrx-theme-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-shadow-inset {
  fill: #0c4fa3;
}

.xrx-theme-appearance .xrx-switch input:disabled ~ svg .svg-switch-channel {
  fill: #1b1b1e;
}

.xrx-theme-appearance .xrx-switch.checked .svg-switch-channel {
  fill: #91b6ec;
}

.xrx-theme-appearance .xrx-switch.checked .svg-switch-thumb-shadow-theme {
  opacity: 0;
}

.xrx-theme-appearance .xrx-switch.checked .svg-switch-thumb-shadow-light {
  opacity: 1;
}

.xrx-theme-appearance .xrx-switch.checked input:disabled ~ svg .svg-switch-channel {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-switch .svg-switch-checkmark {
  fill: black;
}

.xrx-dark-appearance .xrx-switch .svg-switch-channel {
  fill: #1b1b1e;
}

.xrx-dark-appearance .xrx-switch .svg-switch-thumb-background {
  fill: #3f3f43;
}

.xrx-dark-appearance .xrx-switch .svg-switch-thumb-shadow-inset {
  fill: #525256;
}

.xrx-dark-appearance .xrx-switch .svg-switch-thumb-shadow-light,
.xrx-dark-appearance .xrx-switch .svg-switch-thumb-shadow-theme {
  opacity: 0;
}

.xrx-dark-appearance .xrx-switch .svg-switch-thumb-shadow-dark {
  opacity: 1;
}

.xrx-dark-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-background {
  fill: #29292d;
}

.xrx-dark-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-shadow-inset {
  fill: #3f3f43;
}

.xrx-dark-appearance .xrx-switch input:disabled ~ svg .svg-switch-channel {
  fill: black;
}

.xrx-dark-appearance .xrx-switch.checked .svg-switch-channel {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-switch.checked .svg-switch-thumb-shadow-dark,
.xrx-dark-appearance .xrx-switch.checked .svg-switch-thumb-shadow-theme {
  opacity: 0;
}

.xrx-dark-appearance .xrx-switch.checked .svg-switch-thumb-shadow-light {
  opacity: 1;
}

.xrx-dark-appearance .xrx-switch.checked input:disabled ~ svg .svg-switch-channel {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-checkmark {
  fill: black;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-channel {
  fill: #00173c;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-thumb-background {
  fill: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-inset {
  fill: #155fbb;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-dark,
.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-light {
  opacity: 0;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch .svg-switch-thumb-shadow-theme {
  opacity: 1;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-background {
  fill: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch:active input:not(:disabled) ~ svg .svg-switch-thumb-shadow-inset {
  fill: #01377f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch input:disabled ~ svg .svg-switch-channel {
  fill: #1b1b1e;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch.checked .svg-switch-channel {
  fill: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch.checked .svg-switch-thumb-shadow-theme {
  opacity: 0;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch.checked .svg-switch-thumb-shadow-light {
  opacity: 1;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-switch.checked input:disabled ~ svg .svg-switch-channel {
  fill: #91b6ec;
}

.using-mouse .svg-switch-focus {
  opacity: 0 !important;
}

.xrx-switch.notransition svg * {
  transition: none;
}

.xrx-list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  background-color: #fafaff;
  border: 1px solid #dbdbe3;
  border-radius: 6px;
}

.xrx-list-group.list-group-flush {
  border: none;
  border-radius: 0;
}

.xrx-list-group.list-group-flush .list-group-item {
  border-radius: 0;
}

.xrx-list-group.emphasized {
  border: none;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.xrx-list-group.emphasized .list-group-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.xrx-list-group.emphasized .list-group-item:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.list-group-header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  font-size: 16px;
  letter-spacing: .25px;
  background-color: #dbdbe3;
}

.list-group-header .label {
  position: relative;
  top: 1px;
}

.xrx-dark-appearance .list-group-header {
  color: #ffffff;
  background-color: #1b1b1e;
}

.list-group-item {
  display: flex;
  align-items: center;
  min-height: 56px;
  background-color: transparent;
  color: #000000;
  outline: none;
}

.list-group-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.list-group-item.compact {
  min-height: 48px;
}

.list-group-item .content-container {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: 1;
  margin-left: 16px;
}

.list-group-item .left-label {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: default;
}

.list-group-item .left-label span {
  font-size: 20px;
  letter-spacing: .15px;
}

.list-group-item .left-label._2line64-sub-label {
  flex-direction: column;
  align-items: flex-start;
  height: 64px;
}

.list-group-item .left-label._2line64-sub-label span {
  position: relative;
  top: 4px;
}

.list-group-item .left-label._2line64-sub-label .sub-label {
  position: relative;
  top: 5px;
  font-size: 16px;
}

@media (hover) {
  .list-group-item:hover {
    color: #000000;
  }
}

.list-group-item.disabled {
  opacity: .3;
  cursor: not-allowed !important;
}

.list-group-item.disabled .left-label {
  cursor: not-allowed !important;
}

.list-group-item.disabled .custom-image-flush,
.list-group-item.disabled .custom-image-margin,
.list-group-item.disabled .custom-icon {
  box-shadow: none !important;
}

.list-group-item.divider:not(:last-child) .content-container::before {
  position: absolute;
  bottom: 0;
  align-self: stretch;
  width: 100%;
  content: "";
  border-bottom: 1px solid #dbdbe3;
}

.list-group-item .left-object ~ .content-container {
  margin-left: 0;
}

.list-group-item .left-object [class*="xgl-"] {
  display: table;
  margin: 0 16px;
}

.list-group-item .left-object .custom-icon {
  width: 36px;
  margin: 10px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.08);
}

.list-group-item .left-object .custom-image-margin {
  margin: 0 16px 0 4px;
}

.list-group-item .left-object .custom-image-flush {
  margin: 0 16px 0 0;
}

.list-group-item .left-object .xrx-checkbox {
  margin: 0 10px;
}

.list-group-item .sub-label,
.list-group-item .feedback-label {
  color: #525256;
}

.list-group-item .right-object {
  margin-left: auto;
}

.list-group-item .right-object .feedback-label {
  position: relative;
  top: 1px;
  margin: 0 16px;
  font-size: 16px;
  letter-spacing: .15px;
  white-space: nowrap;
}

.list-group-item .right-object .xrx-btn:not(.dropdown-toggle) {
  margin: 8px;
}

.list-group-item .right-object .xrx-switch {
  margin: 0 8px;
}

.list-group-item .right-object .xrx-checkbox {
  margin: 0 4px;
}

.list-group-item .right-object > [class*="xgl-"] {
  display: table;
  margin: 0 16px;
}

a.list-group-item {
  color: #000000;
  text-decoration: none !important;
}

.xrx-list-group.list-group-sm {
  border-radius: 4px;
}

.xrx-list-group.list-group-sm.list-group-flush {
  border-radius: 0;
}

.xrx-list-group.list-group-sm.list-group-flush .list-group-item {
  border-radius: 0;
}

.xrx-list-group.list-group-sm .list-group-header {
  padding: 0 5px;
  height: 28px;
  font-size: 14px;
}

.xrx-list-group.list-group-sm .list-group-item {
  min-height: 38px;
}

.xrx-list-group.list-group-sm .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.xrx-list-group.list-group-sm .list-group-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.xrx-list-group.list-group-sm .list-group-item.compact {
  min-height: 32px;
}

.xrx-list-group.list-group-sm .list-group-item .content-container {
  margin-left: 9px;
}

.xrx-list-group.list-group-sm .list-group-item .left-label span {
  position: relative;
  top: 1px;
  font-size: 16px;
  letter-spacing: .15px;
}

.xrx-list-group.list-group-sm .list-group-item .left-label._2line44-sub-label {
  flex-direction: column;
  align-items: flex-start;
  height: 44px;
}

.xrx-list-group.list-group-sm .list-group-item .left-label._2line44-sub-label span {
  top: 2px;
}

.xrx-list-group.list-group-sm .list-group-item .left-label._2line44-sub-label .sub-label {
  top: -3px;
  font-size: 14px;
}

.xrx-list-group.list-group-sm .list-group-item .left-object ~ .content-container {
  margin-left: 0;
}

.xrx-list-group.list-group-sm .list-group-item .left-object [class*="xgl-"] {
  margin: 0 9px;
}

.xrx-list-group.list-group-sm .list-group-item .left-object .custom-icon {
  width: 24px;
  margin: 7px 9px;
  border-radius: 5px;
}

.xrx-list-group.list-group-sm .list-group-item .left-object .custom-image-margin {
  margin: 0 9px 0 3px;
}

.xrx-list-group.list-group-sm .list-group-item .left-object .custom-image-flush {
  margin: 0 9px 0 0;
}

.xrx-list-group.list-group-sm .list-group-item .left-object .xrx-checkbox {
  margin: 0 5px;
}

.xrx-list-group.list-group-sm .list-group-item .right-object {
  margin-left: auto;
}

.xrx-list-group.list-group-sm .list-group-item .right-object .feedback-label {
  position: relative;
  top: 1px;
  margin: 0 9px;
  font-size: 14px;
  letter-spacing: .25px;
}

.xrx-list-group.list-group-sm .list-group-item .right-object .xrx-btn {
  margin: 6px;
}

.xrx-list-group.list-group-sm .list-group-item .right-object .xrx-switch {
  margin: 0 4px;
}

.xrx-list-group.list-group-sm .list-group-item .right-object .xrx-checkbox {
  margin: 0 3px;
}

.xrx-list-group.list-group-sm .list-group-item .right-object > [class*="xgl-"] {
  margin: 0 9px;
}

.xrx-list-group.list-group-sm.emphasized .list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.xrx-list-group.list-group-sm.emphasized .list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group-item-action {
  transition: background-color .5s;
}

@media (hover) {
  .list-group-item-action:hover:not(.disabled) {
    background-color: #f2f2f9;
    transition: background-color 0s;
  }
}

.list-group-item-action:active:not(.disabled) {
  background-color: #dbdbe3;
}

.list-group-item-action.active:not(.disabled) {
  background-color: #155fbb;
  color: #ffffff;
}

.list-group-item-action.active:not(.disabled) .sub-label,
.list-group-item-action.active:not(.disabled) .feedback-label {
  color: #bad1f5;
}

.list-group-item-action.active:not(.disabled) .content-container::before {
  border-bottom-color: transparent !important;
}

.list-group-item.read-only {
  background-color: #f2f2f9;
}

.list-group-item.read-only .content-container::before {
  border-bottom-color: #dbdbe3 !important;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group {
  background-color: #155fbb;
  border-color: #01377f;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group.emphasized {
  border: none;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

@media (hover) {
  .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action:hover:not(.disabled) {
    background-color: #01377f;
  }
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action:active:not(.disabled) {
  background-color: #002760;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) {
  background-color: #91b6ec;
  color: #000000;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) .sub-label,
.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) .feedback-label {
  color: #29292d;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item {
  background-color: transparent;
  color: #ffffff;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.divider .content-container::before {
  border-bottom-color: #01377f;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item .sub-label,
.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item .feedback-label {
  color: #bad1f5;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.read-only {
  background-color: #0c4fa3;
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.read-only .content-container::before {
  border-bottom-color: #01377f !important;
}

.xrx-dark-appearance .xrx-list-group {
  background-color: #3f3f43;
  border-color: #1b1b1e;
}

.xrx-dark-appearance .xrx-list-group.emphasized {
  border: none;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-action:hover:not(.disabled) {
    background-color: #29292d;
  }
}

.xrx-dark-appearance .list-group-item-action:active:not(.disabled) {
  background-color: #1b1b1e;
}

.xrx-dark-appearance .list-group-item-action.active:not(.disabled) {
  background-color: #91b6ec;
  color: #000000;
}

.xrx-dark-appearance .list-group-item-action.active:not(.disabled) .sub-label,
.xrx-dark-appearance .list-group-item-action.active:not(.disabled) .feedback-label {
  color: #29292d;
}

.xrx-dark-appearance .list-group-item {
  background-color: transparent;
  color: #ffffff;
}

.xrx-dark-appearance .list-group-item.divider .content-container::before {
  border-bottom-color: #1b1b1e;
}

.xrx-dark-appearance .list-group-item .sub-label,
.xrx-dark-appearance .list-group-item .feedback-label {
  color: #bbbcc3;
}

.xrx-dark-appearance .list-group-item.read-only {
  background-color: #29292d;
}

.xrx-dark-appearance .list-group-item.read-only.divider .content-container::before {
  border-bottom-color: #1b1b1e !important;
}

.xrx-dark-appearance .list-group-item.read-only .sub-label,
.xrx-dark-appearance .list-group-item.read-only .feedback-label {
  color: #bbbcc3;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group {
  background-color: #01377f;
  border-color: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group.emphasized {
  border: none;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.32);
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action:hover:not(.disabled) {
    background-color: #002760;
  }
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action:active:not(.disabled) {
  background-color: #00173c;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) {
  background-color: #91b6ec;
  color: #000000;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) .sub-label,
.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item-action.active:not(.disabled) .feedback-label {
  color: #29292d;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item {
  background-color: transparent;
  color: #ffffff;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.divider .content-container::before {
  border-bottom-color: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item .sub-label,
.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item .feedback-label {
  color: #bad1f5;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.read-only {
  background-color: #01377f;
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .list-group-item.read-only.divider .content-container::before {
  border-bottom-color: #002760 !important;
}

.list-group-item-primary,
.list-group-item-success,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-danger {
  background-color: transparent;
}

.list-group-item-primary .left-label .sub-label,
.list-group-item-success .left-label .sub-label,
.list-group-item-warning .left-label .sub-label,
.list-group-item-info .left-label .sub-label,
.list-group-item-danger .left-label .sub-label {
  color: #525256;
}

.xrx-dark-appearance .list-group-item-primary .left-label .sub-label, .xrx-dark-appearance
.list-group-item-success .left-label .sub-label, .xrx-dark-appearance
.list-group-item-warning .left-label .sub-label, .xrx-dark-appearance
.list-group-item-info .left-label .sub-label, .xrx-dark-appearance
.list-group-item-danger .left-label .sub-label {
  color: #bbbcc3;
}

.list-group-item-primary.divider .content-container::before,
.list-group-item-success.divider .content-container::before,
.list-group-item-warning.divider .content-container::before,
.list-group-item-info.divider .content-container::before,
.list-group-item-danger.divider .content-container::before {
  border-bottom-color: #dbdbe3;
}

.list-group-item-primary {
  color: #155fbb !important;
}

@media (hover) {
  .list-group-item-primary.list-group-item-action:hover {
    background-color: #e3edfc;
  }
}

.list-group-item-primary.list-group-item-action:active {
  background-color: #bad1f5;
}

.list-group-item-success {
  color: #186a1e !important;
}

@media (hover) {
  .list-group-item-success.list-group-item-action:hover {
    background-color: #caf7ba;
  }
}

.list-group-item-success.list-group-item-action:active {
  background-color: #a4e195;
}

.list-group-item-warning {
  color: #955503 !important;
}

@media (hover) {
  .list-group-item-warning.list-group-item-action:hover {
    background-color: #fff3bb;
  }
}

.list-group-item-warning.list-group-item-action:active {
  background-color: #ffea9e;
}

.list-group-item-info {
  color: #0e6682 !important;
}

@media (hover) {
  .list-group-item-info.list-group-item-action:hover {
    background-color: #bcefff;
  }
}

.list-group-item-info.list-group-item-action:active {
  background-color: #87d8ec;
}

.list-group-item-danger {
  color: #9f0b14 !important;
}

@media (hover) {
  .list-group-item-danger.list-group-item-action:hover {
    background-color: #ffcbcf;
  }
}

.list-group-item-danger.list-group-item-action:active {
  background-color: #ffa1a9;
}

.xrx-dark-appearance .list-group-item-primary {
  color: #91b6ec !important;
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-primary.list-group-item-action:hover {
    background-color: #01377f;
  }
}

.xrx-dark-appearance .list-group-item-primary.list-group-item-action:active {
  background-color: #001128;
}

.xrx-dark-appearance .list-group-item-success {
  color: #7bc76f !important;
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-success.list-group-item-action:hover {
    background-color: #04390b;
  }
}

.xrx-dark-appearance .list-group-item-success.list-group-item-action:active {
  background-color: #041900;
}

.xrx-dark-appearance .list-group-item-warning {
  color: #ffda7b !important;
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-warning.list-group-item-action:hover {
    background-color: #693b00;
  }
}

.xrx-dark-appearance .list-group-item-warning.list-group-item-action:active {
  background-color: #2c1800;
}

.xrx-dark-appearance .list-group-item-info {
  color: #64bbd2 !important;
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-info.list-group-item-action:hover {
    background-color: #002c3e;
  }
}

.xrx-dark-appearance .list-group-item-info.list-group-item-action:active {
  background-color: #001823;
}

.xrx-dark-appearance .list-group-item-danger {
  color: #ff808b !important;
}

@media (hover) {
  .xrx-dark-appearance .list-group-item-danger.list-group-item-action:hover {
    background-color: #5a0004;
  }
}

.xrx-dark-appearance .list-group-item-danger.list-group-item-action:active {
  background-color: #320001;
}

.using-mouse .list-group-item:focus {
  outline: none;
  box-shadow: none;
}

.multi-list-group .xrx-list-group,
.multi-list-group .xrx-list-group.list-group-sm {
  border-radius: 0;
}

.multi-list-group .xrx-list-group:not(:first-child),
.multi-list-group .xrx-list-group.list-group-sm:not(:first-child) {
  border-top: none;
}

.multi-list-group .xrx-list-group:not(:last-child),
.multi-list-group .xrx-list-group.list-group-sm:not(:last-child) {
  border-bottom: none;
}

.multi-list-group .xrx-list-group:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.multi-list-group .xrx-list-group:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.multi-list-group .xrx-list-group.list-group-sm:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.multi-list-group .xrx-list-group.list-group-sm:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.multi-list-group .xrx-list-group:first-child .list-group-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.multi-list-group .xrx-list-group.list-group-sm:first-child .list-group-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.multi-list-group .xrx-list-group:not(:last-child) .list-group-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

body:not(.using-mouse) .list-group-item:focus {
  box-shadow: inset 0 0 0 3px #3a90d1, inset 0 0 0 6px rgba(58, 144, 209, 0.2);
}

body:not(.using-mouse) .list-group-item:focus .content-container::before {
  border-bottom-color: transparent !important;
}

body:not(.using-mouse) .list-group-item.read-only:focus {
  box-shadow: inset 0 0 0 3px #3a90d1, inset 0 0 0 6px rgba(58, 144, 209, 0.2);
}

body:not(.using-mouse) .list-group-item.read-only:focus .content-container::before {
  border-bottom-color: transparent !important;
}

.xrx-dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  z-index: 1070;
  visibility: hidden;
  opacity: 0;
  margin-top: -8px !important;
  transition: visibility .165s linear, opacity .165s linear, margin-top .165s linear, margin-bottom .165s linear, margin-left .165s linear, margin-right .165s linear;
  min-width: 10rem;
  color: #000000;
  list-style: none;
  background-color: rgba(250, 250, 255, 0.95);
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
}

.dropdown-menu a.list-group-item {
  text-decoration: none !important;
}

.dropdown-menu a.list-group-item .left-label {
  padding-right: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-menu .menu-scroll-container {
  border-radius: 6px;
}

.menu-divider {
  height: 1px;
  background-color: rgba(103, 103, 108, 0.2);
}

.dropdown-menu-arrow,
.dropdown-menu-arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: -1;
  border-radius: 2px;
}

.dropdown-menu-arrow::before {
  content: '';
  transform: rotate(45deg);
  background: rgba(250, 250, 255, 0.94);
}

[data-popper-placement^='top'] > .dropdown-menu-arrow {
  bottom: -5px;
}

[data-popper-placement^='bottom'] > .dropdown-menu-arrow {
  top: -5px;
}

[data-popper-placement^='left'] > .dropdown-menu-arrow {
  right: -5px;
}

[data-popper-placement^='right'] > .dropdown-menu-arrow {
  left: -5px;
}

.dropup .dropdown-menu {
  margin-bottom: -8px !important;
}

.dropright .dropdown-menu {
  margin-top: 0px !important;
  margin-left: -8px !important;
}

.dropleft .dropdown-menu {
  margin-top: 0px !important;
  margin-right: -8px !important;
}

.dropdown-menu.show {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  visibility: visible;
  opacity: 1;
}

.xrx-dark-appearance .dropdown-menu {
  background-color: rgba(63, 63, 67, 0.94);
}

.xrx-dark-appearance .dropdown-menu-arrow::before {
  content: '';
  background: rgba(63, 63, 67, 0.94);
}

.xrx-dark-appearance .menu-divider {
  background-color: rgba(187, 188, 195, 0.2);
}

.anchored-menu {
  margin-top: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  clip-path: inset(0px -25px -25px -25px);
  transition: visibility .25s, opacity .25s, margin-top 0s;
}

.anchored-menu .dropdown-menu-arrow {
  display: none;
}

.anchored-menu .menu-scroll-container,
.anchored-menu .xrx-list-group,
.anchored-menu .list-group-header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.xrx-list-group .xrx-dropdown {
  margin-right: 16px;
}

.xrx-list-group .xrx-dropdown .list-group-item:not(.divider) .content-container:before {
  border: none;
}

.xrx-select {
  position: relative;
  min-width: 90px;
  margin-bottom: 1rem;
}

.xrx-select select {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.xrx-select .dropdown-menu-arrow {
  display: none;
}

.xrx-select .dropdown-toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #dbdbe3;
  outline: none;
  color: #000000;
  transition: all .165s;
  height: 3rem;
  padding: 0 16px;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  padding-right: 12px;
  background-color: #fafaff;
}

.xrx-select .dropdown-toggle:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.xrx-select .dropdown-toggle .caret {
  color: #000000;
}

.xrx-select .dropdown-toggle .btn-label {
  padding-right: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xrx-select .dropdown-toggle .btn-label [class*="xgl-"] {
  display: inline-block;
  top: 4px;
  margin-right: 16px;
}

.xrx-select .dropdown-toggle .btn-label.placeholder-style {
  color: #9e9fa4;
}

.xrx-select .dropdown-toggle .select-btn-right-object {
  position: relative;
  top: -1px;
  display: flex;
}

.xrx-select .dropdown-toggle .select-btn-right-object .divider {
  position: relative;
  top: 1px;
  margin: 0 11px 0 11px;
  width: 1px;
  height: 24px;
  background-color: #dbdbe3;
}

.xrx-select .dropdown-toggle [class*="xgl-"] {
  top: 1px;
}

@media (hover) {
  .xrx-select .dropdown-toggle:hover:not(:disabled) {
    background-color: #dbdbe3;
  }
}

.xrx-select .dropdown-toggle:active:not(:disabled) {
  background-color: #bbbcc3;
}

.xrx-select .dropdown-toggle:disabled, .xrx-select .dropdown-toggle.disabled {
  cursor: not-allowed;
}

.xrx-select.disabled {
  opacity: .3;
}

.xrx-select .left-label span {
  white-space: nowrap;
}

.xrx-select .dropdown-menu {
  margin-top: 0px !important;
}

.xrx-select [role="group"] .list-group-item {
  padding-left: 16px;
}

.xrx-select .show.menu-down .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.xrx-select .show.menu-up .dropdown-toggle {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.xrx-select .menu-down .dropdown-menu,
.xrx-select .menu-down .menu-scroll-container,
.xrx-select .menu-down .xrx-list-group,
.xrx-select .menu-down .list-group-header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.xrx-select .menu-down .dropdown-menu {
  clip-path: inset(0px -25px -25px -25px);
}

.xrx-select .menu-up .dropdown-menu,
.xrx-select .menu-up .menu-scroll-container,
.xrx-select .menu-up .xrx-list-group {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.xrx-select .menu-up .dropdown-menu {
  clip-path: inset(-25px -25px 0px -25px);
}

.xrx-select .dropdown-menu {
  overflow: hidden;
}

.xrx-select .form-text .message {
  color: #525256;
}

.xrx-select.select-focus .dropdown-toggle,
.xrx-select .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-select:not([class*="select-validation"]) .dropdown-toggle {
  box-shadow: none;
}

.xrx-select.select-validation-success .dropdown-toggle {
  box-shadow: none;
  border-color: #24882b;
}

.xrx-select.select-validation-success .form-text .message {
  color: #186a1e;
}

.select-validation-success.select-focus .dropdown-toggle,
.select-validation-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .select-validation-success.select-focus .dropdown-toggle,
.using-mouse .select-validation-success .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #24882b;
}

.xrx-select.select-validation-danger .dropdown-toggle {
  box-shadow: none;
  border-color: #d92231;
}

.xrx-select.select-validation-danger .form-text .message {
  color: #9f0b14;
}

.select-validation-danger.select-focus .dropdown-toggle,
.select-validation-danger .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .select-validation-danger.select-focus .dropdown-toggle,
.using-mouse .select-validation-danger .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #d92231;
}

.xrx-select.select-validation-warning .dropdown-toggle {
  box-shadow: none;
  border-color: #fd9726;
}

.xrx-select.select-validation-warning .form-text .message {
  color: #955503;
}

.select-validation-warning.select-focus .dropdown-toggle,
.select-validation-warning .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .select-validation-warning.select-focus .dropdown-toggle,
.using-mouse .select-validation-warning .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #fd9726;
}

.xrx-theme-appearance .xrx-select .dropdown-toggle {
  color: #ffffff;
  border-color: #01377f;
  background-color: #155fbb;
}

.xrx-theme-appearance .xrx-select .dropdown-toggle .caret {
  color: #ffffff;
}

@media (hover) {
  .xrx-theme-appearance .xrx-select .dropdown-toggle:hover:not(:disabled) {
    background-color: #01377f;
  }
}

.xrx-theme-appearance .xrx-select .dropdown-toggle:active:not(:disabled) {
  background-color: #002760;
}

.xrx-theme-appearance .xrx-select .dropdown-toggle .select-btn-right-object .divider {
  background-color: #01377f;
}

.xrx-theme-appearance .xrx-select .form-text {
  color: #91b6ec;
}

.xrx-theme-appearance .xrx-select .btn-label.placeholder-style {
  color: #5f94dc;
}

.xrx-theme-appearance .xrx-select.select-validation-success .dropdown-toggle {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-theme-appearance .xrx-select.select-validation-success .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .select-validation-success.select-focus .dropdown-toggle,
.xrx-theme-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-theme-appearance .select-validation-success.select-focus .dropdown-toggle,
.using-mouse .xrx-theme-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-theme-appearance .xrx-select.select-validation-danger .dropdown-toggle {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-theme-appearance .xrx-select.select-validation-danger .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .select-validation-danger.select-focus .dropdown-toggle,
.xrx-theme-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-theme-appearance .select-validation-danger.select-focus .dropdown-toggle,
.using-mouse .xrx-theme-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-theme-appearance .xrx-select.select-validation-warning .dropdown-toggle {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-theme-appearance .xrx-select.select-validation-warning .form-text .message {
  color: #91b6ec;
}

.xrx-theme-appearance .select-validation-warning.select-focus .dropdown-toggle,
.xrx-theme-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-theme-appearance .select-validation-warning.select-focus .dropdown-toggle,
.using-mouse .xrx-theme-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-select .dropdown-toggle {
  color: #ffffff;
  border-color: #1b1b1e;
  background-color: #3f3f43;
}

.xrx-dark-appearance .xrx-select .dropdown-toggle .caret {
  color: #ffffff;
}

@media (hover) {
  .xrx-dark-appearance .xrx-select .dropdown-toggle:hover:not(:disabled) {
    background-color: #1b1b1e;
  }
}

.xrx-dark-appearance .xrx-select .dropdown-toggle:active:not(:disabled) {
  background-color: #0e0e10;
}

.xrx-dark-appearance .xrx-select .dropdown-toggle .select-btn-right-object .divider {
  background-color: #1b1b1e;
}

.xrx-dark-appearance .xrx-select .form-text {
  color: #bbbcc3;
}

.xrx-dark-appearance .xrx-select .btn-label.placeholder-style {
  color: #818187;
}

.xrx-dark-appearance .xrx-select.select-validation-success .dropdown-toggle {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-select.select-validation-success .form-text .message {
  color: #7bc76f;
}

.xrx-dark-appearance .select-validation-success.select-focus .dropdown-toggle,
.xrx-dark-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-dark-appearance .select-validation-success.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-select.select-validation-danger .dropdown-toggle {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-select.select-validation-danger .form-text .message {
  color: #ff808b;
}

.xrx-dark-appearance .select-validation-danger.select-focus .dropdown-toggle,
.xrx-dark-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-dark-appearance .select-validation-danger.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-select.select-validation-warning .dropdown-toggle {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-select.select-validation-warning .form-text .message {
  color: #ffda7b;
}

.xrx-dark-appearance .select-validation-warning.select-focus .dropdown-toggle,
.xrx-dark-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-dark-appearance .select-validation-warning.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .dropdown-toggle {
  border-color: #002760;
  background-color: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .dropdown-toggle .caret {
  color: #ffffff;
}

@media (hover) {
  .xrx-dark-appearance .xrx-theme-appearance .xrx-select .dropdown-toggle:hover:not(:disabled) {
    background-color: #002760;
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .dropdown-toggle:active:not(:disabled) {
  background-color: #00173c;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .dropdown-toggle .select-btn-right-object .divider {
  background-color: #002760;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .form-text {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select .btn-label.placeholder-style {
  color: #5f94dc;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-success .dropdown-toggle {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-success .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .select-validation-success.select-focus .dropdown-toggle,
.xrx-dark-appearance .xrx-theme-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #22df24, 0 0 0 6px rgba(34, 223, 36, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-success.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-success .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #7bc76f;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-danger .dropdown-toggle {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-danger .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .select-validation-danger.select-focus .dropdown-toggle,
.xrx-dark-appearance .xrx-theme-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ff4a49, 0 0 0 6px rgba(255, 74, 73, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-danger.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-danger .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ff808b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-warning .dropdown-toggle {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-select.select-validation-warning .form-text .message {
  color: #91b6ec;
}

.xrx-dark-appearance .xrx-theme-appearance .select-validation-warning.select-focus .dropdown-toggle,
.xrx-dark-appearance .xrx-theme-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #ffbc1a, 0 0 0 6px rgba(255, 188, 26, 0.2);
}

.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-warning.select-focus .dropdown-toggle,
.using-mouse .xrx-dark-appearance .xrx-theme-appearance .select-validation-warning .dropdown-toggle:focus {
  box-shadow: none;
  border-color: #ffda7b;
}

.xrx-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
  word-wrap: break-word;
  color: #000000;
  background-color: #fafaff;
  background-clip: border-box;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  transition: box-shadow .165s;
}

.xrx-card.card-action::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #000000;
  opacity: 0;
  border-radius: 8px;
  transition: opacity .165s;
}

.using-mouse .xrx-card.card-action:focus:not(.active):not(:hover),
.using-mouse .xrx-card.card-action:focus:not(:active):not(:hover) {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

@media (hover) {
  .using-mouse .xrx-card.card-action:hover {
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
}

.xrx-card.card-action:focus {
  outline: none;
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .xrx-card.card-action.active:not(.disabled),
.using-mouse .xrx-card.card-action:active:not(.disabled) {
  box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.using-mouse .xrx-card.card-action.active:not(.disabled)::before,
.using-mouse .xrx-card.card-action:active:not(.disabled)::before {
  opacity: .2;
}

.xrx-card.card-action {
  text-decoration: none;
}

.xrx-card.disabled {
  box-shadow: none !important;
  background-color: #bbbcc3;
  opacity: .3;
  cursor: not-allowed !important;
}

.xrx-card.card-dragging {
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.16), 0 0 16px 0 rgba(0, 0, 0, 0.08);
}

.xrx-card > hr {
  margin-right: 0;
  margin-left: 0;
}

.xrx-card h1, .xrx-card h2, .xrx-card h3, .xrx-card h4, .xrx-card h5, .xrx-card h6 {
  margin-top: 0.625rem;
}

.card-1line-title {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 16px;
  color: #000000;
}

.card-1line-title h5 {
  margin: 0;
}

.card-1line-title .card-subtitle {
  font-size: 16px;
  letter-spacing: .25px;
}

.card-1line-title .right-object {
  margin-left: auto;
}

.card-1line-title-subtitle {
  display: flex;
  align-items: center;
  min-height: 76px;
  padding: 16px;
  color: #000000;
}

.card-1line-title-subtitle h6 {
  position: relative;
  top: -10px;
  margin: 0;
  line-height: 0;
}

.card-1line-title-subtitle .card-subtitle {
  position: relative;
  top: 19px;
  font-size: 16px;
  line-height: 0;
  letter-spacing: .25px;
  color: #525256;
}

.card-1line-title-subtitle .right-object {
  margin-left: auto;
}

.card-2line-title {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 16px;
  color: #000000;
}

.card-2line-title .card-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .15px;
  line-height: 1.4;
}

.card-2line-title .right-object {
  margin-left: auto;
}

.card-1line-title .right-object [class*="xgl-"],
.card-1line-title-subtitle .right-object [class*="xgl-"],
.card-2line-title .right-object [class*="xgl-"] {
  display: block;
}

.card-body {
  flex: 1 1 auto;
  padding: 16px;
  color: #000000;
}

.card-buttons {
  display: flex;
  align-items: center;
  padding: 16px;
}

.card-buttons .xrx-btn + .xrx-btn {
  margin-left: 16px;
}

.card-borderless-buttons {
  display: flex;
  align-items: center;
  padding: 8px;
}

.card-borderless-buttons .xrx-btn + .xrx-btn {
  margin-left: 0;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-header {
  margin-bottom: 0;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .xrx-card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .xrx-card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
  background-color: rgba(103, 103, 108, 0.2);
}

.card-group .divider-vertical {
  position: absolute;
  right: 0;
  z-index: 999;
  width: 1px;
  height: 100%;
  background-color: rgba(103, 103, 108, 0.2);
}

.card-group > .xrx-card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .xrx-card {
    flex: 1 0 0%;
    margin-bottom: 0;
    box-shadow: none;
  }
  .card-group > .xrx-card + .xrx-card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .xrx-card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .xrx-card:first-child .card-img-top,
  .card-group > .xrx-card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .xrx-card:first-child .card-img-bottom,
  .card-group > .xrx-card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .xrx-card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .xrx-card:last-child .card-img-top,
  .card-group > .xrx-card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .xrx-card:last-child .card-img-bottom,
  .card-group > .xrx-card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .xrx-card:only-child {
    border-radius: 0.5rem;
  }
  .card-group > .xrx-card:only-child .card-img-top,
  .card-group > .xrx-card:only-child .card-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group > .xrx-card:only-child .card-img-bottom,
  .card-group > .xrx-card:only-child .card-footer {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group > .xrx-card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .xrx-card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .xrx-card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .xrx-card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .xrx-card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .xrx-card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .xrx-card {
    display: inline-block;
    width: 100%;
  }
}

.xrx-theme-appearance .xrx-card,
.xrx-dark-appearance .xrx-card {
  background-color: #155fbb;
}

.xrx-theme-appearance .card-1line-title h5, .xrx-theme-appearance .card-1line-title h6, .xrx-theme-appearance .card-1line-title .card-title,
.xrx-theme-appearance .card-1line-title-subtitle h5,
.xrx-theme-appearance .card-1line-title-subtitle h6,
.xrx-theme-appearance .card-1line-title-subtitle .card-title,
.xrx-theme-appearance .card-2line-title h5,
.xrx-theme-appearance .card-2line-title h6,
.xrx-theme-appearance .card-2line-title .card-title,
.xrx-dark-appearance .card-1line-title h5,
.xrx-dark-appearance .card-1line-title h6,
.xrx-dark-appearance .card-1line-title .card-title,
.xrx-dark-appearance .card-1line-title-subtitle h5,
.xrx-dark-appearance .card-1line-title-subtitle h6,
.xrx-dark-appearance .card-1line-title-subtitle .card-title,
.xrx-dark-appearance .card-2line-title h5,
.xrx-dark-appearance .card-2line-title h6,
.xrx-dark-appearance .card-2line-title .card-title {
  color: #ffffff;
}

.xrx-theme-appearance .card-1line-title .card-subtitle,
.xrx-theme-appearance .card-1line-title-subtitle .card-subtitle,
.xrx-theme-appearance .card-2line-title .card-subtitle,
.xrx-dark-appearance .card-1line-title .card-subtitle,
.xrx-dark-appearance .card-1line-title-subtitle .card-subtitle,
.xrx-dark-appearance .card-2line-title .card-subtitle {
  color: #e3edfc;
}

.xrx-theme-appearance .card-1line-title .right-object,
.xrx-theme-appearance .card-1line-title-subtitle .right-object,
.xrx-theme-appearance .card-2line-title .right-object,
.xrx-dark-appearance .card-1line-title .right-object,
.xrx-dark-appearance .card-1line-title-subtitle .right-object,
.xrx-dark-appearance .card-2line-title .right-object {
  color: #ffffff;
}

.xrx-theme-appearance .card-body,
.xrx-dark-appearance .card-body {
  color: #ffffff;
}

.xrx-dark-appearance .card-group {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .xrx-card {
  background-color: #3f3f43;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

.xrx-dark-appearance .card-1line-title .card-subtitle,
.xrx-dark-appearance .card-1line-title-subtitle .card-subtitle,
.xrx-dark-appearance .card-2line-title .card-subtitle {
  color: #bbbcc3;
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-card {
  background-color: #0c4fa3;
}

.xrx-dark-appearance .xrx-theme-appearance .card-1line-title .card-subtitle,
.xrx-dark-appearance .xrx-theme-appearance .card-1line-title-subtitle .card-subtitle,
.xrx-dark-appearance .xrx-theme-appearance .card-2line-title .card-subtitle {
  color: #e3edfc;
}

.xrx-theme-appearance .xrx-card.disabled {
  background-color: #2f74ca;
}

.xrx-theme-appearance .xrx-card.card-dragging {
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.16), 0 0 16px 0 rgba(0, 0, 0, 0.08);
}

.xrx-dark-appearance .xrx-card.disabled {
  background-color: #525256;
}

.xrx-dark-appearance .xrx-card.card-dragging {
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.48), 0 0 16px 0 rgba(0, 0, 0, 0.24);
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-card.disabled {
  background-color: #155fbb;
}

.using-mouse .xrx-dark-appearance .xrx-card.card-action:focus:not(.active):not(:hover),
.using-mouse .xrx-dark-appearance .xrx-card.card-action:focus:not(:active):not(:hover) {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.48);
}

@media (hover) {
  .using-mouse .xrx-dark-appearance .xrx-card.card-action:hover {
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
  }
}

.using-mouse .xrx-dark-appearance .xrx-card.card-action.active:not(.disabled),
.using-mouse .xrx-dark-appearance .xrx-card.card-action:active:not(.disabled) {
  box-shadow: inset 0 1px 2px 0 black;
}

.xrx-tooltip {
  position: absolute;
  z-index: 1090;
  display: block;
  margin: 0;
  font-family: "Roboto Condensed", Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  transition: opacity .165s, margin .165s ease-in-out;
  pointer-events: none;
}

.xrx-tooltip.show {
  opacity: 1;
}

.xrx-tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.xrx-tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.extend-xrx-tooltip-top, .xrx-tooltip-auto[data-popper-placement^="top"],
[class*="xrx-tooltip-top"] {
  margin: -5px 0 !important;
}

.extend-xrx-tooltip-top.show, .show.xrx-tooltip-auto[data-popper-placement^="top"],
[class*="xrx-tooltip-top"].show {
  margin: 3px 0 !important;
}

.extend-xrx-tooltip-top .tooltip-arrow, .xrx-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
[class*="xrx-tooltip-top"] .tooltip-arrow {
  bottom: -6px;
}

.extend-xrx-tooltip-top .tooltip-arrow::before, .xrx-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
[class*="xrx-tooltip-top"] .tooltip-arrow::before {
  border-width: 0.4rem 0.4rem 0;
  border-top-color: rgba(63, 63, 67, 0.94);
}

.extend-xrx-tooltip-right, .xrx-tooltip-auto[data-popper-placement^="right"],
[class*="xrx-tooltip-right"] {
  margin: 0 -5px !important;
}

.extend-xrx-tooltip-right.show, .show.xrx-tooltip-auto[data-popper-placement^="right"],
[class*="xrx-tooltip-right"].show {
  margin: 0 3px !important;
}

.extend-xrx-tooltip-right .tooltip-arrow, .xrx-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
[class*="xrx-tooltip-right"] .tooltip-arrow {
  left: -6px;
}

.extend-xrx-tooltip-right .tooltip-arrow::before, .xrx-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
[class*="xrx-tooltip-right"] .tooltip-arrow::before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: rgba(63, 63, 67, 0.94);
}

.extend-xrx-tooltip-bottom, .xrx-tooltip-auto[data-popper-placement^="bottom"],
[class*="xrx-tooltip-bottom"] {
  margin: -5px 0 !important;
}

.extend-xrx-tooltip-bottom.show, .show.xrx-tooltip-auto[data-popper-placement^="bottom"],
[class*="xrx-tooltip-bottom"].show {
  margin: 3px 0 !important;
}

.extend-xrx-tooltip-bottom .tooltip-arrow, .xrx-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
[class*="xrx-tooltip-bottom"] .tooltip-arrow {
  top: -6px;
}

.extend-xrx-tooltip-bottom .tooltip-arrow::before, .xrx-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
[class*="xrx-tooltip-bottom"] .tooltip-arrow::before {
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: rgba(63, 63, 67, 0.94);
}

.extend-xrx-tooltip-left, .xrx-tooltip-auto[data-popper-placement^="left"],
[class*="xrx-tooltip-left"] {
  margin: 0 -5px !important;
}

.extend-xrx-tooltip-left.show, .show.xrx-tooltip-auto[data-popper-placement^="left"],
[class*="xrx-tooltip-left"].show {
  margin: 0 3px !important;
}

.extend-xrx-tooltip-left .tooltip-arrow, .xrx-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
[class*="xrx-tooltip-left"] .tooltip-arrow {
  right: -13px;
}

.extend-xrx-tooltip-left .tooltip-arrow::before, .xrx-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
[class*="xrx-tooltip-left"] .tooltip-arrow::before {
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: rgba(63, 63, 67, 0.94);
}

.tooltip-header {
  display: flex;
  align-items: center;
  padding: 1px 1rem 0 1rem;
  height: 32px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .15px;
  background-color: #525256;
}

.tooltip-header:empty {
  display: none;
}

.tooltip-content {
  padding: 0.75rem 1rem;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.tooltip-inner {
  min-width: 48px;
  max-width: 250px;
  color: #ffffff;
  background-color: rgba(63, 63, 67, 0.94);
  border-radius: 0.375rem;
  box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.tooltip-dark-mode-appearance .tooltip-header {
  background-color: rgba(219, 219, 227, 0.94);
  color: #000000;
}

.tooltip-dark-mode-appearance .tooltip-inner {
  background-color: rgba(250, 250, 255, 0.94);
  color: #000000;
}

.tooltip-dark-mode-appearance[class*="xrx-tooltip-top"] .tooltip-arrow::before {
  border-top-color: rgba(250, 250, 255, 0.94);
}

.tooltip-dark-mode-appearance[class*="xrx-tooltip-right"] .tooltip-arrow::before {
  border-right-color: rgba(250, 250, 255, 0.94);
}

.tooltip-dark-mode-appearance[class*="xrx-tooltip-bottom"] .tooltip-arrow::before {
  border-bottom-color: rgba(250, 250, 255, 0.94);
}

.tooltip-dark-mode-appearance[class*="xrx-tooltip-left"] .tooltip-arrow::before {
  border-left-color: rgba(250, 250, 255, 0.94);
}

.xrx-nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  height: 64px;
  padding: 18px 16px 0 16px;
  color: #000000 !important;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15px;
  text-decoration: none;
  transition: background-color .5s;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

@media (hover) {
  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0s;
  }
}

.nav-link:active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000000;
}

.nav-link.active {
  color: #155fbb !important;
  background-color: #e3edfc;
}

.nav-link:focus {
  box-shadow: 0 0 0 3px #3a90d1, 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.using-mouse .nav-link:focus {
  box-shadow: none;
}

.nav-link.disabled {
  color: rgba(0, 0, 0, 0.3) !important;
  cursor: not-allowed;
}

.nav-link-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link-content .left-icon {
  margin-left: -2px;
  margin-right: -2px;
}

.nav-link-content .left-icon ~ .nav-link-label {
  margin-left: 13px;
}

.nav-link-content .caret {
  margin-left: 12px;
  margin-right: -3px;
}

.nav-link-content.glyph-icon-only .left-icon {
  margin-top: 3px;
  margin-right: -3px;
}

.nav-tabs .nav-link.glyph-icon-top {
  height: 76px;
}

.nav-tabs .nav-link.glyph-icon-top .nav-link-content {
  display: block;
  text-align: center;
}

.nav-tabs .nav-link.glyph-icon-top .nav-link-content .left-icon {
  display: inline-block;
  top: -2px;
}

.nav-tabs .nav-link.glyph-icon-top .nav-link-label {
  position: relative;
  top: -7px;
  display: block;
  margin-left: 0 !important;
}

.nav-tabs {
  opacity: 0;
  border-bottom: 1px solid rgba(103, 103, 108, 0.2);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  white-space: nowrap;
}

.nav-tabs .nav-link {
  height: 48px;
  padding: 11px 16px 0 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.sliding-indicator {
  position: absolute;
  bottom: -1px;
  height: 4px;
  background-color: #155fbb;
}

.sliding-indicator.animate {
  transition: left .25s, width .25s;
}

.xrx-dark-appearance .nav-link {
  color: #ffffff !important;
}

.xrx-dark-appearance .nav-link.active {
  color: #91b6ec !important;
}

@media (hover) {
  .xrx-dark-appearance .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.xrx-dark-appearance .nav-link:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.xrx-dark-appearance .nav-link.disabled {
  color: rgba(255, 255, 255, 0.3) !important;
  cursor: not-allowed;
}

.xrx-dark-appearance .nav-tabs {
  border-color: rgba(187, 188, 195, 0.2);
}

.xrx-dark-appearance .sliding-indicator {
  background-color: #91b6ec;
}

.xrx-dark-appearance .nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs.hide,
.xrx-nav-tab-select.hide {
  position: fixed;
  left: -5000px;
}

.nav-tabs.init {
  opacity: 1;
}

#content {
  padding-top: 4rem;
}

.xrx-navbar a {
  text-decoration: none;
}

.xrx-navbar .dn-level1,
.xrx-navbar .dn-level2,
.xrx-navbar .dn-level3,
.xrx-navbar .mn-level1,
.xrx-navbar .mn-level2,
.xrx-navbar .mn-level3 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.xrx-navbar .dn-level1 a,
.xrx-navbar .dn-level2 a,
.xrx-navbar .dn-level3 a,
.xrx-navbar .mn-level1 a,
.xrx-navbar .mn-level2 a,
.xrx-navbar .mn-level3 a {
  outline: none;
}

.dn-menu-item-label,
.mn-menu-item-label {
  position: relative;
  font-size: 1.25rem;
  letter-spacing: .15px;
  color: #000000;
  text-transform: capitalize;
}

.dn-level1-item-glyph,
.dn-level2-item-glyph,
.dn-level3-item-glyph,
.mn-level1-item-glyph,
.mn-level2-item-glyph,
.mn-level3-item-glyph {
  display: none;
}

/* ==============================================================================================
   ** SECTION navbar styles (Desktop)
============================================================================================== */
.dn-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: none;
  height: 4rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.dn-inner {
  position: relative;
  font-size: 0;
}

.dn-menu {
  position: relative;
  float: left;
}

.dn-app-brand {
  display: inline-flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  float: left;
}

.dn-app-brand .dn-brand-img {
  width: 6.25rem;
}

.dn-app-brand .dn-app-brand-label {
  padding-left: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: .25px;
  color: #000000;
}

.dn-brand-img-reverse {
  display: none;
}

.dn-dropdown-menu {
  padding: 0;
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 0;
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
  opacity: 1 !important;
  transition: none;
}

.dn-menu-item a {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0.0625rem 1rem 0 1rem;
  color: #000000;
  transition: background-color .5s;
}

.dn-menu-item a:focus {
  box-shadow: inset 0 0 0 3px #3a90d1, inset 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.dn-level1-item {
  float: left;
}

.dn-level1-item > a:hover {
  background-color: #dbdbe3;
  transition: none;
}

.dn-level1-item > a:active {
  background-color: #bbbcc3;
  transition: none;
}

.dn-level1-item > a.dn-indicator {
  color: #155fbb;
  background-color: #e3edfc !important;
  transition: background-color .5s;
}

.dn-level1-item > a.dn-indicator .dn-menu-item-label {
  color: #155fbb;
}

.dn-dropdown-toggle::after {
  display: none;
}

.dn-dropdown-btn-caret {
  margin-left: 0.5rem;
  margin-right: -0.375rem;
}

.dn-level2 {
  background-color: transparent;
}

.dn-level2.dn-open-left .dn-level3 {
  right: auto;
  left: 0;
}

.dn-open-left {
  right: 0;
  left: auto;
}

.dn-level2-item > a {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 0.625rem 0 1rem;
  background-color: rgba(250, 250, 255, 0.95);
  transition: background-color .5s;
}

.dn-level2-item > a:hover,
.dn-level2-item > .maintain-hover {
  background-color: #dbdbe3;
  transition: none;
}

.dn-level2-item > a:active:not([aria-haspopup]) {
  background-color: #bbbcc3;
}

.dn-level2-item > a > .xgl-sort_right {
  margin-left: auto;
}

.dn-level2-item > a.selected {
  color: #ffffff;
  background-color: #155fbb;
}

.dn-level2-item > a.selected .dn-menu-item-label {
  color: #ffffff;
}

.dn-level3 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
}

.dn-level3-item > a {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1rem;
  background-color: rgba(242, 242, 249, 0.95);
  transition: background-color .5s;
}

.dn-level3-item > a:hover {
  background-color: #dbdbe3;
  transition: none;
}

.dn-level3-item > a:active {
  background-color: #bbbcc3;
}

.dn-level3-item > a.selected {
  color: #ffffff;
  background-color: #155fbb;
}

.dn-level3-item > a.selected .dn-menu-item-label {
  color: #ffffff;
}

.nul-secondary-utility {
  float: right;
  display: flex;
  align-items: center;
  height: 4rem;
}

.nul-secondary-utility .nul-secondary-utility-btn {
  margin-right: 0.5rem;
}

/* ==============================================================================================
   ** SECTION navbar styles (Mobile)
============================================================================================== */
.mn-header {
  position: relative;
  height: 4rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.mn-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 970;
}

.mn-header-left-obj {
  display: flex;
  align-items: center;
  height: 4rem;
  float: left;
}

.mn-header-open-btn {
  padding: 0 0.5rem;
}

.mn-header-close-btn {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 0.5rem;
}

.mn-navbar-container {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: -19.375rem;
  z-index: 990;
  width: 18.75rem;
  min-height: 100%;
  background-color: #fafaff;
  transition: left .25s;
}

.mn-navbar-container.show {
  left: 0;
}

.mn-inner,
.mn-navbar-nav {
  position: relative;
  min-height: 100%;
}

.mn-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 18.75rem;
  padding-bottom: 4rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-right: 1px solid #dbdbe3;
}

.mn-container::-webkit-scrollbar {
  display: none;
}

.mn-footer {
  position: absolute;
  bottom: -4rem;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  background-color: transparent;
}

.mn-footer .mn-brand-img {
  width: 6.25rem;
  font-size: 0;
}

.mn-backdrop {
  position: fixed;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  z-index: 980;
  background-color: #000000;
  opacity: 0;
  transition: opacity .25s;
}

.mn-backdrop.show {
  right: 0;
  opacity: .35;
}

.mn-backdrop.fade {
  opacity: 0;
}

.mn-app-brand {
  display: inline-flex;
  align-items: center;
  height: 4rem;
}

.mn-app-brand .mn-app-brand-label {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: .25px;
  color: #000000;
}

.mn-brand-img-reverse {
  display: none;
}

.mn-menu-item a {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 0.9375rem 0 1rem;
  color: #000000;
  transition: background-color .5s;
}

@media (hover) {
  .mn-menu-item a:hover {
    background-color: #dbdbe3;
    transition: none !important;
  }
}

.mn-menu-item a:active {
  background-color: #bbbcc3;
  transition: none;
}

.mn-menu-item a:focus {
  box-shadow: inset 0 0 0 3px #3a90d1, inset 0 0 0 6px rgba(58, 144, 209, 0.2);
}

.mn-collapse-target-caret {
  top: 1px;
  margin-left: 8px;
}

.mn-level1 {
  position: relative;
}

.mn-menu-item-label {
  top: 0.0625rem;
}

.mn-level1-item {
  position: relative;
}

.mn-level1-item .custom-icon {
  width: 36px;
  margin: 10px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.08);
}

.mn-navbar-container.collapsing .mn-level2-item > a,
.mn-navbar-container.collapsed .mn-level2-item > a {
  padding-left: 22px;
}

.mn-level2-item {
  position: relative;
}

/* ==============================================================================================
   ** SECTION Nav Options
============================================================================================== */
.nav-opt-glyphs .dn-level1-item-glyph,
.nav-opt-glyphs .dn-level2-item-glyph,
.nav-opt-glyphs .dn-level3-item-glyph,
.nav-opt-glyphs .mn-level1-item-glyph,
.nav-opt-glyphs .mn-level2-item-glyph,
.nav-opt-glyphs .mn-level3-item-glyph {
  display: table-cell;
}

.nav-opt-glyphs .dn-level1-item-glyph {
  margin-right: 1rem;
}

.nav-opt-glyphs .dn-level2-item-glyph,
.nav-opt-glyphs .dn-level3-item-glyph {
  margin-right: 1rem;
}

.nav-opt-glyphs .mn-level1-item-glyph,
.nav-opt-glyphs .mn-level2-item-glyph,
.nav-opt-glyphs .mn-level3-item-glyph {
  margin-right: 1rem;
}

.nav-opt-indent .mn-level2-item > a {
  padding-left: 2rem;
}

.nav-opt-indent .mn-level3-item > a {
  padding-left: 3rem;
}

.nav-drawer-minimize-toggle {
  display: none;
}

.nav-opt-minimize .nav-drawer-minimize-toggle {
  display: block;
  position: absolute;
  bottom: -64px;
  width: 100%;
  height: 64px;
  text-align: right;
}

.nav-opt-minimize .nav-drawer-minimize-inner {
  position: relative;
  display: block;
  padding: 16px;
}

.nav-opt-minimize .mn-menu-item-label {
  white-space: nowrap;
}

.nav-opt-ext #content {
  padding-top: 7rem;
}

.nav-opt-ext .dn-header {
  height: 7rem;
}

.nav-opt-ext .dn-menu-container {
  position: absolute;
  top: 4rem;
  left: 0;
  display: inline-block;
  width: 100%;
  margin-left: auto;
  background-color: #ffffff;
}

.nav-opt-ext .dn-menu-container .dn-level1-item > a {
  height: 3rem;
}

.nav-opt-left-navbar {
  margin-left: 18.75rem;
}

.nav-opt-left-navbar .dn-menu {
  display: none;
}

.nav-opt-left-navbar .mn-inner {
  padding-top: 16px;
}

.nav-opt-left-navbar .mn-close-btn {
  visibility: hidden;
  outline: none;
}

.nav-opt-left-navbar .mn-navbar-container {
  display: block !important;
}

.nav-opt-left-navbar .mn-container {
  padding-bottom: 64px;
}

.nav-opt-left-navbar .mn-footer {
  display: none;
}

.nav-opt-left-navbar .xrx-footer {
  margin-left: 18.75rem;
}

.nav-opt-ds-go .mn-level1-item > a {
  padding: 0;
  transition: padding .25s, margin .25s, background-color .5s;
}

.nav-opt-ds-go .mn-level2-item > a {
  padding-left: 2.5rem;
  transition: padding .25s, margin .25s, background-color .5s;
}

.nav-opt-ds-go .mn-level2-item-glyph {
  top: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  padding-right: 2.5rem;
}

.nav-opt-ds-go .mn-container {
  padding-bottom: 8rem;
}

.nav-opt-ds-go .nav-drawer-minimize-toggle {
  bottom: 0 !important;
  height: 8rem;
  background-color: #fafaff;
  border-top: 1px solid #dbdbe3;
  border-right: 1px solid #dbdbe3;
}

.nav-opt-ds-go .nav-collapse-btn {
  float: right;
}

.nav-opt-ds-go .new-collection-btn {
  margin-bottom: 16px;
  min-width: 40px;
}

@media (max-width: 768px) {
  .nav-opt-ds-go .new-collection-btn {
    margin-bottom: 0;
  }
  .nav-opt-ds-go .nav-drawer-minimize-toggle {
    height: 4.5625rem;
  }
  .nav-opt-ds-go .mn-container {
    padding-bottom: 4.5625rem;
  }
}

.nav-opt-ds-go .new-collection {
  height: 0px;
  transition: height .25s linear;
}

.nav-opt-ds-go .new-collection > a {
  opacity: 0;
  transition: opacity .25s linear;
}

.nav-opt-ds-go .new-collection.animate-height {
  height: 56px;
}

.nav-opt-ds-go .new-collection.fade-in > a {
  opacity: 1;
}

/* ==============================================================================================
   ** SECTION media queries
============================================================================================== */
@media (min-width: 992px) {
  .xrx-brand-header .content,
  .dn-navbar-nav .dn-inner {
    max-width: 970px;
  }
}

@media (min-width: 769px) {
  .dn-header {
    display: block !important;
  }
  .mn-header,
  .mn-navbar-container,
  .mn-backdrop {
    display: none;
  }
  .nav-opt-left-navbar .mn-navbar-container {
    left: 0;
  }
}

@media (max-width: 768px) {
  body {
    margin-left: 0 !important;
  }
  #content {
    padding-top: 4rem !important;
  }
  .mn-inner {
    padding-top: 0 !important;
  }
  .mn-backdrop {
    display: block !important;
  }
  .mn-close-btn {
    visibility: visible !important;
  }
  .mn-navbar-container {
    padding-top: 0 !important;
    box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  }
  .mn-container {
    border-right: none;
  }
  .mn-footer {
    display: flex !important;
  }
  .nav-collapse-btn,
  .nav-drawer-minimize-toggle {
    display: none;
  }
  .new-collection-btn {
    margin-bottom: 0;
  }
  .xrx-footer {
    margin-left: 0 !important;
  }
}

/* ==============================================================================================
   ** SECTION sliding indicator
============================================================================================== */
.dn-sliding-indicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: #155fbb;
}

.dn-sliding-indicator.animate {
  transition: left .25s, width .25s;
}

.mn-level2-sliding-indicator,
.mn-level3-sliding-indicator,
.mn-global-sliding-indicator {
  position: absolute;
  right: 0;
  width: 4px;
  background-color: #155fbb;
  visibility: hidden;
}

.mn-level2-sliding-indicator.animate,
.mn-level3-sliding-indicator.animate,
.mn-global-sliding-indicator.animate {
  transition: top .25s;
}

.mn-level2-sliding-indicator.show,
.mn-level3-sliding-indicator.show,
.mn-global-sliding-indicator.show {
  visibility: visible;
}

.mn-indicator {
  position: relative;
}

.mn-indicator::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4px;
  background-color: #155fbb;
  visibility: hidden;
}

.mn-indicator.mn-indicator-show::after {
  content: '';
  visibility: visible;
}

.mn-indicator {
  color: #155fbb !important;
  background-color: #e3edfc !important;
  transition: padding .25s, margin .25s, width .15s, background-color .5s !important;
}

.mn-indicator .mn-menu-item-label {
  color: #155fbb;
}

.dn-dropdown.show {
  position: relative;
}

.transition-off {
  transition-property: none !important;
}

.using-mouse .dn-menu-item a:focus,
.using-mouse .mn-menu-item a:focus {
  outline: none;
  box-shadow: none;
}

/* ==============================================================================================
   ** SECTION sliding indicator
============================================================================================== */
.xrx-dark-appearance .dn-header {
  background-color: #29292d;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
}

.xrx-dark-appearance .dn-header .dropdown-toggle {
  background-color: transparent;
}

.xrx-dark-appearance .dn-brand-img-default {
  display: none;
}

.xrx-dark-appearance .dn-brand-img-reverse {
  display: inline;
}

.xrx-dark-appearance .dn-app-brand-label {
  color: #ffffff;
}

.xrx-dark-appearance .dn-sliding-indicator {
  background-color: #91b6ec;
}

.xrx-dark-appearance .dn-level1-item > a,
.xrx-dark-appearance .dn-level1-item > a .dn-menu-item-label {
  color: #ffffff;
}

.xrx-dark-appearance .dn-level1-item > a:hover {
  background-color: #00173c;
}

.xrx-dark-appearance .dn-level1-item > a:active {
  background-color: #001128;
}

.xrx-dark-appearance .dn-level1-item > a.dn-indicator {
  background-color: #00173c !important;
  color: #91b6ec;
}

.xrx-dark-appearance .dn-level1-item > a.dn-indicator .dn-menu-item-label {
  color: #91b6ec;
}

.xrx-dark-appearance .dn-level2-item > a,
.xrx-dark-appearance .dn-level3-item > a {
  color: #ffffff;
}

.xrx-dark-appearance .dn-level2-item > a .dn-menu-item-label,
.xrx-dark-appearance .dn-level3-item > a .dn-menu-item-label {
  color: #ffffff;
}

.xrx-dark-appearance .dn-level2-item > a:hover,
.xrx-dark-appearance .dn-level3-item > a:hover {
  background-color: #1b1b1e;
}

.xrx-dark-appearance .dn-level2-item > a:active:not([aria-haspopup]),
.xrx-dark-appearance .dn-level3-item > a:active:not([aria-haspopup]) {
  background-color: #0e0e10;
}

.xrx-dark-appearance .dn-level2-item > a.selected,
.xrx-dark-appearance .dn-level3-item > a.selected {
  color: #000000;
  background-color: #91b6ec;
}

.xrx-dark-appearance .dn-level2-item > a.selected .dn-menu-item-label,
.xrx-dark-appearance .dn-level3-item > a.selected .dn-menu-item-label {
  color: #000000;
}

.xrx-dark-appearance .dn-level2-item > a {
  background-color: rgba(63, 63, 67, 0.95);
}

.xrx-dark-appearance .dn-level2-item > .maintain-hover {
  background-color: #1b1b1e;
}

.xrx-dark-appearance .dn-level3-item > a {
  background-color: rgba(41, 41, 45, 0.95);
}

.xrx-dark-appearance .mn-navbar-container {
  background-color: #3f3f43;
}

.xrx-dark-appearance .mn-container {
  border-right: 1px solid #1b1b1e;
}

.xrx-dark-appearance .mn-header {
  background-color: #29292d;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.48), 0 0 4px 0 rgba(0, 0, 0, 0.24);
}

.xrx-dark-appearance .mn-app-brand .mn-app-brand-label {
  color: #ffffff;
}

.xrx-dark-appearance .mn-menu-item a {
  color: #ffffff;
}

@media (hover) {
  .xrx-dark-appearance .mn-menu-item a:hover {
    background-color: #1b1b1e;
  }
}

.xrx-dark-appearance .mn-menu-item a:active {
  background-color: #0e0e10;
}

.xrx-dark-appearance .mn-menu-item-label {
  color: #ffffff;
}

.xrx-dark-appearance .mn-indicator {
  color: #91b6ec !important;
  background-color: #00173c !important;
}

.xrx-dark-appearance .mn-indicator .mn-menu-item-label {
  color: #91b6ec;
}

.xrx-dark-appearance .mn-indicator::after {
  background-color: #91b6ec;
}

.xrx-dark-appearance .mn-level2-sliding-indicator,
.xrx-dark-appearance .mn-level3-sliding-indicator,
.xrx-dark-appearance .mn-global-sliding-indicator {
  background-color: #91b6ec;
}

.xrx-dark-appearance .mn-brand-img-default {
  display: none;
}

.xrx-dark-appearance .mn-brand-img-reverse {
  display: inline;
}

.xrx-theme-appearance.dn-header {
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.xrx-theme-appearance .dn-brand-img-default {
  display: none;
}

.xrx-theme-appearance .dn-brand-img-reverse {
  display: inline;
}

.xrx-theme-appearance .dn-app-brand-label {
  color: #ffffff;
}

.xrx-theme-appearance .dn-sliding-indicator {
  background-color: #ffffff;
}

.xrx-theme-appearance .dn-level1-item > a,
.xrx-theme-appearance .dn-level1-item > a .dn-menu-item-label {
  color: #ffffff;
}

.xrx-theme-appearance .dn-level1-item > a:hover {
  background-color: #002760;
}

.xrx-theme-appearance .dn-level1-item > a:active {
  background-color: #00173c;
}

.xrx-theme-appearance .dn-level1-item > a.dn-indicator {
  background-color: #01377f !important;
}

.xrx-theme-appearance .dn-level1-item > a.dn-indicator .dn-menu-item-label {
  color: #ffffff;
}

.xrx-theme-appearance .dn-menu-container {
  background-color: #0c4fa3;
}

.xrx-theme-appearance .mn-app-brand .mn-app-brand-label {
  color: #ffffff;
}

.xrx-theme-appearance.mn-header {
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.xrx-dark-appearance .xrx-theme-appearance .dn-header,
.xrx-dark-appearance .xrx-theme-appearance .dn-menu-container {
  background-color: #01377f;
}

.xrx-dark-appearance .xrx-theme-appearance .dn-level1-item > a:hover {
  background-color: #00173c;
}

.xrx-dark-appearance .xrx-theme-appearance .dn-level1-item > a:active {
  background-color: #001128;
}

.xrx-dark-appearance .xrx-theme-appearance .dn-level1-item > a.dn-indicator {
  background-color: #002760 !important;
  color: #ffffff;
}

.xrx-dark-appearance .xrx-theme-appearance .dn-level1-item > a.dn-indicator .dn-menu-item-label {
  color: #ffffff;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 4.5rem;
}

.xrx-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  min-height: 4.5rem;
  color: #000000;
  background-color: #dbdbe3;
}

.xrx-footer ul {
  padding: 0;
  margin-bottom: 0.5rem;
  list-style: none;
}

.xrx-footer ul:last-of-type li:last-child::after {
  display: none;
}

.xrx-footer li {
  display: inline-block;
  font-size: 1rem;
}

.xrx-footer li::after {
  display: inline-block;
  padding: 0 0.5rem;
  content: "|";
  color: #525256;
}

.xrx-footer a {
  color: #000000;
  text-decoration: none;
}

.xrx-footer a:hover {
  text-decoration: underline;
}

.xrx-footer .content {
  padding: 1rem;
  font-size: 1rem;
  letter-spacing: .25px;
  line-height: 1.2;
}

.xrx-footer .content .xerox-copyright {
  font-size: 0.6875rem;
  letter-spacing: .35px;
  color: #525256;
}

.xrx-dark-appearance .xrx-footer {
  background-color: #1b1b1e;
  color: #ffffff;
}

.xrx-dark-appearance .xrx-footer a {
  color: #ffffff;
}

.xrx-dark-appearance .xrx-footer li::after {
  color: #bbbcc3;
}

.xrx-dark-appearance .xrx-footer .content .xerox-copyright {
  color: #bbbcc3;
}

.xrx-skip:focus {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1038;
  height: 4rem;
  padding-top: 1.125rem;
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.95);
  outline: none;
}

.modal-open {
  overflow: hidden;
}

.xrx-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .xrx-modal {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.modal-dialog {
  position: relative;
  width: auto;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px;
  pointer-events: none;
}

.xrx-modal.fade .modal-dialog {
  transition: transform 0.15s ease-out;
  transform: translate(0, -20px);
}

@media screen and (prefers-reduced-motion: reduce) {
  .xrx-modal.fade .modal-dialog {
    transition: none;
  }
}

.xrx-modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog.modal-fullscreen {
  transform: none !important;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fafaff;
  background-clip: padding-box;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.16), 0 0 16px 0 rgba(0, 0, 0, 0.08);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  min-height: 4rem;
  background-color: #fafaff;
}

.modal-header .close {
  margin-right: -0.5rem;
}

.modal-header.modal-header-border {
  border-bottom: 1px solid #dbdbe3;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: .25px;
  line-height: 1.25;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.modal-footer {
  padding: 0.5rem;
}

.modal-footer .xrx-btn {
  width: 100%;
}

.modal-footer.modal-footer-border {
  border-top: 1px solid #dbdbe3;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-sm {
  margin: 0.5rem auto;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.6);
  }
}

.modal-xs {
  max-width: 300px;
}

.modal-sm {
  max-width: 500px;
}

.modal-md {
  max-width: 800px;
}

.modal-lg {
  max-width: 1140px;
}

.modal-fullscreen .modal-content {
  position: fixed;
  top: -20px;
  bottom: 20px;
  left: 0;
  right: 0;
  border-radius: 0;
  transition: top .15s, bottom .15s;
}

.xrx-modal.show .modal-fullscreen .modal-content {
  top: 0;
  bottom: 0;
}

.modal-demoted {
  z-index: 1039;
}

.no-backdrop {
  opacity: 0 !important;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .modal-buttons .cb {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .modal-buttons {
    display: block;
  }
}

.xrx-dark-appearance .modal-header,
.xrx-dark-appearance .modal-content {
  background-color: #3f3f43;
}

.xrx-dark-appearance .modal-content {
  box-shadow: 0 16px 24px 5px rgba(0, 0, 0, 0.48), 0 0 16px 0 rgba(0, 0, 0, 0.24);
}

.xrx-dark-appearance .modal-header.modal-header-border {
  border-bottom-color: #1b1b1e;
}

.xrx-dark-appearance .modal-footer.modal-footer-border {
  border-top-color: #1b1b1e;
}

.alpha-index {
  position: relative;
}

.alpha-index .scroll-container {
  height: 100vh;
  overflow: scroll;
}

.alpha-index .xrx-list-group:first-child,
.alpha-index .xrx-list-group:first-child .list-group-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.alpha-index .xrx-list-group:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#alpha-index {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 16px;
  cursor: default;
  line-height: 14px;
}

#alpha-index ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
  border-radius: 15px;
  transition: background-color .1s linear, color .1s linear;
}

#alpha-index [id^="index-"] {
  padding: 0px 8px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  user-select: none;
}

#centered-index-notifier {
  position: absolute;
  top: 0;
  height: 100px;
  width: 100px;
  background-color: #3f3f43;
  border-radius: 5px;
  text-align: center;
  font-size: 60px;
  color: #ffffff;
  font-weight: bold;
  line-height: 100px;
  visibility: hidden;
  user-select: none;
  z-index: 2;
}

.show-notifier {
  visibility: visible !important;
}

.active-index {
  background-color: #3f3f43;
  color: #ffffff;
}

@media (hover) {
  #alpha-index > ul:hover {
    background-color: #3f3f43;
    color: #ffffff;
  }
}

.hide-index-letter {
  display: none;
}

.dot-size {
  font-size: 20px !important;
}

@media (min-height: 481px) {
  #alpha-index {
    line-height: 15px;
  }
}

.xrx-theme-appearance {
  background-color: #0c4fa3;
}

.xrx-dark-appearance {
  background-color: #29292d;
}

.xrx-dark-appearance body,
.xrx-dark-appearance h1, .xrx-dark-appearance h2, .xrx-dark-appearance h3, .xrx-dark-appearance h4, .xrx-dark-appearance h5, .xrx-dark-appearance h6,
.xrx-dark-appearance .h1, .xrx-dark-appearance .h2, .xrx-dark-appearance .h3, .xrx-dark-appearance .h4, .xrx-dark-appearance .h5, .xrx-dark-appearance .h6 {
  color: #ffffff;
}

body.xrx-dark-appearance {
  color: #ffffff;
}

.xrx-dark-appearance {
  color: #ffffff;
}

.xrx-dark-appearance .xrx-theme-appearance {
  background-color: #01377f;
}

/*# sourceMappingURL=xerox-blue.css.map */