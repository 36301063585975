/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// xwuikit themes variables
@import "xrx-theme"; // default
// @import "xrx-theme-override";

// xwuikit 9x variables
@import "xrx-functions"; // default
@import "xrx-mixins"; // default
@import "xrx-utilities"; // default
@import "xrx9x-shadows-variables"; // default

// Bootstrap variables
@import "functions"; // default
@import "variables"; // default
@import "mixins"; // default
@import "root"; // default

// Bootstrap
@import "reboot"; // default
@import "type"; // default
@import "images"; // default
@import "code"; // default
@import "grid"; // default
// @import "tables";
// @import "forms";
// @import "buttons";
@import "transitions"; // default
// @import "dropdown";
// @import "button-group";
// @import "input-group";
// @import "custom-forms";
// @import "nav";
// @import "navbar";
// @import "card";
// @import "breadcrumb";
// @import "pagination";
// @import "badge";
// @import "jumbotron";
// @import "alert";
// @import "progress";
@import "media"; // default
// @import "list-group";
@import "close";
// @import "modal";
// @import "tooltip";
// @import "popover";
// @import "carousel";
@import "utilities"; // default
@import "print"; // default

// xwuikit utility
// @import "xrx9x-delay-visibility";

// xwuikit icons
@import "xrx-icons"; // default

// xwuikit xglyph fonts
@import "xrx-xglyph-font-face-local-build"; // default
@import "xrx-xglyph-font"; // default
@import "xrx-xglyph-icons"; // default
@import "xrx9x-stacked-icon"; // default

// xwuikit 9x svg
@import "xrx9x-svg-defs"; // default

// xwuikit forms
@import "xrx9x-forms"; // default
@import "xrx9x-input-group"; // default

// xwuikit 9x components
@import "xrx9x-headers";
@import "xrx9x-buttons";
@import "xrx9x-checkbox"; // includes styles for radio buttons
@import "xrx9x-switch";
@import "xrx9x-list-group";
@import "xrx9x-dropdown";
@import "xrx9x-select";
@import "xrx9x-cards";
@import "xrx9x-tooltip";
@import "xrx9x-nav";
@import "xrx9x-navbar";
@import "xrx9x-footer";
@import "xrx9x-skip";
@import "xrx9x-modal";
@import "xrx9x-alpha-index";

// xwuikit 9x backgrounds - theme utility classes
@import "xrx9x-theme-appearance"; // default
@import "xrx9x-dark-appearance"; // default
