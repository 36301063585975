// stylelint-disable

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .h7 {
  margin-bottom: rem(ptr(17));
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: rem(ptr(34));
}
h4, .h4,
h5, .h5,
h6, .h6,
.h7 {
  margin-top: rem(ptr(17));
}

h1, .h1 {
  font-size: $h1-font-size;
  letter-spacing: −1.5px;
}
h2, .h2 {
  font-size: $h2-font-size;
  letter-spacing: −0.5px;
}
h3, .h3 {
  font-size: $h3-font-size;
  letter-spacing: 0px;
}
h4, .h4 {
  font-size: $h4-font-size;
  letter-spacing: .25px;
}
h5, .h5 {
  font-size: $h5-font-size;
  letter-spacing: 0px;
}
h6, .h6 {
  font-size: $h6-font-size;
  letter-spacing: 0px;
}
.h7 {
  font-size: $h7-font-size;
  letter-spacing: .25px;
}

h1, .h1,
h2, .h2 {
  font-weight: $font-weight-light;
}

h3, .h3,
h4, .h4,
h6, .h6 {
  font-weight: $font-weight-normal;
}

h5, .h5,
.h7 {
  font-weight: $font-weight-bold;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $lead-line-height;
  color: $lead-color;
}

// Type display classes
// .display-1 {
//   font-size: $display1-size;
//   font-weight: $display1-weight;
//   line-height: $display-line-height;
// }
// .display-2 {
//   font-size: $display2-size;
//   font-weight: $display2-weight;
//   line-height: $display-line-height;
// }
// .display-3 {
//   font-size: $display3-size;
//   font-weight: $display3-weight;
//   line-height: $display-line-height;
// }
// .display-4 {
//   font-size: $display4-size;
//   font-weight: $display4-weight;
//   line-height: $display-line-height;
// }


//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}

// xwuikit
.bg-primary {
  color: $white;
}

// stylelint-enable
