// stylelint-disable
.xrx-radio,
.xrx-checkbox {
  position: relative;
  display: table;
  font-size: 0;

  label {
    position: relative;
    top: 10px;
    margin-bottom: 0;
    margin-left: -2px;
    font-size: 20px;
    letter-spacing: .16px;
    float: right;
    user-select: none;
  }

  &.radio-sm,
  &.checkbox-sm {
     label {
      top: 5px;
      font-size: 16px;
      letter-spacing: .20px;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .svg-checkbox-indeterminate,
  .svg-checkbox-checkmark,
  .svg-checkbox-focus,
  .svg-checkbox-background {
    opacity: 0;
  }

  .svg-checkbox-outer-focus-ring {
    opacity: .2;
  }

  .svg-checkbox-focus {
    fill: $theme-a1;
  }

  .svg-checkbox {
    fill: $neutral-600;
  }

  @media (hover) {
    &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
      opacity: 1;
      fill: rgba($neutral-600, .2);
    }
  }

  &:active input:not(:disabled) ~ svg {
    .svg-checkbox-background {
      opacity: 1;
      fill: $neutral-700;
    }
    .svg-checkbox {
      opacity: 0;
    }
  }

  input:focus ~ svg .svg-checkbox-focus {
    opacity: 1;
  }

  input:disabled {
    ~ label {
      color: rgba($black, .3);
    }
    ~ svg {
      cursor: not-allowed;
      .svg-checkbox {
        fill: rgba($neutral-600, .3);
      }
    }
  }

  &.checked {
    .svg-checkbox-checkmark {
      opacity: 1;
      fill: $white;
    }

    .svg-checkbox-background {
      opacity: 1;
      fill: $theme-500;
    }

    .svg-checkbox {
      opacity: 0;
    }

    @media (hover) {
      &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
        fill: $theme-700;
      }
    }

    &:active input:not(:disabled) ~ svg {
      .svg-checkbox-checkmark {
        opacity: 0;
      }
      .svg-checkbox-background {
        fill: $theme-700;
      }
    }

    input:disabled {
      ~ label {
        color: rgba($black, .3);
      }
      ~ svg .svg-checkbox-background {
        fill: rgba($theme-500, .3);
      }
    }
  }

  &.indeterminate {
    .svg-checkbox-indeterminate {
      opacity: 1;
      fill: $white;
    }

    .svg-checkbox-background {
      opacity: 1;
      fill: $theme-500;
    }

    .svg-checkbox {
      opacity: 0;
    }

    @media (hover) {
      &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
        fill: $theme-700;
      }
    }

    &:active input:not(:disabled) ~ svg {
      .svg-checkbox-indeterminate {
        opacity: 0;
      }
      .svg-checkbox-background {
        fill: $theme-700;
      }
    }

    input:disabled {
      ~ label {
        color: rgba($black, .3);
      }
      ~ svg .svg-checkbox-background {
        fill: rgba($theme-500, .3);
      }
    }
  }
}

.xrx-dark-appearance {
  .xrx-radio,
  .xrx-checkbox {
    label {
      color: $white;
    }

    .svg-checkbox {
      fill: $neutral-200;
    }

    @media (hover) {
      &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
        opacity: 1;
        fill: rgba($neutral-200, .2);
      }
    }

    &:active input:not(:disabled) ~ svg {
      .svg-checkbox-background {
        opacity: 1;
        fill: $neutral-300;
      }
      .svg-checkbox {
        opacity: 0;
      }
    }

    input:focus ~ svg .svg-checkbox-focus {
      opacity: 1;
    }

    input:disabled {
      ~ label {
        color: rgba($white, .3);
      }
      ~ svg .svg-checkbox {
        fill: rgba($neutral-200, .3);
      }
    }

    &.checked {
      .svg-checkbox-checkmark {
        opacity: 1;
        fill: $dark-appearance;
      }

      .svg-checkbox-background {
        opacity: 1;
        fill: $theme-200;
      }

      .svg-checkbox {
        opacity: 0;
      }

      @media (hover) {
        &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
          fill: $theme-300;
        }
      }

      &:active input:not(:disabled) ~ svg {
        .svg-checkbox-checkmark {
          opacity: 0;
        }
        .svg-checkbox-background {
          fill: $theme-300;
        }
      }

      input:disabled {
        ~ label {
          color: rgba($white, .3);
        }
        ~ svg .svg-checkbox-background {
          fill: rgba($theme-200, .3);
        }
      }
    }

    &.indeterminate {
      .svg-checkbox-indeterminate {
        opacity: 1;
        fill: $dark-appearance;
      }

      .svg-checkbox-background {
        opacity: 1;
        fill: $theme-200;
      }

      .svg-checkbox {
        opacity: 0;
      }

      @media (hover) {
        &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
          fill: $theme-300;
        }
      }

      &:active input:not(:disabled) ~ svg {
        .svg-checkbox-indeterminate {
          opacity: 0;
        }
        .svg-checkbox-background {
          fill: $theme-300;
        }
      }

      input:disabled {
        ~ label {
          color: rgba($white, .3);
        }
        ~ svg .svg-checkbox-background {
          fill: rgba($theme-200, .3);
        }
      }
    }
  }
}

.xrx-theme-appearance {
  .xrx-radio,
  .xrx-checkbox {
    label {
      color: $white;
    }

    .svg-checkbox {
      fill: $theme-50;
    }

    @media (hover) {
      &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
        opacity: 1;
        fill: rgba($theme-50, .2);
      }
    }

    &:active input:not(:disabled) ~ svg {
      .svg-checkbox-background {
        opacity: 1;
        fill: $theme-200;
      }
      .svg-checkbox {
        opacity: 0;
      }
    }

    input:focus ~ svg .svg-checkbox-focus {
      opacity: 1;
    }

    input:disabled {
      ~ label {
        color: rgba($white, .3);
      }
      ~ svg .svg-checkbox {
        fill: rgba($theme-50, .3);
      }
    }

    &.checked {
      .svg-checkbox-checkmark {
        opacity: 1;
        fill: $light-theme-appearance;
      }

      .svg-checkbox-background {
        opacity: 1;
        fill: $theme-100;
      }

      .svg-checkbox {
        opacity: 0;
      }

      @media (hover) {
        &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
          fill: $theme-200;
        }
      }

      &:active input:not(:disabled) ~ svg {
        .svg-checkbox-checkmark {
          opacity: 0;
        }
        .svg-checkbox-background {
          fill: $theme-200;
        }
      }

      input:disabled {
        ~ label {
          color: rgba($white, .3);
        }
        ~ svg .svg-checkbox-background {
          fill: rgba($theme-100, .3);
        }
      }
    }

    &.indeterminate {
      .svg-checkbox-indeterminate {
        opacity: 1;
        fill: $light-theme-appearance;
      }

      .svg-checkbox-background {
        opacity: 1;
        fill: $theme-100;
      }

      .svg-checkbox {
        opacity: 0;
      }

      @media (hover) {
        &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
          fill: $theme-200;
        }
      }

      &:active input:not(:disabled) ~ svg {
        .svg-checkbox-indeterminate {
          opacity: 0;
        }
        .svg-checkbox-background {
          fill: $theme-200;
        }
      }

      input:disabled {
        ~ label {
          color: rgba($white, .3);
        }
        ~ svg .svg-checkbox-background {
          fill: rgba($theme-100, .3);
        }
      }
    }
  }
}

.xrx-dark-appearance {
  .xrx-theme-appearance {
    .xrx-radio,
    .xrx-checkbox {
      label {
        color: $white;
      }

      .svg-checkbox {
        fill: $theme-100;
      }

      @media (hover) {
        &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
          opacity: 1;
          fill: rgba($theme-100, .2);
        }
      }

      &:active input:not(:disabled) ~ svg {
        .svg-checkbox-background {
          opacity: 1;
          fill: $theme-200;
        }
        .svg-checkbox {
          opacity: 0;
        }
      }

      input:focus ~ svg .svg-checkbox-focus {
        opacity: 1;
      }

      input:disabled {
        ~ label {
          color: rgba($white, .3);
        }
        ~ svg .svg-checkbox {
          fill: rgba($theme-100, .3);
        }
      }

      &.checked {
        .svg-checkbox-checkmark {
          opacity: 1;
          fill: $dark-theme-appearance;
        }

        .svg-checkbox-background {
          opacity: 1;
          fill: $theme-100;
        }

        .svg-checkbox {
          opacity: 0;
        }

        @media (hover) {
          &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
            fill: $theme-200;
          }
        }

        &:active input:not(:disabled) ~ svg {
          .svg-checkbox-checkmark {
            opacity: 0;
          }
          .svg-checkbox-background {
            fill: $theme-200;
          }
        }

        input:disabled {
          ~ label {
            color: rgba($white, .3);
          }
          ~ svg .svg-checkbox-background {
            fill: rgba($theme-100, .3);
          }
        }
      }

      &.indeterminate {
        .svg-checkbox-indeterminate {
          opacity: 1;
          fill: $light-theme-appearance;
        }

        .svg-checkbox-background {
          opacity: 1;
          fill: $theme-100;
        }

        .svg-checkbox {
          opacity: 0;
        }

        @media (hover) {
          &:hover input:not(:disabled) ~ svg .svg-checkbox-background {
            fill: $theme-200;
          }
        }

        &:active input:not(:disabled) ~ svg {
          .svg-checkbox-indeterminate {
            opacity: 0;
          }
          .svg-checkbox-background {
            fill: $theme-200;
          }
        }

        input:disabled {
          ~ label {
            color: rgba($white, .3);
          }
          ~ svg .svg-checkbox-background {
            fill: rgba($theme-100, .3);
          }
        }
      }
    }
  }
}

.using-mouse {
  .svg-checkbox-focus {
    opacity: 0 !important;
  }
}

// stylelint-enable
