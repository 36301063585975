// stylelint-disable

.xrx-skip:focus {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-xrx-skip;
  height: rem(ptr(64));
  padding-top: rem(ptr(18));
  color: $white;
  text-align: center;
  background-color: rgba($black, .95);
  outline: none;
}

// stylelint-enable
