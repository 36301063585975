// stylelint-disable

//
// Textual form controls
//

label {
  margin-bottom: rem(ptr(3));
  color: $black;
  font-size: rem(ptr(16));
  font-weight: 400;
  letter-spacing: .25px;
}

.form-text {
  display: flex;
  align-items: center;
  margin-top: rem(ptr(3));
  margin-bottom: rem(ptr(16));
  font-size: 15px;
  font-weight: 400;
  letter-spacing: .30px;
  color: $neutral-600;

  &.multiline {
    display: block;
  }

  .message-container {
    display: flex;
    align-items: center;
  }
}

.xrx-input {
  height: 48px;
  padding: 3px;
  margin-bottom: rem(ptr(16));
  background-color: $neutral-25;
  border: 1px solid $neutral-100;
  border-radius: 6px;
  transition: background-color .165s, box-shadow .165s;

  &.input-hover {
    background-color: $neutral-100;
  }

  &.input-focus-first-click:not(.input-active) {
    background-color: $neutral-25;
    box-shadow: 0 0 0 3px $theme-a1, 0 0 0 6px rgba($theme-a1, .2);
  }

  &.input-active {
    background-color: $neutral-200;
    // box-shadow: none;
  }

  &.input-focus {
    background-color: $neutral-25;
    box-shadow: 0 0 0 3px $theme-a1, 0 0 0 6px rgba($theme-a1, .2);
  }

  &.input-readonly {
    background-color: $neutral-50;
  }

  &.input-disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  &.input-disabled input,
  &.input-disabled .input-group {
    pointer-events: none;
  }
}

.label-disabled,
.form-text-disabled {
  opacity: .3;
  cursor: not-allowed;
}

.xrx-input .form-control {
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  display: block;
  width: 100%;
  // padding: $input-padding-y $input-padding-x;
  padding: $input-padding-y 11px $input-padding-y 11px;
  // font-size: $font-size-base;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15px;
  // line-height: $input-line-height;
  line-height: 1.2;
  // color: $input-color;
  color: $black;
  // background-color: $input-bg;
  background-color: transparent !important;
  background-clip: padding-box;
  // border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  }

  @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  // @include box-shadow($input-box-shadow);
  // @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

// select.form-control {
//   &:not([size]):not([multiple]) {
//     height: $input-height;
//   }

//   &:focus::-ms-value {
//     // Suppress the nested default white text on blue background highlight given to
//     // the selected option text when the (still closed) <select> receives focus
//     // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
//     // match the appearance of the native widget.
//     // See https://github.com/twbs/bootstrap/issues/19398.
//     color: $input-color;
//     background-color: $input-bg;
//   }
// }

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

// .form-control-plaintext {
//   display: block;
//   width: 100%;
//   padding-top: $input-padding-y;
//   padding-bottom: $input-padding-y;
//   margin-bottom: 0; // match inputs if this class comes on inputs with default margins
//   line-height: $input-line-height;
//   color: $input-plaintext-color;
//   background-color: transparent;
//   border: solid transparent;
//   border-width: $input-border-width 0;

//   &.form-control-sm,
//   &.form-control-lg {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

// .form-control-sm {
//   padding: $input-padding-y-sm $input-padding-x-sm;
//   font-size: $font-size-sm;
//   line-height: $input-line-height-sm;
//   @include border-radius($input-border-radius-sm);
// }

// select.form-control-sm {
//   &:not([size]):not([multiple]) {
//     height: $input-height-sm;
//   }
// }

// .form-control-lg {
//   padding: $input-padding-y-lg $input-padding-x-lg;
//   font-size: $font-size-lg;
//   line-height: $input-line-height-lg;
//   @include border-radius($input-border-radius-lg);
// }

// select.form-control-lg {
//   &:not([size]):not([multiple]) {
//     height: $input-height-lg;
//   }
// }


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  margin-bottom: $form-group-margin-bottom;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  >.col,
  >[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

// .form-check {
//   position: relative;
//   display: block;
//   padding-left: $form-check-input-gutter;
// }

// .form-check-input {
//   position: absolute;
//   margin-top: $form-check-input-margin-y;
//   margin-left: -$form-check-input-gutter;

//   &:disabled ~ .form-check-label {
//     color: $text-muted;
//   }
// }

// .form-check-label {
//   margin-bottom: 0; // Override default `<label>` bottom margin
// }

// .form-check-inline {
//   display: inline-flex;
//   align-items: center;
//   padding-left: 0; // Override base .form-check
//   margin-right: $form-check-inline-margin-x;

//   // Undo .form-check-input defaults and add some `margin-right`.
//   .form-check-input {
//     position: static;
//     margin-top: 0;
//     margin-right: $form-check-inline-input-margin-x;
//     margin-left: 0;
//   }
// }


// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

// @include form-validation-state("valid", $form-feedback-valid-color);
// @include form-validation-state("invalid", $form-feedback-invalid-color);

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }

    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }

    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

// validation

.form-text .xrx-stacked-icon {
  margin-right: 6px;
}

// success
.xrx-input.input-validation-success {
  // box-shadow: 0 0 0 2px $success-500;
  border-color: $success-500;

  + .form-text {
    .message {
      color: $success-600;
    }
  }
}

.form-text .success-message {
  color: $success-600 !important;
}

.xrx-input.input-focus-first-click.input-validation-success:not(.input-active) {
  box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
}

.xrx-input.input-focus.input-validation-success {
  box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
}

// danger
.xrx-input.input-validation-danger {
  // box-shadow: 0 0 0 2px $danger-500;
  border-color: $danger-500;

  + .form-text {
    .message {
      color: $danger-700;
    }
  }
}

.form-text .danger-message {
  color: $danger-700 !important;
}

.xrx-input.input-focus-first-click.input-validation-danger:not(.input-active) {
  box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
}

.xrx-input.input-focus.input-validation-danger {
  box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
}

// warning
.xrx-input.input-validation-warning {
  // box-shadow: 0 0 0 2px $theme-200;
  border-color: $warning-500;

  + .form-text {
    .message {
      color: $warning-800;
    }
  }
}

.form-text .warning-message {
  color: $warning-800 !important;
}

.xrx-input.input-focus-first-click.input-validation-warning:not(.input-active) {
  box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
}

.xrx-input.input-focus.input-validation-warning {
  box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
}

.form-helper-text {
  margin-bottom: 0 !important;
}


// theme appearance
.xrx-theme-appearance {

  label,
  .form-control {
    color: $white;
  }

  .form-text {
    color: $theme-200;
  }

  .form-control {
    &::placeholder {
      color: $theme-300;
    }
  }

  .xrx-input {
    background-color: $theme-500;
    border-color: $theme-700;

    &.input-hover {
      background-color: $theme-700;
    }

    &.input-focus-first-click:not(.input-active) {
      background-color: $theme-500;
    }

    &.input-active {
      background-color: $theme-800;
    }

    &.input-focus {
      background-color: $theme-500;
    }

    &.input-readonly {
      background-color: $theme-600;
    }

    // success
    &.input-validation-success {
      border-color: $success-200;

      + .form-text {
        .message {
          color: $theme-200;
        }
      }
    }

    .form-text .success-message {
      color: $theme-200 !important;
    }

    // danger
    &.input-validation-danger {
      border-color: $danger-200;

      + .form-text {
        .message {
          color: $theme-200;
        }
      }
    }

    .form-text .danger-message {
      color: $theme-200 !important;
    }

    // warning
    &.input-validation-warning {
      border-color: $warning-200;

      + .form-text {
        .message {
          color: $theme-200;
        }
      }
    }

    .form-text .warning-message {
      color: $theme-200 !important;
    }

  }

}

// dark appearance
.xrx-dark-appearance {

  label,
  .form-control {
    color: $white;
  }

  .form-text {
    color: $neutral-200;
  }

  .form-control {
    &::placeholder {
      color: $neutral-400;
    }
  }

  .xrx-input {
    background-color: $neutral-700;
    border-color: $neutral-900;

    &.input-hover {
      background-color: $neutral-900;
    }

    &.input-focus-first-click:not(.input-active) {
      background-color: $neutral-700;
    }

    &.input-active {
      background-color: $neutral-1000;
    }

    &.input-focus {
      background-color: $neutral-700;
    }

    &.input-readonly {
      background-color: $neutral-800;
    }

    // success
    &.input-validation-success {
      border-color: $success-200;

      + .form-text {
        .message {
          color: $success-200;
        }
      }
    }

    .form-text .success-message {
      color: $success-200 !important;
    }

    // danger
    &.input-validation-danger {
      border-color: $danger-200;

      + .form-text {
        .message {
          color: $danger-200;
        }
      }
    }

    .form-text .danger-message {
      color: $danger-200 !important;
    }

    // warning
    &.input-validation-warning {
      border-color: $warning-200;

      + .form-text {
        .message {
          color: $warning-200;
        }
      }
    }

    .form-text .warning-message {
      color: $warning-200 !important;
    }

  }

}

// dark appearance
.xrx-dark-appearance {
  .xrx-theme-appearance {

    label,
    .form-control {
      color: $white;
    }

    .form-text {
      color: $theme-200;
    }

    .form-control {
      &::placeholder {
        color: $theme-200;
      }
    }

    .xrx-input {
      background-color: $theme-600;
      border-color: $theme-800;

      &.input-hover {
        background-color: $theme-800;
      }

      &.input-focus-first-click:not(.input-active) {
        background-color: $theme-600;
      }

      &.input-active {
        background-color: $theme-900;
      }

      &.input-focus {
        background-color: $theme-600;
      }

      &.input-readonly {
        background-color: $theme-700;
      }

      // success
      &.input-validation-success {
        border-color: $success-200;

        + .form-text {
          .message {
            color: $theme-200;
          }
        }
      }

      .form-text .success-message {
        color: $theme-200 !important;
      }

      // danger
      &.input-validation-danger {
        border-color: $danger-200;

        + .form-text {
          .message {
            color: $theme-200;
          }
        }
      }

      .form-text .danger-message {
        color: $theme-200 !important;
      }

      // warning
      &.input-validation-warning {
        border-color: $warning-200;

        + .form-text {
          .message {
            color: $theme-200;
          }
        }
      }

      .form-text .warning-message {
        color: $theme-200 !important;
      }

    }

  }
}

// stylelint-enable
