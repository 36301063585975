// stylelint-disable

.alpha-index {
  position: relative;

  .scroll-container {
    height: 100vh;
    overflow: scroll;
  }

  .xrx-list-group:first-child,
  .xrx-list-group:first-child .list-group-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xrx-list-group:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

}

#alpha-index {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 16px;
  cursor: default;
  // .touch-action(none); // for ms tablet
  line-height: 14px;

  ul {
    padding: 10px 0;
    margin: 0;
    list-style: none;
    border-radius: 15px;
    transition: background-color .1s linear, color .1s linear;
  }

  [id^="index-"] {
    padding: 0px 8px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    user-select: none;
  }
}

#centered-index-notifier {
  position: absolute;
  top: 0;
  height: 100px;
  width: 100px;
  // background: rgba(32, 32, 32, .60);
  background-color: $neutral-700;
  border-radius: 5px;
  text-align: center;
  font-size: 60px;
  color: $white;
  font-weight: bold;
  line-height: 100px;
  visibility: hidden;
  user-select: none;
  z-index: 2;
}

.show-notifier {
  visibility: visible !important;
}

// index scroller background
.active-index {
  // background-color: rgba(32, 32, 32, .60);
  background-color: $neutral-700;
  color: $white;
}

// index scroller background
@media (hover) {
  #alpha-index > ul:hover {
    // background-color: rgba(32, 32, 32, .60);
    background-color: $neutral-700;
    color: $white;
  }
}

.hide-index-letter {
  display: none;
}

.dot-size {
  font-size: 20px !important;
}

@media (min-height: 481px) {
  #alpha-index {
    line-height: 15px;
  }
}

// #alpha-index-position-container {
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     pointer-events: none;
//     .inner {
//       position: relative;
//     }
// }


// stylelint-enable
