// stylelint-disable

// Base class
.xrx-tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;
  transition: opacity .165s, margin .165s ease-in-out;

  &.show {
    opacity: $tooltip-opacity;
  }

  .tooltip-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  // fix flickering bug on hover
  pointer-events: none;

}

.extend-xrx-tooltip-top,
[class*="xrx-tooltip-top"] {
  margin: -5px 0 !important;

  &.show {
    margin: 3px 0 !important;
  }

  .tooltip-arrow {
    bottom: -6px;

    &::before {
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: rgba($neutral-700, .94);
    }
  }
}

.extend-xrx-tooltip-right,
[class*="xrx-tooltip-right"] {
  margin: 0 -5px !important;

  &.show {
    margin: 0 3px !important;
  }

  .tooltip-arrow {
    left: -6px;

    &::before {
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: rgba($neutral-700, .94);
    }
  }
}

.extend-xrx-tooltip-bottom,
[class*="xrx-tooltip-bottom"] {
  margin: -5px 0 !important;

  &.show {
    margin: 3px 0 !important;
  }

  .tooltip-arrow {
    top: -6px;

    &::before {
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: rgba($neutral-700, .94);
    }
  }
}

.extend-xrx-tooltip-left,
[class*="xrx-tooltip-left"] {
  margin: 0 -5px !important;

  &.show {
    margin: 0 3px !important;
  }

  .tooltip-arrow {
    right: -13px;

    &::before {
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: rgba($neutral-700, .94);
    }
  }
}


.xrx-tooltip-auto {
  &[data-popper-placement^="top"] {
    @extend .extend-xrx-tooltip-top;
  }
  &[data-popper-placement^="right"] {
    @extend .extend-xrx-tooltip-right;
  }
  &[data-popper-placement^="bottom"] {
    @extend .extend-xrx-tooltip-bottom;
  }
  &[data-popper-placement^="left"] {
    @extend .extend-xrx-tooltip-left;
  }
}

.tooltip-header {
  display: flex;
  align-items: center;
  padding: 1px $tooltip-padding-x 0 $tooltip-padding-x;
  height: 32px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .15px;
  background-color: $neutral-600;
  &:empty {
    display: none;
  }
}

.tooltip-content {
  padding: $tooltip-padding-y $tooltip-padding-x;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.tooltip-inner {
  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;
  color: $tooltip-color;
  background-color: rgba($neutral-700, .94);
  @include border-radius($tooltip-border-radius);
  box-shadow: $shadow-light-04;
  overflow: hidden;
}

.tooltip-dark-mode-appearance {
  .tooltip-header {
    background-color: rgba($neutral-100, .94);
    color: $black;
  }
  .tooltip-inner {
    background-color: rgba($neutral-25, .94);
    color: $black;
  }
  &[class*="xrx-tooltip-top"] {
    .tooltip-arrow::before {
      border-top-color: rgba($neutral-25, .94);
    }
  }
  &[class*="xrx-tooltip-right"] {
    .tooltip-arrow::before {
      border-right-color: rgba($neutral-25, .94);
    }
  }
  &[class*="xrx-tooltip-bottom"] {
    .tooltip-arrow::before {
      border-bottom-color: rgba($neutral-25, .94);
    }
  }
  &[class*="xrx-tooltip-left"] {
    .tooltip-arrow::before {
      border-left-color: rgba($neutral-25, .94);
    }
  }
}


// stylelint-enable
