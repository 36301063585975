// stylelint-disable

.xrx-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
  word-wrap: break-word;
  color: $black;
  background-color: $neutral-25;
  background-clip: border-box;
  // border: $card-border-width solid $card-border-color
  border: none;
  border-radius: $card-border-radius;
  box-shadow: $shadow-light-01;

  transition: box-shadow .165s;

  &.card-action::before { // setup overlay for :active state
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: $black;
    opacity: 0;
    border-radius: 8px;
    transition: opacity .165s;
  }

  .using-mouse &.card-action:focus:not(.active):not(:hover),
  .using-mouse &.card-action:focus:not(:active):not(:hover) {
    box-shadow: $shadow-light-01;
  }

  @media (hover) {
    .using-mouse &.card-action:hover {
      box-shadow: $shadow-light-03;
      cursor: pointer;
    }
  }

  &.card-action:focus {
    outline: none;
    box-shadow: 0 0 0 3px $theme-a1, 0 0 0 6px rgba($theme-a1, .2);
  }

  .using-mouse &.card-action.active:not(.disabled),
  .using-mouse &.card-action:active:not(.disabled) {
    box-shadow: $shadow-light-inset;
    &::before {
      opacity: .2;
    }
  }

  &.card-action {
    text-decoration: none;
  }

  &.disabled {
    box-shadow: none !important;
    background-color: $neutral-200;
    opacity: .3;
    cursor: not-allowed !important;
  }

  &.card-dragging {
    box-shadow: $shadow-light-05;
  }

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-top: rem(ptr(10));
  }
}

.card-1line-title {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 16px;
  color: $black;
  h5 {
    margin: 0;
  }
  .card-subtitle {
    font-size: 16px;
    letter-spacing: .25px;
  }
  .right-object {
    margin-left: auto;
  }
}

.card-1line-title-subtitle {
  display: flex;
  align-items: center;
  min-height: 76px;
  padding: 16px;
  color: $black;
  h6 {
    position: relative;
    top: -10px;
    margin: 0;
    line-height: 0;
  }
  .card-subtitle {
    position: relative;
    top: 19px;
    font-size: 16px;
    line-height: 0;
    letter-spacing: .25px;
    color: $neutral-600;
  }
  .right-object {
    margin-left: auto;
  }
}

.card-2line-title {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 16px;
  color: $black;
  .card-title {
    // padding-right: 20px;
    font-size: 16px;
    font-weight: $font-weight-bold;
    letter-spacing: .15px;
    line-height: 1.4;
  }
  .right-object {
    margin-left: auto;
  }
}

.card-1line-title,
.card-1line-title-subtitle,
.card-2line-title {
  .right-object {
    [class*="xgl-"] {
      display: block;
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: 16px;
  color: $black;
}

.card-buttons {
  display: flex;
  align-items: center;
  padding: 16px;
  .xrx-btn + .xrx-btn {
    margin-left: 16px;
  }
}

.card-borderless-buttons {
  display: flex;
  align-items: center;
  padding: 8px;
  .xrx-btn + .xrx-btn {
    margin-left: 0;
  }
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

// .card-text {
//   color: $neutral-600;
// }

// .card-text:last-child {
//   margin-bottom: 0;
// }

// .card-link {
//   @include hover {
//     text-decoration: none;
//   }

//   + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

//
// Optional textual caps
//

.card-header {
  // padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  // color: $black;
  // background-color: $card-cap-bg;
  // border-bottom: $card-border-width solid $card-border-color;
  // border: none;

  // &:first-child {
  //   @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  // }

}

.card-footer {
  // padding: $card-spacer-y $card-spacer-x;
  // color: $black;
  // background-color: $card-cap-bg;
  // border-top: $card-border-width solid $card-border-color;
  // border: none;

  // &:last-child {
  //   @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  // }
}


//
// Header navs
//

// .card-header-tabs {
//   margin-right: -($card-spacer-x / 2);
//   margin-bottom: -$card-spacer-y;
//   margin-left: -($card-spacer-x / 2);
//   border-bottom: 0;
// }

// .card-header-pills {
//   margin-right: -($card-spacer-x / 2);
//   margin-left: -($card-spacer-x / 2);
// }

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  // @include border-radius($card-inner-border-radius);
}

// Card image caps
// .card-img-top {
//   width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
//   // @include border-top-radius($card-inner-border-radius);
// }

// .card-img-bottom {
//   width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
//   // @include border-bottom-radius($card-inner-border-radius);
// }


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .xrx-card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .xrx-card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;
  border-radius: $card-border-radius;
  box-shadow: $shadow-light-01;
  background-color: rgba($neutral-500, .2);

  .divider-vertical {
    position: absolute;
    right: 0;
    z-index: 999;
    width: 1px;
    height: 100%;
    background-color: rgba($neutral-500, .2);
  }

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .xrx-card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .xrx-card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;
      box-shadow: none;

      + .xrx-card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      &:first-child {
        @include border-right-radius(0);

        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        @include border-left-radius(0);

        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }

      &:only-child {
        @include border-radius($card-border-radius);

        .card-img-top,
        .card-header {
          @include border-top-radius($card-border-radius);
        }
        .card-img-bottom,
        .card-footer {
          @include border-bottom-radius($card-border-radius);
        }
      }

      &:not(:first-child):not(:last-child):not(:only-child) {
        @include border-radius(0);

        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          @include border-radius(0);
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .xrx-card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .xrx-card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}


//
// Accordion
//

// .accordion {
//   .xrx-card:not(:first-of-type):not(:last-of-type) {
//     border-bottom: 0;
//     border-radius: 0;
//   }

//   .xrx-card:not(:first-of-type) {
//     .card-header:first-child {
//       border-radius: 0;
//     }
//   }

//   .xrx-card:first-of-type {
//     border-bottom: 0;
//     border-bottom-right-radius: 0;
//     border-bottom-left-radius: 0;
//   }

//   .xrx-card:last-of-type {
//     border-top-left-radius: 0;
//     border-top-right-radius: 0;
//   }
// }


// Light mode - Theme appearance
// Dark mode - Dark appearance
.xrx-theme-appearance,
.xrx-dark-appearance {
  .xrx-card {
    background-color: $theme-500;
  }
  .card-1line-title,
  .card-1line-title-subtitle,
  .card-2line-title {
    h5, h6, .card-title {
      color: $white;
    }
    .card-subtitle {
      color: $theme-50;
    }
    .right-object {
      color: $white;
    }
  }
  .card-body {
    color: $white;
  }
}

// Dark mode override
.xrx-dark-appearance {
  .card-group {
    box-shadow: $shadow-dark-01;
  }
  .xrx-card {
    background-color: $neutral-700;
    box-shadow: $shadow-dark-01;
  }
  .card-1line-title,
  .card-1line-title-subtitle,
  .card-2line-title {
    .card-subtitle {
      color: $neutral-200;
    }
  }
}

// Dark mode w/ theme override
.xrx-dark-appearance .xrx-theme-appearance {
  .xrx-card {
    background-color: $theme-600;
  }
  .card-1line-title,
  .card-1line-title-subtitle,
  .card-2line-title {
    .card-subtitle {
      color: $theme-50;
    }
  }
}

// dragging and disabled styles for theme, dark, and dark-theme
.xrx-theme-appearance .xrx-card {
  &.disabled {
    background-color: $theme-400;
  }
  &.card-dragging {
    box-shadow: $shadow-light-05;
  }
}

.xrx-dark-appearance .xrx-card {
  &.disabled {
    background-color: $neutral-600;
  }
  &.card-dragging {
    box-shadow: $shadow-dark-05;
  }
}

.xrx-dark-appearance .xrx-theme-appearance .xrx-card {
  &.disabled {
    background-color: $theme-500;
  }
}

// actionable styles for dark, dark-theme
.xrx-dark-appearance .xrx-card {

  .using-mouse &.card-action:focus:not(.active):not(:hover),
  .using-mouse &.card-action:focus:not(:active):not(:hover) {
    box-shadow: $shadow-dark-01;
  }

  @media (hover) {
    .using-mouse &.card-action:hover {
      box-shadow: $shadow-dark-03;
    }
  }

  .using-mouse &.card-action.active:not(.disabled),
  .using-mouse &.card-action:active:not(.disabled) {
    box-shadow: $shadow-dark-inset;
  }

}

// stylelint-enable
