// stylelint-disable selector-no-qualifying-type


// variables
$neutral-200-alpha-30: rgba($neutral-200, .3) !default;
$theme-200-alpha-30: rgba($theme-200, .3) !default;
$theme-400-alpha-30: rgba($theme-400, .3) !default;
$theme-500-alpha-30: rgba($theme-500, .3) !default;
$neutral-600-alpha-30: rgba($neutral-600, .3) !default;

$button-box-shadow-inset-success-100: inset 0 1px 0 0 $success-100 !default;
$button-box-shadow-inset-success-400: inset 0 1px 0 0 $success-400 !default;
$button-box-shadow-focus-success: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2) !default;
$button-box-shadow-inset-warning-100: inset 0 1px 0 0 $warning-100 !default;
$button-box-shadow-inset-warning-400: inset 0 1px 0 0 $warning-400 !default;
$button-box-shadow-focus-warning: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2) !default;
$button-box-shadow-inset-info-100: inset 0 1px 0 0 $info-100 !default;
$button-box-shadow-inset-info-400: inset 0 1px 0 0 $info-400 !default;
$button-box-shadow-focus-info: 0 0 0 3px $info-a1, 0 0 0 6px rgba($info-a1, .2) !default;
$button-box-shadow-inset-danger-100: inset 0 1px 0 0 $danger-100 !default;
$button-box-shadow-inset-danger-400: inset 0 1px 0 0 $danger-400 !default;
$button-box-shadow-focus-danger: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2) !default;

$button-box-shadow-inset-white: inset 0 1px 0 0 $white !default;
$button-box-shadow-inset-neutral-600: inset 0 1px 0 0 $neutral-600 !default;
$button-box-shadow-inset-theme-400: inset 0 1px 0 0 $theme-400 !default;
$button-box-shadow-inset-theme-500: inset 0 1px 0 0 $theme-500 !default;
$button-box-shadow-inset-neutral-400: inset 0 1px 0 0 $neutral-400 !default;
$button-box-shadow-inset-neutral-100: inset 0 1px 0 0 $neutral-100 !default;
$button-box-shadow-inset-theme-100: inset 0 1px 0 0 $theme-100 !default;

$button-box-shadow-focus: 0 0 0 3px $theme-a1, 0 0 0 6px rgba($theme-a1, .2) !default;

$button-box-shadow-light: $button-box-shadow-inset-white, $shadow-light-01 !default;
$button-box-shadow-light-hover: $button-box-shadow-inset-white, $shadow-light-03 !default;
$button-box-shadow-light-active: $shadow-light-inset !default;
$button-box-shadow-light-focus: $button-box-shadow-inset-white, $button-box-shadow-focus !default;
$button-background-color-light-disabled: $neutral-200-alpha-30 !default;


//
// Base styles
//
.xrx-btn {
  // display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  // min-width: $btn-min-width;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  // border: $btn-border-width solid transparent;
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $neutral-25, null, $button-box-shadow-light, // static
    $black, $neutral-25, null, $button-box-shadow-light-hover, // hover
    $black, $neutral-200, null, $button-box-shadow-light-active, // active
    $black, $neutral-25, null, $button-box-shadow-light-focus, // focus
    $black-alpha-30, $button-background-color-light-disabled, null // disabled
  );

  @include xrx-button-size($btn-height, $btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  &.btn-inline {
    height: 40px;
    padding: 0 12px;
  }

  &.btn-inline.btn-glyph {
    padding: 0 10px;
  }

  // @include transition($btn-transition); // Share hover and focus styles
  // @include hover-focus {
  //   text-decoration: none;
  // }
  // Opinionated: add "hand" cursor to non-disabled .btn elements

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

// btn on <a> tag
a.xrx-btn {
  line-height: $btn-height;
}

// Future-proof disabling of clicks on `<a>` elements
a.xrx-btn.disabled,
fieldset:disabled a.xrx-btn {
  pointer-events: none;
}

//DONE ** LIGHT - PRIMARY

$button-primary-box-shadow: $button-box-shadow-inset-theme-400, $shadow-light-01 !default;
$button-primary-box-shadow-hover: $button-box-shadow-inset-theme-400, $shadow-light-03 !default;
$button-primary-box-shadow-active: $shadow-theme-inset !default;
$button-primary-box-shadow-focus: $button-box-shadow-inset-theme-400, $button-box-shadow-focus !default;
$button-primary-background-color-disabled: $theme-400-alpha-30 !default;

.xrx-btn.btn-primary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $theme-500, null, $button-primary-box-shadow, // static
    $white, $theme-500, null, $button-primary-box-shadow-hover, // hover
    $white, $theme-700, null, $button-primary-box-shadow-active, // active
    $white, $theme-500, null, $button-primary-box-shadow-focus, // focus
    $white-alpha-30, $button-primary-background-color-disabled, null // disabled
  );
}

//DONE ** LIGHT - SECONDARY

$button-secondary-box-shadow: $button-box-shadow-inset-neutral-100, $shadow-light-01 !default;
$button-secondary-box-shadow-hover: $button-box-shadow-inset-neutral-100, $shadow-light-03 !default;
$button-secondary-box-shadow-active: $shadow-light-inset !default;
$button-secondary-box-shadow-focus: $button-box-shadow-inset-neutral-100, $button-box-shadow-focus !default;
$button-secondary-background-color-disabled: $neutral-200-alpha-30 !default;

.xrx-btn.btn-secondary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $neutral-200, null, $button-secondary-box-shadow, // static
    $black, $neutral-200, null, $button-secondary-box-shadow-hover, // hover
    $black, $neutral-400, null, $button-secondary-box-shadow-active, // active
    $black, $neutral-200, null, $button-secondary-box-shadow-focus, // focus
    $black-alpha-30, $button-secondary-background-color-disabled, null // disabled
  );
}

//CHECKED ** LIGHT/THEME BACKGROUND - DEFAULT

$button-box-shadow-theme: $button-box-shadow-inset-theme-400, $shadow-theme-01 !default;
$button-box-shadow-theme-hover: $button-box-shadow-inset-theme-400, $shadow-theme-03 !default;
$button-box-shadow-theme-active: $shadow-theme-inset !default;
$button-box-shadow-theme-focus: $button-box-shadow-inset-theme-400, $button-box-shadow-focus !default;
$button-background-color-theme-disabled: $theme-400-alpha-30 !default;

.xrx-theme-appearance .xrx-btn {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $theme-500, null, $button-box-shadow-theme, // static
    $white, $theme-500, null, $button-box-shadow-theme-hover, // hover
    $white, $theme-700, null, $button-box-shadow-theme-active, // active
    $white, $theme-500, null, $button-box-shadow-theme-focus, // focus
    $white-alpha-30, $button-background-color-theme-disabled, null // disabled
  );
}

//DONE ** LIGHT/THEME BACKGROUND - PRIMARY

$button-primary-box-shadow-theme: $button-box-shadow-inset-theme-100, $shadow-theme-01 !default;
$button-primary-box-shadow-theme-hover: $button-box-shadow-inset-theme-100, $shadow-theme-03 !default;
$button-primary-box-shadow-theme-active: $shadow-dark-inset !default;
$button-primary-box-shadow-theme-focus: $button-box-shadow-inset-theme-100, $button-box-shadow-focus !default;
$button-primary-background-color-theme-disabled: $theme-400-alpha-30 !default;

.xrx-theme-appearance .xrx-btn.btn-primary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $theme-200, null, $button-primary-box-shadow-theme, // static
    $black, $theme-200, null, $button-primary-box-shadow-theme-hover, // hover
    $black, $theme-300, null, $button-primary-box-shadow-theme-active, // active
    $black, $theme-200, null, $button-primary-box-shadow-theme-focus, // focus
    $black-alpha-30, $button-primary-background-color-theme-disabled, null // disabled
  );
}

//DONE ** LIGHT/THEME BACKGROUND - PRIMARY EMPHASIS

$button-primary-emphasis-box-shadow-theme: $button-box-shadow-inset-theme-100, $shadow-theme-03 !default;
$button-primary-emphasis-box-shadow-theme-hover: $button-box-shadow-inset-theme-100, $shadow-theme-05 !default;
$button-primary-emphasis-box-shadow-theme-active: $shadow-dark-inset !default;
$button-primary-emphasis-box-shadow-theme-focus: $button-box-shadow-inset-theme-100, $button-box-shadow-focus !default;
$button-primary-emphasis-background-color-theme-disabled: $theme-400-alpha-30 !default;

.xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-theme, // static
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-theme-hover, // hover
    $black, $theme-300, null, $button-primary-emphasis-box-shadow-theme-active, // active
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-theme-focus, // focus
    $black-alpha-30, $button-primary-emphasis-background-color-theme-disabled, null // disabled
  );
}

//DONE ** LIGHT - SUCCESS

$button-success-box-shadow: $button-box-shadow-inset-success-400, $shadow-light-01 !default;
$button-success-box-shadow-hover: $button-box-shadow-inset-success-400, $shadow-light-03 !default;
$button-success-box-shadow-active: $shadow-theme-inset !default;
$button-success-box-shadow-focus: $button-box-shadow-inset-success-400, $button-box-shadow-focus-success !default;
$button-success-background-color-disabled: rgba($success-500, .3) !default;

.xrx-btn.btn-success {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $success-500, null, $button-success-box-shadow, // static
    $white, $success-500, null, $button-success-box-shadow-hover, // hover
    $white, $success-700, null, $button-success-box-shadow-active, // active
    $white, $success-500, null, $button-success-box-shadow-focus, // focus
    $white-alpha-30, $button-success-background-color-disabled, null // disabled
  );
}

//DONE ** LIGHT - WARNING

$button-warning-box-shadow: $button-box-shadow-inset-warning-400, $shadow-light-01 !default;
$button-warning-box-shadow-hover: $button-box-shadow-inset-warning-400, $shadow-light-03 !default;
$button-warning-box-shadow-active: $shadow-theme-inset !default;
$button-warning-box-shadow-focus: $button-box-shadow-inset-warning-400, $button-box-shadow-focus-warning !default;
$button-warning-background-color-disabled: rgba($warning-500, .3) !default;

.xrx-btn.btn-warning {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $warning-500, null, $button-warning-box-shadow, // static
    $black, $warning-500, null, $button-warning-box-shadow-hover, // hover
    $black, $warning-700, null, $button-warning-box-shadow-active, // active
    $black, $warning-500, null, $button-warning-box-shadow-focus, // focus
    $black-alpha-30, $button-warning-background-color-disabled, null // disabled
  );
}

//DONE ** LIGHT - INFO

$button-info-box-shadow: $button-box-shadow-inset-info-400, $shadow-light-01 !default;
$button-info-box-shadow-hover: $button-box-shadow-inset-info-400, $shadow-light-03 !default;
$button-info-box-shadow-active: $shadow-theme-inset !default;
$button-info-box-shadow-focus: $button-box-shadow-inset-info-400, $button-box-shadow-focus-info !default;
$button-info-background-color-disabled: rgba($info-500, .3) !default;

.xrx-btn.btn-info {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $info-500, null, $button-info-box-shadow, // static
    $white, $info-500, null, $button-info-box-shadow-hover, // hover
    $white, $info-700, null, $button-info-box-shadow-active, // active
    $white, $info-500, null, $button-info-box-shadow-focus, // focus
    $white-alpha-30, $button-info-background-color-disabled, null // disabled
  );
}

//DONE ** LIGHT - DANGER

$button-danger-box-shadow: $button-box-shadow-inset-danger-400, $shadow-light-01 !default;
$button-danger-box-shadow-hover: $button-box-shadow-inset-danger-400, $shadow-light-03 !default;
$button-danger-box-shadow-active: $shadow-theme-inset !default;
$button-danger-box-shadow-focus: $button-box-shadow-inset-danger-400, $button-box-shadow-focus-danger !default;
$button-danger-background-color-disabled: rgba($danger-500, .3) !default;

.xrx-btn.btn-danger {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $danger-500, null, $button-danger-box-shadow, // static
    $white, $danger-500, null, $button-danger-box-shadow-hover, // hover
    $white, $danger-700, null, $button-danger-box-shadow-active, // active
    $white, $danger-500, null, $button-danger-box-shadow-focus, // focus
    $white-alpha-30, $button-danger-background-color-disabled, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - DEFAULT

$button-borderless-background-color-hover: rgba($black, .1) !default;
$button-borderless-background-color-active: rgba($black, .2) !default;
$button-borderless-box-shadow-focus: $button-box-shadow-focus !default;

.xrx-btn.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, transparent, null, none, // static
    $black, $button-borderless-background-color-hover, null, none, // hover
    $black, $button-borderless-background-color-active, null, none, // active
    $black, transparent, null, $button-borderless-box-shadow-focus, // focus
    $black-alpha-30, transparent, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - PRIMARY

$button-primary-borderless-background-color-hover: rgba($theme-500, .1) !default;
$button-primary-borderless-background-color-active: rgba($theme-500, .2) !default;
$button-primary-borderless-box-shadow-focus: $button-box-shadow-focus !default;

.xrx-btn.btn-primary.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $theme-500, transparent, null, none, // static
    $theme-500, $button-primary-borderless-background-color-hover, null, none, // hover
    $theme-500, $button-primary-borderless-background-color-active, null, none, // active
    $theme-500, transparent, null, $button-primary-borderless-box-shadow-focus, // focus
    $theme-500-alpha-30, transparent, null // disabled
  );
}

//DONE ** LIGHT/THEME BACKGROUND BORDERLESS

$button-borderless-background-color-theme-hover: rgba($white, .1) !default;
$button-borderless-background-color-theme-active: rgba($white, .2) !default;
$button-borderless-box-shadow-theme-focus: $button-box-shadow-focus !default;

.xrx-theme-appearance .xrx-btn.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, transparent, null, none, // static
    $white, $button-borderless-background-color-theme-hover, null, none, // hover
    $white, $button-borderless-background-color-theme-active, null, none, // active
    $white, transparent, null, $button-borderless-box-shadow-theme-focus, // focus
    $white-alpha-30, transparent, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - SUCCESS

$button-borderless-success-background-color-hover: rgba($success-500, .1) !default;
$button-borderless-success-background-color-active: rgba($success-500, .2) !default;
$button-borderless-success-box-shadow-focus: $button-box-shadow-focus-success !default;
$button-borderless-success-color-disabled: rgba($success-600, .3) !default;

.xrx-btn.btn-success.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $success-600, transparent, null, none, // static
    $success-600, $button-borderless-success-background-color-hover, null, none, // hover
    $success-600, $button-borderless-success-background-color-active, null, none, // active
    $success-600, transparent, null, $button-borderless-success-box-shadow-focus, // focus
    $button-borderless-success-color-disabled, transparent, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - WARNING

$button-borderless-warning-background-color-hover: rgba($warning-500, .1) !default;
$button-borderless-warning-background-color-active: rgba($warning-500, .2) !default;
$button-borderless-warning-box-shadow-focus: $button-box-shadow-focus-warning !default;
$button-borderless-warning-color-disabled: rgba($warning-800, .3) !default;

.xrx-btn.btn-warning.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $warning-800, transparent, null, none, // static
    $warning-800, $button-borderless-warning-background-color-hover, null, none, // hover
    $warning-800, $button-borderless-warning-background-color-active, null, none, // active
    $warning-800, transparent, null, $button-borderless-warning-box-shadow-focus, // focus
    $button-borderless-warning-color-disabled, transparent, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - INFO

$button-borderless-info-background-color-hover: rgba($info-500, .1) !default;
$button-borderless-info-background-color-active: rgba($info-500, .2) !default;
$button-borderless-info-box-shadow-focus: $button-box-shadow-focus-info !default;
$button-borderless-info-color-disabled: rgba($info-600, .3) !default;

.xrx-btn.btn-info.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $info-600, transparent, null, none, // static
    $info-600, $button-borderless-info-background-color-hover, null, none, // hover
    $info-600, $button-borderless-info-background-color-active, null, none, // active
    $info-600, transparent, null, $button-borderless-info-box-shadow-focus, // focus
    $button-borderless-info-color-disabled, transparent, null // disabled
  );
}

//DONE ** LIGHT BORDERLESS - DANGER

$button-borderless-danger-background-color-hover: rgba($danger-500, .1) !default;
$button-borderless-danger-background-color-active: rgba($danger-500, .2) !default;
$button-borderless-danger-box-shadow-focus: $button-box-shadow-focus-danger !default;
$button-borderless-danger-color-disabled: rgba($danger-700, .3) !default;

.xrx-btn.btn-danger.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $danger-700, transparent, null, none, // static
    $danger-700, $button-borderless-danger-background-color-hover, null, none, // hover
    $danger-700, $button-borderless-danger-background-color-active, null, none, // active
    $danger-700, transparent, null, $button-borderless-danger-box-shadow-focus, // focus
    $button-borderless-danger-color-disabled, transparent, null // disabled
  );
}

//DONE ** DARK - DEFAULT

$button-box-shadow-dark: $button-box-shadow-inset-neutral-600, $shadow-dark-01 !default;
$button-box-shadow-dark-hover: $button-box-shadow-inset-neutral-600, $shadow-dark-03 !default;
$button-box-shadow-dark-active: $shadow-dark-inset !default;
$button-box-shadow-dark-focus: $button-box-shadow-inset-neutral-600, $button-box-shadow-focus !default;
$button-background-color-dark-disabled: $neutral-600-alpha-30 !default;

.xrx-dark-appearance .xrx-btn {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $neutral-700, null, $button-box-shadow-dark, // static
    $white, $neutral-700, null, $button-box-shadow-dark-hover, // hover
    $white, $neutral-900, null, $button-box-shadow-dark-active, // active
    $white, $neutral-700, null, $button-box-shadow-dark-focus, // focus
    $white-alpha-30, $button-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - PRIMARY

$button-primary-box-shadow-dark: $button-box-shadow-inset-theme-100, $shadow-dark-01 !default;
$button-primary-box-shadow-dark-hover: $button-box-shadow-inset-theme-100, $shadow-dark-03 !default;
$button-primary-box-shadow-dark-active: $button-primary-box-shadow-active !default;
$button-primary-box-shadow-dark-focus: $button-primary-box-shadow-focus !default;
$button-primary-background-color-dark-disabled: $button-primary-background-color-theme-disabled !default;

.xrx-dark-appearance .xrx-btn.btn-primary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $theme-200, null, $button-primary-box-shadow-dark, // static
    $black, $theme-200, null, $button-primary-box-shadow-dark-hover, // hover
    $black, $theme-300, null, $button-primary-box-shadow-dark-active, // active
    $black, $theme-200, null, $button-primary-box-shadow-dark-focus, // focus
    $black-alpha-30, $button-primary-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - SECONDARY

$button-secondary-box-shadow-dark: $button-box-shadow-inset-neutral-400, $shadow-dark-01 !default;
$button-secondary-box-shadow-dark-hover: $button-box-shadow-inset-neutral-400, $shadow-dark-03 !default;
$button-secondary-box-shadow-dark-active: $shadow-dark-inset !default;
$button-secondary-box-shadow-dark-focus: $button-box-shadow-inset-neutral-400, $button-box-shadow-focus !default;
$button-secondary-background-color-dark-disabled: $neutral-600-alpha-30 !default;

.xrx-dark-appearance .xrx-btn.btn-secondary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $neutral-500, null, $button-secondary-box-shadow-dark, // static
    $white, $neutral-500, null, $button-secondary-box-shadow-dark-hover, // hover
    $white, $neutral-700, null, $button-secondary-box-shadow-dark-active, // active
    $white, $neutral-500, null, $button-secondary-box-shadow-dark-focus, // focus
    $white-alpha-30, $button-secondary-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - SUCCESS

$button-success-box-shadow-dark: $button-box-shadow-inset-success-100, $shadow-dark-01 !default;
$button-success-box-shadow-dark-hover: $button-box-shadow-inset-success-100, $shadow-dark-03 !default;
$button-success-box-shadow-dark-active: $shadow-theme-inset !default;
$button-success-box-shadow-dark-focus: $button-box-shadow-inset-success-100, $button-box-shadow-focus-success !default;
$button-success-background-color-dark-disabled: rgba($success-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-success {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $success-200, null, $button-success-box-shadow-dark, // static
    $black, $success-200, null, $button-success-box-shadow-dark-hover, // hover
    $black, $success-400, null, $button-success-box-shadow-dark-active, // active
    $black, $success-200, null, $button-success-box-shadow-dark-focus, // focus
    $black-alpha-30, $button-success-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - WARNING

$button-warning-box-shadow-dark: $button-box-shadow-inset-warning-100, $shadow-dark-01 !default;
$button-warning-box-shadow-dark-hover: $button-box-shadow-inset-warning-100, $shadow-dark-03 !default;
$button-warning-box-shadow-dark-active: $shadow-theme-inset !default;
$button-warning-box-shadow-dark-focus: $button-box-shadow-inset-warning-100, $button-box-shadow-focus-warning !default;
$button-warning-background-color-dark-disabled: rgba($warning-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-warning {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $warning-200, null, $button-warning-box-shadow-dark, // static
    $black, $warning-200, null, $button-warning-box-shadow-dark-hover, // hover
    $black, $warning-400, null, $button-warning-box-shadow-dark-active, // active
    $black, $warning-200, null, $button-warning-box-shadow-dark-focus, // focus
    $black-alpha-30, $button-warning-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - INFO

$button-info-box-shadow-dark: $button-box-shadow-inset-info-100, $shadow-dark-01 !default;
$button-info-box-shadow-dark-hover: $button-box-shadow-inset-info-100, $shadow-dark-03 !default;
$button-info-box-shadow-dark-active: $shadow-theme-inset !default;
$button-info-box-shadow-dark-focus: $button-box-shadow-inset-info-100, $button-box-shadow-focus-info !default;
$button-info-background-color-dark-disabled: rgba($info-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-info {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $info-200, null, $button-info-box-shadow-dark, // static
    $black, $info-200, null, $button-info-box-shadow-dark-hover, // hover
    $black, $info-400, null, $button-info-box-shadow-dark-active, // active
    $black, $info-200, null, $button-info-box-shadow-dark-focus, // focus
    $black-alpha-30, $button-info-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK - DANGER

$button-danger-box-shadow-dark: $button-box-shadow-inset-danger-100, $shadow-dark-01 !default;
$button-danger-box-shadow-dark-hover: $button-box-shadow-inset-danger-100, $shadow-dark-03 !default;
$button-danger-box-shadow-dark-active: $shadow-theme-inset !default;
$button-danger-box-shadow-dark-focus: $button-box-shadow-inset-danger-100, $button-box-shadow-focus-danger !default;
$button-danger-background-color-dark-disabled: rgba($danger-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-danger {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $danger-200, null, $button-danger-box-shadow-dark, // static
    $black, $danger-200, null, $button-danger-box-shadow-dark-hover, // hover
    $black, $danger-400, null, $button-danger-box-shadow-dark-active, // active
    $black, $danger-200, null, $button-danger-box-shadow-dark-focus, // focus
    $black-alpha-30, $button-danger-background-color-dark-disabled, null // disabled
  );
}

//DONE ** DARK/THEME BACKGROUND - DEFAULT

$button-box-shadow-dark-theme: $button-box-shadow-inset-theme-500, $shadow-theme-01 !default;
$button-box-shadow-dark-theme-hover: $button-box-shadow-inset-theme-500, $shadow-theme-03 !default;
$button-box-shadow-dark-theme-active: $shadow-theme-inset !default;
$button-box-shadow-dark-theme-focus: $button-box-shadow-focus !default;
$button-background-color-dark-theme-disabled: rgba($theme-500, .3) !default;

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, $theme-600, null, $button-box-shadow-dark-theme, // static
    $white, $theme-600, null, $button-box-shadow-dark-theme-hover, // hover
    $white, $theme-800, null, $button-box-shadow-dark-theme-active, // active
    $white, $theme-600, null, $button-box-shadow-dark-theme-focus, // focus
    $white-alpha-30, $button-background-color-dark-theme-disabled, null // disabled
  );
}

//CHECKED ** DARK/THEME BACKGROUND - PRIMARY

$button-primary-box-shadow-dark-theme: $button-box-shadow-inset-theme-100, $shadow-theme-01 !default;
$button-primary-box-shadow-dark-theme-hover: $button-box-shadow-inset-theme-100, $shadow-theme-03 !default;
$button-primary-box-shadow-dark-theme-active: $shadow-theme-inset !default;
$button-primary-box-shadow-dark-theme-focus: $button-box-shadow-focus !default;
$button-primary-background-color-dark-theme-disabled: rgba($theme-400, .3) !default;

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $theme-200, null, $button-primary-box-shadow-dark-theme, // static
    $black, $theme-200, null, $button-primary-box-shadow-dark-theme-hover, // hover
    $black, $theme-300, null, $button-primary-box-shadow-dark-theme-active, // active
    $black, $theme-200, null, $button-primary-box-shadow-dark-theme-focus, // focus
    $black-alpha-30, $button-primary-background-color-dark-theme-disabled, null // disabled
  );
}

//CHECKED ** DARK/THEME BACKGROUND - PRIMARY EMPHASIS

$button-primary-emphasis-box-shadow-dark-theme: $button-box-shadow-inset-theme-100, $shadow-theme-03 !default;
$button-primary-emphasis-box-shadow-dark-theme-hover: $button-box-shadow-inset-theme-100, $shadow-theme-05 !default;
$button-primary-emphasis-box-shadow-dark-theme-active: $shadow-theme-inset !default;
$button-primary-emphasis-box-shadow-dark-theme-focus: $button-box-shadow-focus !default;
$button-primary-emphasis-background-color-dark-theme-disabled: rgba($theme-400, .3) !default;

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-primary.btn-emphasis {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-dark-theme, // static
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-dark-theme-hover, // hover
    $black, $theme-300, null, $button-primary-emphasis-box-shadow-dark-theme-active, // active
    $black, $theme-200, null, $button-primary-emphasis-box-shadow-dark-theme-focus, // focus
    $black-alpha-30, $button-primary-emphasis-background-color-dark-theme-disabled, null // disabled
  );
}

//DONE ** DARK BORDERLESS - DEFAULT

$button-borderless-background-color-dark-hover: rgba($white, .1) !default;
$button-borderless-background-color-dark-active: rgba($white, .2) !default;
$button-borderless-box-shadow-dark-focus: $button-box-shadow-focus !default;

.xrx-dark-appearance .xrx-btn.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, transparent, null, none, // static
    $white, $button-borderless-background-color-dark-hover, null, none, // hover
    $white, $button-borderless-background-color-dark-active, null, none, // active
    $white, transparent, null, $button-borderless-box-shadow-dark-focus, // focus
    $white-alpha-30, transparent, null // disabled
  );
}

//DONE ** DARK BORDERLESS - PRIMARY

$button-alt-borderless-background-color-dark-hover: rgba($theme-200, .1) !default;
$button-alt-borderless-background-color-dark-active: rgba($theme-200, .2) !default;
$button-alt-borderless-box-shadow-dark-focus: $button-box-shadow-focus !default;

.xrx-dark-appearance .xrx-btn.btn-primary.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $theme-200, transparent, null, none, // static
    $theme-200, $button-alt-borderless-background-color-dark-hover, null, none, // hover
    $theme-200, $button-alt-borderless-background-color-dark-active, null, none, // active
    $theme-200, transparent, null, $button-alt-borderless-box-shadow-dark-focus, // focus
    $theme-200-alpha-30, transparent, null // disabled
  );
}

//DONE ** DARK BORDERLESS - SUCCESS

$button-borderless-success-background-color-dark-hover: rgba($success-200, .1) !default;
$button-borderless-success-background-color-dark-active: rgba($success-200, .2) !default;
$button-borderless-success-box-shadow-dark-focus: $button-box-shadow-focus-success !default;
$button-borderless-success-color-dark-disabled: rgba($success-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-success.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $success-200, transparent, null, none, // static
    $success-200, $button-borderless-success-background-color-dark-hover, null, none, // hover
    $success-200, $button-borderless-success-background-color-dark-active, null, none, // active
    $success-200, transparent, null, $button-borderless-success-box-shadow-dark-focus, // focus
    $button-borderless-success-color-dark-disabled, transparent, null // disabled
  );
}

//DONE ** DARK BORDERLESS - WARNING

$button-borderless-warning-background-color-dark-hover: rgba($warning-200, .1) !default;
$button-borderless-warning-background-color-dark-active: rgba($warning-200, .2) !default;
$button-borderless-warning-box-shadow-dark-focus: $button-box-shadow-focus-warning !default;
$button-borderless-warning-color-dark-disabled: rgba($warning-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-warning.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $warning-200, transparent, null, none, // static
    $warning-200, $button-borderless-warning-background-color-dark-hover, null, none, // hover
    $warning-200, $button-borderless-warning-background-color-dark-active, null, none, // active
    $warning-200, transparent, null, $button-borderless-warning-box-shadow-dark-focus, // focus
    $button-borderless-warning-color-dark-disabled, transparent, null // disabled
  );
}

//DONE ** DARK BORDERLESS - INFO

$button-borderless-info-background-color-dark-hover: rgba($info-200, .1) !default;
$button-borderless-info-background-color-dark-active: rgba($info-200, .2) !default;
$button-borderless-info-box-shadow-dark-focus: $button-box-shadow-focus-info !default;
$button-borderless-info-color-dark-disabled: rgba($info-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-info.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $info-200, transparent, null, none, // static
    $info-200, $button-borderless-info-background-color-dark-hover, null, none, // hover
    $info-200, $button-borderless-info-background-color-dark-active, null, none, // active
    $info-200, transparent, null, $button-borderless-info-box-shadow-dark-focus, // focus
    $button-borderless-info-color-dark-disabled, transparent, null // disabled
  );
}

//DONE ** DARK BORDERLESS - DANGER

$button-borderless-danger-background-color-dark-hover: rgba($danger-200, .1) !default;
$button-borderless-danger-background-color-dark-active: rgba($danger-200, .2) !default;
$button-borderless-danger-box-shadow-dark-focus: $button-box-shadow-focus-danger !default;
$button-borderless-danger-color-dark-disabled: rgba($danger-200, .3) !default;

.xrx-dark-appearance .xrx-btn.btn-danger.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $danger-200, transparent, null, none, // static
    $danger-200, $button-borderless-danger-background-color-dark-hover, null, none, // hover
    $danger-200, $button-borderless-danger-background-color-dark-active, null, none, // active
    $danger-200, transparent, null, $button-borderless-danger-box-shadow-dark-focus, // focus
    $button-borderless-danger-color-dark-disabled, transparent, null // disabled
  );
}

//DONE ** DARK/THEME BACKGROUND BORDERLESS

$button-borderless-background-color-dark-theme-hover: rgba($white, .1) !default;
$button-borderless-background-color-dark-theme-active: rgba($white, .2) !default;
$button-borderless-box-shadow-dark-theme-focus: $button-box-shadow-focus !default;

.xrx-dark-appearance .xrx-theme-appearance .xrx-btn.btn-borderless {
  @include xrx-button-variant( // label-color, background-color, border-color, box-shadow
    $white, transparent, null, none, // static
    $white, $button-borderless-background-color-dark-theme-hover, null, none, // hover
    $white, $button-borderless-background-color-dark-theme-active, null, none, // active
    $white, transparent, null, $button-borderless-box-shadow-dark-theme-focus, // focus
    $white-alpha-30, transparent, null // disabled
  );
}


//
// Button Sizes
//
// .btn-lg {
//   @include xrx-button-size($btn-height-lg, $btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
// }

.btn-sm {
  @include xrx-button-size($btn-height-sm, $btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

  &.btn-inline {
    height: 26px;
    padding: 0 8px;
  }

  &.btn-inline.btn-glyph {
    padding: 0 7px;
  }

}

// .btn-xs {
//   @include xrx-button-size($btn-height-xs, $btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
// }

// a.btn-lg {
//   line-height: $btn-height-lg;
// }

a.btn-sm {
  line-height: $btn-height-sm;
}

// a.btn-xs {
//   line-height: $btn-height-xs;
// }

//
// Block button
//
.btn-block {
  // display: block;
  width: 100%; // Vertically space out multiple block buttons
  // + .btn-block {
  //   margin-top: $btn-block-spacing-y;
  // }
}


// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


// glyph buttons w/ labels - glyph position left

.xrx-btn [class*="xgl-"] {
  position: relative;
  // top: rem(ptr(1));
  ~ .btn-label {
    padding-left: rem(ptr(6));
  }
}
// .xrx-btn.btn-lg [class*="xgl-"] {
//   top: 0;
//   ~ .btn-label {
//     padding-left: rem(ptr(8));
//   }
// }
.xrx-btn.btn-sm [class*="xgl-"] {
  top: 0;
  ~ .btn-label {
    padding-left: rem(ptr(4));
  }
}
// .xrx-btn.btn-xs [class*="xgl-"] {
//   top: 0;
//   ~ .btn-label {
//     padding-left: rem(ptr(4));
//   }
// }


// glyph buttons w/ labels - glyph position right

.xrx-btn .btn-label ~ [class*="xgl-"] {
  margin-right: rem(ptr(-2));
  margin-left: rem(ptr(6));
}
.xrx-btn.btn-sm .btn-label ~ [class*="xgl-"] {
  margin-left: rem(ptr(4));
}


// label layout

.xrx-btn .btn-label {
  position: relative;
  // top: rem(ptr(1));
  letter-spacing: rem(ptr(.16));
}
// .xrx-btn.btn-lg .btn-label {
//   top: rem(ptr(1));
//   letter-spacing: rem(ptr(0));
// }
.xrx-btn.btn-sm .btn-label {
  top: rem(ptr(1));
  letter-spacing: rem(ptr(.2));
}
// .xrx-btn.btn-xs .btn-label {
//   top: 0;
//   letter-spacing: rem(ptr(.24));
// }


// glyph only button

.btn-glyph {
  min-width: 0;
  [class*="xgl-"] {
    margin-right: rem(ptr(-2));
    margin-left: rem(ptr(-2));
  }
  // &.btn-sm [class*="xgl-"],
  // &.btn-xs [class*="xgl-"] {
  //   margin-right: rem(ptr(-4));
  //   margin-left: rem(ptr(-4));
  // }
  &.btn-sm [class*="xgl-"] {
    margin-right: rem(ptr(-4));
    margin-left: rem(ptr(-4));
  }
}
