// stylelint-disable

// stack status icons size
.stacked-icon-size-16 {
  width: 16px;
  height: 16px;
}

.stacked-icon-size-20 {
  width: 20px;
  height: 20px;
}

.stacked-icon-size-24 {
  width: 24px;
  height: 24px;
}

.stacked-icon-size-32 {
  width: 32px;
  height: 32px;
}

.stacked-icon-size-36 {
  width: 36px;
  height: 36px;
}

.stacked-icon-size-48 {
  width: 48px;
  height: 48px;
}

.stacked-icon-size-64 {
  width: 64px;
  height: 64px;
}

// stack status icons base classes
.xrx-stacked-icon {
  position: relative;
}
.xrx-stacked-icon [class*="xgl-"] {
  position: absolute;
}

// stack status icons light
.xgl-success_stack_mdgd {
  color: $success-500;
}
.xgl-success_stack_frgd {
  color: $white;
}
.xgl-danger_stack_mdgd {
  color: $danger-500;
}
.xgl-danger_stack_frgd {
  color: $white;
}
.xgl-warning_stack_mdgd {
  color: $warning-500;
}
.xgl-warning_stack_frgd {
  color: $black;
}

// stack status icons theme/dark
.xrx-theme-appearance,
.xrx-dark-appearance {
  .xgl-success_stack_mdgd {
    color: $success-200;
  }
  .xgl-success_stack_frgd {
    color: $black;
  }
  .xgl-danger_stack_mdgd {
    color: $danger-200;
  }
  .xgl-danger_stack_frgd {
    color: $black;
  }
  .xgl-warning_stack_mdgd {
    color: $warning-200;
  }
  .xgl-warning_stack_frgd {
    color: $black;
  }
}

// stylelint-enable
