// Button variants
@mixin xrx-button-variant(
  $label, $background, $border, $box-shadow,
  $hover-label, $hover-background, $hover-border, $hover-box-shadow,
  $active-label, $active-background, $active-border, $active-box-shadow,
  $focus-label, $focus-background, $focus-border, $focus-box-shadow,
  $disabled-label, $disabled-background, $disabled-border
) {
  color: $label;
  background-color: $background;
  box-shadow: $box-shadow;
  transition: color .165s, background-color .165s, box-shadow .165s, transform .165s;

  @media (hover) {
    &:hover:not(:focus) { // hover disabled on mobile devices
      color: $hover-label;
      background-color: $hover-background;
      box-shadow: $hover-box-shadow;
    }
  }

  @media (hover) {
    .using-mouse &:hover { // hover disabled on mobile devices
      background-color: $hover-background;
      box-shadow: $hover-box-shadow;
    }
  }

  &:focus {
    outline: none;
    box-shadow: $focus-box-shadow;
  }

  .using-mouse &:focus { // override focus style on click event
    outline: none;
    box-shadow: $box-shadow;
  }

  // &:not(:disabled):not(.disabled):active,
  // &:not(:disabled):not(.disabled).active,
  // .show > &.dropdown-toggle {
  //   color: $active-label;
  //   background-color: $active-background;
  //   box-shadow: $active-box-shadow, $focus-box-shadow;
  //   transition-duration: .067s;
  //   transform: scale(.978);
  // }

  // .using-mouse &:not(:disabled):not(.disabled):active,
  // .using-mouse &:not(:disabled):not(.disabled).active,
  // .using-mouse .show > &.dropdown-toggle {
  //   box-shadow: $active-box-shadow;
  // }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $active-label;
    background-color: $active-background;
    box-shadow: $active-box-shadow, $focus-box-shadow;
    transition-duration: .067s;
    transform: scale(.978);
  }

  .using-mouse &:not(:disabled):not(.disabled):active,
  .using-mouse &:not(:disabled):not(.disabled).active {
    box-shadow: $active-box-shadow;
  }

  &.disabled,
  &:disabled {
    color: $disabled-label !important;
    background-color: $disabled-background !important;
    box-shadow: none !important;
  }
}

// Button sizes -- does not apply to .btn-glyph
@mixin xrx-button-size($height, $padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  height: $height;
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
