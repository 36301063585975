// stylelint-disable

.xrx-header {
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 16px;
  background-color: $neutral-100;
  color: $black;
  font-weight: $font-weight-bold;
  &.header-secondary {
    background-color: $white;
  }
  &.header-primary {
    background-color: $theme-100;
  }
  &.header-success {
    background-color: $success-50;
  }
  &.header-warning {
    background-color: $warning-50;
  }
  &.header-danger {
    background-color: $danger-50;
  }
  &.header-info {
    background-color: $info-50;
  }
}

// .xrx-theme-appearance .xrx-header {
//   background-color: $theme-500;
//   color: $white;
// }

// .xrx-dark-appearance .xrx-header {
//   background-color: $neutral-700;
//   color: $white;
//   // &.header-secondary {
//   //   background-color: $white;
//   // }
//   &.header-primary {
//     background-color: $theme-800;
//   }
//   &.header-success {
//     background-color: $success-1000;
//   }
//   &.header-warning {
//     background-color: $warning-1000;
//   }
//   &.header-danger {
//     background-color: $danger-1000;
//   }
//   &.header-info {
//     background-color: $info-1000;
//   }
// }

// .xrx-dark-appearance .xrx-theme-appearance .xrx-header {
//   background-color: $theme-600;
// }

// stylelint-enable
