// stylelint-disable

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: rem(ptr(72)); // default padding -- xrx-footer.js will update padding-bottom
}

.xrx-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  min-height: rem(ptr(72));
  color: $black;
  background-color: $neutral-100;

  ul {
    padding: 0;
    margin-bottom: rem(ptr(8));
    list-style: none;
    &:last-of-type {
      li:last-child::after {
        display: none;
      }
    }
  }

  li {
    display: inline-block;
    font-size: rem(ptr(16));
    &::after {
      display: inline-block;
      padding: 0 rem(ptr(8));
      content: "|";
      color:  $neutral-600;
    }
  }

  a {
    color: $black;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  .content {
    padding: rem(ptr(16));
    font-size: rem(ptr(16));
    letter-spacing: .25px;
    line-height: 1.2;
    .xerox-copyright {
      font-size: rem(ptr(11));
      letter-spacing: .35px;
      color:  $neutral-600;
    }
  }


}

.xrx-dark-appearance {
  .xrx-footer {
    background-color: $neutral-900;
    color: $white;
    a {
      color: $white;
    }

    li::after {
      color:  $neutral-200;
    }
    .content .xerox-copyright {
      color:  $neutral-200;
    }
  }

}

// stylelint-enable
