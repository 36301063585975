// stylelint-disable

// dark mode styles
.xrx-dark-appearance {
  background-color: $dark-appearance;

  // type
  body,
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
  }

}

body.xrx-dark-appearance {
  color: $white;
}

.xrx-dark-appearance {
  color: $white;
}

.xrx-dark-appearance .xrx-theme-appearance {
  background-color: $dark-theme-appearance;
}

// stylelint-enable
