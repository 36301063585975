// stylelint-disable

// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.xrx-list-group {
  display: flex;
  flex-direction: column;

  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;
  background-color: $neutral-25;
  // overflow: hidden;
  border: 1px solid $neutral-100;
  border-radius: 6px;

  &.list-group-flush {
    border: none;
    border-radius: 0;
    .list-group-item {
      border-radius: 0;
    }
  }

  &.emphasized {
    border: none;
    .list-group-item {
      &:first-child {
        @include border-top-radius(6px);
      }
      &:last-child {
        @include border-bottom-radius(6px);
      }
    }
    box-shadow: $shadow-light-01;
  }
}

.list-group-header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  font-size: 16px;
  letter-spacing: .25px;
  background-color: $neutral-100;

  .label {
    position: relative;
    top: 1px;
  }

  .xrx-dark-appearance & {
    color: $white;
    background-color: $neutral-900;
  }

}

// 9x
.list-group-item {
  display: flex;
  align-items: center;
  min-height: 56px;
  background-color: transparent;
  color: $black;

  &:first-child {
    @include border-top-radius(5px);
  }
  &:last-child {
    @include border-bottom-radius(5px);
  }

  &.compact {
    min-height: 48px;
  }

  .content-container {
    position: relative;
    display: flex;
    align-items: center;
    align-self: stretch;
    flex: 1;
    margin-left: 16px;
  }

  .left-label {
    position: relative;
    display: flex;
    align-items: center;
    align-self: stretch;
    cursor: default;

    span {
      font-size: 20px;
      letter-spacing: .15px;
    }
    &._2line64-sub-label {
      flex-direction: column;
      align-items: flex-start;
      height: 64px;
      span {
        position: relative;
        top: 4px;
      }
      .sub-label {
        position: relative;
        top: 5px;
        font-size: 16px;
      }
    }
  }

  outline: none;

  @media (hover) {
    &:hover {
      color: $black;
    }
  }

  &.disabled {
    opacity: .3;
    cursor: not-allowed !important;
    .left-label {
      cursor: not-allowed !important;
    }

    .custom-image-flush,
    .custom-image-margin,
    .custom-icon {
      // opacity: .3;
      box-shadow: none !important;
    }
  }

  &.divider:not(:last-child) {
    // margin-bottom: 1px;

    .content-container::before {
    // .left-label::before {
      position: absolute;
      // bottom: -1px;
      bottom: 0;
      align-self: stretch;
      width: 100%;
      content: "";
      border-bottom: 1px solid $neutral-100;
    }
  }

  .left-object {
    ~.content-container {
      margin-left: 0;
    }

    [class*="xgl-"] {
      display: table;
      // margin: 0 22px;
      margin: 0 16px;
    }

    .custom-icon {
      width: 36px;
      margin: 10px 16px;
      border-radius: 6px;
      box-shadow: $shadow-light-02;
    }

    .custom-image-margin {
      margin: 0 16px 0 4px;
    }

    .custom-image-flush {
      margin: 0 16px 0 0;
    }

    .xrx-checkbox {
      margin: 0 10px;
    }
  }

  .sub-label,
  .feedback-label {
    color: $neutral-600;
  }

  .right-object {
    margin-left: auto;

    .feedback-label {
      position: relative;
      top: 1px;
      margin: 0 16px;
      font-size: 16px;
      letter-spacing: .15px;
      white-space: nowrap;
    }

    .xrx-btn:not(.dropdown-toggle) {
      margin: 8px;
    }

    .xrx-switch {
      margin: 0 8px;
    }

    .xrx-checkbox {
      margin: 0 4px;
    }

    >[class*="xgl-"] {
      display: table;
      margin: 0 16px;
    }
  }
}

a.list-group-item {
  color: $black;
  text-decoration: none !important;
}

.xrx-list-group.list-group-sm {
  border-radius: 4px;

  &.list-group-flush {
    border-radius: 0;
    .list-group-item {
      border-radius: 0;
    }
  }

  .list-group-header {
    padding: 0 5px;
    height: 28px;
    font-size: 14px;
  }

  .list-group-item {
    min-height: 38px;

    &:first-child {
      @include border-top-radius(3px);
    }
    &:last-child {
      @include border-bottom-radius(3px);
    }

    &.compact {
      min-height: 32px;
    }

    .content-container {
      margin-left: 9px;
    }

    .left-label {
      span {
        position: relative;
        top: 1px;
        font-size: 16px;
        letter-spacing: .15px;
      }
      &._2line44-sub-label {
        flex-direction: column;
        align-items: flex-start;
        height: 44px;
        span {
          top: 2px;
        }
        .sub-label {
          top: -3px;
          font-size: 14px;
        }
      }
    }

    .left-object {
      ~.content-container {
        margin-left: 0;
      }

      [class*="xgl-"] {
        margin: 0 9px;
      }

      .custom-icon {
        width: 24px;
        margin: 7px 9px;
        border-radius: 5px;
      }

      .custom-image-margin {
        margin: 0 9px 0 3px;
      }

      .custom-image-flush {
        margin: 0 9px 0 0;
      }

      .xrx-checkbox {
        margin: 0 5px;
      }
    }

    .right-object {
      margin-left: auto;

      .feedback-label {
        position: relative;
        top: 1px;
        margin: 0 9px;
        font-size: 14px;
        letter-spacing: .25px;
      }

      .xrx-btn {
        margin: 6px;
      }

      .xrx-switch {
        margin: 0 4px;
      }

      .xrx-checkbox {
        margin: 0 3px;
      }

      >[class*="xgl-"] {
        margin: 0 9px;
      }
    }

  }

  &.emphasized {
    .list-group-item {
      &:first-child {
        @include border-top-radius(4px);
      }
      &:last-child {
        @include border-bottom-radius(4px);
      }
    }
  }

}


.list-group-item-action {
  transition: background-color .5s;
  @media (hover) {
    &:hover:not(.disabled) {
      background-color: $neutral-50;
      transition: background-color 0s;
    }
  }

  &:active:not(.disabled) {
    background-color: $neutral-100;
  }

  &.active:not(.disabled) { // selected
    background-color: $theme-500;
    color: $white;

    .sub-label,
    .feedback-label {
      color: $theme-100;
    }

    .content-container::before {
      border-bottom-color: transparent !important;
    }
  }
}


.list-group-item {
  &.read-only {
    background-color: $neutral-50;

    .content-container::before {
      border-bottom-color: $neutral-100 !important;
    }
  }

  // &.disabled {
  //   background-color: transparent;

  //   .sub-label,
  //   .feedback-label {
  //     color: rgba($neutral-600, .3);
  //   }

  //   [class*="xgl-"],
  //   .left-label {
  //     color: rgba($neutral-600, .3);
  //   }

  //   &.divider .left-label::before {
  //     border-color: rgba($neutral-500, .05);
  //   }

  // }
}


// theme on light

.xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group {
  background-color: $theme-500;
  border-color: $theme-700;

  &.emphasized {
    border: none;
    box-shadow: $shadow-theme-01;
  }
}

.xrx-theme-appearance:not(.dn-header):not(.mn-header) {
  .list-group-item-action {
    @media (hover) {
      &:hover:not(.disabled) {
        background-color: $theme-700;
      }
    }

    &:active:not(.disabled) {
      background-color: $theme-800;
    }

    &.active:not(.disabled) {
      background-color: $theme-200;
      color: $black;

      .sub-label,
      .feedback-label {
        color: $neutral-800;
      }
    }
  }

  .list-group-item {
    background-color: transparent;
    color: $white;

    &.divider .content-container::before {
      border-bottom-color: $theme-700;
    }

    .sub-label,
    .feedback-label {
      color: $theme-100;
    }

    &.read-only {
      background-color: $theme-600;

      .content-container::before {
        border-bottom-color: $theme-700 !important;
      }
    }

    // &.disabled {
    //   background-color: transparent;

    //   .sub-label,
    //   .feedback-label {
    //     color: rgba($theme-100, .3);
    //   }

    //   [class*="xgl-"],
    //   .left-label {
    //     color: rgba($white, .3);
    //   }

    //   &.divider .left-label::before {
    //     border-color: rgba($theme-200, .05);
    //   }
    // }
  }
}


// dark aappearance

.xrx-dark-appearance .xrx-list-group {
  background-color: $neutral-700;
  border-color: $neutral-900;

  &.emphasized {
    border: none;
    box-shadow: $shadow-dark-01;
  }
}

.xrx-dark-appearance {
  .list-group-item-action {
    @media (hover) {
      &:hover:not(.disabled) {
        background-color: $neutral-800;
      }
    }

    &:active:not(.disabled) {
      background-color: $neutral-900;
    }

    &.active:not(.disabled) { // selected
      background-color: $theme-200;
      color: $black;

      .sub-label,
      .feedback-label {
        color: $neutral-800;
      }
    }
  }

  .list-group-item {
    background-color: transparent;
    color: $white;

    &.divider .content-container::before {
      border-bottom-color: $neutral-900;
    }

    .sub-label,
    .feedback-label {
      color: $neutral-200;
    }

    &.read-only {
      background-color: $neutral-800;
      &.divider .content-container::before {
        border-bottom-color: $neutral-900 !important;
      }

      .sub-label,
      .feedback-label {
        color: $neutral-200;
      }
    }

    // &.disabled {
    //   background-color: transparent;

    //   .sub-label,
    //   .feedback-label {
    //     color: rgba($neutral-200, .3);
    //   }

    //   [class*="xgl-"],
    //   .left-label {
    //     color: rgba($white, .3);
    //   }

    //   &.divider .content-container::before {
    //     border-color: rgba($neutral-200, .3);
    //   }

    // }
  }
}


// theme on dark

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) .xrx-list-group {
  background-color: $theme-700;
  border-color: $theme-800;

  &.emphasized {
    border: none;
    box-shadow: $shadow-theme-01;
  }
}

.xrx-dark-appearance .xrx-theme-appearance:not(.dn-header):not(.mn-header) {
  .list-group-item-action {
    @media (hover) {
      &:hover:not(.disabled) {
        background-color: $theme-800;
      }
    }

    &:active:not(.disabled) {
      background-color: $theme-900;
    }

    &.active:not(.disabled) {
      background-color: $theme-200;
      color: $black;

      .sub-label,
      .feedback-label {
        color: $neutral-800;
      }
    }
  }

  .list-group-item {
    background-color: transparent;
    color: $white;

    &.divider .content-container::before {
      border-bottom-color: $theme-800;
    }

    .sub-label,
    .feedback-label {
      color: $theme-100;
    }

    &.read-only {
      background-color: $theme-700;
        &.divider .content-container::before {
        border-bottom-color:  $theme-800 !important;
      }
    }

    // &.disabled {
    //   background-color: transparent;

    //   .sub-label,
    //   .feedback-label {
    //     color: rgba($theme-100, .3);
    //   }

    //   [class*="xgl-"],
    //   .left-label {
    //     color: rgba($white, .3);
    //   }

    //   &.divider .left-label::before {
    //     border-color: rgba($theme-800, .3);
    //   }
    // }
  }
}


// status

.list-group-item-primary,
.list-group-item-success,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-danger {
  background-color: transparent;

  .left-label .sub-label {
    color: $neutral-600;
  }

  .xrx-dark-appearance & .left-label .sub-label {
    color: $neutral-200;
  }

  &.divider .content-container::before {
    border-bottom-color: $neutral-100;
  }

}

.list-group-item-primary {
  color: $theme-500 !important;

  &.list-group-item-action {
    @media (hover) {
      &:hover {
        background-color: $theme-50;
      }
    }

    &:active {
      background-color: $theme-100;
    }

  }

}

.list-group-item-success {
  color: $success-600 !important;

  &.list-group-item-action {
    @media (hover) {
      &:hover {
        background-color: $success-50;
      }
    }

    &:active {
      background-color: $success-100;
    }

  }

}

.list-group-item-warning {
  color: $warning-800 !important;

  &.list-group-item-action {
    @media (hover) {
      &:hover {
        background-color: $warning-50;
      }
    }

    &:active {
      background-color: $warning-100;
    }

  }

}

.list-group-item-info {
  color: $info-600 !important;

  &.list-group-item-action {
    @media (hover) {
      &:hover {
        background-color: $info-50;
      }
    }

    &:active {
      background-color: $info-100;
    }

  }

}

.list-group-item-danger {
  color: $danger-700 !important;

  &.list-group-item-action {
    @media (hover) {
      &:hover {
        background-color: $danger-50;
      }
    }

    &:active {
      background-color: $danger-100;
    }

  }

}


.xrx-dark-appearance {
  .list-group-item-primary {
    color: $theme-200 !important;

    &.list-group-item-action {
      @media (hover) {
        &:hover {
          background-color: $theme-700;
        }
      }

      &:active {
        background-color: $theme-1000;
      }

    }

  }

  .list-group-item-success {
    color: $success-200 !important;

    &.list-group-item-action {
      @media (hover) {
        &:hover {
          background-color: $success-900;
        }
      }

      &:active {
        background-color: $success-1000;
      }

    }

  }

  .list-group-item-warning {
    color: $warning-200 !important;

    &.list-group-item-action {
      @media (hover) {
        &:hover {
          background-color: $warning-900
        }
      }

      &:active {
        background-color: $warning-1000;
      }

    }

  }

  .list-group-item-info {
    color: $info-200 !important;

    &.list-group-item-action {
      @media (hover) {
        &:hover {
          background-color: $info-900;
        }
      }

      &:active {
        background-color: $info-1000;
      }

    }

  }

  .list-group-item-danger {
    color: $danger-200 !important;

    &.list-group-item-action {
      @media (hover) {
        &:hover {
          background-color: $danger-900;
        }
      }

      &:active {
        background-color: $danger-1000;
      }

    }

  }
}

.using-mouse {
  .list-group-item:focus {
    outline: none;
    box-shadow: none;
  }
}

// wrap 2 or more list groups
.multi-list-group {
  .xrx-list-group,
  .xrx-list-group.list-group-sm {
    border-radius: 0;
    &:not(:first-child) {
      border-top: none;
    }
    &:not(:last-child) {
      border-bottom: none;
    }
  }
  .xrx-list-group:first-child {
    @include border-top-radius(6px);
  }
  .xrx-list-group:last-child {
    @include border-bottom-radius(6px);
  }
  .xrx-list-group.list-group-sm:first-child {
    @include border-top-radius(4px);
  }
  .xrx-list-group.list-group-sm:last-child {
    @include border-bottom-radius(4px);
  }

  .xrx-list-group:first-child .list-group-header {
    @include border-top-radius(5px);
  }

  .xrx-list-group.list-group-sm:first-child .list-group-header {
    @include border-top-radius(3px);
  }

  .xrx-list-group:not(:last-child) .list-group-item {
    @include border-bottom-radius(0);
  }

}


// focus styles
body:not(.using-mouse) {
  .list-group-item:focus {
    box-shadow: inset 0 0 0 3px $theme-a1, inset 0 0 0 6px rgba($theme-a1, .2);
    .content-container::before {
      border-bottom-color: transparent !important;
    }
  }
  .list-group-item.read-only:focus {
    box-shadow: inset 0 0 0 3px $theme-a1, inset 0 0 0 6px rgba($theme-a1, .2);
    .content-container::before {
      border-bottom-color: transparent !important;
    }
  }
}


// stylelint-enable
