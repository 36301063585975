// stylelint-disable
[class*="xrx-icon-"] {
  box-sizing: content-box;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
  cursor: default;
}

.xrx-icon-disabled {
  opacity: 0.35;
  box-shadow: none;
}

.xrx-icon-sm {
  border-radius: 6px;
}

.xrx-icon-lg {
  border-radius: 12px;
}

.xrx-icon-xl {
  border-radius: 16px;
}
// stylelint-enable
