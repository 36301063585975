// stylelint-disable

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.xrx-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  max-width: 800px; // default max-width
  margin: 0 auto;
  padding: 0 16px;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .xrx-modal.fade & {
    @include transition($modal-transition);
    transform: translate(0, -20px);
  }
  .xrx-modal.show & {
    transform: translate(0, 0);
  }
  &.modal-fullscreen {
    transform: none !important;
  }

}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  background-color: $neutral-25;
  background-clip: padding-box;
  // border: $modal-content-border-width solid $modal-content-border-color;
  // @include border-radius(rem(ptr(7)));
  // @include xrx-box-shadow($modal-content-box-shadow);
  @include border-radius(rem(ptr(12)));
  overflow: hidden;
  box-shadow: $shadow-light-05;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: rgba(0, 0, 0, .35);

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  // align-items: flex-start; // so the close btn always stays on the upper right corner
  align-items: center;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  // padding: rem(ptr(15)) $modal-header-padding rem(ptr(14)) $modal-header-padding;
  padding: 0 rem(ptr(16));
  min-height: rem(ptr(64));
  background-color: $neutral-25;
  // @include border-top-radius(rem(ptr(7)));

  .close {
    // padding: $modal-header-padding;
    // auto on the left force icon to the right even when there is no .modal-title
    // margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
    margin-right: rem(ptr(-8));
  }

  &.modal-header-border {
    border-bottom: 1px solid $neutral-100;
  }
}

// Title text within header
.modal-title {
  margin: 0;
  font-size: rem(ptr(20));
  font-weight: 700;
  letter-spacing: .25px;
  line-height: $modal-title-line-height;
}

// .modal-close-btn {
//   position: absolute;
//   top: rem(ptr(4));
//   right: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: rem(ptr(14));
//   margin: 0;
//   color: $neutral-600;
//   cursor: pointer;
//   background-color: transparent;
//   border: none;
//   transition: color .25s;
//   &:hover {
//     color: $theme-500;
//   }
//   span::before {
//     font-size: $glyph-font-size-default;
//     line-height: $glyph-font-size-default;
//   }
// }

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: rem(ptr(16));
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

// Footer (for actions)
.modal-footer {
  padding: rem(ptr(8));

  .xrx-btn {
    // display: block;
    width: 100%;
  }

  &.modal-footer-border {
    border-top: 1px solid $neutral-100;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// small modal - xwuikit
.modal-sm {
  // max-width: $modal-sm;
  margin: $modal-dialog-margin auto;
  // .modal-footer {
  //   display: block;
  //   .xrx-btn {
  //     width: 100%;
  //   }
  // }
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    // max-width: $modal-md;
    // margin: $modal-dialog-margin-y-sm-up auto;
    // &.modal-sm {
    //   max-width: $modal-sm;
    // }
  }

  .modal-dialog-centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .modal-content {
    @include xrx-box-shadow($modal-content-box-shadow);
  }

  // .modal-sm { max-width: $modal-sm; }

  .modal-footer {
    // display: flex;
    // align-items: center; // vertically center
    // justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    // padding: $modal-footer-padding;
    // border-top: $modal-footer-border-width solid $modal-footer-border-color;

  }

}

// @include media-breakpoint-up(lg) {
//   .modal-lg { max-width: $modal-lg; }
// }

.modal-xs { max-width: 300px; }
.modal-sm { max-width: 500px; }
.modal-md { max-width: 800px; }
.modal-lg { max-width: 1140px; }
.modal-fullscreen {
  .modal-content {
    position: fixed;
    top: -20px;
    bottom: 20px;
    left: 0;
    right: 0;
    border-radius: 0;
    transition: top .15s, bottom .15s;
  }
}

// fullscreen transition
.xrx-modal.show .modal-fullscreen .modal-content {
  top: 0;
  bottom: 0;
}

.modal-demoted { // nested modal - xwuikit
  z-index: $zindex-modal-demoted;
}

.no-backdrop {
  opacity: 0 !important;
}

// button layout
.modal-buttons {
  display: flex;
  justify-content: flex-end;
}
@include media-breakpoint-down(sm) {
  .modal-buttons .cb { width: 100%; }
}
@include media-breakpoint-down(xs) {
  .modal-buttons { display: block; }
}

// dark mode styles
.xrx-dark-appearance {
  .modal-header,
  .modal-content {
    background-color: $neutral-700;
  }
  .modal-content {
    box-shadow: $shadow-dark-05;
  }
  .modal-header.modal-header-border {
    border-bottom-color: $neutral-900;
  }
  .modal-footer.modal-footer-border {
    border-top-color: $neutral-900;
  }
}

// stylelint-enable
