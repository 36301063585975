// This file is generated
// stylelint-disable
$success-50: #caf7ba !default;
$success-100: #a4e195 !default;
$success-200: #7bc76f !default;
$success-300: #66b85f !default;
$success-400: #3ea33e !default;
$success-500: #24882b !default;
$success-600: #186a1e !default;
$success-700: #0f5615 !default;
$success-800: #09420f !default;
$success-900: #04390b !default;
$success-1000: #041900 !default;
$success-a1: #22df24 !default;
$danger-50: #ffcbcf !default;
$danger-100: #ffa1a9 !default;
$danger-200: #ff808b !default;
$danger-300: #f85562 !default;
$danger-400: #ed3745 !default;
$danger-500: #d92231 !default;
$danger-600: #bd1623 !default;
$danger-700: #9f0b14 !default;
$danger-800: #7f0309 !default;
$danger-900: #5a0004 !default;
$danger-1000: #320001 !default;
$danger-a1: #ff4a49 !default;
$warning-50: #fff3bb !default;
$warning-100: #ffea9e !default;
$warning-200: #ffda7b !default;
$warning-300: #ffc558 !default;
$warning-400: #ffaf3d !default;
$warning-500: #fd9726 !default;
$warning-600: #dc7f18 !default;
$warning-700: #ba6a0c !default;
$warning-800: #955503 !default;
$warning-900: #693b00 !default;
$warning-1000: #2c1800 !default;
$warning-a1: #ffbc1a !default;
$info-50: #bcefff !default;
$info-100: #87d8ec !default;
$info-200: #64bbd2 !default;
$info-300: #54abc4 !default;
$info-400: #3192b0 !default;
$info-500: #177896 !default;
$info-600: #0e6682 !default;
$info-700: #08546e !default;
$info-800: #024158 !default;
$info-900: #002c3e !default;
$info-1000: #001823 !default;
$info-a1: #12b3ff !default;
// stylelint-enable
