// stylelint-disable

.xrx-select {
  position: relative;
  min-width: 90px;
  margin-bottom: rem(ptr(16));

  select {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .dropdown-menu-arrow {
    display: none;
  }

  .dropdown-toggle {
    position: relative;
    // z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    // border: none;
    border: 1px solid $neutral-100;
    outline: none;
    color: $black;
    transition: all .165s;

    $select-btn-padding-x: 16px !default;

    @include xrx-button-size($btn-height, $btn-padding-y, $select-btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

    padding-right: 12px;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    .caret {
      color: $black;
    }

    // button label
    .btn-label {
      padding-right: 8px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      [class*="xgl-"] {
        display: inline-block;
        top: 4px;
        margin-right: 16px;
      }
      &.placeholder-style {
        color: $neutral-300;
      }
    }

    .select-btn-right-object {
      position: relative;
      top: -1px;
      display: flex;
      .divider {
        position: relative;
        top: 1px;
        margin: 0 11px 0 11px;
        width: 1px;
        height: 24px;
        background-color: $neutral-100;
      }
    }

    [class*="xgl-"] {
      top: 1px;
    }

    // default colors
    background-color: $neutral-25;

    @media (hover) {
      &:hover:not(:disabled) {
        background-color: $neutral-100;
      }
    }

    &:active:not(:disabled) {
      background-color: $neutral-200;
    }

    &:disabled,
    &.disabled {
      // background-color: $neutral-200;
      cursor: not-allowed;
    }

  }

  &.disabled {
    opacity: .3;
  }

  .left-label span {
    white-space: nowrap;
  }

  .dropdown-menu {
    margin-top: 0px !important;
  }

  // [role="group"] .left-label {
  //   margin-left: 32px;
  // }

  [role="group"] .list-group-item {
    padding-left: 16px;
  }

  .show {

    &.menu-down {
      .dropdown-toggle {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.menu-up {
      .dropdown-toggle {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

  }

  .menu-down {
    .dropdown-menu,
    .menu-scroll-container,
    .xrx-list-group,
    .list-group-header {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .dropdown-menu {
      clip-path: inset(0px -25px -25px -25px);
    }
  }

  .menu-up {
    .dropdown-menu,
    .menu-scroll-container,
    .xrx-list-group {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .dropdown-menu {
      clip-path: inset(-25px -25px 0px -25px);
    }
  }

  .dropdown-menu {
    overflow: hidden;
  }

  .form-text .message {
    color: $neutral-600;
  }

}

// validation styles

.xrx-select.select-focus .dropdown-toggle,
.xrx-select .dropdown-toggle:focus {
  box-shadow: $button-box-shadow-focus;
}

.using-mouse .xrx-select:not([class*="select-validation"]) .dropdown-toggle {
  box-shadow: none;
}

// success
.xrx-select.select-validation-success {
  .dropdown-toggle {
    box-shadow: none;
    border-color: $success-500;
  }
  .form-text .message {
    color: $success-600;
  }
}
.select-validation-success.select-focus .dropdown-toggle,
.select-validation-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
}
.using-mouse .select-validation-success.select-focus .dropdown-toggle,
.using-mouse .select-validation-success .dropdown-toggle:focus {
  box-shadow: none;
  border-color: $success-500;
}

// danger
.xrx-select.select-validation-danger {
  .dropdown-toggle {
    box-shadow: none;
    border-color: $danger-500;
  }
  .form-text .message {
    color: $danger-700;
  }
}
.select-validation-danger.select-focus .dropdown-toggle,
.select-validation-danger .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
}
.using-mouse .select-validation-danger.select-focus .dropdown-toggle,
.using-mouse .select-validation-danger .dropdown-toggle:focus {
  box-shadow: none;
  border-color: $danger-500;
}

// warning
.xrx-select.select-validation-warning {
  .dropdown-toggle {
    box-shadow: none;
    border-color: $warning-500;
  }
  .form-text .message {
    color: $warning-800;
  }
}
.select-validation-warning.select-focus .dropdown-toggle,
.select-validation-warning .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
}
.using-mouse .select-validation-warning.select-focus .dropdown-toggle,
.using-mouse .select-validation-warning .dropdown-toggle:focus {
  box-shadow: none;
  border-color: $warning-500;
}


// theme mode
.xrx-theme-appearance {

  .xrx-select {
    .dropdown-toggle {
      color: $white;
      border-color: $theme-700;

      .caret {
        color: $white;
      }

      background-color: $theme-500;

      @media (hover) {
        &:hover:not(:disabled) {
          background-color: $theme-700;
        }
      }

      &:active:not(:disabled) {
        background-color: $theme-800;
      }

      .select-btn-right-object .divider {
        background-color: $theme-700;
      }
    }

    .form-text {
      color: $theme-200;
    }

    .btn-label.placeholder-style {
      color: $theme-300;
    }
  }

  // success
  .xrx-select.select-validation-success {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $success-200;
    }
    .form-text .message {
      color: $theme-200;
    }
  }
  .select-validation-success.select-focus .dropdown-toggle,
  .select-validation-success .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
  }
  .using-mouse & .select-validation-success.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-success .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $success-200;
  }

  // danger
  .xrx-select.select-validation-danger {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $danger-200;
    }
    .form-text .message {
      color: $theme-200;
    }
  }
  .select-validation-danger.select-focus .dropdown-toggle,
  .select-validation-danger .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
  }
  .using-mouse & .select-validation-danger.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-danger .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $danger-200;
  }

  // warning
  .xrx-select.select-validation-warning {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $warning-200;
    }
    .form-text .message {
      color: $theme-200;
    }
  }
  .select-validation-warning.select-focus .dropdown-toggle,
  .select-validation-warning .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
  }
  .using-mouse & .select-validation-warning.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-warning .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $warning-200;
  }

}


// dark mode
.xrx-dark-appearance {

  .xrx-select {
    .dropdown-toggle {
      color: $white;
      border-color: $neutral-900;

      .caret {
        color: $white;
      }

      background-color: $neutral-700;

      @media (hover) {
        &:hover:not(:disabled) {
          background-color: $neutral-900;
        }
      }

      &:active:not(:disabled) {
        background-color: $neutral-1000;
      }

      .select-btn-right-object .divider {
        background-color: $neutral-900;
      }

    }

    .form-text {
      color: $neutral-200;
    }

    .btn-label.placeholder-style {
      color: $neutral-400;
    }
  }

  // success
  .xrx-select.select-validation-success {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $success-200;
    }
    .form-text .message {
      color: $success-200;
    }
  }
  .select-validation-success.select-focus .dropdown-toggle,
  .select-validation-success .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
  }
  .using-mouse & .select-validation-success.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-success .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $success-200;
  }

  // danger
  .xrx-select.select-validation-danger {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $danger-200;
    }
    .form-text .message {
      color: $danger-200;
    }
  }
  .select-validation-danger.select-focus .dropdown-toggle,
  .select-validation-danger .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
  }
  .using-mouse & .select-validation-danger.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-danger .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $danger-200;
  }

  // warning
  .xrx-select.select-validation-warning {
    .dropdown-toggle {
      box-shadow: none;
      border-color: $warning-200;
    }
    .form-text .message {
      color: $warning-200;
    }
  }
  .select-validation-warning.select-focus .dropdown-toggle,
  .select-validation-warning .dropdown-toggle:focus {
    box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
  }
  .using-mouse & .select-validation-warning.select-focus .dropdown-toggle,
  .using-mouse & .select-validation-warning .dropdown-toggle:focus {
    box-shadow: none;
    border-color: $warning-200;
  }

}


// dark-theme mode
.xrx-dark-appearance {
  .xrx-theme-appearance {

    .xrx-select {
      .dropdown-toggle {
        // color: $white;
        border-color: $theme-800;

        .caret {
          color: $white;
        }

        background-color: $theme-600;

        @media (hover) {
          &:hover:not(:disabled) {
            background-color: $theme-800;
          }
        }

        &:active:not(:disabled) {
          background-color: $theme-900;
        }

        .select-btn-right-object .divider {
          background-color: $theme-800;
        }

      }

      .form-text {
        color: $theme-200;
      }

      .btn-label.placeholder-style {
        color: $theme-300;
      }
    }

    // success
    .xrx-select.select-validation-success {
      .dropdown-toggle {
        box-shadow: none;
        border-color: $success-200;
      }
      .form-text .message {
        color: $theme-200;
      }
    }
    .select-validation-success.select-focus .dropdown-toggle,
    .select-validation-success .dropdown-toggle:focus {
      box-shadow: 0 0 0 3px $success-a1, 0 0 0 6px rgba($success-a1, .2);
    }
    .using-mouse & .select-validation-success.select-focus .dropdown-toggle,
    .using-mouse & .select-validation-success .dropdown-toggle:focus {
      box-shadow: none;
      border-color: $success-200;
    }

    // danger
    .xrx-select.select-validation-danger {
      .dropdown-toggle {
        box-shadow: none;
        border-color: $danger-200;
      }
      .form-text .message {
        color: $theme-200;
      }
    }
    .select-validation-danger.select-focus .dropdown-toggle,
    .select-validation-danger .dropdown-toggle:focus {
      box-shadow: 0 0 0 3px $danger-a1, 0 0 0 6px rgba($danger-a1, .2);
    }
    .using-mouse & .select-validation-danger.select-focus .dropdown-toggle,
    .using-mouse & .select-validation-danger .dropdown-toggle:focus {
      box-shadow: none;
      border-color: $danger-200;
    }

    // warning
    .xrx-select.select-validation-warning {
      .dropdown-toggle {
        box-shadow: none;
        border-color: $warning-200;
      }
      .form-text .message {
        color: $theme-200;
      }
    }
    .select-validation-warning.select-focus .dropdown-toggle,
    .select-validation-warning .dropdown-toggle:focus {
      box-shadow: 0 0 0 3px $warning-a1, 0 0 0 6px rgba($warning-a1, .2);
    }
    .using-mouse & .select-validation-warning.select-focus .dropdown-toggle,
    .using-mouse & .select-validation-warning .dropdown-toggle:focus {
      box-shadow: none;
      border-color: $warning-200;
    }

  }
}

// stylelint-enable
