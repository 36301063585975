// This file is generated
// stylelint-disable
$neutral-25: #fafaff !default;
$neutral-50: #f2f2f9 !default;
$neutral-100: #dbdbe3 !default;
$neutral-200: #bbbcc3 !default;
$neutral-300: #9e9fa4 !default;
$neutral-400: #818187 !default;
$neutral-500: #67676c !default;
$neutral-600: #525256 !default;
$neutral-700: #3f3f43 !default;
$neutral-800: #29292d !default;
$neutral-900: #1b1b1e !default;
$neutral-1000: #0e0e10 !default;
$black-alpha-10: rgba(0, 0, 0, .1) !default;
$black-alpha-20: rgba(0, 0, 0, .2) !default;
$black-alpha-30: rgba(0, 0, 0, .3) !default;
$black-alpha-40: rgba(0, 0, 0, .4) !default;
$black-alpha-50: rgba(0, 0, 0, .5) !default;
$black-alpha-60: rgba(0, 0, 0, .6) !default;
$black-alpha-70: rgba(0, 0, 0, .7) !default;
$black-alpha-80: rgba(0, 0, 0, .8) !default;
$black-alpha-90: rgba(0, 0, 0, .93) !default;
$black: #000000 !default;
$white-alpha-10: rgba(255, 255, 255, .1) !default;
$white-alpha-20: rgba(255, 255, 255, .2) !default;
$white-alpha-30: rgba(255, 255, 255, .3) !default;
$white-alpha-40: rgba(255, 255, 255, .4) !default;
$white-alpha-50: rgba(255, 255, 255, .5) !default;
$white-alpha-60: rgba(255, 255, 255, .6) !default;
$white-alpha-70: rgba(255, 255, 255, .7) !default;
$white-alpha-80: rgba(255, 255, 255, .8) !default;
$white-alpha-90: rgba(255, 255, 255, .9) !default;
$white: #ffffff !default;
// stylelint-enable
