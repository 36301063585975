// stylelint-disable
.xrx-switch {
  position: relative;
  display: table;
  font-size: 0;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .svg-switch-focus,
  .svg-switch-thumb-shadow-dark {
    opacity: 0;
  }

  .svg-switch-checkmark {
    fill: rgba($white, 1);
  }

  .svg-switch-focus {
    fill: $theme-a1;
  }

  .svg-switch-outer-focus {
    opacity: .2;
  }

  .svg-switch-channel {
    fill: rgba($neutral-100, 1);
    transition: fill .15s linear;
  }

  .svg-switch-thumb {
    transform-origin: 0%;
    transform: translate(10px, 10px);
    transition: transform .15s linear, transform-origin .15s linear;
  }

  &.switch-sm .svg-switch-thumb {
    transform: translate(7px, 7px);
  }

  .svg-switch-thumb-background {
    fill: rgba($neutral-25, 1);
    transition: fill .15s linear;
  }

  .svg-switch-thumb-shadow-inset {
    fill: rgba($white, 1);
    transition: fill .15s linear;
  }

  .svg-switch-thumb-shadow-light {
    opacity: 1;
    transition: opacity .15s linear;
  }

  .svg-switch-thumb-shadow-theme {
    opacity: 0;
    transition: opacity .15s linear;
  }

  @media (hover) {
    &:hover input:not(:disabled) ~ svg .svg-switch-thumb {
      transform: translate(10px, 10px) scaleX(1.135);
    }
  }

  @media (hover) {
    &.switch-sm:hover input:not(:disabled) ~ svg .svg-switch-thumb {
      transform: translate(7px, 7px) scaleX(1.135);
    }
  }

  &:active input:not(:disabled) ~ svg {
    .svg-switch-thumb-background {
      fill: rgba($neutral-200, 1);
      transition: fill .067s linear;
    }
    .svg-switch-thumb-shadow-inset {
      fill: rgba($neutral-100, 1);
      transition: fill .067s linear;
    }
  }

  input:focus ~ svg .svg-switch-focus {
    opacity: 1;
  }

  input:disabled ~ svg {
    opacity: .3;
    cursor: not-allowed;
    .svg-switch-channel {
      fill: rgba($neutral-200, 1);
    }
    .svg-switch-thumb-shadow-inset {
      // fill: rgba($white, 0);
      display: none !important;
    }
    .svg-switch-thumb-shadow-light {
      // opacity: 0;
      display: none !important;
    }
    .svg-switch-thumb-shadow-dark {
      // opacity: 0;
      display: none !important;
    }
    .svg-switch-thumb-shadow-theme {
      // fill: rgba($white, 0);
      display: none !important;
    }
  }

  &.checked {

    .svg-switch-channel {
      fill: rgba($theme-500, 1);
    }

    .svg-switch-thumb {
      transform-origin: 37%;
      transform: translate(40px, 10px);
    }

    &.switch-sm .svg-switch-thumb {
      transform-origin: 36%;
      transform: translate(26px, 7px);
    }

    .svg-switch-thumb-shadow-light {
      opacity: 0;
    }

    .svg-switch-thumb-shadow-theme {
      opacity: 1;
    }

    @media (hover) {
      &:hover input:not(:disabled) ~ svg .svg-switch-thumb {
        transform: translate(40px, 10px) scaleX(1.135);
      }
    }

    @media (hover) {
      &.switch-sm:hover input:not(:disabled) ~ svg .svg-switch-thumb {
        transform: translate(26px, 7px) scaleX(1.135);
      }
    }

    input:disabled ~ svg {
      .svg-switch-channel {
        fill: rgba($theme-500, 1);
      }
    }
  }
}


.xrx-theme-appearance {
  .xrx-switch {

    .svg-switch-checkmark {
      fill: rgba($black, 1);
    }

    .svg-switch-channel {
      fill: rgba($theme-800, 1);
    }

    .svg-switch-thumb-background {
      fill: rgba($theme-500, 1);
    }

    .svg-switch-thumb-shadow-inset {
      fill: rgba($theme-400, 1);
    }

    .svg-switch-thumb-shadow-light {
      opacity: 0;
    }

    .svg-switch-thumb-shadow-theme {
      opacity: 1;
    }

    &:active input:not(:disabled) ~ svg {
      .svg-switch-thumb-background {
        fill: rgba($theme-700, 1);
      }
      .svg-switch-thumb-shadow-inset {
        fill: rgba($theme-600, 1);
      }
    }

    input:disabled ~ svg {
      .svg-switch-channel {
        fill: rgba($neutral-900, 1);
      }
    }

    &.checked {

      .svg-switch-channel {
        fill: rgba($theme-200, 1);
      }

      .svg-switch-thumb-shadow-theme {
        opacity: 0;
      }

      .svg-switch-thumb-shadow-light {
        opacity: 1;
      }

      input:disabled ~ svg {
        .svg-switch-channel {
          fill: rgba($theme-200, 1);
        }
      }
    }
  }
}


.xrx-dark-appearance {
  .xrx-switch {

    .svg-switch-checkmark {
      fill: rgba($black, 1);
    }

    .svg-switch-channel {
      fill: rgba($neutral-900, 1);
    }

    .svg-switch-thumb-background {
      fill: rgba($neutral-700, 1);
    }

    .svg-switch-thumb-shadow-inset {
      fill: rgba($neutral-600, 1);
    }

    .svg-switch-thumb-shadow-light,
    .svg-switch-thumb-shadow-theme {
      opacity: 0;
    }

    .svg-switch-thumb-shadow-dark {
      opacity: 1;
    }

    &:active input:not(:disabled) ~ svg {
      .svg-switch-thumb-background {
        fill: rgba($neutral-800, 1);
      }
      .svg-switch-thumb-shadow-inset {
        fill: rgba($neutral-700, 1);
      }
    }

    input:disabled ~ svg {
      .svg-switch-channel {
        fill: rgba($black, 1);
      }
    }

    &.checked {

      .svg-switch-channel {
        fill: rgba($theme-200, 1);
      }

      .svg-switch-thumb-shadow-dark,
      .svg-switch-thumb-shadow-theme {
        opacity: 0;
      }

      .svg-switch-thumb-shadow-light {
        opacity: 1;
      }

      input:disabled ~ svg {
        .svg-switch-channel {
          fill: rgba($theme-200, 1);
        }
      }
    }
  }
}


.xrx-dark-appearance {
  .xrx-theme-appearance {
    .xrx-switch {

      .svg-switch-checkmark {
        fill: rgba($black, 1);
      }

      .svg-switch-channel {
        fill: rgba($theme-900, 1);
      }

      .svg-switch-thumb-background {
        fill: rgba($theme-600, 1);
      }

      .svg-switch-thumb-shadow-inset {
        fill: rgba($theme-500, 1);
      }

      .svg-switch-thumb-shadow-dark,
      .svg-switch-thumb-shadow-light {
        opacity: 0;
      }

      .svg-switch-thumb-shadow-theme {
        opacity: 1;
      }

      &:active input:not(:disabled) ~ svg {
        .svg-switch-thumb-background {
          fill: rgba($theme-800, 1);
        }
        .svg-switch-thumb-shadow-inset {
          fill: rgba($theme-700, 1);
        }
      }

      input:disabled ~ svg {
        .svg-switch-channel {
          fill: rgba($neutral-900, 1);
        }
      }

      &.checked {

        .svg-switch-channel {
          fill: rgba($theme-200, 1);
        }

        .svg-switch-thumb-shadow-theme {
          opacity: 0;
        }

        .svg-switch-thumb-shadow-light {
          opacity: 1;
        }

        input:disabled ~ svg {
          .svg-switch-channel {
            fill: rgba($theme-200, 1);
          }
        }
      }
    }
  }
}

.using-mouse {
  .svg-switch-focus {
    opacity: 0 !important;
  }
}

.xrx-switch.notransition svg * {
  transition: none;
}

// stylelint-enable
