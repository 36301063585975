// stylelint-disable

// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.xrx-nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  height: 64px;
  // padding: $nav-link-padding-y $nav-link-padding-x;
  padding: 18px 16px 0 16px;
  color: $black !important;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15px;
  text-decoration: none;
  // transition: background-color .5s, color .5s;
  transition: background-color .5s;

  @include hover-focus { // override
    text-decoration: none;
  }

  @media (hover) {
    &:hover {
      background-color: rgba($black, .1);
      // transition: background-color 0s, color .5s;
      transition: background-color 0s;
    }
  }

  &:active {
    background-color: rgba($black, .2);
    color: $black;
  }

  &.active { // selected
    color: $theme-500 !important;
    background-color: $theme-50;
    // transition: background-color .5s, color .5s;
    // border-bottom: 4px solid $theme-500;
  }

  &:focus {
    box-shadow: 0 0 0 3px $theme-a1, 0 0 0 6px rgba($theme-a1, .2);
  }

  .using-mouse  &:focus {
    box-shadow: none;
  }

  // Disabled state lightens text
  &.disabled {
    // color: $nav-link-disabled-color;
    color: rgba($black, .3) !important;
    cursor: not-allowed;
    // pointer-events: none;
  }

  // .nav-link-label {
  //   position: relative;
  //   top: 2px;
  // }
}

// label layout/spacing
.nav-link-content {
  display: flex;
  align-items: center;
  justify-content: center;
  .left-icon {
    margin-left: -2px;
    margin-right: -2px;
  }
  .left-icon ~ .nav-link-label {
    margin-left: 13px;
  }
  .caret {
    margin-left: 12px;
    margin-right: -3px;
  }
  &.glyph-icon-only .left-icon {
    margin-top: 3px;
    margin-right: -3px;
  }
  // .nav-link-label {
  //   transition: color .5s;
  // }

}

// glyph icon on top of label
.nav-tabs .nav-link.glyph-icon-top {
  height: 76px;
  .nav-link-content {
    display: block;
    text-align: center;
    .left-icon {
      display: inline-block;
      top: -2px;
    }
  }
  .nav-link-label {
    position: relative;
    top: -7px;
    display: block;
    margin-left: 0 !important;
  }
}


//
// Tabs
//

.nav-tabs {
  opacity: 0;
  // border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: $nav-tabs-border-width solid rgba($neutral-500, .2);

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
    white-space: nowrap;
  }

  .nav-link {
    height: 48px;
    padding: 11px 16px 0 16px;
    @include border-top-radius(6px);
    // border: $nav-tabs-border-width solid transparent;
    // @include border-top-radius($nav-tabs-border-radius);
    // @include border-top-radius(6px);

    // @include hover-focus {
    //   border-color: $nav-tabs-link-hover-border-color;
    // }

    &.active { // selected
      background-color: transparent;
    }

    &.disabled {
      // color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    // color: $nav-tabs-link-active-color;
    // color: $theme-500;
    // background-color: $nav-tabs-link-active-bg;
    // border-color: $nav-tabs-link-active-border-color;
    // border-bottom: 4px solid $theme-500;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

// .nav-pills {
//   .nav-link {
//     @include border-radius($nav-pills-border-radius);
//   }

//   .nav-link.active,
//   .show > .nav-link {
//     color: $nav-pills-link-active-color;
//     background-color: $nav-pills-link-active-bg;
//   }
// }


//
// Justified variants
//

// .nav-fill {
//   .nav-item {
//     flex: 1 1 auto;
//     text-align: center;
//   }
// }

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

//
// sliding indicator
//

.sliding-indicator {
  position: absolute;
  bottom: -1px;
  height: 4px;
  background-color: $theme-500;
}

.sliding-indicator {
  &.animate {
    transition: left .25s, width .25s;
  }
}

.xrx-dark-appearance {
  .nav-link {
    color: $white !important;

    &.active { // selected
      color: $theme-200 !important;
    }

    @media (hover) {
      &:hover {
        background-color: rgba($white, .1);
      }
    }

    &:active {
      background-color: rgba($white, .2);
    }

    &.disabled {
      color: rgba($white, .3) !important;
      cursor: not-allowed;
    }

  }

  .nav-tabs {
    border-color: rgba($neutral-200, .2);
  }

  .sliding-indicator {
    background-color: $theme-200;
  }

  .nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent;
  }
}

.nav-tabs.hide,
.xrx-nav-tab-select.hide {
  position: fixed;
  left: -5000px;
}

.nav-tabs.init {
  opacity: 1;
}

// stylelint-enable
