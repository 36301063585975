// This file is generated
// stylelint-disable
$blue-50: #e3edfc !default;
$blue-100: #bad1f5 !default;
$blue-200: #91b6ec !default;
$blue-300: #5f94dc !default;
$blue-400: #2f74ca !default;
$blue-500: #155fbb !default;
$blue-600: #0c4fa3 !default;
$blue-700: #01377f !default;
$blue-800: #002760 !default;
$blue-900: #00173c !default;
$blue-1000: #001128 !default;
$blue-a1: #3a90d1 !default;
// stylelint-enable
