// This file is generated
// stylelint-disable
$shadow-light-inset: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.5) !default;
$shadow-light-01: 0 1px 2px 1px rgba(0,0,0,0.16) !default;
$shadow-light-02: 0 2px 4px 2px rgba(0,0,0,0.16), 0 0 2px 0 rgba(0,0,0,0.08) !default;
$shadow-light-03: 0 4px 8px 3px rgba(0,0,0,0.16), 0 0 4px 0 rgba(0,0,0,0.08) !default;
$shadow-light-04: 0 8px 12px 4px rgba(0,0,0,0.16), 0 0 8px 0 rgba(0,0,0,0.08) !default;
$shadow-light-05: 0 16px 24px 5px rgba(0,0,0,0.16), 0 0 16px 0 rgba(0,0,0,0.08) !default;
$shadow-theme-inset: inset 0 1px 2px 0 rgba(0, 0, 0, 1) !default;
$shadow-theme-01: 0 1px 2px 1px rgba(0,0,0,0.32) !default;
$shadow-theme-02: 0 2px 4px 2px rgba(0,0,0,0.32), 0 0 2px 0 rgba(0,0,0,0.16) !default;
$shadow-theme-03: 0 4px 8px 3px rgba(0,0,0,0.32), 0 0 4px 0 rgba(0,0,0,0.16) !default;
$shadow-theme-04: 0 8px 12px 4px rgba(0,0,0,0.32), 0 0 8px 0 rgba(0,0,0,0.16) !default;
$shadow-theme-05: 0 16px 24px 5px rgba(0,0,0,0.32), 0 0 16px 0 rgba(0,0,0,0.16) !default;
$shadow-dark-inset: inset 0 1px 2px 0 rgba(0, 0, 0, 1) !default;
$shadow-dark-01: 0 1px 2px 1px rgba(0,0,0,0.48) !default;
$shadow-dark-02: 0 2px 4px 2px rgba(0,0,0,0.48), 0 0 2px 0 rgba(0,0,0,0.24) !default;
$shadow-dark-03: 0 4px 8px 3px rgba(0,0,0,0.48), 0 0 4px 0 rgba(0,0,0,0.24) !default;
$shadow-dark-04: 0 8px 12px 4px rgba(0,0,0,0.48), 0 0 8px 0 rgba(0,0,0,0.24) !default;
$shadow-dark-05: 0 16px 24px 5px rgba(0,0,0,0.48), 0 0 16px 0 rgba(0,0,0,0.24) !default;
// stylelint-enable
