// stylelint-disable
[class*="xgl-"] {
  position: relative;
  top: rem(ptr(1));
  display: table-cell;
  padding: 0;
  margin: 0;
  font-family: xglyphregular;
  font-size: 0;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 0;
  speak: none;
  text-transform: none;
  background: none;
  border: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xglsize-12 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(12));
    line-height: rem(ptr(12));
  }
}

.xglsize-14 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(14));
    line-height: rem(ptr(14));
  }
}

.xglsize-16 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(16));
    line-height: rem(ptr(16));
  }
}

.xglsize-18 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(18));
    line-height: rem(ptr(18));
  }
}

.xglsize-20 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(20));
    line-height: rem(ptr(20));
  }
}

.xglsize-24 {
  top: rem(ptr(0));

  &::before {
    font-size: rem(ptr(24));
    line-height: rem(ptr(24));
  }
}

.xglsize-28::before {
  font-size: rem(ptr(28));
  line-height: rem(ptr(28));
}

.xglsize-32::before {
  font-size: rem(ptr(32));
  line-height: rem(ptr(32));
}

.xglsize-36::before {
  margin-right: rem(ptr(-1));
  margin-left: rem(ptr(-1));
  font-size: rem(ptr(36));
  line-height: rem(ptr(36));
}

.xglsize-48::before {
  margin-right: rem(ptr(-1));
  margin-left: rem(ptr(-1));
  font-size: rem(ptr(48));
  line-height: rem(ptr(48));
}

.xglsize-64::before {
  margin-right: rem(ptr(-2));
  margin-left: rem(ptr(-2));
  font-size: rem(ptr(64));
  line-height: rem(ptr(64));
}

// space
// space
// space
// space

[class*="_two_tone"] {

  &::before,
  &::after {
    position: absolute;
    left: 0
  }

  &.xglsize-12 {
    top: rem(ptr(0));
    width: rem(ptr(12));
    height: rem(ptr(12));
  }

  &.xglsize-12::before,
  &.xglsize-12::after {
    // position: absolute;
    font-size: rem(ptr(12));
    line-height: rem(ptr(12));
  }

  &.xglsize-14 {
    top: rem(ptr(0));
    width: rem(ptr(14));
    height: rem(ptr(14));
  }

  &.xglsize-14::before,
  &.xglsize-14::after {
    // position: absolute;
    font-size: rem(ptr(14));
    line-height: rem(ptr(14));
  }

  &.xglsize-16 {
    top: rem(ptr(0));
    width: rem(ptr(16));
    height: rem(ptr(16));
  }

  &.xglsize-16::before,
  &.xglsize-16::after {
    // position: absolute;
    font-size: rem(ptr(16));
    line-height: rem(ptr(16));
  }

  &.xglsize-18 {
    top: rem(ptr(0));
    width: rem(ptr(18));
    height: rem(ptr(18));
  }

  &.xglsize-18::before,
  &.xglsize-18::after {
    // position: absolute;
    font-size: rem(ptr(18));
    line-height: rem(ptr(18));
  }

  &.xglsize-20 {
    top: rem(ptr(0));
    width: rem(ptr(20));
    height: rem(ptr(20));
  }

  &.xglsize-20::before,
  &.xglsize-20::after {
    // position: absolute;
    font-size: rem(ptr(20));
    line-height: rem(ptr(20));
  }

  &.xglsize-24 {
    top: rem(ptr(0));
    width: rem(ptr(24));
    height: rem(ptr(24));
  }

  &.xglsize-24::before,
  &.xglsize-24::after {
    // position: absolute;
    font-size: rem(ptr(24));
    line-height: rem(ptr(24));
  }

  &.xglsize-28 {
    width: rem(ptr(28));
    height: rem(ptr(28));
  }

  &.xglsize-28::before,
  &.xglsize-28::after {
    // position: absolute;
    font-size: rem(ptr(28));
    line-height: rem(ptr(28));
  }

  &.xglsize-32 {
    width: rem(ptr(32));
    height: rem(ptr(32));
  }

  &.xglsize-32::before,
  &.xglsize-32::after {
    // position: absolute;
    font-size: rem(ptr(32));
    line-height: rem(ptr(32));
  }

  &.xglsize-48 {
    width: rem(ptr(46));
    height: rem(ptr(48));
  }

  &.xglsize-48::before,
  &.xglsize-48::after {
    // position: absolute;
    margin-right: rem(ptr(-1));
    margin-left: rem(ptr(-1));
    font-size: rem(ptr(48));
    line-height: rem(ptr(48));
  }

  &.xglsize-64 {
    width: rem(ptr(60));
    height: rem(ptr(64));
  }

  &.xglsize-64::before,
  &.xglsize-64::after {
    // position: absolute;
    margin-right: rem(ptr(-2));
    margin-left: rem(ptr(-2));
    font-size: rem(ptr(64));
    line-height: rem(ptr(64));
  }
}

// stylelint-enable
